import Button from '@material-ui/core/Button';
import styled from 'styled-components';

const YellowButton = styled(Button) <{ noaction?: boolean }>`
    background-color: #eb7800; 
    color: #ffffff;
    height: fit-content;
    margin: auto;
    cursor: ${p => !p.noaction ? 'pointer' : 'default'};
    pointer-events: ${p => !p.noaction ? 'unset' : 'none'};

    &:hover {
        background-color: ${p => !p.noaction ? '#ff9800' : '#eb7800'};
    }
`;

export default YellowButton;
