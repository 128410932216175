import { useCallback, useEffect, useRef, useState } from 'react';
import { useWindowSize } from 'react-use';

import { Button, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import Chip from '@material-ui/core/Chip';

import useInternalLoader from '../../../../common/hooks/useInternalLoader/useExternalLoader';
import api from '../../../../common/utils/api';
import GenericObject from '../../../../typesAdditional/GenericObject';
import NewVoucherDialog from './components/NewVoucherDialog/NewVoucherDialog';

const DeletedRegistrationsPendingVouchersPage = () => {
    const setIsLoading = useInternalLoader();

    const { width } = useWindowSize();

    const [data, setData] = useState<GenericObject[]>([]);
    const [selectedRegistration, setSelectedRegistration] = useState<GenericObject | undefined>(undefined);

    const [isNewVoucherDialogOpen, setIsNewVoucherDialogOpen] = useState(false);

    const apiRequestId = useRef('');

    const refreshData = useCallback(() => {
        const currentRequestId = uuidv4();
        apiRequestId.current = currentRequestId;

        api.request('/admin/deleted_registrations/pending_vouchers', 'GET').then((res: any) => {
            if (currentRequestId !== apiRequestId.current) return;

            setData(res);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [setIsLoading]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            refreshData();
        }, 10);

        return () => clearTimeout(timeout);
    }, [refreshData]);

    const isMobile = width <= 700;

    return (
        <>
            <div>
                <div style={{ display: isMobile ? undefined : 'flex', textAlign: isMobile ? 'center' : undefined }}>
                    <Typography variant='h2' style={{ fontSize: '3.6em' }}>
                        Elenco voucher da emettere
                    </Typography>

                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '18px' }}>
                        <div style={{ flexGrow: 1 }} />
                        <Typography variant='h2' style={{ fontSize: '2em', marginBottom: '4px', textAlign: isMobile ? 'center' : 'left' }}>
                            settimane non fruite
                        </Typography>
                    </div>

                    <div style={{ flex: 1, flexGrow: 1 }} />
                </div>

                <Divider style={{ margin: '8px 0 12px' }} />
            </div>

            <TableContainer component={Paper} style={{ marginTop: '16px' }}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Cognome</TableCell>
                            <TableCell>Nome</TableCell>
                            <TableCell>Camp</TableCell>
                            <TableCell>Paese</TableCell>
                            <TableCell>Importo non fruito</TableCell>
                            <TableCell>Stato voucher</TableCell>
                            <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>Azioni</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map(registration => {
                            const voucherStatusMap = {
                                to_issue: {
                                    color: '#fdecea',
                                    borderColor: '#f44336',
                                    label: 'Da emettere',
                                    outlined: false
                                },
                                issued: {
                                    color: '#edf7ed',
                                    borderColor: '#4caf50',
                                    label: 'Emesso',
                                    outlined: false
                                },
                                partial_issued: {
                                    color: '#fff4e5',
                                    borderColor: '#ff9800',
                                    label: 'Parzialmente emesso',
                                    outlined: false
                                }
                            }

                            const voucherStatus = parseFloat(registration.emittedVouchers) >= (parseFloat(registration?.additionalData?.paid) - parseFloat(registration?.additionalData?.cost)) ? 'issued' : (parseFloat(registration.emittedVouchers) > 0 ? 'partial_issued' : 'to_issue');
                            const voucherStatusData = voucherStatusMap[voucherStatus];

                            return (
                                <TableRow style={{ height: '45px' }}>
                                    <TableCell>{registration.person.surname}</TableCell>
                                    <TableCell>{registration.person.name}</TableCell>
                                    <TableCell>{registration.course.name}</TableCell>
                                    <TableCell>{registration.courseLocation.townName}</TableCell>
                                    <TableCell>€{(parseFloat(registration?.additionalData?.paid) - parseFloat(registration?.additionalData?.cost)).toFixed(2)}</TableCell>
                                    <TableCell>
                                        <Chip label={voucherStatusData.label} variant={voucherStatusData.outlined ? 'outlined' : 'default'} style={voucherStatusData.outlined ? { width: '170px' } : { backgroundColor: voucherStatusData.color, border: '1px solid ' + voucherStatusData.borderColor, width: '170px' }} />
                                    </TableCell>
                                    <TableCell style={{ padding: '2px 16px', whiteSpace: 'nowrap' }} onClick={() => {
                                        setSelectedRegistration(registration);
                                        setIsNewVoucherDialogOpen(true);
                                    }}>
                                        <Button size='small' variant='outlined' color='primary' disabled={voucherStatus === 'issued'}>
                                            Emetti voucher
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <NewVoucherDialog isOpen={isNewVoucherDialogOpen} onClose={() => setIsNewVoucherDialogOpen(false)} updateData={() => refreshData()} registrationData={selectedRegistration} />
        </>
    );
};

export default DeletedRegistrationsPendingVouchersPage;
