import { useCallback } from 'react';

const useSubmitOnEnter = (submit: () => void) => {
    return useCallback((event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            submit();
        }
    }, [submit]);
};

export default useSubmitOnEnter;
