import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import moment from 'moment';

import GenericObject from '../../../../../../../../typesAdditional/GenericObject';

interface Props {
    payment: GenericObject;
    deletePayment?: (payment: GenericObject) => void;
}

const PaymentCard = (props: Props) => {
    const { payment, deletePayment } = props;

    return (
        <Card variant='outlined' style={{ marginBottom: '12px' }}>
            <CardContent>
                <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ flex: 1, display: 'flex', alignItems: 'center', fontSize: '1.75em' }}>
                            € {parseFloat(payment.amount).toFixed(2)}
                        </div>
                        {payment.invoiceNumber && (
                            <div style={{ flex: 1, display: 'flex', alignItems: 'center', marginTop: '4px', marginBottom: '4px' }}>
                                Fattura: {payment.invoiceNumber}
                            </div>
                        )}
                        {payment.tax.taxable > 0.001 && (
                            <div style={{ flex: 1, marginTop: '4px', marginBottom: '4px' }}>
                                Netto: € {(payment.amount - payment.tax.taxable + payment.tax.netAmount).toFixed(2)}<br />
                                Tasse coll: € {(payment.tax.trainerTaxes).toFixed(2)}<br />
                                Tasse E.S: € {(payment.tax.esTaxes).toFixed(2)}<br />
                                <b>LORDO: € {(payment.amount - payment.tax.taxable + payment.tax.netAmount + payment.tax.trainerTaxes + payment.tax.esTaxes).toFixed(2)}</b><br />
                            </div>
                        )}
                        <div style={{ marginTop: '2px', flex: 1, display: 'flex', alignItems: 'center' }}>
                            {moment(payment.createdAt).format('DD/MM/YYYY H:mm:ss')}
                        </div>
                    </div>
                    <div style={{ flexGrow: 1 }} />
                    {payment.createdBy && (
                        <div style={{ textAlign: 'right', alignItems: 'center', display: 'flex' }}>
                            <div>
                                <div style={{ fontWeight: 'bold', marginBottom: '2px' }}>
                                    Registrato da
                                </div>
                                {payment.createdBy.name}<br />
                                {payment.createdBy.email}
                            </div>
                        </div>
                    )}
                </div>

                {deletePayment && (
                    <div style={{ textAlign: 'right' }}>
                        <Button onClick={() => deletePayment(payment)} color={'secondary'} variant={'outlined'} size='small' style={{ marginTop: '8px', marginBottom: '-8px' }}>
                            Elimina pagamento
                        </Button>
                    </div>
                )}
            </CardContent>
        </Card>
    );
};

export default PaymentCard;
