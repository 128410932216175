import { ButtonProps } from '@material-ui/core/Button';
import 'moment/locale/it';

import CustomDialogWrapper from '../../../../../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import GenericObject from '../../../../../../../../../../typesAdditional/GenericObject';
import DocumentCard from '../../DocumentCard';
import { Document } from '../../../../types';

interface Props {
    open: boolean;
    closeDialog: (refresh?: boolean) => void;
    documents: Document[];
    documentType: GenericObject;
}

const DeletedDocumentsDialog = (props: Props) => {
    const { open, closeDialog, documents, documentType } = props;

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        }
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog(false)} title={'Documenti eliminati o scaduti - ' + (documentType.name)} buttons={buttons} maxWidth='md' fullWidth>
            {documents?.map((document: Document) => {
                return <DocumentCard document={document} isAdmin deletedDocument />;
            })}


        </CustomDialogWrapper>
    );
};

export default DeletedDocumentsDialog;
