import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ReduxState } from '../../../redux/types';
import LoaderContainer from './components/LoaderContainer/LoaderContainer';
import { Container } from './styled';

interface Props {
    children: React.ReactNode;
    type: 'internal' | 'external';
}

const LoaderWrapper = (props: Props) => {
    const { children, type } = props;

    const { externalLoaders, internalLoaders } = useSelector((state: ReduxState) => state.loaders);
    const { isIframe } = useSelector((state: ReduxState) => state.routing);

    const isLoaderVisible = useMemo(() => {
        if (type === 'external') {
            return externalLoaders.length > 0;
        } else {
            return internalLoaders.length > 0;
        }
    }, [externalLoaders, internalLoaders, type]);

    return (
        <Container whiteBackground={isIframe}>
            {children}
            <LoaderContainer isVisible={isLoaderVisible} type={type} />
        </Container>
    );
};

export default LoaderWrapper;
