import { useCallback, useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';

import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import moment from 'moment';

import GreenButton from '../../../../common/components/GreenButton/GreenButton';
import useInternalLoader from '../../../../common/hooks/useInternalLoader/useExternalLoader';
import { useMessageDialog } from '../../../../common/hooks/useMessageDialog/useMessageDialog';
import api from '../../../../common/utils/api';
import snackbar from '../../../../common/utils/snackbar';
import NewLinkDialog from './components/NewLinkDialog/NewLinkDialog';
import { Title } from './styled';

export const STATUS_MAP = {
    not_used: 'non utilizzato',
    used: 'usato',
    partially_deleted: 'usato - iscrizione parzialmente eliminata',
    fully_deleted: 'usato - iscrizione eliminata'
};

const WelfarePage = () => {
    const setIsLoading = useInternalLoader();

    const { width } = useWindowSize();

    const [showMessageDialog, closeMessageDialog] = useMessageDialog();

    const isMobile = width <= 700;

    const [data, setData] = useState<any[]>([]);
    const [info, setInfo] = useState<any[]>([]);

    const [isNewLinkDialogOpen, setIsNewLinkDialogOpen] = useState(false);

    useEffect(() => {
        api.request('/admin/welfare/info').then(res => {
            setInfo(res);
        });
    }, [setInfo])

    const updateData = useCallback(() => {
        setIsLoading(true);
        setData([]);
        api.request('/admin/welfare/links').then(res => {
            setData(res);
            setIsLoading(false);
        });
    }, [setIsLoading]);

    const deleteLink = useCallback((id: string) => {
        api.request('/admin/welfare/links/' + id, 'DELETE').then(() => {
            snackbar.success('Link eliminato con successo');
            closeMessageDialog();
            updateData();
        });
    }, [updateData, closeMessageDialog]);

    const deleteConfirm = useCallback((id: string, token: any) => {
        showMessageDialog({
            title: 'Archivia voucher',
            message: (
                <>
                    Sei sicuro di volere eliminare il link selezionato?

                    {token.status !== 'not_used' && (
                        <p style={{ marginBottom: 0 }}>
                            Il link è già stato utilizzato; l'iscrizione verrà mantenuta, ma il costo verrà ricalcolato utilizzando le quote standard.
                        </p>
                    )}
                </>
            ),
            actions: [
                {
                    text: 'Annulla',
                    action: () => {
                        closeMessageDialog();
                    }
                },
                {
                    text: 'Elimina',
                    color: 'secondary',
                    action: () => {
                        deleteLink(id);
                    }
                }
            ]
        });
    }, [deleteLink, showMessageDialog, closeMessageDialog]);

    useEffect(() => {
        updateData();
    }, [updateData]);

    return (
        <>
            <Title isMobile={isMobile}>
                <div style={{ flex: '1', flexGrow: 1 }}>
                    Welfare
                </div>

                <GreenButton onClick={() => setIsNewLinkDialogOpen(true)}>
                    Nuovo link welfare
                </GreenButton>
            </Title>

            <TableContainer component={Paper} >
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Piattaforma</TableCell>
                            <TableCell>Riferimento interno</TableCell>
                            <TableCell>Data creazione</TableCell>
                            <TableCell>Corso</TableCell>
                            <TableCell>Importo</TableCell>
                            <TableCell>Importo utilizzato</TableCell>
                            <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>Stato</TableCell>
                            <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>Azioni</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((token: any) => {
                            const statusColor = (token.status === 'used') ? '#edf7ed' : (
                                (token.status === 'not_used') ? '#fdeded' : '#fff4e5'
                            );

                            return (
                                <TableRow>
                                    <TableCell>{token.provider.name}</TableCell>
                                    <TableCell>{token.description}</TableCell>
                                    <TableCell>{moment(token.createdAt).format('DD/MM/YYYY')}</TableCell>
                                    <TableCell>{token.course.name}</TableCell>
                                    <TableCell>€{parseFloat(token.amount).toFixed(2)}</TableCell>
                                    <TableCell>€{parseFloat(token.usedAmount).toFixed(2)}</TableCell>
                                    <TableCell style={{ width: '1px', whiteSpace: 'nowrap', backgroundColor: statusColor }}>{STATUS_MAP[token.status as keyof (typeof STATUS_MAP)]}</TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                                        <Button onClick={() => deleteConfirm(token.id, token)} color={'secondary'} variant='outlined'>
                                            Elimina
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <NewLinkDialog isOpen={isNewLinkDialogOpen} onClose={() => setIsNewLinkDialogOpen(false)} updateData={updateData} info={info} />
        </>
    );
};

export default WelfarePage;
