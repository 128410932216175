import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

interface Props {
    id: string;
    townId: string;
    iframeSuffix: string;
    name: string;
    description: string;
}

const PpsMsCard = (props: Props) => {
    const { id, townId, iframeSuffix, name, description } = props;
    const history = useHistory();

    return (
        <Card variant='outlined' style={{display: 'flex', flexDirection: 'column', flex: '1', margin: '8px', minWidth: '300px'}}>
            <CardContent style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                <div>
                    <Typography variant='h5' component='h2' style={{marginBottom: '4px'}}>
                        {name.toUpperCase()}
                    </Typography>
                    <Typography variant='body2' component='p'>
                        {description}
                    </Typography>
                </div>
            </CardContent>
            <CardActions>
                <Button style={{margin: '0 auto'}} color='primary' onClick={() => history.push('/courses/' + id + '/registration?town=' + townId + '&showBackButtonPpsMs=1' + iframeSuffix)}>Iscriviti</Button>
            </CardActions>
        </Card>
    );
};

export default PpsMsCard;
