import { Dispatch, SetStateAction } from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import CustomTextField from '../../../../../../../../common/components/CustomTextField/CustomTextField';
import GenericObject from '../../../../../../../../typesAdditional/GenericObject';

interface Props {
    locations: any[];
    locationType: string;
    selectedLocationId: string;
    setSelectedLocationId: Dispatch<SetStateAction<string>>;
    selectedLocation: GenericObject;
    setSelectedSessions: Dispatch<SetStateAction<string[]>>;
    canUserChangeLocation: boolean;
    isPreselectedLocation: boolean;
}

const Header = (props: Props) => {
    const { locations, locationType, selectedLocationId, setSelectedLocationId, selectedLocation, setSelectedSessions, canUserChangeLocation, isPreselectedLocation } = props;

    if (isPreselectedLocation) {
        return null;
    }

    return (
        <>
            {locationType === 'multi_location' && (canUserChangeLocation ? (
                <CustomTextField
                    select
                    label='Paese in cui desideri iscriverti'
                    value={selectedLocationId}
                    onChange={(e: any) => {
                        setSelectedSessions([]);
                        setSelectedLocationId(e.target.value);
                    }}
                    variant='outlined'
                    fullWidth
                >
                    {locations.filter((loc: any) => !loc.softHidden).sort((location1: any, location2: any) => {
                        return location1.town.name.localeCompare(location2.town.name);
                    }).map((location: any) => (
                        <MenuItem key={location.id} value={location.id}>
                            {location.townName}
                        </MenuItem>
                    ))}
                </CustomTextField>
            ) : (
                <Typography variant='h4' style={{ fontSize: '1.4em', textAlign: 'center', margin: '8px', marginBottom: '16px' }}>
                    Paese selezionato: {selectedLocation?.townName ?? 'non valido'}
                </Typography>
            ))}
        </>
    );
};

export default Header;
