import { createContext, useContext, useMemo, useRef, useState } from 'react';

import { PropTypes } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import sanitizeHtml from 'sanitize-html';

import CustomDialog from '../../components/CustomDialog/CustomDialog';

type ProviderContext = readonly [(option: DialogOption) => void, (_ignoreCallback?: boolean) => void];

const DialogContext = createContext<ProviderContext>([
    (): void => undefined,
    (_ignoreCallback?: boolean): void => undefined,
]);

export const useMessageDialog = () => useContext(DialogContext);

type Action = {
    text: string;
    action: () => void;
    color?: PropTypes.Color;
    disabled?: boolean
};

type DialogParams = {
    title: string;
    message: string | JSX.Element;
    keepHtmlFormatting?: boolean
    actions?: Action[];
    size?: DialogProps['maxWidth'];
    open: boolean;
    onClose?: () => void;
    notDismissible?: boolean;
};

export type DialogOption = Omit<DialogParams, 'open'>;

type DialogContainerProps = DialogParams & {
    onClose: () => void;
    onKill: () => void;
};

const DialogContainer = (props: DialogContainerProps) => {
    const { title, message, keepHtmlFormatting, actions, size, open, onClose, notDismissible, onKill } = props;

    const realMessage = useMemo(() => {
        if (typeof message === 'string' && keepHtmlFormatting) {
            return <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(message) }} />;
        } else {
            return message;
        }
    }, [message, keepHtmlFormatting]);

    return (
        <CustomDialog open={open} onClose={notDismissible ? undefined : onClose} onExited={onKill} maxWidth={size ?? 'sm'} fullWidth>
            <DialogTitle className='dialog-title-text-ellipsis'>
                {title}
            </DialogTitle>
            <DialogContent dividers>
                {realMessage}
            </DialogContent>
            {(actions || !notDismissible) && (
                <DialogActions>
                    {!actions ? (
                        <Button onClick={onClose} color={'primary'}>
                            Chiudi
                        </Button>
                    ) : actions.map(action => {
                        return (
                            <Button onClick={action.action} color={action.color ?? 'primary'} disabled={action.disabled} key={action.text} >
                                {action.text}
                            </Button>
                        );
                    })}
                </DialogActions>
            )}
        </CustomDialog>
    );
};

const DialogProvider = ({ children } : { children: JSX.Element }) => {
    const [dialogs, setDialogs] = useState<DialogParams[]>([]);

    const createDialog = (option: DialogOption) => {
        const dialog = { ...option, open: true };
        setDialogs((oldDialogs) => [...oldDialogs, dialog]);
    };

    const closeDialog = (ignoreCallback: boolean = false) => {
        setDialogs((oldDialogs) => {
            const latestDialog = oldDialogs.pop();
            if (!latestDialog) return oldDialogs;
            if (latestDialog.onClose && !ignoreCallback) latestDialog.onClose();
            return [...oldDialogs].concat({ ...latestDialog, open: false });
        });
    };

    const contextValue = useRef([createDialog, closeDialog] as const);

    return (
        <DialogContext.Provider value={contextValue.current}>
            {children}
            {dialogs.map((dialog, i) => {
                const { onClose, ...dialogParams } = dialog;
                const handleKill = () => {
                    setDialogs((oldDialogs) => oldDialogs.slice(0, oldDialogs.length - 1));
                };

                return (
                     <DialogContainer
                         key={i}
                         onClose={() => closeDialog()}
                         onKill={handleKill}
                        {...dialogParams}
                    />
                );
            })}
        </DialogContext.Provider>
    );
};

export default DialogProvider;
