import { useCallback, useEffect, useRef, useState } from 'react';

import MomentUtils from '@date-io/moment';
import Button, { ButtonProps } from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import 'moment/locale/it';
import { v4 as uuidv4 } from 'uuid';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import CustomTextField from '../../../../../../common/components/CustomTextField/CustomTextField';
import { StyledTextField } from '../../../../../../common/components/CustomTextField/styled';
import { useMessageDialog } from '../../../../../../common/hooks/useMessageDialog/useMessageDialog';
import api from '../../../../../../common/utils/api';
import snackbar from '../../../../../../common/utils/snackbar';
import UserSegmentSelectorDialog from '../../../../components/UserSegmentSelectorDialog/UserSegmentSelectorDialog';
import PersonSelectDialog from '../../../newRegistrationPage/sections/PersonalData/components/PersonSelectDialog/PersonSelectDialog';
import EmailTemplateDialog from '../EmailTemplateDialog/EmailTemplateDialog';
import Checkbox from '@material-ui/core/Checkbox';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    updateData: () => void;
}

const NewVoucherDialog = (props: Props) => {
    const { isOpen, onClose, updateData } = props;

    const [isLoading, setIsLoading] = useState(false);

    const [showMessageDialog] = useMessageDialog();

    const [code, setCode] = useState('');
    const [amount, setAmount] = useState<any>(0.0);

    const [description, setDescription] = useState('');
    const [type, setType] = useState('specific_users');
    const [selectionType, setSelectionType] = useState('conditions');
    const [targetDetails, setTargetDetails] = useState('all_users');
    const [filterSelectionType, setFilterSelectionType] = useState<'people' | 'users'>('people');

    const [filtersTree, setFiltersTree] = useState<any>([]);
    const [filterSimulationResult, setFilterSimulationResult] = useState<any>({});
    const [emailList, setEmailList] = useState<{ email: string, name: string }[]>([]);

    const [isSelectorDialogOpen, setIsSelectorDialogOpen] = useState(false);
    const [isPersonSelectDialogOpen, setIsPersonSelectDialogOpen] = useState(false);

    const [expireDate, setExpireDate] = useState(new Date().toISOString().split('T')[0]);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

    const [emailTemplates, setEmailTemplates] = useState([]);
    const [emailTemplateId, setEmailTemplateId] = useState('');

    const [isEmailTemplateDialogOpen, setIsEmailTemplateDialogOpen] = useState(false);

    const [templateIdForEdit, setTemplateIdForEdit] = useState<null | string>(null);

    const [ignoreNewsletterConsent, setIgnoreNewsletterConsent] = useState(false);

    const refreshTemplates = useCallback(() => {
        return new Promise<void>((resolve, reject) => {
            api.request('/admin/vouchers/email_templates').then((res: any) => {
                setEmailTemplates(res);
                resolve();
            }).catch(() => {
                reject();
            });
        });
    }, []);

    const handleTypeChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedType = (event.target as HTMLInputElement).value;
        setType(selectedType);
    }, []);

    const handleSelectionTypeChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedType = (event.target as HTMLInputElement).value;
        setSelectionType(selectedType);
    }, []);

    const handleTargetDetailsChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedType = (event.target as HTMLInputElement).value;
        setTargetDetails(selectedType);
    }, []);

    const handleFilterSelectionTypeChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedType = (event.target as HTMLInputElement).value;
        setFilterSelectionType(selectedType as ('people' | 'users'));
    }, []);

    const handlePersonClick = useCallback((data: any) => {
        if (!data.email) {
            showMessageDialog({
                title: 'Email non trovata',
                message: (
                    <>
                        Non è possibile trovare l'indirizzo email dell'utente selezionato
                    </>
                )
            });
        } else {
            const email = data.email;
            const name = data.name + ' ' + data.surname;

            setEmailList(m => {
                if (!m.find(m2 => m2.email === email)) {
                    return [...m, { email, name }];
                }

                return m;
            });

            setIsPersonSelectDialogOpen(false);
        }
    }, [showMessageDialog]);

    const removeEmail = useCallback((email: string) => {
        setEmailList(m => m.filter(m2 => m2.email !== email));
    }, []);

    const handleSave = useCallback(() => {
        setIsLoading(true);
        api.request('/admin/vouchers', 'POST', {
            description,
            amount,
            expireDate,
            target: type,
            code,
            selectionType,
            filtersTree,
            emailList,
            emailTemplateId,
            ignoreNewsletterConsent,
            targetDetails,
            filterSelectionType
        }).then((res: any) => {
            snackbar.success('Voucher creato con successo!');
            onClose();
            updateData();
        }).finally(() => {
            setIsLoading(false);
        });
    }, [filterSelectionType, targetDetails, emailTemplateId, description, amount, expireDate, type, code, filtersTree, selectionType, emailList, onClose, updateData, ignoreNewsletterConsent]);

    const apiRequestId = useRef('');

    useEffect(() => {
        const currentRequestId = uuidv4();
        apiRequestId.current = currentRequestId;
        setIsLoading(true);

        api.request('/admin/user_segments/simulate', 'POST', { filter: filtersTree, selectionType: filterSelectionType, ignoreNewsletterConsent, onlyParticipantOrFirstParent: targetDetails === 'participant_or_first_parent' }).then(res => {
            if (currentRequestId !== apiRequestId.current) return;

            setFilterSimulationResult(res);
            setIsLoading(false);
        });
    }, [filtersTree, filterSelectionType, ignoreNewsletterConsent, targetDetails]);

    useEffect(() => {
        if (isOpen) {
            setFiltersTree([]);
            setFilterSimulationResult({});
            setType('specific_users');
            setSelectionType('conditions');
            setTargetDetails('all_users');
            setEmailList([]);
            setDescription('');
            setAmount(0.0);
            setExpireDate(new Date().toISOString().split('T')[0]);
            setEmailTemplateId('');
            refreshTemplates();
        }
    }, [isOpen, refreshTemplates]);

    useEffect(() => {
        if (type !== 'specific_users') {
            setFiltersTree([]);
            setFilterSimulationResult({});
            setSelectionType('conditions');
            setEmailList([]);
        }
    }, [type]);

    useEffect(() => {
        if (filterSelectionType === 'users' && targetDetails === 'all_people') {
            setTargetDetails('all_users');
        }
    }, [filterSelectionType, targetDetails])

    useEffect(() => {
        if (selectionType === 'conditions') {
            setEmailList([]);
        } else if (selectionType === 'emails') {
            setFiltersTree([]);
            setFilterSimulationResult({});
        }
    }, [selectionType]);

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'secondary',
            onClick: () => onClose()
        },
        {
            children: 'Salva',
            color: 'primary',
            onClick: () => handleSave()
        },
    ];

    return (
        <>
            <CustomDialogWrapper open={isOpen} onClose={() => onClose()} title={'Crea nuovo voucher'} buttons={buttons} isLoading={isLoading} maxWidth='md' fullWidth >
                <div style={{ marginBottom: '24px' }}>
                    <Typography variant='h6' noWrap style={{ marginBottom: '6px' }}>
                        Informazioni generali
                    </Typography>

                    <StyledTextField
                        label={'Descrizione'}
                        variant='outlined'
                        type='email'
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        fullWidth
                    />

                    <CustomTextField
                        label='Importo voucher'
                        variant='outlined'
                        value={amount}
                        keepMounted={true}
                        onChange={(e: any) => {
                            setAmount(e.target.value);
                        }}
                        onBlur={() => {
                            setAmount((c: any) => {
                                return Math.max(((typeof c === 'string') ? parseFloat(c.replaceAll(',', '.')) : c), 0).toFixed(2);
                            });
                        }}
                        disabled={isLoading}
                    />

                    <MuiPickersUtilsProvider locale='it' utils={MomentUtils}>
                        <KeyboardDatePicker
                            label='Data scadenza'
                            inputVariant='outlined'
                            minDate={moment(Date.now() - 24 * 3600 * 1000).format('YYYY-MM-DD')}
                            maxDate={moment(Date.now() + 120 * 365 * 24 * 3600 * 1000).format('YYYY-MM-DD')}
                            format='DD/MM/YYYY'
                            value={expireDate}
                            onChange={(date: MaterialUiPickersDate) => {
                                setExpireDate(date?.format('YYYY-MM-DD') ?? '');
                            }}
                            open={isDatePickerOpen}
                            onClick={() => {
                                setIsDatePickerOpen(true);
                            }}
                            onClose={() => setIsDatePickerOpen(false)}
                            onKeyDown={(e) => {
                                e.preventDefault();
                                return false;
                            }}
                            style={{ flex: 1, marginBottom: '4px' }}
                            inputProps={{ tabIndex: -1 }}
                            KeyboardButtonProps={{ tabIndex: -1 }}
                            fullWidth
                        />
                    </MuiPickersUtilsProvider>
                </div>

                <div style={{ marginBottom: '24px' }}>
                    <Typography variant='h6' noWrap>
                        Tipologia
                    </Typography>
                    <FormControl component='fieldset'>
                        <RadioGroup aria-label='gender' name='type' value={type} onChange={handleTypeChange}>
                            <FormControlLabel value='specific_users' control={<Radio color={'primary'} />} label='Codici individuali' />
                            <FormControlLabel value='everyone' control={<Radio color={'primary'} />} label='Singolo codice generico' />
                            <FormControlLabel value='quote' control={<Radio color={'primary'} />} label='Voucher automatico preventivo' />
                        </RadioGroup>
                    </FormControl>
                </div>

                {type === 'specific_users' && (
                    <>
                        <div style={{ marginBottom: '24px' }}>
                            <Typography variant='h6' noWrap>
                                Selezione utenti
                            </Typography>
                            <FormControl component='fieldset'>
                                <RadioGroup aria-label='gender' name='user_selection_type' value={selectionType} onChange={handleSelectionTypeChange}>
                                    <FormControlLabel value='conditions' control={<Radio color={'primary'} />} label='Per condizione' />
                                    <FormControlLabel value='emails' control={<Radio color={'primary'} />} label='Singolarmente' />
                                </RadioGroup>
                            </FormControl>
                        </div>

                        <div style={{ marginBottom: '24px' }}>
                            <Typography variant='h6' noWrap>
                                Utenti selezionati
                            </Typography>

                            {selectionType === 'conditions' && (
                                <>
                                    {targetDetails === 'all_people' ? (
                                        <p>
                                            <b>{(filterSimulationResult?.countEntities ?? 0)}</b>
                                            {' '}person{((filterSimulationResult?.countEntities ?? 0)) === 1 ? 'a' : 'e'}
                                            {' '}selezionat{((filterSimulationResult?.countEntities ?? 0)) === 1 ? 'a' : 'e'}.
                                        </p>
                                    ) : (
                                        <p>
                                            <b>{(filterSimulationResult?.countUsers ?? 0)}</b>
                                            {' '}utent{((filterSimulationResult?.countUsers ?? 0)) === 1 ? 'e' : 'i'}
                                            {' '}selezionat{((filterSimulationResult?.countUsers ?? 0)) === 1 ? 'o' : 'i'}.
                                        </p>
                                    )}


                                    <Button onClick={() => setIsSelectorDialogOpen(true)} color={'primary'} variant={'outlined'}>
                                        Modifica selezione
                                    </Button>

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color='primary'
                                                style={{ padding: '4px' }}
                                                checked={ignoreNewsletterConsent || targetDetails === 'all_people'}
                                                onChange={(e) => {
                                                    setIgnoreNewsletterConsent(e.target.checked);
                                                }}
                                                disabled={isLoading || targetDetails === 'all_people'}
                                            />
                                        }
                                        style={{ display: 'block', margin: '14px 0 8px -6px' }}
                                        label={'Seleziona anche gli utenti che non hanno dato il consenso alla ricezione della newsletter'}
                                    />
                                </>
                            )}

                            {selectionType === 'emails' && (
                                <>
                                    <List component='nav' aria-label='main mailbox folders'>
                                        {emailList.map(({ email, name }: { email: string, name: string }) => {
                                            return (
                                                <ListItem>
                                                    <ListItemText primary={email} secondary={name} />

                                                    <ListItemSecondaryAction>
                                                        <IconButton onClick={() => removeEmail(email)} style={{ marginRight: '-14px' }}>
                                                            <RemoveCircleOutlineIcon />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>

                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                    <Button onClick={() => setIsPersonSelectDialogOpen(true)} color={'primary'} variant={'outlined'}>
                                        Aggiungi utente
                                    </Button>
                                </>
                            )}
                        </div>

                    </>
                )}

                {selectionType === 'conditions' && type === 'specific_users' && (
                    <>
                        <div style={{ marginBottom: '24px' }}>
                            <Typography variant='h6' noWrap>
                                Applica le condizioni intermedie
                            </Typography>

                            <RadioGroup name='target_details' value={filterSelectionType} onChange={handleFilterSelectionTypeChange}>
                                <FormControlLabel value='people' control={<Radio color={'primary'} />} label='sulle persone' />
                                <FormControlLabel value='users' control={<Radio color={'primary'} />} label='sugli utenti' />
                            </RadioGroup>
                        </div>
                        <div style={{ marginBottom: '24px' }}>
                            <Typography variant='h6' noWrap>
                                Politica di generazione
                            </Typography>

                            <div>
                                <RadioGroup name='target_details' value={targetDetails} onChange={handleTargetDetailsChange}>
                                    <FormControlLabel value='all_users' control={<Radio color={'primary'} />} label='un voucher per ogni utente (inclusi "genitore 2")' />
                                    <FormControlLabel value='participant_or_first_parent' control={<Radio color={'primary'} />} label='un voucher per ogni utente (solo per partecipante maggiorenne o "genitore 1")' />
                                    <FormControlLabel value='all_people' control={<Radio color={'primary'} />} label='un voucher per ogni persona/figlio (inviato al partecipante maggiorenne o al "genitore 1")' disabled={filterSelectionType === 'users'} />
                                </RadioGroup>
                            </div>
                        </div>
                    </>
                )}

                {type === 'everyone' && (
                    <div style={{ marginBottom: '24px' }}>
                        <Typography variant='h6' noWrap>
                            Codice voucher
                        </Typography>
                        <StyledTextField
                            label={'Codice voucher'}
                            variant='outlined'
                            type='email'
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            fullWidth
                            style={{ marginTop: '6px' }}
                        />
                    </div>
                )}

                {type !== 'everyone' && (
                    <div style={{ marginBottom: '24px' }}>
                        <Typography variant='h6' noWrap>
                            Template email
                        </Typography>

                        <CustomTextField
                            select
                            label={'Seleziona template'}
                            value={emailTemplateId}
                            onChange={(e: any) => {
                                setEmailTemplateId(e.target.value);
                            }}
                            variant='outlined'
                            fullWidth
                            style={{ marginTop: '6px' }}
                        >
                            {emailTemplates.map((opt: any) => (
                                <MenuItem key={opt.id} value={opt.id} style={{ whiteSpace: 'break-spaces' }}>
                                    {opt.description}
                                </MenuItem>
                            ))}

                            {emailTemplates.length === 0 && (
                                <MenuItem value={''} style={{ whiteSpace: 'break-spaces' }} disabled>
                                    Nessun template disponibile
                                </MenuItem>
                            )}
                        </CustomTextField>

                        <Button onClick={() => {
                            setTemplateIdForEdit(null);
                            setIsEmailTemplateDialogOpen(true);
                        }} color={'primary'} variant={'outlined'} style={{ marginRight: '6px' }}>
                            Crea template
                        </Button>

                        <Button onClick={() => {
                            setTemplateIdForEdit(emailTemplateId);
                            setIsEmailTemplateDialogOpen(true);
                        }} color={'primary'} variant={'outlined'} disabled={!emailTemplateId}>
                            Modifica template
                        </Button>
                    </div>
                )}

            </CustomDialogWrapper>

            <UserSegmentSelectorDialog
                isOpen={isSelectorDialogOpen}
                onClose={() => setIsSelectorDialogOpen(false)}
                filtersTree={filtersTree}
                setFiltersTree={setFiltersTree}
                selectionType={filterSelectionType}
                setSelectionType={setFilterSelectionType}
                simulationResult={filterSimulationResult}
            />

            <PersonSelectDialog open={isPersonSelectDialogOpen} closeDialog={() => setIsPersonSelectDialogOpen(false)} onPersonSelect={handlePersonClick} />

            <EmailTemplateDialog isOpen={isEmailTemplateDialogOpen} onClose={(newTemplateId?: string) => {
                setIsEmailTemplateDialogOpen(false);

                if (newTemplateId) {
                    refreshTemplates().then(() => {
                        setEmailTemplateId(newTemplateId);
                    });
                }
            }} templateId={templateIdForEdit} />
        </>
    );
};

export default NewVoucherDialog;
