import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import useGenericErrorDialog from '../../../../../../common/hooks/useGenericErrorDialog/useGenericErrorDialog';
import useInternalLoader from '../../../../../../common/hooks/useInternalLoader/useExternalLoader';
import api from '../../../../../../common/utils/api';
import CourseCard from '../../../../components/CourseCard/CourseCard';
import CategoryCard from '../CategoryCard/CategoryCard';

const NewCoursesPage = () => {
    const { categoryId: selectedCategory } = useParams<{ categoryId: string }>();
    const setIsLoading = useInternalLoader();

    const history = useHistory();

    const [coursesData, setCoursesData] = useState<any[]>([]);

    const showGenericErrorDialog = useGenericErrorDialog();

    useEffect(() => {
        api.request('/courses').then(res => {
            setCoursesData(res);
            setIsLoading(false);
        }).catch((res) => {
            showGenericErrorDialog(res);
        });
    }, [setIsLoading, showGenericErrorDialog]);

    const courseCategories = useMemo(() => {
        return coursesData.reduce((prev, current) => {
            const currentCategoryObj = {
                ...(prev.find((c: any) => c.category.id === current.category.id) || {
                    category: current.category,
                    courses: []
                })
            };

            currentCategoryObj.courses.push(current);
            return [...(prev.filter((c: any) => c.category.id !== current.category.id)), currentCategoryObj];
        }, [])
            .sort((c1: any, c2: any) => c2.category.createdAt.localeCompare(c1.category.createdAt))
            .sort((c1: any, c2: any) => c1.category.order - c2.category.order);
    }, [coursesData]);

    return (
        <>
            {!selectedCategory ? (
                <Typography variant='h2' style={{ fontSize: '3em' }}>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {courseCategories.map((categoryObj: any) => {
                            const { category } = categoryObj;
                            const { image } = category;

                            const imageUrl = image ? (process.env.REACT_APP_API_HOST + '/assets/' + image.id) : undefined;

                            return (
                                <>
                                    <CategoryCard id={category.id} name={category.name} description={category.description} image={imageUrl} />
                                </>
                            );
                        })}
                    </div>
                </Typography>
            ) : (
                <Typography variant='h2' style={{ fontSize: '3em' }}>
                    <Button style={{ margin: '0 auto' }} color='primary' variant='outlined' onClick={() => history.push('/courses?version=new')}>Torna indietro</Button>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {courseCategories.find((c: any) => c.category.id === selectedCategory)?.courses.map((course: any) => {
                            const { image } = course;

                            const imageUrl = image ? (process.env.REACT_APP_API_HOST + '/assets/' + image.id) : undefined;

                            return (
                                <>
                                    <CourseCard id={course.id} name={course.name} description={course.description} image={imageUrl} category={selectedCategory} />
                                </>
                            );
                        })}
                        {[0, 1, 2, 3, 4, 5, 6].map(idx => {
                            return (
                                <div key={idx} style={{ display: 'flex', flexDirection: 'column', flex: '1', margin: '8px', minWidth: '300px', visibility: 'hidden' }} />
                            );
                        })}
                    </div>
                </Typography>
            )}
        </>
    );
};

export default NewCoursesPage;
