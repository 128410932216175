import { useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import GenericObject from '../../../../../../../../../../typesAdditional/GenericObject';

interface Props {
    data: GenericObject;
    formPrefix: string;
    isLoading: boolean;
    rawOnChange: (field: string, value: any) => void;
    zips: any[];
    townLegalId: string;
}

const ZipAutocomplete = (props: Props) => {
    const { data, formPrefix, isLoading, rawOnChange, zips, townLegalId } = props;

    const [isOpen, setIsOpen] = useState(false);

    const [value, setValue] = useState('');

    const selectedZip = data[formPrefix]?.address?.zip ?? '';
    const setSelectedZip = (zip: string) => {
        rawOnChange(formPrefix + '.address.zip', zip);
    };

    const onChange = (_e: any, option?: any) => {
        setSelectedZip(option?.value ?? '');
    };

    const onInputChange = (e: any) => {
        if (!e) {
            return;
        } else if (e.target.value === undefined) {
            setSelectedZip('');
        }

        setValue(e.target.value === 0 ? '0' : e.target.value);
    };

    const options = zips.map((zip: any) => {
        return {
            name: zip.zip,
            value: zip.zip
        };
    });

    const selectedOption = options.find((t: any) => t.value === selectedZip) ?? null;

    return (
        <Autocomplete
            key={townLegalId}
            onChange={onChange}
            onInputChange={onInputChange}
            style={{flex:1, marginBottom: '12px'}}
            fullWidth
            open={isOpen}
            onOpen={() => {
                setIsOpen(true);
            }}
            onClose={() => {
                setIsOpen(false);
            }}
            getOptionSelected={(option1, option2) => option1.value === option2.value}
            getOptionLabel={(option) => option.name}
            options={options}
            loading={isLoading}
            clearText='Rimuovi'
            closeText='Chiudi'
            openText='Apri'
            loadingText='Caricamento...'
            noOptionsText={!value?.length ? 'Inserisci il CAP' : 'Nessun CAP trovato'}
            value={selectedOption}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label='CAP'
                    variant='outlined'
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {isLoading ? <CircularProgress color='inherit' size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
};

export default ZipAutocomplete;
