import { useCallback, useEffect, useState } from 'react';

import { ButtonProps } from '@material-ui/core/Button';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import CustomTextField from '../../../../../../common/components/CustomTextField/CustomTextField';
import api from '../../../../../../common/utils/api';
import snackbar from '../../../../../../common/utils/snackbar';

interface Props {
    open: boolean;
    closeDialog: (shouldRefresh?: boolean) => void;
    externalSelectedCourseId?: string | null;
}

const EditDuesDialog = (props: Props) => {
    const { open, closeDialog, externalSelectedCourseId } = props;

    const [isLoading, setIsLoading] = useState(false);

    const [expected, setExpected] = useState<string | number>('0.00');
    const [paid, setPaid] = useState<string | number>('0.00');

    useEffect(() => {
        if (!open) return;

        setExpected('0.00');
        setPaid('0.00');
    }, [open, externalSelectedCourseId])

    const refreshData = useCallback(() => {
        if (!externalSelectedCourseId) return;

        setIsLoading(true);
        api.request('/admin/winter_staff/school_course_dues/' + externalSelectedCourseId).then(res => {
            setExpected(res.tmpExpectedDues);
            setPaid(res.tmpPaidDues);
            setIsLoading(false);
        });
    }, [externalSelectedCourseId]);

    const saveData = useCallback(() => {
        setIsLoading(true);
        api.request('/admin/winter_staff/school_course_dues/' + externalSelectedCourseId, 'POST', { tmpExpectedDues: expected, tmpPaidDues: paid }).then(() => {
            snackbar.success('Quote salvate con successo!');
            closeDialog(true);
        }).catch(() => {
            //snackbar.error('Si è verificato un errore durante il salvataggio.');
        }).finally(() => {
            setIsLoading(false);
        });
    }, [closeDialog, externalSelectedCourseId, expected, paid])

    useEffect(() => {
        if (!open) return;
        refreshData();
    }, [open, refreshData]);

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        },
        {
            children: 'Salva',
            color: 'primary',
            onClick: () => saveData()
        }
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Quote scuole'} buttons={buttons} isLoading={isLoading} maxWidth='xs' fullWidth>
            <CustomTextField
                label={'Importo atteso'}
                variant='outlined'
                value={expected}
                keepMounted={true}
                onChange={(e: any) => {
                    setExpected(e.target.value);
                }}
                onBlur={() => {
                    setExpected((c: any) => {
                        return Math.max(((typeof c === 'string') ? parseFloat(c.replaceAll(',', '.')) : c), 0).toFixed(2);
                    });
                }}
                disabled={isLoading}
            />

            <CustomTextField
                label={'Importo pagato'}
                variant='outlined'
                value={paid}
                keepMounted={true}
                onChange={(e: any) => {
                    setPaid(e.target.value);
                }}
                onBlur={() => {
                    setPaid((c: any) => {
                        return Math.max(((typeof c === 'string') ? parseFloat(c.replaceAll(',', '.')) : c), 0).toFixed(2);
                    });
                }}
                disabled={isLoading}
            />
        </CustomDialogWrapper>
    );
};

export default EditDuesDialog;
