import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

interface GenericObject {
    [key: string]: string;
}

const useSearchParams = (): GenericObject => {
    const { search } = useLocation();

    const params = useMemo(() => {
        const searchParams = new URLSearchParams(search);

        const paramsObject: GenericObject = {};

        for (const param of searchParams.entries()) {
            const [key, value] = param;

            paramsObject[key] = value;
        }

        return paramsObject;
    }, [search]);

    return params;
};

export default useSearchParams;
