import { useCallback, useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import useInternalLoader from '../../../../common/hooks/useInternalLoader/useExternalLoader';
import api from '../../../../common/utils/api';
import EmailRecipientsDialog from './components/EmailRecipientsDialog/EmailRecipientsDialog';
import { Title } from './styled';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import EmailContentDialog from '../emailUserListPage/components/EmailContentDialog/EmailContentDialog';

const EmailsListPage = () => {
    const { width } = useWindowSize();
    const isMobile = width <= 700;

    const setIsLoading = useInternalLoader();
    const [data, setData] = useState<any[]>([]);

    const [isEmailRecipientsDialogOpen, setIsEmailRecipientsDialogOpen] = useState(false);
    const [isEmailContentDialogOpen, setIsEmailContentDialogOpen] = useState(false);
    const [selectedGroupId, setSelectedGroupId] = useState('');

    useEffect(() => {
        api.request('/admin/emails').then(res => {
            setData(res);
            setIsLoading(false);
        });
    }, [setIsLoading]);

    const [anchor, setAnchorEl] = useState<null | HTMLElement>(null);
    const [menuGroupId, setMenuGroupId] = useState<null | string>(null);

    const handleMenuClick = useCallback((event: React.MouseEvent<HTMLElement>, userId: string) => {
        setAnchorEl(event.currentTarget);
        setMenuGroupId(userId);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    return (
        <>
            <Title isMobile={isMobile}>
                Email inviate
            </Title>

            <TableContainer component={Paper}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Data</TableCell>
                            <TableCell>Oggetto</TableCell>
                            <TableCell>Inviata da</TableCell>
                            <TableCell>Email schedulate</TableCell>
                            <TableCell>Email inviate</TableCell>
                            <TableCell>Invii falliti</TableCell>
                            <TableCell>Email lette</TableCell>
                            <TableCell>Email con link visualizzati</TableCell>
                            <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>Azioni</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((email: any) => {
                            return (
                                <TableRow>
                                    <TableCell>{moment(email.createdAt).format('DD/MM/YYYY')}</TableCell>
                                    <TableCell>{email.subject}</TableCell>
                                    <TableCell>{email.superUser.name}</TableCell>
                                    <TableCell>{email.scheduledEmails}</TableCell>
                                    <TableCell>{email.sentEmails}</TableCell>
                                    <TableCell>{email.errorEmails}</TableCell>
                                    <TableCell>{email.readEmails}</TableCell>
                                    <TableCell>{email.openedEmails}</TableCell>
                                    <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>
                                        <IconButton onClick={(e: any) => handleMenuClick(e, email.id)} >
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            anchorEl={anchor}
                                            keepMounted
                                            open={!!anchor && menuGroupId === email.id}
                                            onClose={handleClose}
                                        >
                                            <MenuItem onClick={() => {
                                                setSelectedGroupId(email.id);
                                                setIsEmailContentDialogOpen(true);
                                                handleClose();
                                            }}>
                                                Visualizza messaggio
                                            </MenuItem>
                                            <MenuItem onClick={() => {
                                                setSelectedGroupId(email.id);
                                                setIsEmailRecipientsDialogOpen(true);
                                                handleClose();
                                            }}>
                                                Visualizza destinatari
                                            </MenuItem>
                                        </Menu>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <EmailRecipientsDialog
                isOpen={isEmailRecipientsDialogOpen}
                onClose={() => {
                    setIsEmailRecipientsDialogOpen(false);
                }}
                emailGroupId={selectedGroupId}
            />

            <EmailContentDialog
                isOpen={isEmailContentDialogOpen}
                onClose={() => {
                    setIsEmailContentDialogOpen(false);
                }}
                subject={data?.find(g => g.id === selectedGroupId)?.subject}
                content={data?.find(g => g.id === selectedGroupId)?.body}
            />
        </>
    );
};

export default EmailsListPage;
