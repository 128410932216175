import LinearProgress from '@material-ui/core/LinearProgress';

interface Props {
    progress: number;
}

const Header = ({ progress }: Props) => {
    return (
        <div style={{margin: '12px auto', maxWidth: '400px'}}>
            <LinearProgress variant='determinate' value={progress} />
        </div>
    );
};

export default Header;
