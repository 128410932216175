import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import * as Sentry from '@sentry/react';

import useInternalLoader from '../../../../common/hooks/useInternalLoader/useExternalLoader';
import useSearchParams from '../../../../common/hooks/useSearchParams/useSearchParams';
import api from '../../../../common/utils/api';
import { ReduxState } from '../../../../redux/types';
import GenericObject from '../../../../typesAdditional/GenericObject';
import PpsMsCard from './components/PpsMsCard/PpsMsCard';

const RegistrationPpsMs = () => {
    const { id: courseId } = useParams<{id: string}>();

    const { isIframe } = useSelector((state: ReduxState) => state.routing);
    const { town: paramTownLegalId } = useSearchParams();

    const setIsLoading = useInternalLoader();
    const history = useHistory();

    const [data, setData] = useState<GenericObject | null>(null);

    const iframeSuffix = isIframe ? '&iframe' : '';

    useEffect(() => {
        api.request('/courses/' + courseId + '/towns/' + paramTownLegalId + '/pps_ms_info').then(res => {
            setData(res);
        }).catch(() => {
            history.push('/');
        });
    }, [courseId, paramTownLegalId, history]);

    useEffect(() => {
        if (data === null) return;

        if (data.filter((d: any) => d.available).length === 0) {
            history.push('/');
            setIsLoading(isIframe);
        } else if (data.filter((d: any) => d.available).length === 1) { 
            history.push('/courses/' + (data.filter((d: any) => d.available)[0].id) + '/registration?town=' + paramTownLegalId + iframeSuffix);
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    }, [data, iframeSuffix, isIframe, courseId, history, paramTownLegalId, setIsLoading]);

    return (
        <div style={{display: 'flex', flexWrap: 'wrap'}}>
            {data && data.filter((d: any) => d.available).map((course: any) => {
                const name = course.name === 'PPS' ? 'PRONTI PARTENZA SPORT' : (
                    course.name === 'MS' ? 'MULTISPORT' : course.name
                );

                const description = course.maximumSchoolGrade === 3 ? 'Bimbi della scuola dell\'infanzia' : (
                    course.maximumSchoolGrade === 8 ? 'Bimbi della scuola primaria' : 'Bimbi della scuola primaria e ragazzi della scuola secondaria'
                );

                if (![3, 8, 11].includes(course.maximumSchoolGrade)) {
                    Sentry.captureMessage('Invalid maximumSchoolGrade for course ' + courseId + ' (' + course.id + ') and town ' + paramTownLegalId);
                }

                return (
                    <>
                        <PpsMsCard id={course.id} name={name} description={description} townId={paramTownLegalId} iframeSuffix={iframeSuffix} />
                    </>
                );
            })}
        </div>
    );
};

export default RegistrationPpsMs;
