import { useCallback, useEffect, useMemo, useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import { useIntersection } from 'use-intersection';

import CustomDialog from '../CustomDialog/CustomDialog';
import EntityListItem from './components/EntityListItem';
import { FlexPusher, LoaderContainer, SearchFieldContainer, StyledAvatar, StyledDialogContent, StyledTypography } from './styled';
import { Button } from '@material-ui/core';

export interface Entity {
    id: string;
    name: string;
    description?: string;
    image?: string | JSX.Element;
    disabled?: boolean;
    depth?: number;
    canDelete?: boolean;
    highlight?: boolean;
}

interface Props {
    open: boolean;
    title: string;
    size?: DialogProps['maxWidth'];
    data: Entity[];
    noEntityFoundMessage?: string;
    isLoading?: boolean;
    onClose: (selectedId?: string) => void;
    onDelete?: (selectedId: string) => void;
    searchQuery?: string;
    onSearch?: (query: string) => void;
    onLastItemReach?: () => void;
    newEntityBtnPosition?: 'footer' | 'list' | 'both';
    onNewEntityClick?: () => void;
    newEntityBtnText?: string;
    disableItemsClick?: boolean;
    additionalEntries?: Entity[];
    higlightItem?: string;
    onDiscardSelection?: () => void;
}

const EntitySelectDialog = (props: Props) => {
    const {
        open,
        title,
        size,
        data,
        noEntityFoundMessage,
        isLoading,
        onClose,
        onDelete,
        searchQuery,
        onSearch,
        onLastItemReach,
        newEntityBtnPosition,
        onNewEntityClick,
        newEntityBtnText,
        disableItemsClick,
        additionalEntries,
        higlightItem,
        onDiscardSelection
    } = props;

    const [lastItemRef, setLastItemRef] = useState(null);
    const lastItemReached = useIntersection(lastItemRef);

    const lastItemRefCallback = useCallback((node) => {
        if (node !== null) {
            setLastItemRef(node);
        }
    }, []);

    const finalData = useMemo(() => {
        if (data.find(x => x.id === higlightItem)) {
            return [
                {
                    ...data.find(x => x.id === higlightItem),
                    highlight: true
                },
                ...(data.filter(x => x.id !== higlightItem))
            ] as Entity[];
        }

        return data
    }, [data, higlightItem]);

    useEffect(() => {
        // when the last item is reached, trigger the event function, in order to load more data (lazy loading)
        if (lastItemReached && onLastItemReach) {
            onLastItemReach();
        }
    }, [lastItemReached, onLastItemReach]);

    return (
        <CustomDialog onClose={() => onClose()} open={open} maxWidth={size ?? 'xs'} fullWidth >
            <DialogTitle>
                {title}
            </DialogTitle>

            <StyledDialogContent dividers>
                {onSearch && <SearchFieldContainer>
                    {/* if the onSearch prop is passed, then show the search input box */}
                    <TextField
                        margin='dense'
                        variant='outlined'
                        color='primary'
                        label={'Cerca '}
                        value={searchQuery}
                        onChange={(e) => onSearch(e.target.value)}
                        fullWidth
                    />
                </SearchFieldContainer>}

                {!isLoading && finalData.length === 0 && (
                    // if the data is loaded but the array is empty, than show an error message
                    <StyledTypography variant='body1'>
                        {noEntityFoundMessage ?? 'Nessun entità trovata'}
                    </StyledTypography>
                )}

                <List>
                    {finalData.map((item, index) => {
                        const isLastItem = index === (finalData.length - 1);

                        // if the current item is the latest, pass the ref callback to it, in order to achieve lazy loading functionallity
                        const additionalProps = isLastItem ? {
                            ref: lastItemRefCallback
                        } : {};

                        return (
                            <EntityListItem
                                name={item.name}
                                description={item.description}
                                onClick={!disableItemsClick ? (() => onClose(item.id)) : undefined}
                                onDeleteClick={(onDelete && (item.canDelete === undefined || item.canDelete)) ? (() => onDelete(item.id)) : undefined}
                                image={item.image}
                                key={item.id}
                                disabled={item.disabled}
                                depth={item.depth}
                                style={item.highlight ? { backgroundColor: '#ffcb7f' } : {}}
                                {...additionalProps}
                            />
                        );
                    })}

                    {(additionalEntries ?? []).map((item, index) => {
                        return (
                            <EntityListItem
                                name={item.name}
                                description={item.description}
                                onClick={!disableItemsClick ? (() => onClose(item.id)) : undefined}
                                onDeleteClick={(onDelete && (item.canDelete === undefined || item.canDelete)) ? (() => onDelete(item.id)) : undefined}
                                image={item.image}
                                key={item.id}
                                disabled={item.disabled}
                                depth={item.depth}
                            />
                        );
                    })}

                    {(newEntityBtnPosition === 'list' || newEntityBtnPosition === 'both') && newEntityBtnText && onNewEntityClick && !isLoading && (
                        // if the needed props are passed, show the new entity button
                        <EntityListItem
                            name={newEntityBtnText}
                            onClick={onNewEntityClick}
                            image={(
                                <ListItemAvatar>
                                    <StyledAvatar>
                                        <AddIcon />
                                    </StyledAvatar>
                                </ListItemAvatar>
                            )}
                        />
                    )}
                </List>

                {isLoading && (
                    // if the data is being loaded, show the progress spinner
                    <LoaderContainer>
                        <CircularProgress />
                    </LoaderContainer>
                )}
            </StyledDialogContent>

            <DialogActions>
                {(newEntityBtnPosition === 'footer' || newEntityBtnPosition === 'both') && newEntityBtnText && onNewEntityClick && (
                    // as above, but the new entity button is shown in the dialog footer if newEntityBtnPosition === 'footer'
                    <>
                        <Button onClick={onNewEntityClick} color='primary'>
                            {newEntityBtnText}
                        </Button>
                        <FlexPusher />
                    </>
                )}

                {onDiscardSelection ? (
                    <Button onClick={() => {
                        onDiscardSelection();
                    }} color='primary'>
                        Annulla selezione
                    </Button>
                ) : (
                    <Button onClick={() => onClose()} color='primary'>
                        Chiudi
                    </Button>
                )}


            </DialogActions>
        </CustomDialog>
    );
};

export default EntitySelectDialog;
