import { useEffect, useState } from 'react';

import { ButtonProps } from '@material-ui/core/Button';
import CustomDialogWrapper from '../../../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import { MenuItem, Typography } from '@material-ui/core';
import { StyledTextField } from '../../../../../../../../common/components/CustomTextField/styled';

interface Props {
    open: boolean;
    closeDialog: (shouldRefresh?: boolean) => void;
    saveData: (action: string) => void;
}

const SelectActionDialog = (props: Props) => {
    const { open, closeDialog, saveData } = props;

    const [action, setAction] = useState('none');

    useEffect(() => {
        if (!open) return;
        setAction('none');
    }, [open]);

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        },
        {
            children: 'Salva',
            color: 'primary',
            onClick: () => {
                saveData(action);
            }
        }
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Modifica disponibilità'} buttons={buttons} maxWidth='sm' fullWidth>
            <Typography variant='body1' style={{ marginBottom: '16px' }}>
                L'istruttore ha dei turni assegnati per alcuni giorni rimossi dalle disponibilità.
            </Typography>

            <StyledTextField
                label={'Azione'}
                variant='outlined'
                style={{ marginTop: '10px', marginBottom: '4px' }}
                value={action}
                onChange={(e: any) => {
                    setAction(e.target.value);
                }}
                fullWidth
                select
            >
                <MenuItem key={'none'} value={'none'}>
                    mantieni nei turni le disponibilità rimosse
                </MenuItem>
                <MenuItem key={'delete_no'} value={'delete_no'}>
                    rimuovi dai turni le disponiblità modificate in "no"
                </MenuItem>
                <MenuItem key={'delete_no_uncertain'} value={'delete_no_uncertain'}>
                    rimuovi dai turni le disponiblità modificate in "no" e in "forse"
                </MenuItem>
            </StyledTextField>

        </CustomDialogWrapper >
    );
};

export default SelectActionDialog; 