import { useCallback, useEffect, useState } from 'react';

import { ButtonProps } from '@material-ui/core/Button';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import { StyledTextField } from '../../../../../../common/components/CustomTextField/styled';
import api from '../../../../../../common/utils/api';
import snackbar from '../../../../../../common/utils/snackbar';
import ApiEntitySelectDialog from '../../../../../../common/components/ApiEntitySelectDialog/ApiEntitySelectDialog';
import { Checkbox, FormControlLabel, MenuItem } from '@material-ui/core';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import 'moment/locale/it';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { DateType } from '@date-io/type';
import { useMessageDialog } from '../../../../../../common/hooks/useMessageDialog/useMessageDialog';

interface Props {
    open: boolean;
    closeDialog: (reloadData?: boolean, superUserId?: string) => void;
    sessionId?: string;
}

const SelectFacilityDialog = (props: Props) => {
    const { open, closeDialog, sessionId } = props;

    const [isLoading, setIsLoading] = useState(false);

    const [isEntitySelectDialogOpen, setIsEntitySelectDialogOpen] = useState(false);

    const [selectedFacilityId, setSelectedFacilityId] = useState<string | undefined>(undefined);
    const [selectedFacilityData, setSelectedFacilityData] = useState<any>({});

    const [isStartTimePickerOpen, setIsStartTimePickerOpen] = useState(false);
    const [isEndTimePickerOpen, setIsEndTimePickerOpen] = useState(false);

    const [startTime, setStartTime] = useState<DateType | undefined>(undefined);
    const [endTime, setEndTime] = useState<DateType | undefined>(undefined);
    const [defaultStartTime, setDefaultStartTime] = useState<DateType | undefined>(undefined);
    const [defaultEndTime, setDefaultEndTime] = useState<DateType | undefined>(undefined);
    const [shouldOverrideTime, setShouldOverrideTime] = useState(false);
    const [facilityExists, setFacilityExists] = useState(false);

    const [showMessageDialog, closeMessageDialog] = useMessageDialog();

    useEffect(() => {
        if (!selectedFacilityId) return;

        api.request('/admin/winter_staff/facilities/' + selectedFacilityId).then((res: any) => {
            setSelectedFacilityData(res);
        })
    }, [selectedFacilityId]);

    useEffect(() => {
        if (!open || !sessionId) return;

        setSelectedFacilityData(undefined);
        setSelectedFacilityId(undefined);
        setStartTime(undefined);
        setEndTime(undefined);

        setIsLoading(true);

        api.request('/admin/winter_staff/schedule/course_sessions/' + sessionId + '/facility').then((res) => {
            setSelectedFacilityId(res.facilityId);
            setStartTime(moment('1970-01-01 ' + res.startTime + ':00'));
            setEndTime(moment('1970-01-01 ' + res.endTime + ':00'));
            setDefaultStartTime(moment('1970-01-01 ' + res.courseStartTime + ':00'));
            setDefaultEndTime(moment('1970-01-01 ' + res.courseEndTime + ':00'));
            setShouldOverrideTime(!res.useDefaultTime);
            setFacilityExists(!!res.facilityId);
            console.log(!!res.facilityId);
            setIsLoading(false);
        });
    }, [open, sessionId])

    const handleSubmit = useCallback(() => {
        setIsLoading(true);

        api.request('/admin/winter_staff/schedule/course_sessions/' + sessionId + '/facility', 'POST', { facilityId: selectedFacilityId, startTime: startTime?.format('HH:mm'), endTime: endTime?.format('HH:mm'), useDefaultTime: !shouldOverrideTime }).then((res) => {
            snackbar.success('Palestra salvata con successo!');
            closeDialog(true, res.id);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [closeDialog, sessionId, selectedFacilityId, startTime, endTime, shouldOverrideTime]);

    const removeFacility = useCallback(() => {
        showMessageDialog({
            title: 'Rimuovi palestra',
            message: (
                <>
                    <p style={{ margin: '0px' }}>
                        Sei sicuro di voler rimuovere la palestra selezionata dal corso?
                    </p>
                </>
            ),
            actions: [
                {
                    text: 'Annulla',
                    action: () => {
                        closeMessageDialog();
                    }
                },
                {
                    text: 'Conferma',
                    action: () => {
                        closeMessageDialog();

                        setIsLoading(true);

                        api.request('/admin/winter_staff/schedule/course_sessions/' + sessionId + '/facility', 'DELETE').then(res => {
                            snackbar.success('Palestra rimossa con successo!');
                            closeDialog(true);
                        }).finally(() => {
                            setIsLoading(false);
                        });
                    }
                }
            ]
        });
    }, [closeMessageDialog, showMessageDialog, sessionId, closeDialog]);

    const mapFacilities = useCallback((rawData: any) => {
        return {
            id: rawData.id,
            name: rawData.name,
            description: rawData.town.name
        };
    }, []);

    const buttons: ButtonProps[] = [
        ...(facilityExists ? [{
            children: 'Elimina',
            color: 'secondary',
            onClick: () => removeFacility()
        } as ButtonProps] : [{
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        } as ButtonProps]),
        {
            children: 'Salva',
            color: 'primary',
            onClick: () => handleSubmit()
        },
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Assegna palestra'} buttons={buttons} isLoading={isLoading} maxWidth='sm' fullWidth>
            <div style={{ display: 'flex' }}>
                <StyledTextField
                    label={'Palestra'}
                    variant='outlined'
                    value={selectedFacilityId ? 'dummy' : ''}
                    style={{ marginRight: '6px' }}
                    fullWidth
                    disabled={isLoading}
                    SelectProps={{
                        open: false,
                        onOpen: () => setIsEntitySelectDialogOpen(true)
                    }}
                    select
                >
                    <MenuItem value={'dummy'} style={{ whiteSpace: 'break-spaces' }}>
                        {(selectedFacilityData && selectedFacilityData.id === selectedFacilityId) ? (selectedFacilityData.name + ' - ' + selectedFacilityData.town?.name) : ''}
                    </MenuItem>
                </StyledTextField>
            </div>

            <FormControlLabel
                control={
                    <Checkbox
                        color='primary'
                        style={{ padding: '4px' }}
                        checked={shouldOverrideTime}
                        onChange={(e) => {
                            setShouldOverrideTime(e.target.checked);
                        }}
                    />
                }
                style={{ marginTop: '8px', marginLeft: '-4px', marginBottom: '16px' }}
                label={'Modifica orario utilizzo palestra'}
                disabled={isLoading}
            />

            <div style={{ display: 'flex', marginTop: '4px', marginBottom: '8px' }}>
                <MuiPickersUtilsProvider locale='it' utils={MomentUtils}>
                    <KeyboardTimePicker
                        label='Ora inizio'
                        inputVariant='outlined'
                        value={shouldOverrideTime ? startTime : defaultStartTime}
                        onChange={(newDate: MaterialUiPickersDate) => {
                            if (newDate) setStartTime(newDate);
                        }}
                        open={isStartTimePickerOpen}
                        onClick={() => {
                            setIsStartTimePickerOpen(true);
                        }}
                        onClose={() => setIsStartTimePickerOpen(false)}
                        onKeyDown={(e) => {
                            e.preventDefault();
                            return false;
                        }}
                        style={{ flex: 1, marginTop: '2px', marginBottom: '4px', marginRight: '6px' }}
                        inputProps={{ tabIndex: -1 }}
                        KeyboardButtonProps={{ tabIndex: -1 }}
                        fullWidth
                        ampm={false}
                        disabled={isLoading || !shouldOverrideTime}
                    />
                </MuiPickersUtilsProvider>

                <MuiPickersUtilsProvider locale='it' utils={MomentUtils}>
                    <KeyboardTimePicker
                        label='Ora fine'
                        inputVariant='outlined'
                        value={shouldOverrideTime ? endTime : defaultEndTime}
                        onChange={(newDate: MaterialUiPickersDate) => {
                            if (newDate) setEndTime(newDate);
                        }}
                        open={isEndTimePickerOpen}
                        onClick={() => {
                            setIsEndTimePickerOpen(true);
                        }}
                        onClose={() => setIsEndTimePickerOpen(false)}
                        onKeyDown={(e) => {
                            e.preventDefault();
                            return false;
                        }}
                        style={{ flex: 1, marginTop: '2px', marginBottom: '4px', marginLeft: '6px' }}
                        inputProps={{ tabIndex: -1 }}
                        KeyboardButtonProps={{ tabIndex: -1 }}
                        fullWidth
                        ampm={false}
                        disabled={isLoading || !shouldOverrideTime}
                    />
                </MuiPickersUtilsProvider>
            </div   >

            <ApiEntitySelectDialog
                open={isEntitySelectDialogOpen}
                onClose={(selectedId?: string) => {
                    setIsEntitySelectDialogOpen(false);

                    if (selectedId) setSelectedFacilityId(selectedId);
                }}
                title='Seleziona palestra'
                url={'/admin/winter_staff/facilities'}
                map={mapFacilities}
                noEntityFoundMessage='Nessuna palestra trovata'
                disableSearch
            />
        </CustomDialogWrapper >
    );
};

export default SelectFacilityDialog; 
