import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useWindowSize } from 'react-use';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import { useMessageDialog } from '../../../../common/hooks/useMessageDialog/useMessageDialog';
import api from '../../../../common/utils/api';
import snackbar from '../../../../common/utils/snackbar';
import SimulateConfirmDialog from './components/SimulateConfirmDialog/SimulateConfirmDialog';
import { Title } from './styled';
import FileUploader from './components/FileUploader/FileUploader';
import { Asset } from './types';
import GenericObject from '../../../../typesAdditional/GenericObject';
import SelectGroupsDialog from './components/SelectGroupsDialog/SelectGroupsDialog';
import TinyEditor from '../newEmailPage/components/TinyEditor/TinyEditor';
import CustomTextField from '../../../../common/components/CustomTextField/CustomTextField';

const NewWhatsappMessagePage = () => {
    const { width } = useWindowSize();
    const isMobile = width <= 700;

    const [subject, setSubject] = useState('');
    const [availableGroups, setAvailableGroups] = useState<any[]>([]);
    const [selectedGroups, setSelectedGroups] = useState<any[]>([]);
    const [isSelectGroupsDialogOpen, setIsSelectGroupsDialogOpen] = useState(false);

    const [isSimulateConfirmDialogOpen, setIsSimulateConfirmDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [documentAssets, setDocumentAssets] = useState<GenericObject>({});

    const [showMessageDialog, closeMessageDialog] = useMessageDialog();

    const assetId = useMemo(() => {
        return (documentAssets.dummy && documentAssets.dummy[0] && documentAssets.dummy[0].status === 'uploaded') ? documentAssets.dummy[0].id : null;
    }, [documentAssets])

    const editorRef = useRef<any>(null);

    const setEditorRef = useCallback((ref: any) => {
        editorRef.current = ref;
    }, [editorRef]);

    useEffect(() => {
        api.request('/admin/whatsapp/groups').then(res => {
            setAvailableGroups(res);
        })
    }, [])

    const schedulateMessage = useCallback(() => {
        setIsLoading(true);
        api.request('/admin/whatsapp/schedulate', 'POST', { message: editorRef.current.current.getContent(), assetId, selectedGroups, subject }).then(res => {
            snackbar.success('Messaggio inviato correttamente!');
            setSelectedGroups([]);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [assetId, selectedGroups, editorRef, subject]);

    const schedulateMessageAskConfirm = useCallback(() => {
        showMessageDialog({
            title: 'Invia messaggio',
            message: (
                <>
                    <p style={{ marginTop: '0px' }}>
                        Sei sicuro di volere inviare il messaggio a <b>{selectedGroups.length} grupp{selectedGroups.length === 1 ? 'o' : 'i'}</b>?
                    </p>
                    <p style={{ marginBottom: '0px' }}>
                        L'operazione non è reversibile. L'invio potrebbe richiedere alcuni minuti.
                    </p>
                </>
            ),
            actions: [
                {
                    text: 'Annulla',
                    action: () => {
                        closeMessageDialog();
                    }
                },
                {
                    text: 'Conferma',
                    action: () => {
                        closeMessageDialog();
                        schedulateMessage();
                    }
                }
            ]
        });
    }, [closeMessageDialog, showMessageDialog, schedulateMessage, selectedGroups]);

    const handleAssetChange = useCallback((documentId: string, assets: Asset[]) => {
        setDocumentAssets(currentState => {
            return {
                ...currentState,
                [documentId]: assets
            };
        });
    }, [setDocumentAssets]);

    const groupsForList = availableGroups.reduce((prev, curr) => {
        if (curr.type === 'single') {
            return [...prev, { id: curr.id, name: curr.name }];
        } else if (curr.type === 'group') {
            return [...prev, ...(curr.items.map((x: any) => { return { id: x.id, name: curr.name + ' ' + x.name } }))];
        } else {
            return [];
        }
    }, []).filter((x: any) => selectedGroups.includes(x.id));

    return (
        <>
            <Title isMobile={isMobile}>
                Invio nuovo messaggio WhatsApp
            </Title>

            <CustomTextField
                label={'Oggetto del messaggio (visibile solo internamente)'}
                variant='outlined'
                value={subject}
                keepMounted={true}
                onChange={(e: any) => {
                    setSubject(e.target.value);
                }}
            />

            <div style={{ display: width > 1200 ? 'flex' : 'block', marginBottom: '22px' }}>
                <Card style={{ flex: '4', maxWidth: '1400px', marginTop: '12px', marginRight: (width > 1200 ? '8px' : undefined) }}>
                    <CardContent>
                        <Typography variant='h5' component='h2' style={{ marginBottom: '12px' }}>
                            Testo del messaggio
                        </Typography>

                        <TinyEditor setEditorRef={setEditorRef} useWhatsappStyle />
                    </CardContent>
                </Card>

                <div style={{ flex: '3', marginTop: '12px', marginLeft: (width > 1200 ? '8px' : undefined) }}>
                    <Card style={{ height: '100%', minHeight: (width <= 1200 ? '400px' : undefined) }}>
                        <CardContent style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                            <Typography variant='h5' component='h2' style={{ flex: 1, flexGrow: 0, marginBottom: '12px' }}>
                                Allegato
                            </Typography>

                            <FileUploader initialAssets={[]} documentId={'dummy'} status={'missing'} savedAssets={[]} handleAssetChange={handleAssetChange} isAdmin={false} />
                        </CardContent>
                    </Card>
                </div>

                <div style={{ flex: '3', marginTop: '12px', marginLeft: (width > 1200 ? '8px' : undefined) }}>
                    <Card style={{ height: '100%', maxHeight: '400px' }}>
                        <CardContent style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                            <Typography variant='h5' component='h2' style={{ marginBottom: '12px', flex: 1, flexGrow: 0 }}>
                                Gruppi destinatari
                            </Typography>

                            <List style={{ flex: 1, flexGrow: 1, overflow: 'auto', marginBottom: '16px' }}>
                                {groupsForList.map((group: any, index: number) => {
                                    return (
                                        <>
                                            <ListItemText primary={group.name} />

                                            {(index + 1) !== groupsForList.length && (
                                                <Divider component='li' />
                                            )}
                                        </>
                                    );
                                })}
                            </List>

                            <div style={{ flex: 1, flexGrow: 0 }}>
                                <Button color='primary' variant='outlined' component='span' disabled={isLoading} onClick={() => setIsSelectGroupsDialogOpen(true)} >
                                    Scegli gruppi
                                </Button>
                            </div>

                        </CardContent>
                    </Card>
                </div>

            </div>

            <Button variant='outlined' disabled={isLoading} color='primary' onClick={() => setIsSimulateConfirmDialogOpen(true)}>
                Simula invio messaggio
            </Button>

            <Button style={{ marginLeft: '8px' }} variant='contained' color='primary' onClick={schedulateMessageAskConfirm} disabled={isLoading || selectedGroups.length === 0}>
                Invia messaggio a {selectedGroups.length} grupp{selectedGroups.length === 1 ? 'o' : 'i'}
            </Button>

            <SimulateConfirmDialog
                open={isSimulateConfirmDialogOpen}
                closeDialog={() => setIsSimulateConfirmDialogOpen(false)}
                editorRef={editorRef}
                assetId={assetId}
            />

            <SelectGroupsDialog
                open={isSelectGroupsDialogOpen}
                closeDialog={() => setIsSelectGroupsDialogOpen(false)}
                availableGroups={availableGroups}
                selectedGroups={selectedGroups}
                setSelectedGroups={setSelectedGroups}
            />
        </>
    );
};

export default NewWhatsappMessagePage;
