import { useCallback, useEffect, useRef, useState } from 'react';

import { ButtonProps } from '@material-ui/core/Button';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import CustomTextField from '../../../../../../common/components/CustomTextField/CustomTextField';
import api from '../../../../../../common/utils/api';
import snackbar from '../../../../../../common/utils/snackbar';
import TinyEditor from '../../../newEmailPage/components/TinyEditor/TinyEditor';

interface Props {
    isOpen: boolean;
    onClose: (templateId?: string) => void;
    templateId: null | string;
}

const EmailTemplateDialog = (props: Props) => {
    const { isOpen, onClose, templateId } = props;

    const [isLoadingInitial, setIsLoadingInitial] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [data, setData] = useState<any>({});

    const editorRef = useRef<any>(null);

    const setEditorRef = useCallback((ref: any) => {
        editorRef.current = ref;
    }, [editorRef]);

    useEffect(() => {
        if (isOpen) {
            if (templateId) {
                setIsLoading(true);
                setIsLoadingInitial(true);
                api.request('/admin/vouchers/email_templates/' + templateId).then(res => {
                    setData(res);
                    setIsLoading(false);
                    setIsLoadingInitial(false);

                    const interval = setInterval(() => {
                        if (editorRef.current && editorRef.current.current) {
                            clearInterval(interval);
                            editorRef.current.current.setContent(res.message);
                        }
                    }, 10);

                    setTimeout(() => {
                        clearInterval(interval);
                    }, 10000);
                });
            } else {
                setData({});
                setIsLoading(false);
                setIsLoadingInitial(false);

                const interval = setInterval(() => {
                    if (editorRef.current && editorRef.current.current) {
                        clearInterval(interval);
                        editorRef.current.current.setContent('');
                    }
                }, 10);

                setTimeout(() => {
                    clearInterval(interval);
                }, 10000);
            }
        } else {
            setEditorRef(null);
        }
    }, [setEditorRef, editorRef, isOpen, templateId]);

    const handleSave = useCallback(() => {
        setIsLoading(true);
        const newData = { ...data, message: editorRef.current.current.getContent() };
        if (templateId) {
            api.request('/admin/vouchers/email_templates/' + templateId, 'PUT', newData).then(res => {
                snackbar.success('Template salvato con successo!');
                onClose(res.id);
            }).finally(() => {
                setIsLoading(false);
            });
        } else {
            api.request('/admin/vouchers/email_templates', 'POST', newData).then(res => {
                snackbar.success('Template salvato con successo!');
                onClose(res.id);
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }, [onClose, templateId, data, editorRef]);

    const buttons: ButtonProps[] = [
        {
            children: 'Annulla',
            color: 'secondary',
            onClick: () => onClose()
        },
        {
            children: 'Salva',
            color: 'primary',
            onClick: () => handleSave()
        },
    ];

    return (
        <>
            <CustomDialogWrapper open={isOpen} onClose={() => onClose()} title={'Template email'} buttons={buttons} isLoading={isLoading} maxWidth='md' fullWidth >
                {!isLoadingInitial ? (
                    <div>
                        <CustomTextField
                            label={'Descrizione (a uso interno)'}
                            variant='outlined'
                            value={data.description}
                            keepMounted={true}
                            onChange={(e: any) => {
                                setData((d: any) => ({...d, description: e.target.value}));
                            }}
                            disabled={isLoading}
                        />

                        <CustomTextField
                            label={'Oggetto email (pubblico)'}
                            variant='outlined'
                            value={data.subject}
                            keepMounted={true}
                            onChange={(e: any) => {
                                setData((d: any) => ({...d, subject: e.target.value}));
                            }}
                            disabled={isLoading}
                        />
                    </div>
                ) : (
                    <div style={{height: '136px'}} />
                )}



                {isOpen && (
                    <TinyEditor setEditorRef={setEditorRef} />
                )}
            </CustomDialogWrapper>
        </>
    );
};

export default EmailTemplateDialog;
