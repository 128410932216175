import { useState } from 'react';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import MUITable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import GenericObject from '../../../../../../typesAdditional/GenericObject';
import DocumentsDetailDialog from '../DocumentsDetailDialog/DocumentsDetailDialog';

interface Props {
    documentsList: GenericObject[];
    refreshData: () => void;
}

const Table = (props: Props) => {
    const { documentsList, refreshData } = props;

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<GenericObject>({});

    return (
        <>
            <TableContainer component={Paper}>
                <MUITable>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nome</TableCell>
                            <TableCell>Cognome</TableCell>
                            <TableCell>Codice fiscale</TableCell>
                            <TableCell>Stato</TableCell>
                            <TableCell>Dettagli</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {documentsList.map(row => {
                            const { person, documents} = row;
                            const { name, surname, taxCode } = person;

                            const countMissing = documents.filter((document: any) => document.status === 'expiring' || document.status === 'missing').length;

                            return (
                                <TableRow>
                                    <TableCell>{name}</TableCell>
                                    <TableCell>{surname}</TableCell>
                                    <TableCell>{taxCode}</TableCell>
                                    <TableCell>{countMissing} {countMissing === 1 ? 'documento' : 'documenti'} da caricare</TableCell>
                                    <TableCell style={{width: '1px'}}>
                                        <Button onClick={() => {
                                            setSelectedRow(row);
                                            setIsDialogOpen(true);
                                        }} color={'primary'} variant='outlined' style={{whiteSpace: 'nowrap'}}>
                                            Visualizza dettagli
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </MUITable>
            </TableContainer>

            <DocumentsDetailDialog open={isDialogOpen} closeDialog={() => {
                setIsDialogOpen(false);
                refreshData();
            }} selectedRow={selectedRow} />
        </>
    );
};

export default Table;
