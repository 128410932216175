import { useCallback } from 'react';

import moment from 'moment';

interface Params {
    sessionType: string;
}

const useCompareSessionDate = (params: Params) => {
    const { sessionType } = params;

    return useCallback((s1: any, s2: any) => {
        if (sessionType === 'weekly_events') {
            return moment(s1.startTime).isoWeekday() - moment(s2.startTime).isoWeekday();
        } else {
            return s1.startTime < s2.startTime ? -1 : +(s1.startTime > s2.startTime);
        }
    }, [sessionType]);

};

export default useCompareSessionDate;
