import { useMemo, useState, Fragment } from 'react';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import MUITable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import GenericObject from '../../../../../../typesAdditional/GenericObject';
import DetailsDialog from '../DetailsDialog/DetailsDialog';
import ReceiptRequestDialog from '../ReceiptRequestDialog/ReceiptRequestDialog';
import ReceiptsListDialog from '../ReceiptsListDialog/ReceiptsListDialog';

interface Props {
    registrations: GenericObject[];
    refreshRegistrations: () => void;
}

const Table = (props: Props) => {
    const { registrations, refreshRegistrations } = props;

    const [selectedRegistrationId, setSelectedRegistrationId] = useState<string | undefined>();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isReceiptRequestDialogOpen, setIsReceiptRequestDialogOpen] = useState(false);
    const [isReceiptsListDialoOpen, setIsReceiptsListDialoOpen] = useState(false);
    const [dialogReceiptsList, setDialogReceiptsList] = useState<any[]>([]);

    const showDetails = (registration: any) => {
        setSelectedRegistrationId(registration.id);
        setIsDialogOpen(true);
    };

    const showReceiptRequestDialog = (registration: any) => {
        setSelectedRegistrationId(registration.id);
        setIsReceiptRequestDialogOpen(true);
    };

    const showReceiptsListDialog = (registration: any) => {
        setDialogReceiptsList(registration.receipts);
        setIsReceiptsListDialoOpen(true);
    };

    const registrationsGroupedBySeason = useMemo(() => {
        return (registrations as any).reduce((prev: any[], curr: any) => {
            const currentSeason = curr.course.season;
            const { id, name, year } = currentSeason;

            const currentGroup = prev.find((p: any) => p.id === id) ?? {
                id, name, year, registrations: []
            }

            currentGroup.registrations.push(curr);

            const final = prev.filter((p: any) => p.id !== id);
            final.push(currentGroup);

            return final;
        }, [] as any[]).sort((a: any, b: any) => {
            if (a.year !== b.year) {
                return a.year - b.year;
            }

            const aValue = a.name.toLowerCase().includes('estiva') ? 1 : 0;
            const bValue = b.name.toLowerCase().includes('estiva') ? 1 : 0;

            return aValue - bValue;
        });
    }, [registrations]);

    return (
        <>
            <TableContainer component={Paper}>
                <MUITable>
                    <TableHead>
                        <TableRow>
                            <TableCell>Partecipante</TableCell>
                            <TableCell>Corso</TableCell>
                            <TableCell>Paese</TableCell>
                            <TableCell>Costo</TableCell>
                            <TableCell>Stato</TableCell>
                            <TableCell>Ricevuta</TableCell>
                            <TableCell>Dettagli</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {registrationsGroupedBySeason.map((registrationGroup: any, idx: number) => {
                            return (
                                <Fragment key={registrationGroup.id}>
                                    {idx > 0 && (
                                        <TableRow>
                                            <TableCell colSpan={7} style={{ height: '32px', backgroundColor: '#fafafa' }}> </TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow>
                                        <TableCell colSpan={7} style={{ backgroundColor: '#d5e5d6', textAlign: 'center' }}>
                                            <b>Stagione {registrationGroup.name}</b>
                                        </TableCell>
                                    </TableRow>
                                    {registrationGroup.registrations.map((registration: any) => {
                                        const { person, course, courseLocation, cost, paid } = registration;
                                        const { name, surname } = person;

                                        const paidStatus = parseFloat(cost) === parseFloat(paid) ? 'pagato' : (
                                            parseFloat(paid) === 0 ? 'da pagare' : 'pagato parzialmente'
                                        );

                                        return (
                                            <TableRow>
                                                <TableCell>{name} {surname}</TableCell>
                                                <TableCell>{course.name}</TableCell>
                                                <TableCell>{courseLocation.townName}</TableCell>
                                                <TableCell>€{parseFloat(cost).toFixed(2)}</TableCell>
                                                <TableCell>{paidStatus}</TableCell>
                                                <TableCell style={{ width: '1px', textAlign: 'center' }}>
                                                    {registration.receiptStatus !== 'full' && (
                                                        <Button onClick={() => showReceiptRequestDialog(registration)} color={'primary'} variant='outlined' style={{ whiteSpace: 'nowrap' }} disabled={paidStatus === 'da pagare'}>
                                                            Richiedi ricevuta
                                                        </Button>
                                                    )}

                                                    {registration.receiptStatus !== 'zero' && (
                                                        <Button onClick={() => showReceiptsListDialog(registration)} color={'primary'} variant='outlined' style={{ whiteSpace: 'nowrap', marginTop: registration.receiptStatus !== 'full' ? '6px' : '0' }} disabled={paidStatus === 'da pagare' || !cost}>
                                                            Visualizza ricevut{registration.receipts.length === 1 ? 'a' : 'e'}
                                                        </Button>
                                                    )}
                                                </TableCell>
                                                <TableCell style={{ width: '1px' }}>
                                                    <Button onClick={() => showDetails(registration)} color={'primary'} variant='outlined' style={{ whiteSpace: 'nowrap' }}>
                                                        Visualizza dettagli
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </Fragment>
                            )
                        })}

                    </TableBody>
                </MUITable>
            </TableContainer>

            <DetailsDialog isOpen={isDialogOpen} onClose={() => setIsDialogOpen(false)} selectedRegistrationId={selectedRegistrationId} baseUrl={'/registrations'} refreshRegistrations={refreshRegistrations} />
            <ReceiptRequestDialog isOpen={isReceiptRequestDialogOpen} onClose={() => setIsReceiptRequestDialogOpen(false)} selectedRegistrationId={selectedRegistrationId} />
            <ReceiptsListDialog isOpen={isReceiptsListDialoOpen} onClose={() => setIsReceiptsListDialoOpen(false)} receipts={dialogReceiptsList} />
        </>
    );
};

export default Table;
