import { useCallback, useEffect, useMemo, useState } from 'react';

import MomentUtils from '@date-io/moment';
import Button, { ButtonProps } from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import 'moment/locale/it';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import CustomTextField from '../../../../../../common/components/CustomTextField/CustomTextField';
import api from '../../../../../../common/utils/api';
import snackbar from '../../../../../../common/utils/snackbar';
import EmailTemplateDialog from '../../../vouchersPage/components/EmailTemplateDialog/EmailTemplateDialog';
import GenericObject from '../../../../../../typesAdditional/GenericObject';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    updateData: () => void;
    registrationData?: GenericObject;
}

const NewVoucherDialog = (props: Props) => {
    const { isOpen, onClose, updateData, registrationData } = props;

    const [isLoading, setIsLoading] = useState(false);

    const [amount, setAmount] = useState<any>(0.0);
    const [description, setDescription] = useState('');
    const [type, setType] = useState('specific_users');
    const [selectionType, setSelectionType] = useState('conditions');
    const [targetDetails, setTargetDetails] = useState('all_users');

    const [emailList, setEmailList] = useState<{ email: string, name: string }[]>([]);

    const [expireDate, setExpireDate] = useState(new Date().toISOString().split('T')[0]);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

    const [emailTemplates, setEmailTemplates] = useState([]);
    const [emailTemplateId, setEmailTemplateId] = useState('');

    const [isEmailTemplateDialogOpen, setIsEmailTemplateDialogOpen] = useState(false);

    const [templateIdForEdit, setTemplateIdForEdit] = useState<null | string>(null);
    const [ignoreNewsletterConsent, setIgnoreNewsletterConsent] = useState(false);

    const refreshTemplates = useCallback(() => {
        return new Promise<void>((resolve, reject) => {
            api.request('/admin/vouchers/email_templates').then((res: any) => {
                setEmailTemplates(res);
                resolve();
            }).catch(() => {
                reject();
            });
        });
    }, []);

    const registrationId = useMemo(() => {
        return registrationData?.id;
    }, [registrationData]);

    useEffect(() => {
        if (!isOpen) return;

        setType('specific_users');
        setSelectionType('emails');
        setTargetDetails('participant_or_first_parent');
        setExpireDate(new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().split('T')[0]);
        setEmailTemplateId('');
        refreshTemplates();
        setDescription('Quota non goduta - ' + registrationData?.person?.name + ' ' + registrationData?.person?.surname);
        setAmount((parseFloat(registrationData?.additionalData?.paid) - parseFloat(registrationData?.additionalData?.cost) - parseFloat(registrationData?.emittedVouchers)).toFixed(2));
        setIgnoreNewsletterConsent(true);
        setEmailList([{ name: registrationData?.person?.name + ' ' + registrationData?.person?.surname, email: registrationData?.user?.email }]);
    }, [isOpen, registrationData, refreshTemplates]);

    const handleSave = useCallback(() => {
        setIsLoading(true);
        api.request('/admin/vouchers', 'POST', {
            description,
            amount,
            expireDate,
            target: type,
            selectionType,
            filtersTree: [],
            code: '',
            emailList,
            emailTemplateId,
            ignoreNewsletterConsent,
            targetDetails,
            filterSelectionType: 'people',
            registrationId
        }).then((res: any) => {
            snackbar.success('Voucher creato con successo!');
            onClose();
            updateData();
        }).finally(() => {
            setIsLoading(false);
        });
    }, [registrationId, targetDetails, emailTemplateId, description, amount, expireDate, type, selectionType, emailList, onClose, updateData, ignoreNewsletterConsent]);

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'secondary',
            onClick: () => onClose()
        },
        {
            children: 'Salva',
            color: 'primary',
            onClick: () => handleSave()
        },
    ];

    return (
        <>
            <CustomDialogWrapper open={isOpen} onClose={() => onClose()} title={'Crea nuovo voucher'} buttons={buttons} isLoading={isLoading} maxWidth='sm' fullWidth >
                <div style={{ marginBottom: '24px' }}>
                    <Typography variant='h6' noWrap style={{ marginBottom: '6px' }}>
                        Informazioni generali
                    </Typography>

                    <CustomTextField
                        label='Importo voucher'
                        variant='outlined'
                        value={amount}
                        keepMounted={true}
                        onChange={(e: any) => {
                            setAmount(e.target.value);
                        }}
                        onBlur={() => {
                            setAmount((c: any) => {
                                return Math.max(((typeof c === 'string') ? parseFloat(c.replaceAll(',', '.')) : c), 0).toFixed(2);
                            });
                        }}
                        disabled={isLoading}
                    />

                    <MuiPickersUtilsProvider locale='it' utils={MomentUtils}>
                        <KeyboardDatePicker
                            label='Data scadenza'
                            inputVariant='outlined'
                            minDate={moment(Date.now() - 24 * 3600 * 1000).format('YYYY-MM-DD')}
                            maxDate={moment(Date.now() + 120 * 365 * 24 * 3600 * 1000).format('YYYY-MM-DD')}
                            format='DD/MM/YYYY'
                            value={expireDate}
                            onChange={(date: MaterialUiPickersDate) => {
                                setExpireDate(date?.format('YYYY-MM-DD') ?? '');
                            }}
                            open={isDatePickerOpen}
                            onClick={() => {
                                setIsDatePickerOpen(true);
                            }}
                            onClose={() => setIsDatePickerOpen(false)}
                            onKeyDown={(e) => {
                                e.preventDefault();
                                return false;
                            }}
                            style={{ flex: 1, marginBottom: '4px' }}
                            inputProps={{ tabIndex: -1 }}
                            KeyboardButtonProps={{ tabIndex: -1 }}
                            fullWidth
                        />
                    </MuiPickersUtilsProvider>
                </div>

                {type !== 'everyone' && (
                    <div style={{ marginBottom: '24px' }}>
                        <Typography variant='h6' noWrap>
                            Template email
                        </Typography>

                        <CustomTextField
                            select
                            label={'Seleziona template'}
                            value={emailTemplateId}
                            onChange={(e: any) => {
                                setEmailTemplateId(e.target.value);
                            }}
                            variant='outlined'
                            fullWidth
                            style={{ marginTop: '6px' }}
                        >
                            {emailTemplates.map((opt: any) => (
                                <MenuItem key={opt.id} value={opt.id} style={{ whiteSpace: 'break-spaces' }}>
                                    {opt.description}
                                </MenuItem>
                            ))}

                            {emailTemplates.length === 0 && (
                                <MenuItem value={''} style={{ whiteSpace: 'break-spaces' }} disabled>
                                    Nessun template disponibile
                                </MenuItem>
                            )}
                        </CustomTextField>

                        <Button onClick={() => {
                            setTemplateIdForEdit(null);
                            setIsEmailTemplateDialogOpen(true);
                        }} color={'primary'} variant={'outlined'} style={{ marginRight: '6px' }}>
                            Crea template
                        </Button>

                        <Button onClick={() => {
                            setTemplateIdForEdit(emailTemplateId);
                            setIsEmailTemplateDialogOpen(true);
                        }} color={'primary'} variant={'outlined'} disabled={!emailTemplateId}>
                            Modifica template
                        </Button>
                    </div>
                )}

            </CustomDialogWrapper>

            <EmailTemplateDialog isOpen={isEmailTemplateDialogOpen} onClose={(newTemplateId?: string) => {
                setIsEmailTemplateDialogOpen(false);

                if (newTemplateId) {
                    refreshTemplates().then(() => {
                        setEmailTemplateId(newTemplateId);
                    });
                }
            }} templateId={templateIdForEdit} />
        </>
    );
};

export default NewVoucherDialog;
