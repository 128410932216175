import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const InfoBox = ({children, style} : {children: JSX.Element, style?: React.CSSProperties}) => {
    return (
        <Paper style={{flex: 1, padding: '6px', paddingTop: '12px', margin: '0 12px', textAlign: 'center', ...style}} elevation={3}>
            <Typography variant='h2' style={{fontSize: '4em'}}>
                {children}
            </Typography>
        </Paper>
    );
};

export default InfoBox;
