import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import styled from 'styled-components';

export const StyledListItem = styled(ListItem)`
    padding: 4px 16px;
    min-height: 56px;
`;

export const StyledListItemText = styled(ListItemText)<{largepadding?: number}>`
    & span, & p {
        white-space: break-spaces;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    ${props => props.largepadding ? 'padding-right: 32px;' : ''}
`;
