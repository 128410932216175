import styled from 'styled-components/macro';

export const FileName = styled.span`
    margin-left: 8px;
`;

export const FileInputContainer = styled.div`
    margin: 4px 0;
`;

export const StyledFileInput = styled.input`
    display: none;
`;
