import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { close } from '../../../redux/globalDialogs/globalDialogsSlice';

const useCloseDialog = (name: string) => {
    const dispatch = useDispatch();

    const closeDialog = useCallback(() => {
        dispatch(close(name));
    }, [name, dispatch]);

    return closeDialog;
};

export default useCloseDialog;
