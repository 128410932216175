import Divider from "@material-ui/core/Divider";
import { ChangeEvent, Dispatch, SetStateAction } from "react"
import GenericObject from "../../../../../../typesAdditional/GenericObject";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import moment from "moment";
import 'moment/locale/it';

interface Props {
    categories: GenericObject[];
    selectedTab: number;
    selectTab: Dispatch<SetStateAction<number>>;
    isWeekVisible: boolean;
}

const Footer = ({ categories, selectedTab, selectTab, isWeekVisible }: Props) => {
    const a11yProps = (index: number) => {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }

    return (
        <div>
            <Divider />
            <div style={{ display: 'flex' }}>
                <Tabs
                    value={selectedTab}
                    onChange={(e: ChangeEvent<{}>, newValue: number) => selectTab(newValue)}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {categories.map((category: GenericObject, idx: number) => {
                        return (
                            <Tab style={{ whiteSpace: 'pre-wrap' }} label={(
                                <>
                                    {moment(category.startTime).format('dddd')} {"\n"}
                                    {category.scheduleDesc}
                                </>
                            )} {...a11yProps(idx)} />
                        )
                    })}
                </Tabs>

                <div style={{ flexGrow: 1 }} />
            </div>
        </div>
    )
}

export default Footer;