import styled from 'styled-components/macro';

export const Container = styled.div<{ isVisible: boolean, transitionDuration: number, type: 'internal' | 'external' }>`
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ffffff;
    pointer-events: ${p => p.isVisible ? 'initial' : 'none'};
    z-index: ${p => p.type === 'external' ? 1000000 : 1200};
    opacity: ${p => p.isVisible ? 1 : 0};
    ${p => !p.isVisible ? ('transition: opacity ' + p.transitionDuration / 1000 + 's ease-out') : ''}
`;
