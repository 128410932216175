import { useCallback, useEffect, useRef, useState } from 'react';
import { useWindowSize } from 'react-use';

import { Button, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';

import useInternalLoader from '../../../../common/hooks/useInternalLoader/useExternalLoader';
import api from '../../../../common/utils/api';
import GenericObject from '../../../../typesAdditional/GenericObject';
import AvailabilityDetailDialog from '../trainersListPage/components/AvailabilityDetailDialog/AvailabilityDetailDialog';

const TrainersSummerUtilizationPage = () => {
    const setIsLoading = useInternalLoader();

    const { width } = useWindowSize();

    const [people, setPeople] = useState<GenericObject[]>([]);

    const [isAvailabilityDetailDialogOpen, setIsAvailabilityDetailDialogOpen] = useState(false);

    const [selectedTrainerId, setSelectedTrainerId] = useState<string | undefined>(undefined);

    const apiRequestId = useRef('');

    const refreshData = useCallback(() => {
        const currentRequestId = uuidv4();
        apiRequestId.current = currentRequestId;

        api.request('/admin/trainers', 'GET', { page: 1, perPage: 500 }).then((res: any) => {
            if (currentRequestId !== apiRequestId.current) return;

            const { data } = res;
            setPeople(data);

        }).finally(() => {
            setIsLoading(false);
        });
    }, [setIsLoading]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            refreshData();
        }, 10);

        return () => clearTimeout(timeout);
    }, [refreshData]);

    const isMobile = width <= 700;

    return (
        <>
            <div>
                <div style={{ display: isMobile ? undefined : 'flex', textAlign: isMobile ? 'center' : undefined }}>
                    <Typography variant='h2' style={{ fontSize: '3.6em', flexGrow: 1 }}>
                        Utilizzo effettivo istruttori
                    </Typography>
                </div>

                <Divider style={{ margin: '8px 0 12px' }} />
            </div>

            <TableContainer component={Paper} style={{ marginTop: '16px' }}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Cognome</TableCell>
                            <TableCell>Nome</TableCell>
                            <TableCell style={{ backgroundColor: '#fff4e5', textAlign: 'center' }}>Completamente disponibile</TableCell>
                            <TableCell style={{ backgroundColor: '#fff4e5', textAlign: 'center' }}>Impiego totale</TableCell>
                            <TableCell style={{ backgroundColor: '#fff4e5', textAlign: 'center' }}>Impiego parziale</TableCell>
                            <TableCell style={{ backgroundColor: '#edf7ed', textAlign: 'center' }}>Parzialmente disponibile</TableCell>
                            <TableCell style={{ backgroundColor: '#edf7ed', textAlign: 'center' }}>Impiego totale</TableCell>
                            <TableCell style={{ backgroundColor: '#edf7ed', textAlign: 'center' }}>Impiego parziale</TableCell>
                            <TableCell style={{ width: '1px', whiteSpace: 'nowrap', textAlign: 'center' }}>Azioni</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {people.map(person => {
                            return (
                                <TableRow style={{ height: '45px' }}>
                                    <TableCell>{person.lastName}</TableCell>
                                    <TableCell>{person.firstName}</TableCell>
                                    <TableCell style={{ textAlign: 'center', backgroundColor: '#fff4e5' }}>{person.fullyAvailable}</TableCell>
                                    <TableCell style={{ textAlign: 'center', backgroundColor: '#fff4e5' }}>{person.fullyAvailableFullyUsed}</TableCell>
                                    <TableCell style={{ textAlign: 'center', backgroundColor: '#fff4e5' }}>{person.fullyAvailablePartiallyUsed}</TableCell>
                                    <TableCell style={{ textAlign: 'center', backgroundColor: '#edf7ed' }}>{person.partiallyAvailable}</TableCell>
                                    <TableCell style={{ textAlign: 'center', backgroundColor: '#edf7ed' }}>{person.partiallyAvailableFullyUsed}</TableCell>
                                    <TableCell style={{ textAlign: 'center', backgroundColor: '#edf7ed' }}>{person.partiallyAvailablePartiallyUsed}</TableCell>

                                    <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>
                                        <Button variant='outlined' size='small' color='primary' style={{ backgroundColor: '#ffffff' }} onClick={() => {
                                            setSelectedTrainerId(person.id);
                                            setIsAvailabilityDetailDialogOpen(true);
                                        }}>
                                            Visualizza disponibilità
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <AvailabilityDetailDialog
                open={isAvailabilityDetailDialogOpen}
                closeDialog={() => setIsAvailabilityDetailDialogOpen(false)}
                selectedTrainerId={selectedTrainerId}
            />
        </>
    );
};

export default TrainersSummerUtilizationPage;
