import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

const InfoAlert = () => {
    return (
        <Alert severity='info' style={{paddingBottom: '0px', marginBottom: '24px'}}>
            <AlertTitle>Dati anagrafici precompilati</AlertTitle>

            <p style={{marginTop: '0px'}}>
                I dati anagrafici precompilati non possono essere modificati.
            </p>
        </Alert>
    );
};

export default InfoAlert;
