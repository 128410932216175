import Divider from "@material-ui/core/Divider";
import { ChangeEvent, Dispatch, SetStateAction } from "react"
import GenericObject from "../../../../../../typesAdditional/GenericObject";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useWindowSize } from "react-use";
import ZoomControl from "../ZoomControl/ZoomControl";

interface Props {
    weeks: GenericObject[];
    selectedTab: number;
    selectTab: Dispatch<SetStateAction<number>>;
    incrementZoom: () => void;
    decrementZoom: () => void;
    zoomLevel: number;
    setZoomLevel: (zoom: string) => void;
    isWeekVisible: boolean;
}

const Footer = ({ weeks, selectedTab, selectTab, isWeekVisible, ...zoomControlProps }: Props) => {
    const a11yProps = (index: number) => {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }

    const { width } = useWindowSize();
    const isMobile = width <= 900;

    return (
        <div>
            <Divider />
            <div style={{ display: 'flex' }}>
                <Tabs
                    value={selectedTab}
                    onChange={(e: ChangeEvent<{}>, newValue: number) => selectTab(newValue)}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {weeks.map((week: GenericObject, idx: number) => {
                        return (
                            <Tab label={week.shortName} {...a11yProps(idx)} />
                        )
                    })}
                </Tabs>

                {!isMobile && isWeekVisible && (
                    <ZoomControl {...zoomControlProps} />
                )}
            </div>
        </div>
    )
}

export default Footer;