import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

export const ButtonContainer = styled.div`
    text-align: center;
    margin: 0px auto 10px;
`;

export const CheckboxContainer = styled.div`
    text-align: center;
`;

export const Title = styled(Typography)`
    text-align: center;
    margin-bottom: 6px;
`;

export const Logo = styled.img`
    width: 30%;
    max-width: 250px;
    margin: 15px auto;
    display: block;
`;

export const FormContainer = styled(Paper)`
    max-width: 500px;
    padding: 15px;
    margin: 0 auto;
`;

export const ProgressPlaceholder = styled.div`
    margin-bottom 20px;
`;

export const StyledLink = styled(Link)`
    margin: 4px auto 0;
    display: block;
    text-align: center;
`;

export const StyledProgress = styled(LinearProgress)`
    margin: 8px;
`;

export const StyledTextField = styled(TextField)`
    margin-top: 10px !important;
`;
