import { ButtonProps } from '@material-ui/core/Button';

import CustomDialogWrapper from '../../../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import GenericObject from '../../../../../../../../typesAdditional/GenericObject';
import PaymentCard from '../PaymentCard/PaymentCard';

interface Props {
    open: boolean;
    closeDialog: () => void;
    payments: GenericObject[];
}

const DeletedPaymentsDialog = (props: Props) => {
    const { open, closeDialog, payments } = props;

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        },
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Storico pagamenti eliminati'} buttons={buttons} maxWidth='sm' fullWidth>
            {payments.map((payment: any) => {
                return (
                    <PaymentCard payment={payment} />
                );
            })}
        </CustomDialogWrapper>
    );
};

export default DeletedPaymentsDialog;
