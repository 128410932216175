import styled from 'styled-components/macro';

const FlexInputContainer = styled.div`
    display: flex;
    margin-left: -4px;
    margin-right: -4px;
    & > div {
        margin-left: 4px;
        margin-right: 4px;
        flex: 1;
    }

    @media only screen and (max-width: 700px) {
        display: block;
        margin-left: 0px;
        margin-right: 0px;
        & > div {
            margin-left: 0px;
            margin-right: 0px;
            flex: 1;
        }
    }
`;

export default FlexInputContainer;
