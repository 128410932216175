import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import { LinearProgress, Typography } from '@material-ui/core';

interface Props {
    isOpen: boolean;
}

const LoadingDialog = (props: Props) => {
    const { isOpen } = props;

    return (
        <>
            <CustomDialogWrapper open={isOpen} maxWidth='xs' hideTitle hideActions fullWidth >
                <Typography variant='body1'>
                    Attendi, elaborazione del file in corso...
                </Typography>

                <LinearProgress style={{ margin: '12px 0' }} />
            </CustomDialogWrapper>
        </>
    );
};

export default LoadingDialog;
