import { useCallback, useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';

import { ButtonProps } from '@material-ui/core';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import { useMessageDialog } from '../../../../../../common/hooks/useMessageDialog/useMessageDialog';
import api from '../../../../../../common/utils/api';
import snackbar from '../../../../../../common/utils/snackbar';
import { SessionsSelectorProps } from '../../../registrationPage/steps/RegistrationStep6/types';
import SpecialDietsV2Selector from '../../../registrationPage/steps/RegistrationStep6/components/SpecialDietsV2Selector/SpecialDietsV2Selector';

interface Props extends SessionsSelectorProps {
    isOpen: boolean;
    onClose: (shouldRefresh?: boolean, deleted?: boolean) => void;
    baseUrl: string;
    selectedSessions: any;
    isAdmin?: boolean;
    pricesNotAvailable?: boolean;
    singleSessionEvent?: boolean;
    specialDiets: any[];
    setSpecialDiets: (diets: any[]) => void;
    showSpecialDiets: boolean;
}

const ChangeDietDialog = (props: Props) => {
    const { isOpen, onClose, baseUrl, selectedSessions, isAdmin, specialDiets, setSpecialDiets, showSpecialDiets } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [isUntouched, setIsUntouched] = useState(true);

    const [showMessageDialog, closeMessageDialog] = useMessageDialog();

    const { width } = useWindowSize();

    const saveAndClose = useCallback(() => {
        if (isUntouched) {
            snackbar.success('Modifiche salvate con successo!');
            onClose();
            return;
        }

        setIsLoading(true);
        api.request(baseUrl + '/change_sessions', 'POST', { sessions: selectedSessions, specialDiets }).then((res: any) => {
            snackbar.success('Modifiche salvate con successo!');
            onClose(true, res?.deleted ?? false);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [specialDiets, onClose, isUntouched, baseUrl, selectedSessions]);

    const internalOnClose = useCallback(() => {
        if (!isUntouched) {
            showMessageDialog({
                title: 'Ci sono delle modifiche in sospeso',
                message: 'Vuoi salvare le modifiche effettuate?',
                actions: [
                    {
                        text: 'No',
                        action: () => {
                            closeMessageDialog();
                            onClose();
                        }
                    },
                    {
                        text: 'Sì',
                        action: () => {
                            closeMessageDialog();
                            saveAndClose();
                        }
                    }
                ]
            });
        } else {
            onClose();
        }
    }, [closeMessageDialog, saveAndClose, showMessageDialog, isUntouched, onClose]);

    useEffect(() => {
        if (!isOpen) setIsUntouched(true);
    }, [isOpen]);

    const buttons: ButtonProps[] = [
        {
            children: 'Salva',
            color: 'primary',
            onClick: () => saveAndClose()
        },
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => onClose()
        }
    ];

    const isMobile = width < 700;

    return (
        <CustomDialogWrapper fullScreen={isMobile} open={isOpen} onClose={internalOnClose} title={'Modifica dieta speciale'} buttons={buttons} isLoading={isLoading} maxWidth='md' fullWidth contentProps={{}} >            {showSpecialDiets && isAdmin && (
            <div>
                <SpecialDietsV2Selector
                    selectedSpecialDiets={specialDiets}
                    rawOnChange={(field, value) => {
                        setSpecialDiets(value)
                        setIsUntouched(false);
                    }}
                    disabled={false}
                />
            </div>
        )}

        </CustomDialogWrapper>
    );
};

export default ChangeDietDialog;
