import { ButtonProps } from '@material-ui/core/Button';
import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import GenericObject from '../../../../../../typesAdditional/GenericObject';
import { Divider, Typography } from '@material-ui/core';
import 'moment/locale/it';
import AvailabilityTable from '../../../summerSchedulePage/components/FindAvailabilityDialog/components/AvailabilityTable/AvailabilityTable';

interface Props {
    open: boolean;
    closeDialog: () => void;
    selectedWeek?: GenericObject;
    instructor?: GenericObject;
}

const ViewAvailabilityDialog = (props: Props) => {
    const { open, closeDialog, selectedWeek, instructor } = props;

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'secondary',
            onClick: () => closeDialog()
        }
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Dettagli disponibilità'} buttons={buttons} maxWidth='md' fullWidth>
            {instructor && (
                <>
                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography style={{ fontSize: '1.25em', flex: 1 }}>
                                {(instructor.name && instructor.surname) ? (instructor.name + ' ' + instructor.surname) : instructor.email}
                            </Typography>
                            <Typography style={{ fontSize: '1em', textAlign: 'left', paddingTop: '2px' }} color="textSecondary">
                                {(instructor.email) ? (instructor.email) : ''}
                            </Typography>
                        </div>
                        {instructor.residence && (
                            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                                <p style={{ margin: 0, flex: 1 }} />
                                <Typography style={{ fontSize: '1.25em', flex: 1, textAlign: 'right' }}>
                                    {instructor.residence}
                                </Typography>
                                {/*
                                        <Typography style={{ fontSize: '0.95em', textAlign: 'right', marginTop: '-2px' }} color="textSecondary">
                                            residenza
                                        </Typography>
                                        */}
                                <p style={{ margin: 0, flex: 1 }} />
                            </div>
                        )}
                    </div>

                    <AvailabilityTable selectedWeek={selectedWeek} instructor={instructor} />

                    {instructor.notes && (
                        <>
                            <p style={{ margin: '16px 12px' }}><b>Note:</b> {instructor.notes}</p>
                            <Divider />
                        </>
                    )}</>
            )}

        </CustomDialogWrapper >
    );
};

export default ViewAvailabilityDialog;
