import { useEffect, useMemo, useState } from 'react';

import GenericPersonForm, { FormSection } from '../../../../components/GenericPersonForm/GenericPersonForm';
import { Props as FieldsHookProps } from '../../../../components/GenericPersonForm/hooks/useFields';
import StepFooter from '../../../../components/StepFooter/StepFooter';
import useValidatePersonStep from '../../../../hooks/useValidatePersonStep/useValidatePersonStep';
import { GenericStepProps } from '../../../types';
import InfoAlert from './components/InfoAlert/InfoAlert';

interface Props extends GenericStepProps {
    isParticipantMinor: boolean;
    setIsParticipantMinor: (value: boolean) => void;
    shouldSelectSchoolGrade: boolean;
    schoolClasses: any[];
    personExists: boolean;
    discardPersonChoose: () => void;
    canChangePerson: boolean;
}

const formSections: FormSection[] = ['basic_data', 'tax_code', 'address'];

const RegistrationStep1 = (props: Props) => {
    const { courseId, validationResult, data, onChange, rawOnChange, nextStep, shouldSelectSchoolGrade, schoolClasses, setIsParticipantMinor, personExists, discardPersonChoose, canChangePerson, isParticipantMinor } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [goingToNextStep, setIsGoingToNextStep] = useState(false);

    const prefilledFields = data?.userPrefilledFields ?? [];

    const finalFormSections = useMemo((): FormSection[] => {
        const additionalSections: FormSection[] = [];

        if (shouldSelectSchoolGrade) {
            additionalSections.push('school_grade');
        }

        if (!isParticipantMinor) {
            additionalSections.push('phone');
        }

        if (schoolClasses.length > 0) {
            additionalSections.push('school_class');
        }

        return [...formSections, ...additionalSections];
    }, [schoolClasses, isParticipantMinor, shouldSelectSchoolGrade]);

    const fieldsHookProps: FieldsHookProps = useMemo(() => {
        return {
            data,
            validationResult,
            schoolClasses,
            formPrefix: 'participant',
            sections: finalFormSections,
        };
    }, [finalFormSections, data, schoolClasses, validationResult]);

    const validatePersonStep = useValidatePersonStep({
        courseId,
        ...fieldsHookProps
    });

    const internalNextStep = () => {
        setIsLoading(true);

        validatePersonStep().then((res: any) => {
            const { isMinor } = res;
            setIsParticipantMinor(isMinor);
            setIsGoingToNextStep(true);
        }).catch(() => {
            setIsLoading(false);
        });
    };

    useEffect(() => {
        if (!goingToNextStep) return;

        const t = setTimeout(() => {
            nextStep();
            setIsLoading(false);
        }, 500);

        return () => clearTimeout(t);
    }, [goingToNextStep, nextStep]);

    const realPrevStep = (personExists && isParticipantMinor) ? discardPersonChoose : undefined;

    return (
        <>
            {prefilledFields.length > 0 && (
                <InfoAlert personExists={personExists} discardPersonChoose={discardPersonChoose} canChangePerson={canChangePerson} />
            )}


            <GenericPersonForm
                rawOnChange={rawOnChange}
                dataOnChange={onChange}
                disabledFields={prefilledFields}
                {...fieldsHookProps}
            />

            <StepFooter isLoading={isLoading || validationResult?.participant?.isLoading} prevStep={realPrevStep} nextStep={internalNextStep} />
        </>
    );
};

export default RegistrationStep1;
