import Button from '@material-ui/core/Button';
import styled from 'styled-components';

const GreenButton = styled(Button) <{ noaction?: boolean }>`
    background-color: #2e7d32;
    border-color: #1e7e34;
    color: #ffffff;
    height: fit-content;
    margin: auto;
    cursor: ${p => !p.noaction ? 'pointer' : 'default'};
    pointer-events: ${p => !p.noaction ? 'unset' : 'none'};
    
    &:hover {
        background-color: ${p => !p.noaction ? '#339f4b' : 'unset'};
    }
`;

export default GreenButton;
