import { useCallback, useState } from "react";
import { Button, Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import Header from "./components/Header/Header";
import { PageContainer } from "./styled";
import Footer from "./components/Footer/Footer";
import DynamicTableTooltip from "./components/DynamicTableTooltip/DynamicTableTooltip";
import ViewAvailabilityDialog from "./components/ViewAvailabilityDialog/ViewAvailabilityDialog";
import useLocalStorageListener from "../../../../common/hooks/useLocalStorageListener/useLocalStorageListener";
import useSummerWeeksPreloading from "../../../../common/hooks/useSummerWeeksPreloading/useSummerWeeksPreloading";

const SummerWeeklyAvailabilityPage = () => {
    const [isViewAvailabilityDialogOpen, setIsViewAvailabilityDialogOpen] = useState(false);

    const [selectedTrainerId, setSelectedTrainerId] = useState<string | undefined>(undefined);
    const [selectedTab, selectTab] = useState(0);

    const getWeekDetailsUrl = useCallback((id: string) => '/admin/summer_schedule/weeks/' + id + '/availability', []);

    const { loadWeeks, refreshData, weeks, selectedWeek, currentWeekData } = useSummerWeeksPreloading({ selectedTab, getWeekDetailsUrl, year: 2025 });

    const localStorageCallback = useCallback(() => {
        refreshData(true);
        loadWeeks(true);
    }, [refreshData, loadWeeks]);

    useLocalStorageListener({
        name: 'summerAvailabilityLastUpdated',
        callback: localStorageCallback
    });

    return (
        <>
            <PageContainer>
                <Header selectedWeek={selectedWeek} />

                <div style={{ flexGrow: 1, overflow: 'auto' }}>
                    <TableContainer component={Paper} style={{ marginTop: '16px' }}>
                        <Table size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: '25%' }}>Cognome</TableCell>
                                    <TableCell style={{ width: '25%' }}>Nome</TableCell>
                                    <TableCell style={{ width: '25%' }}>Disponibilità</TableCell>
                                    <TableCell style={{ width: '25%' }}>Dettagli disponibilità</TableCell>
                                    <TableCell style={{ width: '25%' }}>Note</TableCell>
                                    <TableCell style={{ width: '84px', whiteSpace: 'nowrap' }}>Azioni</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {currentWeekData.filter((person: any) => person.isVisible).map((person: any) => {
                                    type status = 'yes' | 'no' | 'uncertain';

                                    const statusMap = {
                                        no: {
                                            color: '#fdecea',
                                            borderColor: '#f44336',
                                            label: 'No',
                                            outlined: false
                                        },
                                        yes: {
                                            color: '#edf7ed',
                                            borderColor: '#4caf50',
                                            label: 'Sì',
                                            outlined: false
                                        },
                                        uncertain: {
                                            color: '#fff4e5',
                                            borderColor: '#ff9800',
                                            label: 'Parziale',
                                            outlined: false
                                        }
                                    }

                                    const statusData = statusMap[person.availabilitySummary as status];

                                    return (
                                        <TableRow style={{ height: '45px', backgroundColor: person.color }}>
                                            <TableCell style={{ whiteSpace: 'nowrap' }}>{person.surname}</TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap' }}>{person.name}</TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap' }}>
                                                <Chip label={statusData.label} variant={statusData.outlined ? 'outlined' : 'default'} style={{ ...(statusData.outlined ? {} : { backgroundColor: statusData.color, border: '1px solid ' + statusData.borderColor }), width: '90px' }} />
                                            </TableCell>
                                            <DynamicTableTooltip description={person.availabilitySummary === 'uncertain' ? person.availabilityDescription : ''} />
                                            <DynamicTableTooltip description={person.notes} />
                                            <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>
                                                <Button variant='outlined' size='small' color='primary' style={{ backgroundColor: '#ffffff' }} onClick={() => {
                                                    setSelectedTrainerId(person.id);
                                                    setIsViewAvailabilityDialogOpen(true);
                                                }}>
                                                    Visualizza disponibilità
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>

                <Footer weeks={weeks} selectedTab={selectedTab} selectTab={selectTab} />


            </PageContainer >

            <ViewAvailabilityDialog
                open={isViewAvailabilityDialogOpen}
                closeDialog={() => setIsViewAvailabilityDialogOpen(false)}
                selectedWeek={selectedWeek}
                instructor={currentWeekData.find((t: any) => t.id === selectedTrainerId) ?? undefined}
            />
        </>
    )
}

export default SummerWeeklyAvailabilityPage;