import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import CustomTextField from '../../../../../common/components/CustomTextField/CustomTextField';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';

interface Props {
    numberOfPeople: number;
    setNumberOfPeople: (n: number) => void;
    nextStep: () => void;
    prevStep: () => void;
    progress: number;
}

const Step2 = ({ numberOfPeople, setNumberOfPeople, nextStep, prevStep, progress }: Props) => {
    return (
        <>
            <Typography variant='h2' style={{fontSize: '25px', fontFamily: '\'Libre Franklin\', sans-serif', textAlign: 'center', margin: '8px'}}>
                Seleziona il numero di iscritti per cui effettuare il preventivo
            </Typography>

            <Header progress={progress} />

            <div style={{width: '200px', margin: '24px auto'}}>
                <CustomTextField
                    select
                    label={'Numero di iscritti'}
                    value={numberOfPeople}
                    onChange={(e: any) => {
                        setNumberOfPeople(e.target.value);
                    }}
                    variant='outlined'
                    fullWidth
                    style={{marginTop: '6px'}}
                >
                    {[1, 2, 3, 4, 5].map(n => {
                        return (
                            <MenuItem value={n} style={{whiteSpace: 'break-spaces'}} >
                                {n}
                            </MenuItem>
                        );
                    })}
                 </CustomTextField>
            </div>

            <Footer nextStep={nextStep} prevStep={prevStep} />
        </>
    );
};

export default Step2;
