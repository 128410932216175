import { useCallback, useEffect, useMemo, useState } from 'react';

import Button, { ButtonProps } from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import CustomTextField from '../../../../../../common/components/CustomTextField/CustomTextField';
import { useMessageDialog } from '../../../../../../common/hooks/useMessageDialog/useMessageDialog';
import api from '../../../../../../common/utils/api';
import snackbar from '../../../../../../common/utils/snackbar';
import SetCostDialog from '../SetCostDialog/SetCostDialog';
import DeletedPaymentsDialog from './components/DeletedPaymentsDialog/DeletedPaymentsDialog';
import PaymentCard from './components/PaymentCard/PaymentCard';
import UpdateVoucherCodeDialog from './components/UpdateVoucherCodeDialog/UpdateVoucherCodeDialog';
import CostChangelogDialog from './components/CostChangelogDialog/CostChangelogDialog';
import ReceiptRequestDialog from '../../../../../public/pages/registrationsListPage/components/ReceiptRequestDialog/ReceiptRequestDialog';
import ReceiptsListDialog from '../../../../../public/pages/registrationsListPage/components/ReceiptsListDialog/ReceiptsListDialog';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../../../redux/types';

interface Props {
    open: boolean;
    closeDialog: (changed?: boolean) => void;
    refreshRegistrations: () => void;
    registrationId?: string;
}

const initialData = {
    'paid': '0.00',
    'method': '',
};

const PaymentsDialog = (props: Props) => {
    const { open, closeDialog, refreshRegistrations, registrationId } = props;

    const userInfo = useSelector((state: ReduxState) => state.user);

    const [isLoading, setIsLoading] = useState(true);

    const [costDialogRegistrationId, setCostDialogRegistrationId] = useState<string | null>(null);

    const [data, setData] = useState<any>(initialData);
    const [savedData, setSavedData] = useState<any>(initialData);
    const [paymentMethods, setPaymentMethods] = useState<any[]>([]);

    const [showMessageDialog, closeMessageDialog] = useMessageDialog();

    const [isCostChangelogDialogOpen, setIsCostChangelogDialogOpen] = useState(false);
    const [isDeletedPaymentDialogOpen, setIsDeletedPaymentDialogOpen] = useState(false);
    const [isUpdateVoucherCodeDialogOpen, setIsUpdateVoucherCodeDialogOpen] = useState(false);

    const [selectedRegistrationId, setSelectedRegistrationId] = useState<string | undefined>();
    const [isReceiptRequestDialogOpen, setIsReceiptRequestDialogOpen] = useState(false);
    const [isReceiptsListDialoOpen, setIsReceiptsListDialoOpen] = useState(false);
    const [dialogReceiptsList, setDialogReceiptsList] = useState<any[]>([]);

    const showReceiptRequestDialog = (registration: any) => {
        setSelectedRegistrationId(registration.id);
        setIsReceiptRequestDialogOpen(true);
    };

    const showReceiptsListDialog = (registration: any) => {
        setDialogReceiptsList(registration.receipts);
        setIsReceiptsListDialoOpen(true);
    };

    useEffect(() => {
        api.request('/admin/payment_methods').then(res => {
            setPaymentMethods(res);
            setData((d: any) => {
                return {
                    ...d,
                    method: res.length > 0 ? res[0].id : ''
                };
            });
        });
    }, []);

    useEffect(() => {
        if (!registrationId || !open) return;

        setIsLoading(true);
        api.request('/admin/registrations/' + registrationId + '/payments').then(res => {
            // setData(res);
            setSavedData(res);
            setIsLoading(false);
        });
    }, [open, registrationId]);

    const refreshData = useCallback(() => {
        setIsLoading(true);
        api.request('/admin/registrations/' + registrationId + '/payments').then(res => {
            // setData(res);
            setSavedData(res);
            setIsLoading(false);
        });
    }, [registrationId]);

    useEffect(() => {
        if (!open) return;

        setData((d: any) => {
            return {
                ...d,
                paid: '0.00'
            };
        });
    }, [open]);

    const saveData = useCallback(() => {
        setIsLoading(true);
        api.request('/admin/registrations/' + registrationId + '/payments', 'POST', data).then(() => {
            snackbar.success('Pagamento registrato correttamente!');
            refreshData();
            refreshRegistrations();
            setData((d: any) => {
                return {
                    ...d,
                    paid: '0.00'
                };
            });
        }).finally(() => {
            setIsLoading(false);
        });
    }, [data, refreshRegistrations, refreshData, registrationId]);

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        },
    ];

    const deletePaymentConfirm = useCallback((paymentId: string) => {
        setIsLoading(true);
        api.request('/admin/registrations/' + registrationId + '/payments/' + paymentId, 'DELETE').then(() => {
            snackbar.success('Pagamento eliminato correttamente!');
            refreshData();
            refreshRegistrations();
        }).finally(() => {
            setIsLoading(false);
        });
    }, [refreshData, refreshRegistrations, registrationId]);

    const deletePayment = useCallback((payment: any) => {
        showMessageDialog({
            title: 'Elimina pagamento',
            message: (
                <>
                    Sei sicuro di volere eliminare il pagamento di <b>€{parseFloat(payment.amount).toFixed(2)}</b>{' '}
                    registrato in data <b>{moment(payment.masterPayment.createdAt).format('DD/MM/YYYY')}</b>?
                </>
            ),
            actions: [
                {
                    text: 'Annulla',
                    action: () => {
                        closeMessageDialog();
                    }
                },
                {
                    text: 'Elimina pagamento',
                    color: 'secondary',
                    action: () => {
                        closeMessageDialog();
                        deletePaymentConfirm(payment.id);
                    }
                }
            ]
        });
    }, [deletePaymentConfirm, showMessageDialog, closeMessageDialog]);

    const dialogContent = useMemo(() => {
        /*const hasDiscount = savedData?.discounts?.length > 0 && (savedData.discounts.reduce((prev: number, current: any) => {
            return prev + (parseFloat(current.oldCost) - parseFloat(current.newCost));
        }, 0) > 0);*/
        const hasDiscount = savedData?.discounts?.length > 0;

        const toPay = Math.max(0, parseFloat(savedData.cost) - parseFloat(savedData.paid));

        return (
            <>
                <TableContainer style={{ marginTop: '0px' }}>
                    <Table size='small'>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold', padding: '4px' }}>Partecipante</TableCell>
                                <TableCell style={{ textAlign: 'right', padding: '4px' }}>{savedData?.participant?.name} {savedData?.participant?.surname}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold', padding: '4px' }}>Costo {hasDiscount ? 'iniziale' : 'iscrizione'}</TableCell>
                                <TableCell style={{ textAlign: 'right', padding: '4px' }}>€ {(hasDiscount ? parseFloat(savedData.discounts[0].oldCost) : (parseFloat(savedData.cost) - parseFloat(savedData.deletedRegistrationsCost))).toFixed(2)}</TableCell>
                            </TableRow>
                            {hasDiscount ? (
                                <>
                                    {savedData.discounts.map((d: any) => {
                                        return (
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold', padding: '4px' }}>
                                                    {(parseFloat(d.oldCost) - parseFloat(d.newCost)) > 0 ? 'Sconto' : 'Sovrapprezzo'}
                                                    {' '}{d.discount.nameDescription}
                                                </TableCell>
                                                <TableCell style={{ textAlign: 'right', padding: '4px' }}>
                                                    {(parseFloat(d.oldCost) - parseFloat(d.newCost)) > 0 ? '-' : ''}
                                                    {' '}€ {Math.abs(parseFloat(d.oldCost) - parseFloat(d.newCost)).toFixed(2)}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}

                                    {savedData.deletedRegistrationsCost > 0 && (
                                        <TableRow style={{ backgroundColor: '#fdecea' }}>
                                            <TableCell style={{ fontWeight: 'bold', padding: '4px' }}>Settimane cancellate</TableCell>
                                            <TableCell style={{ textAlign: 'right', padding: '4px' }}>€ {parseFloat(savedData.deletedRegistrationsCost).toFixed(2)}</TableCell>
                                        </TableRow>
                                    )}

                                    <TableRow>
                                        <TableCell style={{ fontWeight: 'bold', padding: '4px' }}>Costo finale</TableCell>
                                        <TableCell style={{ textAlign: 'right', padding: '4px' }}>€ {parseFloat(savedData.cost).toFixed(2)}</TableCell>
                                    </TableRow>
                                </>
                            ) : (
                                <>
                                    {savedData.deletedRegistrationsCost > 0 && (
                                        <TableRow style={{ backgroundColor: '#fdecea' }}>
                                            <TableCell style={{ fontWeight: 'bold', padding: '4px' }}>Settimane cancellate</TableCell>
                                            <TableCell style={{ textAlign: 'right', padding: '4px' }}>€ {parseFloat(savedData.deletedRegistrationsCost).toFixed(2)}</TableCell>
                                        </TableRow>
                                    )}
                                </>
                            )}

                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold', padding: '4px' }}>Pagato</TableCell>
                                <TableCell style={{ textAlign: 'right', padding: '4px' }}>- € {parseFloat(savedData.paid).toFixed(2)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold', padding: '4px', borderBottom: 0 }}>Da pagare</TableCell>
                                <TableCell style={{ textAlign: 'right', padding: '4px', borderBottom: 0 }}>€ {toPay.toFixed(2)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                {(savedData.costChangelog?.length ?? 0) > 1 && (
                    <div style={{ flex: 1, textAlign: 'right', display: 'flex', alignItems: 'center', marginTop: '8px', marginBottom: '12px' }}>
                        <div style={{ flex: 1 }}>
                            <Link href='#' onClick={(e: any) => {
                                e.preventDefault();
                                setIsCostChangelogDialogOpen(true);
                            }}>
                                Storico modifiche costo
                            </Link>
                        </div>
                    </div>
                )}

                <div style={{ textAlign: 'center' }}>
                    <Button onClick={() => setCostDialogRegistrationId(registrationId ?? '')} color={'primary'} variant={'outlined'} style={{ marginRight: '2px', display: 'inline-block', marginBottom: '16px' }}>
                        Modifica costo
                    </Button>{' '}

                    <Button onClick={() => setIsUpdateVoucherCodeDialogOpen(true)} color={'primary'} variant={'outlined'} style={{ marginLeft: '2px', display: 'inline-block', marginBottom: '16px' }}>
                        {savedData.voucherCode ? 'Modifica' : 'Aggiungi'} voucher
                    </Button>
                </div>


                {['cristian@cristianlivella.com', 'ariboli@epuntos.it', 'gbuzzone@epuntos.it', 'dscaburri@epuntos.it'].includes(userInfo.email) && (
                    <div style={{ textAlign: 'center' }}>
                        <Button disabled={!savedData.registration || savedData.registration.receiptStatus === 'full'} onClick={() => showReceiptRequestDialog(savedData.registration)} color={'primary'} variant={'outlined'} style={{ marginRight: '2px', display: 'inline-block', marginBottom: '16px' }}>
                            Richiedi ricevuta
                        </Button>{' '}

                        <Button disabled={!savedData.registration || savedData.registration.receiptStatus === 'zero' || (savedData?.registration?.receipts ?? []).length === 0} onClick={() => showReceiptsListDialog(savedData.registration)} color={'primary'} variant={'outlined'} style={{ marginLeft: '2px', display: 'inline-block', marginBottom: '16px' }}>
                            Visualizza ricevut{(savedData?.registration?.receipts?.length ?? 0) === 1 ? 'a' : 'e'}
                        </Button>
                    </div>
                )}

                <Divider style={{ marginTop: '0px', marginBottom: '16px' }} />

                {(savedData.cost === savedData.paid ? (
                    <Alert severity='success' style={{ marginBottom: '16px' }}>
                        Il costo dell'iscrizione è stato interamente pagato!
                    </Alert>
                ) : (
                    <>
                        <Typography variant='h5' style={{ margin: '0 0 14px', fontSize: '1.4em' }}>
                            Registra pagamento
                        </Typography>

                        <div style={{ display: 'flex' }}>
                            <div style={{ marginRight: '3px', flex: 1 }}>
                                <CustomTextField
                                    label='Importo pagato'
                                    variant='outlined'
                                    required
                                    value={data.paid}
                                    keepMounted={true}
                                    onChange={(e) => {
                                        setData((d: any) => {
                                            return {
                                                ...d,
                                                paid: e.target.value
                                            };
                                        });
                                    }}
                                    onBlur={() => {
                                        setData((d: any) => {
                                            return {
                                                ...d,
                                                paid: Math.min(Math.max(((typeof d.paid === 'string') ? parseFloat(d.paid.replaceAll(',', '.')) : d.paid), 0), toPay).toFixed(2)
                                            };
                                        });
                                    }}
                                    disabled={isLoading}
                                />
                            </div>
                            <div style={{ marginLeft: '3px', flex: 1 }}>
                                <CustomTextField
                                    select
                                    label={'Metodo di pagamento'}
                                    value={data.method}
                                    onChange={(e: any) => {
                                        setData((d: any) => {
                                            return {
                                                ...d,
                                                method: e.target.value
                                            };
                                        });
                                    }}
                                    variant='outlined'
                                    required
                                    fullWidth
                                    disabled={isLoading}
                                >
                                    {paymentMethods.map((opt: any) => (
                                        <MenuItem key={opt.id} value={opt.id} style={{ whiteSpace: 'break-spaces' }}>
                                            {opt.name}
                                        </MenuItem>
                                    ))}
                                </CustomTextField>
                            </div>
                        </div>

                        <Button onClick={() => saveData()} color={'primary'} variant={'outlined'} style={{ margin: '0 auto', display: 'block' }}>
                            Salva
                        </Button>
                    </>
                ))}

                <Divider style={{ marginTop: '8px' }} />

                <div style={{ display: 'flex' }}>
                    <Typography variant='h5' style={{ margin: '12px 0', fontSize: '1.4em', flex: 1 }}>
                        Storico pagamenti
                    </Typography>

                    {savedData?.deletedPayments?.length > 0 && (
                        <div style={{ flex: 1, textAlign: 'right', display: 'flex', alignItems: 'center' }}>
                            <div style={{ flex: 1 }}>
                                <Link href='#' onClick={(e: any) => {
                                    e.preventDefault();
                                    setIsDeletedPaymentDialogOpen(true);
                                }}>
                                    <b>{savedData?.deletedPayments?.length}</b>{' '}
                                    pagament{savedData?.deletedPayments?.length === 1 ? 'o' : 'i'}{' '}
                                    eliminat{savedData?.deletedPayments?.length === 1 ? 'o' : 'i'}
                                </Link>
                            </div>
                        </div>
                    )}
                </div>

                {!(savedData?.payments?.length) ? (
                    <Alert severity='warning'>
                        Nessun pagamento effettuato
                    </Alert>
                ) : (
                    <>
                        {savedData.payments.map((payment: any) => (
                            <PaymentCard payment={payment} deletePayment={deletePayment} />
                        ))}
                    </>
                )}

            </>
        );
    }, [data, deletePayment, isLoading, registrationId, savedData, paymentMethods, saveData, userInfo]);

    return (
        <>
            <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Gestione pagamenti'} buttons={buttons} isLoading={isLoading} maxWidth='sm' fullWidth>
                {isLoading ? (
                    <Alert severity='info' style={{ marginBottom: '16px' }}>
                        Caricamento in corso...
                    </Alert>
                ) : (dialogContent)}
            </CustomDialogWrapper>
            <SetCostDialog registrationId={costDialogRegistrationId} closeDialog={(shouldRefresh?: boolean) => {
                setCostDialogRegistrationId(null);

                if (shouldRefresh) {
                    refreshData();
                    refreshRegistrations();
                }
            }} />
            <CostChangelogDialog
                open={isCostChangelogDialogOpen}
                closeDialog={() => setIsCostChangelogDialogOpen(false)}
                changelog={savedData?.costChangelog ?? []}
            />
            <DeletedPaymentsDialog
                open={isDeletedPaymentDialogOpen}
                closeDialog={() => setIsDeletedPaymentDialogOpen(false)}
                payments={savedData?.deletedPayments ?? []}
            />
            <UpdateVoucherCodeDialog
                open={isUpdateVoucherCodeDialogOpen}
                closeDialog={(shouldRefresh?: boolean) => {
                    setIsUpdateVoucherCodeDialogOpen(false);

                    if (shouldRefresh) {
                        refreshData();
                        refreshRegistrations();
                    }
                }}
                registrationId={registrationId}
                savedVoucherCode={savedData.voucherCode ?? ''}
            />
            <ReceiptRequestDialog isOpen={isReceiptRequestDialogOpen} onClose={() => setIsReceiptRequestDialogOpen(false)} selectedRegistrationId={selectedRegistrationId} />
            <ReceiptsListDialog isOpen={isReceiptsListDialoOpen} onClose={() => setIsReceiptsListDialoOpen(false)} receipts={dialogReceiptsList} />
        </>
    );
};

export default PaymentsDialog;
