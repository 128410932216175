import { useCallback, useEffect, useState } from 'react';

import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import api from '../../../../../../common/utils/api';
import snackbar from '../../../../../../common/utils/snackbar';

interface Props {
    open: boolean;
    closeDialog: (reloadData?: boolean) => void;
    userId: string | null;
}

const filter = createFilterOptions<string>();

const EditUserTagsDialog = (props: Props) => {
    const { open, closeDialog, userId } = props;

    const [isLoading, setIsLoading] = useState(false);

    const [data, setData] = useState<any>({});
    const [tags, setTags] = useState<string[]>([]);
    const [tagsList, setTagsList] = useState<string[]>([]);

    useEffect(() => {
        if (!open) {
            api.request('/admin/emails/tags').then((res: string[]) => {
                setTagsList(res);
            });

            return;
        }

        setTags([]);

        api.request('/admin/emails/users/' + userId).then((res: any) => {
            setData(res);
            setTags(res.tags.map((t: any) => t.name));
        });
    }, [userId, open]);

    const handleSubmit = useCallback(() => {
        setIsLoading(true);

        api.request('/admin/emails/users/' + userId, 'PUT', { tags }).then(() => {
            snackbar.success('Tag modificati con successo!');
            closeDialog(true);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [userId, closeDialog, tags]);

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'secondary',
            onClick: () => closeDialog()
        },
        {
            children: 'Salva',
            color: 'primary',
            onClick: () => handleSubmit()
        },
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Modifica tag'} buttons={buttons} isLoading={isLoading} maxWidth='sm' fullWidth>
            <TableContainer component={Paper} style={{marginBottom: '18px'}}>
                <Table size='small'>
                    <TableBody>
                        <TableRow>
                            <TableCell><b>Email utente</b></TableCell>
                            <TableCell>{data?.email}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <Autocomplete
                value={tags}
                multiple
                options={tagsList}
                getOptionLabel={(option: any) => option}
                defaultValue={[]}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    if (params.inputValue !== '') {
                        filtered.push(`Aggiungi "${params.inputValue}"`);
                    }

                    return filtered;
                }}
                onChange={(_event, newValue) => {
                    setTags(newValue.map(v => {
                        const regex = /Aggiungi "([\s\S]*)"/;
                        const match = v.match(regex);

                        if (match && match[1]) {
                            return match[1];
                        }

                        return v;
                    }));
                }}
                renderInput={(params: any) => (
                    <TextField
                        {...params}
                        variant='standard'
                        label='Tag'
                    />
                )}
            />
        </CustomDialogWrapper>
    );
};

export default EditUserTagsDialog;
