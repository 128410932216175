import { useMemo } from 'react';

import GenericObject from '../../../../../../../typesAdditional/GenericObject';

interface Props {
    formPrefix: string;
    data: GenericObject;
    validationResult: GenericObject;
}

const useValidateBasicData = (props: Props) => {
    const { formPrefix, data, validationResult } = props;

    const validation = validationResult[formPrefix];

    return useMemo(() => {
        return {
            'tax_code': validation?.taxCode?.valid ?? false,
            'date_of_birth': !(
                validation?.taxCode?.dateOfBirth &&
                data[formPrefix]?.date_of_birth &&
                (validation?.taxCode?.dateOfBirth.substring(2) !== data[formPrefix]?.date_of_birth.substring(2))
            )
        };
    }, [data, validation, formPrefix]);
};

export default useValidateBasicData;
