import { ButtonProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import CustomDialogWrapper from "../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper";
import { useEffect, useState } from "react";
import api from "../../../../../../common/utils/api";

interface Props {
    open: boolean;
    closeDialog: () => void;
    selectedPaymentIds: string | null;
    selectedRegistrationIds: string | null;
    paymentType: string;
}

const DetailRegistrationsPaymentsDialog = (props: Props) => {
    const { open, closeDialog, selectedPaymentIds, selectedRegistrationIds, paymentType } = props;

    const [isLoading, setIsLoading] = useState(false);

    const [data, setData] = useState([]);

    useEffect(() => {
        if (!open) return;

        setData([]);
        setIsLoading(true);

        if (selectedPaymentIds === null) {
            api.request('/admin/dues_collection/registration_details', 'POST', { selectedRegistrationIds }).then((res: any) => {
                setData(res);
                setIsLoading(false);
            });
        } else {
            api.request('/admin/dues_collection/payment_details', 'POST', { selectedPaymentIds }).then((res: any) => {
                setData(res);
                setIsLoading(false);
            });
        }
    }, [selectedPaymentIds, selectedRegistrationIds, open]);

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        },
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Dettagli quote ' + (selectedPaymentIds === null ? 'da incassare' : 'incassate')} buttons={buttons} maxWidth='md' isLoading={isLoading} fullWidth>
            <TableContainer style={{ marginTop: '0px' }}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell>cognome</TableCell>
                            <TableCell>nome</TableCell>
                            <TableCell>corso</TableCell>
                            <TableCell>paese</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                {selectedPaymentIds === null ? 'quota da incassare' : (paymentType === 'sepa' ? 'quota incassata (bonifico)' : 'quota incassata (contanti)')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {data.map((row: any) => {
                            if (selectedRegistrationIds !== null && ((parseFloat(row.cost) - parseFloat(row.paidSepa) - parseFloat(row.paidCash))) < 0.001) return null;
                            if (selectedPaymentIds !== null && paymentType === 'sepa' && parseFloat(row.paidSepa) < 0.001) return null;
                            if (selectedPaymentIds !== null && paymentType === 'cash' && parseFloat(row.paidCash) < 0.001) return null;

                            return (
                                <TableRow>
                                    <TableCell>{row.surname}</TableCell>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>{row.courseName}</TableCell>
                                    <TableCell>{row.townName}</TableCell>
                                    <TableCell style={{ textAlign: 'right' }}>€ {(parseFloat((selectedPaymentIds === null ? (parseFloat(row.cost) - parseFloat(row.paidSepa) - parseFloat(row.paidCash)) : (paymentType === 'sepa' ? row.paidSepa : row.paidCash)))).toFixed(2)}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </CustomDialogWrapper>
    )
}

export default DetailRegistrationsPaymentsDialog;