import { useSelector } from 'react-redux';

import { ReduxState } from '../../../../../../redux/types';
import FallbackLoginButton from './components/FallbackLoginButton/FallbackLoginButton';
import LoggedUserInfo from './components/LoggedUserInfo/LoggedUserInfo';
import { StyledDivider } from './styled';

const UserInfoSection = () => {
    const userInfo = useSelector((state: ReduxState) => state.user);
    const isUserLoggedIn = userInfo.id.length > 0;

    return (
        <>
            {isUserLoggedIn ? <LoggedUserInfo /> : <FallbackLoginButton />}

            <StyledDivider variant='middle' />
        </>
    );
};

export default UserInfoSection;
