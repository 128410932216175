import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import validator from 'validator';

import CustomTextField from '../../../../common/components/CustomTextField/CustomTextField';
import useGenericErrorDialog from '../../../../common/hooks/useGenericErrorDialog/useGenericErrorDialog';
import useInternalLoader from '../../../../common/hooks/useInternalLoader/useExternalLoader';
import { useMessageDialog } from '../../../../common/hooks/useMessageDialog/useMessageDialog';
import useSearchParams from '../../../../common/hooks/useSearchParams/useSearchParams';
import useSubmitOnEnter from '../../../../common/hooks/useSubmitOnEnter/useSubmitOnEnter';
import api from '../../../../common/utils/api';
import snackbar from '../../../../common/utils/snackbar';
import { ReduxState } from '../../../../redux/types';
import { setFromApi } from '../../../../redux/user/userSlice';

const RegistrationPageLogin = () => {
    const { id: courseId } = useParams<{id: string}>();
    const { isIframe, initialPath } = useSelector((state: ReduxState) => state.routing);
    const { town: paramTownLegalId, showBackButtonPpsMs } = useSearchParams();

    const iframeSuffix = isIframe ? '&iframe' : '';

    const setExternalIsLoading = useInternalLoader();
    const history = useHistory();
    const dispatch = useDispatch();

    const [courseData, setCourseData] = useState<any>(null);

    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');

    const [showMessageDialog] = useMessageDialog();
    const showGenericErrorDialog = useGenericErrorDialog();

    useEffect(() => {
        api.request('/courses/' + courseId).then(res => {
            setCourseData(res);
        }).catch(() => {
            history.push('/');
        }).finally(() => {
            setExternalIsLoading(false);
        });
    }, [setExternalIsLoading, courseId, history]);

    const onEmailChange = ((event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    });

    const onSubmit = useCallback(() => {
        if (isLoading) return;

        setIsLoading(true);

        api.userLogin(email, true).then((status: string) => {
            if (status === 'login_ok') {
                api.request('/auth/me').then(res => {
                    snackbar.success('Accesso effettuato con successo!');
                    dispatch(setFromApi(res));
                }).catch((res) => {
                    showGenericErrorDialog(res);
                });
            } else if (status === 'email_magic_link') {
                showMessageDialog({
                    title: 'Completa l\'iscrizione',
                    message: 'Ti abbiamo inviato un messaggio all\'indirizzo email specificato. Clicca sul link che hai ricevuto per completare l\'iscrizione.',
                    notDismissible: true
                });
            } else {
                showGenericErrorDialog();
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }, [showGenericErrorDialog, dispatch, email, isLoading, showMessageDialog]);

    const handleKeyDown = useSubmitOnEnter(onSubmit);

    const isEmailValid = validator.isEmail(email.trim());

    return (
        <>
            <Paper elevation={isIframe ? 0 : 3} style={{padding: '8px', margin: '6px 24px'}}>
                {!(isIframe && /^\/courses\/[a-z0-9-]+\/(|pps_ms_)registration/.test(initialPath ?? '')) && (
                    <>
                        <Typography variant='h2' style={{fontSize: '3em', textAlign: 'center', margin: '8px'}}>
                            {courseData?.name}
                        </Typography>
                        <Typography variant='h4' style={{fontSize: '1.4em', textAlign: 'center', margin: '8px'}}>
                            Modulo di iscrizione
                        </Typography>
                    </>
                )}

                <div style={{maxWidth: '600px', margin: '16px auto'}}>
                    <Typography variant='body1' style={{margin: '8px'}}>
                        Per iniziare la procedura di iscrizione, inserisci il tuo indirizzo email:
                    </Typography>

                    <CustomTextField keepMounted error={!isEmailValid} style={{margin: '16px 0'}} variant='outlined' label='Indirizzo email' value={email} onChange={onEmailChange} onKeyDown={handleKeyDown} disabled={isLoading} autoFocus={!isIframe} fullWidth />

                    <div style={{width: '100%', textAlign: 'center', marginTop: '4px', height: '40px'}}>
                        {isLoading ? <CircularProgress /> : (
                            <>
                                {!!showBackButtonPpsMs && (
                                    <Button variant='outlined' color='primary' onClick={() => history.push('/courses/' + courseId + '/pps_ms_registration?town=' + paramTownLegalId + iframeSuffix)} disabled={isLoading} style={{marginRight: '8px'}} >
                                       Indietro
                                    </Button>
                                )}
                                <Button variant='contained' color='primary' onClick={onSubmit} disabled={isLoading} >
                                   Avanti
                                </Button>
                            </>
                        )}
                    </div>
                </div>
            </Paper>
        </>
    );
};


export default RegistrationPageLogin;
