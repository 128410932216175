import { ForwardedRef, forwardRef } from 'react';

import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import { StyledListItem, StyledListItemText } from './styled';

interface Props {
    name: string;
    description?: string;
    disabled?: boolean;
    depth?: number;
    image?: string | JSX.Element;
    onClick?: () => void;
    onDeleteClick?: () => void;
    style?: React.CSSProperties
}

const EntityListItem = forwardRef((props: Props, ref: ForwardedRef<HTMLDivElement>) => {
    const { name, disabled, depth, description, image, onClick, onDeleteClick, style } = props;

    return (
        // @ts-ignore https://github.com/mui-org/material-ui/issues/14971
        <StyledListItem style={style} {...(onClick && !disabled && { onClick, button: true })} ref={ref} >
            {typeof image === 'string' ? (
                // if the image prop is a string, use it as the image url, otherwise render it as a component
                <ListItemAvatar>
                    <Avatar alt={name} src={image} />
                </ListItemAvatar>
            ) : image}
            <StyledListItemText primary={name} secondary={description} largepadding={onDeleteClick ? 1 : 0} style={depth ? { paddingLeft: '24px' } : {}} />

            {onDeleteClick && (
                // if onDeleteClick is defined, show the delete icon
                <ListItemSecondaryAction>
                    <IconButton onClick={onDeleteClick} style={{ marginRight: '-14px' }} >
                        <RemoveCircleOutlineIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            )}

        </StyledListItem>
    );
});

export default EntityListItem;
