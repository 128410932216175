import { useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import RouteType from '../../../typesAdditional/Route';
import routes from '../routes';
import { Container, ExternalContainer } from './styled';

interface Props {
    appbarVisible: boolean;
}

const Router = (props: Props) => {
    const { appbarVisible } = props;
    const finalRoutes = useMemo(() => {
        const fallbackRoute: RouteType = {
            name: 'Fallback redirect',
            path: '*',
            showSidebar: false,
            canAuth: true,
            component: <Redirect to={routes[0]?.path ?? '/'} />,
        };

        return  [...routes, fallbackRoute].map(route => {
            return (
                <Route
                    path={route.path}
                    exact={route.exactPath}
                    component={() => {
                        // TODO: report to Sentry if component is undefined and there aren't subroutes
                        return route.component ?? null;
                    }}
                    key={route.path}
                />
            );
        });
    }, []);

    return (
        <ExternalContainer appbarVisible={appbarVisible}>
            <Container>
                <Switch>
                    {finalRoutes}
                </Switch>
            </Container>
        </ExternalContainer>
    );
};

export default Router;
