import { useMemo } from 'react';

import { Link } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import GenericObject from '../../../../../../../../../../typesAdditional/GenericObject';

interface Props {
    personExists: boolean;
    discardPersonChoose: () => void;
    canChangePerson: boolean;
}

const InfoAlert = (props: Props) => {
    const { personExists, discardPersonChoose, canChangePerson } = props;

    const handleDiscardPersonChoose = (e: React.SyntheticEvent) => {
        discardPersonChoose();
        e.preventDefault();
        return false;
    };

    const messages: GenericObject = {
        selectedPerson: {
            title: 'Dati anagrafici precompilati',
            content: (
                <>
                    <p style={{marginTop: '0px', marginBottom: '8px'}}>
                        I dati anagrafici precompilati non possono essere modificati.
                    </p>
                    {canChangePerson && (
                        <p style={{marginTop: '0px'}}>
                            <Link href='#' onClick={handleDiscardPersonChoose}>Clicca qui</Link> se vuoi iscrivere un'altra persona.
                        </p>
                    )}
                </>
            )
        },
        existingPeople: null    // TODO?
    };

    const messageType = useMemo(() => {
        if (personExists) {
            return 'selectedPerson';
        } else {
            return null;
        }
    }, [personExists]);

    if (!messageType || !messages[messageType]) return null;

    return (
        <Alert severity='info' style={{paddingBottom: '0px', marginBottom: '24px'}}>
            <AlertTitle>{messages[messageType].title}</AlertTitle>
            {messages[messageType].content}
        </Alert>
    );
};

export default InfoAlert;
