import { useCallback, useEffect, useMemo, useState } from 'react';

import { ButtonProps } from '@material-ui/core/Button';
import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import GenericObject from '../../../../../../typesAdditional/GenericObject';
import { MenuItem, Typography } from '@material-ui/core';
import 'moment/locale/it';
import api from '../../../../../../common/utils/api';
import snackbar from '../../../../../../common/utils/snackbar';
import { StyledTextField } from '../../../../../../common/components/CustomTextField/styled';

interface Props {
    open: boolean;
    closeDialog: (shouldRefresh?: boolean) => void;
    selectedVariation?: GenericObject;
}

const ApproveVariationDialog = (props: Props) => {
    const { open, closeDialog, selectedVariation } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [action, setAction] = useState('none');

    useEffect(() => {
        if (!open) return;
        setIsLoading(false);
        setAction('none');
    }, [open]);

    const saveData = useCallback(() => {
        setIsLoading(true);
        api.request('/admin/summer_availability/variations/' + (selectedVariation?.id ?? 0) + '/approve', 'POST', { action }).then(res => {
            snackbar.success('Modifiche approvate!');
            closeDialog(true);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [closeDialog, action, selectedVariation]);

    const buttons: ButtonProps[] = [
        {
            children: 'Annulla',
            color: 'primary',
            onClick: () => closeDialog(false)
        },
        {
            children: 'Conferma',
            color: 'primary',
            onClick: () => saveData()
        }
    ];

    const hasNoOrUncertain = useMemo(() => {
        return ((selectedVariation?.updatedEntries?.no ?? 0) + (selectedVariation?.updatedEntries?.uncertain ?? 0)) > 0;
    }, [selectedVariation])

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog(false)} title={'Approva variazione'} buttons={buttons} isLoading={isLoading} maxWidth='sm' fullWidth>
            <Typography variant='body1' style={{ marginBottom: '16px' }}>
                Sei sicuro di volere approvare le modifiche selezionate?
            </Typography>

            {hasNoOrUncertain && (
                <StyledTextField
                    label={'Azione'}
                    variant='outlined'
                    style={{ marginTop: '10px', marginBottom: '4px' }}
                    value={action}
                    onChange={(e: any) => {
                        setAction(e.target.value);
                    }}
                    fullWidth
                    select
                    disabled={isLoading}
                >
                    <MenuItem key={'none'} value={'none'}>
                        mantieni nei turni le disponibilità rimosse
                    </MenuItem>
                    <MenuItem key={'delete_no'} value={'delete_no'}>
                        rimuovi dai turni le disponiblità modificate in "no"
                    </MenuItem>
                    <MenuItem key={'delete_no_uncertain'} value={'delete_no_uncertain'}>
                        rimuovi dai turni le disponiblità modificate in "no" e in "forse"
                    </MenuItem>
                </StyledTextField>
            )}
        </CustomDialogWrapper>
    );
};

export default ApproveVariationDialog;
