import { useCallback, useEffect, useState } from 'react';

import { ButtonProps, FormControl, FormControlLabel, Paper, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import { useMessageDialog } from '../../../../../../common/hooks/useMessageDialog/useMessageDialog';
import api from '../../../../../../common/utils/api';
import snackbar from '../../../../../../common/utils/snackbar';

interface Props {
    isOpen: boolean;
    onClose: (shouldRefresh?: boolean) => void;
    baseUrl: string;
    savedPhotoConsent: boolean | null;
}

const ChangePhotoConsentDialog = (props: Props) => {
    const { isOpen, onClose, baseUrl, savedPhotoConsent } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [isUntouched, setIsUntouched] = useState(true);
    const [photoConsent, setPhotoConsent] = useState<boolean | null>(null);

    useEffect(() => {
        if (photoConsent === null) {
            setPhotoConsent(savedPhotoConsent);
        }
    }, [savedPhotoConsent, photoConsent]);

    useEffect(() => {
        if (isOpen) {
            setPhotoConsent(null);
        }
    }, [isOpen]);

    const [showMessageDialog, closeMessageDialog] = useMessageDialog();

    const saveAndClose = useCallback(() => {
        if (isUntouched) {
            snackbar.success('Modifiche salvate con successo!');
            onClose();
            return;
        }

        setIsLoading(true);
        api.request(baseUrl + '/change_photo_consent', 'POST', { photoConsent }).then((res: any) => {
            snackbar.success('Modifiche salvate con successo!');
            onClose(true);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [onClose, isUntouched, baseUrl, photoConsent]);

    const internalOnClose = useCallback(() => {
        if (!isUntouched) {
            showMessageDialog({
                title: 'Ci sono delle modifiche in sospeso',
                message: 'Vuoi salvare le modifiche effettuate?',
                actions: [
                    {
                        text: 'No',
                        action: () => {
                            closeMessageDialog();
                            onClose();
                        }
                    },
                    {
                        text: 'Sì',
                        action: () => {
                            closeMessageDialog();
                            saveAndClose();
                        }
                    }
                ]
            });
        } else {
            onClose();
        }
    }, [closeMessageDialog, saveAndClose, showMessageDialog, isUntouched, onClose]);

    useEffect(() => {
        if (!isOpen) setIsUntouched(true);
    }, [isOpen]);

    const buttons: ButtonProps[] = [
        {
            children: 'Salva',
            color: 'primary',
            onClick: () => saveAndClose()
        },
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => onClose()
        }
    ];

    return (
        <CustomDialogWrapper open={isOpen} onClose={internalOnClose} title={'Modifica consenso foto'} buttons={buttons} isLoading={isLoading} maxWidth='md' fullWidth contentProps={{}} >
            <TableContainer component={Paper}>
                <Table aria-label='simple table'>
                    <TableBody>
                        <TableRow>
                            <TableCell align='left'>
                                consenso foto
                            </TableCell>
                            <TableCell align='center'>
                                <FormControl component='fieldset'>
                                    <RadioGroup aria-label='gender' name='question' value={photoConsent ? 'yes' : 'no'} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        const value = (event.target as HTMLInputElement).value === 'yes';
                                        setPhotoConsent(value);
                                        setIsUntouched(value === (!!savedPhotoConsent));
                                    }}>
                                        <FormControlLabel value='yes' control={<Radio color='primary' />} label='sì' disabled={isLoading} />
                                        <FormControlLabel value='no' control={<Radio color='primary' />} label='no' disabled={isLoading} />
                                    </RadioGroup>
                                </FormControl>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>


        </CustomDialogWrapper>
    );
};

export default ChangePhotoConsentDialog;
