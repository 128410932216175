import Button from '@material-ui/core/Button';

interface Props {
    nextStep: () => void;
    prevStep: () => void;
}

const Footer = ({ nextStep, prevStep }: Props) => {
    return (
        <div style={{textAlign: 'right'}}>
            <Button variant='outlined' color='primary' style={{width: '100px', marginRight: '8px'}} onClick={() => prevStep()} >
                Indietro
            </Button>

            <Button variant='contained' color='primary' style={{width: '100px'}} onClick={() => nextStep()} >
                OK
            </Button>
        </div>
    );
};

export default Footer;
