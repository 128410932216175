import { useState } from 'react';

import useInternalLoader from '../../../../../../../common/hooks/useInternalLoader/useExternalLoader';
import { AdditionalProps } from '../types';

const useProps = (additionalProps?: AdditionalProps) => {
    const setIsLoading = useInternalLoader(false);
    const [lastPath, setLastPath] = useState(window.location.pathname.replace(/^\/admin/, ''));

    if (additionalProps) {
        return additionalProps;
    }

    return {
        setIsLoading, lastPath, setLastPath
    };
};

export default useProps;
