import { Redirect } from 'react-router-dom';

import { faTrashAlt, faCalendarCheck, faEnvelope, faHome, faReceipt, faTag, faUserPlus, faUsers, faHandsHelping, faMoneyBillTransfer, faCalendarDays, faUserClock, faSun, faSnowflake, faSchool, faFileInvoice, faMoneyCheckAlt, faAddressBook, faCoins } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { SuperUserRoute } from '../../typesAdditional/Route';
import EmailsListPage from './pages/emailsListPage/EmailsListPage';
import EmailUserListPage from './pages/emailUserListPage/EmailUserListPage';
import LoginPage from './pages/loginPage/LoginPage';
import NewEmailPage from './pages/newEmailPage/NewEmailPage';
import NewRegistrationPage from './pages/newRegistrationPage/NewRegistrationPage';
import PeopleListPage from './pages/peopleListPage/PeopleListPage';
import ReceiptsListPage from './pages/receiptsListPage/receiptsListPage';
import ReceiptsRequestsPage from './pages/receiptsRequestsPage/ReceiptsRequestsPage';
import RegistrationLocationPage from './pages/registrationLocationPage/RegistrationLocationPage';
import RegistrationsPage from './pages/registrationsPage/RegistrationsPage';
import VouchersPage from './pages/vouchersPage/VouchersPage';
import WelfarePage from './pages/welfarePage/WelfarePage';
import NewWhatsappMessagePage from './pages/newWhatsappMessagePage/NewWhatsappMessagePage';
import WhatsappMessagesListPage from './pages/whatsappMessagesListPage/WhatsappMessagesListPage';
import BankReconciliationPage from './pages/bankReconciliationPage/BankReconciliationPage';
import SummerAvailabilityPage from './pages/summerAvailabilityPage/SummerAvailabilityPage';
import SummerSchedulePage from './pages/summerSchedulePage/SummerSchedulePage';
import TrainersListPage from './pages/trainersListPage/TrainersListPage';
import SummerPayoutsPage from './pages/summerPayoutsPage/SummerPayoutsPage';
import SummerWeeklyAvailabilityPage from './pages/summerWeeklyAvailabilityPage/SummerWeeklyAvailabilityPage';
import SummerAvailabilityVariationPage from './pages/summerAvailabilityVariationPage/SummerAvailabilityVariationPage';
import DeletedRegistrationsListPage from './pages/deletedRegistrationsListPage/DeletedRegistrationsListPage';
import DeletedRegistrationsPendingVouchersPage from './pages/deletedRegistrationsPendingVouchersPage/DeletedRegistrationsPendingVouchersPage';
import TrainersSummerUtilizationPage from './pages/trainersSummerUtilizationPage/TrainersSummerUtilizationPage';
import FacilitiesListPage from './pages/facilitiesListPage/FacilitiesListPage';
import WinterGeneralSchedulePage from './pages/winterGeneralSchedulePage/WinterGeneralSchedulePage';
import WinterSchedulePage from './pages/winterSchedulePage/WinterSchedulePage';
import WinterDetailedTimetablePage from './pages/winterDetailedTimetablePage/WinterDetailedTimetablePage';
import TrainerPayoutsReportTable from './pages/trainerPayoutsReportTable/TrainerPayoutsReportTable';
import DuesCollectPage from './pages/duesCollectPage/DuesCollectPage';
import SummerWeeklyScheduleStatusPage from './pages/summerWeeklyScheduleStatusPage/SummerWeeklyScheduleStatusPage';

const routes: SuperUserRoute[] = [
    {
        name: 'Home',
        icon: faHome,
        path: '/',
        component: <Redirect to={(window.location.host.startsWith('admin.epuntos.it') ? '' : '/admin') + '/registrations'} />,
        requireAuth: true,
        showInSidebar: false,
    },
    {
        name: 'Elenco utenti',
        icon: faUsers,
        path: '/people',
        component: <PeopleListPage />,
        requireAuth: true
    },
    {
        name: 'Rubrica istruttori',
        path: '/trainers',
        requireAuth: true,
        component: <TrainersListPage season='public' />,
        icon: faAddressBook
    },
    {
        name: 'Aggiungi iscrizione',
        icon: faUserPlus,
        path: '/registrations/new',
        component: <NewRegistrationPage />,
        requireAuth: true,
        needAdmin: true
    },
    {
        name: 'Gestione iscrizioni',
        icon: faCalendarCheck,
        path: '/registrations',
        component: <RegistrationsPage />,
        requireAuth: true
    },
    {
        name: 'Gestione iscrizioni',
        path: '/registrations/:courseId/locations/:locationId',
        component: <RegistrationLocationPage />,
        showInSidebar: false
    },
    {
        name: 'Incassi quote',
        icon: faCoins,
        path: '/dues-collection',
        component: <DuesCollectPage />,
        requireAuth: true,
        needAdmin: true,
        emailWhiteList: ['cristian@cristianlivella.com', 'ariboli@epuntos.it', 'gbuzzone@epuntos.it', 'dscaburri@epuntos.it', 'federico.viaro@gmail.com']
    },
    {
        name: 'Iscrizioni eliminate',
        icon: faTrashAlt,
        path: '/deleted-registrations',
        requireAuth: true,
        needAdmin: true,
        subRoutes: [
            {
                name: 'Elenco iscrizioni',
                path: '/deleted-registrations/list',
                needAdmin: true,
                component: <DeletedRegistrationsListPage />
            },
            {
                name: 'Voucher da emettere',
                path: '/deleted-registrations/pending-vouchers',
                needAdmin: true,
                component: <DeletedRegistrationsPendingVouchersPage />
            },
        ]
    },
    {
        name: 'Disponibilità estate',
        icon: faCalendarDays,
        path: '/summer-availability',
        component: <SummerAvailabilityPage />,
    },
    {
        name: 'Turni estate',
        icon: faUserClock,
        path: '/summer-schedule',
        component: <SummerSchedulePage />,
    },
    {
        name: 'Turni inverno',
        icon: faSchool,
        path: '/winter-schedule',
        component: <WinterGeneralSchedulePage season='winter' />,
    },
    {
        name: 'Gestione turni',
        path: '/winter-schedule/pps-ms-schedule/:townId',
        component: <WinterSchedulePage />,
        showInSidebar: false
    },
    {
        name: 'Gestione turni',
        path: '/winter-schedule/schedule/:courseLocationId',
        component: <WinterSchedulePage />,
        showInSidebar: false
    },
    {
        name: 'Compensi mensili',
        icon: faFileInvoice,
        path: '/winter-schedule/timetable',
        component: <Redirect to={(window.location.host.startsWith('admin.epuntos.it') ? '' : '/admin') + '/winter-schedule/timetable/9'} />,
    },
    {
        name: 'Compensi mensili',
        icon: faFileInvoice,
        path: '/winter-schedule/timetable/:month',
        component: <WinterDetailedTimetablePage season='winter' />,
        showInSidebar: false
    },
    {
        name: 'Report compensi',
        icon: faMoneyCheckAlt,
        path: '/payouts-report',
        component: <Redirect to={(window.location.host.startsWith('admin.epuntos.it') ? '' : '/admin') + '/payouts-report/2024'} />,
    },
    {
        name: 'Report compensi',
        icon: faMoneyCheckAlt,
        path: '/payouts-report/:year',
        component: <TrainerPayoutsReportTable season='winter' />,
        showInSidebar: false
    },
    {
        name: 'Gestione estate',
        icon: faSun,
        path: '/staff',
        needAdmin: true,
        subRoutes: [
            {
                name: 'Elenco istruttori',
                path: '/staff/trainers',
                needAdmin: true,
                component: <TrainersListPage season='summer' />
            },
            {
                name: 'Disponibilità istruttori',
                path: '/staff/summer-availabilities',
                needAdmin: true,
                component: <SummerWeeklyAvailabilityPage />
            },
            {
                name: 'Istruttori richiesti',
                path: '/staff/required-trainers',
                needAdmin: true,
                component: <SummerWeeklyScheduleStatusPage />
            },
            {
                name: 'Utilizzo istruttori',
                path: '/staff/summer-availabilities-utilization',
                needAdmin: true,
                component: <TrainersSummerUtilizationPage />
            },
            {
                name: 'Richieste variazione',
                path: '/staff/summer-availabilities-variations',
                needAdmin: true,
                component: <SummerAvailabilityVariationPage />
            },
            {
                name: 'Compensi',
                path: '/staff/summer-payouts',
                needAdmin: true,
                component: <SummerPayoutsPage season='summer' />
            },
            {
                name: 'Gestione turni',
                path: '/staff/summer-schedule',
                needAdmin: true,
                component: <SummerSchedulePage />
            },
        ]
    },
    {
        name: 'Gestione inverno',
        icon: faSnowflake,
        path: '/staff-winter',
        needAdmin: true,
        subRoutes: [
            {
                name: 'Elenco istruttori',
                path: '/staff-winter/trainers',
                needAdmin: true,
                component: <TrainersListPage season='winter' />
            },
            {
                name: 'Elenco palestre',
                path: '/staff-winter/facilities',
                needAdmin: true,
                component: <FacilitiesListPage season='winter' />
            },
            {
                name: 'Compensi',
                path: '/staff-winter/payouts',
                needAdmin: true,
                component: <SummerPayoutsPage season='winter' />
            },
            {
                name: 'Costi palestre',
                path: '/staff-winter/facilities-costs',
                needAdmin: true,
                component: <SummerPayoutsPage season='winter_facilities' />
            },
            {
                name: 'Timetable',
                path: '/staff-winter/detailed-timetable/:trainerId/:month',
                needAdmin: true,
                component: <WinterDetailedTimetablePage season='winter' />,
                showInSidebar: false
            },
            {
                name: 'Facility timetable',
                path: '/staff-winter/detailed-facility-timetable/:facilityId/:month',
                needAdmin: true,
                component: <WinterDetailedTimetablePage season='winter' />,
                showInSidebar: false
            },
            {
                name: 'Timetable',
                path: '/staff-winter/payouts-report/:trainerId/:year',
                needAdmin: true,
                component: <TrainerPayoutsReportTable season='winter' />,
                showInSidebar: false
            },
            {
                name: 'Gestione turni',
                path: '/staff-winter/general-schedule',
                needAdmin: true,
                component: <WinterGeneralSchedulePage season='winter' />
            },
            {
                name: 'Gestione turni',
                path: '/staff-winter/pps-ms-schedule/:townId',
                needAdmin: true,
                component: <WinterSchedulePage />,
                showInSidebar: false
            },
            {
                name: 'Gestione turni',
                path: '/staff-winter/schedule/:courseLocationId',
                needAdmin: true,
                component: <WinterSchedulePage />,
                showInSidebar: false
            },
        ]
    },
    {
        name: 'Importa pagamenti',
        icon: faMoneyBillTransfer,
        path: '/bank_reconciliation',
        component: <BankReconciliationPage />,
        needAdmin: true,
    },
    {
        name: 'Voucher',
        icon: faTag,
        path: '/vouchers',
        component: <VouchersPage />,
        needAdmin: true,
    },
    {
        name: 'Welfare',
        icon: faHandsHelping,
        path: '/welfare',
        component: <WelfarePage />,
        needAdmin: true,
    },
    {
        name: 'Invio email',
        icon: faEnvelope,
        path: '/emails',
        needAdmin: true,
        subRoutes: [
            {
                name: 'Nuova email',
                path: '/emails/new',
                needAdmin: true,
                component: <NewEmailPage />
            },
            {
                name: 'Storico invii',
                path: '/emails/list',
                needAdmin: true,
                component: <EmailsListPage />
            },
            {
                name: 'Lista contatti',
                path: '/emails/users',
                needAdmin: true,
                component: <EmailUserListPage />
            }
        ]
    },
    {
        name: 'WhatsApp',
        icon: faWhatsapp,
        path: '/whatsapp',
        needAdmin: true,
        subRoutes: [
            {
                name: 'Nuovo messaggio',
                path: '/whatsapp/new',
                needAdmin: true,
                component: <NewWhatsappMessagePage />
            },
            {
                name: 'Storico invii',
                path: '/whatsapp/list',
                needAdmin: true,
                component: <WhatsappMessagesListPage />
            }
        ]
    },
    {
        name: 'Ricevute',
        icon: faReceipt,
        path: '/receipts',
        needAdmin: true,
        subRoutes: [
            {
                name: 'Da emettere',
                path: '/receipts/requests',
                needAdmin: true,
                component: <ReceiptsRequestsPage />
            },
            {
                name: 'Emesse',
                path: '/receipts/list',
                needAdmin: true,
                component: <ReceiptsListPage />
            }
        ]
    },
    {
        name: 'Login',
        path: '/login',
        component: <LoginPage />,
        showSidebar: false,
        showInSidebar: false,
        canAuth: false,
        requireAuth: false
    }
];

const defaultAttributes: Partial<SuperUserRoute> = {
    exactPath: true,
    exactPathSidebar: true,
    showSidebar: true,
    showInSidebar: true,
    requireAuth: true,
    canAuth: true
};

const setDefaultAttributes = (routess: SuperUserRoute[]): SuperUserRoute[] => {
    return routess.map(route => {
        const realRoute = route.subRoutes ? {
            ...route,
            subRoutes: setDefaultAttributes(route.subRoutes)
        } : route;

        return { ...defaultAttributes, ...realRoute };
    });
};

// default attributes and real attributes are merged
const finalRoutes = setDefaultAttributes(routes);

export default finalRoutes;
