import { useCallback, useEffect, useState } from 'react';

import { ButtonProps } from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import CustomTextField from '../../../../../../common/components/CustomTextField/CustomTextField';
import { StyledTextField } from '../../../../../../common/components/CustomTextField/styled';
import { useMessageDialog } from '../../../../../../common/hooks/useMessageDialog/useMessageDialog';
import api from '../../../../../../common/utils/api';
import { Link } from '@material-ui/core';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    updateData: () => void;
    info: any;
}

const NewLinkDialog = (props: Props) => {
    const { isOpen, onClose, updateData, info } = props;

    const [isLoading, setIsLoading] = useState(false);

    const [showMessageDialog] = useMessageDialog();

    const [welfareProviderId, setWelfareProviderId] = useState<null | string>(null);
    const [description, setDescription] = useState('');
    const [courseOptionId, setCourseOptionId] = useState<null | string>(null);
    const [numberOfWeeks, setNumberOfWeeks] = useState(1);

    useEffect(() => {
        if (welfareProviderId === null && info?.providers?.length > 0) {
            setWelfareProviderId(info.providers[0].id);
        }
    }, [welfareProviderId, info])

    const handleSave = useCallback(() => {
        setIsLoading(true);
        api.request('/admin/welfare/links', 'POST', {
            welfareProviderId,
            description,
            courseOptionId,
            numberOfWeeks
        }).then((res: any) => {
            const courseOption = (info?.courseOptions ?? []).find((c: any) => c.id === courseOptionId);

            showMessageDialog({
                title: 'Link generato con successo',
                message: (
                    <>
                        <p style={{ margin: 0 }}>
                            Modulo iscrizione <b>{courseOption.course.name}</b>, <b>{res.numberOfWeeks}</b> settiman{res.numberOfWeeks === 1 ? 'a' : 'e'} (<b>€{(res.amount).toFixed(2)}</b>):
                        </p>
                        <p style={{ margin: 0, marginTop: '14px' }}>
                            <Link href={res.link} target='_blank'>{res.link}</Link>
                        </p>
                    </>
                )
            });
            onClose();
            updateData();
        }).finally(() => {
            setIsLoading(false);
        });
    }, [info, onClose, showMessageDialog, updateData, welfareProviderId, description, courseOptionId, numberOfWeeks]);

    useEffect(() => {
        if (isOpen) {
            setDescription('');
            setNumberOfWeeks(1);
        }
    }, [isOpen]);

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'secondary',
            onClick: () => onClose()
        },
        {
            children: 'Salva',
            color: 'primary',
            onClick: () => handleSave()
        },
    ];

    return (
        <>
            <CustomDialogWrapper open={isOpen} onClose={() => onClose()} title={'Crea nuovo link welfare'} buttons={buttons} isLoading={isLoading} maxWidth='md' fullWidth >
                <CustomTextField
                    select
                    label={'Piattaforma Welfare'}
                    value={welfareProviderId}
                    onChange={(e: any) => {
                        setWelfareProviderId(e.target.value);
                    }}
                    variant='outlined'
                    fullWidth
                    disabled={isLoading}
                >
                    {(info?.providers ?? []).map((provider: any) => (
                        <MenuItem key={provider.id} value={provider.id} style={{ whiteSpace: 'break-spaces' }}>
                            {provider.name}
                        </MenuItem>
                    ))}
                </CustomTextField>

                <StyledTextField
                    label={'Riferimento interno'}
                    variant='outlined'
                    type='email'
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    disabled={isLoading}
                    fullWidth
                />

                <CustomTextField
                    select
                    label={'Corso'}
                    value={courseOptionId}
                    onChange={(e: any) => {
                        setCourseOptionId(e.target.value);
                        setNumberOfWeeks(1);
                    }}
                    variant='outlined'
                    fullWidth
                    disabled={isLoading}
                    style={{ marginTop: '12px' }}
                >
                    {(info?.courseOptions ?? []).map((option: any) => (
                        <MenuItem key={option.id} value={option.id} style={{ whiteSpace: 'break-spaces' }}>
                            {option.course.name}
                        </MenuItem>
                    ))}
                </CustomTextField>

                <CustomTextField
                    select
                    label={'Numero settimane'}
                    value={numberOfWeeks}
                    onChange={(e: any) => {
                        setNumberOfWeeks(e.target.value);
                    }}
                    variant='outlined'
                    fullWidth
                    disabled={isLoading}
                >
                    {Array.from({ length: (info?.courseOptions ?? []).find((c: any) => c.id === courseOptionId)?.maxWeeks ?? 1 }, (_, i) => i + 1).map((i: any) => (
                        <MenuItem key={i} value={i} style={{ whiteSpace: 'break-spaces' }}>
                            {i}
                        </MenuItem>
                    ))}
                </CustomTextField>

                <CustomTextField
                    label='Valore welfare'
                    variant='outlined'
                    value={'€ ' + (numberOfWeeks * ((info?.courseOptions ?? []).find((c: any) => c.id === courseOptionId)?.price ?? 0).toFixed(2))}
                    inputProps={{ readOnly: true }}
                />

            </CustomDialogWrapper>
        </>
    );
};

export default NewLinkDialog;
