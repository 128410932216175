import { useCallback, useEffect, useMemo, useState } from 'react';
import { useWindowSize } from 'react-use';

import { Button, Card, CardContent, Divider, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';

import useInternalLoader from '../../../../common/hooks/useInternalLoader/useExternalLoader';
import api from '../../../../common/utils/api';
import GenericObject from '../../../../typesAdditional/GenericObject';
import MenuItem from '@material-ui/core/MenuItem';
import CustomTextField from '../../../../common/components/CustomTextField/CustomTextField';
import React from 'react';
import DetailRegistrationsPaymentsDialog from './components/DetailRegistrationsPaymentsDialog/DetailRegistrationsPaymentsDialog';
import EditDuesDialog from './components/EditDuesDialog/EditDuesDialog';

const DuesCollectPage = () => {
    const setIsLoading = useInternalLoader();

    const { width } = useWindowSize();

    const [seasons, setSeasons] = useState<GenericObject[]>([]);
    const [seasonId, setSeasonId] = useState('');

    const [data, setData] = useState<any>({});

    const [isDetailRegistrationsPaymentsDialogOpen, setIsDetailRegistrationsPaymentsDialogOpen] = useState(false);
    const [selectedPaymentIds, setSelectedPaymentIds] = useState<string | null>(null);
    const [selectedRegistrationIds, setSelectedRegistrationIds] = useState<string | null>(null);
    const [paymentType, setPaymentType] = useState('sepa');

    const [isEditDuesDialogOpen, setIsEditDuesDialogOpen] = useState(false);
    const [editDuesCourseId, setEditDuesCourseId] = useState<string | null>(null);

    useEffect(() => {
        api.request('/admin/seasons').then((resSeasons: any) => {
            setSeasons(resSeasons);

            const wantedSeasonId = localStorage.getItem('courses_season_id') ?? '6b56856c-0bda-11ef-882b-7bf14585cd75';

            if (resSeasons.find((s: any) => s.id === wantedSeasonId)) {
                setSeasonId(wantedSeasonId ?? '');
            } else {
                setSeasonId(resSeasons[0]?.id ?? '');
            }
        }).catch(() => {
            setIsLoading(false);
        });
    }, [setIsLoading]);

    const refreshData = useCallback(() => {
        if (!seasonId) return;

        setIsLoading(true);

        api.request('/admin/dues_collection/' + seasonId).then((res: any) => {
            setData(res);
            setIsLoading(false);
        })
    }, [seasonId, setIsLoading]);

    useEffect(() => {
        refreshData();
    }, [refreshData]);

    const finalData = useMemo(() => {
        const courses = (data?.courses ?? []).reduce((prev: any, curr: any) => {
            const currCategory = { ...(prev.find(((x: any) => x.categoryName === curr.courseName)) ?? { categoryId: curr.categoryId, categoryName: curr.courseName, courseOrder: curr.courseOrder, categoryOrder: curr.categoryOrder, towns: [], totals: { toPay: 0, paidCash: 0, paidSepa: 0 } }) };
            currCategory.towns = [...currCategory.towns, curr];

            currCategory.totals.toPay += Math.max(0, parseFloat(curr.totalCosts) - (parseFloat(curr.paidCash) + parseFloat(curr.paidSepa)));
            currCategory.totals.paidCash += parseFloat(curr.paidCash);
            currCategory.totals.paidSepa += parseFloat(curr.paidSepa);

            return [...prev.filter((x: any) => x.categoryName !== curr.courseName), currCategory];
        }, []).sort((a: any, b: any) => {
            if (a.categoryOrder !== b.categoryOrder) {
                return a.categoryOrder - b.categoryOrder;
            }

            if (a.courseOrder !== b.courseOrder) {
                return a.courseOrder - b.courseOrder;
            }

            return a.categoryName.localeCompare(b.categoryName);
        });

        const schools = [{
            categoryId: 'schools',
            categoryName: 'Scuole',
            towns: (data?.schools ?? []).map((school: any) => {
                return {
                    id: school.id,
                    townName: school.name,
                    totalCosts: school.tmpExpectedDues,
                    paidSepa: school.tmpPaidDues,
                    paidCash: 0
                };
            }).sort((a: any, b: any) => {
                return a.townName.localeCompare(b.townName);
            }),
            totals: (data?.schools ?? []).reduce((prev: any, curr: any) => {
                const clonedData = { ...prev };

                clonedData.toPay += Math.max(0, parseFloat(curr.tmpExpectedDues) - (parseFloat(curr.tmpPaidDues)));
                clonedData.paidSepa += parseFloat(curr.tmpPaidDues);

                return clonedData;
            }, { toPay: 0, paidCash: 0, paidSepa: 0 })
        }]

        return [...courses, ...schools];
    }, [data]);

    const totalData = useMemo(() => {
        return (data?.courses ?? []).reduce((prev: any, curr: any) => {
            const clonedData = { ...prev };

            clonedData.toPay += Math.max(0, parseFloat(curr.totalCosts) - (parseFloat(curr.paidCash) + parseFloat(curr.paidSepa)));
            clonedData.paidCash += parseFloat(curr.paidCash);
            clonedData.paidSepa += parseFloat(curr.paidSepa);

            return clonedData;
        }, { toPay: 0, paidCash: 0, paidSepa: 0 });
    }, [data]);

    const totalDataSchools = useMemo(() => {
        return (data?.schools ?? []).reduce((prev: any, curr: any) => {
            const clonedData = { ...prev };

            clonedData.toPay += Math.max(0, parseFloat(curr.tmpExpectedDues) - (parseFloat(curr.tmpPaidDues)));
            clonedData.paidSepa += parseFloat(curr.tmpPaidDues);

            return clonedData;
        }, { toPay: 0, paidCash: 0, paidSepa: 0 });
    }, [data]);

    const isMobile = width <= 700;

    const nFormat = new Intl.NumberFormat('it-iT', { minimumFractionDigits: 2 });

    return (
        <>
            <div>
                <div style={{ display: isMobile ? undefined : 'flex', textAlign: isMobile ? 'center' : undefined }}>
                    <Typography variant='h2' style={{ fontSize: isMobile ? '3em' : '3.6em', textAlign: isMobile ? 'center' : 'left' }}>
                        Incassi quote
                    </Typography>

                    <div style={{ flex: 1, flexGrow: 1 }} />

                    <div style={{ flex: '1', flexGrow: 1, maxWidth: '200px', marginTop: '4px' }}>
                        <CustomTextField
                            select
                            label={'Stagione iscrizioni'}
                            value={seasonId}
                            onChange={(e: any) => {
                                setSeasonId(e.target.value);
                                localStorage.setItem('courses_season_id', e.target.value);
                            }}
                            variant='outlined'
                            required
                            fullWidth
                        >
                            {seasons.map((opt: any) => (
                                <MenuItem key={opt.id} value={opt.id} style={{ whiteSpace: 'break-spaces' }}>
                                    {opt.name}
                                </MenuItem>
                            ))}
                        </CustomTextField>
                    </div>
                </div>

                <Divider style={{ margin: '8px 0 12px' }} />
            </div>

            <div style={{ display: isMobile ? undefined : 'flex', flexDirection: 'row', margin: isMobile ? undefined : '0 -6px' }}>
                <Card style={{ flex: 1, margin: '0 6px', textAlign: 'center', marginTop: '6px' }}>
                    <CardContent style={{ padding: '4px 8px' }}>
                        <p style={{ fontSize: '1.4em', marginTop: '10px', marginBottom: 0 }}>Quote da incassare</p>
                        <p style={{ fontSize: '2.1em', margin: '10px 0' }}>€ {nFormat.format(totalData.toPay + totalDataSchools.toPay)}</p>
                    </CardContent>
                </Card>

                <Card style={{ flex: 1, margin: '0 6px', textAlign: 'center', marginTop: '6px' }}>
                    <CardContent style={{ padding: '4px 8px' }}>
                        <p style={{ fontSize: '1.4em', marginTop: '10px', marginBottom: 0 }}>Quote incassate (bonifico)</p>
                        <p style={{ fontSize: '2.1em', margin: '10px 0' }}>€ {nFormat.format(totalData.paidSepa + totalDataSchools.paidSepa)}</p>
                    </CardContent>
                </Card>

                <Card style={{ flex: 1, margin: '0 6px', textAlign: 'center', marginTop: '6px' }}>
                    <CardContent style={{ padding: '4px 8px' }}>
                        <p style={{ fontSize: '1.4em', marginTop: '10px', marginBottom: 0 }}>Quote incassate (contanti)</p>
                        <p style={{ fontSize: '2.1em', margin: '10px 0' }}>€ {nFormat.format(totalData.paidCash)}</p>
                    </CardContent>
                </Card>
            </div>

            <TableContainer component={Paper} style={{ marginTop: '16px' }}>
                <Table size='small'>
                    {finalData.map((category: any, categoryIdx: number) => {
                        return (
                            <>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={5} style={{ backgroundColor: '#edf7ed', fontSize: '1.3em', textAlign: 'center' }}>
                                            {category.categoryName}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow style={{ height: '4px' }} />
                                    <TableRow>
                                        <TableCell>Paese</TableCell>
                                        <TableCell style={{ textAlign: 'right' }}>Quote da incassare</TableCell>
                                        <TableCell style={{ textAlign: 'right' }}>Quote incassate (bonifico)</TableCell>
                                        <TableCell style={{ textAlign: 'right' }}>Quote incassate (contanti)</TableCell>
                                        <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }} />
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {category.towns.map((town: any) => {
                                        return (
                                            <TableRow>
                                                <TableCell>{town.townName}</TableCell>
                                                <TableCell style={{ textAlign: 'right' }}>
                                                    <Link href='#' onClick={(e) => {
                                                        e.preventDefault();
                                                        setIsDetailRegistrationsPaymentsDialogOpen(true);
                                                        setSelectedPaymentIds(null);
                                                        setSelectedRegistrationIds(town.registrationIds);
                                                        setPaymentType('sepa');
                                                    }}
                                                    >
                                                        € {nFormat.format(Math.max(0, parseFloat(town.totalCosts) - (parseFloat(town.paidCash) + parseFloat(town.paidSepa))))}
                                                    </Link>
                                                </TableCell>
                                                <TableCell style={{ textAlign: 'right' }}>
                                                    <Link href='#' onClick={(e) => {
                                                        e.preventDefault();
                                                        setIsDetailRegistrationsPaymentsDialogOpen(true);
                                                        setSelectedPaymentIds(town.paymentIds);
                                                        setSelectedRegistrationIds(null);
                                                        setPaymentType('sepa');
                                                    }}>
                                                        <>
                                                            € {nFormat.format(parseFloat(town.paidSepa))}
                                                        </>
                                                    </Link>
                                                </TableCell>
                                                <TableCell style={{ textAlign: 'right' }}>
                                                    {category.categoryId === 'schools' ? (
                                                        <>
                                                            n.a.
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Link href='#' onClick={(e) => {
                                                                e.preventDefault();
                                                                setIsDetailRegistrationsPaymentsDialogOpen(true);
                                                                setSelectedPaymentIds(town.paymentIds);
                                                                setSelectedRegistrationIds(null);
                                                                setPaymentType('cash');
                                                            }}>
                                                                € {nFormat.format(parseFloat(town.paidCash))}
                                                            </Link>
                                                        </>
                                                    )}
                                                </TableCell>
                                                <TableCell style={{ width: '1px', whiteSpace: 'nowrap', paddingTop: '3px', paddingBottom: '3px' }}>
                                                    {category.categoryId === 'schools' && (
                                                        <>
                                                            <Button size='small' variant='outlined' color='primary' onClick={() => {
                                                                setEditDuesCourseId(town.id);
                                                                setIsEditDuesDialogOpen(true);
                                                            }}>
                                                                Modifica
                                                            </Button>
                                                        </>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}

                                    <TableRow>
                                        <TableCell style={{ fontWeight: 'bold' }}>TOTALE</TableCell>
                                        <TableCell style={{ textAlign: 'right', fontWeight: 'bold' }}>€ {nFormat.format(parseFloat(category.totals.toPay))}</TableCell>
                                        <TableCell style={{ textAlign: 'right', fontWeight: 'bold' }}>€ {nFormat.format(parseFloat(category.totals.paidSepa))}</TableCell>
                                        <TableCell style={{ textAlign: 'right', fontWeight: 'bold' }}>
                                            {category.categoryId === 'schools' ? (
                                                <>
                                                    n.a.
                                                </>
                                            ) : (
                                                <>
                                                    € {nFormat.format(parseFloat(category.totals.paidCash))}
                                                </>
                                            )}
                                        </TableCell>
                                        <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }} />
                                    </TableRow>

                                    {categoryIdx !== (finalData.length - 1) && (
                                        <TableRow style={{ height: '40px' }}>
                                            <TableCell colSpan={5} style={{ backgroundColor: '#fafafa' }} />
                                        </TableRow>
                                    )}
                                </TableBody >
                            </>
                        )
                    })}
                </Table>
            </TableContainer>

            <DetailRegistrationsPaymentsDialog
                open={isDetailRegistrationsPaymentsDialogOpen}
                closeDialog={() => setIsDetailRegistrationsPaymentsDialogOpen(false)}
                selectedPaymentIds={selectedPaymentIds}
                selectedRegistrationIds={selectedRegistrationIds}
                paymentType={paymentType}
            />

            <EditDuesDialog
                open={isEditDuesDialogOpen}
                closeDialog={() => {
                    setIsEditDuesDialogOpen(false);
                    refreshData();
                }}
                externalSelectedCourseId={editDuesCourseId}
            />
        </>
    );
};

export default DuesCollectPage;
