import Typography from '@material-ui/core/Typography';
import styled from 'styled-components/macro';

export const Container = styled(Typography)<{ isMobile: boolean }>`
    font-size: 3em;
    display: ${p => p.isMobile ? 'block' : 'flex'};
    margin: ${p => p.isMobile ? '0 6px 8px' : '0 22px 8px'}
`;

export const Title = styled.div<{ isIframe: boolean; isMobile: boolean }>`
    margin: 0;
    flex: 1;
    text-align: ${p => (p.isIframe || p.isMobile) ? 'center' : 'left'};

    ${p => p.isIframe && `
        font-size: 25px;
        font-family: 'Libre Franklin', sans-serif;
    `}
`;
