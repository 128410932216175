import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { v4 as uuidv4 } from 'uuid';

import { addExternalLoader, addInternalLoader, removeExternalLoader, removeInternalLoader } from '../../../redux/loaders/loadersSlice';

interface Props {
    initialIsLoading?: boolean;
    type: 'external' | 'internal';
}

const useLoader = (props: Props) => {
    const { initialIsLoading, type } = props;

    const [guid] = useState(uuidv4());
    const [internalIsLoading, setInternalIsLoading] = useState(false);
    const dispatch = useDispatch();

    const [addLoader, removeLoader] = useMemo(() => {
        if (type === 'external') {
            return [
                addExternalLoader,
                removeExternalLoader
            ];
        } else {
            return [
                addInternalLoader,
                removeInternalLoader
            ];
        }
    }, [type]);

    const setIsLoading = useCallback((isLoading: boolean) => {
        if (isLoading) {
            dispatch(addLoader(guid));
        } else {
            dispatch(removeLoader(guid));
        }
    }, [dispatch, addLoader, removeLoader, guid]);

    useEffect(() => {
        setInternalIsLoading(initialIsLoading === undefined ? true : initialIsLoading);
    }, [initialIsLoading]);

    useEffect(() => {
        return () => {
            setInternalIsLoading(false);
        };
    }, [setInternalIsLoading]);

    useEffect(() => {
        setIsLoading(internalIsLoading);
    }, [internalIsLoading, setIsLoading]);

    return setIsLoading;
};

export default useLoader;
