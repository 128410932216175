import Button, { ButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent, { DialogContentProps } from '@material-ui/core/DialogContent';
import DialogTitle, { DialogTitleProps } from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components/macro';

import CustomDialog, { Props as CustomDialogProps } from '../CustomDialog/CustomDialog';
import { Resizable } from 're-resizable';
import { useWindowSize } from 'react-use';

interface Props extends CustomDialogProps {
    title?: string;
    buttons?: ButtonProps[];
    titleProps?: DialogTitleProps;
    contentProps?: DialogContentProps;
    isLoading?: boolean;
    hideTitle?: boolean;
    hideActions?: boolean;
    resizable?: boolean;
}

const CustomDialogWrapper = (props: Props) => {
    const { title, buttons, titleProps, contentProps, isLoading, children, onClose, hideTitle, hideActions, resizable, ...otherProps } = props;

    const { width, height } = useWindowSize();

    return (
        <CustomDialog {...{ ...otherProps, onClose }} >
            {!hideTitle && (
                <DialogTitle {...titleProps}>
                    {title}
                    {onClose && (
                        // @ts-ignore
                        <StyledIconButton onClick={onClose}>
                            <CloseIcon />
                        </StyledIconButton>
                    )}
                </DialogTitle>
            )}

            {resizable ? (
                <Resizable
                    defaultSize={{
                        width: 600,
                        height: 298,
                    }}
                    minWidth='340px'
                    minHeight='240px'
                    maxWidth={(width - 138) + 'px'}
                    maxHeight={(height - 138) + 'px'}
                    resizeRatio={2}
                >
                    <DialogContent dividers {...contentProps} >
                        {children}
                    </DialogContent>
                </Resizable>
            ) : (
                <DialogContent dividers {...contentProps} >
                    {children}
                </DialogContent>
            )}

            {!hideActions && (
                <DialogActions>
                    {isLoading && (
                        <div style={{ position: 'absolute', left: '14px', bottom: '8px' }}>
                            <CircularProgress style={{ width: '28px', height: '28px' }} />
                        </div>
                    )}
                    {buttons?.map(buttonProps => {
                        return <Button {...{ ...buttonProps, ...(isLoading && { disabled: true }) }} />;
                    })}
                </DialogActions>
            )}
        </CustomDialog>
    );
};

const StyledIconButton = styled(IconButton)`
    position: absolute;
    right: 0;
    top: 0;
    padding: 20px;
`;

export default CustomDialogWrapper;
