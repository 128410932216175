import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

interface Props {
    id: string;
    name: string;
    description: string;
    image?: string;
    category?: string;
}

const CourseCard = (props: Props) => {
    const { id, name, description, image, category } = props;
    const history = useHistory();

    return (
        <Card variant='outlined' style={{display: 'flex', flexDirection: 'column', flex: '1', margin: '8px', minWidth: '300px'}}>
            <CardContent style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                {image && (
                    <div style={{margin: '0 auto 12px', height: '220px', width: '100%', maxWidth: (category === '1b2de58f-2793-4afb-b11e-54544bd162b7' ? 'unset' : '200px')}}>
                        <img src={image} alt='course' style={{width: '100%', height: (category === '1b2de58f-2793-4afb-b11e-54544bd162b7' ? '185px' : 'unset'), objectFit: 'cover', display: 'block'}}/>
                    </div>
                )}
                <div>
                    <Typography variant='h5' component='h2' style={{marginBottom: '4px'}}>
                        {name.toUpperCase()}
                    </Typography>
                    <Typography variant='body2' component='p'>
                        {description}
                    </Typography>
                </div>
            </CardContent>
            <CardActions>
                <Button style={{margin: '0 auto'}} color='primary' onClick={() => history.push('/courses/' + id + '/registration')}>Iscriviti</Button>
            </CardActions>
        </Card>
    );
};

export default CourseCard;
