import { Alert, AlertTitle } from '@material-ui/lab';

const LoadingSimulationAlert = () => {
    return (
        <Alert severity='info' style={{paddingBottom: '0px'}}>
            <AlertTitle>Calcolo in corso</AlertTitle>
            <p style={{marginTop: '0px'}}>
                Attendi, è in corso la computazione dei dettagli della tua iscrizione.
            </p>
        </Alert>
    );
};

export default LoadingSimulationAlert;
