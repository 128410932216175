import { FieldDefinition } from '../types';

export type FieldName = 'address.street' | 'address.street_number' | 'address.town_legal_id' | 'address.zip';

const addressFields: FieldDefinition<FieldName>[] = [
    {
        name: 'address.street',
        label: 'indirizzo di residenza'
    },
    {
        name: 'address.street_number',
        label: 'numero civico'
    },
    {
        name: 'address.town_legal_id',
        label: 'paese di residenza'
    },
    {
        name: 'address.zip',
        label: 'CAP'
    }
];

export default addressFields;
