import { useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import { downloadBlob } from 'download.js';

import api from '../../../../../../../common/utils/api';
import { Asset } from '../../../types';

interface Props {
    asset: Asset;
    handleRemove: () => void;
    isAdmin?: boolean;
}

const FileItem = (props: Props) => {
    const { asset, handleRemove } = props;

    const [isDownloading, setIsDownloading] = useState(false);

    const downloadAsset = () => {
        setIsDownloading(true);
        api.request('/admin/assets/private/' + asset.id, 'GET', undefined, true, 'blob').then(res => {
            downloadBlob(res.headers.get('X-File-Name'), res.content);
            setIsDownloading(false);
        });
    };

    return (
        <ListItem>
            <ListItemText primary={asset.originalName} />

            {(asset.status === 'uploaded' || asset.status === 'saved') && (
                <>
                    {isDownloading ? (
                        <CircularProgress style={{ width: '22px', height: '22px', marginRight: '2px' }} />
                    ) : (
                        <>
                            <IconButton style={{ padding: '4px', marginRight: '-4px' }} onClick={downloadAsset} edge='end'>
                                <GetAppIcon />
                            </IconButton>
                        </>
                    )}

                    {asset.status === 'uploaded' && (
                        <IconButton onClick={handleRemove} style={{ padding: '4px', marginRight: '-4px', marginLeft: '10px' }} edge='end'>
                            <DeleteIcon />
                        </IconButton>
                    )}
                </>
            )}

            {asset.status === 'uploading' && (
                <CircularProgress style={{ width: '22px', height: '22px' }} />
            )}
        </ListItem>
    );
};

export default FileItem;
