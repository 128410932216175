import { Dispatch, SetStateAction } from 'react';

import { ButtonProps } from '@material-ui/core/Button';

import CustomDialogWrapper from '../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import UserSegmentSelector from '../UserSegmentSeletor/UserSegmentSelector';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    filtersTree: any;
    setFiltersTree: Dispatch<SetStateAction<any>>;
    simulationResult?: any;
    setSimulationResult?: (data: any) => void;
    selectionType: 'people' | 'users';
    setSelectionType: (type: 'people' | 'users') => void;
}

const UserSegmentSelectorDialog = (props: Props) => {
    const { isOpen, onClose, filtersTree, setFiltersTree, simulationResult, setSimulationResult, selectionType, setSelectionType } = props;

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => onClose()
        },
    ];

    return (
        <CustomDialogWrapper open={isOpen} onClose={() => onClose()} title={'Seleziona Utenti'} buttons={buttons} maxWidth='sm' fullWidth>
            <UserSegmentSelector filtersTree={filtersTree} setFiltersTree={setFiltersTree} simulationResult={simulationResult} setSimulationResult={setSimulationResult} selectionType={selectionType} setSelectionType={setSelectionType} />
        </CustomDialogWrapper>
    );
};

export default UserSegmentSelectorDialog;
