import { useCallback } from 'react';
import GenericObject from '../../../../../../../typesAdditional/GenericObject';
import * as Sentry from '@sentry/react';

interface Params {
    sessions: GenericObject[];
    selectedSessions: GenericObject[];
    selectedSessionsSuggestions: GenericObject[];
    selectedLocationId: string;
    setSelectedLocationId: (locationId: string) => void;
    handleSelect: (sessionId: string, value: boolean) => void;
    handleFoodSelect: (sessionId: string, value: boolean) => void;
    handleSelectSuggestions: (sessionId: string, value: boolean) => void;
    handleFoodSelectSuggestions: (sessionId: string, value: boolean) => void;
}

const useHybridHandleSelect = (params: Params) => {
    const { sessions, selectedSessions, selectedSessionsSuggestions, selectedLocationId, setSelectedLocationId, handleSelect, handleFoodSelect, handleSelectSuggestions, handleFoodSelectSuggestions } = params;

    const genericHandleSelect = useCallback((type: 'course' | 'food', sessionId: string, value: boolean) => {
        const internalHandleSelect = type === 'course' ? handleSelect : handleFoodSelect;
        const internalHandleSuggestion = type === 'course' ? handleSelectSuggestions : handleFoodSelectSuggestions;

        const session = sessions.find(s => s.id === sessionId);
        if (!session) return;

        if (!value && selectedLocationId === session.location.id) {
            if (type === 'course' && selectedSessions.length === 1 && selectedSessions[0].id === sessionId && selectedSessionsSuggestions.length > 0) {
                internalHandleSelect(sessionId, value);

                const suggestionSessionData = sessions.find(s => s.id === selectedSessionsSuggestions[0].id);
                if (!suggestionSessionData) return;

                const newLocationId = suggestionSessionData.location.id;
                setSelectedLocationId(newLocationId);

                selectedSessionsSuggestions.forEach(suggestion => {
                    const currentSuggestionData = sessions.find(s => s.id === suggestion.id);

                    if (currentSuggestionData && currentSuggestionData.location.id === newLocationId) {
                        internalHandleSuggestion(suggestion.id, false);
                        internalHandleSelect(suggestion.id, true);
                        handleFoodSelect(suggestion.id, suggestion.foodService);
                    }
                })
            } else {
                internalHandleSelect(sessionId, value);
            }
        } else if (!value && selectedLocationId !== session.location.id) {
            internalHandleSuggestion(sessionId, value);
        } else if (selectedSessions.length === 0 && selectedLocationId !== session.location.id) {
            setSelectedLocationId(session.location.id);
            internalHandleSelect(sessionId, value)
        } else if (selectedSessions.length > 0 && selectedLocationId !== session.location.id) {
            internalHandleSuggestion(sessionId, value);
        } else if (selectedLocationId === session.location.id) {
            internalHandleSelect(sessionId, value);
        } else {
            Sentry.captureMessage('Invalid condition at useHybridgHandleSelect.');
        }
    }, [sessions, selectedSessions, selectedSessionsSuggestions, selectedLocationId, setSelectedLocationId, handleSelect, handleFoodSelect, handleSelectSuggestions, handleFoodSelectSuggestions]);

    const internalHandleSelect = useCallback((sessionId: string, value: boolean) => {
        genericHandleSelect('course', sessionId, value);
    }, [genericHandleSelect]);

    const internalHandleFoodSelect = useCallback((sessionId: string, value: boolean) => {
        genericHandleSelect('food', sessionId, value);
    }, [genericHandleSelect]);

    return {
        handleSelect: internalHandleSelect,
        handleFoodSelect: internalHandleFoodSelect
    };
};

export default useHybridHandleSelect;
