import { useEffect, useState } from 'react';

import { ButtonProps } from '@material-ui/core/Button';
import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import GenericObject from '../../../../../../typesAdditional/GenericObject';
import { Divider, LinearProgress, List, ListItem, ListItemText } from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/it';
import AvailabilityTable from '../../../summerSchedulePage/components/FindAvailabilityDialog/components/AvailabilityTable/AvailabilityTable';
import api from '../../../../../../common/utils/api';

interface Props {
    open: boolean;
    closeDialog: () => void;
    selectedTrainerId?: string;
    overrideDialogTitle?: string;
}

const AvailabilityDetailDialog = (props: Props) => {
    const { open, closeDialog, selectedTrainerId, overrideDialogTitle } = props;

    const [data, setData] = useState<GenericObject[]>([]);
    const [selectedWeek, setSelectedWeek] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!selectedTrainerId || !open) return;

        setIsLoading(true);

        api.request('/admin/summer_availability/trainers/' + selectedTrainerId).then(res => {
            setData(res);
            setSelectedWeek(res[0]?.weekId ?? '');
            setIsLoading(false);
        });
    }, [selectedTrainerId, open]);

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        },
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={overrideDialogTitle ?? 'Dettagli disponibilità'} isLoading={isLoading} buttons={buttons} maxWidth='xl' contentProps={{ style: { display: !isLoading ? 'flex' : undefined, width: '1027px' } }}>
            {(isLoading) ? (
                <LinearProgress style={{ margin: '8px 0' }} />
            ) : (
                <>
                    <div style={{ display: 'flex', marginLeft: '-24px', marginRight: '-24px', marginTop: '-16px', marginBottom: '-16px', minWidth: '900px' }}>
                        <div style={{ flex: 0.3, padding: '17px 8px', borderRight: '1px solid rgba(0, 0, 0, 0.12)', overflow: 'auto' }}>
                            <List>
                                {data.map((week: any) => {
                                    const startDate = moment(week.startDate);
                                    const endDate = moment(week.startDate).add(4, 'days');

                                    return (
                                        <ListItem button selected={week.weekId === selectedWeek} onClick={() => setSelectedWeek(week.weekId)}>
                                            <ListItemText primary={startDate.format('D MMMM') + ' - ' + endDate.format('D MMMM')} />
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </div>
                        <div style={{ flex: 0.7, padding: '14px 8px' }}>
                            <AvailabilityTable
                                selectedWeek={data.find((w: any) => w.weekId === selectedWeek) ?? {}}
                                instructor={data.find((w: any) => w.weekId === selectedWeek)?.availability ?? []}
                            />

                            {(data.find((w: any) => w.weekId === selectedWeek)?.availability ?? {}).notes ? (
                                <>
                                    <p style={{ margin: '16px 12px' }}><b>Note:</b> {(data.find((w: any) => w.weekId === selectedWeek)?.availability ?? {}).notes}</p>
                                    <Divider />
                                </>
                            ) : (
                                <div style={{ height: '53px' }} />
                            )}
                        </div>
                    </div>
                </>
            )}


        </CustomDialogWrapper >
    );
};

export default AvailabilityDetailDialog;
