import { useCallback, useState } from "react";
import GenericObject from "../../../../typesAdditional/GenericObject";
import { Button, Card, CardContent, Divider, List, ListItem, ListItemText, Tooltip, Typography } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import FindAvailabilityDialog from "./components/FindAvailabilityDialog/FindAvailabilityDialog";
import Header from "./components/Header/Header";
import { PageContainer } from "./styled";
import Footer from "./components/Footer/Footer";
import AddInstructorDialog, { Entry } from "./components/AddInstructorDialog/AddInstructorDialog";
import DynamicTooltipTrainerDescription from "./components/DynamicTooltipTrainerDescription/DynamicTooltipTrainerDescription";
import { ReduxState } from "../../../../redux/types";
import { useSelector } from "react-redux";
import { useWindowSize } from "react-use";
import Calendar from './calendar.png';
import useLocalStorageListener from "../../../../common/hooks/useLocalStorageListener/useLocalStorageListener";
import useSummerWeeksPreloading from "../../../../common/hooks/useSummerWeeksPreloading/useSummerWeeksPreloading";
import WeekNotesDialog from "./components/WeekNotesDialog/WeekNotesDialog";
import styled from "styled-components";
import DetailedScheduleDialog from "./components/DetailedScheduleDialog/DetailedScheduleDialog";

const StyledButton = styled(Button)`
    & .MuiButton-label {
        overflow: hidden;
        text-overflow: ellipsis;
        display: initial !important;
    }
`;

const SummerSchedulePage = () => {
    const { width } = useWindowSize();
    const isMobile = width <= 900;

    const [selectedTab, selectTab] = useState(0);

    const getWeekDetailsUrl = useCallback((id: string) => '/admin/summer_schedule/weeks/' + id, []);

    const [year, setYear] = useState(2025);

    const { loadWeeks, refreshWeekData, refreshData, weeks, selectedWeek, currentWeekData } = useSummerWeeksPreloading({ selectedTab, getWeekDetailsUrl, year });

    const userInfo = useSelector((state: ReduxState) => state.user);
    const currentUserId = userInfo?.id ?? '';
    const canManageStaff = userInfo?.canManageStaff ?? false;

    const [isFindAvailabilityDialogOpen, setIsFindAvailabilityDialogOpen] = useState(false);
    const [isAddInstructorDialogOpen, setIsAddInstructorDialogOpen] = useState(false);
    const [isWeekNotesDialogOpen, setIsWeekNotesDialogOpen] = useState(false);
    const [addInstructorDialogMode, setAddInstructorDialogMode] = useState<'add' | 'edit' | 'replacement'>('add');
    const [selectedLocationId, setSelectedLocationId] = useState<string | undefined>(undefined);
    const [addInstructorEntriesToSelect, setAddInstructorEntriesToSelect] = useState<Entry[] | null>([]);

    const [isDetailedScheduleDialogOpen, setIsDetailedScheduleDialogOpen] = useState(false);
    const [detailsSelectedLocationId, setDetailsSelectedLocationId] = useState<string | undefined>();

    const [selectedTrainerId, setSelectedTrainerId] = useState<string | undefined>(undefined);

    const [zoomLevel, setZoomLevel] = useState(1);

    const setZoomLevelImpl = useCallback((zoom: string) => {
        setZoomLevel(Math.min(Math.max(parseFloat(zoom.replaceAll('%', '')) / 100, 0.2), 1.6));
    }, []);

    const decrementZoom = useCallback(() => {
        setZoomLevel(actual => Math.max(actual - 0.1, 0.2))
    }, []);

    const incrementZoom = useCallback(() => {
        setZoomLevel(actual => Math.min(actual + 0.1, 1.6))
    }, []);

    const localStorageCallback = useCallback(() => {
        refreshData(true);
        loadWeeks(true);
    }, [refreshData, loadWeeks]);

    useLocalStorageListener({
        name: 'summerAvailabilityLastUpdated',
        callback: localStorageCallback
    });

    const getTextColor = (background: string) => {
        // TODO: hardcoded black because I don't like the color difference between the locations...
        // I have to check that black is visible with all the colors of the palette,
        // otherwise I have to prevent the white use, maybe tweaking the parameters of this function
        return '#000000';

        /*
        const r = parseInt(background.slice(1, 3), 16);
        const g = parseInt(background.slice(3, 5), 16);
        const b = parseInt(background.slice(5, 7), 16);
    
        if ((r * 0.299) + (g * 0.587) + (b * 0.114) > 186) {
            console.log('black ' + background);
            return '#000000';
        } else {
            console.log('white ' + background)
            return '#ffffff';
        }
        */
    }

    return (
        <>
            <PageContainer>
                {!isMobile && (
                    <Header selectedWeek={selectedWeek} refreshWeeks={() => {
                        loadWeeks();
                        localStorage.setItem('summerAvailabilityLastUpdated', Date.now().toString());
                    }} setIsWeekNotesDialogOpen={setIsWeekNotesDialogOpen} year={year} setYear={setYear} />
                )}

                <div style={{ flexGrow: 1, overflowY: 'auto' }}>

                    {isMobile && (
                        <Header selectedWeek={selectedWeek} refreshWeeks={() => {
                            loadWeeks();
                            localStorage.setItem('summerAvailabilityLastUpdated', Date.now().toString());
                        }} setIsWeekNotesDialogOpen={setIsWeekNotesDialogOpen} year={year} setYear={setYear} />
                    )}

                    {!currentWeekData.isVisible && (
                        <>
                            <Typography variant='h2' style={{ textAlign: 'center', fontSize: isMobile ? '2.25em' : '3em', color: '#444444', marginTop: '24px' }}>
                                I turni della settimana selezionata non sono ancora stati pubblicati
                            </Typography>

                            <img src={Calendar} style={{ width: isMobile ? '260px' : '500px', margin: '0 auto', display: 'block' }} alt='' />
                        </>
                    )}

                    <div style={{ paddingBottom: '16px', display: 'flex', marginLeft: '10px', overflowX: isMobile ? 'auto' : undefined, scale: (isMobile ? 1 : zoomLevel).toString(), transformOrigin: '0 0', marginBottom: -Math.max(0, ((1 - (isMobile ? 1 : zoomLevel)) * 100 / 2)) + '%' }}>

                        {(currentWeekData?.locations ?? []).map((location: GenericObject) => {
                            return (
                                <div style={{ margin: '0 12px' }}>
                                    <Card style={{ width: '180px' }}>
                                        <StyledButton onClick={() => {
                                            setDetailsSelectedLocationId(location.id);
                                            setIsDetailedScheduleDialogOpen(true);
                                        }} style={{ letterSpacing: 'normal', width: '100%', textTransform: 'none', borderRadius: 0, backgroundColor: '#' + location.scheduleColor, color: getTextColor('#' + location.scheduleColor), padding: '4px', textAlign: 'center', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                            <b>
                                                {(location.courseShortName ?? location.courseName) + ' ' + location.townName}
                                            </b>
                                        </StyledButton>

                                        {canManageStaff && (
                                            <>
                                                <div style={{ display: 'flex', padding: '4px 12px' }}>
                                                    <div style={{ flex: 1 }}>
                                                        <Tooltip title={'Numero istruttori sempre presenti'} arrow>
                                                            <span>
                                                                {location.trainersCount}
                                                            </span>
                                                        </Tooltip>
                                                    </div>
                                                    <div style={{ flex: 1, textAlign: 'right' }}>
                                                        <Tooltip title={'Partecipanti iscritti'} arrow>
                                                            <span>
                                                                {location.registrationsCount}
                                                            </span>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        <Divider />
                                        <List>
                                            {location.schedule.map((instructor: GenericObject) => {
                                                return (
                                                    <ListItem alignItems="flex-start" style={{ borderTop: '1px solid #0000001f', borderBottom: '1px solid #0000001f', padding: '8px 6px', marginBottom: '6px', height: '74px', display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: instructor.superUserId === currentUserId ? '#f1dd38' : (instructor.isResponsible ? '#e8f4fd' : undefined) }}
                                                        onClick={() => {
                                                            if (!canManageStaff) return;
                                                            setSelectedTrainerId(instructor.superUser.id);
                                                            setSelectedLocationId(location.id);
                                                            setAddInstructorDialogMode('edit');
                                                            setIsAddInstructorDialogOpen(true);
                                                            setAddInstructorEntriesToSelect(null);
                                                        }}
                                                        // @ts-ignore
                                                        button={canManageStaff}
                                                    >
                                                        <ListItemText
                                                            primary={(
                                                                <p style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center', margin: 0, fontWeight: 'bold', ...(instructor.isResponsible ? { textTransform: 'uppercase', fontStyle: 'italic' } : {}) }}>
                                                                    {instructor.superUser.firstName + ' ' + instructor.superUser.lastName}
                                                                </p>
                                                            )}
                                                            secondary={
                                                                <DynamicTooltipTrainerDescription description={instructor.description} />
                                                            }
                                                        />
                                                    </ListItem>
                                                )
                                            })}
                                        </List>
                                        {(canManageStaff && !(selectedWeek?.editDisabled ?? false)) &&
                                            <CardContent>
                                                <Button
                                                    variant='outlined'
                                                    color='primary'
                                                    style={{ margin: '0 auto', display: 'block', width: '100%', height: '32px' }}
                                                    size='small'
                                                    onClick={() => {
                                                        setAddInstructorEntriesToSelect(null);
                                                        setIsFindAvailabilityDialogOpen(true);
                                                        setSelectedLocationId(location.id);
                                                    }}>
                                                    <AddIcon />
                                                </Button>
                                            </CardContent>
                                        }
                                    </Card>
                                </div>

                            )
                        })}
                    </div>


                </div>


                <Footer weeks={weeks} selectedTab={selectedTab} selectTab={selectTab} incrementZoom={incrementZoom} decrementZoom={decrementZoom} zoomLevel={zoomLevel} setZoomLevel={setZoomLevelImpl} isWeekVisible={currentWeekData.isVisible} />


            </PageContainer >

            <FindAvailabilityDialog
                open={isFindAvailabilityDialogOpen}
                closeDialog={() => setIsFindAvailabilityDialogOpen(false)}
                selectedWeek={selectedWeek}
                weekId={selectedWeek?.id}
                locationId={selectedLocationId}
                selectTrainer={(trainerId) => {
                    setSelectedTrainerId(trainerId);
                    setIsFindAvailabilityDialogOpen(false);
                    setAddInstructorDialogMode('add');
                    setIsAddInstructorDialogOpen(true);
                }}
                addInstructorEntriesToSelect={addInstructorEntriesToSelect}
            />

            <AddInstructorDialog
                open={isAddInstructorDialogOpen}
                closeDialog={() => setIsAddInstructorDialogOpen(false)}
                mode={addInstructorDialogMode}
                selectedWeek={selectedWeek}
                weekId={selectedWeek?.id}
                locationId={selectedLocationId}
                trainerId={selectedTrainerId}
                refreshData={() => {
                    refreshData();
                    localStorage.setItem('summerAvailabilityLastUpdated', Date.now().toString());
                }}
                entriesToSelect={addInstructorEntriesToSelect}
                readOnly={selectedWeek?.editDisabled ?? false}
            />

            <WeekNotesDialog
                open={isWeekNotesDialogOpen}
                closeDialog={() => setIsWeekNotesDialogOpen(false)}
                selectedWeek={currentWeekData}
                refreshWeekData={refreshWeekData}
                readOnly={year === 2024}
            />

            <DetailedScheduleDialog
                open={isDetailedScheduleDialogOpen}
                closeDialog={() => setIsDetailedScheduleDialogOpen(false)}
                selectedWeek={selectedWeek}
                selectedLocation={(currentWeekData?.locations ?? []).find((x: any) => x.id === detailsSelectedLocationId)}
                setSelectedTrainerId={setSelectedTrainerId}
                setSelectedLocationId={setSelectedLocationId}
                setAddInstructorDialogMode={setAddInstructorDialogMode}
                setIsAddInstructorDialogOpen={setIsAddInstructorDialogOpen}
                setIsFindAvailabilityDialogOpen={setIsFindAvailabilityDialogOpen}
                setAddInstructorEntriesToSelect={setAddInstructorEntriesToSelect}
                readOnly={selectedWeek?.editDisabled ?? false}
            />
        </>
    )
}

export default SummerSchedulePage;