import { ForwardedRef, forwardRef } from 'react';

import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import { StyledListItem, StyledListItemText } from './styled';

interface Props {
    name: string;
    description?: string;
    image?: string | JSX.Element;
    onClick?: () => void;
    onDeleteClick?: () => void;
}

const EntityListItem = forwardRef((props: Props, ref: ForwardedRef<HTMLDivElement>) => {
    const { name, description, image, onClick, onDeleteClick } = props;

    return (
        // @ts-ignore https://github.com/mui-org/material-ui/issues/14971
        <StyledListItem {...(onClick && { onClick, button: true })} ref={ref} >
            {typeof image === 'string' ? (
                // if the image prop is a string, use it as the image url, otherwise render it as a component
                <ListItemAvatar>
                    <Avatar alt={name} src={image} />
                </ListItemAvatar>
            ) : image}
            <StyledListItemText primary={name} secondary={description} largepadding={onDeleteClick ? 1 : 0} />

            {onDeleteClick && (
                // if onDeleteClick is defined, show the delete icon
                <ListItemSecondaryAction>
                    <IconButton onClick={onDeleteClick} style={{marginRight: '-14px'}}>
                        <RemoveCircleOutlineIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            )}

        </StyledListItem>
    );
});

export default EntityListItem;
