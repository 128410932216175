import { useCallback, useEffect, useMemo, useState } from 'react';
import { useWindowSize } from 'react-use';

import { Button, Checkbox, Divider, Fab, FormControl, InputLabel, Link, ListItem, ListItemText, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import useInternalLoader from '../../../../common/hooks/useInternalLoader/useExternalLoader';
import api from '../../../../common/utils/api';
import { PageContainer } from './styled';
import Footer from './components/Footer/Footer';
import moment from 'moment';
import 'moment/locale/it';
import styled from 'styled-components';
import { ReduxState } from '../../../../redux/types';
import { useSelector } from 'react-redux';
import DynamicTooltipFacilityName from './components/DynamicTooltipFacilityName/DynamicTooltipFacilityName';
import AddIcon from '@material-ui/icons/Add';
import SelectFacilityDialog from './components/SelectFacilityDialog/SelectFacilityDialog';
import SelectTrainerDialog from './components/SelectTrainerDialog/SelectTrainerDialog';
import snackbar from '../../../../common/utils/snackbar';
import GreenButton from '../../../../common/components/GreenButton/GreenButton';
import YellowButton from '../../../../common/components/YellowButton/YellowButton';
import Calendar from '../summerSchedulePage/calendar.png';
import AddSchoolDialog from './components/AddSchoolDialog/AddSchoolDialog';
import SettingsIcon from '@material-ui/icons/Settings';
import SelectCoordinatorDialog from './components/SelectCoordinatorDialog/SelectCoordinatorDialog';
import { cx } from '@emotion/css';
import { styledAvailabilityFilterMenu2 } from '../summerSchedulePage/components/FindAvailabilityDialog/styled';
import { StyledTextField } from '../loginPage/styled';
import GenericObject from '../../../../typesAdditional/GenericObject';
import ApiEntitySelectDialog from '../../../../common/components/ApiEntitySelectDialog/ApiEntitySelectDialog';

export const StyledListItem = styled(ListItem) <{ backgroundColor?: string, disableOnHover?: boolean }>`
    background-color: ${p => p.backgroundColor ? p.backgroundColor : 'unset'};

    &:hover {
        ${p => (p.backgroundColor && !p.disableOnHover) ? ('background-color: ' + p.backgroundColor + '88') : ''}
    }
`

interface Props {
    season: 'summer' | 'winter';
}

const WEEK_DAYS = ['', 'lunedì', 'martedì', 'mercoledì', 'giovedì', 'venerdì', 'sabato'];
const COORDINATORS = {
    'ariboli@epuntos.it': 'Alessandro',
    'dscaburri@epuntos.it': 'Dario',
    'gbuzzone@epuntos.it': 'Giorgio'
};

const WinterGeneralSchedulePage = ({ season }: Props) => {
    const setIsLoading = useInternalLoader();

    const { width } = useWindowSize();

    const [filterDays, setFilterDays] = useState<string[]>([]);
    const [filterCoordinator, setFilterCoordinator] = useState<string[]>([]);
    const [filterSuperUserId, setFilterSuperUserId] = useState<string | undefined>(undefined);
    const [filterSuperUser, setFilterSuperUser] = useState<GenericObject | undefined>(undefined);

    console.log('filterSuperUserId', filterSuperUserId);

    useEffect(() => {
        if (!filterSuperUserId) {
            setFilterSuperUser(undefined);
            return;
        }

        api.request('/admin/trainers/' + filterSuperUserId).then((res: any) => {
            setFilterSuperUser(res);
        })
    }, [filterSuperUserId]);

    const [isEntitySelectDialogOpen, setIsEntitySelectDialogOpen] = useState(false);

    const filterLabel = useMemo(() => {
        const capitalizeFirstLetter = (string: string) => {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }

        const partialLabels = [];

        if (filterDays.length > 0) {
            partialLabels.push('giorn' + (filterDays.length === 1 ? 'o' : 'i') + ': ' + filterDays.sort().map(x => WEEK_DAYS[parseInt(x)]).join(', '));
        }

        if (filterCoordinator.length > 0) {
            // @ts-ignore 
            partialLabels.push('coordinator' + (filterCoordinator.length === 1 ? 'e' : 'i') + ': ' + filterCoordinator.sort().map(x => COORDINATORS[x]).join(', '));
        }

        if (filterSuperUser) {
            partialLabels.push('istruttore: ' + filterSuperUser.surname + ' ' + filterSuperUser.name)
        }

        console.log('partial labels', partialLabels);

        if (partialLabels.length === 0) {
            return 'Seleziona filtro';
        } else {
            return capitalizeFirstLetter(partialLabels.join('; '));
        }
    }, [filterDays, filterCoordinator, filterSuperUser]);

    const [info, setInfo] = useState<any>({});
    const [selectedTab, selectTab] = useState<number>(0);
    const [isLoadingVisibility, setIsLoadingVisibility] = useState(false);

    const userInfo = useSelector((state: ReduxState) => state.user);
    const currentUserId = userInfo?.id ?? '';
    const canManageStaff = userInfo?.canManageStaff ?? false;

    const selectedCategory = useMemo(() => {
        if (!info?.categories) return null;

        return info.categories[selectedTab];
    }, [info, selectedTab]);

    const [zoomLevel, setZoomLevel] = useState(1);

    const setZoomLevelImpl = useCallback((zoom: string) => {
        setZoomLevel(Math.min(Math.max(parseFloat(zoom.replaceAll('%', '')) / 100, 0.2), 1.6));
    }, []);

    const decrementZoom = useCallback(() => {
        setZoomLevel(actual => Math.max(actual - 0.1, 0.2))
    }, []);

    const incrementZoom = useCallback(() => {
        setZoomLevel(actual => Math.min(actual + 0.1, 1.6))
    }, []);

    const groupsForRender = useMemo(() => {
        if (!selectedCategory) return [];

        if (selectedCategory?.slug === 'pps_ms') {
            const allSessions = selectedCategory.courses.reduce((prev: any, curr: any) => {
                const currentLocations = curr.locations.map((loc: any) => {
                    return { ...loc, courseId: curr.id, courseName: curr.name };
                });

                const sessions = currentLocations.reduce((prev2: any, curr2: any) => {
                    const currentSessions = curr2.sessions.map((sess: any) => {
                        return { ...sess, location: curr2 };
                    })

                    return [...prev2, ...currentSessions];
                }, []);

                return [...prev, ...sessions];
            }, []);

            const townGroups = allSessions.reduce((prev: any, curr: any) => {
                const foundGroup = {
                    ...(prev.find((g: any) => g.townId === curr.location.town.id && g.townDistinguishing === curr.location.townDistinguishing) ?? {
                        townId: curr.location.town.id,
                        townDistinguishing: curr.location.townDistinguishing,
                        townName: curr.location.townName,
                        coordinators: curr.location.coordinators,
                        sessions: []
                    })
                };

                foundGroup.sessions.push(curr);

                return [...prev.filter((g: any) => g.townId !== curr.location.town.id || g.townDistinguishing !== curr.location.townDistinguishing), foundGroup];
            }, []).filter((g: any) => {
                return filterCoordinator.length === 0 || g.coordinators.some((c: any) => filterCoordinator.includes(c.superUser.email));
            }).sort((g1: any, g2: any) => {
                return g1.townName.localeCompare(g2.townName);
            }).map((g: any) => {
                const sessions = g.sessions.sort((s1: any, s2: any) => {
                    const date1 = new Date(s1.startTime);
                    const date2 = new Date(s2.startTime);

                    const time1 = date1.getHours() * 3600 + date1.getMinutes() * 60 + date1.getSeconds();
                    const time2 = date2.getHours() * 3600 + date2.getMinutes() * 60 + date2.getSeconds();

                    return time1 - time2;
                }).sort((s1: any, s2: any) => {
                    const dayOfWeek1 = (new Date(s1.startTime).getDay() + 6) % 7;
                    const dayOfWeek2 = (new Date(s2.startTime).getDay() + 6) % 7;

                    return dayOfWeek1 - dayOfWeek2;
                }).filter((s: any) => {
                    const dayOfWeek = ((new Date(s.startTime)).getDay() + 6) % 7;

                    return filterDays.length === 0 || filterDays.includes('' + (dayOfWeek + 1));
                }).filter((s: any) => {
                    return !filterSuperUserId || s.trainers.some((t: any) => t.superUser.id === filterSuperUserId);
                });

                if (sessions.length === 0) return null;

                return { ...g, sessions };
            }).filter((x: any) => x !== null);

            return townGroups;
        } else {
            const allLocations = selectedCategory.courses.reduce((prev: any, curr: any) => {
                const currentLocations = curr.locations.map((loc: any) => {
                    return { ...loc, courseId: curr.id, courseName: curr.name };
                });

                return [...prev, ...currentLocations];
            }, []);

            return allLocations.filter((g: any) => {
                return filterCoordinator.length === 0 || g.coordinators.some((c: any) => filterCoordinator.includes(c.superUser.email));
            }).sort((g1: any, g2: any) => {
                return g1.townName.localeCompare(g2.townName);
            }).sort((g1: any, g2: any) => {
                return g1.courseName.localeCompare(g2.courseName);
            }).map((g: any) => {
                const sessions = g.sessions.sort((s1: any, s2: any) => {
                    const date1 = new Date(s1.startTime);
                    const date2 = new Date(s2.startTime);

                    const time1 = date1.getHours() * 3600 + date1.getMinutes() * 60 + date1.getSeconds();
                    const time2 = date2.getHours() * 3600 + date2.getMinutes() * 60 + date2.getSeconds();

                    return time1 - time2;
                }).sort((s1: any, s2: any) => {
                    const dayOfWeek1 = (new Date(s1.startTime).getDay() + 6) % 7;
                    const dayOfWeek2 = (new Date(s2.startTime).getDay() + 6) % 7;

                    return dayOfWeek1 - dayOfWeek2;
                }).filter((s: any) => {
                    const dayOfWeek = ((new Date(s.startTime)).getDay() + 6) % 7;

                    return filterDays.length === 0 || filterDays.includes('' + (dayOfWeek + 1));
                }).filter((s: any) => {
                    return !filterSuperUserId || s.trainers.some((t: any) => t.superUser.id === filterSuperUserId);
                });

                if (sessions.length === 0) return null;

                return { ...g, sessions };
            }).filter((x: any) => x !== null);
        }
    }, [selectedCategory, filterDays, filterCoordinator, filterSuperUserId]);

    const finalGroupsForRender = useMemo(() => {
        return groupsForRender.map((g: any) => {
            const userEnabled = g.sessions.map((sess: any) => {
                return sess.userEnabled;
            }).some((x: any) => !!x);

            return { ...g, userEnabled: userEnabled }
        })
    }, [groupsForRender]);

    const maximumTrainersInCourse = useMemo(() => {
        const value = Math.max(...(groupsForRender.map((g: any) => {
            const partialTrainers = g.sessions.map((sess: any) => {
                return sess.trainers.length;
            });

            return Math.max(...partialTrainers);
        }).flat()));

        if (isNaN(value) || value < 0) return 0;
        return value;
    }, [groupsForRender]);

    const [isSelectFacilityDialogOpen, setIsSelectFacilityDialogOpen] = useState(false);
    const [isSelectTrainerDialogOpen, setIsSelectTrainerDialogOpen] = useState(false);
    const [isSelectTrainerDialogSubstituteMode, setIsSelectTrainerDialogSubstituteMode] = useState(false);
    const [selectedSessionId, setSelectedSessionId] = useState<string | undefined>(undefined);
    const [selectedTrainerId, setSelectedTrainerId] = useState<string | undefined>(undefined);

    const [selectedSchoolCourseId, setSelectedSchoolCourseId] = useState<string | null>(null);
    const [isAddScholDialogOpen, setIsAddScholDialogOpen] = useState(false);

    const [isSelectCoordinatorDialogOpen, setIsSelectCoordinatorDialogOpen] = useState(false);
    const [coordinatorLocationId, setCoordinatorLocationId] = useState<undefined | string>(undefined);
    const [coordinatorTownId, setCoordinatorTownId] = useState<undefined | string>(undefined);
    const [coordinatorTownDistinguishing, setCoordinatorTownDistinguishing] = useState<undefined | string>(undefined);

    const [trainerIdsInSession, setTrainerIdsInSession] = useState<string[]>([]);

    const refreshData = useCallback(() => {
        api.request('/admin/winter_staff/schedule/info').then(res => {
            setInfo(res);
            setIsLoading(false);
        })
    }, [setIsLoading]);

    const setScheduleVisibility = useCallback((visibility: boolean) => {
        setIsLoadingVisibility(true);

        api.request('/admin/winter_staff/schedule/visibility', 'POST', { visibility }).then(() => {
            refreshData();
            snackbar.success('Visibilità modificato con successo!');
        }).finally(() => {
            setIsLoadingVisibility(false);
        });
    }, [setIsLoadingVisibility, refreshData]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            refreshData();
        }, 10);

        return () => clearTimeout(timeout);
    }, [refreshData]);

    const getTextColor = (background: string) => {
        const r = parseInt(background.slice(1, 3), 16);
        const g = parseInt(background.slice(3, 5), 16);
        const b = parseInt(background.slice(5, 7), 16);

        if ((r * 0.299) + (g * 0.587) + (b * 0.114) > 186) {
            //console.log('black ' + background);
            return '#000000';
        } else {
            //console.log('white ' + background)
            return '#ffffff';
        }
    }

    const mapTrainers = useCallback((rawData: any) => {
        console.log(trainerIdsInSession, rawData.id);
        if (trainerIdsInSession.includes(rawData.id)) return null

        return {
            id: rawData.id,
            name: rawData.lastName + ' ' + rawData.firstName,
            //description: rawData.town.name
        };
    }, [trainerIdsInSession]);

    const isMobile = width <= 700;

    return (
        <PageContainer>
            <div>
                <div style={{ display: isMobile ? undefined : 'flex', textAlign: isMobile ? 'center' : undefined }}>
                    <Typography variant='h2' style={{ fontSize: isMobile ? '3em' : '3.6em', textAlign: isMobile ? 'center' : 'left' }}>
                        Inverno 2024/2025
                    </Typography>

                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '18px' }}>
                        <div style={{ flexGrow: 1 }} />
                        <Typography variant='h2' style={{ fontSize: '2em', marginBottom: '4px', textAlign: isMobile ? 'center' : 'left' }}>
                            {selectedCategory?.description ?? ''}
                        </Typography>
                    </div>

                    <div style={{ flex: 1, flexGrow: 1 }} />

                    {canManageStaff && (
                        <TextField
                            label={'Filtri'}
                            size='small'
                            variant='outlined'
                            style={{ flex: 1, marginLeft: '12px', marginRight: '12px', marginTop: '10px' }}
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{
                                renderValue: () => <>{filterLabel}</>,
                                displayEmpty: true,
                                MenuProps: {
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left'
                                    },
                                    transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left',
                                    },
                                    className: cx(styledAvailabilityFilterMenu2),
                                    onClick: (e: React.MouseEvent) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }
                                }
                            }}
                        >
                            <div style={{ padding: '16px' }}>
                                <FormControl variant='outlined' fullWidth >
                                    <InputLabel variant='outlined' id='filter_day_label'>Giorno</InputLabel>
                                    <Select
                                        label='Giorno'
                                        variant='outlined'
                                        multiple
                                        value={filterDays}
                                        onChange={(event) => {
                                            // @ts-ignore
                                            setFilterDays(event.target.value);
                                        }}
                                        // @ts-ignore
                                        renderValue={(selected: string[]) => selected.sort().map(x => WEEK_DAYS[parseInt(x)]).join(', ')}
                                    >
                                        <MenuItem value={'1'} onClick={e => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}>
                                            <Checkbox color='primary' style={{ padding: '5px 10px 5px 0' }} checked={filterDays.indexOf('1') > -1} onClick={e => {
                                                //e.stopPropagation();
                                            }} />
                                            <ListItemText primary={'lunedì'} />
                                        </MenuItem>
                                        <MenuItem value={'2'} onClick={e => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}>
                                            <Checkbox color='primary' style={{ padding: '5px 10px 5px 0' }} checked={filterDays.indexOf('2') > -1} onClick={e => {
                                                //e.stopPropagation();
                                            }} />
                                            <ListItemText primary={'martedì'} />
                                        </MenuItem>
                                        <MenuItem value={'3'} onClick={e => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}>
                                            <Checkbox color='primary' style={{ padding: '5px 10px 5px 0' }} checked={filterDays.indexOf('3') > -1} onClick={e => {
                                                //e.stopPropagation();
                                            }} />
                                            <ListItemText primary={'mercoledì'} />
                                        </MenuItem>
                                        <MenuItem value={'4'} onClick={e => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}>
                                            <Checkbox color='primary' style={{ padding: '5px 10px 5px 0' }} checked={filterDays.indexOf('4') > -1} onClick={e => {
                                                //e.stopPropagation();
                                            }} />
                                            <ListItemText primary={'giovedì'} />
                                        </MenuItem>
                                        <MenuItem value={'5'} onClick={e => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}>
                                            <Checkbox color='primary' style={{ padding: '5px 10px 5px 0' }} checked={filterDays.indexOf('5') > -1} onClick={e => {
                                                //e.stopPropagation();
                                            }} />
                                            <ListItemText primary={'venerdì'} />
                                        </MenuItem>
                                        <MenuItem value={'6'} onClick={e => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}>
                                            <Checkbox color='primary' style={{ padding: '5px 10px 5px 0' }} checked={filterDays.indexOf('6') > -1} onClick={e => {
                                                //e.stopPropagation();
                                            }} />
                                            <ListItemText primary={'sabato'} />
                                        </MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl variant='outlined' fullWidth style={{ marginTop: '12px' }}>
                                    <InputLabel variant='outlined' id='filter_coordinator_label'>Coordinatore</InputLabel>
                                    <Select
                                        label='Coordinatore'
                                        variant='outlined'
                                        multiple
                                        value={filterCoordinator}
                                        onChange={(event) => {
                                            // @ts-ignore
                                            setFilterCoordinator(event.target.value);
                                        }}
                                        // @ts-ignore 
                                        renderValue={(selected: string[]) => selected.sort().map(x => COORDINATORS[x]).join(', ')}
                                    >
                                        <MenuItem value={'ariboli@epuntos.it'} onClick={e => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}>
                                            <Checkbox color='primary' style={{ padding: '5px 10px 5px 0' }} checked={filterCoordinator.indexOf('ariboli@epuntos.it') > -1} onClick={e => {
                                                //e.stopPropagation();
                                            }} />
                                            <ListItemText primary={'Alessandro'} />
                                        </MenuItem>
                                        <MenuItem value={'dscaburri@epuntos.it'} onClick={e => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}>
                                            <Checkbox color='primary' style={{ padding: '5px 10px 5px 0' }} checked={filterCoordinator.indexOf('dscaburri@epuntos.it') > -1} onClick={e => {
                                                //e.stopPropagation();
                                            }} />
                                            <ListItemText primary={'Dario'} />
                                        </MenuItem>
                                        <MenuItem value={'gbuzzone@epuntos.it'} onClick={e => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}>
                                            <Checkbox color='primary' style={{ padding: '5px 10px 5px 0' }} checked={filterCoordinator.indexOf('gbuzzone@epuntos.it') > -1} onClick={e => {
                                                //e.stopPropagation();
                                            }} />
                                            <ListItemText primary={'Giorgio'} />
                                        </MenuItem>
                                    </Select>
                                </FormControl>

                                <StyledTextField
                                    label={'Istruttore'}
                                    variant='outlined'
                                    value={filterSuperUserId ? 'dummy' : ''}
                                    style={{ marginRight: '6px' }}
                                    fullWidth
                                    SelectProps={{
                                        open: false,
                                        onOpen: () => setIsEntitySelectDialogOpen(true)
                                    }}
                                    select
                                >
                                    <MenuItem value={'dummy'} style={{ whiteSpace: 'break-spaces' }}>
                                        {(filterSuperUser && filterSuperUser.id === filterSuperUserId) ? (filterSuperUser.surname + ' ' + filterSuperUser.name) : ''}
                                    </MenuItem>
                                </StyledTextField>
                            </div>
                        </TextField>
                    )}

                    {canManageStaff && selectedCategory?.slug === 'school_courses' && (
                        <>
                            <Button variant='contained' color='primary' onClick={() => {
                                setIsAddScholDialogOpen(true);
                                setSelectedSchoolCourseId(null);
                            }} style={{ height: 'fit-content', margin: 'auto' }} >
                                Aggiungi scuola
                            </Button>
                        </>
                    )}

                    {canManageStaff && (
                        <>
                            {info?.visibility ? (
                                <GreenButton disabled={isLoadingVisibility} noaction={!canManageStaff} onClick={() => {
                                    if (canManageStaff) {
                                        setScheduleVisibility(false);
                                    }
                                }} style={{ margin: isMobile ? '8px auto 0' : 'auto 16px', display: isMobile ? 'block' : undefined, flexGrow: 0, marginTop: isMobile ? '10px' : undefined, marginBottom: isMobile ? '10px' : undefined, width: '180px', height: 'fit-content' }}>
                                    Turni visibili
                                </GreenButton>
                            ) : (
                                <YellowButton disabled={isLoadingVisibility} noaction={!canManageStaff} onClick={() => {
                                    if (canManageStaff) {
                                        setScheduleVisibility(true);
                                    }
                                }} style={{ margin: isMobile ? '8px auto 0' : 'auto 16px', display: isMobile ? 'block' : undefined, flexGrow: 0, marginTop: isMobile ? '10px' : undefined, marginBottom: isMobile ? '10px' : undefined, width: '180px', height: 'fit-content' }}>
                                    Turni non visibili
                                </YellowButton>
                            )}
                        </>
                    )}

                    {/*
                    <GreenButton onClick={() => {
                        console.log('boh')
                    }} style={{ flexGrow: 0, marginTop: isMobile ? '10px' : undefined, marginBottom: isMobile ? '10px' : undefined }}>
                        [X]
                    </GreenButton>
                    */}
                </div>

                <Divider style={{ margin: '8px 0 12px' }} />
            </div>

            <div style={{ flexGrow: 1, overflowY: 'auto', marginRight: '0px' }}>
                {!((info?.visibility || canManageStaff)) && (
                    <>
                        <Typography variant='h2' style={{ textAlign: 'center', fontSize: isMobile ? '2.25em' : '3em', color: '#444444', marginTop: '24px' }}>
                            I turni non sono ancora stati pubblicati
                        </Typography>

                        <img src={Calendar} style={{ width: isMobile ? '260px' : '500px', margin: '0 auto', display: 'block' }} alt='' />
                    </>
                )}

                <div style={{ maxHeight: '100%', paddingBottom: '16px', display: 'flex', marginLeft: '10px', overflowX: isMobile ? 'auto' : undefined, scale: (isMobile ? 1 : zoomLevel).toString(), transformOrigin: '0 0', marginBottom: -Math.max(0, ((1 - (isMobile ? 1 : zoomLevel)) * 100 / 2)) + '%' }}>
                    <div>
                        {(info?.visibility || canManageStaff) && (
                            <TableContainer component={Paper} style={{ marginTop: '16px', width: 'auto' }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {selectedCategory?.slug === 'school_courses' && canManageStaff && (
                                                <TableCell />
                                            )}

                                            <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>
                                                {selectedCategory?.slug === 'pps_ms' ? 'Paese' : 'Corso'}
                                            </TableCell>

                                            {canManageStaff && (
                                                <>
                                                    <TableCell style={{ width: '150px', whiteSpace: 'nowrap' }}>Palestra</TableCell>
                                                </>
                                            )}

                                            <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>Sostituzioni</TableCell>

                                            <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>Giorno</TableCell>
                                            {selectedCategory?.slug === 'pps_ms' && (
                                                <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>Corso</TableCell>
                                            )}
                                            {canManageStaff && selectedCategory?.slug !== 'school_courses' && (
                                                <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>Iscritti</TableCell>
                                            )}
                                            <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>Orario</TableCell>

                                            {[...Array(maximumTrainersInCourse + (canManageStaff ? 1 : 0)).keys()].map((trainerIdx: number) => {
                                                return (
                                                    <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>Istruttore {trainerIdx + 1}</TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {finalGroupsForRender.map((g: any) => {
                                            return (
                                                <>
                                                    <TableRow>
                                                        <TableCell colSpan={6 + maximumTrainersInCourse + (canManageStaff ? 1 : 0) + (selectedCategory?.slug === 'pps_ms' ? 1 : 0)} style={{ backgroundColor: '#fafafa' }} />
                                                    </TableRow>
                                                    {g.sessions.map((session: any, idx: number) => {
                                                        return (
                                                            <TableRow>
                                                                {idx === 0 && (
                                                                    <>
                                                                        {selectedCategory?.slug === 'school_courses' && canManageStaff && (
                                                                            <TableCell rowSpan={g.sessions.length}>
                                                                                <Fab size="small" color="primary" aria-label="add" onClick={() => {
                                                                                    setSelectedSchoolCourseId(g.id);
                                                                                    setIsAddScholDialogOpen(true);
                                                                                }} >
                                                                                    <SettingsIcon />
                                                                                </Fab>
                                                                            </TableCell>
                                                                        )}

                                                                        <TableCell rowSpan={g.sessions.length} style={{ padding: '8px 14px', textAlign: 'center', width: '1px', whiteSpace: 'nowrap', fontWeight: 'bold' }}>
                                                                            <Button component={RouterLink} to={(window.location.host.startsWith('admin.epuntos.it') ? '' : '/admin') + (canManageStaff ? '/staff-winter/' : '/winter-schedule/') + (selectedCategory?.slug === 'pps_ms' ? ('pps-ms-schedule/' + (g?.townDistinguishing ?? g?.townId)) : ('schedule/' + g?.id))} disabled={!canManageStaff && !g.userEnabled} variant={!canManageStaff && !g.userEnabled ? 'contained' : 'outlined'} style={{ padding: '6px 22px', color: '#000000', textTransform: 'uppercase' }} onClick={() => undefined}>
                                                                                {selectedCategory?.slug === 'pps_ms' ? g.townName : (g.courseName + ' ' + (finalGroupsForRender.filter((x: any) => x.courseName === g.courseName).length > 1 ? g.townName : ''))}
                                                                            </Button>
                                                                            <div style={{ display: 'block', marginTop: '14px', textAlign: 'center' }}>
                                                                                {canManageStaff ? (
                                                                                    <Link href='#' onClick={() => {
                                                                                        if (selectedCategory?.slug === 'pps_ms') {
                                                                                            setCoordinatorLocationId(undefined);
                                                                                            setCoordinatorTownId(g?.townId);
                                                                                            setCoordinatorTownDistinguishing(g?.townDistinguishing);
                                                                                        } else {
                                                                                            setCoordinatorLocationId(g?.id);
                                                                                            setCoordinatorTownId(undefined);
                                                                                            setCoordinatorTownDistinguishing(undefined);
                                                                                        }

                                                                                        setIsSelectCoordinatorDialogOpen(true);
                                                                                    }}>
                                                                                        {(g?.coordinators ?? []).map((c: any) => c.superUser.firstName).sort().join(', ')}

                                                                                        {(g?.coordinators ?? []).length === 0 && (
                                                                                            <i>Seleziona coordinatore</i>
                                                                                        )}
                                                                                    </Link>
                                                                                ) : (
                                                                                    <>
                                                                                        {(g?.coordinators ?? []).map((c: any) => c.superUser.firstName).sort().join(', ')}
                                                                                    </>
                                                                                )}

                                                                            </div>
                                                                        </TableCell>
                                                                    </>
                                                                )}

                                                                {canManageStaff && (
                                                                    <>
                                                                        <TableCell style={{ width: '1px', whiteSpace: 'nowrap', padding: 0, backgroundColor: session.facility ? '#e8f4fd' : undefined }}>
                                                                            {session.facility ? (
                                                                                <StyledListItem backgroundColor={'#e8f4fd'} disableOnHover={!canManageStaff} alignItems="flex-start" style={{ width: '220px', padding: '0px 6px', height: '58px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                                                                                    onClick={() => {
                                                                                        if (!canManageStaff) return;
                                                                                        setSelectedSessionId(session.id);
                                                                                        setIsSelectFacilityDialogOpen(true);
                                                                                    }}
                                                                                    // @ts-ignore
                                                                                    button={canManageStaff}
                                                                                >
                                                                                    <ListItemText
                                                                                        primary={(
                                                                                            <DynamicTooltipFacilityName description={session.facility.facility.name + ((!session.facility.facility.name.toLowerCase().includes(session.facility.facility.town.name.toLowerCase()) && (!(session.facility.facility.town.name.toLowerCase() === 'bergamo' && session.facility.facility.name.toLowerCase().includes('bg'))) ? (' - ' + session.facility.facility.town.name) : ''))} />
                                                                                        )}
                                                                                        secondary={session.facility.startEndTimeDesc ? (
                                                                                            <div style={{ textAlign: 'center' }}>
                                                                                                {session.facility.startEndTimeDesc}
                                                                                            </div>
                                                                                        ) : undefined}
                                                                                    />
                                                                                </StyledListItem>
                                                                            ) : (
                                                                                <div style={{ height: '58px', display: 'flex', alignItems: 'center', padding: '8px 22px' }}>
                                                                                    {canManageStaff && (
                                                                                        <Button
                                                                                            variant='outlined'
                                                                                            color='primary'
                                                                                            style={{ margin: '0 auto', display: 'block', width: '100%', height: '32px' }}
                                                                                            size='small'
                                                                                            onClick={() => {
                                                                                                if (!canManageStaff) return;
                                                                                                setSelectedSessionId(session.id);
                                                                                                setIsSelectFacilityDialogOpen(true);
                                                                                            }}>
                                                                                            <AddIcon />
                                                                                        </Button>
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                        </TableCell>
                                                                    </>
                                                                )}

                                                                <TableCell style={{ width: '150px', whiteSpace: 'nowrap', padding: 0, backgroundColor: session.substitutes[0] ? (canManageStaff ? ('#' + session.substitutes[0].superUser.scheduleColor) : (session.substitutes[0].superUser.id === currentUserId ? '#f1dd38' : '#e8f4fd')) : undefined }}>
                                                                    {session.substitutes.length > 0 ? (
                                                                        <>
                                                                            {session.substitutes.slice(0, 2).map((substitute: any) => {
                                                                                return (
                                                                                    <StyledListItem backgroundColor={canManageStaff ? ('#' + substitute.superUser.scheduleColor) : (substitute.superUser.id === currentUserId ? '#f1dd38' : '#e8f4fd')} disableOnHover={!canManageStaff} alignItems="flex-start" style={{ width: '150px', padding: '0px 6px', height: (58 / session.substitutes.slice(0, 2).length) + 'px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                                                                                        onClick={() => {
                                                                                            if (!canManageStaff) return;
                                                                                            setSelectedSessionId(session.id);
                                                                                            setSelectedTrainerId(substitute.superUser.id);
                                                                                            setIsSelectTrainerDialogOpen(true);
                                                                                            setIsSelectTrainerDialogSubstituteMode(true);
                                                                                            setTrainerIdsInSession([]);
                                                                                        }}
                                                                                        // @ts-ignore
                                                                                        button={canManageStaff}
                                                                                    >
                                                                                        <ListItemText
                                                                                            primary={(
                                                                                                <DynamicTooltipFacilityName description={substitute.superUser.firstName + ' ' + substitute.superUser.lastName} textColor={getTextColor(canManageStaff ? ('#' + substitute.superUser.scheduleColor) : (substitute.superUser.id === currentUserId ? '#f1dd38' : '#e8f4fd'))} />
                                                                                            )}
                                                                                            secondary={substitute.startEndTimeDesc ? (
                                                                                                <div style={{ textAlign: 'center' }}>
                                                                                                    {substitute.startEndTimeDesc}
                                                                                                </div>
                                                                                            ) : undefined}
                                                                                        />
                                                                                    </StyledListItem>
                                                                                )
                                                                            })}
                                                                        </>
                                                                    ) : (
                                                                        <div style={{ height: '58px', display: 'flex', alignItems: 'center', padding: '8px 22px' }}>
                                                                            {canManageStaff && (
                                                                                <Button
                                                                                    variant='outlined'
                                                                                    color='primary'
                                                                                    style={{ margin: '0 auto', display: 'block', width: '100%', height: '32px' }}
                                                                                    size='small'
                                                                                    onClick={() => {
                                                                                        if (!canManageStaff) return;
                                                                                        setSelectedSessionId(session.id);
                                                                                        setSelectedTrainerId(undefined);
                                                                                        setIsSelectTrainerDialogOpen(true);
                                                                                        setIsSelectTrainerDialogSubstituteMode(true);
                                                                                        setTrainerIdsInSession([]);
                                                                                    }}>
                                                                                    <AddIcon />
                                                                                </Button>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </TableCell>

                                                                {g.id === '6cc5a03f-76a0-11ef-aac5-4865ee14ab2a' ? (
                                                                    <>
                                                                        <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }} colSpan={canManageStaff ? 3 : 2} />
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <TableCell style={{ width: '1px', whiteSpace: 'nowrap', color: info?.weekDayColors[(new Date(session.startTime).getDay() + 6) % 7] ?? '#000000', fontWeight: 'bold', textTransform: 'uppercase' }}>{moment(session.startTime).format('dddd')}</TableCell>

                                                                        {selectedCategory?.slug === 'pps_ms' && (
                                                                            <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>{session.scheduleDesc}</TableCell>
                                                                        )}

                                                                        {canManageStaff && selectedCategory?.slug !== 'school_courses' && (
                                                                            <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>{session.participants}</TableCell>
                                                                        )}

                                                                        <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>{moment(session.startTime).format('HH:mm') + ' - ' + moment(session.endTime).format('HH:mm')}</TableCell>
                                                                    </>
                                                                )}

                                                                {[...Array(maximumTrainersInCourse + (canManageStaff ? 1 : 0)).keys()].map((trainerIdx: number) => {
                                                                    return (
                                                                        <TableCell style={{ width: '150px', whiteSpace: 'nowrap', padding: 0, backgroundColor: session.trainers[trainerIdx] ? (canManageStaff ? ('#' + session.trainers[trainerIdx].superUser.scheduleColor) : (session.trainers[trainerIdx].superUser.id === currentUserId ? '#f1dd38' : '#e8f4fd')) : undefined }}>
                                                                            {session.trainers[trainerIdx] ? (
                                                                                <StyledListItem backgroundColor={canManageStaff ? ('#' + session.trainers[trainerIdx].superUser.scheduleColor) : (session.trainers[trainerIdx].superUser.id === currentUserId ? '#f1dd38' : '#e8f4fd')} disableOnHover={!canManageStaff} alignItems="flex-start" style={{ width: '150px', padding: '0px 6px', height: '58px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                                                                                    onClick={() => {
                                                                                        if (!canManageStaff) return;
                                                                                        setSelectedSessionId(session.id);
                                                                                        setSelectedTrainerId(session.trainers[trainerIdx].superUser.id);
                                                                                        setIsSelectTrainerDialogOpen(true);
                                                                                        setIsSelectTrainerDialogSubstituteMode(false);
                                                                                        setTrainerIdsInSession(session.trainers.map((t: any) => t.superUser.id))
                                                                                    }}
                                                                                    // @ts-ignore
                                                                                    button={canManageStaff}
                                                                                >
                                                                                    <ListItemText
                                                                                        primary={(
                                                                                            <DynamicTooltipFacilityName description={session.trainers[trainerIdx].superUser.firstName + ' ' + session.trainers[trainerIdx].superUser.lastName} textColor={getTextColor(canManageStaff ? ('#' + session.trainers[trainerIdx].superUser.scheduleColor) : (session.trainers[trainerIdx].superUser.id === currentUserId ? '#f1dd38' : '#e8f4fd'))} />
                                                                                        )}
                                                                                        secondary={session.trainers[trainerIdx].startEndTimeDesc ? (
                                                                                            <div style={{ textAlign: 'center' }}>
                                                                                                {session.trainers[trainerIdx].startEndTimeDesc}
                                                                                            </div>
                                                                                        ) : undefined}
                                                                                    />
                                                                                </StyledListItem>
                                                                            ) : (
                                                                                <div style={{ width: '150px', height: '58px', display: 'flex', alignItems: 'center', padding: '8px 22px' }}>
                                                                                    {canManageStaff && (
                                                                                        <Button
                                                                                            variant='outlined'
                                                                                            color='primary'
                                                                                            style={{ margin: '0 auto', display: 'block', width: '100%', height: '32px' }}
                                                                                            size='small'
                                                                                            onClick={() => {
                                                                                                if (!canManageStaff) return;
                                                                                                setSelectedSessionId(session.id);
                                                                                                setSelectedTrainerId(undefined);
                                                                                                setIsSelectTrainerDialogOpen(true);
                                                                                                setIsSelectTrainerDialogSubstituteMode(false);
                                                                                                setTrainerIdsInSession(session.trainers.map((t: any) => t.superUser.id))
                                                                                            }}>
                                                                                            <AddIcon />
                                                                                        </Button>
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                        </TableCell>
                                                                    )
                                                                })}
                                                            </TableRow>
                                                        )
                                                    })}
                                                </>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </div>
                </div>
            </div>

            {(info?.visibility || canManageStaff) && (
                <Footer categories={info?.categories ?? []} selectedTab={selectedTab} selectTab={selectTab} incrementZoom={incrementZoom} decrementZoom={decrementZoom} zoomLevel={zoomLevel} setZoomLevel={setZoomLevelImpl} isWeekVisible={true} />
            )}

            <SelectFacilityDialog
                open={isSelectFacilityDialogOpen}
                closeDialog={(shouldRefresh?: boolean) => {
                    setIsSelectFacilityDialogOpen(false);

                    if (shouldRefresh) refreshData();
                }}
                sessionId={selectedSessionId}
            />

            <SelectTrainerDialog
                open={isSelectTrainerDialogOpen}
                closeDialog={(shouldRefresh?: boolean) => {
                    setIsSelectTrainerDialogOpen(false);

                    if (shouldRefresh) refreshData();
                }}
                sessionId={selectedSessionId}
                trainerId={selectedTrainerId}
                substitute={isSelectTrainerDialogSubstituteMode}
                trainerIdsInSession={trainerIdsInSession}
            />

            <AddSchoolDialog
                open={isAddScholDialogOpen}
                closeDialog={() => setIsAddScholDialogOpen(false)}
                courseId={selectedSchoolCourseId}
                refreshData={() => refreshData()}
            />

            {canManageStaff && (
                <>
                    <SelectCoordinatorDialog
                        open={isSelectCoordinatorDialogOpen}
                        closeDialog={(shouldRefresh?: boolean) => {
                            setIsSelectCoordinatorDialogOpen(false)

                            if (shouldRefresh) refreshData();
                        }}
                        locationId={coordinatorLocationId}
                        townId={coordinatorTownId}
                        townDistinguishing={coordinatorTownDistinguishing}
                    />

                    <ApiEntitySelectDialog
                        open={isEntitySelectDialogOpen}
                        onClose={(selectedId?: string) => {
                            setIsEntitySelectDialogOpen(false);

                            if (selectedId) setFilterSuperUserId(selectedId);
                        }}
                        title='Seleziona istruttore'
                        url={'/admin/trainers'}
                        map={mapTrainers}
                        noEntityFoundMessage='Nessun istruttore trovato'
                        params={{ season: 'winter' }}
                        onDiscardSelection={() => {
                            setIsEntitySelectDialogOpen(false);
                            setFilterSuperUserId(undefined)
                        }}
                    />
                </>
            )}
        </PageContainer>
    );
};

export default WinterGeneralSchedulePage;
