import { useCallback, useEffect, useMemo, useState } from 'react';
import { useWindowSize } from 'react-use';

import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import { downloadBlob } from 'download.js';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { CircularProgress, Divider } from '@material-ui/core';

import CustomTextField from '../../../../common/components/CustomTextField/CustomTextField';
import useInternalLoader from '../../../../common/hooks/useInternalLoader/useExternalLoader';
import { useMessageDialog } from '../../../../common/hooks/useMessageDialog/useMessageDialog';
import api from '../../../../common/utils/api';
import snackbar from '../../../../common/utils/snackbar';
import IssueReceiptDialog from './components/IssueReceiptDialog/IssueReceiptDialog';
import { Title } from './styled';
import IconButton from '@material-ui/core/IconButton';

const ReceiptsRequestsPage = () => {
    const { width } = useWindowSize();
    const isMobile = width <= 700;

    const setIsLoading = useInternalLoader();
    const [data, setData] = useState<any[]>([]);

    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [confirmDialogData, setConfirmDialogData] = useState<any>({});

    const [showMessageDialog, closeMessageDialog] = useMessageDialog();

    const confirmDialogRequestId = confirmDialogData?.id;

    const [order, setOrder] = useState(localStorage.getItem('receipts_order') ?? 'alphabetical');
    const [query, setQuery] = useState('');

    const [updateInProgress, setUpdateInProgress] = useState<string[]>([]);

    const [anchor, setAnchorEl] = useState<null | HTMLElement>(null);
    const [menuReceiptId, setMenuReceiptId] = useState<null | string>(null);

    const handleMenuClick = useCallback((event: React.MouseEvent<HTMLElement>, receiptId: string) => {
        setAnchorEl(event.currentTarget);
        setMenuReceiptId(receiptId);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const updateData = useCallback(() => {
        api.request('/admin/receipts/requests').then(res => {
            setData(res);
            setIsLoading(false);
        });
    }, [setIsLoading]);

    useEffect(() => {
        updateData();
    }, [updateData]);

    const base64toBlob = useCallback((dataURI: string) => {
        // convert a base64 string to a blob object
        const byteString = atob(dataURI);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab]);
    }, []);

    const getPreview = (requestId: string) => {
        setUpdateInProgress(a => [...a, requestId]);
        api.request('/admin/receipts/requests/' + requestId + '/preview').then(res => {
            const blob = base64toBlob(res.content);
            downloadBlob('anteprima.pdf', blob);
        }).finally(() => {
            setUpdateInProgress(a => a.filter(u => u !== requestId));
        });;
    };

    const emitReceiptConfirm = (request: any) => {
        setConfirmDialogData(request);
        setIsConfirmDialogOpen(true);
    };

    const markAsManualIssued = (request: any) => {
        showMessageDialog({
            title: 'Segna come emessa manualmente',
            message: (
                <>
                    <p style={{ marginTop: '0px' }}>
                        Sei sicuro di volere contrassegnare la richiesta di {request.personName} come "emessa manualmente"?
                    </p>
                    <p style={{ marginBottom: '0px' }}>
                        L'operazione non è reversibile.
                    </p>
                </>
            ),
            actions: [
                {
                    text: 'Annulla',
                    action: () => {
                        closeMessageDialog();
                    }
                },
                {
                    text: 'Conferma',
                    action: () => {
                        closeMessageDialog();
                        setUpdateInProgress(a => [...a, request.id]);
                        api.request('/admin/receipts/requests/' + request.id + '/mark_as_issued').then(() => {
                            snackbar.success('Ricevuta contrassegnata come emessa!');
                            updateData();
                        }).finally(() => {
                            setUpdateInProgress(a => a.filter(u => u !== request.id));
                        });;
                    }
                }
            ]
        });
    };

    const deleteRequest = (request: any) => {
        showMessageDialog({
            title: 'Elimina richiesta',
            message: (
                <>
                    <p style={{ marginTop: '0px' }}>
                        Sei sicuro di volere eliminare la richiesta di {request.personName}?
                    </p>
                    <p style={{ marginBottom: '0px' }}>
                        L'operazione non è reversibile.
                    </p>
                </>
            ),
            actions: [
                {
                    text: 'Annulla',
                    action: () => {
                        closeMessageDialog();
                    }
                },
                {
                    text: 'Conferma',
                    action: () => {
                        closeMessageDialog();
                        setUpdateInProgress(a => [...a, request.id]);
                        api.request('/admin/receipts/requests/' + request.id, 'DELETE').then(() => {
                            snackbar.success('Richiesta ricevuta eliminata!');
                            updateData();
                        }).finally(() => {
                            setUpdateInProgress(a => a.filter(u => u !== request.id));
                        });;
                    }
                }
            ]
        });
    };

    const sendCashReceiptConfirmationWarnig = (request: any) => {
        setUpdateInProgress(a => [...a, request.id]);
        api.request('/admin/receipts/requests/' + request.id + '/send_cash_confirmation_notice', 'POST').then(() => {
            snackbar.success('Email di conferma inviata con successo!');
            updateData();
        }).finally(() => {
            setUpdateInProgress(a => a.filter(u => u !== request.id));
        });
    }

    const sortedRequests = useMemo(() => {
        return data.sort((a: any, b: any) => {
            if (order === 'alphabetical' && a.personName !== b.personName) {
                return a.personName.localeCompare(b.personName);
            } else {
                return a.createdAt.localeCompare(b.createdAt);
            }
        });
    }, [data, order]);

    const filteredRequests = useMemo(() => {
        if (!query || !query.trim()) {
            return sortedRequests;
        }

        const lowerQuery = query.toLowerCase().trim().replaceAll(' ', '');

        return sortedRequests.filter(r => {
            return (
                r.personName.toLowerCase().replaceAll(' ', '').startsWith(lowerQuery) ||
                r.taxCode.toLowerCase().replaceAll(' ', '').startsWith(lowerQuery) ||
                r.personName.toLowerCase().replaceAll(' ', '').includes(lowerQuery) ||
                r.taxCode.toLowerCase().replaceAll(' ', '').includes(lowerQuery)
            );
        }).sort((a, b) => {
            const aGoodMatch = (
                a.personName.toLowerCase().replaceAll(' ', '').startsWith(lowerQuery) ||
                a.taxCode.toLowerCase().replaceAll(' ', '').startsWith(lowerQuery)
            );

            const bGoodMatch = (
                b.personName.toLowerCase().replaceAll(' ', '').startsWith(lowerQuery) ||
                b.taxCode.toLowerCase().replaceAll(' ', '').startsWith(lowerQuery)
            );

            if (aGoodMatch && bGoodMatch) {
                return 0;
            } else if (aGoodMatch) {
                return 1;
            } else {
                return -1;
            }
        });
    }, [sortedRequests, query]);

    return (
        <>
            <Title isMobile={isMobile}>
                <div style={{ flex: '1', flexGrow: 1 }}>
                    Ricevute da emettere
                </div>
                <div style={{ flex: '1', flexGrow: 1, maxWidth: '620px', marginTop: '4px', textAlign: 'right' }}>
                    <TextField
                        label='Ricerca genitore'
                        variant='outlined'
                        placeholder={''}
                        value={query}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQuery(e.target.value)}
                        InputProps={{
                            startAdornment: <InputAdornment position='start'><SearchIcon /></InputAdornment>,
                        }}
                    />

                    <CustomTextField
                        select
                        label={'Ordine'}
                        value={order}
                        onChange={(e: any) => {
                            setOrder(e.target.value);
                            localStorage.setItem('receipts_order', e.target.value);
                        }}
                        variant='outlined'
                        required
                        fullWidth
                        keepMounted
                        style={{ marginLeft: '12px', maxWidth: '240px', textAlign: 'left' }}
                    >
                        <MenuItem value={'alphabetical'} style={{ whiteSpace: 'break-spaces' }}>
                            alfabetico
                        </MenuItem>
                        <MenuItem value={'date'} style={{ whiteSpace: 'break-spaces' }}>
                            data di richiesta
                        </MenuItem>
                    </CustomTextField>
                </div>
            </Title>

            <TableContainer component={Paper}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nome genitore</TableCell>
                            <TableCell>Codice fiscale</TableCell>
                            <TableCell>Corso</TableCell>
                            <TableCell>Metodo di pagamento</TableCell>
                            <TableCell>Importo</TableCell>
                            <TableCell>Azioni</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredRequests.map((request: any) => {
                            return (
                                <TableRow style={(request.methodName === 'Contanti' && request.cashConfirmationNoticeSentAt) ? { backgroundColor: '#e8f4fd' } : undefined}>
                                    <TableCell>{request.personName}</TableCell>
                                    <TableCell>{request.taxCode}</TableCell>
                                    <TableCell>{request.courseName}</TableCell>
                                    <TableCell>{request.methodName}</TableCell>
                                    <TableCell>
                                        € {parseFloat(request.amount).toFixed(2)}
                                    </TableCell>
                                    <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>
                                        {updateInProgress.includes(request.id) ? (
                                            <div style={{ display: 'flex', alignItems: 'center', height: '48px', width: '48px', padding: '0 7px' }}>
                                                <CircularProgress style={{ width: '34px', height: '34px' }} />
                                            </div>
                                        ) : (
                                            <IconButton onClick={(e: any) => handleMenuClick(e, request.id)} >
                                                <MoreVertIcon />
                                            </IconButton>
                                        )}


                                        <Menu
                                            anchorEl={anchor}
                                            keepMounted
                                            open={!!anchor && menuReceiptId === request.id}
                                            onClose={handleClose}
                                        >
                                            <MenuItem onClick={() => {
                                                getPreview(request.id);
                                                handleClose();
                                            }} disabled={updateInProgress.includes(request.id)} >
                                                Visualizza anteprima
                                            </MenuItem>
                                            <MenuItem onClick={() => {
                                                emitReceiptConfirm(request);
                                                handleClose();
                                            }} disabled={updateInProgress.includes(request.id)}>
                                                Emetti ricevuta
                                            </MenuItem>
                                            {(request.methodName) === 'Contanti' && (
                                                <>
                                                    <Divider style={{ margin: '6px 2px' }} />
                                                    <MenuItem onClick={() => {
                                                        sendCashReceiptConfirmationWarnig(request);
                                                        handleClose();
                                                    }} disabled={updateInProgress.includes(request.id) || request.cashConfirmationNoticeSentAt}>
                                                        Conferma invio
                                                    </MenuItem>
                                                    <MenuItem onClick={() => {
                                                        markAsManualIssued(request);
                                                        handleClose();
                                                    }} disabled={updateInProgress.includes(request.id)} >
                                                        Segna come emessa manualmente
                                                    </MenuItem>
                                                </>
                                            )}
                                            <Divider style={{ margin: '6px 2px' }} />
                                            <MenuItem onClick={() => {
                                                deleteRequest(request);
                                                handleClose();
                                            }} disabled={updateInProgress.includes(request.id)} >
                                                Elimina richiesta
                                            </MenuItem>
                                        </Menu>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <IssueReceiptDialog
                open={isConfirmDialogOpen}
                closeDialog={() => setIsConfirmDialogOpen(false)}
                data={confirmDialogData}
                requestId={confirmDialogRequestId}
                updateData={updateData}
            />
        </>
    );
};

export default ReceiptsRequestsPage;
