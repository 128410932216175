import { ButtonProps } from '@material-ui/core/Button';

import CustomDialogWrapper from '../../../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import GenericObject from '../../../../../../../../typesAdditional/GenericObject';
import { Checkbox, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';

interface Props {
    open: boolean;
    closeDialog: () => void;
    periods: GenericObject[];
    selectedPeriods: string[];
    selectPeriod: (id: string, selected: boolean) => void;
    ignoreAmount?: boolean;
}

const SelectPeriodsDialog = (props: Props) => {
    const { open, closeDialog, periods, selectedPeriods, selectPeriod, ignoreAmount } = props;

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        },
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Seleziona periodo'} buttons={buttons} maxWidth='xs' fullWidth contentProps={{ style: { padding: 0 } }}>
            <TableContainer style={{ marginTop: '0px', marginBottom: '32px' }}>
                <Table size='small'>
                    <TableBody>
                        {periods.map((p: any) => {
                            return (
                                <TableRow>
                                    <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>
                                        <Checkbox
                                            color='primary'
                                            style={{ padding: '4px' }}
                                            checked={selectedPeriods.includes(p.id)}
                                            onChange={(e) => {
                                                selectPeriod(p.id, e.target.checked);
                                            }}
                                            disabled={p.toPay < 0.001 && !ignoreAmount}
                                        />
                                    </TableCell>
                                    <TableCell>{p.name}</TableCell>

                                    {!ignoreAmount && (
                                        <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>€ {parseFloat(p.toPay) % 1 === 0 ? parseFloat(p.toPay) : parseFloat(p.toPay).toFixed(2)}</TableCell>
                                    )}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </CustomDialogWrapper>
    );
};

export default SelectPeriodsDialog;
