import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Backdrop from '@material-ui/core/Backdrop';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import { PaperProps } from '@material-ui/core/Paper';
import _ from 'lodash';
import { useDebounce } from 'use-debounce';
import { v4 as uuidv4 } from 'uuid';

import { add, remove } from '../../../redux/dialogs/dialogsSlice';
import { ReduxState } from '../../../redux/types';
import { BackdropWithoutTransition, StyledPaper } from './styled';

export interface Props extends DialogProps {
    alwaysVisible?: boolean;
}

const CustomDialog = (props: Props) => {
    const { open, alwaysVisible, ...dialogProps } = props;

    const dialogId = useRef<string | null>(null);

    const [firstDialog] = useSelector((state: ReduxState) => state.dialogs.slice(0, 1), _.isEqual);
    const [foregroundDialog] = useSelector((state: ReduxState) => state.dialogs.slice(-1), _.isEqual);

    const [debouncedFirstDialog] = useDebounce(firstDialog, 250);

    const dispatch = useDispatch();

    useEffect(() => {
        dialogId.current = uuidv4();
    }, []);

    useEffect(() => {
        if (!dialogId) {
            return;
        }

        const action = open ? add : remove;
        dispatch(action(dialogId.current));
    }, [dispatch, open, dialogId]);

    const BackdropComponent = [firstDialog, debouncedFirstDialog].some(id => id === dialogId.current) ? Backdrop : BackdropWithoutTransition;

    return (
        <Dialog
            open={open}
            BackdropComponent={BackdropComponent}
            BackdropProps={{ invisible: !(foregroundDialog === dialogId.current || (firstDialog === foregroundDialog)) }}
            PaperComponent={StyledPaper}
            PaperProps={{ show: ((foregroundDialog === dialogId.current) || alwaysVisible) ? 1 : 0 } as PaperProps}
            {...dialogProps}
        />
    );
};

export default CustomDialog;
