import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import api from '../../../../../../common/utils/api';
import GenericObject from '../../../../../../typesAdditional/GenericObject';
import StepFooter from '../../components/StepFooter/StepFooter';
import { GenericStepProps } from '../types';
import DocumentCard from './components/DocumentCard/DocumentCard';
import MissingDocumentsAlert from './components/MissingDocumentsAlert/MissingDocumentsAlert';
import NoMissingDocumentsAlert from './components/NoMissingDocumentsAlert/NoMissingDocumentsAlert';
import { Asset, Document } from './types';

interface Props extends GenericStepProps {
    documentAssets: GenericObject;
    setDocumentAssets: Dispatch<SetStateAction<GenericObject>>;
}

const RegistrationStep4 = (props: Props) => {
    const { courseId, data, prevStep, nextStep, rawOnChange, documentAssets, setDocumentAssets, courseData, gtmStepPush, selectedLocationId, preselectedLocation } = props;
    const { participant } = data;

    const [isLoading, setIsLoading] = useState(true);
    const [documentsData, setDocumentsData] = useState<Document[]>([]);

    useEffect(() => {
        const campTown = (courseData?.locations?.find((l: any) => l.id === selectedLocationId) ?? preselectedLocation)?.town?.name;

        const analyticsData = {
            visitorLoggedIn: '1',
            summer_camp: courseData?.name ?? '',
            camp_town: campTown ?? '',
            student_country: 'Italy',
            student_city: data?.participant?.address?.town_name ?? '',
            student_zip: data?.participant?.address?.zip ?? '',
        };

        gtmStepPush(3, analyticsData);
    }, [gtmStepPush, courseData, selectedLocationId, data, preselectedLocation]);

    const missingDocuments = documentsData.filter(d => d.status === 'missing');
    const countMissingDocuments = missingDocuments.length;

    const handleAssetChange = useCallback((documentId: string, assets: Asset[]) => {
        setDocumentAssets(currentState => {
            return {
                ...currentState,
                [documentId]: assets
            };
        });
    }, [setDocumentAssets]);

    useEffect(() => {
        api.request('/courses/' + courseId + '/documents/list', 'POST', { participant }).then(res => {
            setDocumentsData(res);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [courseId, participant]);

    const internalNextStep = useCallback(() => {
        const documents = Object.entries(documentAssets).map(([key, value]) => {
            return {
                documentTypeId: key,
                assets: value.map((asset: Asset) => asset.id)
            };
        }).filter(document => document.assets.length > 0);

        rawOnChange('documents', documents);
        nextStep();
    }, [documentAssets, nextStep, rawOnChange]);

    const isUploading = Object.entries(documentAssets).some((d: any) => d[1].some((a: any) => a.status === 'uploading'));

    return (
        <>
            {documentsData.length > 0 ? (
                <>
                    {countMissingDocuments === 0 ? (
                        <NoMissingDocumentsAlert count={documentsData.length} seasonId={courseData.season.id} />
                    ) : (
                        <MissingDocumentsAlert missingDocuments={missingDocuments} count={documentsData.length} courseData={courseData} />
                    )}

                    <div>
                        {documentsData.map((document: Document) => {
                            return <DocumentCard initialAssets={documentAssets[document.documentType.id] ?? []} document={document} handleAssetChange={handleAssetChange} />;
                        })}
                    </div>
                </>
            ) : (
                <NoMissingDocumentsAlert count={documentsData.length} seasonId={courseData.season.id} />
            )}

            <StepFooter isLoading={isLoading || isUploading} prevStep={prevStep} nextStep={internalNextStep} />
        </>
    );
};

export default RegistrationStep4;
