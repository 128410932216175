import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import api from '../../../../common/utils/api';
import ConditionDetailsDialog from './components/ConditionDetailsDialog/ConditionDetailsDialog';
import ConditionDialog from './components/ConditionDialog/ConditionDialog';
import useFilters from './hooks/useFilters';
import CustomTextField from '../../../../common/components/CustomTextField/CustomTextField';
import MenuItem from '@material-ui/core/MenuItem';

interface Props {
    filtersTree: any;
    setFiltersTree: Dispatch<SetStateAction<any>>;
    simulationResult?: any;
    setSimulationResult?: (data: any) => void;
    hint?: string;
    showSelectionTypeMenu?: boolean;
    selectionType: 'people' | 'users';
    setSelectionType: (type: 'people' | 'users') => void;
}

const UserSegmentSelector = (props: Props) => {
    const { filtersTree, setFiltersTree, setSimulationResult: externalSetSimulationResult, simulationResult: externalSimulationResult, hint, showSelectionTypeMenu, selectionType, setSelectionType } = props;

    const filters = useFilters();

    const [conditionStepDialog, setConditionStepDialog] = useState(0);
    const [selectedFilter, setSelectedFilter] = useState('all');
    const [selectedFilterPath, setSelectedFilterPath] = useState('');
    const [initialParams, setInitialParams] = useState<any>({});
    const [simulationResult, setSimulationResult] = useState({ countEntities: 0, filters: [] });

    const isDialogOpen = conditionStepDialog > 0;

    useEffect(() => {
        if (isDialogOpen) {
            setSelectedFilter(initialParams.key ?? 'all');
        }
    }, [initialParams, isDialogOpen]);

    useEffect(() => {
        api.request('/admin/user_segments/simulate', 'POST', { filter: filtersTree, selectionType }).then(res => {
            setSimulationResult(res);

            if (externalSetSimulationResult) {
                externalSetSimulationResult(res);
            }
        });
    }, [selectionType, filtersTree, externalSetSimulationResult]);

    const saveCondition = useCallback((data: any) => {
        const newTree = [...filtersTree];

        if (!selectedFilterPath) {
            // @ts-ignore
            newTree.push([{ ...data, id: uuidv4() }]);
        } else {
            const selected = _.get(newTree, selectedFilterPath);

            if (Array.isArray(selected)) {
                selected.push({ ...data, id: uuidv4() });
                _.set(newTree, selectedFilterPath, selected);
            } else {
                _.set(newTree, selectedFilterPath, data);
            }
        }

        setFiltersTree(newTree);
        setConditionStepDialog(0);
    }, [setFiltersTree, filtersTree, selectedFilterPath]);

    const deleteCondition = useCallback((path: string) => {
        const newTree = [...filtersTree];
        _.unset(newTree, path);
        setFiltersTree(newTree.map((section: any[]) => section.filter((n: any) => n)).filter((n: any) => n.length !== 0));
    }, [setFiltersTree, filtersTree]);

    return (
        <>
            <Card style={{ maxWidth: '600px' }}>
                <CardContent>
                    {hint && (
                        <Typography variant='h5' component='h2' style={{ marginBottom: '12px' }}>
                            {hint}
                        </Typography>
                    )}

                    {showSelectionTypeMenu && (
                        <CustomTextField
                            select
                            label={'Applica le condizioni intermedie:'}
                            value={selectionType}
                            onChange={(e: any) => {
                                setSelectionType(e.target.value);
                            }}
                            variant='outlined'
                            style={{ margin: '6px 0 18px' }}
                            fullWidth
                        >
                            <MenuItem value={'people'} style={{ whiteSpace: 'break-spaces' }}>
                                sulle persone
                            </MenuItem>
                            <MenuItem value={'users'} style={{ whiteSpace: 'break-spaces' }}>
                                sugli utenti
                            </MenuItem>
                        </CustomTextField>
                    )}

                    {filtersTree.map((sections: any, sectionIndex: number) => (
                        <>
                            <Card style={{ padding: '12px', margin: '16px 4px', border: '1px solid rgba(0, 0, 0, 0.3);' }} variant='outlined'>
                                {sections.map((filter: any, filterIndex: number) => {
                                    const thisFilterDetails = ((externalSimulationResult ?? simulationResult).filters.flat().find((f: any) => f.id === filter.id) as any)?.details ?? filter.details;

                                    return (
                                        <>
                                            <Card style={{ padding: '12px', margin: '0 4px 16px', border: '1px solid rgba(0, 0, 0, 0.12);' }} variant='outlined'>
                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ flex: '1' }}>
                                                        <p style={{ marginTop: '0px' }}>{thisFilterDetails.description}</p>
                                                        {selectionType === 'people' && (
                                                            <p style={{ marginBottom: '0px' }}>Person{thisFilterDetails.countPeople === 1 ? 'a' : 'e'} selezionat{thisFilterDetails.countPeople === 1 ? 'a' : 'e'}: <b>{thisFilterDetails.countPeople}</b></p>
                                                        )}
                                                        {selectionType === 'users' && (
                                                            <p style={{ marginBottom: '0px' }}>
                                                                Utent{thisFilterDetails.countUsers === 1 ? 'e' : 'i'} selezionat{thisFilterDetails.countUsers === 1 ? 'o' : 'i'}: <b>{thisFilterDetails.countUsers}</b><br />
                                                                {filter.reverse && (
                                                                    <>
                                                                        {filter.reverse_entity === 'users' ? (
                                                                            '(il genitore o almeno un figlio rispetta la condizione)'
                                                                        ) : (
                                                                            '(il genitore e tutti i figli rispettano la condizione)'
                                                                        )}
                                                                    </>
                                                                )}
                                                            </p>
                                                        )}
                                                    </div>
                                                    <div style={{ flex: '0 1 0%', whiteSpace: 'nowrap', textAlign: 'right' }}>
                                                        <IconButton style={{ padding: '4px', marginRight: '-4px' }} edge='end' onClick={() => {
                                                            setSelectedFilterPath('[' + sectionIndex + '][' + filterIndex + ']');
                                                            setInitialParams(filter);
                                                            setConditionStepDialog(2);
                                                        }}>
                                                            <EditIcon />
                                                        </IconButton>

                                                        <IconButton style={{ padding: '4px', marginRight: '-4px' }} edge='end' onClick={() => {
                                                            deleteCondition('[' + sectionIndex + '][' + filterIndex + ']');
                                                        }}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            </Card>
                                            {filterIndex !== (sections.length - 1) && (
                                                <p style={{ textAlign: 'center', fontSize: '1.2em', fontWeight: 500 }}>E (AND)</p>
                                            )}
                                        </>
                                    )
                                })}
                                <Button variant='outlined' color='primary' style={{ margin: '0 auto', display: 'block' }} onClick={() => {
                                    setSelectedFilterPath('[' + sectionIndex + ']');
                                    setInitialParams({});
                                    setConditionStepDialog(1);
                                }}>
                                    Aggiungi condizione e (AND)
                                </Button>
                            </Card>
                            {sectionIndex !== (filtersTree.length - 1) && (
                                <p style={{ textAlign: 'center', fontSize: '1.2em', fontWeight: 500 }}>O (OR)</p>
                            )}
                        </>
                    ))}

                    <Button variant='outlined' color='primary' style={{ margin: '0 auto', display: 'block' }} onClick={() => {
                        setSelectedFilterPath('');
                        setInitialParams({});
                        setConditionStepDialog(1);
                    }}>
                        Aggiungi condizione{filtersTree.length === 0 ? '' : ' o (OR)'}
                    </Button>

                    <div style={{ marginTop: '12px' }}>
                        {filtersTree.length === 0 ? (
                            <Alert severity='warning'>
                                <p style={{ margin: '0px' }}>
                                    È necessario aggiungere almeno una condizione.
                                </p>
                            </Alert>
                        ) : ((simulationResult.countEntities ?? 0) === 0 ? (
                            <Alert severity='warning'>
                                <p style={{ margin: '0px' }}>
                                    Non è {selectionType === 'people' ? 'stata trovata nessuna persona' : 'stato trovato nessun utente'} che rispetta le condizioni impostate.
                                </p>
                            </Alert>
                        ) : (
                            <Alert severity='info'>
                                {selectionType === 'people' ? (
                                    <>
                                        <AlertTitle>Dettagli persone selezionate</AlertTitle>
                                        <p style={{ marginBottom: '0px' }}>Person{simulationResult.countEntities === 1 ? 'a' : 'e'} selezionat{simulationResult.countEntities === 1 ? 'a' : 'e'}: <b>{simulationResult.countEntities}</b></p>
                                    </>
                                ) : (
                                    <>
                                        <AlertTitle>Dettagli utenti selezionati</AlertTitle>
                                        <p style={{ marginBottom: '0px' }}>Utent{simulationResult.countEntities === 1 ? 'e' : 'i'} selezionat{simulationResult.countEntities === 1 ? 'o' : 'i'}: <b>{simulationResult.countEntities}</b></p>
                                    </>
                                )}
                            </Alert>
                        ))}
                    </div>

                </CardContent>
            </Card>

            <ConditionDialog
                filters={filters}
                open={conditionStepDialog === 1}
                closeDialog={() => setConditionStepDialog(0)}
                conditionNextStep={() => setConditionStepDialog(2)}
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
            />

            <ConditionDetailsDialog
                open={conditionStepDialog === 2}
                closeDialog={() => setConditionStepDialog(0)}
                saveCondition={saveCondition}
                selectedFilter={selectedFilter}
                initialParams={initialParams}
                selectionType={selectionType}
            />
        </>
    );
};

export default UserSegmentSelector;
