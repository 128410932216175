import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ButtonProps } from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { v4 as uuidv4 } from 'uuid';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import CustomTextField from '../../../../../../common/components/CustomTextField/CustomTextField';
import api from '../../../../../../common/utils/api';
import TownAutoComplete from '../../../../../public/pages/registrationPage/components/GenericPersonForm/sections/address/components/TownAutocomplete/TownAutocomplete';

interface Props {
    open: boolean;
    closeDialog: () => void;
    saveCondition: (data: any) => void;
    selectedFilter: string;
    initialParams: any;
    selectionType: 'people' | 'users'
}

const ConditionDetailsDialog = (props: Props) => {
    const { open, closeDialog, saveCondition, selectedFilter, initialParams, selectionType } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [params, setParams] = useState<any>({});
    const [paramsDefinition, setParamsDefinition] = useState<any[]>([]);
    const [filterDetails, setFilterDetails] = useState<any>({});

    const apiRequestId = useRef('');

    useEffect(() => {
        api.request('/admin/user_segments/filters/' + selectedFilter).then(res => {
            setParamsDefinition(res?.params ?? []);
        });

        setParams(initialParams);
    }, [selectedFilter, initialParams]);

    useEffect(() => {
        const currentRequestId = uuidv4();
        apiRequestId.current = currentRequestId;
        setIsLoading(true);

        api.request('/admin/user_segments/simulate_filter', 'POST', { key: selectedFilter, selectionType, ...params }).then((res: any) => {
            if (currentRequestId !== apiRequestId.current) return;

            if (res.params) {
                setParamsDefinition(res.params);
            }

            setFilterDetails(res);
            setIsLoading(false);
        });
    }, [selectedFilter, params, selectionType]);

    const buttons: ButtonProps[] = [
        {
            children: 'Salva',
            color: 'primary',
            onClick: () => saveCondition({ key: selectedFilter, ...params, details: filterDetails }),
            disabled: filterDetails.error === 'invalid_filter_params'
        },
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        },
    ];

    const dummyTownData = useMemo(() => {
        return {
            dummy: {
                address: {
                    town_legal_id: params.town
                }
            }
        };
    }, [params]);

    const dummyTownRawOnChange = useCallback((path: string, value: string) => {
        if (path === 'dummy.address.town_legal_id') {
            setParams((oldParams: any) => {
                return { ...oldParams, town: value };
            });
        }
    }, []);

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Dettagli condizione'} buttons={buttons} isLoading={isLoading} maxWidth='sm' fullWidth>
            {paramsDefinition.map((p: any) => {
                if (p.type === 'number') {
                    return (
                        <CustomTextField
                            label={p.name}
                            variant='outlined'
                            value={params[p.key]}
                            keepMounted={true}
                            onChange={(e: any) => {
                                setParams((oldParams: any) => {
                                    let value: string | number = parseInt(e.target.value, 10);

                                    if (p.maximum !== undefined) {
                                        value = Math.min(value, p.maximum);
                                    }

                                    if (p.minimum !== undefined) {
                                        value = Math.max(value, p.minimum);
                                    }

                                    if (isNaN(value)) {
                                        value = '';
                                    }

                                    return { ...oldParams, [p.key]: value };
                                });
                            }}
                        // disabled={isLoading}
                        />
                    );
                } else if (p.type === 'select') {
                    return (
                        <CustomTextField
                            select
                            label={p.name}
                            value={params[p.key] ?? 0}
                            onChange={(e: any) => {
                                setParams((oldParams: any) => {
                                    return { ...oldParams, [p.key]: e.target.value };
                                });
                            }}
                            variant='outlined'
                            disabled={isLoading}
                            fullWidth
                        >
                            <MenuItem value={0} style={{ whiteSpace: 'break-spaces' }}>
                                {p.default}
                            </MenuItem>
                            {p.values.map((opt: any, index: number) => (
                                <MenuItem key={index} value={opt.value} style={{ whiteSpace: 'break-spaces' }}>
                                    {opt.label}
                                </MenuItem>
                            ))}
                        </CustomTextField>
                    );
                } else if (p.type === 'town_autocomplete') {
                    return (
                        <TownAutoComplete data={dummyTownData} formPrefix={'dummy'} rawOnChange={dummyTownRawOnChange} />
                    );
                } else if (p.type === 'boolean') {
                    return (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color='primary'
                                    style={{ padding: '4px' }}
                                    checked={params[p.key]}
                                    onChange={(e) => {
                                        setParams((oldParams: any) => {
                                            return { ...oldParams, [p.key]: e.target.checked };
                                        });
                                    }}
                                />
                            }
                            style={{ marginLeft: '-4px', marginBottom: '8px', display: 'block' }}
                            label={p.name}
                            disabled={isLoading}
                        />
                    );
                }

                return null;
            })}

            {paramsDefinition.some(p => p.key === 'reverse') && selectionType === 'users' && params.reverse && (
                <CustomTextField
                    select
                    label={'Seleziona utenti per i quali'}
                    value={params.reverse_entity ?? 'people'}
                    onChange={(e: any) => {
                        setParams((oldParams: any) => {
                            return { ...oldParams, reverse_entity: e.target.value };
                        });
                    }}
                    variant='outlined'
                    disabled={isLoading}
                    fullWidth
                    style={{ margin: '8px 0 18px' }}
                >
                    <MenuItem value={'people'} style={{ whiteSpace: 'break-spaces' }}>
                        il genitore e tutti i figli rispettano la condizione
                    </MenuItem>
                    <MenuItem value={'users'} style={{ whiteSpace: 'break-spaces' }}>
                        il genitore o almeno un figlio rispetta la condizione
                    </MenuItem>
                </CustomTextField>
            )}

            {filterDetails.error === 'invalid_filter_params' && (
                <Alert severity='warning'>
                    {filterDetails.errorDesc}
                </Alert>
            )}

            {filterDetails.status === 'ok' && (
                <Alert severity='info'>
                    <AlertTitle>Dettagli {selectionType === 'people' ? 'persone selezionate' : 'selezione'}</AlertTitle>
                    <p>{filterDetails.description}</p>
                    {selectionType === 'people' && (
                        <p style={{ marginBottom: '0px' }}>Person{filterDetails.countPeople === 1 ? 'a' : 'e'} selezionat{filterDetails.countPeople === 1 ? 'a' : 'e'}: <b>{filterDetails.countPeople}</b></p>
                    )}
                    {selectionType === 'users' && (
                        <p style={{ marginBottom: '0px' }}>
                            Utent{filterDetails.countUsers === 1 ? 'e' : 'i'} selezionat{filterDetails.countUsers === 1 ? 'o' : 'i'}: <b>{filterDetails.countUsers}</b><br />
                            {params.reverse && (
                                <>
                                    {params.reverse_entity === 'users' ? (
                                        '(il genitore o almeno un figlio rispetta la condizione)'
                                    ) : (
                                        '(il genitore e tutti i figli rispettano la condizione)'
                                    )}
                                </>
                            )}
                        </p>
                    )}
                </Alert>
            )}
        </CustomDialogWrapper>
    );
};

export default ConditionDetailsDialog;
