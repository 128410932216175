import { useCallback, useMemo, useState } from 'react';

import { ButtonProps } from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import { StyledTextField } from '../../../../../../common/components/CustomTextField/styled';
import { useMessageDialog } from '../../../../../../common/hooks/useMessageDialog/useMessageDialog';

interface Props {
    open: boolean;
    closeDialog: () => void;
    isLoading: boolean;
    handleLogin: (password: string) => void;
}

const PasswordExpiredDialog = (props: Props) => {
    const { open, closeDialog, isLoading, handleLogin } = props;

    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [isDirty, setIsDirty] = useState(false);

    const [showMessageDialog] = useMessageDialog();

    const validationError = useMemo(() => {
        if (password.length < 6) {
            return 'too_short';
        } else if (password !== passwordConfirm) {
            return 'mismatch';
        }

        return null;
    }, [password, passwordConfirm]);

    const handleChangePassword = useCallback(() => {
        setIsDirty(true);

        if (validationError === 'too_short') {
            return showMessageDialog({
                title: 'Errore di validazione',
                message: 'La password deve essere lunga almeno 6 caratteri.'
            });
        } else if (validationError === 'mismatch') {
            return showMessageDialog({
                title: 'Errore di validazione',
                message: 'La password e la conferma non corrispondono.'
            });
        }

        handleLogin(password);
    }, [password, handleLogin, showMessageDialog, validationError]);

    const handleKeyDown = useCallback((e) => {
        if (e.keyCode === 13) {
            handleChangePassword();
        }
    }, [handleChangePassword]);

    const buttons: ButtonProps[] = [
        {
            children: 'Salva',
            color: 'primary',
            onClick: () => handleChangePassword()
        },
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        },
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Modifica password'} buttons={buttons} isLoading={isLoading} maxWidth='sm' fullWidth>
            <Alert severity='info' style={{marginBottom: '16px'}}>
                <AlertTitle>Password scaduta</AlertTitle>
                La password di accesso è scaduta. È necessario modificarla per proseguire.
            </Alert>

            <StyledTextField
                label={'Nuova password'}
                variant='outlined'
                type='password'
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handleKeyDown}
                error={(!!password || isDirty) && validationError === 'too_short'}
                fullWidth
            />
            <StyledTextField
                label={'Conferma password'}
                variant='outlined'
                type='password'
                onChange={(e) => setPasswordConfirm(e.target.value)}
                onKeyDown={handleKeyDown}
                error={(!!passwordConfirm || isDirty) && !!validationError}
                fullWidth
            />
        </CustomDialogWrapper>
    );
};

export default PasswordExpiredDialog;
