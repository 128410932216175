import browserUpdate from 'browser-update';

interface Props {
    eventId: string | null;
}

const ErrorBoundaryFallback = (props: Props) => {
    const { eventId } = props;

    browserUpdate({
        required: {
            e: -4,
            f: -3,
            o: -3,
            s: -1,
            c: -1
        },
        insecure: true,
        unsupported: true,
        style: 'top',
        noclose: true,
    });

    return (
        <>
            <p style={{marginLeft: '8px'}}>
                Sì è verificato un errore imprevisto.
                Il team tecnico di Educazione.Sport è già stato informato.
            </p>
            <p style={{marginLeft: '8px'}}>
                Ti invitiamo a riprovare più tardi, oppure scrivi a{' '}
                <a href='mailto:info@epuntos.it' target='_blank' rel='noreferrer'>info@epuntos.it</a>{' '}
                {eventId && <>comunicando il codice d'errore <b>{eventId}</b>{' '}</>}
                per avere maggiori informazioni.
            </p>
        </>
    );
};

export default ErrorBoundaryFallback;
