import { useCallback, useState } from 'react';

import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import 'moment/locale/it';

import CustomTextField, { Props as CustomTextFieldProps } from '../../../../../../../../common/components/CustomTextField/CustomTextField';
import FlexInputContainer from '../../../../../../../../common/components/FlexInputContainer/FlexInputContainer';
import PlaceOfBirthField from '../../../PlaceOfBirthField/PlaceOfBirthField';
import { SectionProps } from '../../GenericPersonForm';
import useValidateBasicData from '../../hooks/useValidateBasicData';

interface Props extends SectionProps {
    withTaxCode: boolean;
}

const BasicData = (props: Props) => {
    const { rawOnChange, dataOnChange, withTaxCode, disabledFields, ...validatorProps } = props;
    const { formPrefix, data, validationResult } = validatorProps;

    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

    // const personExists = data[formPrefix]?.id?.length > 0;
    const personExists = false;
    const isAdmin = (window.location.pathname.startsWith('/admin') || window.location.host.startsWith('admin.epuntos.it'));

    const dateOfBirthOnChange = useCallback((date: MaterialUiPickersDate) => {
        rawOnChange(formPrefix + '.date_of_birth', date?.format('YYYY-MM-DD'));
        setIsDatePickerOpen(false);
    }, [formPrefix, rawOnChange]);

    const commonProps: Pick<CustomTextFieldProps, 'data' | 'dataOnChange' | 'variant' | 'disabled'> = {
        variant: 'outlined',
        data,
        dataOnChange,
    };

    const { tax_code: isTaxCodeValid, date_of_birth: isDateOfBirthValid } = useValidateBasicData(validatorProps);

    const validation = validationResult[formPrefix];

    const placeOfBirth = validation?.taxCode?.townOfBirth?.name ?? validation?.taxCode?.countryOfBirth?.name ?? '';
    const dateOfBirth = data[formPrefix]?.date_of_birth ?? null;

    return (
        <>
            <FlexInputContainer>
                <CustomTextField label='Nome' path={formPrefix + '.name'} disabled={(personExists && !isAdmin) || (disabledFields && disabledFields.includes('name'))} {...commonProps} />
                <CustomTextField label='Cognome' path={formPrefix + '.surname'} disabled={(personExists && !isAdmin) || (disabledFields && disabledFields.includes('surname'))} {...commonProps} />
                {withTaxCode && (
                    <CustomTextField
                        label='Codice fiscale'
                        path={formPrefix + '.tax_code'}
                        inputProps={{ maxLength: 16, style: { textTransform: 'uppercase' } }}
                        error={!isTaxCodeValid && (data[formPrefix]?.tax_code || !isAdmin)}
                        disabled={(personExists && !isAdmin) || (disabledFields && disabledFields.includes('taxCode'))}
                        {...commonProps}
                    />
                )}
            </FlexInputContainer>

            {withTaxCode && (
                <FlexInputContainer>
                    <PlaceOfBirthField prefix={formPrefix} data={data} value={placeOfBirth} disabled={(personExists && !isAdmin) || (disabledFields && disabledFields.includes('taxCode'))} rawOnChange={rawOnChange} />

                    <MuiPickersUtilsProvider locale='it' utils={MomentUtils}>
                        <KeyboardDatePicker
                            label='Data di nascita'
                            error={!isDateOfBirthValid}
                            inputVariant='outlined'
                            minDate={moment(Date.now() - 120 * 365 * 24 * 3600 * 1000).format('YYYY-MM-DD')}
                            maxDate={moment(Date.now()).format('YYYY-MM-DD')}
                            format='DD/MM/YYYY'
                            value={dateOfBirth}
                            onChange={dateOfBirthOnChange}
                            open={isDatePickerOpen}
                            onClick={() => {
                                if (personExists && !isAdmin) return;

                                setIsDatePickerOpen(true);
                            }}
                            onClose={() => setIsDatePickerOpen(false)}
                            onKeyDown={(e) => {
                                e.preventDefault();
                                return false;
                            }}
                            style={{ flex: 1, marginBottom: '12px' }}
                            inputProps={{ tabIndex: -1 }}
                            KeyboardButtonProps={{ tabIndex: -1 }}
                            fullWidth
                            disabled={(personExists && !isAdmin) || (disabledFields && disabledFields.includes('dateOfBirth'))}
                        />
                    </MuiPickersUtilsProvider>
                </FlexInputContainer>
            )}
        </>
    );
};

export default BasicData;
