import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';

export const IconContainer = styled.div`
    width: 40px;
    height: 44px;
    text-align: center;
    margin-right: 8px;
`;

export const StyledIcon = styled(FontAwesomeIcon)`
    padding: 6px 0;
`;
