import { useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import useInternalLoader from '../../../../common/hooks/useInternalLoader/useExternalLoader';
import { useMessageDialog } from '../../../../common/hooks/useMessageDialog/useMessageDialog';
import routes from '../../routes';
import UserInfoSection from './components/UserInfoSection/UserInfoSection';

const DrawerContent = () => {
    const [showMessageDialog] = useMessageDialog();

    const [lastPath, setLastPath] = useState('');

    const setIsLoading = useInternalLoader(false);

    const items = useMemo(() => {
        return routes.map(route => {
            if (!route.showInSidebar) {
                return null;
            }

            const exactPath = route.exactPathSidebar && route.exactPath;

            return (
                <ListItem button key={route.path} to={route.path} exact={exactPath} component={NavLink} activeClassName='Mui-selected' onClick={(e: any) => {
                    if (route.path !== lastPath) {
                        setLastPath(route.path);
                        setIsLoading(true);

                        setTimeout(() => {
                            setIsLoading(false);
                        }, 500);
                    }

                    if (route.workInProgress) {
                        showMessageDialog({
                            title: 'Pagina non disponibile',
                            message: 'La pagina selezionata è in fase di sviluppo'
                        });
                        e.preventDefault();
                        return false;
                    }
                }}>
                    <>
                        <div style={{width: 40, height: 44, textAlign: 'center', marginRight: '8px'}}>
                            {route.icon && <FontAwesomeIcon icon={route.icon} size='2x' style={{padding: '6px 0'}} color='#1c9100' />}
                        </div>

                        <ListItemText primary={route.name ?? ''} />
                    </>
                </ListItem>
            );
        });
    }, [lastPath, setIsLoading, showMessageDialog]);

    return (
        <>
            <UserInfoSection />
            <div style={{flex: '1 0 auto'}} >
                {items}
            </div>
            <div style={{flexShrink: 0, margin: '12px'}}>
                <img src={process.env.PUBLIC_URL + '/logo512.png'} alt='logo' style={{width: '140px', margin: '0 auto', display: 'block'}} />
            </div>

        </>
    );
};

export default DrawerContent;
