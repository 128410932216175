import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';
import { MuiThemeProvider, StylesProvider } from '@material-ui/core/styles';
import * as Sentry from '@sentry/react';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from 'styled-components';

import ErrorBoundaryFallback from './common/components/ErrorBoundaryFallback/ErrorBoundaryFallback';
import LoaderWrapper from './common/components/LoaderWrapper/LoaderWrapper';
import SentryConfigDispatcher from './common/components/SentryConfigDispatcher/SentryConfigDispatcher';
import DialogProvider from './common/hooks/useMessageDialog/useMessageDialog';
import { SnackbarUtilsConfigurator } from './common/utils/snackbar';
import store from './redux/store';
import MainRouter from './routing/MainRouter/MainRouter';

const App = () => {
    useEffect(() => {
        // tslint:disable no-console
        console.info('%cMade with ❤️ in 🇮🇹 by Cristian Livella.', 'font-size: 16px');
        console.info('%cIf you would like to report bugs, problems, or suggestions, please email me at cristian@cristianlivella.com.', 'font-size: 14px');
        console.info('');
        console.info('%cATTENZIONE:', 'color:red; font-size:60px; font-weight: bold; -webkit-text-stroke: 1px black');
        console.info('%cL\'utilizzo di questa console è riservato agli sviluppatori. Non scrivere o inserire codici che non comprendi, in particolare modo se qualcuno ti ha chiesto espressamente di farlo, in quanto potrebbe essere un tentativo per compromettere la sicurezza del tuo account e dei tuoi dati personali. Per maggiori informazioni: https://it.wikipedia.org/wiki/Self-XSS', 'font-size: 18px');
        // tslint:enable no-console
    }, []);

    const theme = createMuiTheme();

    return (
        <Sentry.ErrorBoundary fallback={ErrorBoundaryFallback}>
            <Provider store={store}>
                <StylesProvider injectFirst>
                    <MuiThemeProvider theme={theme}>
                        <ThemeProvider theme={theme}>
                            <SnackbarProvider anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }} autoHideDuration={5000} maxSnack={10}>
                                <SnackbarUtilsConfigurator />
                                <DialogProvider>
                                    <>
                                        <LoaderWrapper type='external'>
                                            <BrowserRouter basename={process.env.PUBLIC_URL}>
                                                <MainRouter />
                                            </BrowserRouter>
                                        </LoaderWrapper>
                                        <SentryConfigDispatcher />
                                    </>
                                </DialogProvider>
                            </SnackbarProvider>
                        </ThemeProvider>
                    </MuiThemeProvider>
                </StylesProvider>
            </Provider>
        </Sentry.ErrorBoundary>
    );
};

export default App;
