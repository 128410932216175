import { useCallback, useEffect, useState } from 'react';

import MomentUtils from '@date-io/moment';
import { ButtonProps } from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import 'moment/locale/it';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import api from '../../../../../../common/utils/api';
import snackbar from '../../../../../../common/utils/snackbar';

interface Props {
    open: boolean;
    closeDialog: () => void;
    data: any;
    requestId: string;
    updateData: () => void;
}

const IssueReceiptDialog = (props: Props) => {
    const { open, closeDialog, data, requestId, updateData } = props;

    const [isLoading, setIsLoading] = useState(false);

    const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

    useEffect(() => {
        if (open) {
            api.request('/admin/receipts/requests/' + requestId + '/recommended_date').then(res => {
                setDate(res?.date ?? (new Date().toISOString().split('T')[0]));
            });
        }
    }, [requestId, open]);

    const emitReceipt = useCallback(() => {
        setIsLoading(true);
        api.request('/admin/receipts/requests/' + requestId + '/issue', 'GET', { date }).then(() => {
            snackbar.success('Ricevuta emessa con successo!');
            updateData();
            closeDialog();
        }).finally(() => {
            setIsLoading(false);
        });
    }, [closeDialog, date, requestId, updateData]);

    const buttons: ButtonProps[] = [
        {
            children: 'Conferma',
            color: 'primary',
            onClick: () => emitReceipt()
        },
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        },
    ];

    const amount = parseFloat(data.amount).toFixed(2) ?? '0.00';

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Emissione ricevuta'} buttons={buttons} isLoading={isLoading} maxWidth='sm' fullWidth>
            <Typography variant='body1' style={{margin: '0 4px 16px'}}>
                Stai emettendo una ricevuta di <b>€ {amount}</b> a <b>{data.personName}</b> ({data.taxCode})
            </Typography>

            <MuiPickersUtilsProvider locale='it' utils={MomentUtils}>
                <KeyboardDatePicker
                    label='Data ricevuta'
                    inputVariant='outlined'
                    format='DD/MM/YYYY'
                    value={date}
                    onChange={(newDate: MaterialUiPickersDate) => {
                        setDate(newDate?.format('YYYY-MM-DD') ?? '');
                    }}
                    open={isDatePickerOpen}
                    onClick={() => {
                        setIsDatePickerOpen(true);
                    }}
                    onClose={() => setIsDatePickerOpen(false)}
                    onKeyDown={(e) => {
                        e.preventDefault();
                        return false;
                    }}
                    style={{flex: 1, marginTop: '14px', marginBottom: '4px'}}
                    inputProps={{tabIndex: -1}}
                    KeyboardButtonProps={{tabIndex: -1}}
                    fullWidth
                />
            </MuiPickersUtilsProvider>
        </CustomDialogWrapper>
    );
};

export default IssueReceiptDialog;
