import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Paper from '@material-ui/core/Paper';
import _ from 'lodash';

import api from '../../../../common/utils/api';
import { ReduxState } from '../../../../redux/types';
import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';
import Step4 from './steps/Step4';

const QuotePage = () => {
    const { isIframe } = useSelector((state: ReduxState) => state.routing);

    const [currentStep, setCurrentStep] = useState(1);

    const [category, setCategory] = useState('');
    const [numberOfPeople, setNumberOfPeopleImpl] = useState(1);

    const [currentPerson, setCurrentPerson] = useState(1);

    const [quoteInfo, setQuoteInfo] = useState<any>({});

    const [data, setData] = useState<any>({
        people: []
    });

    const setNumberOfPeople = useCallback((n: number) => {
        const oldPeople = [...data.people];

        if (oldPeople.length > n) {
            oldPeople.length = n;
            setData((d: any) => {
                return {
                    ...d,
                    people: oldPeople
                };
            });
        }

        setNumberOfPeopleImpl(n);
    }, [data]);

    useEffect(() => {
        api.request('/quote/info').then((res: any) => {
            setQuoteInfo(res);
        });
    }, []);

    const rawOnChange = useCallback((name: string, value: any) => {
        setData((d: any) => {
            const newObj = {...d};
            _.set(newObj, name, value);
            return newObj;
        });
    }, [setData]);

    const handleSetCategory = useCallback((cat: string) => {
        setCategory(cat);
        setNumberOfPeopleImpl(1);
        setCurrentStep(2);
    }, []);

    const goToStep3 = useCallback(() => {
        setCurrentPerson(1);
        setCurrentStep(3);
    }, []);

    const resetForm = useCallback(() => {
        setCurrentStep(1);
        setCategory('');
        setNumberOfPeopleImpl(1);
        setCurrentPerson(1);
        setData({
            people: []
        });
    }, []);

    const progress = useMemo(() => {
        if (currentStep < 3) {
            return 10;
        } else {
            return 10 + (80 / numberOfPeople * currentPerson);
        }
    }, [currentStep, numberOfPeople, currentPerson]);

    useEffect(() => {
        console.log(data);
    }, [data]);

    return (
        <>
            <Paper elevation={isIframe ? 0 : 3} style={{padding: '8px', margin: '6px auto', maxWidth: '900px'}}>

                {currentStep === 1 && (
                    <Step1 setCategory={handleSetCategory} />
                )}

                {currentStep === 2 && (
                    <Step2 numberOfPeople={numberOfPeople} setNumberOfPeople={setNumberOfPeople} nextStep={() => goToStep3()} prevStep={() => setCurrentStep(1)} progress={progress} />
                )}

                {currentStep === 3 && (
                    <Step3
                        currentPerson={currentPerson}
                        nextStep={() => {
                            if (currentPerson < numberOfPeople) {
                                setCurrentPerson(p => p + 1);
                            } else {
                                setCurrentStep(4);
                            }
                        }}
                        prevStep={() => {
                            if (currentPerson === 1) {
                                setCurrentStep(2);
                            } else {
                                setCurrentPerson(p => p - 1);
                            }
                        }}
                        progress={progress}
                        category={category}
                        quoteInfo={quoteInfo}
                        data={data}
                        setData={setData}
                        rawOnChange={rawOnChange}
                    />
                )}

                {currentStep === 4 && (
                    <Step4 setCategory={handleSetCategory} data={data} resetForm={resetForm} />
                )}
            </Paper>
        </>
    );
};

export default QuotePage;
