import { useCallback, useEffect, useRef, useState } from 'react';
import { useWindowSize } from 'react-use';

import { CircularProgress, Divider, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { useDebounce } from 'use-debounce';
import { v4 as uuidv4 } from 'uuid';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import useInternalLoader from '../../../../common/hooks/useInternalLoader/useExternalLoader';
import api from '../../../../common/utils/api';
import GenericObject from '../../../../typesAdditional/GenericObject';
import { StyledTablePagination } from './styled';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import GreenButton from '../../../../common/components/GreenButton/GreenButton';
import AddFacilityDialog from './components/AddFacilityDialog/AddFacilityDialog';
import { useMessageDialog } from '../../../../common/hooks/useMessageDialog/useMessageDialog';
import snackbar from '../../../../common/utils/snackbar';
import EditCostDialog from './components/EditCostDialog/EditCostDialog';
import { TIME_UNIT_DESC } from './components/WageChangelogDialog/WageChangelogDialog';

interface Props {
    season: 'summer' | 'winter';
}

const FacilitiesListPage = ({ season }: Props) => {
    const setIsLoading = useInternalLoader();

    const { width } = useWindowSize();

    const [paginationPage, setPaginationPage] = useState(0);
    const [paginationTotal, setPaginationTotal] = useState(0);
    const [paginationItemsPerPage, setPaginationItemsPerPage] = useState(25);

    const [facilities, setFacilities] = useState<GenericObject[]>([]);
    const [query] = useState('');
    const [loadedQuery, setLoadedQuery] = useState('');
    const [loadedPage, setLoadedPage] = useState(0);
    const [loadedItemsPerPage, setLoadedItemsPerPage] = useState(25);
    const [internalLoading, setInternalLoading] = useState(false);
    const [debouncedQuery] = useDebounce(query, 500);

    const [updateInProgress, setUpdateInProgress] = useState<string[]>([]);

    const [isAddTrainerDialogOpen, setIsAddTrainerDialogOpen] = useState(false);
    const [isEditWageDialogOpen, setIsEditWageDialogOpen] = useState(false);

    const [showMessageDialog, closeMessageDialog] = useMessageDialog();

    const [anchor, setAnchorEl] = useState<null | HTMLElement>(null);
    const [menuGroupId, setMenuGroupId] = useState<null | string>(null);

    const [selectedTrainerId, setSelectedTrainerId] = useState<string | undefined>(undefined);

    const handleMenuClick = useCallback((event: React.MouseEvent<HTMLElement>, userId: string) => {
        setAnchorEl(event.currentTarget);
        setMenuGroupId(userId);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const apiRequestId = useRef('');

    useEffect(() => {
        setInternalLoading(query !== loadedQuery || paginationPage !== loadedPage || paginationItemsPerPage !== loadedItemsPerPage);
    }, [query, loadedQuery, paginationPage, loadedPage, paginationItemsPerPage, loadedItemsPerPage]);

    useEffect(() => {
        setPaginationPage(0);
    }, [debouncedQuery]);

    const refreshData = useCallback(() => {
        const currentRequestId = uuidv4();
        apiRequestId.current = currentRequestId;

        api.request('/admin/winter_staff/facilities', 'GET', { q: debouncedQuery, page: paginationPage + 1, perPage: paginationItemsPerPage, season }).then((res: any) => {
            if (currentRequestId !== apiRequestId.current) return;

            const { data, total, lastPage } = res;
            setFacilities(data);
            setPaginationTotal(total);
            setLoadedQuery(debouncedQuery);
            setLoadedPage(paginationPage);
            setLoadedItemsPerPage(paginationItemsPerPage);

            if (paginationPage + 1 > lastPage) {
                setPaginationPage(lastPage - 1);
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }, [debouncedQuery, setIsLoading, paginationPage, paginationItemsPerPage, season]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            refreshData();
        }, 10);

        return () => clearTimeout(timeout);
    }, [refreshData]);

    const disableTrainer = useCallback((trainerId: string, name: string) => {
        showMessageDialog({
            title: 'Elimina palestra',
            message: (
                <>
                    <p style={{ margin: '0px' }}>
                        Sei sicuro di volere disabilitare la palestra <b>{name}</b>?
                    </p>
                </>
            ),
            actions: [
                {
                    text: 'Annulla',
                    action: () => {
                        closeMessageDialog();
                    }
                },
                {
                    text: 'Conferma',
                    action: () => {
                        setUpdateInProgress(a => [...a, trainerId]);
                        closeMessageDialog();

                        api.request('/admin/winter_staff/facilities/' + trainerId, 'DELETE', { season }).then(() => {
                            snackbar.success('Palestra eliminata con successo!');

                            setIsLoading(true);
                            refreshData();
                        }).finally(() => {
                            setUpdateInProgress(a => a.filter(u => u !== trainerId));
                        });
                    }
                }
            ]
        });
    }, [closeMessageDialog, showMessageDialog, refreshData, setIsLoading, season]);

    const isMobile = width <= 700;

    return (
        <>
            <div>
                <div style={{ display: isMobile ? undefined : 'flex', textAlign: isMobile ? 'center' : undefined }}>
                    <Typography variant='h2' style={{ fontSize: isMobile ? '3em' : '3.6em', textAlign: isMobile ? 'center' : 'left' }}>
                        Elenco palestre
                    </Typography>

                    <div style={{ flex: 1, flexGrow: 1 }} />


                    <GreenButton onClick={() => {
                        setIsAddTrainerDialogOpen(true)
                        setSelectedTrainerId(undefined);
                    }} style={{ flexGrow: 0, marginTop: isMobile ? '10px' : undefined, marginBottom: isMobile ? '10px' : undefined }}>
                        Aggiungi palestra
                    </GreenButton>
                </div>

                <Divider style={{ margin: '8px 0 12px' }} />
            </div>

            <TableContainer component={Paper} style={{ marginTop: '16px' }}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: '40%' }}>Descrizione</TableCell>
                            <TableCell style={{ width: '40%' }}>Paese</TableCell>
                            <TableCell style={{ width: '20%' }}>Costo</TableCell>
                            <TableCell style={{ width: '84px', whiteSpace: 'nowrap' }}>Azioni</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {facilities.map(facility => {
                            return (
                                <TableRow style={{ height: '45px' }}>
                                    <TableCell>{internalLoading ? <Skeleton variant='text' /> : facility.name}</TableCell>
                                    <TableCell>{internalLoading ? <Skeleton variant='text' /> : facility.town.name}</TableCell>
                                    <TableCell>
                                        {(parseFloat(facility.cost ? facility.cost : 0) % 1 === 0 ? parseFloat(facility.cost ? facility.cost : 0) : parseFloat(facility.cost ? facility.cost : 0).toFixed(2)) + ' €/' + TIME_UNIT_DESC[facility.costTimeUnit as keyof typeof TIME_UNIT_DESC]}
                                    </TableCell>

                                    <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>
                                        {updateInProgress.includes(facility.id) ? (
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <CircularProgress style={{ width: '22px', height: '22px' }} />
                                                </div>
                                            </div>
                                        ) : (
                                            <IconButton onClick={(e: any) => handleMenuClick(e, facility.id)} style={{ padding: '2px 14px' }}>
                                                <MoreVertIcon />
                                            </IconButton>
                                        )}
                                        <Menu
                                            anchorEl={anchor}
                                            keepMounted
                                            open={!!anchor && menuGroupId === facility.id}
                                            onClose={handleClose}
                                        >
                                            {/*
                                            {facility.status === 'waiting_first_login' && (
                                                <MenuItem disabled={updateInProgress.includes(facility.id)} onClick={() => {
                                                    handleClose();
                                                }}>
                                                    Reimposta password
                                                </MenuItem>
                                            )}
                                            */}

                                            <MenuItem disabled={updateInProgress.includes(facility.id)} onClick={() => {
                                                handleClose();
                                                setSelectedTrainerId(facility.id);
                                                setIsAddTrainerDialogOpen(true);
                                            }}>
                                                Modifica informazioni
                                            </MenuItem>

                                            <MenuItem disabled={updateInProgress.includes(facility.id)} onClick={() => {
                                                handleClose();
                                                setSelectedTrainerId(facility.id);
                                                setIsEditWageDialogOpen(true);
                                            }}>
                                                Modifica costi
                                            </MenuItem>

                                            <MenuItem disabled={updateInProgress.includes(facility.id)} onClick={() => {
                                                handleClose();
                                                disableTrainer(facility.id, facility.name + ' - ' + facility.town.name)
                                            }}>
                                                Elimina
                                            </MenuItem>
                                        </Menu>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <StyledTablePagination
                count={paginationTotal}
                page={paginationPage}
                onPageChange={(e: any, page: number) => setPaginationPage(page)}
                rowsPerPage={paginationItemsPerPage}
                onChangeRowsPerPage={(e: any) => setPaginationItemsPerPage(parseInt(e.target.value, 10))}
                // @ts-ignore
                component='div'
                backIconButtonText='Pagina precedente'
                nextIconButtonText='Pagina successiva'
                labelRowsPerPage='Righe'
                labelDisplayedRows={({ from, to, count }) => {
                    return `${from} - ${to} di ${count}`;
                }}
            />

            <AddFacilityDialog
                open={isAddTrainerDialogOpen}
                closeDialog={(shouldRefresh?: boolean) => {
                    setIsAddTrainerDialogOpen(false)

                    if (shouldRefresh) refreshData();
                }}
                selectedTrainerId={selectedTrainerId}
            />

            <EditCostDialog
                open={isEditWageDialogOpen}
                closeDialog={(shouldRefresh?: boolean) => {
                    setIsEditWageDialogOpen(false);

                    if (shouldRefresh) refreshData();
                }}
                selectedTrainer={facilities.find(x => x.id === selectedTrainerId)}
                selectedTrainerId={selectedTrainerId}
            />
        </>
    );
};

export default FacilitiesListPage;
