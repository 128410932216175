import { useCallback, useEffect, useState } from 'react';

import { ButtonProps, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import moment from 'moment';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import api from '../../../../../../common/utils/api';
import snackbar from '../../../../../../common/utils/snackbar';
import GenericObject from '../../../../../../typesAdditional/GenericObject';
import useCompareSessionDate from '../../../registrationPage/hooks/useCompareSessionDate/useCompareSessionDate';
import { StyledTextField } from '../../../../../../common/components/CustomTextField/styled';

interface Props {
    open: boolean;
    onClose: () => void;
    deletedSessions: GenericObject[];
    baseUrl: string;
    allowClose?: boolean;
}

const DeletedSessionsDialog = (props: Props) => {
    const { open, onClose, deletedSessions, baseUrl, allowClose } = props;

    const [isLoading, setIsLoading] = useState(false);

    const [actions, setActions] = useState<GenericObject[]>([]);

    const compareSessionDate = useCompareSessionDate({ sessionType: 'daily_week_events' });

    useEffect(() => {
        if (!open) return;

        setActions([]);
    }, [open])

    const saveData = useCallback(() => {
        setIsLoading(true);
        api.request(baseUrl + '/deleted_sessions', 'POST', { sessions: actions }).then(() => {
            snackbar.success('Modifiche salvate con successo!');

            onClose();
        }).finally(() => {
            setIsLoading(false);
        });
    }, [baseUrl, actions, onClose]);

    const getActionForSession = useCallback((sessionId: string) => {
        const newAction = actions.find((x: any) => x.sessionId === sessionId);

        if (newAction) return newAction.action;

        return deletedSessions.find((x: any) => x.id === sessionId)?.deletedSessionData?.actionType;
    }, [actions, deletedSessions]);

    const setActionForSession = useCallback((sessionId: string, action: string) => {
        setActions(oldActions => {
            return [...oldActions.filter((x: any) => x.sessionId !== sessionId), { sessionId, action }];
        })
    }, []);

    const buttons: ButtonProps[] = [
        {
            children: 'Salva',
            color: 'primary',
            onClick: () => saveData()
        }
    ];

    return (
        <CustomDialogWrapper onClose={allowClose ? onClose : undefined} open={open} title={'Settimane eliminate'} buttons={buttons} isLoading={isLoading} maxWidth='md' contentProps={{ style: { width: '700px' } }} >
            {!allowClose && (
                <Typography variant='body1' style={{ marginBottom: '22px' }}>
                    Sono state eliminate delle settimane con meno di 8 giorni di preavviso. <br />Seleziona la quota da applicare per ciascuna settimana eliminata.
                </Typography>
            )}

            <TableContainer component={Paper}>
                <Table aria-label='simple table'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Settimana</TableCell>
                            <TableCell>Quota da pagare</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {deletedSessions.sort(compareSessionDate).map((session) => {
                            return (
                                <TableRow>
                                    <TableCell>
                                        {moment(session.startTime).format('D MMMM YYYY') + ' - ' + moment(session.endTime).format('D MMMM YYYY')}
                                        <p style={{ color: '#555555', margin: 0, marginTop: '2px' }}>
                                            {session.deletedSessionData.deletedDaysBefore > 0 ? (
                                                <>
                                                    eliminata <b>{session.deletedSessionData.deletedDaysBefore}</b> giorn{session.deletedSessionData.deletedDaysBefore === 1 ? 'o' : 'i'} prima dell'inizio del camp
                                                </>
                                            ) : (
                                                session.deletedSessionData.deletedDaysBefore < 0 ? (
                                                    <>
                                                        eliminata <b>{Math.abs(session.deletedSessionData.deletedDaysBefore)}</b> giorn{session.deletedSessionData.deletedDaysBefore === -1 ? 'o' : 'i'} dopo l'inizio del camp
                                                    </>
                                                ) : (
                                                    <>
                                                        eliminata il giorno d'inizio del camp
                                                    </>
                                                )
                                            )}

                                        </p>
                                    </TableCell>
                                    <TableCell style={{ padding: '2px 16px', width: '280px' }}>
                                        <StyledTextField
                                            label={'Azione'}
                                            variant='outlined'
                                            style={{ marginTop: '10px', marginBottom: '4px' }}
                                            value={getActionForSession(session.id)}
                                            onChange={(e: any) => {
                                                setActionForSession(session.id, e.target.value);
                                            }}
                                            fullWidth
                                            select
                                        >
                                            <MenuItem key={'no_charge'} value={'no_charge'}>
                                                eliminazione completa
                                            </MenuItem>
                                            <MenuItem key={'half_charge'} value={'half_charge'}>
                                                50% della quota
                                            </MenuItem>
                                            <MenuItem key={'full_charge'} value={'full_charge'}>
                                                100% della quota
                                            </MenuItem>
                                        </StyledTextField>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>

                </Table>
            </TableContainer>

        </CustomDialogWrapper>
    );
};

export default DeletedSessionsDialog;
