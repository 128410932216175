import { ButtonProps, Table, TableBody, TableCell, TableContainer, TableRow } from "@material-ui/core";
import CustomDialogWrapper from "../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper";
import { useMemo } from "react";
import moment from "moment";

export const TIME_UNIT_DESC = {
    hourly: 'h',
    weekly: 'settimana',
    monthly: 'mese',
    yearly: 'anno (solare)',
    yearly_sport_calendar: 'anno (sportivo)'
}

interface Props {
    open: boolean;
    closeDialog: () => void;
    selectedTrainer?: any;
    selectedWage?: any;
    data: any;
}

const WageChangelogDialog = (props: Props) => {
    const { open, closeDialog, selectedTrainer, selectedWage, data } = props;

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        }
    ];

    const wages = useMemo(() => {
        if (!selectedWage) return [];

        const allWages = [...(data?.default?.current ?? []), ...(data?.default?.previous ?? []), ...(data?.custom?.current ?? []), ...(data?.custom?.previous ?? [])];

        const filtered = allWages.filter((w: any) => {
            return w.course?.id === selectedWage.course?.id && w.courseLocation?.id === selectedWage.courseLocation?.id
        })

        return [...filtered].reverse();
    }, [data, selectedWage]);

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Costi ' + selectedTrainer?.name + ' - ' + selectedTrainer?.town?.name} buttons={buttons} maxWidth='sm' fullWidth>
            <TableContainer style={{ marginTop: '0px' }}>
                <Table size='small'>
                    <TableBody>
                        {wages.map((w: any, idx: number) => {
                            return (
                                <TableRow>
                                    <TableCell>
                                        {w.startDate === '1970-01-01' ? 'dall\'inizio' : ('dal ' + moment(w.startDate).format('DD/MM/YYYY'))}{' '}
                                        {wages[idx + 1] ? ('al ' + moment(wages[idx + 1].startDate).subtract(1, 'day').format('DD/MM/YYYY')) : 'a oggi'}
                                    </TableCell>
                                    <TableCell style={{ width: '1px', paddingRight: 0, textAlign: 'right' }}>
                                        {parseFloat(w.cost) % 1 === 0 ? parseFloat(w.cost) : parseFloat(w.cost).toFixed(2)}
                                    </TableCell>
                                    <TableCell style={{ width: '1px', paddingLeft: '6px', whiteSpace: 'nowrap' }}>
                                        €/{TIME_UNIT_DESC[w.costTimeUnit as keyof typeof TIME_UNIT_DESC]}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </CustomDialogWrapper>
    )
}

export default WageChangelogDialog;