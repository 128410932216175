import { ButtonProps } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import CustomDialogWrapper from "../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper";
import moment from "moment";
import 'moment/locale/it';
import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import CustomTextField from "../../../../../../common/components/CustomTextField/CustomTextField";
import api from "../../../../../../common/utils/api";
import snackbar from "../../../../../../common/utils/snackbar";

interface Props {
    open: boolean;
    closeDialog: (shouldRefresh?: boolean) => void;
    selectedTrainerId?: string;
}

const AddOtherAsdPayoutDialog = (props: Props) => {
    const { open, closeDialog, selectedTrainerId} = props;

    const [isLoading, setIsLoading] = useState(false);

    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

    const [amount, setAmount] = useState<any>(0.0);
    const [asdName, setAsdName] = useState('');
    const [date, setDate] = useState(new Date().toISOString().split('T')[0]);

    useEffect(() => {
        if (!open) return;

        setAmount(0.0);
        setAsdName('');
        setDate(new Date().toISOString().split('T')[0])
    }, [open]);

    const saveData = useCallback(() => {
        setIsLoading(true);

        api.request('/admin/other_asd_payouts/' + selectedTrainerId, 'POST', { amount, asdName, date }).then((res: any) => {
            snackbar.success('Compenso aggiunto con successo!');
            closeDialog(true);
        }).finally(() => {
            setIsLoading(false);
        })
    }, [closeDialog, selectedTrainerId, amount, asdName, date]);

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        },
        {
            children: 'Salva',
            color: 'primary',
            onClick: () => saveData()
        },
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Nuovo compenso altra ASD'} buttons={buttons} isLoading={isLoading} maxWidth='sm' fullWidth>
            <MuiPickersUtilsProvider locale='it' utils={MomentUtils}>
                <KeyboardDatePicker
                    label='Data'
                    inputVariant='outlined'
                    minDate={'2024-01-01'}
                    maxDate={moment(Date.now()).format('YYYY-MM-DD')}
                    format='DD/MM/YYYY'
                    value={date}
                    onChange={(date: MaterialUiPickersDate) => {
                        setDate(date?.format('YYYY-MM-DD') ?? '');
                    }}
                    open={isDatePickerOpen}
                    onClick={() => {
                        setIsDatePickerOpen(true);
                    }}
                    onClose={() => setIsDatePickerOpen(false)}
                    onKeyDown={(e) => {
                        e.preventDefault();
                        return false;
                    }}
                    style={{ flex: 1, marginBottom: '4px' }}
                    inputProps={{ tabIndex: -1 }}
                    KeyboardButtonProps={{ tabIndex: -1 }}
                    disabled={isLoading}
                    fullWidth
                />
            </MuiPickersUtilsProvider>

            <CustomTextField
                label='Importo'
                variant='outlined'
                value={amount}
                keepMounted={true}
                style={{ marginTop: '8px' }}
                onChange={(e: any) => {
                    setAmount(e.target.value);
                }}
                onBlur={() => {
                    setAmount((c: any) => {
                        return Math.max(((typeof c === 'string') ? parseFloat(c.replaceAll(',', '.')) : c), 0).toFixed(2);
                    });
                }}
                disabled={isLoading}
            />

            <CustomTextField
                label={'Nome ASD'}
                variant='outlined'
                type='email'
                value={asdName}
                onChange={(e) => setAsdName(e.target.value)}
                disabled={isLoading}
                keepMounted
                fullWidth
            />
        </CustomDialogWrapper>
    )
}

export default AddOtherAsdPayoutDialog; 