import { useCallback, useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';

import useInternalLoader from '../../../../common/hooks/useInternalLoader/useExternalLoader';
import api from '../../../../common/utils/api';
import GenericObject from '../../../../typesAdditional/GenericObject';
import Table from './components/Table/Table';
import { Title } from './styled';

const RegistrationsListPage = () => {
    const setIsLoading = useInternalLoader();
    const { width } = useWindowSize();

    const [registrations, setRegistrations] = useState<GenericObject[]>([]);

    const refreshRegistrations = useCallback(() => {
        setIsLoading(true);
        api.request('/registrations').then((res: any) => {
            setRegistrations(res);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [setIsLoading]);

    useEffect(() => {
        refreshRegistrations();
    }, [refreshRegistrations, setIsLoading]);

    const isMobile = width <= 700;

    return (
        <>
            <Title isMobile={isMobile}>
                Le mie iscrizioni
            </Title>

            <Table registrations={registrations} refreshRegistrations={refreshRegistrations} />
        </>
    );
};

export default RegistrationsListPage;
