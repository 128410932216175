import { useEffect, useState } from 'react';

import CustomTextField from '../../../../../../../../common/components/CustomTextField/CustomTextField';
import FlexInputContainer from '../../../../../../../../common/components/FlexInputContainer/FlexInputContainer';
import api from '../../../../../../../../common/utils/api';
import { SectionProps as Props } from '../../GenericPersonForm';
import TownAutoComplete from './components/TownAutocomplete/TownAutocomplete';
import ZipAutocomplete from './components/ZipAutocomplete/ZipAutocomplete';

const Address = (props: Props) => {
    const { formPrefix, data, dataOnChange, rawOnChange } = props;

    const [townZips, setTownZips] = useState([]);

    const townLegalId = data[formPrefix]?.address?.town_legal_id;

    useEffect(() => {
        setTownZips([]);

        if (townLegalId) {
            api.request('/towns/' + townLegalId).then(res => {
                setTownZips(res?.zips ?? []);
            });
        }
    }, [townLegalId]);

    return (
        <div style={{marginTop: '16px'}}>
            <FlexInputContainer>
                <CustomTextField
                    label='Indirizzo di residenza'
                    path={formPrefix + '.address.street'}
                    variant='outlined'
                    data={data}
                    dataOnChange={dataOnChange}
                    style={{flex: 3}}
                />
                <CustomTextField
                    label='Numero civico'
                    path={formPrefix + '.address.street_number'}
                    variant='outlined'
                    data={data}
                    dataOnChange={dataOnChange}
                    style={{flex: 1}}
                />
            </FlexInputContainer>

            <FlexInputContainer>
                <TownAutoComplete data={data} formPrefix={formPrefix} rawOnChange={rawOnChange} />
                <ZipAutocomplete
                    data={data}
                    formPrefix={formPrefix}
                    isLoading={townZips.length === 0 && townLegalId}
                    rawOnChange={rawOnChange}
                    zips={townZips}
                    townLegalId={townLegalId}
                />
            </FlexInputContainer>
        </div>
    );
};

export default Address;
