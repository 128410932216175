import { useCallback, useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';

import { ButtonProps, Link } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import { useMessageDialog } from '../../../../../../common/hooks/useMessageDialog/useMessageDialog';
import api from '../../../../../../common/utils/api';
import snackbar from '../../../../../../common/utils/snackbar';
import CardSessionsSelector from '../../../registrationPage/steps/RegistrationStep6/components/CardsSessionsSelector/CardSessionsSelector';
import TableSessionsSelector from '../../../registrationPage/steps/RegistrationStep6/components/TableSessionsSelector/TableSessionsSelector';
import { SessionsSelectorProps } from '../../../registrationPage/steps/RegistrationStep6/types';
import SpecialDietsV2Selector from '../../../registrationPage/steps/RegistrationStep6/components/SpecialDietsV2Selector/SpecialDietsV2Selector';
import GenericObject from '../../../../../../typesAdditional/GenericObject';
import DeletedSessionsDialog from '../DeletedSessionsDialog/DeletedSessionsDialog';

interface Props extends SessionsSelectorProps {
    isOpen: boolean;
    onClose: (shouldRefresh?: boolean, deleted?: boolean, currentDeletedSessions?: GenericObject[]) => void;
    baseUrl: string;
    selectedSessions: any;
    isAdmin?: boolean;
    pricesNotAvailable?: boolean;
    singleSessionEvent?: boolean;
    specialDiets: any[];
    setSpecialDiets: (diets: any[]) => void;
    showSpecialDiets: boolean;
    isSummer?: boolean;
}

const ChangeSessionsDialog = (props: Props) => {
    const { isOpen, onClose, baseUrl, selectedSessions, isAdmin, sessionType, hasFoodService, simulateRegistrationData, isSelected, isFoodSelected, handleSelect, handleFoodSelect, pricesNotAvailable, singleSessionEvent, specialDiets, setSpecialDiets, showSpecialDiets, isSummer } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [isUntouched, setIsUntouched] = useState(true);

    const [isDeletedSessionsDialogOpen, setIsDeletedSessionsDialogOpen] = useState(false);
    const [deletedSessions, setDeletedSessions] = useState<GenericObject[]>([]);

    const [showMessageDialog, closeMessageDialog] = useMessageDialog();

    const { width } = useWindowSize();

    const internalHandleSelect = (sessionId: string, checked: boolean) => {
        setIsUntouched(false);
        handleSelect(sessionId, checked);
    };

    const internalHandleFoodSelect = (sessionId: string, checked: boolean) => {
        setIsUntouched(false);
        handleFoodSelect(sessionId, checked);
    };

    const saveAndClose = useCallback(() => {
        if (isUntouched) {
            snackbar.success('Modifiche salvate con successo!');
            onClose();
            return;
        }

        setIsLoading(true);
        api.request(baseUrl + '/change_sessions', 'POST', { sessions: selectedSessions, specialDiets }).then((res: any) => {
            snackbar.success('Modifiche salvate con successo!');

            if (res.deletedSessions.length > 0 && !(res?.deleted ?? false)) {
                setIsDeletedSessionsDialogOpen(true);
                setDeletedSessions(res.deletedSessions);
            }

            onClose(true, res?.deleted ?? false, res?.deleted ? res.deletedSessions : undefined);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [specialDiets, onClose, isUntouched, baseUrl, selectedSessions]);

    const internalOnClose = useCallback(() => {
        if (!isUntouched) {
            showMessageDialog({
                title: 'Ci sono delle modifiche in sospeso',
                message: 'Vuoi salvare le modifiche effettuate?',
                actions: [
                    {
                        text: 'No',
                        action: () => {
                            closeMessageDialog();
                            onClose();
                        }
                    },
                    {
                        text: 'Sì',
                        action: () => {
                            closeMessageDialog();
                            saveAndClose();
                        }
                    }
                ]
            });
        } else {
            onClose();
        }
    }, [closeMessageDialog, saveAndClose, showMessageDialog, isUntouched, onClose]);

    useEffect(() => {
        if (!isOpen) setIsUntouched(true);
    }, [isOpen]);

    const buttons: ButtonProps[] = [
        {
            children: 'Salva',
            color: 'primary',
            onClick: () => saveAndClose()
        },
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => onClose()
        }
    ];

    const isMobile = width < 700;

    return (
        <>
            <CustomDialogWrapper fullScreen={isMobile} open={isOpen} onClose={internalOnClose} title={'Modifica iscrizione'} buttons={buttons} isLoading={isLoading} maxWidth='md' fullWidth contentProps={{}} >
                {!isAdmin && (
                    <Alert severity='info' style={{ paddingBottom: '0px', marginBottom: '12px' }}>
                        <p style={{ marginTop: '0px', marginBottom: '10px' }}>
                            Per modificare i dati anagrafici, scrivi a <Link href='mailto:info@epuntos.it' target='_blank' rel='noreferrer'>info@epuntos.it</Link>.
                        </p>
                    </Alert>
                )}

                {width > 700 ? (
                    <TableSessionsSelector
                        sessionType={sessionType}
                        hasFoodService={hasFoodService}
                        simulateRegistrationData={simulateRegistrationData}
                        isSelected={isSelected}
                        isFoodSelected={isFoodSelected}
                        handleSelect={internalHandleSelect}
                        handleFoodSelect={internalHandleFoodSelect}
                        isAdmin={isAdmin}
                        pricesNotAvailable={pricesNotAvailable}
                        singleSessionEvent={singleSessionEvent}
                        isSummer={isSummer}
                        userCanChooseSessions
                    />
                ) : (
                    <CardSessionsSelector
                        sessionType={sessionType}
                        hasFoodService={hasFoodService}
                        simulateRegistrationData={simulateRegistrationData}
                        isSelected={isSelected}
                        isFoodSelected={isFoodSelected}
                        handleSelect={internalHandleSelect}
                        handleFoodSelect={internalHandleFoodSelect}
                        isAdmin={isAdmin}
                        pricesNotAvailable={pricesNotAvailable}
                        singleSessionEvent={singleSessionEvent}
                        isSummer={isSummer}
                        userCanChooseSessions
                    />
                )}

                {showSpecialDiets && isAdmin && false && (
                    <div style={{ marginTop: '22px' }}>
                        <SpecialDietsV2Selector
                            selectedSpecialDiets={specialDiets}
                            rawOnChange={(field, value) => {
                                setSpecialDiets(value)
                                setIsUntouched(false);
                            }}
                            disabled={false}
                        />
                    </div>
                )}

            </CustomDialogWrapper>

            <DeletedSessionsDialog
                open={isDeletedSessionsDialogOpen}
                onClose={() => {
                    setIsDeletedSessionsDialogOpen(false)
                    onClose(true);
                }}
                deletedSessions={deletedSessions}
                baseUrl={baseUrl}
            />
        </>
    );
};

export default ChangeSessionsDialog;
