import useLoader from '../useLoader/useLoader';

const useInternalLoader = (initialIsLoading?: boolean) => {
    return useLoader({
        initialIsLoading,
        type: 'internal'
    });
};

export default useInternalLoader;
