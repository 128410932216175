import Button from '@material-ui/core/Button';
import styled from 'styled-components/macro';

import useOpenDialog from '../../../../../../../../common/hooks/useOpenDialog/useOpenDialog';
import { Container } from '../../styled';

const FallbackLoginButton = () => {
    const openLoginDialog = useOpenDialog('loginDialog');

    return (
        <Container>
            <StyledButton variant='outlined' color='primary' onClick={openLoginDialog}>
                Effettua l'accesso
            </StyledButton>
        </Container>
    );
};

const StyledButton = styled(Button)`
    width: 100%;
    margin: 4px 0;
`;

export default FallbackLoginButton;
