import { useCallback, useEffect, useState } from 'react';

import { ButtonProps } from '@material-ui/core/Button';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import useGenericErrorDialog from '../../../../../../common/hooks/useGenericErrorDialog/useGenericErrorDialog';
import { useMessageDialog } from '../../../../../../common/hooks/useMessageDialog/useMessageDialog';
import api from '../../../../../../common/utils/api';
import snackbar from '../../../../../../common/utils/snackbar';
import GenericObject from '../../../../../../typesAdditional/GenericObject';
import DocumentCard from '../../../registrationPage/steps/RegistrationStep4/components/DocumentCard/DocumentCard';
import { Document } from '../../../registrationPage/steps/RegistrationStep4/types';
import MissingDocumentsAlert from './components/MissingDocumentsAlert/MissingDocumentsAlert';
import NoMissingDocumentsAlert from './components/NoMissingDocumentsAlert/NoMissingDocumentsAlert';

interface Props {
    open: boolean;
    closeDialog: () => void;
    selectedRow: GenericObject;
    isAdmin?: boolean;
    refreshRegistrations?: () => void;
}

const DocumentsDetailDialog = (props: Props) => {
    const { open, closeDialog, selectedRow, isAdmin, refreshRegistrations } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [hasUnsavedData, setHasUnsavedData] = useState(false);

    const [documentAssets, setDocumentAssets] = useState<GenericObject>({});

    const [showMessageDialog, closeMessageDialog] = useMessageDialog();
    const showGenericErrorDialog = useGenericErrorDialog();

    const handleAssetChange = useCallback((documentTypeId: string, assets: string[]) => {
        setDocumentAssets(currentState => {
            return {
                ...currentState,
                [documentTypeId]: assets
            };
        });
    }, []);

    useEffect(() => {
        if (open) {
            setHasUnsavedData(false);
            setDocumentAssets({});
        }
    }, [open]);

    const saveDocuments = useCallback(() => {
        const documentsToSave = Object.entries(documentAssets).map(([key, value]) => {
            return {
                documentTypeId: key,
                assets: value
            };
        }).filter(document => document.assets.length > 0);

        api.request((isAdmin ? '/admin' : '') + '/documents/' + (selectedRow?.person?.id ?? ''), 'POST', { documents: documentsToSave }).then(() => {
            snackbar.success('Documenti salvati!');
            closeDialog();
            if (refreshRegistrations) {
                refreshRegistrations();
            }
        }).catch((res) => {
            showGenericErrorDialog(res);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [isAdmin, closeDialog, documentAssets, selectedRow, showGenericErrorDialog, refreshRegistrations]);

    const documents = selectedRow?.documents ?? [];
    const countMissing = documents.filter((document: any) => document.status === 'expiring' || document.status === 'missing').length;

    const personName = selectedRow?.person?.name + ' ' + selectedRow?.person?.surname;

    const buttons: ButtonProps[] = [(isAdmin && false) ? {
        children: 'Chiudi',
        color: 'primary',
        onClick: () => closeDialog()
    } : {
        children: 'Salva',
        color: 'primary',
        onClick: () => saveDocuments()
    }];

    const isUploading = Object.entries(documentAssets).some((d: any) => d[1].some((a: any) => a.status === 'uploading'));

    useEffect(() => {
        if (isUploading) {
            setHasUnsavedData(true);
        }
    }, [isUploading]);

    const internalOnClose = useCallback(() => {
        if (hasUnsavedData) {
            showMessageDialog({
                title: 'Sono presenti dati non salvati',
                message: (
                    <>
                        <p style={{marginTop: '0px'}}>
                            Vuoi salvare le modifiche effettuate?
                        </p>
                    </>
                ),
                actions: [
                    {
                        text: 'Annulla',
                        action: () => {
                            closeDialog();
                            closeMessageDialog();
                        }
                    },
                    {
                        text: 'Salva',
                        action: () => {
                            closeMessageDialog();
                            saveDocuments();
                        }
                    }
                ]
            });
        } else {
            closeDialog();
        }
    }, [closeMessageDialog, saveDocuments, showMessageDialog, closeDialog, hasUnsavedData]);

    return (
        <CustomDialogWrapper open={open} onClose={internalOnClose} title={'Documenti di ' + personName} buttons={buttons} isLoading={isLoading || isUploading} maxWidth='md' fullWidth>
            {!isAdmin && (
                <>
                    {countMissing === 0 ? (
                        <NoMissingDocumentsAlert count={selectedRow?.documents?.length ?? 0} />
                    ) : (
                        <MissingDocumentsAlert count={selectedRow?.documents?.length ?? 0} />
                    )}
                </>
            )}

            <div>
                {selectedRow?.documents?.map((document: Document) => {
                    return <DocumentCard document={document} handleAssetChange={handleAssetChange} isAdmin={isAdmin} closeDialog={closeDialog} refreshRegistrations={refreshRegistrations} />;
                })}
            </div>
        </CustomDialogWrapper>
    );
};


export default DocumentsDetailDialog;
