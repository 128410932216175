import { createSlice } from '@reduxjs/toolkit';

import initialState from './initialState';

export const loadersSlice = createSlice({
    name: 'loadersSlice',
    initialState,
    reducers: {
        addExternalLoader: (state, action) => {
            const externalLoaders = [...state?.externalLoaders, action.payload];

            return {
                ...state,
                externalLoaders
            };
        },
        removeExternalLoader: (state, action) => {
            const externalLoaders = state.externalLoaders.filter(id => id !== action.payload);

            return {
                ...state,
                externalLoaders
            };
        },
        addInternalLoader: (state, action) => {
            const internalLoaders = [...state?.internalLoaders, action.payload];

            return {
                ...state,
                internalLoaders
            };
        },
        removeInternalLoader: (state, action) => {
            const internalLoaders = state.internalLoaders.filter(id => id !== action.payload);

            return {
                ...state,
                internalLoaders
            };
        },
    }
});

export const { addExternalLoader, removeExternalLoader, addInternalLoader, removeInternalLoader } = loadersSlice.actions;

export default loadersSlice.reducer;
