import { useCallback, useEffect, useState } from 'react';

import { ButtonProps } from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components/macro';

import CustomDialogWrapper from '../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import useCloseDialog from '../../../../common/hooks/useCloseDialog/useCloseDialog';
import useGenericErrorDialog from '../../../../common/hooks/useGenericErrorDialog/useGenericErrorDialog';
import { useMessageDialog } from '../../../../common/hooks/useMessageDialog/useMessageDialog';
import useSubmitOnEnter from '../../../../common/hooks/useSubmitOnEnter/useSubmitOnEnter';
import api from '../../../../common/utils/api';

interface Props {
    open: boolean;
}

const LoginDialog = ({ open }: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');

    const closeDialog = useCloseDialog('loginDialog');
    const [showMessageDialog] = useMessageDialog();
    const showGenericErrorDialog = useGenericErrorDialog();

    useEffect(() => {
        if (open) {
            setEmail('');
        }
    }, [open]);

    const onEmailChange = ((event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    });

    const onSubmit = useCallback(() => {
        if (isLoading) return;

        setIsLoading(true);

        api.userLogin(email).then((status: string) => {
            if (status === 'email_magic_link') {
                showMessageDialog({
                    title: 'Completa l\'accesso',
                    message: 'Ti abbiamo inviato un messaggio all\'indirizzo email specificato. Clicca sul link che hai ricevuto per completare l\'accesso.'
                });
            } else {
                showGenericErrorDialog({ status });
            }
            closeDialog();
        }).finally(() => {
            setIsLoading(false);
        });
    }, [showGenericErrorDialog, closeDialog, email, isLoading, showMessageDialog]);

    const handleKeyDown = useSubmitOnEnter(onSubmit);

    const buttons: ButtonProps[] = [{
        children: 'Accedi',
        color: 'primary',
        onClick: onSubmit
    }];

    return (
        <CustomDialogWrapper open={open} onClose={closeDialog} title='Accedi a Educazione.Sport' buttons={buttons} isLoading={isLoading} maxWidth='sm' fullWidth>
            <StyledP>
                Accedi per iscriverti ai corsi, visualizzare le tue iscrizioni e pagamenti e gestire le impostazioni
                per la ricezione della newsletter.
            </StyledP>
            <StyledP>
                Inserisci il tuo indirizzo email. Se stai accedendo per la prima volta, verrà automaticamente creato un nuovo account.
            </StyledP>
            <StyledTextField variant='outlined' label='Indirizzo email' value={email} onChange={onEmailChange} onKeyDown={handleKeyDown} autoFocus fullWidth />
        </CustomDialogWrapper>
    );
};

const StyledP = styled.p`
    margin-top: 0;
`;

const StyledTextField = styled(TextField)`
    margin: 8px 0;
`;

export default LoginDialog;
