import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';

import GenericObject from '../../../../../../../../typesAdditional/GenericObject';

interface Props {
    discounts: GenericObject;
    data: GenericObject;
    rawOnChange: (field: string, value: any) => void;
}

const DiscountSelector = (props: Props) => {
    const { discounts, data, rawOnChange } = props;

    const selectedDiscount = data?.discount_id ?? '';

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value;
        rawOnChange('discount_id', value);
    };

    const customDiscounts = discounts?.list?.filter((discount: GenericObject) => {
        return discount.conditionType === 'custom';
    }) ?? [];

    if (customDiscounts.length === 0) {
        return null;
    }

    return (
        <div style={{ marginBottom: '20px' }}>
            {customDiscounts.length === 1 && customDiscounts[0].id === '18335161-c387-4785-8630-ae58ab7ba99b' ? (
                <>
                    <Typography variant='h4' style={{ fontSize: '1.4em', textAlign: 'center', margin: '8px', marginBottom: '10px' }}>
                        Il partecipante al Camp è un alunno/a dell'Istituto Scolastico Vest di Clusone?
                    </Typography>

                    <FormControl component='fieldset'>
                        <RadioGroup aria-label='gender' name='gender1' value={selectedDiscount} onChange={handleChange}>
                            {customDiscounts.map((discount: any) => {
                                return (
                                    <FormControlLabel value={discount.id} control={<Radio color='primary' />} label={'Sì'} />
                                );
                            })}
                            <FormControlLabel value='' control={<Radio color='primary' />} label='No' />
                        </RadioGroup>
                    </FormControl>
                </>
            ) : (
                <>
                    <Typography variant='h4' style={{ fontSize: '1.4em', textAlign: 'center', margin: '8px', marginBottom: '10px' }}>
                        Se vuoi usufruire di una convenzione, selezionala qui
                    </Typography>
                    <FormControl component='fieldset'>
                        <RadioGroup aria-label='gender' name='gender1' value={selectedDiscount} onChange={handleChange}>
                            <FormControlLabel value='' control={<Radio color='primary' />} label='Nessuna convenzione' />
                            {customDiscounts.map((discount: any) => {
                                return (
                                    <FormControlLabel value={discount.id} control={<Radio color='primary' />} label={discount.description} />
                                );
                            })}
                        </RadioGroup>
                    </FormControl>
                </>
            )}

        </div>
    );
};

export default DiscountSelector;
