import { TableCell } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { useEffect, useState } from "react";
import styled from "styled-components";

interface Props {
    description: string;
}

const StyledTooltip = styled(props => (
    <Tooltip classes={{ popper: props.className }} {...props} />
))`
    & .MuiTooltip-tooltip {
        background-color: #444444;
        font-size: 1em; 
        font-weight: normal;
    }

    & .MuiTooltip-arrow {
        color: #444444;
    }
  `;

const DynamicTableTooltip = ({ description }: Props) => {
    const [shouldShowTooltip, setShouldShowTooltip] = useState(false);

    const [ref, setRef] = useState<HTMLParagraphElement | null>();

    useEffect(() => {
        if (!ref) return;

        const element = ref;
        setShouldShowTooltip(element.offsetWidth < element.scrollWidth);
    }, [ref]);

    const content = (
        <TableCell
            key={description}
            ref={(newRef: HTMLParagraphElement) => setRef(newRef)}
            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '500px' }}
        >
            {description}
        </TableCell>
    );

    if (shouldShowTooltip) {
        return (
            <StyledTooltip title={description} arrow >
                {content}
            </StyledTooltip>
        )
    } else {
        return content;
    }


}

export default DynamicTableTooltip;