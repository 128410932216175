import { useCallback, useEffect, useMemo, useState } from 'react';
import { useWindowSize } from 'react-use';

import { Accordion, AccordionDetails, AccordionSummary, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { ButtonProps } from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import moment from 'moment';
import sanitizeHtml from 'sanitize-html';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import { useMessageDialog } from '../../../../../../common/hooks/useMessageDialog/useMessageDialog';
import api from '../../../../../../common/utils/api';
import snackbar from '../../../../../../common/utils/snackbar';
import useCompareSessionDate from '../../../registrationPage/hooks/useCompareSessionDate/useCompareSessionDate';
import useHandleSelect from '../../../registrationPage/steps/RegistrationStep6/hooks/useHandleSelect';
import ChangeSessionsDialog from '../ChangeSessionsDialog/ChangeSessionsDialog';
import EditDataDialog from '../EditDataDialog/EditDataDialog';
import detailsMap from './detailsMap';
import ChangeDietDialog from '../ChangeDietDialog/ChangeDietDialog';
import ChangePhotoConsentDialog from '../ChangePhotoConsentDialog/ChangePhotoConsentDialog';
import { ReduxState } from '../../../../../../redux/types';
import { useSelector } from 'react-redux';
import DeletedSessionsDialog from '../DeletedSessionsDialog/DeletedSessionsDialog';
import GenericObject from '../../../../../../typesAdditional/GenericObject';
import { StyledNotesTableCell } from './styled';
import RegistrationNotesDialog from '../../../../../admin/pages/registrationLocationPage/components/RegistrationNotesDialog/RegistrationNotesDialog';

const DELETED_ACTION_COEFF = {
    no_charge: 0,
    half_charge: 0.5,
    full_charge: 1.0
}

interface Props {
    isOpen: boolean;
    onClose: () => void;
    selectedRegistrationId?: string;
    baseUrl: string;
    refreshRegistrations: () => void;
    isAdmin?: boolean;
}

const DetailsDialog = (props: Props) => {
    const { isOpen, onClose, selectedRegistrationId, baseUrl, refreshRegistrations, isAdmin } = props;

    const userInfo = useSelector((state: ReduxState) => state.user);
    const isSuperAdmin = isAdmin && !!userInfo.administrator;

    const [isLoading, setIsLoading] = useState(false);
    const [isChangeSessionsDialogOpen, setIsChangeSessionsDialogOpen] = useState(false);
    const [isChangeDietDialogOpen, setIsChangeDietDialogOpen] = useState(false);
    const [isChangePhotoConsentDialogOpen, setIsChangePhotoConsentDialogOpen] = useState(false);
    const [editDialogSection, setEditDialogSection] = useState<string | null>(null);

    const [isDeletedSessionsDialogOpen, setIsDeletedSessionsDialogOpen] = useState(false);
    const [isNotesDialogOpen, setIsNotesDialogOpen] = useState(false);
    const [deletedSessions, setDeletedSessions] = useState<GenericObject[]>([]);

    const [accordion0Open, setAccordion0Open] = useState(true);
    const [accordion1Open, setAccordion1Open] = useState(true);
    const [accordion2Open, setAccordion2Open] = useState(true);

    const [selectedRegistration, setSelectedRegistration] = useState<any>({});

    const [specialDiets, setSpecialDiets] = useState<any[]>([]);

    const [showMessageDialog, closeMessageDialog] = useMessageDialog();

    useEffect(() => {
        if (!isOpen) return;

        setAccordion0Open(true);
        setAccordion1Open(true);
        setAccordion2Open(true);
        setSelectedRegistration({});
    }, [isOpen]);

    const refreshRegistration = useCallback(() => {
        if (!selectedRegistrationId || !isOpen) return;

        setIsLoading(true);
        api.request(baseUrl + '/' + selectedRegistrationId).then(res => {
            setSelectedRegistration(res);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [baseUrl, isOpen, selectedRegistrationId]);

    useEffect(() => {
        refreshRegistration();
    }, [refreshRegistration]);

    useEffect(() => {
        setSelectedSessions(selectedRegistration?.wrapperDetails?.sessions ?? []);
        setSpecialDiets(selectedRegistration?.specialDietsV2 ?? []);
    }, [isChangeSessionsDialogOpen, selectedRegistration]);

    const { width } = useWindowSize();

    const isMobile = width < 700;

    const getTransformedAttribute = (section: any, attribute: any) => {
        const transformType = attribute.transform ?? null;
        const value = _.get(selectedRegistration, section.keyPrefix + '.' + attribute.key);

        if (transformType === 'date' && value) {
            return moment(value).format('D MMMM YYYY');
        }

        if (transformType === 'boolean') {
            return value ? 'sì' : 'no';
        }

        return value;
    };

    const sessionType = selectedRegistration?.course?.sessionType;

    const hasFoodService = selectedRegistration?.wrapperDetails?.details?.sessions?.some((location: any) => {
        return location.hasFoodService;
    });

    const sessions = selectedRegistration?.wrapperDetails?.details?.sessions ?? [];
    const savedDeletedSessions = selectedRegistration?.deletedSessions ?? [];

    const [selectedSessions, setSelectedSessions] = useState<any[]>([]);
    const { handleSelect, handleFoodSelect } = useHandleSelect({ selectedSessions, setSelectedSessions, sessions });

    const compareSessionDate = useCompareSessionDate({ sessionType });

    const singleSessionEvent = (sessionType === 'single_event' && (sessions ?? []).length === 1);

    const pricesNotAvailable = selectedRegistration?.wrapperDetails?.details?.sessions?.some((session: any) => {
        return session?.prices?.course?.actual?.price <= 0;
    }) && selectedRegistration?.course?.id !== '7030abec-7234-4939-a154-336743ed9f92';;

    const isSelected = (sessionId: string) => {
        return !!selectedSessions.find(s => s.id === sessionId);
    };

    const isFoodSelected = (sessionId: string) => {
        const curr = selectedSessions.find(s => s.id === sessionId);

        if (!curr) return false;

        return curr.foodService;
    };

    const onChangeSessionsDialogClose = useCallback((shouldRefresh?: boolean, deleted?: boolean, currentDeletedSessions?: GenericObject[]) => {
        setIsChangeSessionsDialogOpen(false);
        if (shouldRefresh) {
            if (deleted) {
                onClose();

                if (currentDeletedSessions) {
                    setIsDeletedSessionsDialogOpen(true);
                    setDeletedSessions(currentDeletedSessions);
                }
            } else {
                refreshRegistration();
            }
            refreshRegistrations();
        }
    }, [onClose, refreshRegistration, refreshRegistrations]);

    const onChangeDietDialogClose = useCallback((shouldRefresh?: boolean, deleted?: boolean) => {
        setIsChangeDietDialogOpen(false);
        if (shouldRefresh) {
            if (deleted) {
                onClose();
            } else {
                refreshRegistration();
            }
            refreshRegistrations();
        }
    }, [onClose, refreshRegistration, refreshRegistrations]);

    const onChangePhotoConsentDialogClose = useCallback((shouldRefresh?: boolean) => {
        setIsChangePhotoConsentDialogOpen(false);
        if (shouldRefresh) {
            refreshRegistration();
            refreshRegistrations();
        }
    }, [refreshRegistration, refreshRegistrations]);

    const onDataChange = useCallback(() => {
        refreshRegistration();
        refreshRegistrations();
    }, [refreshRegistration, refreshRegistrations]);

    const deleteRegistration = useCallback(() => {
        setIsLoading(true);
        api.request(baseUrl + '/' + selectedRegistrationId + '/change_sessions', 'POST', { sessions: [], force_delete: true }).then((res) => {
            snackbar.success('Iscrizione eliminata con successo!');

            if (res.deletedSessions.length > 0) {
                setIsDeletedSessionsDialogOpen(true);
                setDeletedSessions(res.deletedSessions);
            }

            onClose();
            refreshRegistrations();
        }).finally(() => {
            setIsLoading(false);
        });
    }, [baseUrl, onClose, refreshRegistrations, selectedRegistrationId]);

    const sendConfirmationEmail = useCallback(() => {
        api.request(baseUrl + '/' + selectedRegistrationId + '/send_confirmation_email', 'POST').then(() => {
            snackbar.success('Email di conferma inviata con successo!');
        });
    }, [baseUrl, selectedRegistrationId]);

    const userEmails = useMemo(() => {
        const emails = [];

        if (selectedRegistration?.user?.email) {
            emails.push(selectedRegistration.user.email);
        }

        if (selectedRegistration?.secondParentUser?.email) {
            emails.push(selectedRegistration.secondParentUser.email);
        }

        return emails;
    }, [selectedRegistration]);

    const buttons: ButtonProps[] = [
        {
            children: 'Elimina',
            disabled: !selectedRegistration?.canUserDelete && !isAdmin,
            color: 'secondary',
            onClick: () => showMessageDialog({
                title: 'Elimina iscrizione',
                message: 'Sei sicuro di volere eliminare l\'iscrizione selezionata?',
                actions: [
                    {
                        text: 'No',
                        action: () => {
                            closeMessageDialog();
                        }
                    },
                    {
                        text: 'Sì',
                        action: () => {
                            deleteRegistration();
                            closeMessageDialog();
                        }
                    }
                ]
            })
        },
        {
            children: 'Modifica',
            color: 'primary',
            onClick: () => setIsChangeSessionsDialogOpen(true),
            disabled: (selectedRegistration?.course?.id ?? '') === '42990ea4-76a0-11ef-aac5-4865ee14ab2a'
        },
        ...(isAdmin ? [{
            children: 'Invia email di conferma',
            color: 'primary',
            onClick: () => showMessageDialog({
                title: 'Invia email di conferma',
                message: (
                    <>
                        <p style={{ marginTop: 0 }}>Sei sicuro di volere re-inviare l'email di conferma {userEmails.length > 1 ? 'ai genitori' : 'al genitore'}?</p>
                        <p>Verrà inviata {userEmails.length > 1 ? 'ai seguenti indirizzi' : 'al seguente indirizzo'} email:</p>
                        <ul>
                            {userEmails.map(m => {
                                return (
                                    <li>{m}</li>
                                );
                            })}
                        </ul>
                    </>
                ),
                actions: [
                    {
                        text: 'Annulla',
                        action: () => {
                            closeMessageDialog();
                        }
                    },
                    {
                        text: 'Invia',
                        action: () => {
                            sendConfirmationEmail();
                            closeMessageDialog();
                        }
                    }
                ]
            })
        } as ButtonProps] : []),
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => onClose()
        }
    ];

    return (
        <>
            <CustomDialogWrapper fullScreen={isMobile} open={isOpen} onClose={onClose} title={'Dettagli iscrizione'} buttons={buttons} isLoading={isLoading} maxWidth='md' fullWidth contentProps={{}} >
                <Accordion expanded={accordion0Open} onChange={() => setAccordion0Open(status => !status)}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                        <Typography style={{ fontSize: '1.4em' }}>Informazioni iscrizione</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: '0' }}>
                        <TableContainer style={{ marginTop: '0px' }}>
                            <Table size='small'>
                                <TableBody>
                                    {selectedRegistration.registrationDatetime && (
                                        <>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>Data iscrizione</TableCell>
                                                <TableCell colSpan={((!!selectedRegistration.course.enableSpecialDietsV2) && selectedRegistration.specialDietsV2Text && isAdmin) ? 2 : 1}>{selectedRegistration.registrationDatetime}</TableCell>
                                            </TableRow>
                                            {selectedRegistration.registrationDatetime !== selectedRegistration.registrationUpdateDatetime && (
                                                <>
                                                    <TableRow>
                                                        <TableCell style={{ fontWeight: 'bold' }}>Data ultima modifica iscrizione</TableCell>
                                                        <TableCell colSpan={((!!selectedRegistration.course.enableSpecialDietsV2) && selectedRegistration.specialDietsV2Text && isAdmin) ? 2 : 1}>{selectedRegistration.registrationUpdateDatetime}</TableCell>
                                                    </TableRow>
                                                </>
                                            )}
                                            {(!!selectedRegistration.course.enableSpecialDiets) && selectedRegistration.specialDiets && (
                                                <>
                                                    <TableRow>
                                                        <TableCell style={{ fontWeight: 'bold' }}>Intolleranze o diete speciali</TableCell>
                                                        <TableCell colSpan={((!!selectedRegistration.course.enableSpecialDietsV2) && selectedRegistration.specialDietsV2Text && isAdmin) ? 2 : 1}>{selectedRegistration.specialDiets}</TableCell>
                                                    </TableRow>
                                                </>
                                            )}
                                            {(!!selectedRegistration.course.enableSpecialDietsV2) && selectedRegistration.specialDietsV2Text && (
                                                <>
                                                    <TableRow>
                                                        <TableCell style={{ fontWeight: 'bold' }}>Intolleranze o diete speciali</TableCell>
                                                        <TableCell>
                                                            {selectedRegistration.specialDietsV2Text}
                                                        </TableCell>
                                                        {isAdmin && (
                                                            <TableCell style={{ textAlign: 'right' }}>
                                                                <Button onClick={() => setIsChangeDietDialogOpen(true)} color={'primary'} variant='outlined' size='small'>
                                                                    Modifica dieta
                                                                </Button>
                                                            </TableCell>
                                                        )}
                                                    </TableRow>
                                                </>
                                            )}
                                            {(!!selectedRegistration.course.enableClasses) && selectedRegistration.schoolClass && (
                                                <>
                                                    <TableRow>
                                                        <TableCell style={{ fontWeight: 'bold' }}>Classe frequentata</TableCell>
                                                        <TableCell colSpan={((!!selectedRegistration.course.enableSpecialDietsV2) && selectedRegistration.specialDietsV2Text && isAdmin) ? 2 : 1}>{selectedRegistration.schoolClass.description}</TableCell>
                                                    </TableRow>
                                                </>
                                            )}
                                            {selectedRegistration.sessionsWelfare.length > 0 && (
                                                <>
                                                    {selectedRegistration.sessionsWelfare.map((obj: any) => {
                                                        return (
                                                            <TableRow>
                                                                <TableCell style={{ fontWeight: 'bold' }}>Settimane {obj.name}</TableCell>
                                                                <TableCell colSpan={((!!selectedRegistration.course.enableSpecialDietsV2) && selectedRegistration.specialDietsV2Text && isAdmin) ? 2 : 1}>
                                                                    {obj.sessions.map((session: any, idx: number) => {
                                                                        return (
                                                                            <>
                                                                                {session}
                                                                                {idx < (obj.sessions.length - 1) && (
                                                                                    <br />
                                                                                )}
                                                                            </>
                                                                        )
                                                                    })}
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                </>
                                            )}
                                            {isAdmin && (
                                                <>
                                                    <TableRow>
                                                        <TableCell style={{ fontWeight: 'bold' }}>Note</TableCell>
                                                        <StyledNotesTableCell>
                                                            <div dangerouslySetInnerHTML={{
                                                                __html: sanitizeHtml(selectedRegistration.notes, {
                                                                    allowedAttributes: false,
                                                                    allowedTags: false,
                                                                    parseStyleAttributes: false
                                                                })
                                                            }} />
                                                        </StyledNotesTableCell>
                                                        <TableCell style={{ textAlign: 'right' }}>
                                                            <Button onClick={() => setIsNotesDialogOpen(true)} color={'primary'} variant='outlined' size='small'>
                                                                {!!selectedRegistration.plainNotes ? 'Modifica' : 'Aggiungi'}
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            )}

                                        </>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={accordion1Open} onChange={() => setAccordion1Open(status => !status)}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                        <Typography style={{ fontSize: '1.4em' }}>Dati anagrafici</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: '0' }}>
                        <TableContainer style={{ marginTop: '0px' }}>
                            <Table size='small'>
                                <TableBody>
                                    {detailsMap.map(section => {
                                        if ((selectedRegistration && _.get(selectedRegistration, section.keyPrefix)) || (section.keyPrefix === 'secondParentUser' && _.get(selectedRegistration, 'parent'))) {
                                            return (
                                                <>
                                                    <TableRow>
                                                        <TableCell colSpan={3}>
                                                            <Typography variant='h5' noWrap style={{ fontSize: '1.2em', marginBottom: '4px', display: 'flex', alignItems: 'center' }}>
                                                                <span>
                                                                    {section.name}
                                                                </span>
                                                                {isAdmin && (
                                                                    <>
                                                                        <div style={{ flexGrow: 1 }}></div>
                                                                        <Button onClick={() => setEditDialogSection(section.keyPrefix)} color={'primary'} variant='outlined'>
                                                                            {_.get(selectedRegistration, section.keyPrefix) ? 'Modifica dati' : 'Aggiungi dati'}
                                                                        </Button>
                                                                    </>
                                                                )}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    {section.map.map(attribute => {
                                                        const value = getTransformedAttribute(section, attribute);
                                                        const isPhotoConsent = attribute.key === 'photoConsent';
                                                        const isPhone = attribute.key === 'phone';
                                                        const isAdminPhotoConsent = isPhotoConsent && isSuperAdmin;

                                                        if (!value) return null;
                                                        if (isPhotoConsent && !isAdmin) return null;

                                                        return (
                                                            <TableRow style={(isPhotoConsent && value === 'no') ? { backgroundColor: '#f5a8a8' } : undefined}>
                                                                <TableCell style={{ fontWeight: 'bold' }}>{attribute.name}</TableCell>
                                                                <TableCell colSpan={(isAdminPhotoConsent || (isPhone && isAdmin)) ? 1 : 2}>{getTransformedAttribute(section, attribute)}</TableCell>
                                                                {isAdminPhotoConsent && (
                                                                    <TableCell style={{ textAlign: 'right' }}>
                                                                        <Button onClick={() => setIsChangePhotoConsentDialogOpen(true)} color={'primary'} variant='outlined' size='small' style={{ backgroundColor: '#ffffff', textAlign: 'center' }}>
                                                                            Modifica consenso
                                                                        </Button>
                                                                    </TableCell>
                                                                )}
                                                                {(isPhone && isAdmin) && (
                                                                    <TableCell style={{ textAlign: 'right' }}>
                                                                        {/* @ts-ignore */}
                                                                        <Button component={Link} target='_blank' href={"whatsapp://send?phone=" + _.get(selectedRegistration, section.keyPrefix + '.normalizedPhoneNumber')?.substring(1) ?? ''} color={'primary'} variant='outlined' size='small' style={{ backgroundColor: '#ffffff', textDecoration: 'none', color: '#128C7E', textAlign: 'center' }}>
                                                                            Contatta su WhatsApp
                                                                        </Button>
                                                                    </TableCell>
                                                                )}
                                                            </TableRow>
                                                        );
                                                    })}
                                                    <TableRow style={{ height: '24px' }}>
                                                        <TableCell colSpan={2} style={{ borderBottom: 'none' }} />
                                                    </TableRow>
                                                </>
                                            );
                                        }

                                        return null;
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </AccordionDetails>
                </Accordion>

                {(sessions.length > 0 && (selectedRegistration?.course?.id ?? '') !== '42990ea4-76a0-11ef-aac5-4865ee14ab2a') && (
                    <Accordion expanded={accordion2Open} onChange={() => setAccordion2Open(status => !status)}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                            <Typography style={{ fontSize: '1.4em' }}>{sessionType === 'daily_week_events' ? 'Settimane selezionate' : (sessionType === 'weekly_events' ? 'Giorni selezionati' : 'Eventi selezionati')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: '0' }}>
                            <TableContainer style={{ marginTop: '0px' }}>
                                <Table size='small'>
                                    {!singleSessionEvent && (
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{sessionType === 'daily_week_events' ? 'Settimana' : 'Giorno'}</TableCell>
                                                <TableCell />
                                                <TableCell align='center'>Corso</TableCell>
                                                {hasFoodService && <TableCell align='center'>Pasti</TableCell>}
                                            </TableRow>
                                        </TableHead>
                                    )}

                                    <TableBody>
                                        {[...sessions, ...savedDeletedSessions].sort(compareSessionDate).map((session: any) => {
                                            const date = (() => {
                                                if (singleSessionEvent) {
                                                    return (
                                                        <>
                                                            <strong>{moment(session.startTime).format('D MMMM YYYY')}</strong>, dalle ore <strong>{moment(session.startTime).format('H:mm')}</strong> alle ore <strong>{moment(session.endTime).format('H:mm')}</strong>
                                                        </>
                                                    )
                                                } else if (sessionType === 'daily_week_events') {
                                                    return moment(session.startTime).format('D MMMM YYYY') + ' - ' + moment(session.endTime).format('D MMMM YYYY');
                                                } else if (sessionType === 'weekly_events') {
                                                    return (
                                                        <>
                                                            <strong>{moment(session.startTime).format('dddd')}</strong>, dalle ore <strong>{moment(session.startTime).format('H:mm')}</strong> alle ore <strong>{moment(session.endTime).format('H:mm')}</strong>
                                                        </>
                                                    );
                                                } else {
                                                    return moment(session.startTime).format('dddd D MMMM YYYY');
                                                }
                                            })();

                                            if (!isSelected(session.id) && !isFoodSelected(session.id) && !singleSessionEvent && !session.deletedSessionData) return null;

                                            return (
                                                <TableRow style={session.deletedSessionData ? { backgroundColor: '#fdecea' } : undefined}>
                                                    <TableCell colSpan={session.deletedSessionData ? 1 : 2} style={session.deletedSessionData ? { width: '1px', whiteSpace: 'nowrap' } : undefined}>{date}</TableCell>

                                                    {session.deletedSessionData && (
                                                        <TableCell>[ELIMINATA]</TableCell>
                                                    )}

                                                    <TableCell align='center'>
                                                        {singleSessionEvent ? (isSelected(session.id) ? 'sì' : 'no') : (isSelected(session.id) ? 'X' : '')}
                                                        {session?.deletedSessionData?.coursePrice ? ('€' + (parseFloat(session.deletedSessionData.coursePrice) * DELETED_ACTION_COEFF[session.deletedSessionData.actionType as keyof typeof DELETED_ACTION_COEFF]).toFixed(2)) : ''}
                                                    </TableCell>
                                                    {hasFoodService && (
                                                        <TableCell align='center'>
                                                            {isFoodSelected(session.id) ? 'X' : ''}
                                                            {session?.deletedSessionData?.foodPrice ? ('€' + (parseFloat(session.deletedSessionData.foodPrice) * DELETED_ACTION_COEFF[session.deletedSessionData.actionType as keyof typeof DELETED_ACTION_COEFF]).toFixed(2)) : ''}
                                                        </TableCell>
                                                    )}
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                    </Accordion>
                )}

                {(sessions.length > 0 || isAdmin) && (
                    <>
                        <ChangeSessionsDialog
                            isOpen={isChangeSessionsDialogOpen}
                            onClose={onChangeSessionsDialogClose}
                            sessionType={sessionType}
                            hasFoodService={hasFoodService}
                            simulateRegistrationData={selectedRegistration?.wrapperDetails?.details}
                            isSelected={isSelected}
                            isFoodSelected={isFoodSelected}
                            handleSelect={handleSelect}
                            handleFoodSelect={handleFoodSelect}
                            baseUrl={baseUrl + '/' + selectedRegistrationId}
                            selectedSessions={selectedSessions}
                            isAdmin={isAdmin}
                            pricesNotAvailable={pricesNotAvailable}
                            singleSessionEvent={singleSessionEvent}
                            specialDiets={specialDiets}
                            setSpecialDiets={setSpecialDiets}
                            showSpecialDiets={selectedRegistration?.course?.enableSpecialDietsV2 ?? false}
                            isSummer={(selectedRegistration?.course?.season?.name ?? '').toLowerCase().includes('estiva')}
                        />

                        <ChangeDietDialog
                            isOpen={isChangeDietDialogOpen}
                            onClose={onChangeDietDialogClose}
                            sessionType={sessionType}
                            hasFoodService={hasFoodService}
                            simulateRegistrationData={selectedRegistration?.wrapperDetails?.details}
                            isSelected={isSelected}
                            isFoodSelected={isFoodSelected}
                            handleSelect={handleSelect}
                            handleFoodSelect={handleFoodSelect}
                            baseUrl={baseUrl + '/' + selectedRegistrationId}
                            selectedSessions={selectedSessions}
                            isAdmin={isAdmin}
                            pricesNotAvailable={pricesNotAvailable}
                            singleSessionEvent={singleSessionEvent}
                            specialDiets={specialDiets}
                            setSpecialDiets={setSpecialDiets}
                            showSpecialDiets={selectedRegistration?.course?.enableSpecialDietsV2 ?? false}
                        />
                    </>
                )}

                {isAdmin && (
                    <>
                        <EditDataDialog
                            data={selectedRegistration}
                            section={editDialogSection}
                            onClose={() => setEditDialogSection(null)}
                            onDataChange={onDataChange}
                        />

                        <ChangePhotoConsentDialog
                            isOpen={isChangePhotoConsentDialogOpen}
                            onClose={onChangePhotoConsentDialogClose}
                            baseUrl={baseUrl + '/' + selectedRegistrationId}
                            savedPhotoConsent={selectedRegistration?.participant?.photoConsent ?? null}
                        />
                    </>
                )}
            </CustomDialogWrapper>

            <DeletedSessionsDialog
                open={isDeletedSessionsDialogOpen}
                onClose={() => {
                    setIsDeletedSessionsDialogOpen(false)
                    refreshRegistrations();
                }}
                deletedSessions={deletedSessions}
                baseUrl={baseUrl + '/' + selectedRegistrationId}
            />

            <RegistrationNotesDialog open={isNotesDialogOpen} closeDialog={() => setIsNotesDialogOpen(false)} refreshRegistrations={onDataChange} registration={selectedRegistration} />
        </>

    );
};

export default DetailsDialog;
