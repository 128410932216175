import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CssBaseline from '@material-ui/core/CssBaseline';

import LoaderWrapper from '../../common/components/LoaderWrapper/LoaderWrapper';
import useExternalLoader from '../../common/hooks/useExternalLoader/useExternalLoader';
import AppBar from '../../common/layouts/components/AppBar/AppBar';
import Drawer from '../../common/layouts/components/Drawer/Drawer';
import { MainController } from '../../common/layouts/styled';
import api from '../../common/utils/api';
import { ReduxState } from '../../redux/types';
import { setFromApi } from '../../redux/user/userSlice';
import Dialogs from '../public/dialogs/Dialogs';
import DrawerContent from './components/DrawerContent/DrawerContent';
import Router from './routing/Router';
import { open as openDrawerRedux, close as closeDrawerRedux } from '../../redux/drawer/drawerSlice';

const AdminApp = () => {
    const { isIframe } = useSelector((state: ReduxState) => state.routing);
    const [isLoading, setIsLoading] = useState(true);

    const userInfo = useSelector((state: ReduxState) => state.user);
    const isUserLoggedIn = userInfo.id.length > 0;

    const { open: isDrawerOpen } = useSelector((state: ReduxState) => state.drawer);

    const dispatch = useDispatch();

    useExternalLoader(isLoading);

    useEffect(() => {
        api.isSuperUserLoggedIn().then(isLoggedIn => {
            if (isLoggedIn) {
                api.request('/admin/auth/me').then(profile => {
                    dispatch(setFromApi(profile));
                    setIsLoading(false);
                });
            } else {
                setIsLoading(false);
            }
        });
    }, [dispatch]);

    const setIsDrawerOpen = useCallback((open: boolean) => {
        if (open) {
            dispatch(openDrawerRedux());
        } else {
            dispatch(closeDrawerRedux());
        }
    }, [dispatch]);

    const openDrawer = useCallback(() => {
        setIsDrawerOpen(true);
    }, [setIsDrawerOpen]);

    // TODO: add some logic
    const showSidebar = !isIframe && isUserLoggedIn;
    const showAppbar = !isIframe;

    const drawerContent = <DrawerContent />;

    return (
        <MainController>
            <CssBaseline />

            {showAppbar && (
                <AppBar showSidebar={showSidebar} openDrawer={openDrawer} />
            )}

            <Drawer show={showSidebar} isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen} content={drawerContent} />

            <LoaderWrapper type='internal'>
                {!isLoading && <Router appbarVisible={showAppbar} />}
            </LoaderWrapper>

            <Dialogs />
        </MainController>
    );
};

export default AdminApp;
