import Button from '@material-ui/core/Button';
import styled from 'styled-components/macro';

export const Container = styled.div`
    margin-top: 24px;
    display: flex;
`;

export const LeftSection = styled.div`
    text-align: left;
    flex: 1;
`;

export const CenterSection = styled.div`
    text-align: center;
    flex: 1;
`;

export const RigthSection = styled.div`
    text-align: right;
    flex: 1;
`;

export const StyledButton = styled(Button)`
    margin: 4px 0;
`;
