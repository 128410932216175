import { useEffect, useState } from 'react';

import { ButtonProps } from '@material-ui/core/Button';
import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import GenericObject from '../../../../../../typesAdditional/GenericObject';
import { Divider, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/it';
import AvailabilityTable from '../../../summerSchedulePage/components/FindAvailabilityDialog/components/AvailabilityTable/AvailabilityTable';

interface Props {
    open: boolean;
    closeDialog: () => void;
    globalAvailability: GenericObject[];
    detailedClassification: GenericObject;
    changes: GenericObject[];
}

const VariationDetailDialog = (props: Props) => {
    const { open, closeDialog, globalAvailability, detailedClassification, changes } = props;

    const [selectedWeek, setSelectedWeek] = useState('');

    useEffect(() => {
        if (globalAvailability[0]) {
            setSelectedWeek(globalAvailability[0].weekId);
        }
    }, [globalAvailability]);

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        },
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Dettagli variazioni'} buttons={buttons} maxWidth='xl'>
            <div style={{ display: 'flex', marginLeft: '-24px', marginRight: '-24px', marginTop: '-16px', marginBottom: '-16px', minWidth: '900px' }}>
                <div style={{ flex: 0.3, padding: '17px 8px', borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                    <List>
                        {globalAvailability.map((week: any) => {
                            const startDate = moment(week.startDate);
                            const endDate = moment(week.startDate).add(4, 'days');

                            const classification = (detailedClassification?.data ?? []).find((w: any) => w.weekId === week.weekId)

                            type status = 0 | 1 | 2;

                            const statusMap = {
                                0: {
                                    color: '#edf7ed',
                                    borderColor: '#4caf50',
                                    label: 'Non impattante',
                                    outlined: false
                                },
                                1: {
                                    color: '#fff4e5',
                                    borderColor: '#ff9800',
                                    label: 'Impattante, sostituibile',
                                    outlined: false
                                },
                                2: {
                                    color: '#fdecea',
                                    borderColor: '#f44336',
                                    label: 'Impattante, non sostituibile',
                                    outlined: false
                                },
                            }

                            const statusData = statusMap[classification.status as status];

                            return (
                                <ListItem button selected={week.weekId === selectedWeek} onClick={() => setSelectedWeek(week.weekId)}>
                                    <div style={{ margin: '-16px', width: '6px', height: '72px', marginRight: '16px', backgroundColor: statusData.borderColor }}></div>
                                    <ListItemText primary={startDate.format('D MMMM') + ' - ' + endDate.format('D MMMM')} secondary={statusData.label.toLowerCase()} />
                                </ListItem>
                            )
                        })}
                    </List>
                </div>
                <div style={{ flex: 0.7, padding: '14px 8px' }}>
                    <AvailabilityTable
                        selectedWeek={globalAvailability.find((w: any) => w.weekId === selectedWeek) ?? {}}
                        instructor={globalAvailability.find((w: any) => w.weekId === selectedWeek)?.availability ?? {}}
                        changes={changes.find((w: any) => w.weekId === selectedWeek)?.extendedChanges ?? []}
                    />
                    {(globalAvailability.find((w: any) => w.weekId === selectedWeek)?.availability ?? {}).notes ? (
                        <>
                            <p style={{ margin: '16px 12px' }}><b>Note:</b> {(globalAvailability.find((w: any) => w.weekId === selectedWeek)?.availability ?? {}).notes}</p>
                            <Divider />
                        </>
                    ) : (
                        <div style={{ height: '53px' }} />
                    )}
                    <div style={{ marginTop: '32px' }}>
                        <Typography variant="caption" display="block" gutterBottom>
                            Le celle con lo sfondo colorato rappresentano le disponibilità modificate.
                        </Typography>
                    </div>
                </div>
            </div>
        </CustomDialogWrapper >
    );
};

export default VariationDetailDialog;
