import { useEffect, useMemo, useState } from 'react';
import { useWindowSize } from 'react-use';

import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';

import CustomTextField from '../../../../common/components/CustomTextField/CustomTextField';
import useInternalLoader from '../../../../common/hooks/useInternalLoader/useExternalLoader';
import api from '../../../../common/utils/api';
import { Title } from './styled';

const ReceiptsListPage = () => {
    const { width } = useWindowSize();
    const isMobile = width <= 700;

    const setIsLoading = useInternalLoader();
    const [data, setData] = useState<any[]>([]);

    const [order, setOrder] = useState(localStorage.getItem('receipts_order') ?? 'alphabetical');
    const [year, setYear] = useState(localStorage.getItem('receipts_year') ?? (new Date().getFullYear()));
    const [query, setQuery] = useState('');

    useEffect(() => {
        setIsLoading(true);
        api.request('/admin/receipts', 'GET', { year }).then(res => {
            setData(res);
            setIsLoading(false);
        });
    }, [year, setIsLoading]);

    const sortedReceipts = useMemo(() => {
        return data.sort((a: any, b: any) => {
            if (order === 'alphabetical' && a.personName !== b.personName) {
                return a.personName.localeCompare(b.personName);
            } else {
                if (a.date === b.date) {
                    return a.createdAt.localeCompare(b.createdAt);
                } else {
                    return a.date.localeCompare(b.date);
                }
            }
        });
    }, [data, order]);

    const filteredReceipts = useMemo(() => {
        if (!query || !query.trim()) {
            return sortedReceipts;
        }

        const lowerQuery = query.toLowerCase().trim().replaceAll(' ', '');

        return sortedReceipts.filter(r => {
            return (
                r.personName.toLowerCase().replaceAll(' ', '').startsWith(lowerQuery) ||
                r.taxCode.toLowerCase().replaceAll(' ', '').startsWith(lowerQuery) ||
                r.personName.toLowerCase().replaceAll(' ', '').includes(lowerQuery) ||
                r.taxCode.toLowerCase().replaceAll(' ', '').includes(lowerQuery)
            );
        }).sort((a, b) => {
            const aGoodMatch = (
                a.personName.toLowerCase().replaceAll(' ', '').startsWith(lowerQuery) ||
                a.taxCode.toLowerCase().replaceAll(' ', '').startsWith(lowerQuery)
            );

            const bGoodMatch = (
                b.personName.toLowerCase().replaceAll(' ', '').startsWith(lowerQuery) ||
                b.taxCode.toLowerCase().replaceAll(' ', '').startsWith(lowerQuery)
            );

            if (aGoodMatch && bGoodMatch) {
                return 0;
            } else if (aGoodMatch) {
                return 1;
            } else {
                return -1;
            }
        });
    }, [sortedReceipts, query]);

    /*
    const getNameSurnameCombinations = useCallback((str: string) => {
        const combinations = [];

        const words = str.trim().split(' ').filter((word: string) => {
            return word.length > 0;
        });

        if (words.length < 2) {
            return [];
        }

        for (let i = 1; i < words.length; i++) {
            combinations.push([words.slice(0, i).join(' '), words.slice(i).join(' ')]);
        }

        return combinations;
    }, []);
    */

    return (
        <>
            <Title isMobile={isMobile}>
                <div style={{ flex: '1', flexGrow: 1 }}>
                    Ricevute emesse
                </div>
                <div style={{ flex: '1', flexGrow: 1, maxWidth: '852px', marginTop: '4px', textAlign: 'right' }}>
                    <CustomTextField
                        select
                        label={'Anno fiscale'}
                        value={year}
                        onChange={(e: any) => {
                            setYear(e.target.value);
                            localStorage.setItem('receipts_year', e.target.value);
                        }}
                        variant='outlined'
                        required
                        fullWidth
                        keepMounted
                        style={{ marginRight: '12px', maxWidth: '240px', textAlign: 'left' }}
                    >
                        {Array.from(new Array((new Date().getFullYear()) - 2022 + 1), (_x, i) => i + 2022).map((y: number) => {
                            return (
                                <MenuItem value={y} style={{ whiteSpace: 'break-spaces' }}>
                                    {y}
                                </MenuItem>
                            );
                        })}
                    </CustomTextField>

                    <TextField
                        label='Ricerca genitore'
                        variant='outlined'
                        placeholder={''}
                        value={query}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQuery(e.target.value)}
                        InputProps={{
                            startAdornment: <InputAdornment position='start'><SearchIcon /></InputAdornment>,
                        }}
                    />

                    <CustomTextField
                        select
                        label={'Ordine'}
                        value={order}
                        onChange={(e: any) => {
                            setOrder(e.target.value);
                            localStorage.setItem('receipts_order', e.target.value);
                        }}
                        variant='outlined'
                        required
                        fullWidth
                        keepMounted
                        style={{ marginLeft: '12px', maxWidth: '240px', textAlign: 'left' }}
                    >
                        <MenuItem value={'alphabetical'} style={{ whiteSpace: 'break-spaces' }}>
                            alfabetico
                        </MenuItem>
                        <MenuItem value={'date'} style={{ whiteSpace: 'break-spaces' }}>
                            data di emissione
                        </MenuItem>
                    </CustomTextField>
                </div>
            </Title>

            <TableContainer component={Paper}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Numero</TableCell>
                            <TableCell>Data</TableCell>
                            <TableCell>Nome genitore</TableCell>
                            <TableCell>Codice fiscale</TableCell>
                            <TableCell>Corso</TableCell>
                            <TableCell>Metodo di pagamento</TableCell>
                            <TableCell>Importo</TableCell>
                            <TableCell>Azioni</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredReceipts.map((receipt: any) => {
                            return (
                                <TableRow style={receipt.cancelled ? { backgroundColor: '#fdecea' } : undefined}>
                                    <TableCell>{receipt.fullNumber}</TableCell>
                                    <TableCell>{moment(receipt.date).format('DD/MM/YYYY')}</TableCell>
                                    <TableCell>{receipt.personName}</TableCell>
                                    <TableCell>{receipt.taxCode}</TableCell>
                                    <TableCell>{receipt.courseName}</TableCell>
                                    <TableCell>{receipt.methodName}</TableCell>
                                    <TableCell>
                                        € {parseFloat(receipt.amount).toFixed(2)}
                                    </TableCell>
                                    <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>
                                        <Button href={receipt.cancelled ? '#' : (process.env.REACT_APP_API_HOST + '/assets/' + receipt.asset.id)} target='_blank' color={'primary'} variant='outlined' style={{ backgroundColor: '#ffffff' }} disabled={receipt.cancelled} >
                                            Visualizza
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default ReceiptsListPage;
