import { useCallback, useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import moment from 'moment';

import GreenButton from '../../../../common/components/GreenButton/GreenButton';
import useInternalLoader from '../../../../common/hooks/useInternalLoader/useExternalLoader';
import { useMessageDialog } from '../../../../common/hooks/useMessageDialog/useMessageDialog';
import api from '../../../../common/utils/api';
import snackbar from '../../../../common/utils/snackbar';
import NewReconciliationDialog from './components/NewReconciliationDialog/NewReconciliationDialog';
import { Title } from './styled';
import LoadingDialog from './components/LoadingDialog/LoadingDialog';
import { downloadBlob } from 'download.js';

const BankReconciliationPage = () => {
    const setIsLoading = useInternalLoader();

    const { width } = useWindowSize();

    const [showMessageDialog, closeMessageDialog] = useMessageDialog();

    const isMobile = width <= 700;

    const [data, setData] = useState<any[]>([]);

    const [isNewReconciliationDialogOpen, setIsNewReconciliationDialogOpen] = useState(false);
    const [isLoadingDialogOpen, setIsLoadingDialogOpen] = useState(false);

    const updateData = useCallback(() => {
        setIsLoading(true);
        setData([]);
        api.request('/admin/bank_reconciliation/files').then(res => {
            setData(res);
            setIsLoading(false);
        });
    }, [setIsLoading]);

    useEffect(() => {
        updateData();
    }, [updateData]);

    const base64toBlob = useCallback((dataURI: string) => {
        // convert a base64 string to a blob object
        const byteString = atob(dataURI);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab]);
    }, []);

    const onFileUploaded = useCallback((assetId: string) => {
        setIsNewReconciliationDialogOpen(false);
        setIsLoadingDialogOpen(true);

        api.request('/admin/bank_reconciliation/simulate', 'POST', { assetId }, false).then((res) => {
            setIsLoadingDialogOpen(false);

            showMessageDialog({
                title: 'Conferma importazione',
                message: (
                    <>
                        {res.added.count > 0 && (
                            <p style={{ marginTop: '0', marginBottom: '10px' }}>
                                Sono stati riconosciuti <b>{res.added.count}</b> nuovi pagamenti, per un totale di <b>€{parseFloat(res.added.amount).toFixed(2)}</b>.
                            </p>
                        )}

                        {res.duplicate.count > 0 && (
                            <p style={{ marginTop: '0', marginBottom: '10px' }}>
                                Sono stati ignorati <b>{res.duplicate.count}</b> pagamenti duplicati, per un totale di <b>€{parseFloat(res.duplicate.amount).toFixed(2)}</b>.
                            </p>
                        )}

                        {res.pending.count > 0 && (
                            <p style={{ marginTop: '0', marginBottom: '10px' }}>
                                Sono stati ignorati <b>{res.pending.count}</b> pagamenti, per un totale di <b>€{parseFloat(res.pending.amount).toFixed(2)}</b>, perché non è ancora disponibile la causale completa. Verranno registrati con la prossima importazione.
                            </p>
                        )}

                        {res.error.count > 0 && (
                            <p style={{ marginTop: '0', marginBottom: '10px' }}>
                                Non è stato possibile riconoscere <b>{res.error.count}</b> pagamenti.
                            </p>
                        )}

                        {res.added.count > 0 && (
                            <p style={{ margin: '0' }}>
                                Vuoi confermare l'importazione?
                            </p>
                        )}
                    </>
                ),
                actions: [
                    {
                        text: 'Annulla',
                        action: () => {
                            closeMessageDialog();
                        }
                    },
                    ...(res.added.count > 0 ? [
                        {
                            text: 'Conferma',
                            action: () => {
                                closeMessageDialog();
                                setIsLoadingDialogOpen(true);

                                api.request('/admin/bank_reconciliation/import', 'POST', { assetId }).then((res) => {
                                    snackbar.success('Pagamenti importati con successo!');
                                    const blob = base64toBlob(res.fileContent);
                                    downloadBlob(res.fileName, blob);
                                    updateData();
                                }).finally(() => {
                                    setIsLoadingDialogOpen(false);
                                });
                            }
                        }
                    ] : [])
                ]
            })
        }).catch((res) => {
            const { error } = res;

            if (error === 'invalid_file') {
                showMessageDialog({
                    title: 'Impossibile caricare il file',
                    message: 'Il file selezionato non è nel formato corretto.'
                });
            }
        })
    }, [base64toBlob, setIsNewReconciliationDialogOpen, closeMessageDialog, showMessageDialog, updateData]);

    return (
        <>
            <Title isMobile={isMobile}>
                <div style={{ flex: '1', flexGrow: 1 }}>
                    Importa pagamenti <span style={{ fontSize: '0.7em' }}>(da estratto conto)</span>
                </div>

                <GreenButton onClick={() => setIsNewReconciliationDialogOpen(true)}>
                    Nuova importazione
                </GreenButton>
            </Title>

            <TableContainer component={Paper} >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Data</TableCell>
                            <TableCell>Utente</TableCell>
                            <TableCell>Pagamenti importati</TableCell>
                            <TableCell>Pagamenti duplicati</TableCell>
                            <TableCell>Pagamenti non riconosciuti</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((file: any) => {
                            return (
                                <TableRow>
                                    <TableCell>{moment(file.createdAt).format('DD/MM/YYYY')}</TableCell>
                                    <TableCell>{file.superUser.name}</TableCell>
                                    <TableCell>{file.addedCount} (€{parseFloat(file.addedAmount).toFixed(2)})</TableCell>
                                    <TableCell>{file.duplicateCount} (€{parseFloat(file.duplicateAmount).toFixed(2)})</TableCell>
                                    <TableCell>{file.errorCount}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <NewReconciliationDialog isOpen={isNewReconciliationDialogOpen} onClose={() => setIsNewReconciliationDialogOpen(false)} onFileUploaded={onFileUploaded} />

            <LoadingDialog isOpen={isLoadingDialogOpen} />
        </>
    );
};

export default BankReconciliationPage;
