import { useCallback, useState } from 'react';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import EventIcon from '@material-ui/icons/Event';

import { Asset, Document } from '../../types';
import ApproveDialog from './components/ApproveDialog/ApproveDialog';
import FileUploader from './components/FileUploader/FileUploader';
import FormAsset from './components/FormAsset/FormAsset';
import UnapproveDialog from './components/UnapproveDialog/UnapproveDialog';
import { useMessageDialog } from '../../../../../../../../common/hooks/useMessageDialog/useMessageDialog';
import api from '../../../../../../../../common/utils/api';
import snackbar from '../../../../../../../../common/utils/snackbar';
import Link from '@material-ui/core/Link';
import DeletedDocumentsDialog from './components/DeletedDocumentsDialog/DeletedDocumentsDialog';
import moment from 'moment';
import 'moment/locale/it';
import GenericObject from '../../../../../../../../typesAdditional/GenericObject';

interface Props {
    initialAssets?: Asset[];
    document: Document & GenericObject;
    handleAssetChange?: (documentId: string, assets: any[]) => void;
    isAdmin?: boolean;
    refreshRegistrations?: () => void;
    closeDialog?: () => void;
    deletedDocument?: boolean;
}

const backgroundColors = {
    ok: '#edf7ed',
    ok_not_required: '#edf7ed',
    reviewing: '#edf7ed',
    expiring: '#fff4e5',
    missing: '',
    missing_pre_birthday: '',
    read_only: ''
};

const backgroundColorsAdmin = {
    ok: backgroundColors.ok,
    ok_not_required: backgroundColors.ok,
    reviewing: '#fff4e5',
    expiring: backgroundColors.expiring,
    missing: '#fdecea',
    missing_pre_birthday: '#fdecea',
    read_only: ''
};

const showAdminButtons = true;

const DocumentCard = (props: Props) => {
    const { initialAssets, document, handleAssetChange, isAdmin, refreshRegistrations, closeDialog, deletedDocument } = props;
    const { id: documentId, documentType, status, deletedDocuments } = document;
    const { id, name, description, formAsset } = documentType;

    const [isLoading, setIsLoading] = useState(false);
    const [showMessageDialog, closeMessageDialog] = useMessageDialog();

    const showDeleteDialog = useCallback(() => {
        showMessageDialog({
            title: 'Elimina documento',
            message: (
                <>
                    <p style={{ margin: '0px', marginBottom: '10px' }}>
                        Sei sicuro di volere eliminare il documento selezionato?
                    </p>
                    <p style={{ margin: '0px' }}>
                        L'utente non è verrà notificato.
                    </p>
                </>
            ),
            actions: [
                {
                    text: 'Annulla',
                    action: () => {
                        closeMessageDialog();
                    }
                },
                {
                    text: 'Conferma',
                    action: () => {
                        closeMessageDialog();
                        setIsLoading(true);
                        api.request('/admin/documents/' + documentId, 'DELETE').then(() => {
                            snackbar.success('Documento eliminato!');
                            if (refreshRegistrations) refreshRegistrations();
                            if (closeDialog) closeDialog();
                        }).finally(() => {
                            setIsLoading(false);
                        });
                    }
                }
            ]
        });
    }, [closeDialog, showMessageDialog, closeMessageDialog, documentId, refreshRegistrations]);

    const [isUnapproveDialogOpen, setIsUnapproveDialogOpen] = useState(false);
    const [isApproveDialogOpen, setIsApproveDialogOpen] = useState(false);
    const [isDeletedDocumentsDialogOpen, setIsDeletedDocumentsDialogOpen] = useState(false);

    return (
        <>
            <Card variant='outlined' style={{ margin: '16px 0 0', backgroundColor: (isAdmin ? backgroundColorsAdmin[status] : backgroundColors[status]) ?? '' }}>
                <CardContent>
                    {!deletedDocument && (
                        <>
                            <Typography variant='h5' component='h2'>
                                {name}
                            </Typography>

                            <Typography variant='body2' style={{ marginTop: '4px', marginLeft: '1px' }}>
                                {description}
                            </Typography>

                            {(status === 'ok' || (status === 'reviewing' && !isAdmin)) && (
                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '6px' }}>
                                    <CheckCircleOutlineIcon style={{ marginRight: '4px' }} /> Documento già caricato!
                                </div>
                            )}

                            {(status === 'ok_not_required') && (
                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '6px' }}>
                                    <CheckCircleOutlineIcon style={{ marginRight: '4px' }} /> Documento non richiesto (caricamento facoltativo)
                                </div>
                            )}

                            {(status === 'expiring') && (
                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '6px' }}>
                                    <EventIcon style={{ marginRight: '4px' }} /> Documento in scadenza
                                </div>
                            )}

                            {(status === 'reviewing' && isAdmin) && (
                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '6px' }}>
                                    <CheckCircleOutlineIcon style={{ marginRight: '4px' }} /> In attesa di approvazione
                                </div>
                            )}

                            {document.expireAt && (
                                <p>
                                    Data scadenza: <b>{moment(document.expireAt).format('DD/MM/YYYY')}</b>.
                                </p>
                            )}
                        </>
                    )}

                    {/* TODO: check this conditions */}
                    {(status === 'missing' || status === 'expiring' || true) && (
                        <>
                            {(status === 'missing' || status === 'expiring' || status === 'missing_pre_birthday') && formAsset && (
                                <FormAsset asset={formAsset} />
                            )}

                            <FileUploader initialAssets={initialAssets} documentId={id} status={status} savedAssets={document.assets ?? []} handleAssetChange={handleAssetChange} isAdmin={isAdmin} />
                        </>
                    )}

                    {!deletedDocument && (
                        <>
                            {status !== 'missing' && status !== 'reviewing' && isAdmin && showAdminButtons && !!documentId && (
                                <div style={{ marginTop: '6px' }}>
                                    <Button disabled={isLoading} onClick={() => showDeleteDialog()} color={'secondary'} variant={'outlined'} style={{ backgroundColor: '#ffffff' }}>
                                        Elimina
                                    </Button>
                                </div>
                            )}

                            {(status === 'reviewing' && isAdmin && showAdminButtons) && (
                                <div style={{ marginTop: '6px' }}>
                                    <Button disabled={isLoading} onClick={() => showDeleteDialog()} color={'secondary'} variant={'outlined'} style={{ backgroundColor: '#ffffff', marginRight: '12px' }}>
                                        Elimina
                                    </Button>{' '}
                                    <Button disabled={isLoading} onClick={() => setIsUnapproveDialogOpen(true)} color={'secondary'} variant={'outlined'} style={{ backgroundColor: '#ffffff' }}>
                                        Rifiuta
                                    </Button>{' '}
                                    <Button disabled={isLoading} onClick={() => setIsApproveDialogOpen(true)} color={'primary'} variant={'contained'}>
                                        Approva
                                    </Button>
                                </div>
                            )}

                            {isAdmin && !!deletedDocuments?.length && deletedDocuments?.length > 0 && (
                                <div style={{ textAlign: 'right' }}>
                                    <Link href='#' onClick={(e: any) => {
                                        e.preventDefault();
                                        setIsDeletedDocumentsDialogOpen(true);
                                    }}>
                                        <b>{deletedDocuments?.length}</b>{' '}
                                        document{deletedDocuments?.length === 1 ? 'o' : 'i'}{' '}
                                        eliminat{deletedDocuments?.length === 1 ? 'o' : 'i'}
                                    </Link>
                                </div>
                            )}
                        </>
                    )}

                    {deletedDocument && (
                        <div style={{ marginBottom: '-24px' }}>
                            {(document.user || document.superUser) && (
                                <p>
                                    Caricato{' '}
                                    {document.user ? (
                                        <>dall'utente <b>{document.user.email}</b></>
                                    ) : (
                                        <>da <b>{document.superUser.email}</b></>
                                    )}
                                    {' '}in data <b>{moment(document.createdAt).format('DD/MM/YYYY H:mm:ss')}</b>.
                                </p>
                            )}

                            {document.expireAt && (
                                <p>
                                    Data scadenza: <b>{moment(document.expireAt).format('DD/MM/YYYY')}</b>.
                                </p>
                            )}

                            {(document.approvedAt || document.approvedBy) && (
                                <p>
                                    Approvato {document.approvedBy && (<>da <b>{document.approvedBy.email}</b></>)} {document.approvedAt && (<>in data <b>{moment(document.approvedAt).format('DD/MM/YYYY H:mm:ss')}</b></>)}.
                                </p>
                            )}

                            {(document.unapprovedAt || document.unapprovedBy) && (
                                <p>
                                    Rifiutato {document.unapprovedBy && (<>da <b>{document.unapprovedBy.email}</b></>)} {document.unapprovedAt && (<>in data <b>{moment(document.unapprovedAt).format('DD/MM/YYYY H:mm:ss')}</b></>)}.
                                </p>
                            )}

                            {(document.deletedAt || document.deletedBy) && (
                                <p>
                                    Eliminato {document.deletedBy && (<>da <b>{document.deletedBy.email}</b></>)} {document.deletedAt && (<>in data <b>{moment(document.deletedAt).format('DD/MM/YYYY H:mm:ss')}</b></>)}.
                                </p>
                            )}

                        </div>
                    )}
                </CardContent>
            </Card>

            <UnapproveDialog documentId={documentId ?? ''} open={isUnapproveDialogOpen} closeDialog={(refresh?: boolean) => {
                if (refresh && refreshRegistrations) {
                    refreshRegistrations();
                    if (closeDialog) closeDialog();
                }
                setIsUnapproveDialogOpen(false);
            }} />

            <ApproveDialog documentId={documentId ?? ''} documentType={documentType} open={isApproveDialogOpen} closeDialog={(refresh?: boolean) => {
                if (refresh && refreshRegistrations) {
                    refreshRegistrations();
                    if (closeDialog) closeDialog();
                }
                setIsApproveDialogOpen(false);
            }} />

            <DeletedDocumentsDialog open={isDeletedDocumentsDialogOpen} closeDialog={() => setIsDeletedDocumentsDialogOpen(false)} documents={(deletedDocuments ?? []) as Document[]} documentType={documentType} />
        </>
    );
};

export default DocumentCard;
