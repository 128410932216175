import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { useEffect, useState } from "react";

interface Props {
    description: string;
}

const DynamicTooltipTrainerDescription = ({ description }: Props) => {
    const [shouldShowTooltip, setShouldShowTooltip] = useState(false);

    const [ref, setRef] = useState<HTMLParagraphElement | null>();

    useEffect(() => {
        if (!ref) return;

        const element = ref;
        setShouldShowTooltip(element.offsetWidth < element.scrollWidth);
    }, [ref]);

    const content = (
        <Typography
            key={description}
            ref={(newRef: HTMLParagraphElement) => setRef(newRef)}
            component="p"
            variant="body2"
            color="textPrimary"
            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center' }}
        >
            {description}
        </Typography>
    );

    if (shouldShowTooltip) {
        return (
            <Tooltip title={description} arrow>
                {content}
            </Tooltip>
        )
    } else {
        return content;
    }


}

export default DynamicTooltipTrainerDescription;