import Divider from "@material-ui/core/Divider";
import { ChangeEvent, Dispatch, SetStateAction } from "react"
import GenericObject from "../../../../../../typesAdditional/GenericObject";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

interface Props {
    weeks: GenericObject[];
    selectedTab: number;
    selectTab: Dispatch<SetStateAction<number>>
}

const Footer = ({ weeks, selectedTab, selectTab }: Props) => {
    const a11yProps = (index: number) => {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }

    return (
        <div>
            <Divider />
            <Tabs
                value={selectedTab}
                onChange={(e: ChangeEvent<{}>, newValue: number) => selectTab(newValue)}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
            >
                {weeks.map((week: GenericObject, idx: number) => {
                    return (
                        <Tab label={week.shortName} {...a11yProps(idx)} />
                    )
                })}
            </Tabs>
        </div>
    )
}

export default Footer;