import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useDebounce } from 'use-debounce';

import EntitySelectDialog, { Entity } from '../EntitySelectDialog/EntitySelectDialog';
import GenericObject from '../../../typesAdditional/GenericObject';
import api from '../../utils/api';
import { v4 as uuidv4 } from 'uuid';

interface Props {
    open: boolean;
    onClose: (selectedId?: string) => void;
    title: string;
    noEntityFoundMessage?: string;
    url: string;
    params?: { [param: string]: any };
    map: (rawData: any) => Entity | null;
    disableSearch?: boolean;
    disablePagination?: boolean;
    newEntityBtnPosition?: 'footer' | 'list' | 'both';
    onNewEntityClick?: () => void;
    newEntityBtnText?: string;
    additionalEntries?: Entity[];
    higlightItem?: string;
    onDiscardSelection?: () => void;
}

const ApiEntitySelectDialog = (props: Props) => {
    const { open, url, params, map, disableSearch, disablePagination, ...otherProps } = props;

    const [isLoading, setIsLoading] = useState(true);

    const [apiData, setApiData] = useState<GenericObject[]>([]);

    const [paginationCurrentPage, setPaginationCurrentPage] = useState(1);
    const [reachPaginationEnd, setReachPaginationEnd] = useState(false);

    const [searchQuery, setSearchQuery] = useState('');
    const [debouncedSearchQuery] = useDebounce(searchQuery, 300);

    const apiRequestId = useRef('');

    const getData = useCallback((query: string, page: number) => {
        const currentRequestId = uuidv4();
        apiRequestId.current = currentRequestId;

        // fetch the data to be shown in the dialog
        setIsLoading(true);

        const requestParams = {
            ...params,
            ...(!disableSearch && { query }),       // query param is not passed if disableSearch is true
            ...(!disablePagination && { page }),    // page number is not passed if disablePagination is true
        };

        api.request(url, 'GET', requestParams).then((res) => {
            if (currentRequestId !== apiRequestId.current) return;

            if (disablePagination) {
                setApiData(res);
                setReachPaginationEnd(true);
            }
            else {
                const { data, nextPageUrl } = res;
                setApiData(currentData => [...currentData, ...data]);
                setReachPaginationEnd(nextPageUrl === null);
            }
        }).finally(() => {
            setIsLoading(false);
        });

    }, [url, params, disablePagination, disableSearch]);

    const loadMoreData = useCallback(() => {
        // Lazy loading; this method is called when user reach the last item in the list.
        // If the end of pagination has not yet been reached, then load the next page.
        if (!reachPaginationEnd && !isLoading) {
            setPaginationCurrentPage((page) => page + 1);
        }
    }, [reachPaginationEnd, isLoading]);

    const dataForRender = useMemo(() => {
        // call map function passed as prop for each item, and then filter out null items
        return apiData.map(map).flatMap(item => item ? [item] : []);
    }, [apiData, map]);

    useEffect(() => {
        // effect executed when the dialog opens or closes or when the user change the search query
        if (!open) {
            // if the dialog is close, clear the search query and do nothing else
            setSearchQuery('');
            return;
        }
        // set the current page to 1 and clear the loaded data; new data will be loaded by getData
        setPaginationCurrentPage(1);
        setApiData([]);
    }, [open, debouncedSearchQuery]);

    useEffect(() => {
        // on dialog opens and when the search query or the pagination page changes, load the data from API
        if (!open) return;
        getData(debouncedSearchQuery, paginationCurrentPage);
    }, [open, getData, debouncedSearchQuery, paginationCurrentPage]);

    return (
        <EntitySelectDialog
            open={open}
            data={dataForRender}
            isLoading={isLoading}
            onLastItemReach={loadMoreData}
            {...otherProps}
            {...(!disableSearch && {
                searchQuery,
                onSearch: setSearchQuery
            })}
        />
    );
};

export default ApiEntitySelectDialog;
