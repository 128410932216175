import { Dispatch, SetStateAction } from 'react';

import { ButtonProps } from '@material-ui/core/Button';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import Checkbox from '@material-ui/core/Checkbox';
import { Divider, Typography } from '@material-ui/core';

interface Props {
    open: boolean;
    closeDialog: () => void;
    availableGroups: any[];
    selectedGroups: any[];
    setSelectedGroups: Dispatch<SetStateAction<any[]>>;
}

const SelectGroupsDialog = (props: Props) => {
    const { open, closeDialog, availableGroups, selectedGroups, setSelectedGroups } = props;

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        },
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Gruppi destinatari'} buttons={buttons} maxWidth='sm' fullWidth>
            {availableGroups.map((group, idx) => {
                return (
                    <>
                        {idx !== 0 && (
                            <Divider style={{ margin: '16px 0' }} />
                        )}
                        <div>
                            {group.type === 'single' && (
                                <div style={{ padding: '6px 12px', display: 'flex', backgroundColor: '#def3df' }}>
                                    <Typography variant='body1' style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                                        <b>{group.name}</b>
                                    </Typography>
                                    <div style={{ flex: 1, flexGrow: 1 }} />
                                    <div style={{ flex: 1, flexGrow: 0 }}>
                                        <Checkbox
                                            color='primary'
                                            checked={selectedGroups.includes(group.id)}
                                            onChange={() => {
                                                setSelectedGroups((curr) => {
                                                    if (curr.includes(group.id)) {
                                                        return curr.filter(x => x !== group.id);
                                                    } else {
                                                        const newG = [...curr];
                                                        newG.push(group.id);
                                                        return newG;
                                                    }
                                                })
                                            }}
                                            style={{ padding: '9px 3px' }}
                                        />
                                    </div>
                                </div>
                            )}

                            {group.type === 'group' && (
                                <>
                                    <div style={{ padding: '6px 12px', display: 'flex', backgroundColor: '#def3df', marginBottom: '8px' }}>
                                        <Typography variant='body1' style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                                            <b>{group.name}</b>
                                        </Typography>
                                        <div style={{ flex: 1, flexGrow: 1 }} />
                                        <div style={{ flex: 1, flexGrow: 0 }}>
                                            <Checkbox
                                                color='primary'
                                                checked={group.items.some((g: any) => selectedGroups.includes(g.id))}
                                                indeterminate={!group.items.every((g: any) => selectedGroups.includes(g.id)) && group.items.some((g: any) => selectedGroups.includes(g.id))}
                                                onChange={() => {
                                                    if (group.items.every((g: any) => selectedGroups.includes(g.id))) {
                                                        setSelectedGroups((curr: any) => {
                                                            return curr.filter((g: any) => !group.items.map((x: any) => x.id).includes(g))
                                                        })
                                                    } else {
                                                        setSelectedGroups((curr: any) => {
                                                            const newG = [...curr];

                                                            group.items.forEach((element: any) => {
                                                                if (!newG.includes(element.id)) {
                                                                    newG.push(element.id);
                                                                }
                                                            });

                                                            return newG;
                                                        })
                                                    }
                                                }}
                                                style={{ padding: '9px 4px' }}
                                            />
                                        </div>
                                    </div>
                                    {group.items.sort((a: any, b: any) => {
                                        return a.name.localeCompare(b.name);
                                    }).map((item: any, itemIdx: number) => {
                                        return (
                                            <div style={{ padding: '6px 12px', display: 'flex', backgroundColor: (itemIdx % 2 === 0) ? '#ecf8ed' : undefined }}>
                                                <Typography variant='body1' style={{ paddingLeft: '16px', flex: 1, display: 'flex', alignItems: 'center' }}>
                                                    {item.name}
                                                </Typography>
                                                <div style={{ flex: 1, flexGrow: 1 }} />
                                                <div style={{ flex: 1, flexGrow: 0 }}>
                                                    <Checkbox
                                                        color='primary'
                                                        checked={selectedGroups.includes(item.id)}
                                                        onChange={() => {
                                                            setSelectedGroups((curr) => {
                                                                if (curr.includes(item.id)) {
                                                                    return curr.filter(x => x !== item.id);
                                                                } else {
                                                                    const newG = [...curr];
                                                                    newG.push(item.id);
                                                                    return newG;
                                                                }
                                                            })
                                                        }}
                                                        style={{ padding: '3px' }}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })}
                                </>
                            )}
                        </div>
                    </>
                )
            })}
        </CustomDialogWrapper >
    );
};

export default SelectGroupsDialog;
