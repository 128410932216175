import { createSlice } from '@reduxjs/toolkit';

import initialState from './initialState';

export const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {
        setFromApi: (state, action) => {
            Object.assign(state, action.payload);
        }
    }
});

export const { setFromApi } = userSlice.actions;

export default userSlice.reducer;
