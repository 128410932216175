import { useCallback, useEffect, useState } from 'react';

import { ButtonProps } from '@material-ui/core/Button';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import CustomTextField from '../../../../../../common/components/CustomTextField/CustomTextField';
import api from '../../../../../../common/utils/api';
import snackbar from '../../../../../../common/utils/snackbar';

interface Props {
    registrationId: string | null;
    closeDialog: (shouldRefresh?: boolean) => void;
}

const SetCostDialog = (props: Props) => {
    const { registrationId, closeDialog } = props;

    const open = registrationId !== null;

    const [isLoading, setIsLoading] = useState(false);
    const [cost, setCost] = useState<string | number>('0.0');

    useEffect(() => {
        if (!registrationId || !open) return;

        setIsLoading(true);
        api.request('/admin/registrations/' + registrationId + '/payments').then(res => {
            setCost(res.cost.toFixed(2));
            setIsLoading(false);
        });
    }, [open, registrationId]);

    const saveData = useCallback(() => {
        setIsLoading(true);
        api.request('/admin/registrations/' + registrationId + '/cost', 'PUT', { cost }).then(() => {
            snackbar.success('Costo modificato con successo!');
            closeDialog(true);
        }).catch(() => {
            snackbar.error('Si è verificato un errore durante il salvataggio.');
        }).finally(() => {
            setIsLoading(false);
        });
    }, [cost, closeDialog, registrationId]);

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        },
        {
            children: 'Salva',
            color: 'primary',
            onClick: () => saveData()
        },
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Modifica costo iscrizione'} buttons={buttons} isLoading={isLoading} maxWidth='xs' fullWidth>
            <CustomTextField
                label='Nuovo costo'
                variant='outlined'
                value={cost}
                keepMounted={true}
                onChange={(e: any) => {
                    setCost(e.target.value);
                }}
                onBlur={() => {
                    setCost((c: any) => {
                        return Math.max(((typeof c === 'string') ? parseFloat(c.replaceAll(',', '.')) : c), 0).toFixed(2);
                    });
                }}
                disabled={isLoading}
            />
        </CustomDialogWrapper>
    );
};

export default SetCostDialog;
