import { TableCell } from "@material-ui/core";
import styled from "styled-components";

export const StyledNotesTableCell = styled(TableCell)`
    & p:first-of-type {
        margin-top: 0;
    }
    & p:last-of-type {
        margin-bottom: 0;
    }
`;