import { createSlice } from '@reduxjs/toolkit';

import initialState from './initialState';

export const routingSlice = createSlice({
    name: 'routingSlice',
    initialState,
    reducers: {
        setIsIframe: (state, action) => {
            if (state.isIframe) {
                return state;
            }

            return {
                ...state,
                isIframe: action.payload
            };
        },
        setInitialPath: (state, action) => {
            if (state.initialPath !== undefined) {
                return state;
            }

            return {
                ...state,
                initialPath: action.payload
            };
        }
    }
});

export const { setIsIframe, setInitialPath } = routingSlice.actions;

export default routingSlice.reducer;
