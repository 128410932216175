import TextField from '@material-ui/core/TextField';
import styled from 'styled-components/macro';

export const FormContainer = styled.div`
    max-width: 700px;
    margin: 8px auto;
`;

export const StyledTextField = styled(TextField)`
    width: 100%;
    margin-bottom: 12px;
`;
