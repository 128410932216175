import { ButtonProps } from '@material-ui/core/Button';
import 'moment/locale/it';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    subject: string;
    content: string;
}

const EmailContentDialog = (props: Props) => {
    const { isOpen, onClose, subject, content } = props;

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => onClose()
        },
    ];

    return (
        <>
            <CustomDialogWrapper open={isOpen} onClose={() => onClose()} title={subject} buttons={buttons} maxWidth='md' fullWidth >
                <div dangerouslySetInnerHTML={{ __html: content }} />
            </CustomDialogWrapper>
        </>
    );
};

export default EmailContentDialog;
