import { useState } from 'react';

import Link from '@material-ui/core/Link';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { downloadBlob } from 'download.js';

import api from '../../../../../../../../../../common/utils/api';
import { FormAsset as FormAssetType } from '../../../../types';

interface Props {
    asset: FormAssetType;
}

const FormAsset = (props: Props) => {
    const { asset } = props;

    const [isDownloading, setIsDownloading] = useState(false);

    const download = (e: React.SyntheticEvent) => {
        if (isDownloading) return;

        setIsDownloading(true);
        api.request('/assets/private/' + asset.id, 'GET', undefined, true, 'blob').then(res => {
            downloadBlob(res.headers.get('X-File-Name'), res.content);
        });

        e.preventDefault();
        return false;
    };

    return (
        <div style={{margin: '8px 0', marginLeft: '-4px', display: 'flex', alignItems: 'center'}}>
            <AttachFileIcon />

            <Link href='#' onClick={download}>
                {asset?.originalName ?? ''}
            </Link>
        </div>
    );
};

export default FormAsset;
