import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';

import useSearchParams from '../../common/hooks/useSearchParams/useSearchParams';
import AdminApp from '../../entryPoints/admin/AdminApp';
import PublicApp from '../../entryPoints/public/PublicApp';
import { setInitialPath, setIsIframe } from '../../redux/routing/routingSlice';

const RedirectToAdmin = () => {
    useEffect(() => {
        const path = window.location.pathname;
        window.location.href = 'https://admin.epuntos.it' + (path.substr(path.substr(1).indexOf('/') + 1))
    }, []);

    return null;
}

const MainRouter = () => {
    const { pathname } = useLocation();
    const { iframe } = useSearchParams();

    const dispatch = useDispatch();

    const isAdminHost = window.location.host === 'admin.epuntos.it';
    const isProductionMainDomain = window.location.host === 'epuntos.it' && window.location.pathname.startsWith('/crm');

    useEffect(() => {
        dispatch(setIsIframe(iframe !== undefined));
        dispatch(setInitialPath(pathname));
    }, [dispatch, iframe, pathname]);

    if (isAdminHost) {
        return (
            <Switch>
                <Route path='*' component={AdminApp} />
            </Switch>
        )
    } else if (isProductionMainDomain) {
        return (
            <Switch>
                <Route path='/admin' component={RedirectToAdmin} />
                <Route path='*' component={PublicApp} />
            </Switch>
        )
    }

    return (
        <Switch>
            <Route path='/admin' component={AdminApp} />
            <Route path='*' component={PublicApp} />
        </Switch>
    );
};

export default MainRouter;
