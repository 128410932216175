import { useCallback, useMemo } from 'react';

import Typography from '@material-ui/core/Typography';
import _ from 'lodash';

import useGenericErrorDialog from '../../../../../../common/hooks/useGenericErrorDialog/useGenericErrorDialog';
import { DialogOption, useMessageDialog } from '../../../../../../common/hooks/useMessageDialog/useMessageDialog';
import api from '../../../../../../common/utils/api';
import GenericObject from '../../../../../../typesAdditional/GenericObject';
import useFields, { Props as FieldsHookProps } from '../../components/GenericPersonForm/hooks/useFields';

export interface Props extends FieldsHookProps {
    courseId: string;
    messageCallback?: (params: DialogOption) => void;
}

const useValidatePersonStep = (props: Props) => {
    const { courseId, messageCallback, ...fieldsHookProps } = props;
    const { data, formPrefix } = fieldsHookProps;

    const [showMessageDialog] = useMessageDialog();
    const showGenericErrorDialog = useGenericErrorDialog();

    const fields = useFields(fieldsHookProps);

    const showMessage = useMemo(() => {
        if (messageCallback) {
            return messageCallback;
        }

        return showMessageDialog;
    }, [messageCallback, showMessageDialog]);

    const handleApiError = useCallback((res: GenericObject) => {
        if (res?.errorDesc) {
            const { errorDesc } = res;

            showMessage({
                title: 'Errore validazione dati',
                message: errorDesc
            });
        } else if (Object.keys(res).length > 0 && res[Object.keys(res)[0]][0]) {
            showMessage({
                title: 'Errore validazione dati',
                message: res[Object.keys(res)[0]]
            });
        } else {
            showGenericErrorDialog(res);
        }
    }, [showMessage, showGenericErrorDialog]);

    const onSubmit = useCallback(() => {
        const missingFields = fields.filter(field => {
            const value = _.get(data, formPrefix + '.' + field.name);

            if (!value || value.length < 0) {
                return true;
            }

            return false;
        });

        const invalidFields = fields.filter(field => {
            const { valid } = field;

            return valid === false;
        });

        if (missingFields.length > 0 || invalidFields.length > 0) {
            const missingError = missingFields.length > 0;

            showMessage({
                title: missingError ? 'Campi mancanti' : 'Campi errati',
                message: (
                    <Typography>
                        I seguenti campi richiesti non sono stati compilati{!missingError && ' correttamente'}:
                        <ul>
                            {(missingError ? missingFields : invalidFields).map(field => {
                                return <li>{field.label}</li>;
                            })}
                        </ul>
                    </Typography>
                )
            });

            return Promise.reject();
        }

        return new Promise((resolve, reject) => {
            const prefix = (window.location.pathname.startsWith('/admin/registrations/new') || (window.location.host.startsWith('admin.epuntos.it') && window.location.pathname.startsWith('/registrations/new'))) ? '/admin/courses/' : (
                (window.location.pathname.startsWith('/admin') || window.location.host.startsWith('admin.epuntos.it')) ? '/admin/basic_courses/' : '/courses/'
            );
            api.request(prefix + courseId + '/validate_data/' + formPrefix, 'POST', data[formPrefix], false).then((res: GenericObject) => {
                if (res.result === 'ok') {
                    resolve(res);
                } else {
                    handleApiError(res);
                    reject(res);
                }
            }).catch((res) => {
                handleApiError(res);
                reject(res);
            });
        });
    }, [courseId, data, fields, showMessage, formPrefix, handleApiError]);

    return onSubmit;
};

export default useValidatePersonStep;
