import { useCallback, useEffect, useState } from 'react';

import { ButtonProps } from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';

import CustomDialogWrapper from '../../../../../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import CustomTextField from '../../../../../../../../../../common/components/CustomTextField/CustomTextField';
import api from '../../../../../../../../../../common/utils/api';
import snackbar from '../../../../../../../../../../common/utils/snackbar';

interface Props {
    open: boolean;
    closeDialog: (refresh?: boolean) => void;
    documentId: string;
}

const UnapproveDialog = (props: Props) => {
    const { open, closeDialog, documentId } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [notifyUser, setNotifyUser] = useState(false);
    const [reason, setReason] = useState('');

    useEffect(() => {
        if (!open) return;
        setIsLoading(false);
        setNotifyUser(false);
        setReason('');
    }, [open]);

    const saveData = useCallback(() => {
        setIsLoading(true);
        api.request('/admin/documents/' + documentId + '/unapprove', 'POST', { notify: notifyUser, reason }).then(res => {
            snackbar.success('Documento rifiutato!');
            closeDialog(true);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [closeDialog, notifyUser, reason, documentId]);

    const buttons: ButtonProps[] = [
        {
            children: 'Annulla',
            color: 'primary',
            onClick: () => closeDialog(false)
        },
        {
            children: 'Conferma',
            color: 'primary',
            onClick: () => saveData()
        }
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog(false)} title={'Rifiuta documento'} buttons={buttons} isLoading={isLoading} maxWidth='md'>
            <Typography variant='body1'>
                Sei sicuro di voler rifiutare il documento selezionato? <br/>
                Il documento non sarà più visible. L'operazione non è reversibile.
            </Typography>

            <FormControlLabel
                control={
                    <Checkbox
                        color='primary'
                        style={{padding: '4px'}}
                        checked={notifyUser}
                        onChange={(e) => setNotifyUser(e.target.checked)}
                    />
                }
                label={'Notifica l\'utente'}
                style={{margin: '12px 0 6px'}}
                disabled={isLoading}
            />

            {notifyUser && (
                <CustomTextField
                    label='Motivazione rifiuto'
                    variant='outlined'
                    value={reason}
                    keepMounted={true}
                    onChange={(e: any) => {
                        setReason(e.target.value);
                    }}
                    disabled={isLoading}
                    rows={2}
                    multiline
                />
            )}

        </CustomDialogWrapper>
    );
};

export default UnapproveDialog;
