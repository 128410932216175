import { useEffect, useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

import api from '../../../../../../common/utils/api';

const useParticipantDataValidator = (courseId: string, data: any): any => {
    const { participant } = data;

    const [validationResult, setValidationResult] = useState({ isLoading: false });

    const apiRequestId = useRef('');

    useEffect(() => {
        if (!courseId) return;

        const prefix = (window.location.pathname.startsWith('/admin/registrations/new') || (window.location.host.startsWith('admin.epuntos.it') && window.location.pathname.startsWith('/registrations/new'))) ? '/admin/courses/' : (
            (window.location.pathname.startsWith('/admin') || window.location.host.startsWith('admin.epuntos.it')) ? '/admin/basic_courses/' : '/courses/'
        );

        setValidationResult(oldResult => {
            return {
                ...oldResult,
                isLoading: true
            };
        })

        const timeout = setTimeout(() => {
            const currentRequestId = uuidv4();
            apiRequestId.current = currentRequestId;

            api.request(prefix + courseId + '/validate_data/tax_code', 'POST', participant, false).then(res => {
                if (currentRequestId !== apiRequestId.current) return;

                setValidationResult(oldResult => {
                    return {
                        ...oldResult,
                        taxCode: res,
                        isLoading: false
                    };
                });
            }).catch(() => {
                if (currentRequestId !== apiRequestId.current) return;

                setValidationResult(oldResult => {
                    return {
                        ...oldResult,
                        taxCode: null,
                        isLoading: false
                    };
                });
            });
        }, 250);

        return () => clearTimeout(timeout);
    }, [courseId, participant, participant?.name, participant?.surname, participant?.tax_code, participant?.date_of_birth]);

    return validationResult;
};

export default useParticipantDataValidator;
