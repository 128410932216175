import * as Sentry from '@sentry/react';

export const dataLayerPush = (data: any) => {
    // tslint:disable no-console
    const realData = {
        browser_id: localStorage.getItem('browser_id'),
        user_id: sessionStorage.getItem('user_id'),
        ...data
    };

    console.log('Trying to push dataLayer');
    console.log(realData);

    if (window.location.host.startsWith('localhost')) {
        console.log('Skipping push in local environment');
        return;
    }

    try {
        // @ts-ignore
        window?.dataLayer?.push(realData);
        console.log('DataLayer push success.');
    } catch (e) {
        Sentry.captureException(e);
        console.log('DataLayer push failed.');
        console.log(e);
    }
    // tslint:enable no-console
};
