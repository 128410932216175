import styled from 'styled-components/macro';

export const ExternalContainer = styled.div<{ appbarVisible: boolean }>`
    padding-top: ${p => p.appbarVisible ? '64px' : '0'};
    height: 100%;
`;

export const Container = styled.div`
    padding: 12px;
    width: 100%;
    overflow: auto;
    height: 100%;
`;
