import styled from 'styled-components/macro';

export const Container = styled.div`
    height: 100vh;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
`;

export const Logo = styled.img`
    position: absolute;
    height: 120px;
    animation: fadeIn 2s infinite alternate;

    @keyframes fadeIn {
        from { opacity: 0.6; }
    }
`;

export const Spinner = styled.div`
    width: 12vmax;
    height: 12vmax;
    border-right: 4px solid #2e7d32;
    // border-right: 4px solid #93c01f;
    border-radius: 100%;
    animation: spinLeft 1500ms linear infinite;

    @keyframes spinLeft {
        from {transform:rotate(0deg);}
        to {transform:rotate(720deg);}
    }

    @keyframes spinRight {
        from {transform:rotate(360deg);}
        to {transform:rotate(0deg);}
    }
`;
