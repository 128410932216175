import { ButtonProps } from '@material-ui/core/Button';

import CustomDialogWrapper from '../../../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import GenericObject from '../../../../../../../../typesAdditional/GenericObject';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import moment from 'moment';

const TYPES_DESC = {
    registration_created: 'costo iniziale',
    updated_cost: 'costo modificato manualmente',
    updated_registration: 'modifica iscrizione',
    add_voucher: 'aggiunta voucher',
    system_calculation: 'ricalcolo quota',
    unknown: 'n.d.'
};

interface Props {
    open: boolean;
    closeDialog: () => void;
    changelog: GenericObject[];
}

const CostChangelogDialog = (props: Props) => {
    const { open, closeDialog, changelog } = props;

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        },
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Storico modifiche costo'} buttons={buttons} maxWidth='sm' fullWidth>
            {changelog.map((row: any) => {
                return (
                    <Card variant='outlined' style={{ marginBottom: '12px' }}>
                        <CardContent style={{ paddingTop: '12px', paddingBottom: '12px' }}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ flex: 1, display: 'flex', alignItems: 'center', fontSize: '1.75em' }}>
                                        € {parseFloat(row.cost).toFixed(2)}
                                    </div>
                                    <div style={{ flex: 1, display: 'flex', alignItems: 'center', marginTop: '6px', marginBottom: '4px' }}>
                                        {TYPES_DESC[row.type as keyof (typeof TYPES_DESC)]}
                                    </div>
                                    <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                                        {moment(row.datetime).format('DD/MM/YYYY H:mm:ss')}
                                    </div>
                                </div>
                                <div style={{ flexGrow: 1 }} />
                                <div style={{ textAlign: 'right', alignItems: 'center', display: 'flex' }}>
                                    <div>
                                        {row.user && (
                                            <>
                                                <p style={{ margin: 0, marginBottom: '4px' }}><b>utente</b></p>
                                                {row.user?.person?.name ?? ''} {row.user?.person?.surname ?? ''}<br />
                                                {row.user.email}
                                            </>
                                        )}

                                        {row.superUser && (
                                            <>
                                                <p style={{ margin: 0, marginBottom: '4px' }}><b>{row.superUser.administrator ? 'amministratore' : 'responsabile'}</b></p>
                                                {row.superUser.name}<br />
                                                {row.superUser.email}
                                            </>
                                        )}

                                        {!(row.user || row.superUser) && (
                                            <>
                                                <b><i>CRM</i></b>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                );
            })}
        </CustomDialogWrapper>
    );
};

export default CostChangelogDialog;
