import TextField from '@material-ui/core/TextField';
import styled from 'styled-components/macro';

export const StyledTextField = styled(TextField)`
    width: 100%;
    margin-bottom: 12px;

    & li {
        white-space: break-spaces;
    }
`;
