import { FieldDefinition } from '../types';

export type FieldName = 'phone' | 'email';

export const phoneField: FieldDefinition<FieldName> = {
    name: 'phone',
    label: 'recapito telefonico'
};

export const emailField: FieldDefinition<FieldName> = {
    name: 'email',
    label: 'indirizzo email'
};

const contactDataFields: FieldDefinition<FieldName>[] = [
    phoneField,
    emailField
];

export default contactDataFields;
