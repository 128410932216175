import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import { ReduxState } from '../../../../../../../redux/types';
import RoutesList from '../RoutesList';
import { AdditionalProps, FinalRoute } from '../types';
import { IconContainer, StyledIcon } from './styled';

interface Props {
    route: FinalRoute;
    openedSection: string;
    additionalProps: AdditionalProps;
    handleClick: (e: React.UIEvent, route: FinalRoute) => void;
    depth: number;
}

const RouteItem = (props: Props) => {
    const { route, openedSection, additionalProps, handleClick, depth } = props;

    const userInfo = useSelector((state: ReduxState) => state.user);

    const exactPath = route.exactPathSidebar && route.exactPath;

    if (!route.showInSidebar || (route.needAdmin && !userInfo.administrator)) {
        return null;
    }

    if ((route.path === '/summer-availability' || route.path === '/summer-schedule') && (!userInfo.allowSummerAvailabilities2024)) {
        return null;
    }

    if (route.path.startsWith('/winter-schedule') && ((userInfo.administrator && userInfo.canManageStaff) || !userInfo.winterEnabled) && userInfo.email !== 'ecappellini@epuntos.it') {
        return null;
    }

    if ((route.path === '/summer-schedule' || route.path.startsWith('/payouts-report')) && (userInfo.administrator && userInfo.canManageStaff)) {
        return null;
    }

    if (route.path === '/summer-availability-admin' && userInfo.allowSummerAvailabilities2024) {
        return null;
    }

    if ((route.path === '/staff' || route.path === '/staff-winter') && (!userInfo.canManageStaff)) {
        return null;
    }

    if (route.emailWhiteList && !route.emailWhiteList.includes(userInfo.email)) {
        return null;
    }

    return (
        <>
            <ListItem
                key={route.path}
                to={(window.location.host.startsWith('admin.epuntos.it') ? '' : '/admin') + route.path}
                exact={exactPath || !route.subRoutes}
                component={NavLink}
                activeClassName='Mui-selected'
                onClick={(e: React.UIEvent) => handleClick(e, route)}
                style={{ paddingLeft: (16 * depth + 16) + 'px' }}
                button
            >
                <>
                    {depth === 0 ? (
                        <IconContainer>
                            {route.icon && <StyledIcon icon={route.icon} size='2x' color='#1c9100' />}
                        </IconContainer>
                    ) : <div style={{ width: '40px' }} />}

                    <ListItemText primary={route.name ?? ''} />

                    {route.subRoutes && (
                        openedSection === route.guid ? <ExpandLess /> : <ExpandMore />
                    )}
                </>
            </ListItem>

            {route.subRoutes && (
                <Collapse in={openedSection === route.guid} timeout='auto' unmountOnExit>
                    <RoutesList routes={route.subRoutes} additionalProps={additionalProps} depth={depth + 1} parentGuid={route.guid} />
                </Collapse>
            )}
        </>
    );
};

export default RouteItem;
