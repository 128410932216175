import { Button } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import styled from "styled-components";

export const StyledAutocomplete = styled(Autocomplete)`
    flex: 1;
    margin-top: 4px;
    margin-bottom: 0px;

    .MuiAutocomplete-inputRoot {
        text-align: center;
        width: 78px;
        padding-right: 6px !important;
    }

    .MuiAutocomplete-inputRoot input {
        text-align: center
    }

    .MuiAutocomplete-endAdornment {
        display: none;
    }
`;

export const StyledButton = styled(Button)`
    min-width: 0 !important;
    padding: 6px !important;
    height: fit-content !important;
    flex-grow: 0 !important;
    margin: auto 6px !important; 
`;