import { useCallback, useMemo, useState } from 'react';

import { TextFieldProps } from '@material-ui/core/TextField';
import _ from 'lodash';

import GenericObject from '../../../typesAdditional/GenericObject';
import { StyledTextField } from './styled';

export type Props = TextFieldProps & {
    path?: string;
    data?: GenericObject;
    dataOnChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: string) => void;
    keepMounted?: boolean;
};

const CustomTextField = (props: Props) => {
    const { path, data, error, onChange, onBlur, dataOnChange, value, keepMounted, ...otherProps } = props;

    const [isUntouched, setIsUntouched] = useState(true);
    const [isBlurUntouched, setIsBlurUntouched] = useState(true);

    const internalOnChange = useCallback((event) => {
        if (dataOnChange) {
            dataOnChange(event, path ?? '');
        } else if (onChange) {
            onChange(event);
        }

        setIsUntouched(false);
    }, [path, dataOnChange, onChange]);

    const handleOnBlur = useCallback((event) => {
        setIsBlurUntouched(false);

        if (onBlur) {
            onBlur(event);
        }
    }, [onBlur]);

    const realValue = useMemo(() => {
        if (!path || !data) {
            return value;
        }

        return _.get(data, path) ?? (value ?? '');
    }, [data, path, value]);

    const key = (typeof value === 'string' && !keepMounted) ? value : '';

    return (
        <StyledTextField {...otherProps} key={key} value={realValue} onChange={internalOnChange} error={error && !isUntouched && !isBlurUntouched} onBlur={handleOnBlur} />
    );
};

export default CustomTextField;
