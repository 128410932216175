import useLoader from '../useLoader/useLoader';

const useExternalLoader = (initialIsLoading?: boolean) => {
    return useLoader({
        initialIsLoading,
        type: 'external'
    });
};

export default useExternalLoader;
