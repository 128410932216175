import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useWindowSize } from 'react-use';

import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import _ from 'lodash';

import useInternalLoader from '../../../../common/hooks/useInternalLoader/useExternalLoader';
import useSearchParams from '../../../../common/hooks/useSearchParams/useSearchParams';
import api from '../../../../common/utils/api';
import { dataLayerPush } from '../../../../common/utils/gtm';
import { ReduxState } from '../../../../redux/types';
import GenericObject from '../../../../typesAdditional/GenericObject';
import Header from './components/Header/Header';
import MobileStepper from './components/MobileStepper/MobileStepper';
import useFirstParentDataValidator from './hooks/useFirstParentDataValidator/useFirstParentDataValidator';
import useParticipantDataValidator from './hooks/useParticipantDataValidator/useParticipantDataValidator';
import RegistrationStep1 from './steps/RegistrationStep1/RegistrationStep1';
import { PersonForRegistration } from './steps/RegistrationStep1/types';
import RegistrationStep2 from './steps/RegistrationStep2/RegistrationStep2';
import RegistrationStep3 from './steps/RegistrationStep3/RegistrationStep3';
import RegistrationStep4 from './steps/RegistrationStep4/RegistrationStep4';
import RegistrationStep6 from './steps/RegistrationStep6/RegistrationStep6';
import RegistrationStep7 from './steps/RegistrationStep7/RegistrationStep7';
import RegistrationStepVoucher from './steps/RegistrationStepVoucher/RegistrationStepVoucher';
import { FormContainer } from './styled';
import { Link } from '@material-ui/core';

interface Props {
    welfareData?: any;
    resetWelfareData?: () => void;
}

const RegistrationPage = ({ welfareData, resetWelfareData }: Props) => {
    const { id: courseId } = useParams<{ id: string }>();

    const { isIframe } = useSelector((state: ReduxState) => state.routing);
    const { town: paramTownLegalId } = useSearchParams();
    const { width } = useWindowSize();

    const setIsLoading = useInternalLoader();
    const history = useHistory();

    const [courseData, setCourseData] = useState<any>(null);

    const [registrationData, setRegistrationData] = useState<any>({});
    const [isParticipantMinor, setIsParticipantMinor] = useState(true);

    const participantValidationResult = useParticipantDataValidator(courseId, registrationData);
    const firstParentValidationResult = useFirstParentDataValidator(courseId, registrationData);

    const [currentStep, setCurrentStep] = useState(0);

    const [oldDateOfBirth, setOldDateOfBirth] = useState('');
    const [oldParentDateOfBirth, setOldParentDateOfBirth] = useState('');

    const [selectedSessions, setSelectedSessions] = useState<any[]>([]);
    const [selectedLocationId, setSelectedLocationId] = useState<string>('');
    const [suggestedSessions, setSuggestedSessions] = useState<any[]>([]);

    // TODO: 10 just to be sure, less is enaugh
    const [gtmPushedSteps, setGtmPushedStep] = useState<boolean[]>(Array(10).fill(false));

    const preselectedLocation = courseData?.locations?.find((t: any) => (t.town.legalId === paramTownLegalId || (t.townDistinguishing && t.townDistinguishing === paramTownLegalId)) && !t.softHidden);
    const isLocationInvalid = paramTownLegalId && !preselectedLocation;

    const resetRegistrationData = useCallback(() => {
        setRegistrationData({});
        setIsParticipantMinor(true);
        setCurrentStep(0);
        setOldDateOfBirth('');
        setOldParentDateOfBirth('');
        setSelectedSessions([]);
        setSelectedLocationId('');
        resetWelfareData && resetWelfareData();
    }, [resetWelfareData]);

    useEffect(() => {
        api.request('/courses/' + courseId).then(res => {
            setCourseData(res);
            setIsParticipantMinor(res.minimumAge < 18);
        }).catch(() => {
            history.push('/');
        }).finally(() => {
            setIsLoading(false);
        });
    }, [setIsLoading, courseId, history]);

    const validationResult = {
        participant: participantValidationResult,
        parent: firstParentValidationResult
    };

    const title = courseData?.name;

    const rawOnChange = useCallback((name: string, value: any) => {
        setRegistrationData((d: any) => {
            const newObj = { ...d };
            _.set(newObj, name, value);
            return newObj;
        });
    }, []);

    const onChange = useCallback((e: any, name: string) => {
        rawOnChange(name, e.target.value);
    }, [rawOnChange]);

    const gtmStepPush = useCallback((step: number, data: any) => {
        if (gtmPushedSteps[step]) return;

        setGtmPushedStep(prevState => {
            const newState = [...prevState];
            newState[step] = true;
            return newState;
        });

        dataLayerPush({
            event: 'subscription_step' + step,
            ...data
        });
    }, [gtmPushedSteps]);

    useEffect(() => {
        const shouldUpdate = (registrationData?.participant?.date_of_birth ?? '') === oldDateOfBirth;
        const newComputedDateOfBirth = participantValidationResult?.taxCode?.dateOfBirth;

        if (newComputedDateOfBirth && shouldUpdate) {
            setOldDateOfBirth(newComputedDateOfBirth);
            rawOnChange('participant.date_of_birth', newComputedDateOfBirth);
        }
    }, [rawOnChange, oldDateOfBirth, participantValidationResult?.taxCode?.dateOfBirth, registrationData?.participant?.date_of_birth]);

    useEffect(() => {
        const shouldUpdate = (registrationData?.parent?.date_of_birth ?? '') === oldParentDateOfBirth;
        const newComputedDateOfBirth = firstParentValidationResult?.taxCode?.dateOfBirth;

        if (newComputedDateOfBirth && shouldUpdate) {
            setOldParentDateOfBirth(newComputedDateOfBirth);
            rawOnChange('parent.date_of_birth', newComputedDateOfBirth);
        }
    }, [rawOnChange, oldParentDateOfBirth, firstParentValidationResult?.taxCode?.dateOfBirth, registrationData?.parent?.date_of_birth]);

    useEffect(() => {
        if (participantValidationResult?.taxCode?.isMinor !== undefined) {
            setIsParticipantMinor(participantValidationResult.taxCode.isMinor);
        }
    }, [participantValidationResult]);

    const steps = useMemo(() => {
        return [
            {
                name: 'Dati partecipante',
            },
            {
                name: 'Dati genitore 1',
                hidden: !isParticipantMinor
            },
            {
                name: 'Dati genitore 2',
                hidden: !isParticipantMinor || (courseData?.id === '7030abec-7234-4939-a154-336743ed9f92')
            },
            {
                name: 'Documenti e certificati',
                hidden: !((courseData?.documentTypes ?? []).filter((x: any) => !x.isFacultative).length) && courseData?.season?.id !== '7866fe82-9a92-11ef-a671-bc2411550d1f'
            },
            {
                name: 'Dettagli iscrizione'
            },
            {
                name: 'Codice sconto',
                hidden: !(courseData?.showDiscountCodeStep) || (welfareData && welfareData.valid)
            },
            {
                name: 'Conferma iscrizione'
            }
        ];
    }, [courseData, isParticipantMinor, welfareData]);

    const decrementStep = () => {
        for (let i = currentStep - 1; i >= 0; i--) {
            if (!steps[i].hidden) {
                setCurrentStep(i);
                return;
            }
        }
    };

    const incrementStep = () => {
        for (let i = currentStep + 1; i < steps.length; i++) {
            if (!steps[i].hidden) {
                setCurrentStep(i);
                return;
            }
        }
    };

    const linearStepNumber = useMemo(() => {
        const selectedStep = steps[currentStep];
        const visibleSteps = steps.filter(step => !step.hidden);

        return visibleSteps.findIndex(step => step === selectedStep);
    }, [currentStep, steps]);

    const linearStepNames = steps.filter(step => {
        return !step.hidden;
    }).map(step => {
        return step.name;
    });

    const shouldSelectSchoolGrade = courseData?.minimumSchoolGrade || courseData?.maximumSchoolGrade;
    const schoolClasses = courseData?.schoolClasses ?? [];

    const commonStepProps = {
        courseId,
        courseData,
        validationResult,
        onChange,
        rawOnChange,
        setIsParticipantMinor,
        selectedLocationId,
        preselectedLocation,
        gtmStepPush,
        shouldSelectSchoolGrade,
        schoolClasses,
        data: registrationData,
        prevStep: decrementStep,
        nextStep: incrementStep,
        welfareData
    };

    // THIS STUFF IN AN HOOK? Moved from RegistrationStep1
    const setIsLoadingPeople = useInternalLoader();

    const [personList, setPersonList] = useState<PersonForRegistration[]>([]);

    useEffect(() => {
        api.request('/courses/' + courseId + '/people').then(res => {
            setPersonList(res);
        }).finally(() => {
            setIsLoadingPeople(false);
        });
    }, [courseId, setIsLoadingPeople]);
    // END HOOK STUFF

    // STEP 4 STUFF
    const [rawDocumentAssets, setRawDocumentAssets] = useState<GenericObject>({});
    // END STEP 4 STUFF

    // STEP 6 STUFF
    const [questionResponse, setQuestionResponse] = useState('');
    // END STEP 7 STUFF

    // STEP 7 STUFF
    const [givenConsents, setGivenConsents] = useState<string[]>([]);
    // END STEP 7 STUFF

    if (isLocationInvalid) {
        return (
            <div>
                <Paper elevation={isIframe ? 0 : 3} style={{ padding: '8px', margin: width > 700 ? '6px 24px' : '2px', textAlign: 'center' }}>
                    <p style={{ fontSize: '1.65em', marginTop: '4px', marginBottom: 0 }}>
                        Siamo spiacenti ma al momento non è possibile iscriversi al corso selezionato.
                    </p>
                    <p style={{ fontSize: '1.15em', marginBottom: '6px' }}>
                        Se necessitate di maggiori informazioni potete contattarci a <Link href='mailto:info@epuntos.it'>info@epuntos.it</Link>.
                    </p>
                </Paper>
            </div>
        );
    }

    return (
        <div>
            <Header title={title} preselectedLocation={preselectedLocation} />

            <Paper elevation={isIframe ? 0 : 3} style={{ padding: '8px', margin: width > 700 ? '6px 24px' : '2px' }}>
                {width > 900 ? (
                    <Stepper alternativeLabel activeStep={linearStepNumber} style={{ maxWidth: '1200px', margin: '0 auto' }}>
                        {linearStepNames.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                ) : (
                    <MobileStepper activeStep={linearStepNumber} steps={linearStepNames} />
                )}

                <FormContainer>
                    {currentStep === 0 && <RegistrationStep1 {...commonStepProps} personList={personList} isParticipantMinor={isParticipantMinor} />}
                    {currentStep === 1 && <RegistrationStep2 {...commonStepProps} />}
                    {currentStep === 2 && <RegistrationStep3 {...commonStepProps} />}
                    {currentStep === 3 && <RegistrationStep4 {...commonStepProps} documentAssets={rawDocumentAssets} setDocumentAssets={setRawDocumentAssets} />}
                    {currentStep === 4 && <RegistrationStep6 {...commonStepProps} initialSelectedSessions={selectedSessions} initialSelectedLocationId={selectedLocationId} setSelectedLocationId={setSelectedLocationId} setSelectedSessions={setSelectedSessions} setSuggestedSessions={setSuggestedSessions} questionResponse={questionResponse} setQuestionResponse={setQuestionResponse} />}
                    {currentStep === 5 && <RegistrationStepVoucher {...commonStepProps} initialSelectedSessions={selectedSessions} initialSelectedLocationId={selectedLocationId} setSelectedLocationId={setSelectedLocationId} setSelectedSessions={setSelectedSessions} suggestedSessions={suggestedSessions} setSuggestedSessions={setSuggestedSessions} questionResponse={questionResponse} setQuestionResponse={setQuestionResponse} />}
                    {currentStep === 6 && <RegistrationStep7 {...commonStepProps} selectedSessions={selectedSessions} suggestedSessions={suggestedSessions} resetRegistrationData={resetRegistrationData} givenConsents={givenConsents} setGivenConsents={setGivenConsents} />}
                </FormContainer>
            </Paper>
        </div>
    );
};

export default RegistrationPage;
