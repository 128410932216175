import Button, { ButtonProps } from "@material-ui/core/Button";
import CustomDialogWrapper from "../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper";
import { useCallback, useEffect, useState } from "react";
import GenericObject from "../../../../../../typesAdditional/GenericObject";
import api from "../../../../../../common/utils/api";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import ApiEntitySelectDialog from "../../../../../../common/components/ApiEntitySelectDialog/ApiEntitySelectDialog";
import snackbar from "../../../../../../common/utils/snackbar";
import { useMessageDialog } from "../../../../../../common/hooks/useMessageDialog/useMessageDialog";
import AddTrainerDialog from "../../../trainersListPage/components/AddTrainerDialog/AddTrainerDialog";

interface Props {
    open: boolean;
    closeDialog: () => void;
    location?: GenericObject;
    refreshData: () => void;
}

const ResponsibleDialog = ({ open, closeDialog, location, refreshData: externalRefreshData }: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<GenericObject[]>([]);

    const [showMessageDialog, closeMessageDialog] = useMessageDialog();

    const [isAddResponsibleDialogOpen, setIsAddResponsibleDialogOpen] = useState(false);
    const [isNewResponsibleDialogOpen, setIsNewResponsibleDialogOpen] = useState(false);

    const refreshData = useCallback(() => {
        if (!location) return;

        setIsLoading(true);

        api.request('/admin/location_responsibles/' + location.id).then(res => {
            setData(res);
            setIsLoading(false);
        })
    }, [location]);

    useEffect(() => {
        if (!open) return;

        setData([]);
        refreshData();
    }, [open, refreshData]);

    const mapResponsible = useCallback((rawData: any) => {
        return {
            id: rawData.id,
            name: (rawData.lastName || rawData.firstName) ? ((rawData.lastName ?? '') + ' ' + (rawData.firstName ?? '')) : rawData.email,
            description: (rawData.lastName || rawData.firstName) ? rawData.email : undefined
        };
    }, []);

    const removeResponsible = useCallback((superUserId: string) => {
        showMessageDialog({
            title: 'Rimuovi responsabile',
            message: (
                <>
                    <p style={{ margin: '0px' }}>
                        Sei sicuro di voler rimuovere il responsabile selezionato?
                    </p>
                </>
            ),
            actions: [
                {
                    text: 'Annulla',
                    action: () => {
                        closeMessageDialog();
                    }
                },
                {
                    text: 'Conferma',
                    action: () => {
                        closeMessageDialog();

                        setIsLoading(true);

                        api.request('/admin/location_responsibles/' + location?.id + '/' + superUserId, 'DELETE').then(res => {
                            snackbar.success('Responsabile rimosso con successo!');
                            refreshData();
                            externalRefreshData();
                        });
                    }
                }
            ]
        });
    }, [location, closeMessageDialog, showMessageDialog, externalRefreshData, refreshData]);

    const buttons: ButtonProps[] = [
        {
            children: 'Aggiungi responsabile',
            color: 'primary',
            onClick: () => setIsAddResponsibleDialogOpen(true)
        },
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        }
    ];

    return (
        <>
            <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Responsabili - ' + location?.courseName + ' ' + location?.townName} buttons={buttons} isLoading={isLoading} maxWidth='md' fullWidth contentProps={{ style: { padding: '0 16px' } }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: '27%' }}>Cognome</TableCell>
                                <TableCell style={{ width: '27%' }}>Nome</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell style={{ width: '1px' }}>Azioni </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((r, idx) => {
                                return (
                                    <TableRow>
                                        <TableCell style={{ borderBottom: (idx + 1) === data.length ? 'none' : undefined }}>{r.lastName}</TableCell>
                                        <TableCell style={{ borderBottom: (idx + 1) === data.length ? 'none' : undefined }}>{r.firstName}</TableCell>
                                        <TableCell style={{ borderBottom: (idx + 1) === data.length ? 'none' : undefined }}>{r.email}</TableCell>
                                        <TableCell style={{ padding: '2px 16px', borderBottom: (idx + 1) === data.length ? 'none' : undefined }}>
                                            <Button disabled={isLoading || r.fromSchedule} color='secondary' variant='outlined' onClick={() => removeResponsible(r.id)}>Rimuovi</Button>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CustomDialogWrapper>

            <ApiEntitySelectDialog
                open={isAddResponsibleDialogOpen}
                onClose={(selectedId?: string) => {
                    setIsAddResponsibleDialogOpen(false);

                    if (selectedId) {
                        api.request('/admin/location_responsibles/' + location?.id + '/' + selectedId, 'PUT').then(res => {
                            snackbar.success('Responsabile aggiunto con successo!');
                            refreshData();
                            externalRefreshData();
                        });
                    }
                }}
                title='Aggiungi responsabile'
                url={'/admin/location_responsibles/' + location?.id + '/unassigned'}
                map={mapResponsible}
                noEntityFoundMessage='Nessun responsabile trovato'
                newEntityBtnText='Nuovo responsabile'
                newEntityBtnPosition='footer'
                onNewEntityClick={() => {
                    setIsAddResponsibleDialogOpen(false);
                    setIsNewResponsibleDialogOpen(true);
                }}
            />

            <AddTrainerDialog
                open={isNewResponsibleDialogOpen}
                closeDialog={(shouldRefresh?: boolean, superUserId?: string) => {
                    setIsNewResponsibleDialogOpen(false)

                    if (superUserId) {
                        api.request('/admin/location_responsibles/' + location?.id + '/' + superUserId, 'PUT').then(res => {
                            snackbar.success('Responsabile aggiunto con successo!');
                            refreshData();
                            externalRefreshData();
                        });
                    }
                }}
                overrideTitle='Nuovo responsabile'
                minimalForm
            />
        </>
    );
}

export default ResponsibleDialog;