import { createSlice } from '@reduxjs/toolkit';

import initialState from './initialState';

export const dialogsSlice = createSlice({
    name: 'dialogSlice',
    initialState,
    reducers: {
        add: (state, action) => {
            return [...state, action.payload];
        },
        remove: (state, action) => {
            return state.filter((item: string) => item !== action.payload);
        }
    }
});

export const { add, remove } = dialogsSlice.actions;

export default dialogsSlice.reducer;
