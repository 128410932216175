import { useCallback, useEffect, useState } from 'react';

import { ButtonProps } from '@material-ui/core/Button';
import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import GenericObject from '../../../../../../typesAdditional/GenericObject';
import { LinearProgress, List, ListItem, ListItemText } from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/it';
import AvailabilityTable from '../../../summerSchedulePage/components/FindAvailabilityDialog/components/AvailabilityTable/AvailabilityTable';
import api from '../../../../../../common/utils/api';
import _ from 'lodash';
import CustomTextField from '../../../../../../common/components/CustomTextField/CustomTextField';
import snackbar from '../../../../../../common/utils/snackbar';
import { useMessageDialog } from '../../../../../../common/hooks/useMessageDialog/useMessageDialog';
import SelectActionDialog from './compnents/SelectActionDialog/SelectActionDialog';

interface Props {
    open: boolean;
    closeDialog: (shouldRefresh?: boolean) => void;
    selectedTrainerId?: string;
}

const AvailabilityUpdateDialog = (props: Props) => {
    const { open, closeDialog, selectedTrainerId } = props;

    const [data, setData] = useState<GenericObject[]>([]);
    const [selectedWeek, setSelectedWeek] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [isSelectActionDialogOpen, setIsSelectActionDialogOpen] = useState(false);

    const [showMessageDialog, closeMessageDialog] = useMessageDialog();

    useEffect(() => {
        if (!selectedTrainerId || !open) return;

        setIsLoading(true);

        api.request('/admin/summer_availability/trainers/' + selectedTrainerId).then(res => {
            setData(res);
            setSelectedWeek(res[0]?.weekId ?? '');
            setIsLoading(false);
        });
    }, [open, selectedTrainerId]);

    const getWeekNotes = useCallback(() => {
        const weekData = data.find(x => x.weekId === selectedWeek);

        if (weekData) {
            return weekData.availability.notes ?? '';
        } else {
            return '';
        }
    }, [data, selectedWeek]);

    const setWeekNotes = useCallback((notes) => {
        setData((d: any) => {
            const weekData = _.cloneDeep(d.find((x: any) => x.weekId === selectedWeek));

            weekData.availability.notes = notes;

            return [
                ...d.filter((x: any) => x.weekId !== selectedWeek),
                weekData
            ];
        })
    }, [setData, selectedWeek]);

    const setDetailedAvailability = useCallback((day: number, dayTime: string, availability: string) => {
        setData(oldData => {
            const currentWeekData = _.cloneDeep(oldData.find((x: any) => x.weekId === selectedWeek)) ?? {};

            currentWeekData.availability.availabilities.find((x: any) => x.day === day && x.dayTime === dayTime).availability = availability;

            return [...oldData.filter((x: any) => x.weekId !== selectedWeek), currentWeekData];
        });
    }, [selectedWeek]);

    const saveData = useCallback((action?: string) => {
        const formattedData = data.map((week: any) => {
            return {
                week: week.startDate.substr(0, 10),
                notes: week.availability.notes,
                detailed: week.availability.availabilities.filter((daySegment: any) => daySegment.day < 6).map((daySegment: any) => {
                    return {
                        day: daySegment.day - 1,
                        dayTime: daySegment.dayTime === 'morning' ? 0 : 1,
                        availability: daySegment.availability
                    };
                })
            }
        })

        setIsLoading(true);

        api.request('/admin/summer_availability/trainers/' + selectedTrainerId, 'POST', { data: formattedData, action }).then(res => {
            setIsLoading(false);

            if (res.saved) {
                snackbar.success('Disponibilità salvata con successo!');
                closeDialog(true);
            } else if (res.status === 'action_required') {
                setIsSelectActionDialogOpen(true);
            } else {
                snackbar.error('Si è verificato un errore durante il salvataggio');
            }
        });
    }, [closeDialog, data, selectedTrainerId]);

    const saveDataConfirm = useCallback(() => {
        showMessageDialog({
            title: 'Modifiche in sospeso',
            message: (
                <>
                    <p style={{ margin: '0px', marginBottom: '6px' }}>
                        Ci sono delle modifiche in attesa di approvazione per l'istruttore selezionato. Proseguendo, le modifiche in sospeso verranno eliminate.
                    </p>
                    <p style={{ margin: '0px' }}>
                        Sei sicuro di voler proseguire?
                    </p>
                </>
            ),
            actions: [
                {
                    text: 'Annulla',
                    action: () => {
                        closeMessageDialog();
                    }
                },
                {
                    text: 'Conferma',
                    action: () => {
                        closeMessageDialog();
                        saveData();
                    }
                }
            ]
        });
    }, [saveData, showMessageDialog, closeMessageDialog]);

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        },
        {
            children: 'Salva',
            color: 'primary',
            onClick: () => {
                if (data[0] && data[0].pendingVariation) {
                    saveDataConfirm();
                } else {
                    saveData();
                }
            }
        }
    ];

    return (
        <>
            <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Modifica disponibilità'} isLoading={isLoading} buttons={buttons} maxWidth='xl' contentProps={{ style: { display: !isLoading ? 'flex' : undefined, width: '1027px' } }}>
                {(isLoading) ? (
                    <LinearProgress style={{ margin: '8px 0' }} />
                ) : (
                    <>
                        <div style={{ display: 'flex', marginLeft: '-24px', marginRight: '-24px', marginTop: '-16px', marginBottom: '-16px', minWidth: '900px', width: '1027px' }}>
                            <div style={{ flex: 0.25, padding: '17px 8px', borderRight: '1px solid rgba(0, 0, 0, 0.12)', overflow: 'auto' }}>
                                <List>
                                    {data.sort((week1: any, week2: any) => week1.startDate.localeCompare(week2.startDate)).map((week: any) => {
                                        const startDate = moment(week.startDate);
                                        const endDate = moment(week.startDate).add(4, 'days');

                                        return (
                                            <ListItem button selected={week.weekId === selectedWeek} onClick={() => setSelectedWeek(week.weekId)}>
                                                <ListItemText primary={startDate.format('D MMMM') + ' - ' + endDate.format('D MMMM')} />
                                            </ListItem>
                                        )
                                    })}
                                </List>
                            </div>
                            <div style={{ flex: 0.75, padding: '14px 8px' }}>
                                <AvailabilityTable
                                    selectedWeek={data.find((w: any) => w.weekId === selectedWeek) ?? {}}
                                    instructor={data.find((w: any) => w.weekId === selectedWeek)?.availability ?? []}
                                    setDetailedAvailability={setDetailedAvailability}
                                />

                                <CustomTextField
                                    label='Note aggiuntive'
                                    variant='outlined'
                                    size='small'
                                    style={{ margin: '18px 0', backgroundColor: '#ffffff' }}
                                    value={getWeekNotes()}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setWeekNotes(e.target.value)}
                                    keepMounted
                                />
                            </div>
                        </div>
                    </>
                )}
            </CustomDialogWrapper >

            <SelectActionDialog
                open={isSelectActionDialogOpen}
                closeDialog={() => setIsSelectActionDialogOpen(false)}
                saveData={(action: string) => {
                    setIsSelectActionDialogOpen(false);
                    saveData(action);
                }}
            />
        </>
    );
};

export default AvailabilityUpdateDialog; 