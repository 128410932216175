import { useCallback, useEffect, useMemo, useState } from "react";
import useInternalLoader from "../../../../common/hooks/useInternalLoader/useExternalLoader";
import api from "../../../../common/utils/api";
import GenericObject from "../../../../typesAdditional/GenericObject";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import Header from "./components/Header/Header";
import { PageContainer } from "./styled";
import moment from 'moment';
import 'moment/locale/it';
import useElementSize from '@custom-react-hooks/use-element-size';
import ChangeExtraWageDialog from "./components/ChangeExtraWageDialog/ChangeExtraWageDialog";
import styled from "styled-components";
import ChangeWageDialog from "./components/ChangeWageDialog/ChangeWageDialog";
import ChangeHistoryTwoToneIcon from '@material-ui/icons/ChangeHistoryTwoTone';
import PaymentsDialog from "./components/PaymentsDialog/PaymentsDialog";
import useLocalStorageListener from "../../../../common/hooks/useLocalStorageListener/useLocalStorageListener";
import { useWindowSize } from "react-use";
import { useHistory } from "react-router-dom";
import BulkPaymentsDialog from "./components/BulkPaymentsDialog/BulkPaymentsDialog";

interface Props {
    season: 'summer' | 'winter' | 'winter_facilities'
}

const StyledTableCell = styled(TableCell) <{ backgroundColor?: string }>` 
    padding: 6px 16px 6px 16px;

    ${props => props.backgroundColor ? ('background-color: ' + props.backgroundColor + ' !important') : ''} 
`

const ClickableTableCell = styled(StyledTableCell)`
    cursor: pointer;

    &:hover {
        background-color: #EBEBEB !important;
    }
`;

const SummerPayoutsPage = ({ season }: Props) => {
    const OtherSeasonRimanenzeComponent = season === 'summer' ? ClickableTableCell : StyledTableCell;
    const AmountComponent = ClickableTableCell;

    const { width } = useWindowSize();
    const isMobile = width <= 700;

    const [year, setYear] = useState(2025);

    const setIsLoading = useInternalLoader();

    const [data, setData] = useState<GenericObject | undefined>();
    const [isAdvancedModeEnabled, setIsAdvancedModeEnabled] = useState(false);

    const history = useHistory();

    const [isChangeExtraWageDialogOpen, setIsChangeExtraDialogOpen] = useState(false);
    const [isChangeWageDialogOpen, setIsChangeWageDialogOpen] = useState(false);
    const [isPaymentsDialogOpen, setIsPaymentsDialogOpen] = useState(false);
    const [isBulkPaymentsDialogOpen, setIsBulkPaymentsDialogOpen] = useState(false);

    const [selectedSuperUserId, setSelectedSuperUserId] = useState('');
    const [selectedExtraWageType, setSelectedExtraWageType] = useState<'winter_credits' | 'extra_work' | 'bonus' | 'winter_extra_work' | 'winter_bonus' | 'winter_competitions'>('winter_credits');
    const [selectedWeekId, setSelectedWeekId] = useState('');

    const [zoomLevel, setZoomLevel] = useState(1);

    const isDefaultZoom = !isMobile && (zoomLevel === 1 || zoomLevel.toFixed(2) === '1.00');

    const setZoomLevelImpl = useCallback((zoom: string) => {
        setZoomLevel(Math.min(Math.max(parseFloat(zoom.replaceAll('%', '')) / 100, 0.2), 1.6));
    }, []);

    const decrementZoom = useCallback(() => {
        setZoomLevel(actual => Math.max(actual - 0.1, 0.2))
    }, []);

    const incrementZoom = useCallback(() => {
        setZoomLevel(actual => Math.min(actual + 0.1, 1.6))
    }, []);

    useLocalStorageListener({ name: 'summerAvailabilityLastUpdated', callback: () => refreshData(true) });

    const refreshData = useCallback((silent = false) => {
        if (!silent) setIsLoading(true);

        api.request(season === 'summer' ? '/admin/summer_payouts' : (season === 'winter' ? '/admin/winter_staff/payouts/info' : '/admin/winter_staff/facilities/costs/info'), 'GET', { year }).then(res => {
            setData(res);
            if (!silent) setIsLoading(false);
        })
    }, [setIsLoading, season, year]);

    useEffect(() => {
        refreshData();
    }, [refreshData]);

    const [setRefFirstColumn, sizeFirstColumn] = useElementSize();

    const firstColumnStyle = useMemo((): React.CSSProperties => {
        if (!isDefaultZoom) return {};

        return {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: 0,
            background: 'white',
            boxShadow: '2px 0px 0px 0px #999999',
            zIndex: 10
        };
    }, [isDefaultZoom]);

    const lastColumnStyle = useMemo((): React.CSSProperties => {
        if (!isDefaultZoom) return {};

        return {
            position: 'sticky',
            right: 0,
            background: 'white',
            boxShadow: '-2px 0px 0px 0px #999999',
            zIndex: 10
        };
    }, [isDefaultZoom]);

    const secondColumnStyle = useMemo((): React.CSSProperties => {
        if (!isDefaultZoom) return {};

        return {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: sizeFirstColumn.width + 'px',
            background: 'white',
            boxShadow: '2px 0px 0px 0px #999999',
            zIndex: 10
        };
    }, [isDefaultZoom, sizeFirstColumn.width]);

    const firstRowStyle = useMemo((): React.CSSProperties => {
        if (!isDefaultZoom) return {};

        return {
            position: 'sticky',
            top: 0,
            background: 'white',
            boxShadow: '0px 2px 0px 0px #999999',
            zIndex: 10
        };
    }, [isDefaultZoom]);

    const lastRowStyle = useMemo((): React.CSSProperties => {
        if (!isDefaultZoom) return {};

        return {
            position: 'sticky',
            bottom: 0,
            background: 'white',
            boxShadow: '0px -2px 0px 0px #999999',
            zIndex: 10
        };
    }, [isDefaultZoom]);

    const formatNumber = useCallback((number: string | number) => {
        const realNumber = typeof number === 'string' ? parseFloat(number) : number;
        return '€ ' + (Math.round(realNumber * 100) / 100).toLocaleString('it-IT', { minimumFractionDigits: ((realNumber % 1) !== 0) ? 2 : 0 });
    }, []);

    return (
        <>
            <PageContainer>
                <Header incrementZoom={incrementZoom} decrementZoom={decrementZoom} setZoomLevel={setZoomLevelImpl} zoomLevel={zoomLevel} season={season} setIsBulkPaymentsDialogOpen={setIsBulkPaymentsDialogOpen} isAdvancedModeEnabled={isAdvancedModeEnabled} setIsAdvancedModeEnabled={setIsAdvancedModeEnabled} year={year} setYear={setYear} />

                <div style={{ flexGrow: 1, overflow: 'auto' }}>
                    <div style={{ display: 'flex', maxHeight: '100%' }}>
                        <div style={{ display: zoomLevel.toFixed(2) === '1.00' ? 'contents' : undefined }}>
                            <TableContainer component={Paper} style={{ marginTop: '16px', overflow: !isDefaultZoom ? 'visible' : undefined, maxHeight: !isDefaultZoom ? '1px' : undefined, maxWidth: !isDefaultZoom ? '1px' : undefined }} >
                                <Table size='small' style={{ backgroundColor: '#ffffff', borderCollapse: 'separate', scale: (isMobile ? 1 : zoomLevel).toString(), transformOrigin: '0 0', marginBottom: -Math.max(0, ((1 - (isMobile ? 1 : zoomLevel)) * 100 * 3 / 2)) + '%', marginRight: '-100%' }}>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell style={{ ...firstRowStyle, ...firstColumnStyle, whiteSpace: 'nowrap', zIndex: 100, boxShadow: isDefaultZoom ? '2px 2px 0px 0px #999999' : undefined }} ref={setRefFirstColumn}>{season === 'winter_facilities' ? 'Descrizione' : 'Cognome istrutttore'}</StyledTableCell>
                                            <StyledTableCell style={{ ...firstRowStyle, ...secondColumnStyle, whiteSpace: 'nowrap', zIndex: 100, boxShadow: isDefaultZoom ? '2px 2px 0px 0px #999999' : undefined }}>{season === 'winter_facilities' ? 'Paese' : 'Nome istruttore'}</StyledTableCell>

                                            {season !== 'winter_facilities' && (
                                                <StyledTableCell style={{ ...firstRowStyle, whiteSpace: 'nowrap', borderLeft: '1px solid #e0e0e0', minWidth: '122px', textAlign: 'center' }}>Rimanenze {season === 'summer' ? 'inverno' : 'estate'}</StyledTableCell>
                                            )}

                                            {season === 'summer' && (
                                                <>
                                                    {(data?.summerWeeks ?? []).map((week: any) => {
                                                        const startDate = moment(week.startDate);
                                                        const endDate = moment(week.startDate).add(4, 'days');

                                                        return (
                                                            <>
                                                                <StyledTableCell style={{ ...firstRowStyle, whiteSpace: 'nowrap', borderLeft: '1px solid #e0e0e0', width: '122px', textAlign: 'center' }}>{startDate.format('DD/MM')} - {endDate.format('DD/MM')}</StyledTableCell>

                                                                {isAdvancedModeEnabled && (
                                                                    <>
                                                                        <StyledTableCell style={{ ...firstRowStyle, whiteSpace: 'nowrap', borderLeft: '1px solid #e0e0e0', textAlign: 'center', fontWeight: 'normal' }}>Altre ASD</StyledTableCell>
                                                                        <StyledTableCell style={{ ...firstRowStyle, whiteSpace: 'nowrap', borderLeft: '1px solid #e0e0e0', textAlign: 'center', fontWeight: 'normal' }}>Sforamento</StyledTableCell>
                                                                        <StyledTableCell style={{ ...firstRowStyle, whiteSpace: 'nowrap', borderLeft: '1px solid #e0e0e0', textAlign: 'center', fontWeight: 'normal' }}>Netto</StyledTableCell>
                                                                        <StyledTableCell style={{ ...firstRowStyle, whiteSpace: 'nowrap', borderLeft: '1px solid #e0e0e0', textAlign: 'center', fontWeight: 'normal' }}>Tasse coll.</StyledTableCell>
                                                                        <StyledTableCell style={{ ...firstRowStyle, whiteSpace: 'nowrap', borderLeft: '1px solid #e0e0e0', textAlign: 'center', fontWeight: 'normal' }}>Tasse E.S</StyledTableCell>
                                                                        <StyledTableCell style={{ ...firstRowStyle, whiteSpace: 'nowrap', borderLeft: '1px solid #e0e0e0', textAlign: 'center', fontWeight: 'normal' }}>Lordo</StyledTableCell>
                                                                    </>
                                                                )}
                                                            </>
                                                        )
                                                    })}
                                                </>
                                            )}

                                            {(season === 'winter' || season === 'winter_facilities') && (
                                                <>
                                                    {(data?.winterMonths ?? []).map((month: any) => {
                                                        return (
                                                            <>
                                                                <StyledTableCell style={{ ...firstRowStyle, whiteSpace: 'nowrap', borderLeft: '1px solid #e0e0e0', width: '122px', textAlign: 'center', textTransform: 'uppercase', minWidth: '115px' }}>{moment().month(month - 1).format('MMMM')}</StyledTableCell>

                                                                {season === 'winter' && isAdvancedModeEnabled && (
                                                                    <>
                                                                        <StyledTableCell style={{ ...firstRowStyle, whiteSpace: 'nowrap', borderLeft: '1px solid #e0e0e0', textAlign: 'center', fontWeight: 'normal' }}>Altre ASD</StyledTableCell>
                                                                        <StyledTableCell style={{ ...firstRowStyle, whiteSpace: 'nowrap', borderLeft: '1px solid #e0e0e0', textAlign: 'center', fontWeight: 'normal' }}>Sforamento</StyledTableCell>
                                                                        <StyledTableCell style={{ ...firstRowStyle, whiteSpace: 'nowrap', borderLeft: '1px solid #e0e0e0', textAlign: 'center', fontWeight: 'normal' }}>Netto</StyledTableCell>
                                                                        <StyledTableCell style={{ ...firstRowStyle, whiteSpace: 'nowrap', borderLeft: '1px solid #e0e0e0', textAlign: 'center', fontWeight: 'normal' }}>Tasse coll.</StyledTableCell>
                                                                        <StyledTableCell style={{ ...firstRowStyle, whiteSpace: 'nowrap', borderLeft: '1px solid #e0e0e0', textAlign: 'center', fontWeight: 'normal' }}>Tasse E.S</StyledTableCell>
                                                                        <StyledTableCell style={{ ...firstRowStyle, whiteSpace: 'nowrap', borderLeft: '1px solid #e0e0e0', textAlign: 'center', fontWeight: 'normal' }}>Lordo</StyledTableCell>
                                                                    </>
                                                                )}
                                                            </>
                                                        )
                                                    })}
                                                </>
                                            )}

                                            {season !== 'winter_facilities' && (
                                                <>
                                                    <StyledTableCell style={{ ...firstRowStyle, whiteSpace: 'nowrap', borderLeft: '1px solid #e0e0e0', minWidth: '122px', textAlign: 'center' }}>Lavori extra {season === 'summer' ? 'estate' : 'inverno'}</StyledTableCell>
                                                    <StyledTableCell style={{ ...firstRowStyle, whiteSpace: 'nowrap', borderLeft: '1px solid #e0e0e0', minWidth: '122px', textAlign: 'center' }}>Premi</StyledTableCell>
                                                </>
                                            )}

                                            <StyledTableCell style={{ ...firstRowStyle, ...lastColumnStyle, whiteSpace: 'nowrap', zIndex: 100, boxShadow: isDefaultZoom ? '-2px 2px 0px 0px #999999' : undefined }}>{season === 'winter_facilities' ? 'TOT. PALESTRE' : 'TOT. ISTRUTTORI'}</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow style={{ height: '2px' }}></TableRow>
                                        {(data?.superUsers ?? data?.facilities ?? []).map((superUser: any) => {
                                            return (
                                                <TableRow>
                                                    <StyledTableCell style={firstColumnStyle}>{season === 'winter_facilities' ? superUser.name : superUser.lastName}</StyledTableCell>
                                                    <StyledTableCell style={secondColumnStyle}>{season === 'winter_facilities' ? superUser.town.name : superUser.firstName}</StyledTableCell>

                                                    {season !== 'winter_facilities' && (
                                                        <OtherSeasonRimanenzeComponent style={{ cursor: season === 'summer' ? 'pointer' : undefined, borderLeft: '1px solid #e0e0e0', textAlign: 'center' }}
                                                            backgroundColor={superUser.extraWages.find((x: any) => x.type === (season === 'summer' ? 'winter_credits' : 'summer_credits')) ? ((superUser.extraWages.find((x: any) => x.type === (season === 'summer' ? 'winter_credits' : 'summer_credits')).amount < 0) ? '#d7d0e9' : (superUser.extraWages.find((x: any) => x.type === (season === 'summer' ? 'winter_credits' : 'summer_credits')).paidAmount === superUser.extraWages.find((x: any) => x.type === (season === 'summer' ? 'winter_credits' : 'summer_credits')).amount ? '#d2ebd2' : (superUser.extraWages.find((x: any) => x.type === (season === 'summer' ? 'winter_credits' : 'summer_credits')).paidAmount > 0 ? '#fff4e5' : '#ffffff'))) : '#e8f4fd'}
                                                            onClick={() => {
                                                                if (season === 'winter') return;
                                                                setSelectedSuperUserId(superUser.id);
                                                                setSelectedExtraWageType('winter_credits');
                                                                setIsChangeExtraDialogOpen(true);
                                                            }}
                                                        >
                                                            {superUser.extraWages.find((x: any) => x.type === (season === 'summer' ? 'winter_credits' : 'summer_credits')) && (
                                                                <>
                                                                    {(() => {
                                                                        const payout = superUser.extraWages.find((x: any) => x.type === (season === 'summer' ? 'winter_credits' : 'summer_credits')) ?? {
                                                                            amount: 0.0,
                                                                            taxable: 0.0,
                                                                            netAmount: 0.0,
                                                                            trainerTaxes: 0.0,
                                                                            esTaxes: 0.0
                                                                        };

                                                                        return (
                                                                            <>
                                                                                {formatNumber(payout.amount - payout.taxable + payout.netAmount + payout.trainerTaxes + payout.esTaxes)}
                                                                            </>
                                                                        )
                                                                    })()}
                                                                </>
                                                            )}
                                                        </OtherSeasonRimanenzeComponent>
                                                    )}

                                                    {season === 'summer' && (
                                                        <>
                                                            {(data?.summerWeeks ?? []).map((week: any) => {
                                                                const payout = superUser.weekPayouts.find((p: any) => p.weekId === week.id);

                                                                const overflow = payout.taxable + (Math.max(payout.amount - payout.paidAmount - payout.remainingUntilWeek, 0));

                                                                const advancedModeCells = (
                                                                    <>
                                                                        <StyledTableCell style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>€ {payout.otherAsdUntilWeek.toFixed(2)}</StyledTableCell>
                                                                        <StyledTableCell style={{ textAlign: 'center', whiteSpace: 'nowrap', backgroundColor: overflow > 0.005 ? '#faccc6' : undefined }}>€ {overflow.toFixed(2)}</StyledTableCell>
                                                                        <StyledTableCell style={{ textAlign: 'center', whiteSpace: 'nowrap', backgroundColor: ((payout.amount - payout.taxable + payout.netAmount) > 0.005 && overflow > 0.005) ? '#d2ebd2' : undefined }}>{overflow > 0.005 ? <>€ {(payout.amount - payout.taxable + payout.netAmount - (Math.max(0, overflow - payout.paidAmount)) * 0.135).toFixed(2)}</> : ''}</StyledTableCell>
                                                                        <StyledTableCell style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{overflow > 0.005 ? <>€ {payout.trainerTaxes.toFixed(2)}</> : ''}</StyledTableCell>
                                                                        <StyledTableCell style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{overflow > 0.005 ? <>€ {payout.esTaxes.toFixed(2)}</> : ''}</StyledTableCell>
                                                                        <StyledTableCell style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{overflow > 0.005 ? <>€ {(payout.amount - payout.taxable + payout.netAmount + payout.trainerTaxes + payout.esTaxes - (Math.max(0, overflow - payout.paidAmount)) * 0.135).toFixed(2)}</> : ''}</StyledTableCell>                </>
                                                                );

                                                                if (payout.countEntries === 0) {
                                                                    return (
                                                                        <>
                                                                            <StyledTableCell backgroundColor='#e8f4fd' style={{ borderLeft: '1px solid #e0e0e0' }} />
                                                                            {isAdvancedModeEnabled && advancedModeCells}
                                                                        </>
                                                                    )
                                                                }

                                                                const backgroundColor = payout.paidAmount === payout.amount ? '#d2ebd2' : (payout.paidAmount > 0 ? '#fff4e5' : '#ffffff');

                                                                return (
                                                                    <>
                                                                        <AmountComponent style={{ borderLeft: '1px solid #e0e0e0', textAlign: 'right' }} backgroundColor={backgroundColor} onClick={() => {
                                                                            setSelectedSuperUserId(superUser.id);
                                                                            setSelectedWeekId(week.id);
                                                                            setIsChangeWageDialogOpen(true);
                                                                        }}>
                                                                            <p style={{ textAlign: 'center', margin: 0 }}>
                                                                                {formatNumber((isAdvancedModeEnabled ? payout.amount : (payout.amount - payout.taxable + payout.netAmount + payout.trainerTaxes + payout.esTaxes)).toFixed(2))}
                                                                            </p>

                                                                            {payout.overrideWage && (
                                                                                <span style={{ position: 'relative', top: '-21px', left: '-11px' }}>
                                                                                    <ChangeHistoryTwoToneIcon style={{ height: '26px', width: '26px', marginTop: '-2px', color: '#f44336', display: 'inline-block', position: 'absolute' }} />
                                                                                </span>
                                                                            )}
                                                                        </AmountComponent>

                                                                        {isAdvancedModeEnabled && advancedModeCells}
                                                                    </>
                                                                )
                                                            })}
                                                        </>
                                                    )}

                                                    {(season === 'winter' || season === 'winter_facilities') && (
                                                        <>
                                                            {(data?.winterMonths ?? []).map((month: any) => {
                                                                const payout = superUser.monthPayouts.find((p: any) => p.month === month);

                                                                const overflow = payout.taxable + (Math.max(payout.amount - payout.paidAmount - payout.remainingUntilMonth, 0));

                                                                const advancedModeCells = season === 'winter' ? (
                                                                    <>
                                                                        <StyledTableCell style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>€ {payout.otherAsdUntilMonth.toFixed(2)}</StyledTableCell>
                                                                        <StyledTableCell style={{ textAlign: 'center', whiteSpace: 'nowrap', backgroundColor: overflow > 0.005 ? '#faccc6' : undefined }}>€ {overflow.toFixed(2)}</StyledTableCell>
                                                                        <StyledTableCell style={{ textAlign: 'center', whiteSpace: 'nowrap', backgroundColor: ((payout.amount - payout.taxable + payout.netAmount) > 0.005 && overflow > 0.005) ? '#d2ebd2' : undefined }}>{overflow > 0.005 ? <>€ {(payout.amount - payout.taxable + payout.netAmount - (Math.max(0, overflow - payout.paidAmount)) * 0.135).toFixed(2)}</> : ''}</StyledTableCell>
                                                                        <StyledTableCell style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{overflow > 0.005 ? <>€ {payout.trainerTaxes.toFixed(2)}</> : ''}</StyledTableCell>
                                                                        <StyledTableCell style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{overflow > 0.005 ? <>€ {payout.esTaxes.toFixed(2)}</> : ''}</StyledTableCell>
                                                                        <StyledTableCell style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{overflow > 0.005 ? <>€ {(payout.amount - payout.taxable + payout.netAmount + payout.trainerTaxes + payout.esTaxes - (Math.max(0, overflow - payout.paidAmount)) * 0.135).toFixed(2)}</> : ''}</StyledTableCell>                </>
                                                                ) : null;

                                                                if (payout.countEntries === 0) {
                                                                    return (
                                                                        <>
                                                                            <ClickableTableCell backgroundColor='#e8f4fd' style={{ borderLeft: '1px solid #e0e0e0' }} onClick={() => {
                                                                                history.push((window.location.host.startsWith('admin.epuntos.it') ? '' : '/admin') + '/staff-winter/' + (season === 'winter_facilities' ? 'detailed-facility-timetable' : 'detailed-timetable') + '/' + superUser.id + '/' + month);
                                                                            }} />

                                                                            {isAdvancedModeEnabled && season === 'winter' && advancedModeCells}
                                                                        </>
                                                                    )
                                                                }

                                                                const backgroundColor = payout.paidAmount === payout.amount ? '#d2ebd2' : (payout.paidAmount > 0 ? '#fff4e5' : '#ffffff');

                                                                return (
                                                                    <>
                                                                        <AmountComponent style={{ borderLeft: '1px solid #e0e0e0', textAlign: 'right' }} backgroundColor={backgroundColor} onClick={() => {
                                                                            history.push((window.location.host.startsWith('admin.epuntos.it') ? '' : '/admin') + '/staff-winter/' + (season === 'winter_facilities' ? 'detailed-facility-timetable' : 'detailed-timetable') + '/' + superUser.id + '/' + month);
                                                                        }}>
                                                                            <p style={{ textAlign: 'center', margin: 0 }}>{formatNumber(payout.amount - ((season !== 'winter_facilities' && !isAdvancedModeEnabled) ? (payout.taxable - payout.netAmount - payout.trainerTaxes - payout.esTaxes) : 0))}</p>

                                                                            {payout.overrideWage && (
                                                                                <span style={{ position: 'relative', top: '-21px', left: '-11px' }}>
                                                                                    <ChangeHistoryTwoToneIcon style={{ height: '26px', width: '26px', marginTop: '-2px', color: '#f44336', display: 'inline-block', position: 'absolute' }} />
                                                                                </span>
                                                                            )}
                                                                        </AmountComponent>

                                                                        {isAdvancedModeEnabled && season === 'winter' && advancedModeCells}
                                                                    </>
                                                                )
                                                            })}
                                                        </>
                                                    )}

                                                    {season !== 'winter_facilities' && (
                                                        <>
                                                            <ClickableTableCell style={{ borderLeft: '1px solid #e0e0e0', textAlign: 'center' }}
                                                                backgroundColor={superUser.extraWages.find((x: any) => x.type === (season === 'summer' ? 'extra_work' : 'winter_extra_work')) ? (superUser.extraWages.find((x: any) => x.type === (season === 'summer' ? 'extra_work' : 'winter_extra_work')).paidAmount === superUser.extraWages.find((x: any) => x.type === (season === 'summer' ? 'extra_work' : 'winter_extra_work')).amount ? '#d2ebd2' : (superUser.extraWages.find((x: any) => x.type === (season === 'summer' ? 'extra_work' : 'winter_extra_work')).paidAmount > 0 ? '#fff4e5' : '#ffffff')) : '#e8f4fd'}
                                                                onClick={() => {
                                                                    setSelectedSuperUserId(superUser.id);
                                                                    setSelectedExtraWageType((season === 'summer' ? 'extra_work' : 'winter_extra_work'));
                                                                    setIsChangeExtraDialogOpen(true);
                                                                }}
                                                            >
                                                                {superUser.extraWages.find((x: any) => x.type === (season === 'summer' ? 'extra_work' : 'winter_extra_work')) && (
                                                                    <>
                                                                        {(() => {
                                                                            const payout = superUser.extraWages.find((x: any) => x.type === (season === 'summer' ? 'extra_work' : 'winter_extra_work')) ?? {
                                                                                amount: 0.0,
                                                                                taxable: 0.0,
                                                                                netAmount: 0.0,
                                                                                trainerTaxes: 0.0,
                                                                                esTaxes: 0.0
                                                                            };

                                                                            return (
                                                                                <>
                                                                                    {formatNumber(payout.amount - payout.taxable + payout.netAmount + payout.trainerTaxes + payout.esTaxes)}
                                                                                </>
                                                                            )
                                                                        })()}
                                                                    </>
                                                                )}
                                                            </ClickableTableCell>

                                                            <ClickableTableCell style={{ borderLeft: '1px solid #e0e0e0', textAlign: 'center' }}
                                                                backgroundColor={superUser.extraWages.find((x: any) => x.type === (season === 'summer' ? 'bonus' : 'winter_bonus')) ? (superUser.extraWages.find((x: any) => x.type === (season === 'summer' ? 'bonus' : 'winter_bonus')).paidAmount === superUser.extraWages.find((x: any) => x.type === (season === 'summer' ? 'bonus' : 'winter_bonus')).amount ? '#d2ebd2' : (superUser.extraWages.find((x: any) => x.type === (season === 'summer' ? 'bonus' : 'winter_bonus')).paidAmount > 0 ? '#fff4e5' : '#ffffff')) : '#e8f4fd'}
                                                                onClick={() => {
                                                                    setSelectedSuperUserId(superUser.id);
                                                                    setSelectedExtraWageType((season === 'summer' ? 'bonus' : 'winter_bonus'));
                                                                    setIsChangeExtraDialogOpen(true);
                                                                }}
                                                            >
                                                                {superUser.extraWages.find((x: any) => x.type === (season === 'summer' ? 'bonus' : 'winter_bonus')) && (
                                                                    <>
                                                                        {(() => {
                                                                            const payout = superUser.extraWages.find((x: any) => x.type === (season === 'summer' ? 'bonus' : 'winter_bonus')) ?? {
                                                                                amount: 0.0,
                                                                                taxable: 0.0,
                                                                                netAmount: 0.0,
                                                                                trainerTaxes: 0.0,
                                                                                esTaxes: 0.0
                                                                            };

                                                                            return (
                                                                                <>
                                                                                    {formatNumber(payout.amount - payout.taxable + payout.netAmount + payout.trainerTaxes + payout.esTaxes)}
                                                                                </>
                                                                            )
                                                                        })()}
                                                                    </>
                                                                )}
                                                            </ClickableTableCell>
                                                        </>
                                                    )}


                                                    <AmountComponent style={{ ...lastColumnStyle, whiteSpace: 'nowrap' }} backgroundColor={((superUser.paidPayouts === superUser.totalPayouts && superUser.totalPayouts > 0) ? '#d2ebd2' : (superUser.paidPayouts > 0 ? '#fff4e5' : '#ffffff'))} onClick={() => {
                                                        setSelectedSuperUserId(superUser.id);
                                                        setIsPaymentsDialogOpen(true);
                                                    }} >
                                                        {formatNumber(season === 'winter_facilities' ? superUser.totalPayouts : superUser.totalPayoutsV2)}
                                                    </AmountComponent>
                                                </TableRow>
                                            )
                                        })}

                                        <TableRow style={{ ...lastRowStyle }}>
                                            <StyledTableCell colSpan={2} style={{ ...firstColumnStyle, fontSize: '1.3em' }}><b>TOTALE</b></StyledTableCell>

                                            {season !== 'winter_facilities' && (
                                                <StyledTableCell style={{ borderLeft: '1px solid #e0e0e0', textAlign: 'center', fontWeight: 'bold', fontSize: '1.3em' }}>
                                                    {formatNumber((data?.superUsers ?? []).reduce((prev: number, curr: any) => {
                                                        return prev + curr.extraWages.filter((x: any) => x.type === (season === 'summer' ? 'winter_credits' : 'summer_credits')).reduce((prev2: number, curr2: any) => {
                                                            return prev2 + curr2.amount;
                                                        }, 0);
                                                    }, 0))}
                                                </StyledTableCell>
                                            )}

                                            {season === 'summer' && (
                                                <>
                                                    {(data?.summerWeeks ?? []).map((week: any) => {
                                                        const totalWeekPayout = (data?.totalPayoutForWeek ?? []).find((p: any) => p.weekId === week.id);

                                                        return (
                                                            <>

                                                                <StyledTableCell style={{ borderLeft: '1px solid #e0e0e0', textAlign: 'center', fontWeight: 'bold', fontSize: '1.3em', whiteSpace: 'nowrap' }}>
                                                                    {formatNumber(totalWeekPayout.amount)}
                                                                </StyledTableCell>

                                                                {isAdvancedModeEnabled && (
                                                                    <StyledTableCell colSpan={6} />
                                                                )}
                                                            </>
                                                        )
                                                    })}
                                                </>
                                            )}

                                            {(season === 'winter' || season === 'winter_facilities') && (
                                                <>
                                                    {(data?.winterMonths ?? []).map((month: any) => {
                                                        const totalWeekPayout = (data?.totalPayoutForMonth ?? []).find((p: any) => p.month === month);

                                                        return (
                                                            <>
                                                                <StyledTableCell style={{ borderLeft: '1px solid #e0e0e0', textAlign: 'center', fontWeight: 'bold', fontSize: '1.3em', whiteSpace: 'nowrap' }}>
                                                                    {formatNumber(totalWeekPayout.amount)}
                                                                </StyledTableCell>

                                                                {isAdvancedModeEnabled && (
                                                                    <StyledTableCell colSpan={6} />
                                                                )}
                                                            </>
                                                        )
                                                    })}
                                                </>
                                            )}

                                            {season !== 'winter_facilities' && (
                                                <>
                                                    <StyledTableCell style={{ borderLeft: '1px solid #e0e0e0', textAlign: 'center', fontWeight: 'bold', fontSize: '1.3em', whiteSpace: 'nowrap' }}>
                                                        {formatNumber((data?.superUsers ?? []).reduce((prev: number, curr: any) => {
                                                            return prev + curr.extraWages.filter((x: any) => x.type === (season === 'summer' ? 'extra_work' : 'winter_extra_work')).reduce((prev2: number, curr2: any) => {
                                                                return prev2 + curr2.amount;
                                                            }, 0);
                                                        }, 0))}
                                                    </StyledTableCell>

                                                    <StyledTableCell style={{ borderLeft: '1px solid #e0e0e0', textAlign: 'center', fontWeight: 'bold', fontSize: '1.3em', whiteSpace: 'nowrap' }}>
                                                        {formatNumber((data?.superUsers ?? []).reduce((prev: number, curr: any) => {
                                                            return prev + curr.extraWages.filter((x: any) => x.type === (season === 'summer' ? 'bonus' : 'winter_bonus')).reduce((prev2: number, curr2: any) => {
                                                                return prev2 + curr2.amount;
                                                            }, 0);
                                                        }, 0))}
                                                    </StyledTableCell>
                                                </>
                                            )}

                                            <StyledTableCell style={{ ...lastColumnStyle, whiteSpace: 'nowrap', fontSize: '1.3em', fontWeight: 'bold' }} backgroundColor='#f4b083'>
                                                {formatNumber(((season === 'summer' ? data?.totalPayoutForWeek : data?.totalPayoutForMonth) ?? []).reduce((prev: number, curr: any) => {
                                                    return parseFloat(curr.amount) + prev;
                                                }, 0))}
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>

            </PageContainer >

            <ChangeExtraWageDialog
                open={isChangeExtraWageDialogOpen}
                closeDialog={(shouldRefresh?: boolean) => {
                    if (shouldRefresh) {
                        refreshData();
                        localStorage.setItem('summerAvailabilityLastUpdated', Date.now().toString());
                    }
                    setIsChangeExtraDialogOpen(false);
                }}
                superUserId={selectedSuperUserId}
                type={selectedExtraWageType}
                year={year}
            />

            <ChangeWageDialog
                open={isChangeWageDialogOpen}
                closeDialog={(shouldRefresh?: boolean) => {
                    if (shouldRefresh) {
                        refreshData();
                        localStorage.setItem('summerAvailabilityLastUpdated', Date.now().toString());
                    }
                    setIsChangeWageDialogOpen(false);
                }}
                superUserId={selectedSuperUserId}
                weekId={selectedWeekId}
            />

            {season !== 'winter_facilities' && (
                <PaymentsDialog
                    open={isPaymentsDialogOpen}
                    closeDialog={() => setIsPaymentsDialogOpen(false)}
                    refreshRegistrations={() => {
                        refreshData();
                        localStorage.setItem('summerAvailabilityLastUpdated', Date.now().toString());
                    }}
                    superUserId={selectedSuperUserId}
                    season={season}
                    year={year}
                />
            )}

            <BulkPaymentsDialog
                open={isBulkPaymentsDialogOpen}
                closeDialog={() => setIsBulkPaymentsDialogOpen(false)}
                season={season}
                refreshData={() => refreshData()}
            />
        </>
    )
}

export default SummerPayoutsPage;