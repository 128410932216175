import { useCallback, useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';

import useInternalLoader from '../../../../common/hooks/useInternalLoader/useExternalLoader';
import api from '../../../../common/utils/api';
import GenericObject from '../../../../typesAdditional/GenericObject';
import Table from './components/Table/Table';
import { Title } from './styled';

const DocumentsPage = () => {
    const setIsLoading = useInternalLoader();
    const { width } = useWindowSize();

    const [documents, setDocuments] = useState<GenericObject[]>([]);

    const refreshData = useCallback(() => {
        api.request('/documents').then((res: any) => {
            setDocuments(res);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [setIsLoading]);

    useEffect(() => {
        refreshData();
    }, [refreshData]);

    const isMobile = width <= 700;

    return (
        <>
            <Title isMobile={isMobile}>
                I miei documenti
            </Title>

            <Table documentsList={documents} refreshData={refreshData} />
        </>
    );
};

export default DocumentsPage;
