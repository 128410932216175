import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

interface Props {
    count: number;
}

const NoMissingDocumentsAlert = (props: Props) => {
    const { count } = props;

    return (
        <Alert severity='success' style={{paddingBottom: '0px'}}>
            <AlertTitle>{count === 1 ? 'Documento richiesto' : 'Documenti richiesti'}</AlertTitle>

            <p style={{marginTop: '0px', marginBottom: '10px'}}>
                {count === 1 ? 'Il documento richiesto è già stato caricato.' : 'Tutti i documenti richiesti sono già stati caricati.'}
            </p>
        </Alert>
    );
};

export default NoMissingDocumentsAlert;
