import Typography from "@material-ui/core/Typography";
import GenericObject from "../../../../../../typesAdditional/GenericObject"
import Divider from "@material-ui/core/Divider";
import { useWindowSize } from "react-use";
import GreenButton from "../../../../../../common/components/GreenButton/GreenButton";
import YellowButton from "../../../../../../common/components/YellowButton/YellowButton";
import { useSelector } from "react-redux";
import { ReduxState } from "../../../../../../redux/types";
import { useState, useCallback, SetStateAction, Dispatch } from "react";
import api from "../../../../../../common/utils/api";
import snackbar from "../../../../../../common/utils/snackbar";
import Button from "@material-ui/core/Button";
import { downloadBlob } from "download.js";
import SelectYearDialog from "../SelectYearDialog/SelectYearDialog";
import { Link } from "@material-ui/core";

interface Props {
    selectedWeek?: GenericObject;
    refreshWeeks: () => void;
    setIsWeekNotesDialogOpen: Dispatch<SetStateAction<boolean>>;
    year: number;
    setYear: Dispatch<SetStateAction<number>>
}

const Header = ({ selectedWeek, refreshWeeks, setIsWeekNotesDialogOpen, year, setYear }: Props) => {
    const { width } = useWindowSize();
    const isMobile = width <= 900;

    const [isLoading, setIsLoading] = useState(false);
    const [isSelectYearDialogOpen, setIsSelectYearDialogOpen] = useState(false);

    const userInfo = useSelector((state: ReduxState) => state.user);

    const canManageStaff = userInfo?.canManageStaff ?? false;

    const base64toBlob = useCallback((dataURI: string) => {
        // convert a base64 string to a blob object
        const byteString = atob(dataURI);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab]);
    }, []);

    const handleExport = () => {
        setIsLoading(true);
        api.request('/admin/summer_schedule/export_trainers_report', 'GET').then(res => {
            const blob = base64toBlob(res.content);
            downloadBlob(res.filename, blob);
        }).finally(() => {
            setIsLoading(false);
        });
    };

    const setWeekStatus = useCallback((weekId: string, status: string) => {
        setIsLoading(true);

        api.request('/admin/summer_schedule/weeks/' + weekId + '/status', 'POST', { status }).then(() => {
            refreshWeeks();
            snackbar.success('Stato modificato con successo!');
        }).finally(() => {
            setIsLoading(false);
        });
    }, [refreshWeeks]);

    const setWeekVisibility = useCallback((weekId: string, visibility: boolean) => {
        setIsLoading(true);

        api.request('/admin/summer_schedule/weeks/' + weekId + '/visibility', 'POST', { visibility }).then(() => {
            refreshWeeks();
            snackbar.success('Visibilità modificato con successo!');
        }).finally(() => {
            setIsLoading(false);
        });
    }, [refreshWeeks]);

    const setWeekAutomatic = useCallback((weekId: string, automatic: boolean) => {
        setIsLoading(true);

        api.request('/admin/summer_schedule/weeks/' + weekId + '/automatic', 'POST', { automatic }).then(() => {
            refreshWeeks();
            snackbar.success('Turni automatici ' + (automatic ? 'attivati' : 'disattivati') + '!');
        }).finally(() => {
            setIsLoading(false);
        });
    }, [refreshWeeks]);

    return (
        <div>
            <div style={{ display: isMobile ? 'block' : 'flex' }}>
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '18px' }}>
                    <div style={{ flexGrow: 1 }} />
                    <Typography variant='h2' style={{ fontSize: isMobile ? '3em' : '3.6em', textAlign: isMobile ? 'center' : 'left' }}>
                        {canManageStaff ? (
                            <>
                                Turni estate <Link href='#' onClick={() => setIsSelectYearDialogOpen(true)}>{year}</Link>
                            </>
                        ) : (
                            <>
                                Turni estate {year}
                            </>
                        )}

                    </Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '18px' }}>
                    <div style={{ flexGrow: 1 }} />
                    <Typography variant='h2' style={{ fontSize: '2em', marginBottom: '4px', textAlign: isMobile ? 'center' : 'left' }}>
                        settimana {selectedWeek?.shortName}
                    </Typography>
                </div>

                <div style={{ flex: 1, flexGrow: 1 }} />

                {canManageStaff && (
                    <>
                        {year === 2025 && (
                            <Button disabled={isLoading} variant={selectedWeek?.automatic ? 'contained' : 'outlined'} color='primary' onClick={() => setWeekAutomatic(selectedWeek?.id ?? '', !selectedWeek?.automatic)} style={{ height: 'fit-content', margin: 'auto 16px', flexGrow: 0, marginTop: isMobile ? '10px' : 'auto', marginBottom: isMobile ? '10px' : 'auto', display: isMobile ? 'block' : undefined, }}>
                                Turni automatici {selectedWeek?.automatic ? 'attivi' : 'non attivi'}
                            </Button>
                        )}

                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {!isMobile && (
                                <Button disabled={isLoading} variant={'outlined'} color='primary' onClick={() => setIsWeekNotesDialogOpen(oldStatus => !oldStatus)} style={{ height: 'fit-content', margin: 'auto 0', flexGrow: 0, marginTop: isMobile ? '10px' : '2px', marginBottom: isMobile ? '10px' : '2px', display: isMobile ? 'block' : undefined, }}>
                                    Note
                                </Button>
                            )}

                            <Button disabled={isLoading} variant={'outlined'} color='primary' onClick={() => handleExport()} style={{ height: 'fit-content', margin: 'auto', flexGrow: 0, marginTop: isMobile ? '10px' : '2px', marginBottom: isMobile ? '10px' : '2px', display: isMobile ? 'block' : undefined, }}>
                                Esporta report
                            </Button>
                        </div>
                    </>
                )}

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {canManageStaff && (
                        <>
                            {selectedWeek?.visible ? (
                                <GreenButton noaction={year !== 2025} disabled={isLoading} onClick={() => {
                                    if (canManageStaff && year === 2025) {
                                        setWeekVisibility(selectedWeek?.id ?? '', false);
                                    }
                                }} style={{ margin: isMobile ? '8px auto 0' : '2px 16px', display: isMobile ? 'block' : undefined, flexGrow: 0, marginTop: isMobile ? '10px' : '2px', marginBottom: isMobile ? '10px' : '2px', width: '180px', height: 'fit-content' }}>
                                    Turni visibili
                                </GreenButton>
                            ) : (
                                <YellowButton noaction={year !== 2025} disabled={isLoading} onClick={() => {
                                    if (canManageStaff && year === 2025) {
                                        setWeekVisibility(selectedWeek?.id ?? '', true);
                                    }
                                }} style={{ margin: isMobile ? '8px auto 0' : '2px 16px', display: isMobile ? 'block' : undefined, flexGrow: 0, marginTop: isMobile ? '10px' : '2px', marginBottom: isMobile ? '10px' : '2px', width: '180px', height: 'fit-content' }}>
                                    Turni non visibili
                                </YellowButton>
                            )}
                        </>
                    )}

                    {(canManageStaff || selectedWeek?.visible) ? (
                        <>
                            {selectedWeek?.status === 'final' ? (
                                <GreenButton disabled={isLoading} noaction={!canManageStaff || year !== 2025} onClick={() => {
                                    if (canManageStaff && year === 2025) {
                                        setWeekStatus(selectedWeek?.id ?? '', 'draft');
                                    }
                                }} style={{ margin: isMobile ? '8px auto 0' : '2px 16px', display: isMobile ? 'block' : undefined, flexGrow: 0, marginTop: isMobile ? '10px' : '2px', marginBottom: isMobile ? '10px' : '2px', width: '180px' }}>
                                    Turni definitivi
                                </GreenButton>
                            ) : (
                                <YellowButton disabled={isLoading} noaction={!canManageStaff || year !== 2025} onClick={() => {
                                    if (canManageStaff && year === 2025) {
                                        setWeekStatus(selectedWeek?.id ?? '', 'final');
                                    }
                                }} style={{ margin: isMobile ? '8px auto 0' : '2px 16px', display: isMobile ? 'block' : undefined, flexGrow: 0, marginTop: isMobile ? '10px' : '2px', marginBottom: isMobile ? '10px' : '2px', width: '180px' }}>
                                    Bozza turni
                                </YellowButton>
                            )}
                        </>
                    ) : (
                        <YellowButton disabled={isLoading} noaction style={{ margin: isMobile ? '8px auto 0' : 'auto 16px', display: isMobile ? 'block' : undefined, flexGrow: 0, marginTop: isMobile ? '10px' : undefined, marginBottom: isMobile ? '10px' : undefined, width: '220px', height: 'fit-content' }}>
                            Turni non disponibili
                        </YellowButton>
                    )}
                </div>
            </div>

            <Divider style={{ margin: '16px 0' }} />

            <SelectYearDialog open={isSelectYearDialogOpen} closeDialog={() => setIsSelectYearDialogOpen(false)} selectedYear={year} setSelectedYear={setYear} />
        </div>
    )
}

export default Header;