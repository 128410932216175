import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useExternalLoader from '../../../../common/hooks/useExternalLoader/useExternalLoader';
import useGenericErrorDialog from '../../../../common/hooks/useGenericErrorDialog/useGenericErrorDialog';
import { useMessageDialog } from '../../../../common/hooks/useMessageDialog/useMessageDialog';
import useSearchParams from '../../../../common/hooks/useSearchParams/useSearchParams';
import api from '../../../../common/utils/api';
import snackbar from '../../../../common/utils/snackbar';
import { setFromApi } from '../../../../redux/user/userSlice';

const LoginPage = () => {
    const { token, redirectUrl } = useSearchParams();
    const setIsLoading = useExternalLoader();

    const [showMessageDialog] = useMessageDialog();
    const showGenericErrorDialog = useGenericErrorDialog();

    const dispatch = useDispatch();
    const history = useHistory();

    const realRedirectUrl = (redirectUrl ?? '').replaceAll('/crm', '');

    const onEndLoginProcedure = useCallback((loginSuccess: boolean) => {
        history.push((realRedirectUrl?.length) ? realRedirectUrl : '/');
        setIsLoading(false);

    }, [history, setIsLoading, realRedirectUrl]);

    useEffect(() => {
        // @ts-ignore
        window.noRedirectToHome = true;

        api.userLoginWithToken(token).then(() => {
            api.request('/auth/me').then(res => {
                snackbar.success('Accesso effettuato con successo!');
                dispatch(setFromApi(res));
                onEndLoginProcedure(true);
            }).catch((res) => {
                showGenericErrorDialog(res);
            });
        }).catch(res => {
            const { error } = res;

            if (token === '' || error === 'token_invalid') {
                showMessageDialog({
                    title: 'Impossibile effettuare l\'accesso',
                    message: 'Il link utilizzato non è valido.'
                });
            } else if (error === 'token_used') {
                showMessageDialog({
                    title: 'Impossibile effettuare l\'accesso',
                    message: 'Il link è già stato utilizzato. Ripeti la procedura per accedere di nuovo.'
                });
            } else if (error === 'token_expired') {
                showMessageDialog({
                    title: 'Impossibile effettuare l\'accesso',
                    message: 'Il link che hai utilizzato è scaduto. Ripeti la procedura per accedere di nuovo.'
                });
            } else if (error === 'token_expired_refreshed') {
                showMessageDialog({
                    title: 'Impossibile effettuare l\'accesso',
                    message: 'Il link che hai utilizzato è scaduto. Ti abbiamo inviato un\'email contenente un nuovo link per accedere!'
                });
            } else {
                showGenericErrorDialog({ error });
            }

            onEndLoginProcedure(false);
        });
    }, [token, dispatch, onEndLoginProcedure, showGenericErrorDialog, showMessageDialog]);

    return null;
};

export default LoginPage;
