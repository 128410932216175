import { useEffect, useMemo, useState } from 'react';

import Button from '@material-ui/core/Button';
import { v4 as uuidv4 } from 'uuid';

import { useMessageDialog } from '../../../../../../common/hooks/useMessageDialog/useMessageDialog';
import api from '../../../../../../common/utils/api';
import { Asset, Document } from '../../types';
import FilesList from '../FilesList/FilesList';
import { StyledFileInput } from './styled';
import { FlexPusher } from '../../../../components/DrawerContent/components/UserInfoSection/styled';

const maxUploadSize = 32;

interface Props {
    initialAssets?: Asset[];
    documentId: string;
    handleAssetChange: (documentId: string, assets: Asset[]) => void;
    savedAssets: Asset[];
    status: Document['status'];
    isAdmin?: boolean;
}

const FileUploader = (props: Props) => {
    const { initialAssets, documentId, handleAssetChange, savedAssets, status, isAdmin } = props;

    const [assetList, setAssetList] = useState<Asset[]>((status === 'ok' || status === 'reviewing' || status === 'expiring') ? savedAssets : (initialAssets ?? []));

    const [showMessageDialog] = useMessageDialog();

    useEffect(() => {
        handleAssetChange(documentId, assetList);
    }, [assetList, documentId, handleAssetChange]);

    const formId = useMemo(() => {
        return uuidv4();
    }, []);

    const handleCompletedUpload = (tmpId: string, newId: string, mime: string) => {
        setAssetList(currentState => currentState.map(asset => {
            if (asset.id === tmpId) {
                return {
                    ...asset,
                    id: newId,
                    mimeType: mime,
                    status: 'uploaded'
                };
            } else {
                return asset;
            }
        }));
    };

    const handleRemove = (id: string) => {
        setAssetList(currentState => currentState.filter(asset => {
            return asset.id !== id;
        }));
    };

    const handleApiError = (tmpId: string, apiRes: any) => {
        const { error } = apiRes;

        if (error === 'file_too_big') {
            const maxSize = apiRes.maxSizeMb ?? 30;

            showMessageDialog({
                title: 'Impossibile caricare il file',
                message: 'Il file selezionato è troppo grande. Dimensione massima: ' + maxSize + ' MB.'
            });
        }

        handleRemove(tmpId);
    };

    const onFileChange = (e: React.FormEvent<HTMLInputElement>) => {
        const files = (e.target as HTMLInputElement).files;

        if (!files) return;

        for (const file of files) {
            if (file.size / 1024 / 1024 > maxUploadSize) {
                showMessageDialog({
                    title: 'Impossibile caricare il file',
                    message: 'Il file selezionato è troppo grande. Dimensione massima: 30 MB.'
                });
                continue;
            }

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (res) => {
                const base64 = res?.target?.result?.toString().split(',')[1];

                if (base64) {
                    const tmpId = uuidv4();
                    setAssetList(currentState => {
                        return [
                            ...currentState,
                            {
                                id: tmpId,
                                originalName: file.name,
                                status: 'uploading'
                            }
                        ];
                    });

                    api.request('/admin/assets', 'POST', {
                        name: file.name,
                        content: base64,
                        private: false,
                        allowAllMimeTypes: true
                    }, false).then((apiRes) => {
                        handleCompletedUpload(tmpId, apiRes.id, apiRes.mimeType);
                    }).catch((apiRes) => {
                        handleApiError(tmpId, apiRes);
                    });
                }
            };
        }

        (e.target as HTMLInputElement).value = '';
    };

    return (
        <>
            <div style={{ flex: 1 }}>
                <FilesList assets={assetList} removeAsset={handleRemove} status={status} isAdmin={isAdmin} />
            </div>

            <FlexPusher />

            <div style={{ flex: 1, flexGrow: 0 }}>
                {(!isAdmin || true) && (
                    <>
                        {!(status === 'ok' || status === 'reviewing') && (
                            <>
                                <StyledFileInput
                                    id={'file-' + formId}
                                    type='file'
                                    onChange={onFileChange}
                                />

                                <div>
                                    <label htmlFor={'file-' + formId}>
                                        <Button color='primary' variant='outlined' component='span' disabled={assetList.length > 0} >
                                            Scegli file
                                        </Button>
                                    </label>
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default FileUploader;
