import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { Alert, AlertTitle } from '@material-ui/lab';

import CustomTextField from '../../../../../../common/components/CustomTextField/CustomTextField';
import GenericObject from '../../../../../../typesAdditional/GenericObject';
import LoadingSimulationAlert from '../../components/LoadingSimulationAlert/LoadingSimulationAlert';
import SimulationRecapAlert from '../../components/SimulationRecapAlert/SimulationRecapAlert';
import StepFooter from '../../components/StepFooter/StepFooter';
import useSimulateRegistration from '../../hooks/useSimulateRegistration/useSimulateRegistration';
import useInternalNextStep from './hooks/useInternalNextStep';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../../../redux/types';
import sanitizeHtml from 'sanitize-html';

interface Props {
    courseId: string;
    courseData: any;
    validationResult: any;
    data: GenericObject;
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: string) => void;
    rawOnChange: (field: string, value: any) => void;
    selectedLocationId: string;
    selectedSessions: any[];
    prevStep: () => void;
    nextStep: () => void;
    resetRegistrationData: () => void;
    givenConsents: string[];
    setGivenConsents: Dispatch<SetStateAction<string[]>>;
    preselectedLocation: GenericObject;
    gtmStepPush: (step: number, data: any) => void;
    suggestedSessions: any[];
    welfareData: any;
}

const RegistrationStep7 = (props: Props) => {
    const { courseId, courseData, data, selectedLocationId, selectedSessions, resetRegistrationData, prevStep, givenConsents, setGivenConsents, preselectedLocation, gtmStepPush, suggestedSessions, welfareData } = props;

    const { sessionType, consents, showReferralQuestion } = courseData;

    const userInfo = useSelector((state: ReduxState) => state.user);

    const [selectedReferralOption, setSelectedReferralOption] = useState<number | ''>('');

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingFinal, setIsLoadingFinal] = useState(false);

    const {
        simulateRegistrationData,
        countSessions,
        countSessionsFoodService,
        countQueueSessions,
        getSessionsLabel,
        isLoading: isSimulationLoading,
        toPay,
        saving,
        additionalSaving,
    } = useSimulateRegistration({ courseId, courseData, data, sessionType, selectedLocationId, initialSelectedSessions: selectedSessions, finalStep: true, suggestedSessions, welfareData });

    const [newsletterConsents, setNewsletterConsents] = useState({
        email: !!userInfo.newsletterEmail,
        sms: !!userInfo.newsletterSms
    });

    const changeConsent = (consentId: string, value: boolean) => {
        const currConsent = givenConsents.find(s => s === consentId);

        if (value && !currConsent) {
            setGivenConsents(c => [...c, consentId]);
        } else if (!value) {
            setGivenConsents(c => c.filter(cc => cc !== consentId));
        }
    };

    const internalNextStep = useInternalNextStep({
        courseId,
        courseData,
        data,
        selectedLocationId,
        selectedSessions,
        consents,
        givenConsents,
        newsletterConsents,
        selectedReferralOption,
        setIsLoading,
        setIsLoadingFinal,
        resetRegistrationData,
        preselectedLocation,
        gtmStepPush,
        suggestedSessions,
        price: toPay,
        saving: saving + additionalSaving,
        welfareData
    });

    const realSessions = simulateRegistrationData?.discounts?.selected?.customPricing?.sessions ?? simulateRegistrationData?.sessions ?? [];

    const pricesNotAvailable = realSessions?.some((session: any) => {
        return session?.prices?.course?.actual?.price <= 0;
    }) && courseId !== '7030abec-7234-4939-a154-336743ed9f92';

    /*
    const referralOptions = [
        'Conoscevo già Educazione.Sport.',
        'Non vi conoscevo. L\'associazione mi è stata consigliata da un amico/a',
        'Non vi conoscevo. Vi ho trovati tramite i risultati di ricerca Google',
        'Non vi conoscevo. Vi ho trovati tramite Facebook',
        'Non vi conoscevo. Vi ho trovati tramite Instagram',
        'Non vi conoscevo. Vi ho trovati tramite il sito internet tuttocampiestivi.com',
        'Non vi conoscevo. Vi ho trovati tramite il sito internet orangogo.it',
        'Non vi conoscevo. Vi ho scoperti tramite Bergamonews'
    ];
    */

    const referralOptionsV3 = [
        {
            id: 7,
            label: 'Un amico/a - Passaparola',
        },
        ...(((courseData.category.name === 'Corsi Bimbi e Ragazzi Annuali' && courseData.season.year >= 2023) || courseData.category.name === 'Camp Estivi') ? [{
            id: 15,
            label: 'L\'informativa della nostra scuola'
        }] : []),
        ...(((courseData.category.name === 'Corsi Adulti Annuali' && courseData.season.year >= 2023) || courseData.category.name === 'Camp Estivi') ? [{
            id: 16,
            label: 'Le Informative del mio comune di residenza'
        }] : []),
        {
            id: 18,
            label: 'La mia azienda'
        },
        {
            id: 8,
            label: 'Google',
        },
        {
            id: 9,
            label: 'Instagram',
        },
        {
            id: 10,
            label: 'Facebook',
        },
        {
            id: 11,
            label: 'Tuttocampiestivi.com',
        },
        /*{
            id: 12,
            label: 'Orangogo.it'
        },
        {
            id: 13,
            label: 'Eppen / Eco di Bergamo'
        },*/
        {
            id: 17,
            label: 'Testate Giornalistiche'
        },
        {
            id: 19,
            label: 'Volantini'
        },
        {
            id: 14,
            label: 'Altro'
        }
    ]

    const isLoadingReal = isLoading || isSimulationLoading;

    useEffect(() => {
        if ((!!showReferralQuestion && userInfo.referralIsOldUser)) {
            setSelectedReferralOption(0);
        }
    }, [showReferralQuestion, userInfo]);

    return (
        <>
            <div>

                {isLoadingFinal ? (
                    <Alert severity='info' style={{ paddingBottom: '0px' }}>
                        <AlertTitle>Salvataggio in corso</AlertTitle>
                        <p style={{ marginTop: '0px' }}>
                            Attendi, è in corso il salvataggio della tua iscrizione.
                        </p>
                    </Alert>
                ) : (
                    <>
                        {(isLoadingReal && !isLoadingFinal) ? (
                            <LoadingSimulationAlert />
                        ) : (
                            <>
                                {(countSessions + countQueueSessions) > 0 && (
                                    <SimulationRecapAlert
                                        simulateRegistrationData={simulateRegistrationData}
                                        countSessions={countSessions}
                                        countSessionsFoodService={countSessionsFoodService}
                                        countQueueSessions={countQueueSessions}
                                        toPay={toPay}
                                        saving={saving}
                                        additionalSaving={additionalSaving}
                                        getSessionsLabel={getSessionsLabel}
                                        pricesNotAvailable={pricesNotAvailable}
                                    />
                                )}
                            </>
                        )}
                    </>
                )}

                {courseId === '7030abec-7234-4939-a154-336743ed9f92' ? (
                    <div style={{ textAlign: 'left', marginTop: '18px' }}>
                        <p style={{ fontSize: '1.4em', marginTop: '4px', marginBottom: 0 }}>
                            ✉️ <b>Ci sei quasi...!</b> Dopo l'invio ti invieremo una mail...
                        </p>
                        <p style={{ fontSize: '1.25em', width: 'fit-content' }}>
                            <b>Nella Mail ti verrà comunicato:</b>
                            <ul>
                                <li>se puoi partecipare all'evento <b><u>oppure</u></b></li>
                                <li>se sei in lista di attesa</li>
                            </ul>
                        </p>
                        <p style={{ fontSize: '1.15em', marginTop: '4px', marginBottom: 0 }}>
                            Ti ricordiamo infatti che ci sono solo 25 posti disponibili.
                        </p>
                        <p style={{ fontSize: '1.15em', marginTop: '4px', marginBottom: 0 }}>
                            (N.B: se sei in lista di attesa e si liberano dei posti, ti ricontatteremo noi!)
                        </p>
                    </div>
                ) : (
                    <>
                        {(!!showReferralQuestion && !userInfo.referralIsOldUser) ? (
                            <>
                                <div style={{ marginTop: '24px', marginBottom: '8px' }}>
                                    <CustomTextField
                                        select
                                        label={'Vi ho scoperti tramite'}
                                        value={selectedReferralOption}
                                        onChange={(e: any) => {
                                            setSelectedReferralOption(e.target.value);
                                        }}
                                        variant='outlined'
                                        required
                                        fullWidth
                                    >
                                        {referralOptionsV3.map((opt: any) => (
                                            <MenuItem key={opt.id} value={opt.id} style={{ whiteSpace: 'break-spaces' }}>
                                                {opt.label}
                                            </MenuItem>
                                        ))}
                                    </CustomTextField>
                                </div>
                            </>

                        ) : <div style={{ marginTop: '16px' }} />}

                        {courseId === 'b85b1c8b-7dae-4229-9a93-56ebd3c8570f' && (
                            <div style={{ color: '#222222', marginTop: '24px', marginBottom: '24px' }}>
                                <p style={{ fontSize: '1.2em', marginBottom: '8px' }}>
                                    <b>CONSENSI E DOVERI ASSOCIATIVI</b>
                                </p>
                                <p>
                                    In qualità di SOCIO:<br />
                                    A partecipare all’attività sportiva: CALCIO che verrà organizzata dall’U.S.O. Città di Alzano ASD per anno sportivo 2023/2024.
                                </p>
                                <p>
                                    Consapevole che la richiesta potrà essere esaminata solo al verificarsi delle condizioni di seguito elencate:
                                </p>
                                <p>
                                    DICHIARA
                                    <ol>
                                        <li>Di impegnarsi a versare la quota per la partecipazione sportiva pari a 300€</li>
                                        <li>Di impegnarsi a produrre certificazione medica per l’idoneità alla pratica di attività sportiva non agonistica conforme a quanto previsto dalle vigenti normative;</li>
                                    </ol>
                                </p>
                                <p>
                                    I versamenti potranno avvenire con bonifico bancario su: IBAN: IT90G0845353740000000235675 Intestato a: USO CITTA DI ALZANO ASD, con causale: “Quota partecipazione attività sportive - cognome e nome atleta”.
                                </p>
                            </div>
                        )}

                        <div style={{ marginTop: '8px' }}>
                            {consents.map((consent: any) => {
                                return (
                                    <FormControlLabel
                                        style={{ display: 'flex', marginBottom: '10px', alignItems: 'flex-start' }}
                                        label={(
                                            <>
                                                <span style={{ fontSize: '0.87em', whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: sanitizeHtml(selectedLocationId === '17ffcad7-afc6-479a-b23e-9494e4c72f4a' ? consent.description.replaceAll('USO Città di Alzano', 'ARES Redona') : consent.description) }} />
                                                <span style={{ display: 'block', fontSize: '0.9em', color: '#555555' }}>
                                                    <i>* obbligatorio</i>
                                                </span>
                                            </>
                                        )}
                                        control={
                                            <Checkbox
                                                checked={givenConsents.includes(consent.id)}
                                                onChange={(e) => changeConsent(consent.id, e.target.checked)}
                                                style={{ paddingTop: '0px' }}
                                                color='primary'
                                            />
                                        }
                                    />
                                );
                            })}
                        </div>

                        <div style={{ marginTop: '24px' }}>
                            <FormControlLabel
                                style={{ display: 'flex', marginBottom: '10px', alignItems: 'flex-start' }}
                                label={(
                                    <>
                                        <span style={{ fontSize: '0.89em' }}>
                                            Si, voglio ricevere aggiornamenti importanti e novità riguardo alle attività dell'associazione via Email.
                                        </span>
                                    </>
                                )}
                                control={
                                    <Checkbox
                                        checked={newsletterConsents.email}
                                        onChange={(e) => setNewsletterConsents(c => {
                                            return {
                                                ...c,
                                                email: e.target.checked
                                            };
                                        })}
                                        style={{ paddingTop: '0px' }}
                                        color='primary'
                                    />
                                }
                            />
                            <FormControlLabel
                                style={{ display: 'flex', marginBottom: '10px', alignItems: 'flex-start' }}
                                label={(
                                    <>
                                        <span style={{ fontSize: '0.89em' }}>
                                            Si, voglio ricevere aggiornamenti importanti e novità riguardo alle attività dell'associazione via SMS.
                                        </span>
                                    </>
                                )}
                                control={
                                    <Checkbox
                                        checked={newsletterConsents.sms}
                                        onChange={(e) => setNewsletterConsents(c => {
                                            return {
                                                ...c,
                                                sms: e.target.checked
                                            };
                                        })}
                                        style={{ paddingTop: '0px' }}
                                        color='primary'
                                    />
                                }
                            />
                        </div>

                        <div style={{ color: '#222222', marginTop: '24px' }}>
                            <p>
                                Ai sensi dell’art. 10 della legge 675/1996 e successive modificazioni, le informazioni indicate nella presente dichiarazione verranno utilizzate unicamente per le finalità per le quali sono state acquisite.
                            </p>
                            <p>
                                Consapevole che chiunque rilascia dichiarazioni mendaci è punito ai sensi del codice penale e delle leggi speciali in materia, ai sensi e per gli effetti dell'art. 46 D.P.R. n. 445/2000.
                            </p>
                            <p>
                                Iscrivendoti al corso, accetti la Privacy Policy e le Condizioni d'uso di Educazione.Sport.
                            </p>
                        </div>
                    </>
                )}

            </div>

            <StepFooter isLoading={isLoadingReal} prevStep={prevStep} nextStep={internalNextStep} finalStep />
        </>
    );
};

export default RegistrationStep7;
