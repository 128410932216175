import { useCallback, useMemo } from 'react';

import List from '@material-ui/core/List';

import { Asset, Document } from '../../types';
import FileItem from './components/FileItem';

interface Props {
    assets: Asset[];
    removeAsset: (id: string) => void;
    status: Document['status'];
    isAdmin?: boolean;
}

const FilesList = (props: Props) => {
    const { assets, removeAsset, status, isAdmin } = props;

    const getAssetWithStatus = useCallback((asset: Asset): Asset => {
        if (status === 'ok' || status === 'reviewing' || status === 'expiring') {
            return {
                ...asset,
                status: 'saved'
            };
        }

        return asset;
    }, [status]);

    const assetsWithStatus = useMemo(() => {
        return assets.map(asset => getAssetWithStatus(asset));
    }, [assets, getAssetWithStatus]);

    return (
        <List>
            {assetsWithStatus.map((asset: Asset) => {
                return (
                    <FileItem asset={asset} handleRemove={() => removeAsset(asset.id)} isAdmin={isAdmin} />
                );
            })}
        </List>
    );
};

export default FilesList;
