import { useEffect, useState } from 'react';

import { ButtonProps } from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import 'moment/locale/it';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import api from '../../../../../../common/utils/api';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    groupId: string;
}

const MessageRecipientsDialog = (props: Props) => {
    const { isOpen, onClose, groupId } = props;

    const [isLoading, setIsLoading] = useState(false);

    const [data, setData] = useState<any>({});

    useEffect(() => {
        console.log('group ' + groupId);
        if (!groupId) {
            return;
        }

        setData({});
        setIsLoading(true);
        api.request('/admin/whatsapp/messages/' + groupId).then((res: any) => {
            setData(res);
            setIsLoading(false);
        });
    }, [groupId]);

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => onClose()
        },
    ];

    return (
        <>
            <CustomDialogWrapper open={isOpen} onClose={() => onClose()} title={'Destinatari messaggio'} buttons={buttons} isLoading={isLoading} maxWidth='md' fullWidth >
                <TableContainer component={Paper}>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Gruppo</TableCell>
                                <TableCell>Invio effettivo</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(data?.messages ?? []).sort((mail1: any, mail2: any) => {
                                return mail1.groupName.localeCompare(mail2.groupName);
                            }).map((v: any, idx: number) => {
                                return (
                                    <TableRow style={{ backgroundColor: (!v.sentAt ? '#fdecea' : '#edf7ed') }} >
                                        <TableCell>{idx + 1}</TableCell>
                                        <TableCell>{v.groupName}</TableCell>
                                        <TableCell>{v.sentAt ? moment(v.sentAt).format('DD/MM/YYYY HH:mm:ss') : ''}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CustomDialogWrapper>
        </>
    );
};

export default MessageRecipientsDialog;
