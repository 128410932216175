import { useCallback, useEffect, useState } from 'react';

import { ButtonProps } from '@material-ui/core/Button';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import api from '../../../../../../common/utils/api';
import GenericObject from '../../../../../../typesAdditional/GenericObject';
import RegistrationsTable from '../../../registrationLocationPage/components/RegistrationsTable/RegistrationsTable';
import { useHistory } from 'react-router-dom';

interface Props {
    open: boolean;
    closeDialog: (changed?: boolean) => void;
    refreshData: () => void;
    person?: GenericObject;
}

const PersonRegistrationsDialog = (props: Props) => {
    const { open, closeDialog, person, refreshData: refreshExternalData } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [registrations, setRegistrations] = useState<GenericObject[]>([]);

    const history = useHistory();

    const refreshData = useCallback(() => {
        setIsLoading(true);
        api.request('/admin/people/' + person?.id + '/registrations').then(res => {
            setRegistrations(res);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [person]);

    useEffect(() => {
        if (!open) return;

        setRegistrations([]);
        refreshData();
    }, [open, refreshData]);

    const buttons: ButtonProps[] = [
        {
            children: 'Aggiungi iscrizione',
            color: 'primary',
            onClick: () => {
                localStorage.setItem('new_registration_person_id', person?.id);
                history.push((window.location.host.startsWith('admin.epuntos.it') ? '' : '/admin') + '/registrations/new');
            }
        },
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        },
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Iscrizioni di ' + person?.surname + ' ' + person?.name} buttons={buttons} isLoading={isLoading} maxWidth='lg' fullWidth>
        <RegistrationsTable
            registrations={registrations}
            refreshRegistrations={() => {
                refreshData();
                refreshExternalData();
            }}
            isLoading={isLoading}
            compactView
        />
        </CustomDialogWrapper>
    );
};

export default PersonRegistrationsDialog;
