import { TableBody, TableCell, TableContainer, TableHead, Tooltip, FormControlLabel, Checkbox, MenuItem } from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/it';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import ChangeHistoryTwoToneIcon from '@material-ui/icons/ChangeHistoryTwoTone';
import React, { useCallback } from 'react';
import GenericObject from '../../../../../../../../typesAdditional/GenericObject';
import CustomTextField from '../../../../../../../../common/components/CustomTextField/CustomTextField';

interface Props {
    selectedWeek?: GenericObject;
    instructor: GenericObject;
    isEntrySelected?: (day: number, dayTime: string) => boolean
    selectEntry?: (day: number, dayTime: string, selected: boolean) => void;
    setDetailedAvailability?: (day: number, dayTime: string, availability: string) => void;
    changes?: GenericObject[];
    readOnlyCheckbox?: boolean;
}

const AvailablityTable = (props: Props) => {
    const { selectedWeek, instructor, isEntrySelected, selectEntry, setDetailedAvailability, changes, readOnlyCheckbox } = props;

    const getDetailedAvailability = useCallback((instructorData, day, dayTime) => {
        const currentData = instructorData?.find((x: any) => x.day === day && x.dayTime === dayTime);

        if (currentData) {
            return currentData.availability ?? 'no';
        } else {
            return 'no';
        }
    }, []);

    const getDetailedAvailabilityOtherLocations = useCallback((instructorData, day, dayTime) => {
        const currentData = instructorData?.find((x: any) => x.day === day && x.dayTime === dayTime);

        if (currentData) {
            return currentData.otherLocations ?? [];
        } else {
            return [];
        }
    }, []);

    // TODO: just temporary, must be moved somewhere else to prevent loading it for each trainer
    const firstDay = instructor.firstDay;
    const lastDay = instructor.lastDay;

    const showCheckbox = isEntrySelected !== undefined && selectEntry !== undefined;

    return (
        <TableContainer style={{ marginTop: '16px' }} >
            <Table size='small'>
                <TableHead>
                    <TableRow>
                        {!setDetailedAvailability && (<TableCell></TableCell>)}

                        {[...Array(6).keys()].map((day) => {
                            const realDay = day + 1;

                            if (realDay < firstDay || realDay > lastDay) return null;

                            return (
                                <TableCell style={{ width: ((100 - 10) / (lastDay - firstDay + 1)) + '%', textAlign: 'center' }}>
                                    {moment(selectedWeek?.startDate).add(day, 'days').format('dddd')}{(lastDay - firstDay) > 4 ? <br /> : ' '}
                                    {moment(selectedWeek?.startDate).add(day, 'days').format('DD/MM')}
                                </TableCell>
                            )
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {['morning', 'afternoon'].map((dayTime) => {
                        const label = dayTime === 'morning' ? 'mattino' : 'pomeriggio';

                        return (
                            <TableRow>
                                {!setDetailedAvailability && (<TableCell><b>{label}</b></TableCell>)}

                                {[...Array(6).keys()].map((day) => {
                                    const realDay = day + 1;

                                    if (realDay < firstDay || realDay > lastDay) return null;

                                    const availability = getDetailedAvailability(instructor.availabilities, (realDay), dayTime);
                                    const color = availability === 'yes' ? '#4caf50' : (availability === 'no' ? '#f44336' : '#ff9800');
                                    const tooltip = availability === 'yes' ? 'Disponibilie' : (availability === 'no' ? 'Non disponibile' : 'Disponibilità incerta');

                                    const otherLocations = getDetailedAvailabilityOtherLocations(instructor.availabilities, realDay, dayTime);
                                    const otherLocationsDesc = otherLocations.length > 1 ? (otherLocations.slice(0, -1).join(', ') + ' e ' + otherLocations.slice(-1)) : otherLocations[0];

                                    const newAvailability = changes ? (changes.find((x: any) => x.day === realDay && x.dayTime === dayTime)?.newAvailability ?? null) : null;

                                    type status = 'yes' | 'uncertain' | 'no';

                                    const statusMap = {
                                        yes: {
                                            color: '#edf7ed',
                                            borderColor: '#4caf50',
                                            label: 'Non impattante',
                                            outlined: false
                                        },
                                        uncertain: {
                                            color: '#fff4e5',
                                            borderColor: '#ff9800',
                                            label: 'Impattante, sostituibile',
                                            outlined: false
                                        },
                                        no: {
                                            color: '#fdecea',
                                            borderColor: '#f44336',
                                            label: 'Impattante, non sostituibile',
                                            outlined: false
                                        },
                                    }

                                    const statusData = statusMap[newAvailability as status];

                                    return (

                                        <TableCell style={{ lineHeight: '0', textAlign: 'center', backgroundColor: statusData ? statusData.color : '#ffffff' }}>
                                            {setDetailedAvailability ? (
                                                <>
                                                    <CustomTextField
                                                        label={label}
                                                        variant='outlined'
                                                        value={availability}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDetailedAvailability(realDay, dayTime, e.target.value)}
                                                        select
                                                        size='small'
                                                        style={{ width: '100%', backgroundColor: '#ffffff', marginTop: '6px' }}
                                                    >
                                                        <MenuItem value={'yes'}>
                                                            sì
                                                        </MenuItem>
                                                        <MenuItem value={'no'}>
                                                            no
                                                        </MenuItem>
                                                        {/*
                                                        <MenuItem value={'uncertain'}>
                                                            forse
                                                        </MenuItem>
                                                        */}
                                                    </CustomTextField>
                                                </>
                                            ) : (
                                                <>
                                                    {otherLocations.length > 0 && (
                                                        <span style={{ width: '1px', height: '1px', display: 'block', margin: '0 auto', marginTop: '-1px' }}>
                                                            <span style={{ right: showCheckbox ? '56px' : '38px', top: '3px', position: 'relative' }}>
                                                                <Tooltip title={'Già assegnato a ' + otherLocationsDesc} arrow placement='right'>
                                                                    <ChangeHistoryTwoToneIcon style={{ height: '26px', width: '26px', marginTop: '-5px', marginBottom: '-5px', color: '#ff9800', display: 'inline-block' }} />
                                                                </Tooltip>
                                                            </span>
                                                        </span>
                                                    )}

                                                    <Tooltip title={tooltip} arrow placement='right'>
                                                        <span style={{ ...(showCheckbox ? { height: '18px', width: '18px' } : { height: '22px', width: '22px' }), backgroundColor: color, borderRadius: '50%', display: 'inline-block' }}></span>
                                                    </Tooltip>

                                                    {showCheckbox && (
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    color='primary'
                                                                    style={{ padding: '0px' }}
                                                                    checked={isEntrySelected(realDay, dayTime)}
                                                                    onChange={(e) => {
                                                                        if (readOnlyCheckbox) return;

                                                                        selectEntry(realDay, dayTime, e.target.checked);
                                                                    }}
                                                                    inputProps={{ readOnly: readOnlyCheckbox }}
                                                                />
                                                            }
                                                            style={{ display: 'inline-block', marginTop: '-8px', marginLeft: '6px', marginRight: '6px' }}
                                                            label={''}
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default React.memo(AvailablityTable)