import { useCallback, useEffect } from 'react';
import { useWindowSize } from 'react-use';

import { IconButton, Link } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { v4 as uuidv4 } from 'uuid';

import CustomTextField from '../../../../../common/components/CustomTextField/CustomTextField';
import { useMessageDialog } from '../../../../../common/hooks/useMessageDialog/useMessageDialog';
import TownAutoComplete from '../../registrationPage/components/GenericPersonForm/sections/address/components/TownAutocomplete/TownAutocomplete';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';

interface Props {
    currentPerson: number;
    nextStep: () => void;
    prevStep: () => void;
    progress: number;
    category: string;
    quoteInfo: any;
    data: any;
    setData: (data: any) => void;
    rawOnChange: (x: any, y: any) => void;
}

const Step3 = ({ currentPerson, nextStep, prevStep, progress, category, quoteInfo, data, setData, rawOnChange }: Props) => {
    const [showMessageDialog] = useMessageDialog();

    const { width } = useWindowSize();

    useEffect(() => {
        if ((data?.people[currentPerson - 1]?.items ?? []).length === 0) {
            rawOnChange('people[' + (currentPerson - 1) + '].items', [{
                id: uuidv4(),
                courseId: '',
                locationId: '',
                discountId: '',
                sessions: 1,
                sessionsFoodService: 0
            }]);
        }
    }, [rawOnChange, currentPerson, data]);

    const addItem = useCallback(() => {
        setData((d: any) => {
            const oldData = {...d};
            const oldItems = d.people[currentPerson - 1].items;
            const newItems = [...oldItems, {
                id: uuidv4(),
                courseId: '',
                locationId: '',
                discountId: '',
                sessions: 1,
                sessionsFoodService: 0
            }];
            oldData.people[currentPerson - 1].items = newItems;
            return oldData;
        });
    }, [setData, currentPerson]);

    const validateAndNextStep = useCallback(() => {
        let error = null;

        if (!data.people[currentPerson - 1]?.address?.town_legal_id) {
            error = 'È necessario specificare il paese di residenza.';
        } else {
            let item: any;
            let idx = 0;
            const itmCount = (data.people[currentPerson - 1]?.items ?? []).length;

            for (item in (data.people[currentPerson - 1]?.items ?? [])) {
                if ((data.people[currentPerson - 1]?.items ?? []).hasOwnProperty(item)) {
                    if (!(data.people[currentPerson - 1]?.items ?? [])[item].courseId) {
                        error = 'Il camp selezionato ' + (itmCount > 1 ? ('nel blocco ' + (idx + 1) + ' ') : '') + 'non è valido.';
                        break;
                    } else if (!(data.people[currentPerson - 1]?.items ?? [])[item].locationId) {
                        error = 'Il paese di iscrizione selezionato ' + (itmCount > 1 ? ('nel blocco ' + (idx + 1) + ' ') : '') + 'non è valido.';
                        break;
                    }

                    idx++;
                }
            }
        }

        if (error) {
            showMessageDialog({
                title: 'Dati incompleti',
                message: error
            });
            return;
        }

        nextStep();
    }, [nextStep, showMessageDialog, currentPerson, data]);

    return (
        <>
            <Typography variant='h2' style={{fontSize: '25px', fontFamily: '\'Libre Franklin\', sans-serif', textAlign: 'center', margin: '8px'}}>
                Figlio {currentPerson}
            </Typography>

            <Header progress={progress} />

            <div style={{margin: '24px auto'}}>

                <div style={{display: 'flex', width: '100%', maxWidth: '460px', margin: '0 auto'}}>
                    {width > 500 && (<div style={{width: '55px', flexShrink: 0}} />)}
                    <div style={{flexGrow: 1, marginBottom: '24px'}}>
                        <TownAutoComplete data={data} formPrefix={'people[' + (currentPerson - 1) + ']'} rawOnChange={rawOnChange} />
                    </div>
                    {width > 500 && (<div style={{width: '55px', flexShrink: 0}} />)}
                </div>

                {(data?.people[currentPerson - 1]?.items ?? []).map(((item: any, idx: number) => {
                    const availableSessions = quoteInfo
                        ?.categories.find((c: any) => c.slug === category)
                        ?.courses?.find((c: any) => c.id === item.courseId)
                        ?.locations?.find((l: any) => l.id === item.locationId)
                        ?.countSessions ?? 0;

                    const availableFoodSessions = quoteInfo
                        ?.categories.find((c: any) => c.slug === category)
                        ?.courses?.find((c: any) => c.id === item.courseId)
                        ?.locations?.find((l: any) => l.id === item.locationId)
                        ?.countFoodSessions ?? 0;

                    const discounts = quoteInfo
                        ?.categories.find((c: any) => c.slug === category)
                        ?.courses?.find((c: any) => c.id === item.courseId)
                        ?.locations?.find((l: any) => l.id === item.locationId)
                        ?.discounts ?? [];

                    return (
                        <div style={{display: 'flex', width: '100%', maxWidth: '460px', margin: '0 auto'}}>
                            {(width > 500 || (data?.people[currentPerson - 1]?.items ?? []).length > 1) && (
                                <div style={{width: '55px', flexShrink: 0}}>
                                    {(data?.people[currentPerson - 1]?.items ?? []).length > 1 && (
                                        <div style={{flexDirection: 'column', backgroundColor: idx % 2 === 0 ? '#DEEBF7' : '#9ECAE1', height: 'calc(100% - 14px)', margin: '4px 10px 0px 0px', boxSizing: 'border-box', borderRadius: '8px', textAlign: 'center', padding: '14px 0', display: 'flex'}} >
                                            <Typography variant='h2' style={{fontSize: '2em'}}>
                                                {idx + 1}
                                            </Typography>
                                            <div style={{flexGrow: 1}} />
                                            <div>
                                                <IconButton aria-label='delete' onClick={() => {
                                                    setData((d: any) => {
                                                        const oldData = {...d};
                                                        const oldItems = d.people[currentPerson - 1].items;
                                                        const newItems = oldItems.filter((i: any) => i.id !== item.id);
                                                        oldData.people[currentPerson - 1].items = newItems;
                                                        return oldData;
                                                    });
                                                }}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}

                            <div style={{flexGrow: 1}}>
                                <CustomTextField
                                    select
                                    label={'Camp estivo'}
                                    value={item.courseId}
                                    onChange={(e: any) => {
                                        rawOnChange('people[' + (currentPerson - 1) + '].items[' + idx + '].courseId', e.target.value);
                                        rawOnChange('people[' + (currentPerson - 1) + '].items[' + idx + '].locationId', '');
                                        rawOnChange('people[' + (currentPerson - 1) + '].items[' + idx + '].sessions', 1);
                                        rawOnChange('people[' + (currentPerson - 1) + '].items[' + idx + '].sessionsFoodService', 0);
                                    }}
                                    variant='outlined'
                                    fullWidth
                                    style={{marginTop: '6px'}}
                                    keepMounted
                                >
                                    {(quoteInfo.categories.find((c: any) => c.slug === category)?.courses ?? [])
                                        .sort((course1: any, course2: any) => {
                                            return course1.name.localeCompare(course2.name);
                                        }).map((course: any) => {
                                            return (
                                                <MenuItem value={course.id} style={{whiteSpace: 'break-spaces'}} >
                                                    {course.name}
                                                </MenuItem>
                                            );
                                    })}
                                 </CustomTextField>

                                 <CustomTextField
                                     select
                                     label={'Paese di iscrizione'}
                                     value={item.locationId}
                                     onChange={(e: any) => {
                                         const newLocation = quoteInfo
                                             .categories.find((c: any) => c.slug === category)
                                             ?.courses?.find((c: any) => c.id === item.courseId)
                                             ?.locations?.find((l: any) => l.id === e.target.value);

                                         rawOnChange('people[' + (currentPerson - 1) + '].items[' + idx + '].locationId', e.target.value);
                                         rawOnChange('people[' + (currentPerson - 1) + '].items[' + idx + '].discountId', '');
                                         rawOnChange('people[' + (currentPerson - 1) + '].items[' + idx + '].sessions', Math.min(item.sessions, newLocation.countSessions));
                                         rawOnChange('people[' + (currentPerson - 1) + '].items[' + idx + '].sessionsFoodService', Math.min(item.sessionsFoodService, newLocation.countSessions, newLocation.countFoodSessions));
                                     }}
                                     variant='outlined'
                                     fullWidth
                                     style={{marginTop: '6px'}}
                                     keepMounted
                                 >
                                     {(quoteInfo.categories.find((c: any) => c.slug === category)?.courses?.find((c: any) => c.id === item.courseId)?.locations ?? [])
                                         .sort((location1: any, location2: any) => {
                                             return location1.town.localeCompare(location2.town);
                                         }).map((location: any) => {
                                             return (
                                                 <MenuItem value={location.id} style={{whiteSpace: 'break-spaces'}} >
                                                     {location.town}
                                                 </MenuItem>
                                             );
                                     })}
                                     {!item.courseId && (
                                         <MenuItem value={'disabled'} style={{whiteSpace: 'break-spaces'}} disabled >
                                             Seleziona prima il camp
                                         </MenuItem>
                                     )}
                                  </CustomTextField>

                                  {discounts.map((d: any) => {
                                      return (
                                          <>
                                              <CustomTextField
                                                  select
                                                  label={d.question}
                                                  value={item.discountId === d.id ? 'true' : 'false'}
                                                  onChange={(e: any) => {
                                                      rawOnChange('people[' + (currentPerson - 1) + '].items[' + idx + '].discountId', e.target.value === 'true' ? d.id : '');
                                                  }}
                                                  variant='outlined'
                                                  fullWidth
                                                  style={{marginTop: '6px'}}
                                                  keepMounted
                                              >
                                                  <MenuItem value={'true'} style={{whiteSpace: 'break-spaces'}} >
                                                    Sì
                                                  </MenuItem>
                                                  <MenuItem value={'false'} style={{whiteSpace: 'break-spaces'}} >
                                                    No
                                                  </MenuItem>
                                               </CustomTextField>
                                          </>
                                      );
                                  })}

                                  {(width > 850 && idx === 0 && (!item.locationId || availableSessions > 1)) && (
                                      <div style={{position: 'relative'}}>
                                           <div style={{position: 'absolute', left: '365px', top: '12px', width: '220px', fontSize: '1.07em'}}>
                                               PS: ogni settimana aggiuntiva costerà di meno
                                           </div>
                                      </div>
                                  )}

                                  <CustomTextField
                                      select
                                      label={'Numero di settimane'}
                                      value={item.sessions}
                                      onChange={(e: any) => {
                                          rawOnChange('people[' + (currentPerson - 1) + '].items[' + idx + '].sessions', e.target.value);
                                          rawOnChange('people[' + (currentPerson - 1) + '].items[' + idx + '].sessionsFoodService', Math.min(item.sessionsFoodService, e.target.value, availableFoodSessions));
                                      }}
                                      variant='outlined'
                                      fullWidth
                                      style={{marginTop: '6px'}}
                                      keepMounted
                                      helperText={width <= 850 ? 'PS: ogni settimana aggiuntiva costerà di meno' : undefined}
                                  >
                                      {(Array.from(Array(Math.max(1, availableSessions) + 1).keys()).slice(1)).map((i: any) => {
                                              return (
                                                  <MenuItem value={i} style={{whiteSpace: 'break-spaces'}} >
                                                      {i}
                                                  </MenuItem>
                                              );
                                      })}
                                   </CustomTextField>

                                   {(!item.locationId || availableFoodSessions > 0) && (
                                       <CustomTextField
                                           select
                                           label={'Per quante settimane vuoi il servizio pasti?'}
                                           value={item.sessionsFoodService}
                                           onChange={(e: any) => {
                                               rawOnChange('people[' + (currentPerson - 1) + '].items[' + idx + '].sessionsFoodService', e.target.value);
                                           }}
                                           variant='outlined'
                                           fullWidth
                                           style={{marginTop: '6px'}}
                                           keepMounted
                                       >
                                           {(Array.from(Array(Math.min(availableFoodSessions + 1, item.sessions + 1)).keys())).map((i: any) => {
                                                   return (
                                                       <MenuItem value={i} style={{whiteSpace: 'break-spaces'}} >
                                                           {i}
                                                       </MenuItem>
                                                   );
                                           })}
                                        </CustomTextField>
                                   )}
                                </div>

                                {width > 500 && (
                                    <div style={{width: '55px', flexShrink: 0}} />
                                )}

                        </div>
                    );
                }))}

                <div style={{width: '100%', maxWidth: '460px', margin: '8px auto', paddingLeft: (width > 500 || (data?.people[currentPerson - 1]?.items ?? []).length > 1) ? '55px' : undefined}}>
                    <Fab color='primary' aria-label='add' size='small' onClick={() => addItem()}>
                        <AddIcon />
                    </Fab>
                    <span style={{marginLeft: '10px'}}>
                        <Link href='#' onClick={(e: any) => {
                            e.preventDefault();
                            addItem();
                            return false;
                        }}>Aggiungi un altro Camp</Link>
                    </span>
                    <div style={{fontSize: '1.07em', marginLeft: '50px'}}>
                        PS: ogni settimana aggiuntiva costerà di meno
                    </div>
                </div>

            </div>

            <Footer nextStep={validateAndNextStep} prevStep={prevStep} />
        </>
    );
};

export default Step3;
