import { createSlice } from '@reduxjs/toolkit';

import initialState from './initialState';

export const drawerSlice = createSlice({
    name: 'drawerSlice',
    initialState,
    reducers: {
        open: (state) => {
            return {
                open: true
            }
        },
        close: (state) => {
            return {
                open: false
            }
        },
        toggle: (state) => {
            return {
                open: !state.open
            }
        }
    }
});

export const { open, close, toggle } = drawerSlice.actions;

export default drawerSlice.reducer;
