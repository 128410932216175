import { FieldDefinition } from '../types';

export type FieldName = 'name' | 'surname' | 'tax_code' | 'date_of_birth' | 'place_of_birth_legal_id';

export const basicDataWithoutTaxCodeFields: FieldDefinition<FieldName>[] = [
    {
        name: 'name',
        label: 'nome'
    },
    {
        name: 'surname',
        label: 'cognome'
    }
];

const taxCodeFields: FieldDefinition<FieldName>[] = [
    {
        name: 'tax_code',
        label: 'codice fiscale'
    },
    {
        name: 'date_of_birth',
        label: 'data di nascita'
    }
];

const taxCodeFieldsAlternative: FieldDefinition<FieldName>[] = [
    {
        name: 'place_of_birth_legal_id',
        label: 'luogo di nascita'
    },
    {
        name: 'date_of_birth',
        label: 'data di nascita'
    }
];

const basicDataFields = [...basicDataWithoutTaxCodeFields, ...taxCodeFields];

export const basicDataFieldsNoTaxCodeFields = [...basicDataWithoutTaxCodeFields, ...taxCodeFieldsAlternative];

export default basicDataFields;
