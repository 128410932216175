import { useMemo } from 'react';

import GenericObject from '../../../../../../../typesAdditional/GenericObject';

interface Props {
    formPrefix: string;
    data: GenericObject;
}

const useValidatePhoneNumber = (props: Props) => {
    const { formPrefix, data } = props;

    const phone = (data[formPrefix]?.phone ?? '').replace(/ /g, '').replace(/\./g, '').replace(/-/g, '');

    return useMemo(() => {
        return {
            'phone': (phone.length > 7 && phone.length < 15 && /^[0-9+]+$/.test(phone))
        };
    }, [phone]);

};

export default useValidatePhoneNumber;
