import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ButtonProps } from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import BoringAvatar from 'boring-avatars';
import Base64 from 'crypto-js/enc-base64';
import sha256 from 'crypto-js/sha256';
import { useDebounce } from 'use-debounce';
import { v4 as uuidv4 } from 'uuid';

import CustomDialogWrapper from '../../../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import api from '../../../../../../../../common/utils/api';
import GenericObject from '../../../../../../../../typesAdditional/GenericObject';
import EntityListItem from './components/EntityListItem';

interface Props {
    open: boolean;
    closeDialog: () => void;
    onPersonSelect: (data: any) => void;
}

const PersonSelectDialog = (props: Props) => {
    const { open, closeDialog, onPersonSelect } = props;

    const [paginationPage, setPaginationPage] = useState(0);
    const [, setPaginationTotal] = useState(0);
    const [paginationItemsPerPage] = useState(25);

    const [isLoading, setIsLoading] = useState(false);
    const [people, setPeople] = useState<GenericObject[]>([]);
    const [query, setQuery] = useState('');
    const [loadedQuery, setLoadedQuery] = useState('');
    const [loadedPage, setLoadedPage] = useState(0);
    const [loadedItemsPerPage, setLoadedItemsPerPage] = useState(25);
    const [debouncedQuery] = useDebounce(query, 500);

    const apiRequestId = useRef('');

    useEffect(() => {
        if (open) {
            setQuery('');
        }
    }, [open]);

    useEffect(() => {
        setIsLoading(query !== loadedQuery || paginationPage !== loadedPage || paginationItemsPerPage !== loadedItemsPerPage);
    }, [query, loadedQuery, paginationPage, loadedPage, paginationItemsPerPage, loadedItemsPerPage]);

    useEffect(() => {
        setPaginationPage(0);
    }, [debouncedQuery]);

    const refreshData = useCallback(() => {
        const currentRequestId = uuidv4();
        apiRequestId.current = currentRequestId;

        api.request('/admin/people', 'GET', { q: debouncedQuery, page: paginationPage + 1, perPage: paginationItemsPerPage }).then((res: any) => {
            if (currentRequestId !== apiRequestId.current) return;

            const { data, total, lastPage } = res;
            setPeople(data);
            setPaginationTotal(total);
            setLoadedQuery(debouncedQuery);
            setLoadedPage(paginationPage);
            setLoadedItemsPerPage(paginationItemsPerPage);

            if (paginationPage + 1 > lastPage) {
                setPaginationPage(lastPage - 1);
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }, [debouncedQuery, setIsLoading, paginationPage, paginationItemsPerPage]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            refreshData();
        }, 10);

        return () => clearTimeout(timeout);
    }, [refreshData]);

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        },
    ];

    const peopleWithMail = useMemo(() => {
        return people.reduce((prev: GenericObject[], curr) => {
            const thisPersonCombinations = curr.emailsPhones.length ? curr.emailsPhones.map((emailsPhone: GenericObject) => {
                return {
                    ...curr,
                    emailsPhones: undefined,
                    email: emailsPhone.email,
                    phone: emailsPhone.phone
                };
            }) : [{
                ...curr,
                emailsPhones: undefined,
                email: '',
                phone: ''
            }];

            return [...prev, ...thisPersonCombinations];
        }, []);
    }, [people]);

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Ricerca in anagrafica'} buttons={buttons} isLoading={isLoading} maxWidth='sm' fullWidth>
            <TextField
                label='Ricerca utenti'
                variant='outlined'
                placeholder='Ricerca per nome, cognome, codice fiscale o indirizzo email'
                value={query}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQuery(e.target.value)}
                InputProps={{
                    startAdornment: <InputAdornment position='start'><SearchIcon /></InputAdornment>,
                }}
                style={{width: '100%', maxWidth: '600px', marginBottom: '16px'}}
            />

            {peopleWithMail.map((person) => {
                const taxCodeHash = Base64.stringify(sha256((person.taxCode.length ? person.taxCode : person.id) + person.email));

                return (
                    <EntityListItem
                        name={person.name + ' ' + person.surname}
                        description={(person.taxCode ? (person.taxCode + (person.email ? ' - ' : '')) : '') + person.email}
                        image={(
                            <div style={{marginRight: '12px'}}>
                                <BoringAvatar
                                    name={taxCodeHash}
                                    variant='beam'
                                    colors={['#FFAD08', '#EDD75A', '#73B06F', '#0C8F8F', '#405059']}
                                />
                            </div>
                        )}
                        onClick={() => onPersonSelect(person)}
                    />
                );
            })}
        </CustomDialogWrapper>
    );
};

export default PersonSelectDialog;
