import { useRef, useEffect, useState, useCallback } from 'react';

import { ButtonProps } from '@material-ui/core/Button';
import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import api from '../../../../../../common/utils/api';
import { v4 as uuidv4 } from 'uuid';
import 'moment/locale/it';
import CustomTextField from '../../../../../../common/components/CustomTextField/CustomTextField';
import snackbar from '../../../../../../common/utils/snackbar';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import 'moment/locale/it';
import MomentUtils from '@date-io/moment';
import { Checkbox, FormControlLabel } from '@material-ui/core';

interface Props {
    open: boolean;
    closeDialog: (shouldRefresh?: boolean) => void;
    superUserId: string;
    extraWageId?: string;
    month?: string;
}

const getDefaultDate = (month?: string) => {
    if (!month) return '2024-09-01';

    const realMonth = parseInt(month);

    if (realMonth >= 9) {
        return '2024-' + month + '-01';
    } else {
        return '2025-' + month + '-01';
    }
}

const ChangeExtraWageDialog = (props: Props) => {
    const { open, closeDialog, superUserId, extraWageId, month } = props;

    const [isLoading, setIsLoading] = useState(false);

    const [hours, setHours] = useState('0.00');
    const [amount, setAmount] = useState('0.00');
    const [notes, setNotes] = useState('');
    const [date, setDate] = useState(getDefaultDate(month));
    const [automaticAmount, setAutomaticAmount] = useState(true);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

    const apiRequestId = useRef('');

    useEffect(() => {
        if (!superUserId || !open || !extraWageId) return;

        const currentRequestId = uuidv4();
        apiRequestId.current = currentRequestId;
        setIsLoading(true);

        api.request('/admin/winter_staff/extra_wages/' + extraWageId).then(res => {
            if (currentRequestId !== apiRequestId.current) return;

            setHours(parseFloat(res.hours).toFixed(2));
            setAmount(parseFloat(res.amount).toFixed(2));
            setNotes(res.notes);
            setDate(res.date);
            setAutomaticAmount(res.type === 'hours');
            setIsLoading(false);
        })
    }, [superUserId, open, extraWageId]);

    useEffect(() => {
        if (!open) return;

        setHours('0.00');
        setAmount('0.00');
        setNotes('');
        setDate(getDefaultDate(month));
        setAutomaticAmount(true);
    }, [open, month]);

    const saveData = useCallback(() => {
        setIsLoading(true);
        api.request('/admin/winter_staff/extra_wages' + (extraWageId ? ('/' + extraWageId) : ''), extraWageId ? 'PUT' : 'POST', { superUserId, hours, amount, date, notes, type: automaticAmount ? 'hours' : 'amount' }).then(res => {
            setIsLoading(false);
            snackbar.success('Compenso salvato con successo!');
            closeDialog(true);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [closeDialog, superUserId, date, hours, amount, automaticAmount, notes, extraWageId]);

    const buttons: ButtonProps[] = [
        {
            children: 'Annulla',
            color: 'primary',
            onClick: () => closeDialog()
        },
        {
            children: 'Salva',
            color: 'primary',
            onClick: () => saveData()
        },
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={(extraWageId ? 'Modifica' : 'Aggiungi') + ' compenso aggiuntivo'} buttons={buttons} isLoading={isLoading} maxWidth='sm' fullWidth>
            <FormControlLabel
                control={
                    <Checkbox
                        color='primary'
                        style={{ padding: '4px' }}
                        checked={automaticAmount}
                        onChange={(e) => {
                            setAutomaticAmount(e.target.checked);
                        }}
                    />
                }
                style={{ marginLeft: '-4px', marginBottom: '16px' }}
                label={'Calcola automaticamente compenso'}
                disabled={isLoading}
            />

            <div style={{ display: 'flex' }}>
                <MuiPickersUtilsProvider locale='it' utils={MomentUtils}>
                    <KeyboardDatePicker
                        label='Data'
                        inputVariant='outlined'
                        format='DD/MM/YYYY'
                        value={date}
                        onChange={(newDate: MaterialUiPickersDate) => {
                            setDate(newDate?.format('YYYY-MM-DD') ?? '');
                        }}
                        open={isDatePickerOpen}
                        onClick={() => {
                            setIsDatePickerOpen(true);
                        }}
                        onClose={() => setIsDatePickerOpen(false)}
                        onKeyDown={(e) => {
                            e.preventDefault();
                            return false;
                        }}
                        style={{ flex: 1, marginRight: '6px' }}
                        inputProps={{ tabIndex: -1 }}
                        KeyboardButtonProps={{ tabIndex: -1 }}
                        fullWidth
                        disabled={isLoading}
                        minDate={'2024-09-01'}
                        maxDate={'2025-06-30'}
                    />
                </MuiPickersUtilsProvider>

                <CustomTextField
                    label={'Ore'}
                    variant='outlined'
                    value={hours === 'NaN' ? '0.00' : hours}
                    keepMounted={true}
                    onChange={(e) => {
                        setHours(e.target.value);
                    }}
                    onBlur={() => {
                        setHours((val: any) => {
                            return Math.max(((typeof val === 'string') ? parseFloat(val.replaceAll(',', '.')) : val), -Infinity).toFixed(2);
                        });
                    }}
                    disabled={isLoading}
                    style={{ flex: 1, marginLeft: '6px' }}
                />

                {!automaticAmount && (
                    <CustomTextField
                        label={'Compenso'}
                        variant='outlined'
                        value={amount === 'NaN' ? '0.00' : amount}
                        keepMounted={true}
                        onChange={(e) => {
                            setAmount(e.target.value);
                        }}
                        onBlur={() => {
                            setAmount((val: any) => {
                                return Math.max(((typeof val === 'string') ? parseFloat(val.replaceAll(',', '.')) : val), -Infinity).toFixed(2);
                            });
                        }}
                        disabled={isLoading}
                        style={{ flex: 1, marginLeft: '6px' }}
                    />
                )}
            </div>

            <CustomTextField
                label={'Note'}
                variant='outlined'
                value={notes}
                keepMounted={true}
                onChange={(e) => {
                    setNotes(e.target.value);
                }}
                disabled={isLoading}
            />
        </CustomDialogWrapper >
    );
};

export default ChangeExtraWageDialog;
