import Divider from "@material-ui/core/Divider";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Link } from "react-router-dom";
import moment from 'moment';
import 'moment/locale/it';
import { useSelector } from "react-redux";
import { ReduxState } from "../../../../../../redux/types";

interface Props {
    superUserId: string;
    facilityId?: string;
    selectedTab: string;
    incrementZoom: () => void;
    decrementZoom: () => void;
    zoomLevel: number;
    setZoomLevel: (zoom: string) => void;
    isWeekVisible: boolean;
}

const Footer = ({ superUserId, facilityId, selectedTab, isWeekVisible, ...zoomControlProps }: Props) => {
    const a11yProps = (index: number) => {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }

    const userInfo = useSelector((state: ReduxState) => state.user);
    const isAdmin = (userInfo?.administrator ?? false) && (userInfo?.canManageStaff ?? false);

    return (
        <div>
            <Divider />
            <div style={{ display: 'flex' }}>
                <Tabs
                    value={selectedTab}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {[9, 10, 11, 12, 1, 2, 3, 4, 5, 6].map((month: number, idx: number) => {
                        return (
                            <Tab label={moment().month(month - 1).format("MMMM")} component={Link} to={(window.location.host.startsWith('admin.epuntos.it') ? '' : '/admin') + (isAdmin ? ('/staff-winter/' + (facilityId ? 'detailed-facility-timetable' : 'detailed-timetable') + '/' + (facilityId ?? superUserId) + '/' + month.toString()) : ('/winter-schedule/timetable/' + month.toString()))} value={month.toString()} {...a11yProps(idx)} />
                        )
                    })}
                </Tabs>

                <div style={{ flexGrow: 1 }} />

                {/*
                {!isMobile && isWeekVisible && (
                    <ZoomControl {...zoomControlProps} />
                )}
                */}
            </div>
        </div>
    )
}

export default Footer;