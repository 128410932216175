import { ButtonProps, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@material-ui/core";
import CustomDialogWrapper from "../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper";
import { useMemo } from "react";
import moment from "moment";

interface Props {
    open: boolean;
    closeDialog: () => void;
    selectedTrainer?: any;
    selectedWage?: any;
    data: any;
}

const WageChangelogDialog = (props: Props) => {
    const { open, closeDialog, selectedTrainer, selectedWage, data } = props;

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        }
    ];

    const wages = useMemo(() => {
        if (!selectedWage) return [];

        const allWages = [...(data?.default?.current ?? []), ...(data?.default?.previous ?? []), ...(data?.custom?.current ?? []), ...(data?.custom?.previous ?? [])];

        const filtered = allWages.filter((w: any) => {
            return w.course?.id === selectedWage.course?.id && w.courseLocation?.id === selectedWage.courseLocation?.id
        })

        return [...filtered].reverse();
    }, [data, selectedWage]);

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Compensi ' + selectedTrainer?.lastName + ' ' + selectedTrainer?.firstName} buttons={buttons} maxWidth='sm' fullWidth>
            <Typography variant='h5' style={{ margin: '0px 0 14px', fontSize: '1.3em', flex: 1 }}>
                {!selectedWage?.course?.id ? 'Compensi orari generali' : (
                    selectedWage.course.name + ' ' + (selectedWage.courseLocation ? (selectedWage.courseLocation.townName + ' ') : '') + (selectedWage.course.season.name.toLowerCase().includes('invernale') ? (selectedWage.course.season.year + '/' + (selectedWage.course.season.year + 1)) : (selectedWage.course.season.year + 1))
                )}
            </Typography>

            <TableContainer style={{ marginTop: '0px' }}>
                <Table size='small'>
                    <TableBody>
                        {wages.map((w: any, idx: number) => {
                            return (
                                <TableRow>
                                    <TableCell>
                                        {w.startDate === '1970-01-01' ? 'dall\'inizio' : ('dal ' + moment(w.startDate).format('DD/MM/YYYY'))}{' '}
                                        {wages[idx + 1] ? ('al ' + moment(wages[idx + 1].startDate).subtract(1, 'day').format('DD/MM/YYYY')) : 'a oggi'}
                                    </TableCell>
                                    <TableCell style={{ width: '1px', paddingRight: 0, textAlign: 'right' }}>
                                        {parseFloat(w.hourlyWage) % 1 === 0 ? parseFloat(w.hourlyWage) : parseFloat(w.hourlyWage).toFixed(2)}
                                    </TableCell>
                                    <TableCell style={{ width: '1px', paddingLeft: '6px' }}>
                                        €/ora
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </CustomDialogWrapper>
    )
}

export default WageChangelogDialog;