import { useCallback, useEffect, useState } from "react";
import useInternalLoader from "../../../../common/hooks/useInternalLoader/useExternalLoader";
import api from "../../../../common/utils/api";
import GenericObject from "../../../../typesAdditional/GenericObject";
import { Chip, IconButton, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import Header from "./components/Header/Header";
import { PageContainer } from "./styled";
import DynamicTableTooltip from "./components/DynamicTableTooltip/DynamicTableTooltip";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VariationDetailDialog from "./components/VariationDetailDialog/VariationDetailDialog";
import ApproveVariationDialog from "./components/ApproveVariationDialog/ApproveVariationDialog";
import UnapproveVariationDialog from "./components/UnapproveVariationDialog/UnapproveVariationDialog";
import useLocalStorageListener from "../../../../common/hooks/useLocalStorageListener/useLocalStorageListener";

const SummerAvailabilityVariationPage = () => {
    const setIsLoadingWeekData = useInternalLoader();

    const [data, setData] = useState<GenericObject[]>([]);

    const [selectedVariation, setSelectedVariation] = useState<GenericObject | undefined>(undefined);
    const [isVariationDetailDialogOpen, setIsVariationDetailDialogOpen] = useState(false);
    const [isApproveVariationDialogOpen, setIsApproveVariationDialogOpen] = useState(false);
    const [isUnapproveVariationDialogOpen, setIsUnapproveVariationDialogOpen] = useState(false);

    const [anchor, setAnchorEl] = useState<null | HTMLElement>(null);
    const [menuGroupId, setMenuGroupId] = useState<null | string>(null);

    useLocalStorageListener({ name: 'summerAvailabilityLastUpdated', callback: () => refreshData(true) });

    const handleMenuClick = useCallback((event: React.MouseEvent<HTMLElement>, userId: string) => {
        setAnchorEl(event.currentTarget);
        setMenuGroupId(userId);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const refreshData = useCallback((silent = false) => {
        if (!silent) setIsLoadingWeekData(true);

        api.request('/admin/summer_availability/variations').then(res => {
            setData(res);
            if (!silent) setIsLoadingWeekData(false);
        })
    }, [setIsLoadingWeekData]);

    useEffect(() => {
        refreshData();
    }, [refreshData]);

    return (
        <>
            <PageContainer>
                <Header />

                <div style={{ flexGrow: 1, overflow: 'auto' }}>
                    <TableContainer component={Paper} style={{ marginTop: '16px' }}>
                        <Table size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: '25%', whiteSpace: 'nowrap' }}>Cognome</TableCell>
                                    <TableCell style={{ width: '25%', whiteSpace: 'nowrap' }}>Nome</TableCell>
                                    <TableCell style={{ width: '25%', whiteSpace: 'nowrap' }}>Turni modificati</TableCell>
                                    <TableCell style={{ width: '25%', whiteSpace: 'nowrap' }}>Impatto variazione</TableCell>
                                    <TableCell style={{ width: '25%', whiteSpace: 'nowrap' }}>Motivo variazione</TableCell>
                                    <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>Azioni</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map(person => {
                                    type status = 0 | 1 | 2;

                                    const statusMap = {
                                        0: {
                                            color: '#edf7ed',
                                            borderColor: '#4caf50',
                                            label: 'Non impattante',
                                            outlined: false
                                        },
                                        1: {
                                            color: '#fff4e5',
                                            borderColor: '#ff9800',
                                            label: 'Impattante, sostituibile',
                                            outlined: false
                                        },
                                        2: {
                                            color: '#fdecea',
                                            borderColor: '#f44336',
                                            label: 'Impattante, non sostituibile',
                                            outlined: false
                                        },
                                    }

                                    const statusData = statusMap[person.globalStatus as status];

                                    return (
                                        <TableRow style={{ height: '45px', backgroundColor: person.color }}>
                                            <TableCell style={{ whiteSpace: 'nowrap' }}>{person.superUser.lastName}</TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap' }}>{person.superUser.firstName}</TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap' }}>{person.updatedEntriesDesc}</TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap' }}>
                                                <Chip label={statusData.label} variant={statusData.outlined ? 'outlined' : 'default'} style={{ ...(statusData.outlined ? {} : { backgroundColor: statusData.color, border: '1px solid ' + statusData.borderColor }), width: '190px' }} />
                                            </TableCell>
                                            <DynamicTableTooltip description={person.reason} />
                                            <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>
                                                <IconButton onClick={(e: any) => handleMenuClick(e, person.id)} style={{ padding: '2px 14px' }}>
                                                    <MoreVertIcon />
                                                </IconButton>
                                                <Menu
                                                    anchorEl={anchor}
                                                    keepMounted
                                                    open={!!anchor && menuGroupId === person.id}
                                                    onClose={handleClose}
                                                >

                                                    <MenuItem onClick={() => {
                                                        handleClose();
                                                        setSelectedVariation(person);
                                                        setIsVariationDetailDialogOpen(true);
                                                    }}>
                                                        Visualizza dettagli
                                                    </MenuItem>

                                                    <MenuItem onClick={() => {
                                                        handleClose();
                                                        setSelectedVariation(person);
                                                        setIsApproveVariationDialogOpen(true);
                                                    }}>
                                                        Approva
                                                    </MenuItem>

                                                    <MenuItem onClick={() => {
                                                        handleClose();
                                                        setSelectedVariation(person);
                                                        setIsUnapproveVariationDialogOpen(true);
                                                    }}>
                                                        Rifiuta
                                                    </MenuItem>
                                                </Menu>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>

            </PageContainer >


            <VariationDetailDialog
                open={isVariationDetailDialogOpen}
                closeDialog={() => setIsVariationDetailDialogOpen(false)}
                globalAvailability={selectedVariation?.globalAvailability ?? []}
                detailedClassification={selectedVariation?.detailedClassification ?? []}
                changes={selectedVariation?.changes ?? []}
            />

            <ApproveVariationDialog
                open={isApproveVariationDialogOpen}
                closeDialog={(shouldRefresh?: boolean) => {
                    setIsApproveVariationDialogOpen(false)

                    if (shouldRefresh) {
                        refreshData();
                        localStorage.setItem('summerAvailabilityLastUpdated', Date.now().toString());
                    }
                }}
                selectedVariation={selectedVariation}
            />

            <UnapproveVariationDialog
                open={isUnapproveVariationDialogOpen}
                closeDialog={(shouldRefresh?: boolean) => {
                    setIsUnapproveVariationDialogOpen(false);

                    if (shouldRefresh) {
                        refreshData();
                        localStorage.setItem('summerAvailabilityLastUpdated', Date.now().toString());
                    }
                }}
                selectedVariation={selectedVariation}
            />
        </>
    )
}

export default SummerAvailabilityVariationPage; 