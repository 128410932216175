import { useMemo } from "react";
import GenericObject from "../../../../../../../typesAdditional/GenericObject";

interface Params {
    simulateRegistrationData: GenericObject;
    selectedSessionsSuggestions?: GenericObject[];
    showAllLocationsSessions?: boolean;
    courseId?: string;
}

const useSessionsMergeFilter = (params: Params) => {
    const { simulateRegistrationData, selectedSessionsSuggestions, showAllLocationsSessions, courseId } = params;

    const sessions = useMemo(() => {
        let tempSessions = simulateRegistrationData?.discounts?.selected?.customPricing?.sessions ?? simulateRegistrationData?.sessions ?? [];

        if (showAllLocationsSessions) {
            const suggestionsMapped = (simulateRegistrationData?.suggestions ?? []).filter((s: any) => {
                return s.location.course.id === courseId && (s.isVisible || (selectedSessionsSuggestions ?? []).find((s2: any) => s.id === s2.id));
            }).map((s: any) => {
                return { ...s, isSuggestion: true };
            });

            tempSessions = [...tempSessions, ...suggestionsMapped];
        }

        return tempSessions;
    }, [simulateRegistrationData, selectedSessionsSuggestions, showAllLocationsSessions, courseId]);

    return sessions;
}

export default useSessionsMergeFilter;