import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

interface Props {
    count: number;
    seasonId: string;
}

const NoMissingDocumentsAlert = (props: Props) => {
    const { count, seasonId } = props;

    if (count === 0 && seasonId === '7866fe82-9a92-11ef-a671-bc2411550d1f') {
        return (
            <Alert severity='success' style={{ paddingBottom: '0px' }}>
                <p style={{ marginTop: '0px', marginBottom: '10px' }}>
                    Per le iscrizioni ai Camp Estivi NON è necessaria la consegna del Certificato Medico
                </p>

                <p style={{ marginTop: '0px', marginBottom: '4px' }}>
                    Puoi proseguire allo step successivo!
                </p>
            </Alert>
        )
    }

    return (
        <Alert severity='success' style={{ paddingBottom: '0px' }}>
            <AlertTitle>{count === 1 ? 'Documento richiesto' : 'Documenti richiesti'}</AlertTitle>

            <p style={{ marginTop: '0px', marginBottom: '10px' }}>
                {count === 1 ? 'Il documento richiesto è già stato caricato.' : 'Tutti i documenti richiesti sono già stati caricati.'}
            </p>

            <p style={{ marginTop: '0px', marginBottom: '4px' }}>
                Puoi proseguire allo step successivo!
            </p>
        </Alert>
    );
};

export default NoMissingDocumentsAlert;
