import { useCallback, useEffect, useState } from 'react';

import { ButtonProps } from '@material-ui/core/Button';
import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import GenericObject from '../../../../../../typesAdditional/GenericObject';
import { Typography } from '@material-ui/core';
import 'moment/locale/it';
import api from '../../../../../../common/utils/api';
import snackbar from '../../../../../../common/utils/snackbar';
import CustomTextField from '../../../../../../common/components/CustomTextField/CustomTextField';

interface Props {
    open: boolean;
    closeDialog: (shouldRefresh?: boolean) => void;
    selectedVariation?: GenericObject;
}

const UnapproveVariationDialog = (props: Props) => {
    const { open, closeDialog, selectedVariation } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [reason, setReason] = useState('');

    useEffect(() => {
        if (!open) return;
        setIsLoading(false);
        setReason('');
    }, [open]);

    const saveData = useCallback(() => {
        setIsLoading(true);
        api.request('/admin/summer_availability/variations/' + (selectedVariation?.id ?? 0) + '/unapprove', 'POST', { reason }).then(res => {
            snackbar.success('Modifiche rifiutate!');
            closeDialog(true);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [closeDialog, reason, selectedVariation]);

    const buttons: ButtonProps[] = [
        {
            children: 'Annulla',
            color: 'primary',
            onClick: () => closeDialog(false)
        },
        {
            children: 'Conferma',
            color: 'primary',
            onClick: () => saveData()
        }
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog(false)} title={'Rifiuta variazione'} buttons={buttons} isLoading={isLoading} maxWidth='sm' fullWidth>
            <Typography variant='body1' style={{ marginBottom: '16px' }}>
                Sei sicuro di voler rifiutare la variazione di disponibilità selezionata? <br />
                Verrà inviata un'email di notifica all'istruttore.
            </Typography>

            <CustomTextField
                label='Motivazione rifiuto'
                variant='outlined'
                value={reason}
                keepMounted={true}
                onChange={(e: any) => {
                    setReason(e.target.value);
                }}
                disabled={isLoading}
                rows={2}
                multiline
            />
        </CustomDialogWrapper>
    );
};

export default UnapproveVariationDialog;
