import { useCallback, useEffect, useState } from "react";
import CustomDialogWrapper from "../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper"
import { Button, ButtonProps, Divider, MenuItem, TextField } from "@material-ui/core";
import { StyledTextField } from "../../../loginPage/styled";
import GenericObject from "../../../../../../typesAdditional/GenericObject";
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import 'moment/locale/it';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import _ from 'lodash';
import styled from "styled-components";
import DeleteIcon from '@material-ui/icons/Delete';
import { v4 as uuidv4 } from 'uuid';
import api from "../../../../../../common/utils/api";
import snackbar from "../../../../../../common/utils/snackbar";
import { useMessageDialog } from "../../../../../../common/hooks/useMessageDialog/useMessageDialog";

interface Props {
    open: boolean;
    closeDialog: () => void;
    courseId: string | null;
    refreshData: () => void;
}

const CustomStyledTextField = styled(TextField)`
    margin-top: 2px !important;
`;


const AddSchoolDialog = (props: Props) => {
    const { open, closeDialog, courseId, refreshData } = props;

    const [showMessageDialog, closeMessageDialog] = useMessageDialog();

    const [isLoading, setIsLoading] = useState(false);
    const [schoolName, setSchoolName] = useState('');
    const [startDate, setStartDate] = useState('2024-09-01');
    const [endDate, setEndDate] = useState('2025-06-30');
    const [days, setDays] = useState<GenericObject[]>([]);

    const [isStartDatePickerOpen, setIsStartDatePickerOpen] = useState(false);
    const [isEndDatePickerOpen, setIsEndDatePickerOpen] = useState(false);
    const [startTimePickerOpenId, setStartTimePickerOpenId] = useState<number | null>(null);
    const [endTimePickerOpenId, setEndTimePickerOpenId] = useState<number | null>(null);

    useEffect(() => {
        if (!open) return;

        setSchoolName('');
        setStartDate('2024-09-01');
        setEndDate('2025-06-30');
        setDays([{
            day: '',
            startTime: null,
            endTime: null,
            uuid: uuidv4()
        }]);
    }, [open]);

    useEffect(() => {
        if (days.length === 0) {
            setDays([{
                day: '',
                startTime: null,
                endTime: null,
                uuid: uuidv4()
            }]);
        }
    }, [days]);

    useEffect(() => {
        if (!courseId || !open) return;

        setIsLoading(true);

        api.request('/admin/winter_staff/schools/' + courseId).then((res) => {
            setSchoolName(res.schoolName);
            setStartDate(res.startDate);
            setEndDate(res.endDate);
            setDays(res.days.map((day: any) => {
                return {
                    ...day,
                    startTime: moment('1970-01-01 ' + day.startTime + ':00'),
                    endTime: moment('1970-01-01 ' + day.endTime + ':00'),
                }
            }));
            setIsLoading(false);
        });
    }, [courseId, open]);

    useEffect(() => {
        console.log('days', days);
    })

    const handleSubmit = useCallback(() => {
        setIsLoading(true);

        const finalDays = days.map((day: any) => {
            return {
                ...day,
                startTime: day.startTime?.format('HH:mm') ?? null,
                endTime: day.endTime?.format('HH:mm') ?? null
            };
        })

        api.request('/admin/winter_staff/schools' + (courseId ? ('/' + courseId) : ''), 'POST', { schoolName, startDate, endDate, days: finalDays }).then((res) => {
            snackbar.success('Scuola ' + (courseId ? 'modificata' : 'aggiunta') + ' con successo!');
            closeDialog();
            refreshData();
        }).finally(() => {
            setIsLoading(false);
        })
    }, [schoolName, startDate, endDate, days, courseId, closeDialog, refreshData]);

    const deleteSchool = useCallback(() => {
        showMessageDialog({
            title: 'Rimuovi scuola',
            message: (
                <>
                    <p style={{ margin: '0px' }}>
                        Sei sicuro di voler rimuovere la scuola selezionata? Verranno rimossi anche i relativi turni assegnati.
                    </p>
                </>
            ),
            actions: [
                {
                    text: 'Annulla',
                    action: () => {
                        closeMessageDialog();
                    }
                },
                {
                    text: 'Conferma',
                    action: () => {
                        closeMessageDialog();

                        setIsLoading(true);

                        api.request('/admin/winter_staff/schools/' + courseId, 'DELETE').then(res => {
                            snackbar.success('Scuola eliminata con successo!');
                            closeDialog();
                            refreshData();
                        }).finally(() => {
                            setIsLoading(false);
                        });
                    }
                }
            ]
        });
    }, [showMessageDialog, closeMessageDialog, closeDialog, courseId, refreshData]);

    const buttons: ButtonProps[] = [
        ...(courseId ? [{
            children: 'Elimina',
            color: 'secondary',
            onClick: () => deleteSchool()
        } as ButtonProps] : [{
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        } as ButtonProps]),
        {
            children: 'Salva',
            color: 'primary',
            onClick: () => handleSubmit()
        },
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={(courseId ? 'Modifica' : 'Aggiungi') + ' scuola'} buttons={buttons} isLoading={isLoading} maxWidth='sm' fullWidth >
            <StyledTextField
                label={'Nome scuola'}
                variant='outlined'
                value={schoolName}
                onChange={(e) => setSchoolName(e.target.value)}
                style={{ marginTop: '0px !important' }}
                fullWidth
                disabled={isLoading}
            />

            <div style={{ display: 'flex', marginTop: '12px' }}>
                <MuiPickersUtilsProvider locale='it' utils={MomentUtils}>
                    <KeyboardDatePicker
                        label='Giorno inizio'
                        inputVariant='outlined'
                        minDate={'2024-09-01'}
                        maxDate={'2025-06-30'}
                        format='DD/MM/YYYY'
                        value={startDate}
                        onChange={(date: MaterialUiPickersDate) => {
                            setStartDate(date?.format('YYYY-MM-DD') ?? '');
                        }}
                        open={isStartDatePickerOpen}
                        onClick={() => {
                            setIsStartDatePickerOpen(true);
                        }}
                        onClose={() => setIsStartDatePickerOpen(false)}
                        onKeyDown={(e) => {
                            e.preventDefault();
                            return false;
                        }}
                        style={{ flex: 1, marginBottom: '4px', marginRight: '6px' }}
                        inputProps={{ tabIndex: -1 }}
                        KeyboardButtonProps={{ tabIndex: -1 }}
                        fullWidth
                    />
                </MuiPickersUtilsProvider>

                <MuiPickersUtilsProvider locale='it' utils={MomentUtils}>
                    <KeyboardDatePicker
                        label='Giorno fine'
                        inputVariant='outlined'
                        minDate={'2024-09-01'}
                        maxDate={'2025-06-30'}
                        format='DD/MM/YYYY'
                        value={endDate}
                        onChange={(date: MaterialUiPickersDate) => {
                            setEndDate(date?.format('YYYY-MM-DD') ?? '');
                        }}
                        open={isEndDatePickerOpen}
                        onClick={() => {
                            setIsEndDatePickerOpen(true);
                        }}
                        onClose={() => setIsEndDatePickerOpen(false)}
                        onKeyDown={(e) => {
                            e.preventDefault();
                            return false;
                        }}
                        style={{ flex: 1, marginBottom: '4px', marginLeft: '6px' }}
                        inputProps={{ tabIndex: -1 }}
                        KeyboardButtonProps={{ tabIndex: -1 }}
                        fullWidth
                    />
                </MuiPickersUtilsProvider>
            </div>

            <Divider style={{ margin: '12px 0' }} />

            {days.map((day: any, idx: number) => {
                return (
                    <div key={day.uuid} style={{ display: 'flex', marginTop: '4px', marginBottom: '4px' }}>
                        <CustomStyledTextField
                            label={'Giorno'}
                            variant='outlined'
                            value={day.day}
                            onChange={(e) => {
                                setDays(oldDays => {
                                    const clonedOldDays = _.cloneDeep(oldDays);
                                    _.set(clonedOldDays, '[' + idx + '].day', e.target.value);

                                    return clonedOldDays;
                                });
                            }}
                            fullWidth
                            disabled={isLoading}
                            select
                            style={{ flex: 1, marginRight: '6px' }}
                        >
                            <MenuItem value={'1'} style={{ whiteSpace: 'break-spaces' }}>
                                lunedì
                            </MenuItem>
                            <MenuItem value={'2'} style={{ whiteSpace: 'break-spaces' }}>
                                martedì
                            </MenuItem>
                            <MenuItem value={'3'} style={{ whiteSpace: 'break-spaces' }}>
                                mercoledì
                            </MenuItem>
                            <MenuItem value={'4'} style={{ whiteSpace: 'break-spaces' }}>
                                giovedì
                            </MenuItem>
                            <MenuItem value={'5'} style={{ whiteSpace: 'break-spaces' }}>
                                venerdì
                            </MenuItem>
                            <MenuItem value={'6'} style={{ whiteSpace: 'break-spaces' }}>
                                sabato
                            </MenuItem>
                        </CustomStyledTextField>
                        <MuiPickersUtilsProvider locale='it' utils={MomentUtils}>
                            <KeyboardTimePicker
                                label='Ora inizio'
                                inputVariant='outlined'
                                value={day.startTime}
                                onChange={(newDate: MaterialUiPickersDate) => {
                                    if (newDate) {
                                        setDays(oldDays => {
                                            const clonedOldDays = _.cloneDeep(oldDays);
                                            _.set(clonedOldDays, '[' + idx + '].startTime', newDate);

                                            return clonedOldDays;
                                        });
                                    }
                                }}
                                open={startTimePickerOpenId === idx}
                                onClick={() => {
                                    setStartTimePickerOpenId(idx);
                                }}
                                onClose={() => setStartTimePickerOpenId(null)}
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                    return false;
                                }}
                                style={{ flex: 1, marginTop: '2px', marginBottom: '4px', marginLeft: '6px', marginRight: '6px' }}
                                inputProps={{ tabIndex: -1 }}
                                KeyboardButtonProps={{ tabIndex: -1 }}
                                fullWidth
                                ampm={false}
                                disabled={isLoading}
                            />
                        </MuiPickersUtilsProvider>

                        <MuiPickersUtilsProvider locale='it' utils={MomentUtils}>
                            <KeyboardTimePicker
                                label='Ora fine'
                                inputVariant='outlined'
                                value={day.endTime}
                                onChange={(newDate: MaterialUiPickersDate) => {
                                    if (newDate) {
                                        setDays(oldDays => {
                                            const clonedOldDays = _.cloneDeep(oldDays);
                                            _.set(clonedOldDays, '[' + idx + '].endTime', newDate);

                                            return clonedOldDays;
                                        });
                                    }
                                }}
                                open={endTimePickerOpenId === idx}
                                onClick={() => {
                                    setEndTimePickerOpenId(idx);
                                }}
                                onClose={() => setEndTimePickerOpenId(null)}
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                    return false;
                                }}
                                style={{ flex: 1, marginTop: '2px', marginBottom: '4px', marginRight: '6px' }}
                                inputProps={{ tabIndex: -1 }}
                                KeyboardButtonProps={{ tabIndex: -1 }}
                                fullWidth
                                ampm={false}
                                disabled={isLoading}
                            />
                        </MuiPickersUtilsProvider>

                        <Button onClick={() => {
                            setDays((oldDays: any) => {
                                const clonedDays = [...oldDays];
                                console.log('removing ', idx);
                                clonedDays.splice(idx, 1);
                                return clonedDays;
                            })
                        }} variant='outlined' color='secondary' style={{ height: 'fit-content', margin: 'auto', padding: '6px 12px !important', minWidth: 'unset' }}>
                            <DeleteIcon />
                        </Button>
                    </div >
                )
            })}

            <Button variant='outlined' color='primary' onClick={() => {
                setDays(oldDays => [...oldDays, {
                    day: '',
                    startTime: null,
                    endTime: null,
                    uuid: uuidv4()
                }])
            }} >
                Aggiungi giorno
            </Button>

        </CustomDialogWrapper >
    )
}

export default AddSchoolDialog;