import { useCallback, useEffect, useState } from 'react';

import MomentUtils from '@date-io/moment';
import { ButtonProps } from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import 'moment/locale/it';

import CustomDialogWrapper from '../../../../../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import api from '../../../../../../../../../../common/utils/api';
import snackbar from '../../../../../../../../../../common/utils/snackbar';
import GenericObject from '../../../../../../../../../../typesAdditional/GenericObject';

interface Props {
    open: boolean;
    closeDialog: (refresh?: boolean) => void;
    documentId: string;
    documentType: GenericObject;
}

const ApproveDialog = (props: Props) => {
    const { open, closeDialog, documentId, documentType } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [expireDate, setExpireDate] = useState(new Date().toISOString().split('T')[0]);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

    const isVisitaMedica = documentType.name === 'Visita medica';

    useEffect(() => {
        if (!open) return;
        setIsLoading(false);
        setExpireDate(new Date().toISOString().split('T')[0]);
    }, [open]);

    const saveData = useCallback(() => {
        setIsLoading(true);
        api.request('/admin/documents/' + documentId + '/approve', 'POST', { expireDate: !isVisitaMedica ? '2038-01-19' : expireDate }).then(() => {
            snackbar.success('Documento approvato!');
            closeDialog(true);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [closeDialog, isVisitaMedica, expireDate, documentId]);

    const buttons: ButtonProps[] = [
        {
            children: 'Annulla',
            color: 'primary',
            onClick: () => closeDialog(false)
        },
        {
            children: 'Conferma',
            color: 'primary',
            onClick: () => saveData()
        }
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog(false)} title={'Approva documento'} buttons={buttons} isLoading={isLoading} maxWidth='sm' fullWidth>
            {isVisitaMedica ? (
                <>
                    <Typography variant='body1'>
                        Inserisci la data di scadenza del documento.
                    </Typography>

                    <MuiPickersUtilsProvider locale='it' utils={MomentUtils}>
                        <KeyboardDatePicker
                            label='Data scadenza'
                            inputVariant='outlined'
                            minDate={moment(Date.now() - 24 * 3600 * 1000).format('YYYY-MM-DD')}
                            maxDate={moment(Date.now() + 120 * 365 * 24 * 3600 * 1000).format('YYYY-MM-DD')}
                            format='DD/MM/YYYY'
                            value={expireDate}
                            onChange={(date: MaterialUiPickersDate) => {
                                setExpireDate(date?.format('YYYY-MM-DD') ?? '');
                            }}
                            open={isDatePickerOpen}
                            onClick={() => {
                                setIsDatePickerOpen(true);
                            }}
                            onClose={() => setIsDatePickerOpen(false)}
                            onKeyDown={(e) => {
                                e.preventDefault();
                                return false;
                            }}
                            style={{flex: 1, marginTop: '14px', marginBottom: '4px'}}
                            inputProps={{tabIndex: -1}}
                            KeyboardButtonProps={{tabIndex: -1}}
                            fullWidth
                        />
                    </MuiPickersUtilsProvider>
                </>
            ): (
                <Typography variant='body1'>
                    Sei sicuro di volere approvare il documento selezionato?
                </Typography>
            )}


        </CustomDialogWrapper>
    );
};

export default ApproveDialog;
