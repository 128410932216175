import { ButtonProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import CustomDialogWrapper from "../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper";
import moment from 'moment';
import 'moment/locale/it';

interface Props {
    open: boolean;
    closeDialog: () => void;
    data: any[];
}

const SummerWeeksDetailDialog = (props: Props) => {
    const { open, closeDialog, data } = props;

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        }
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Dettaglio settimane estive'} buttons={buttons} maxWidth='md' fullWidth>
            <TableContainer style={{ marginTop: '0px' }}>
                <Table size='small'>
                    <TableHead>
                        <TableRow style={{ backgroundColor: '#e8f4fd' }}>
                            <TableCell>settimana</TableCell>
                            <TableCell>camp</TableCell>
                            <TableCell>tecnico</TableCell>
                            <TableCell>responsabile</TableCell>
                            <TableCell>compenso</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((week: any) => {
                            const startDate = moment(week.week);
                            const endDate = moment(week.week).add(4, 'days');

                            return (
                                <TableRow style={{ backgroundColor: (week.notResponsible + week.responsible) === 0 ? '#fdecea' : undefined }}>
                                    <TableCell>
                                        {startDate.format('D MMMM') + ' - ' + endDate.format('D MMMM')}
                                    </TableCell>
                                    <TableCell>
                                        {week.locations.join(', ')}
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'right' }}>
                                        {week.notResponsible}
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'right' }}>
                                        {week.responsible}
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'right' }}>
                                        € {parseFloat(week.payout) % 1 === 0 ? parseFloat(week.payout) : parseFloat(week.payout).toFixed(2)}
                                    </TableCell>
                                </TableRow>
                            )
                        })}

                        <TableRow style={{ backgroundColor: '#e8f4fd' }}>
                            <TableCell colSpan={2}>
                                <b>TOTALE</b>
                            </TableCell>
                            <TableCell style={{ textAlign: 'right' }}><b>{data.reduce((prev: any, curr: any) => prev + curr.notResponsible, 0)}</b></TableCell>
                            <TableCell style={{ textAlign: 'right' }}><b>{data.reduce((prev: any, curr: any) => prev + curr.responsible, 0)}</b></TableCell>
                            <TableCell style={{ textAlign: 'right' }}><b>€ {parseFloat(data.reduce((prev: any, curr: any) => prev + curr.payout, 0)) % 1 === 0 ? parseFloat(data.reduce((prev: any, curr: any) => prev + curr.payout, 0)) : parseFloat(data.reduce((prev: any, curr: any) => prev + curr.payout, 0)).toFixed(2)}</b></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </CustomDialogWrapper>
    )
}

export default SummerWeeksDetailDialog;