import GenericObject from '../../../../../../../../typesAdditional/GenericObject';
import useSortAndFilterSessions from '../../../../hooks/useSortAndFilterSessions/useSortAndFilterSessions';
import useSessionsMergeFilter from '../../hooks/useSessionsMergeFilter';
import { FullSessionSelectorProps } from '../../types';
import SessionCard from './components/SessionCard';

const CardSessionsSelector = (props: FullSessionSelectorProps) => {
    const { courseId, sessionType, hasFoodService, simulateRegistrationData, questionResponse, schoolGrade, yearOfBirth, tmpFixCorsiAdulti, getSessionPriceWithOffset, selectedSessions, isAdmin, singleSessionEvent, showAllLocationsSessions, showTownName, selectedSessionsSuggestions, ...cardProps } = props;

    const sessions = useSessionsMergeFilter({
        simulateRegistrationData,
        selectedSessionsSuggestions,
        showAllLocationsSessions,
        courseId
    });

    const filteredSessions = useSortAndFilterSessions({
        sessions,
        sessionType,
        questionResponse,
        schoolGrade,
        yearOfBirth,
        isAdmin
    });

    const sessionsLabels = [...new Set(sessions.map((s: any) => s.overrideLabel).filter((s: any) => !!s))].sort((s1: any, s2: any) => {
        return s2.localeCompare(s1);
    }) as string[];

    const thereIsADifferentCourse = sessions.some((s: any) => {
        return courseId !== undefined && s.location.course.id !== courseId;
    });

    const thereIsADifferentTown = sessions.reduce((prev: string[], curr: GenericObject) => {
        const clonedPrev = [...prev];

        if (!clonedPrev.includes(curr.location.townName)) {
            clonedPrev.push(curr.location.townName);
        }

        return clonedPrev;
    }, []).length > 0;

    return (
        <>
            {filteredSessions.map((session: any) => {
                const isDifferentCourse = thereIsADifferentCourse;

                return <SessionCard key={session.id} sessionType={sessionType} hasFoodService={hasFoodService} session={session} isDifferentCourse={isDifferentCourse} showTownName={thereIsADifferentTown || showTownName} applyPriceOffset={(!!session.isSuggestion) && tmpFixCorsiAdulti} getSessionPriceWithOffset={(!!session.isSuggestion) ? getSessionPriceWithOffset : undefined} singleSessionEvent={singleSessionEvent} sessionsLabels={sessionsLabels} {...cardProps} />;
            })}
        </>
    );
};

export default CardSessionsSelector;
