import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useWindowSize } from 'react-use';

import { Divider, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Chip from '@material-ui/core/Chip';

import useInternalLoader from '../../../../common/hooks/useInternalLoader/useExternalLoader';
import api from '../../../../common/utils/api';
import GenericObject from '../../../../typesAdditional/GenericObject';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PaymentsDialog from '../registrationLocationPage/components/PaymentsDialog/PaymentsDialog';
import DeletedSessionsDialog from '../../../public/pages/registrationsListPage/components/DeletedSessionsDialog/DeletedSessionsDialog';
import CustomTextField from '../../../../common/components/CustomTextField/CustomTextField';

const DeletedRegistrationsListPage = () => {
    const setIsLoading = useInternalLoader();

    const { width } = useWindowSize();

    const [data, setData] = useState<GenericObject[]>([]);
    const [order, setOrder] = useState(localStorage.getItem('deleted_registrations_order') ?? 'alphabetical');

    const [anchor, setAnchorEl] = useState<null | HTMLElement>(null);
    const [menuGroupId, setMenuGroupId] = useState<null | string>(null);

    const [selectedRegistrationId, setSelectedRegistrationId] = useState<string | undefined>();
    const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false);
    const [isDeletedSessionsDialogOpen, setIsDeletedSessionsDialogOpen] = useState(false);

    const [deletedSessions, setDeletedSessions] = useState<GenericObject[]>([]);
    const [baseUrl, setBaseUrl] = useState('');

    const sortedRegistrations = useMemo(() => {
        return data.sort((a: any, b: any) => {
            if (order === 'alphabetical' && (a.person.surname + ' ' + a.person.name) !== (b.person.surname + ' ' + b.person.name)) {
                return (a.person.surname + ' ' + a.person.name).localeCompare(b.person.surname + ' ' + b.person.name);
            } else {
                return a.firstDeletedAt.localeCompare(b.firstDeletedAt);
            }
        });
    }, [data, order]);

    const handleMenuClick = useCallback((event: React.MouseEvent<HTMLElement>, userId: string) => {
        setAnchorEl(event.currentTarget);
        setMenuGroupId(userId);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const apiRequestId = useRef('');

    const refreshData = useCallback(() => {
        const currentRequestId = uuidv4();
        apiRequestId.current = currentRequestId;

        api.request('/admin/deleted_registrations', 'GET').then((res: any) => {
            if (currentRequestId !== apiRequestId.current) return;

            setData(res);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [setIsLoading]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            refreshData();
        }, 10);

        return () => clearTimeout(timeout);
    }, [refreshData]);

    const isMobile = width <= 700;

    return (
        <>
            <div>
                <div style={{ display: isMobile ? undefined : 'flex', textAlign: isMobile ? 'center' : undefined }}>
                    <Typography variant='h2' style={{ fontSize: '3.6em', flexGrow: 1 }}>
                        Elenco iscrizioni eliminate
                    </Typography>

                    <div style={{ flex: 1, flexGrow: 1 }} />

                    <CustomTextField
                        select
                        label={'Ordine'}
                        value={order}
                        onChange={(e: any) => {
                            setOrder(e.target.value);
                            localStorage.setItem('deleted_registrations_order', e.target.value);
                        }}
                        variant='outlined'
                        required
                        fullWidth
                        keepMounted
                        style={{ marginLeft: '12px', maxWidth: '240px', textAlign: 'left' }}
                    >
                        <MenuItem value={'alphabetical'} style={{ whiteSpace: 'break-spaces' }}>
                            alfabetico
                        </MenuItem>
                        <MenuItem value={'date'} style={{ whiteSpace: 'break-spaces' }}>
                            data di eliminazione
                        </MenuItem>
                    </CustomTextField>

                    <Divider style={{ margin: '8px 0 12px' }} />
                </div>
            </div>

            <TableContainer component={Paper} style={{ marginTop: '16px' }}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Data eliminazione</TableCell>
                            <TableCell>Cognome</TableCell>
                            <TableCell>Nome</TableCell>
                            <TableCell>Camp</TableCell>
                            <TableCell>Paese</TableCell>
                            <TableCell>Stato iscrizione</TableCell>
                            <TableCell>Costo settimane eliminate</TableCell>
                            <TableCell>Rimanente da pagare</TableCell>
                            <TableCell>Stato pagamento</TableCell>
                            <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>Azioni</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedRegistrations.map(registration => {
                            const hiddenStatusMap = {
                                hidden: {
                                    color: '#fdecea',
                                    borderColor: '#f44336',
                                    label: 'Eliminata interamente',
                                    outlined: false
                                },
                                not_hidden: {
                                    color: '#fff4e5',
                                    borderColor: '#ff9800',
                                    label: 'Eliminata parzialmente',
                                    outlined: false
                                }
                            }

                            const paymentStatusMap = {
                                to_pay: {
                                    color: '#fdecea',
                                    borderColor: '#f44336',
                                    label: 'Da pagare',
                                    outlined: false
                                },
                                full_paid: {
                                    color: '#edf7ed',
                                    borderColor: '#4caf50',
                                    label: 'Pagata',
                                    outlined: false
                                },
                                partial_paid: {
                                    color: '#fff4e5',
                                    borderColor: '#ff9800',
                                    label: 'Parzialmente pagata',
                                    outlined: false
                                }
                            }

                            const hiddenStatusData = hiddenStatusMap[registration.hidden ? 'hidden' : 'not_hidden'];

                            const toPay = Math.min(parseFloat(registration.deletedRegistrationsCost), parseFloat(registration.toPay));
                            const toPayStatus = toPay === 0.0 ? 'full_paid' : (toPay === registration.deletedRegistrationsCost ? 'to_pay' : 'partial_paid');
                            const paymentStatusData = paymentStatusMap[toPayStatus];

                            return (
                                <TableRow style={{ height: '45px' }}>
                                    <TableCell>{registration.firstDeletedAtFormatted}</TableCell>
                                    <TableCell>{registration.person.surname}</TableCell>
                                    <TableCell>{registration.person.name}</TableCell>
                                    <TableCell>{registration.course.name}</TableCell>
                                    <TableCell>{registration.courseLocation.townName}</TableCell>
                                    <TableCell>
                                        <Chip label={hiddenStatusData.label} variant={hiddenStatusData.outlined ? 'outlined' : 'default'} style={hiddenStatusData.outlined ? { width: '200px' } : { backgroundColor: hiddenStatusData.color, border: '1px solid ' + hiddenStatusData.borderColor, width: '200px' }} />
                                    </TableCell>
                                    <TableCell>
                                        €{parseFloat(registration.deletedRegistrationsCost).toFixed(2)}
                                    </TableCell>
                                    <TableCell>
                                        €{toPay.toFixed(2)}
                                    </TableCell>
                                    <TableCell>
                                        <Chip label={(toPayStatus === 'full_paid' && toPay < 0.01) ? 'Pagamento non richiesto' : paymentStatusData.label} variant={paymentStatusData.outlined ? 'outlined' : 'default'} style={paymentStatusData.outlined ? { width: '180px' } : { backgroundColor: paymentStatusData.color, border: '1px solid ' + paymentStatusData.borderColor, width: '180px' }} />
                                    </TableCell>
                                    <TableCell style={{ padding: '2px 16px' }}>
                                        <IconButton onClick={(e: any) => handleMenuClick(e, registration.id)} style={{}}>
                                            <MoreVertIcon />
                                        </IconButton>

                                        <Menu
                                            anchorEl={anchor}
                                            keepMounted
                                            open={!!anchor && menuGroupId === registration.id}
                                            onClose={handleClose}
                                        >
                                            <MenuItem onClick={() => {
                                                handleClose();
                                                setSelectedRegistrationId(registration.id);
                                                setDeletedSessions(registration.deletedSessions);
                                                setBaseUrl('/admin/courses/' + registration.course.id + '/locations/' + registration.courseLocation.id + '/registrations/' + registration.id);
                                                setIsDeletedSessionsDialogOpen(true);
                                            }}>
                                                Impostazione settimane
                                            </MenuItem>

                                            <MenuItem onClick={() => {
                                                handleClose();
                                                setSelectedRegistrationId(registration.id);
                                                setIsPaymentDialogOpen(true);
                                            }}>
                                                Gestione pagamenti
                                            </MenuItem>
                                        </Menu>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <PaymentsDialog open={isPaymentDialogOpen} closeDialog={() => setIsPaymentDialogOpen(false)} registrationId={selectedRegistrationId} refreshRegistrations={refreshData} />

            <DeletedSessionsDialog
                open={isDeletedSessionsDialogOpen}
                onClose={() => {
                    setIsDeletedSessionsDialogOpen(false)
                    refreshData();
                }}
                deletedSessions={deletedSessions}
                baseUrl={baseUrl}
                allowClose
            />
        </>
    );
};

export default DeletedRegistrationsListPage;
