import { useCallback, useEffect, useState } from 'react';

import { ButtonProps } from '@material-ui/core/Button';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import { StyledTextField } from '../../../../../../common/components/CustomTextField/styled';
import api from '../../../../../../common/utils/api';
import snackbar from '../../../../../../common/utils/snackbar';
import ApiEntitySelectDialog from '../../../../../../common/components/ApiEntitySelectDialog/ApiEntitySelectDialog';
import { Checkbox, FormControlLabel, MenuItem } from '@material-ui/core';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import 'moment/locale/it';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { DateType } from '@date-io/type';
import { useMessageDialog } from '../../../../../../common/hooks/useMessageDialog/useMessageDialog';

interface Props {
    open: boolean;
    closeDialog: (reloadData?: boolean, superUserId?: string) => void;
    sessionId?: string;
    substitute?: boolean;
    trainerId?: string;
    trainerIdsInSession: string[];
    day?: string;
    additionalDayId?: string;
    higlightItem?: string;
}

const SelectTrainerDialog = (props: Props) => {
    const { open, closeDialog, sessionId, substitute, trainerId, trainerIdsInSession, day, additionalDayId, higlightItem } = props;

    console.log('trainerId', trainerId);

    const [isLoading, setIsLoading] = useState(false);

    const [isEntitySelectDialogOpen, setIsEntitySelectDialogOpen] = useState(false);

    const [selectedTrainerId, setSelectedTrainerId] = useState<string | undefined>(undefined);
    const [selectedTrainerData, setSelectedTrainerData] = useState<any>({});

    const [isStartTimePickerOpen, setIsStartTimePickerOpen] = useState(false);
    const [isEndTimePickerOpen, setIsEndTimePickerOpen] = useState(false);

    const [startTime, setStartTime] = useState<DateType | undefined>(undefined);
    const [endTime, setEndTime] = useState<DateType | undefined>(undefined);
    const [defaultStartTime, setDefaultStartTime] = useState<DateType | undefined>(undefined);
    const [defaultEndTime, setDefaultEndTime] = useState<DateType | undefined>(undefined);
    const [shouldOverrideTime, setShouldOverrideTime] = useState(false);
    const [facilityExists, setFacilityExists] = useState(false);

    const [showMessageDialog, closeMessageDialog] = useMessageDialog();

    useEffect(() => {
        if (!selectedTrainerId) return;

        api.request('/admin/trainers/' + selectedTrainerId).then((res: any) => {
            setSelectedTrainerData(res);
        })
    }, [selectedTrainerId]);

    useEffect(() => {
        console.log('open', open, 'sessionId', sessionId);
        if (!open || !sessionId) return;

        setSelectedTrainerData(undefined);
        setSelectedTrainerId(undefined);
        setStartTime(undefined);
        setEndTime(undefined);

        setIsLoading(true);

        api.request('/admin/winter_staff/schedule/course_sessions/' + sessionId + '/trainers', 'GET', { substitute: substitute ? true : false, trainerId: trainerId ?? '', day, additionalDayId }).then((res) => {
            setSelectedTrainerId(res.trainerId);
            setStartTime(moment('1970-01-01 ' + res.startTime + ':00'));
            setEndTime(moment('1970-01-01 ' + res.endTime + ':00'));
            setDefaultStartTime(moment('1970-01-01 ' + res.courseStartTime + ':00'));
            setDefaultEndTime(moment('1970-01-01 ' + res.courseEndTime + ':00'));
            setShouldOverrideTime(!res.useDefaultTime);
            setFacilityExists(!!res.trainerId);
            console.log(!!res.trainerId);
            setIsLoading(false);
        });
    }, [open, sessionId, substitute, trainerId, day, additionalDayId])

    const handleSubmit = useCallback(() => {
        setIsLoading(true);

        api.request('/admin/winter_staff/schedule/course_sessions/' + sessionId + '/trainers', 'POST', { substitute: substitute ? true : false, trainerId: trainerId ?? '', selectedTrainerId: selectedTrainerId ?? '', startTime: startTime?.format('HH:mm'), endTime: endTime?.format('HH:mm'), useDefaultTime: !shouldOverrideTime, day, additionalDayId }).then((res) => {
            snackbar.success((substitute ? 'Sostituto' : 'Istruttore') + ' salvato con successo!');
            closeDialog(true, res.id);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [closeDialog, sessionId, trainerId, selectedTrainerId, startTime, endTime, shouldOverrideTime, substitute, day, additionalDayId]);

    const removeFacility = useCallback(() => {
        showMessageDialog({
            title: 'Rimuovi ' + (substitute ? 'sostituto' : 'istruttore'),
            message: (
                <>
                    <p style={{ margin: '0px' }}>
                        Sei sicuro di voler rimuovere {(substitute ? 'il sostituto' : 'l\'istruttore')} selezionato?
                    </p>
                </>
            ),
            actions: [
                {
                    text: 'Annulla',
                    action: () => {
                        closeMessageDialog();
                    }
                },
                {
                    text: 'Conferma',
                    action: () => {
                        closeMessageDialog();

                        setIsLoading(true);

                        api.request('/admin/winter_staff/schedule/course_sessions/' + sessionId + '/trainers', 'DELETE', { substitute: substitute ? true : false, trainerId: trainerId ?? '', day, additionalDayId }).then(res => {
                            snackbar.success((substitute ? 'Sostituto' : 'Istruttore') + ' rimosso con successo!');
                            closeDialog(true);
                        }).finally(() => {
                            setIsLoading(false);
                        });
                    }
                }
            ]
        });
    }, [closeMessageDialog, showMessageDialog, sessionId, closeDialog, day, additionalDayId, substitute, trainerId]);

    const mapTrainers = useCallback((rawData: any) => {
        console.log(trainerIdsInSession, rawData.id);
        if (trainerIdsInSession.includes(rawData.id)) return null

        return {
            id: rawData.id,
            name: rawData.firstName + ' ' + rawData.lastName,
            //description: rawData.town.name
        };
    }, [trainerIdsInSession]);

    const buttons: ButtonProps[] = [
        ...(facilityExists ? [{
            children: 'Elimina',
            color: 'secondary',
            onClick: () => removeFacility()
        } as ButtonProps] : [{
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        } as ButtonProps]),
        {
            children: 'Salva',
            color: 'primary',
            onClick: () => handleSubmit()
        },
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Seleziona ' + (substitute ? 'sostituto' : 'istruttore')} buttons={buttons} isLoading={isLoading} maxWidth='sm' fullWidth>
            <div style={{ display: 'flex' }}>
                <StyledTextField
                    label={'Istruttore'}
                    variant='outlined'
                    value={selectedTrainerId ? 'dummy' : ''}
                    style={{ marginRight: '6px' }}
                    fullWidth
                    disabled={isLoading}
                    SelectProps={{
                        open: false,
                        onOpen: () => setIsEntitySelectDialogOpen(true)
                    }}
                    select
                >
                    <MenuItem value={'dummy'} style={{ whiteSpace: 'break-spaces' }}>
                        {(selectedTrainerData && selectedTrainerData.id === selectedTrainerId) ? (selectedTrainerData.name + ' ' + selectedTrainerData.surname) : ''}
                    </MenuItem>
                </StyledTextField>
            </div>


            <FormControlLabel
                control={
                    <Checkbox
                        color='primary'
                        style={{ padding: '4px' }}
                        checked={shouldOverrideTime}
                        onChange={(e) => {
                            setShouldOverrideTime(e.target.checked);
                        }}
                    />
                }
                style={{ marginTop: '8px', marginLeft: '-4px', marginBottom: '16px' }}
                label={'Orario parziale'}
                disabled={isLoading}
            />

            <div style={{ display: 'flex', marginTop: '4px', marginBottom: '8px' }}>
                <MuiPickersUtilsProvider locale='it' utils={MomentUtils}>
                    <KeyboardTimePicker
                        label='Ora inizio'
                        inputVariant='outlined'
                        value={shouldOverrideTime ? startTime : defaultStartTime}
                        onChange={(newDate: MaterialUiPickersDate) => {
                            if (newDate) setStartTime(newDate);
                        }}
                        open={isStartTimePickerOpen}
                        onClick={() => {
                            setIsStartTimePickerOpen(true);
                        }}
                        onClose={() => setIsStartTimePickerOpen(false)}
                        onKeyDown={(e) => {
                            e.preventDefault();
                            return false;
                        }}
                        style={{ flex: 1, marginTop: '2px', marginBottom: '4px', marginRight: '6px' }}
                        inputProps={{ tabIndex: -1 }}
                        KeyboardButtonProps={{ tabIndex: -1 }}
                        fullWidth
                        ampm={false}
                        disabled={isLoading || !shouldOverrideTime}
                    />
                </MuiPickersUtilsProvider>

                <MuiPickersUtilsProvider locale='it' utils={MomentUtils}>
                    <KeyboardTimePicker
                        label='Ora fine'
                        inputVariant='outlined'
                        value={shouldOverrideTime ? endTime : defaultEndTime}
                        onChange={(newDate: MaterialUiPickersDate) => {
                            if (newDate) setEndTime(newDate);
                        }}
                        open={isEndTimePickerOpen}
                        onClick={() => {
                            setIsEndTimePickerOpen(true);
                        }}
                        onClose={() => setIsEndTimePickerOpen(false)}
                        onKeyDown={(e) => {
                            e.preventDefault();
                            return false;
                        }}
                        style={{ flex: 1, marginTop: '2px', marginBottom: '4px', marginLeft: '6px' }}
                        inputProps={{ tabIndex: -1 }}
                        KeyboardButtonProps={{ tabIndex: -1 }}
                        fullWidth
                        ampm={false}
                        disabled={isLoading || !shouldOverrideTime}
                    />
                </MuiPickersUtilsProvider>
            </div>

            <ApiEntitySelectDialog
                open={isEntitySelectDialogOpen}
                onClose={(selectedId?: string) => {
                    setIsEntitySelectDialogOpen(false);

                    if (selectedId) setSelectedTrainerId(selectedId);
                }}
                title='Seleziona istruttore'
                url={'/admin/trainers'}
                map={mapTrainers}
                noEntityFoundMessage='Nessun istruttore trovato'
                params={{ season: 'winter' }}
                higlightItem={higlightItem}
            />
        </CustomDialogWrapper >
    );
};

export default SelectTrainerDialog; 
