import Typography from "@material-ui/core/Typography";
import GenericObject from "../../../../../../typesAdditional/GenericObject"
import Divider from "@material-ui/core/Divider";
import { useWindowSize } from "react-use";

interface Props {
    selectedWeek?: GenericObject;
}

const Header = ({ selectedWeek }: Props) => {
    const { width } = useWindowSize();
    const isMobile = width <= 700;

    return (
        <div style={{ display: isMobile ? undefined : 'flex', textAlign: isMobile ? 'center' : undefined }}>
            <div style={{ display: 'flex', flexGrow: 1 }}>
                <Typography variant='h2' style={{ fontSize: '3.6em' }}>
                    Stime istruttori richiesti
                </Typography>
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '18px' }}>
                    <div style={{ flexGrow: 1 }} />
                    <Typography variant='h2' style={{ fontSize: '2em', marginBottom: '4px' }}>
                        settimana {selectedWeek?.shortName}
                    </Typography>
                </div>
            </div>

            <Divider style={{ margin: '16px 0' }} />
        </div>
    )
}

export default Header;