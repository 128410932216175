import Avatar from '@material-ui/core/Avatar';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

export const FlexPusher = styled.div`
    flex-grow: 1;
`;

export const LoaderContainer = styled.div`
    margin: 4px auto 10px;
    width: fit-content;
`;

export const StyledAvatar = styled(Avatar)`
    background-color: #00aa8d;
`;

export const StyledDialogContent = styled(DialogContent)`
    padding: 0px;
`;

export const StyledTypography = styled(Typography)`
    padding: 24px 0 16px;
    text-align: center;
`;

export const SearchFieldContainer = styled.div`
    padding: 5px 24px 0px;
`;
