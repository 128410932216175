import { useEffect, useRef, useState } from 'react';

import Loader from '../Loader/Loader';
import { Container } from './styled';

interface Props {
    isVisible: boolean;
    type: 'internal' | 'external';
}

const TRANSITION_DURATION = 1000;

const LoaderContainer = (props: Props) => {
    const { isVisible, type } = props;
    const isInitiallyVisible = useRef(isVisible);
    const [shouldRenderLoader, setShouldRenderLoader] = useState(isInitiallyVisible.current);

    useEffect(() => {
        if (isVisible) {
            setShouldRenderLoader(true);
        } else {
            const timeout = setTimeout(() => {
                setShouldRenderLoader(false);
            }, TRANSITION_DURATION + 1000);

            return () => {
                clearTimeout(timeout);
            };
        }
    }, [isVisible]);

    if (!shouldRenderLoader) {
        return null;
    }

    return (
        <Container isVisible={isVisible} transitionDuration={TRANSITION_DURATION} type={type}>
            <Loader />
        </Container>
    );
};

export default LoaderContainer;
