import { useMemo } from 'react';

import GenericObject from '../../../../../../../typesAdditional/GenericObject';
import { FormSection } from '../GenericPersonForm';
import addressFields from '../sections/address/fields';
import basicDataFields, { basicDataFieldsNoTaxCodeFields, basicDataWithoutTaxCodeFields } from '../sections/basicData/fields';
import { emailField, phoneField } from '../sections/contactData/fields';
import useValidateBasicData from './useValidateBasicData';
import useValidateEmailAddress from './useValidateEmailAddress';
import useValidatePhoneNumber from './useValidatePhoneNumber';

interface ValidatorHookProps {
    formPrefix: string;
    data: GenericObject;
    validationResult: GenericObject;
}

export interface Props extends ValidatorHookProps {
    sections: FormSection[];
}

const mergeFieldsWithValidation = (fields: GenericObject[], validation: GenericObject): GenericObject[] => {
    return fields.map(field => {
        return {
            ...field,
            valid: validation[field.name] === undefined ? true : validation[field.name]
        };
    });
};

const useFields = (props: Props) => {
    const { sections, ...validatorProps } = props;

    const validatedBasicData = useValidateBasicData(validatorProps);
    const validatedPhoneNumber = useValidatePhoneNumber(validatorProps);
    const validatedEmailAddress = useValidateEmailAddress(validatorProps);

    const isAdmin = (window.location.pathname.startsWith('/admin') || window.location.host.startsWith('admin.epuntos.it'));
    const usePlaceOfBirthAutoComplete = !validatorProps.data[validatorProps.formPrefix]?.tax_code && isAdmin;

    return useMemo(() => {
        return [
            ...(sections.includes('basic_data') ? mergeFieldsWithValidation(
                sections.includes('tax_code') ? (usePlaceOfBirthAutoComplete ? basicDataFieldsNoTaxCodeFields : basicDataFields) : basicDataWithoutTaxCodeFields,
                validatedBasicData
            ) : []),
            ...(sections.includes('address') ? addressFields : []),
            ...(sections.includes('phone') ? mergeFieldsWithValidation([phoneField], validatedPhoneNumber) : []),
            ...(sections.includes('email') ? mergeFieldsWithValidation([emailField], validatedEmailAddress) : [])
        ];
    }, [sections, validatedBasicData, validatedPhoneNumber, validatedEmailAddress, usePlaceOfBirthAutoComplete]);
};

export default useFields;
