import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import styled from 'styled-components/macro';

export const AppTitleContainer = styled.div<{center: boolean}>`
    ${props => props.center ? 'margin: 0 auto !important;' : ''}
`;

export const MenuButton = styled(IconButton)`
    @media (min-width: 960px) {
        display: none !important;
    }
`;

export const MenuButtonContainer = styled.div<{show: boolean}>`
    ${props => props.show ? '' : 'display: none ! important;'}
`;

export const StyledAppBar = styled(AppBar)`
    z-index: ${props => props.theme.zIndex.drawer + 1} !important;
    background-color: #2e7d32;
`;
