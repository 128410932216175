import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import CircularProgressWithLabel from './CircularProgressWithLabel';

interface Props {
    activeStep: number;
    steps: string[];
}

const MobileStepper = (props: Props) => {
    const { activeStep, steps } = props;

    const value = (activeStep + 1) / (steps.length + 1) * 100;

    const formatNextLabel = (str: string) => {
        if (!str) return '';

        return str[0].toLowerCase() + str.slice(1);
    };

    return (
        <>
            <div style={{display: 'flex', marginBottom: '4px'}}>
                <CircularProgressWithLabel labelValue={(activeStep + 1) + ' di ' + (steps.length)} value={value} />

                <div style={{flex: 1, textAlign: 'right', paddingTop: '14px', paddingRight: '12px'}}>
                    <Typography variant='h4' style={{fontSize: '1.4em', marginBottom: '6px'}}>
                        {steps[activeStep]}
                    </Typography>

                    {steps[activeStep+1] && (
                        <Typography variant='h4' style={{fontSize: '1em'}}>
                            Prossimo: {formatNextLabel(steps[activeStep+1] ?? '')}
                        </Typography>
                    )}
                </div>
            </div>

            <Divider />
        </>
    );
};

export default MobileStepper;
