import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ButtonProps } from '@material-ui/core/Button';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import CustomTextField from '../../../../../../common/components/CustomTextField/CustomTextField';
import api from '../../../../../../common/utils/api';
import snackbar from '../../../../../../common/utils/snackbar';
import { ReduxState } from '../../../../../../redux/types';

interface Props {
    open: boolean;
    closeDialog: () => void;
    editorRef: any;
    subject: string;
}

const SimulateConfirmDialog = (props: Props) => {
    const { open, closeDialog, subject, editorRef } = props;

    const userInfo = useSelector((state: ReduxState) => state.user);

    const [isLoading, setIsLoading] = useState(false);
    const [recipient, setRecipient] = useState('');

    useEffect(() => {
        if (open) {
            setRecipient(userInfo.email);
        }
    }, [open, userInfo]);

    const simulateEmail = useCallback(() => {
        setIsLoading(true);
        api.request('/admin/emails/simulate', 'POST', { subject, recipient, content: editorRef.current.getContent() }).then(() => {
            snackbar.success('Email inviata correttamente!');
            closeDialog();
        }).finally(() => {
            setIsLoading(false);
        });
    }, [closeDialog, subject, recipient, editorRef]);

    const buttons: ButtonProps[] = [
        {
            children: 'Invia',
            color: 'primary',
            onClick: () => simulateEmail()
        },
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        },
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Simula invio email'} buttons={buttons} isLoading={isLoading} maxWidth='sm' fullWidth>
            <CustomTextField
                label={'Indirizzo email'}
                variant='outlined'
                value={recipient}
                keepMounted={true}
                onChange={(e) => setRecipient(e.target.value)}
                fullWidth
            />
        </CustomDialogWrapper>
    );
};

export default SimulateConfirmDialog;
