import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

interface Props {
    count: number;
}

const MissingDocumentsAlert = (props: Props) => {
    const { count } = props;

    return (
        <Alert severity='info' style={{paddingBottom: '0px'}}>
            <AlertTitle>{count === 1 ? 'Documento richiesto' : 'Documenti richiesti'}</AlertTitle>

            <p style={{marginTop: '0px', marginBottom: '10px'}}>
                La partecipazione al corso dove è stata effettuata l'iscrizione richiede il caricamento{' '}
                {count === 1 ? 'del seguente documento' : 'dei seguenti documenti'}.
            </p>
        </Alert>
    );
};

export default MissingDocumentsAlert;
