import MenuItem from '@material-ui/core/MenuItem';

import CustomTextField from '../../../../../../../../common/components/CustomTextField/CustomTextField';
import FlexInputContainer from '../../../../../../../../common/components/FlexInputContainer/FlexInputContainer';
import { SectionProps } from '../../GenericPersonForm';
const options = [
    /*{
        id: 0,
        name: 'Seleziona la classe'
    },*/
    {
        id: 1,
        name: 'Scuola dell\'infanzia'
    },
    {
        id: 4,
        name: '1ª elementare'
    },
    {
        id: 5,
        name: '2ª elementare'
    },
    {
        id: 6,
        name: '3ª elementare'
    },
    {
        id: 7,
        name: '4ª elementare'
    },
    {
        id: 8,
        name: '5ª elementare'
    },
    {
        id: 9,
        name: 'Scuola secondaria'
    },
];

const SchoolGrade = (props: SectionProps) => {
    const { dataOnChange, rawOnChange, ...validatorProps } = props;
    const { formPrefix, data } = validatorProps;

    return (
        <FlexInputContainer>
            <CustomTextField
                label='Classe frequentata'
                path={formPrefix + '.schoolGrade'}
                variant='outlined'
                data={data}
                dataOnChange={dataOnChange}
                select
            >
                {options.map(opt => {
                    return (
                        <MenuItem key={opt.id} value={opt.id} style={{whiteSpace: 'break-spaces'}}>
                            {opt.name}
                        </MenuItem>
                    );
                })}
            </CustomTextField>
        </FlexInputContainer>
    );
};

export default SchoolGrade;
