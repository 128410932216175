import { useCallback, useEffect, useMemo, useState } from 'react';

import { ButtonProps } from '@material-ui/core/Button';
import _ from 'lodash';
import snakecaseKeys from 'snakecase-keys';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import api from '../../../../../../common/utils/api';
import snackbar from '../../../../../../common/utils/snackbar';
import GenericObject from '../../../../../../typesAdditional/GenericObject';
import GenericPersonForm, { FormSection } from '../../../registrationPage/components/GenericPersonForm/GenericPersonForm';
import { Props as FieldsHookProps } from '../../../registrationPage/components/GenericPersonForm/hooks/useFields';
import useFirstParentDataValidator from '../../../registrationPage/hooks/useFirstParentDataValidator/useFirstParentDataValidator';
import useParticipantDataValidator from '../../../registrationPage/hooks/useParticipantDataValidator/useParticipantDataValidator';
import useValidatePersonStep from '../../../registrationPage/hooks/useValidatePersonStep/useValidatePersonStep';
import detailsMap from '../DetailsDialog/detailsMap';

interface Props {
    data: GenericObject;
    section: string | null;
    onClose: () => void;
    onDataChange: () => void;
}

const EditDataDialog = (props: Props) => {
    const { data, onClose, onDataChange, section } = props;

    const [internalData, setInternalData] = useState<GenericObject>({});
    const [oldDateOfBirth, setOldDateOfBirth] = useState('');
    const [oldParentDateOfBirth, setOldParentDateOfBirth] = useState('');

    useEffect(() => {
        setInternalData(snakecaseKeys(data));
        setOldDateOfBirth('');
        setOldParentDateOfBirth('');
    }, [data, section]);

    const isOpen = section !== null;

    const [isLoading, setIsLoading] = useState(false);

    const sectionName = detailsMap.find(s => s.keyPrefix === section)?.name.toLowerCase() ?? '';

    const formPrefix = useMemo(() => {
        if (section === 'participant' || section === 'person.residenceAddress') {
            return 'participant';
        } else if (section === 'parent') {
            return 'parent';
        } else if (section === 'secondParentUser') {
            return 'second_parent';
        }
        return '';
    }, [section]);

    const formSections: FormSection[] = useMemo(() => {
        if (section === 'participant') {
            return ['basic_data', 'tax_code', ...(data?.participant?.userId ? ['email', 'phone'] as FormSection[] : [])];
        } else if (section === 'person.residenceAddress') {
            return ['address'];
        } else if (section === 'parent') {
            return ['basic_data', 'tax_code', 'email', 'phone'];
        } else if (section === 'secondParentUser') {
            return ['basic_data', 'email', 'phone'];
        }
        return [];
    }, [data, section]);

    const courseId = internalData?.course?.id;

    const participantValidationResult = useParticipantDataValidator(courseId, internalData);
    const firstParentValidationResult = useFirstParentDataValidator(courseId, internalData);

    const validationResult = {
        participant: participantValidationResult,
        parent: firstParentValidationResult
    };

    const fieldsHookProps: FieldsHookProps = {
        data: internalData,
        validationResult,
        formPrefix,
        sections: formSections
    };

    const validatePersonStep = useValidatePersonStep({
        courseId,
        ...fieldsHookProps
    });

    const save = () => {
        setIsLoading(true);

        validatePersonStep().then(() => {
            const requestData = {
                ...internalData[formPrefix],
                courseId,
                registrationId: internalData.id,
                type: formPrefix
            };

            api.request('/admin/people/' + (internalData[formPrefix].id ? internalData[formPrefix].id : ''), internalData[formPrefix].id ? 'PUT' : 'POST', requestData).then(() => {
                snackbar.success('Dati modificati con successo!');
                onDataChange();
                onClose();
            }).catch(() => {
                snackbar.error('Si è verificato un errore sconosciuto.');
            }).finally(() => {
                setIsLoading(false);
            });
        }).catch(() => {
            setIsLoading(false);
        });
    };

    const buttons: ButtonProps[] = [
        {
            children: 'Salva',
            color: 'primary',
            onClick: () => save()
        },
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => onClose()
        }
    ];

    const rawOnChange = useCallback((name: string, value: any) => {
        setInternalData((d: any) => {
            const newObj = {...d};
            _.set(newObj, name, value);
            return newObj;
        });
    }, []);

    const onChange = useCallback((e: any, name: string) => {
        rawOnChange(name, e.target.value);
    }, [rawOnChange]);

    useEffect(() => {
        const shouldUpdate = (internalData?.participant?.date_of_birth ?? '') === oldDateOfBirth;
        const newComputedDateOfBirth = participantValidationResult?.taxCode?.dateOfBirth;

        if (newComputedDateOfBirth && shouldUpdate) {
            setOldDateOfBirth(newComputedDateOfBirth);
            rawOnChange('participant.date_of_birth', newComputedDateOfBirth);
        }
    }, [rawOnChange, oldDateOfBirth, participantValidationResult?.taxCode?.dateOfBirth, internalData?.participant?.date_of_birth]);

    useEffect(() => {
        const shouldUpdate = (internalData?.parent?.date_of_birth ?? '') === oldParentDateOfBirth;
        const newComputedDateOfBirth = firstParentValidationResult?.taxCode?.dateOfBirth;

        if (newComputedDateOfBirth && shouldUpdate) {
            setOldParentDateOfBirth(newComputedDateOfBirth);
            rawOnChange('parent.date_of_birth', newComputedDateOfBirth);
        }
    }, [rawOnChange, oldParentDateOfBirth, firstParentValidationResult?.taxCode?.dateOfBirth, internalData?.parent?.date_of_birth]);

    return (
        <CustomDialogWrapper open={isOpen} onClose={onClose} title={'Modifica dettagli anagrafici - ' + sectionName} buttons={buttons} isLoading={isLoading} maxWidth='md' fullWidth >
            <GenericPersonForm
                rawOnChange={rawOnChange}
                dataOnChange={onChange}
                disabledFields={[]}
                {...fieldsHookProps}
            />
        </CustomDialogWrapper>
    );
};

export default EditDataDialog;
