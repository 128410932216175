import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import useSearchParams from '../../../../../../../common/hooks/useSearchParams/useSearchParams';
import GenericObject from '../../../../../../../typesAdditional/GenericObject';

interface Props {
    locations: GenericObject;
    setSelectedLocationId: Dispatch<SetStateAction<string>>;
}

const useTownFromParams = (props: Props) => {
    const { locations, setSelectedLocationId } = props;

    const { town } = useSearchParams();

    const [canUserChangeLocation, setCanUserChangeLocation] = useState<boolean>(true);

    useEffect(() => {
        if (town) {
            const location = locations.find((l: any) => l.town.legalId === town || (l.townDistinguishing && l.townDistinguishing === town));

            if (location) {
                setSelectedLocationId(location.id);
                setCanUserChangeLocation(false);
            }
        }
    }, [town, locations, setSelectedLocationId]);

    return { canUserChangeLocation };
};

export default useTownFromParams;
