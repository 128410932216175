import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';


import { AppTitleContainer, MenuButton, MenuButtonContainer, StyledAppBar } from './styled';

interface Props {
    showSidebar: boolean;
    openDrawer: () => void;
}

const AppBar = (props: Props) => {
    const { showSidebar, openDrawer } = props;

    return (
        <StyledAppBar>
            <Toolbar>
                <MenuButtonContainer show={showSidebar}>
                    <MenuButton color='inherit' edge='start' onClick={openDrawer} >
                        <MenuIcon />
                    </MenuButton>
                </MenuButtonContainer>

                <AppTitleContainer center={!showSidebar}>
                    <Typography variant='h6' noWrap>
                        <a href="https://epuntos.it">
                            <img
                                src={process.env.PUBLIC_URL + '/logo-landscape-white.png'}
                                style={{ height: '45px', marginTop: '10px', marginLeft: showSidebar ? '20px' : undefined }}
                                alt="Logo E.S"
                            />
                        </a>
                    </Typography>
                </AppTitleContainer>
            </Toolbar>
        </StyledAppBar>
    );

};

export default AppBar;
