import { useSelector } from 'react-redux';
import { useWindowSize } from 'react-use';

import { ReduxState } from '../../../../../../redux/types';
import Location from '../../../../../../types/Location';
import { Container, Title } from './styled';

interface Props {
    title: string;
    preselectedLocation?: Location;
}

const Header = (props: Props) => {
    const { title, preselectedLocation } = props;

    const { isIframe } = useSelector((state: ReduxState) => state.routing);
    const { width } = useWindowSize();

    const isMobile = width <= 700;

    return (
        <Container isMobile={isMobile}>
            <Title isMobile={isMobile} isIframe={isIframe}>
                {title} {preselectedLocation && (' - ' + preselectedLocation.townName)}
            </Title>

            {!isIframe && (
                <div style={{fontSize: '0.65em', margin: '0 auto', display: width > 700 ? 'flex' : 'block', alignItems: 'center', textAlign: width > 700 ? 'right' : 'center'}}>
                    <p style={{margin: '0'}}>Modulo di iscrizione</p>
                </div>
            )}
        </Container>
    );
};

export default Header;
