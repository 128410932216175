import { useCallback, useMemo } from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import moment from 'moment';

import { useMessageDialog } from '../../../../../../../../../common/hooks/useMessageDialog/useMessageDialog';
import { SingleSessionSelectorProps } from '../../../types';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

interface Props extends SingleSessionSelectorProps {
    isDifferentCourse: boolean;
    showTownName?: boolean;
    applyPriceOffset?: boolean;
    getSessionPriceWithOffset?: (sessionId: string) => number;
    pricesNotAvailable?: boolean;
    singleSessionEvent?: boolean;
    isSummer?: boolean;
    userCanChooseSessions: boolean;
    sessionsLabels: string[];
}

const SessionCard = (props: Props) => {
    const { sessionType, session, hasFoodService, isSelected, isFoodSelected, handleSelect, handleFoodSelect, isDifferentCourse, showTownName, applyPriceOffset, getSessionPriceWithOffset, pricesNotAvailable, singleSessionEvent, isSummer, userCanChooseSessions, sessionsLabels } = props;

    const [showMessageDialog] = useMessageDialog();

    const handleBlockedSession = useCallback((reason: string) => {
        if (reason === 'edit_too_late') {
            showMessageDialog({
                title: 'Iscrizione già effettuata',
                message: <>
                    <p style={{ marginTop: '0px' }}>La cancellazione può essere comunicata solo via mail, scrivendo a <Link href='mailto:info@epuntos.it' target='_blank' rel='noreferrer'>info@epuntos.it</Link>, entro i termini previsti dalle condizioni di partecipazione, accettate in fase di iscrizione.</p>
                    <p style={{ marginBottom: '0px' }}>Grazie</p>
                </>
            });
        } else if (reason === 'too_late') {
            showMessageDialog({
                title: 'Iscrizioni chiuse',
                message: <>
                    <p style={{ marginTop: '0px' }}>Non è più possibile iscriversi alla settimana selezionata.</p>
                    <p style={{ marginBottom: '0px' }}>Per maggiori informazioni: <Link href='mailto:info@epuntos.it' target='_blank' rel='noreferrer'>info@epuntos.it</Link>.</p>
                </>
            });
        } else if (reason === 'welfare') {
            showMessageDialog({
                title: 'Impossibile modificare l\'iscrizione',
                message: <>
                    <p style={{ marginTop: '0px' }}>Non puoi modificare l'iscrizione in questo momento, devi prima completare l'iscrizione welfare.</p>
                    <p style={{ marginBottom: '0px' }}>Per ulteriori necessità scrivi a <Link href='mailto:info@epuntos.it' target='_blank' rel='noreferrer'>info@epuntos.it</Link>.</p>
                </>
            });
        } else if (reason === 'overlap') {
            showMessageDialog({
                title: 'Impossibile iscriversi a questa settimana',
                message: <>
                    <p style={{ marginTop: '0px' }}>Il partecipante è già iscritto a un altro camp nella settimana selezionata.</p>
                    <p style={{ marginBottom: '0px' }}>Per ulteriori necessità scrivi a <Link href='mailto:info@epuntos.it' target='_blank' rel='noreferrer'>info@epuntos.it</Link>.</p>
                </>
            });
        } else if (reason === 'overlap_winter') {
            showMessageDialog({
                title: 'Impossibile iscriversi a questo giorno',
                message: <>
                    <p style={{ marginTop: '0px' }}>Il partecipante è già iscritto a un altro corso nel giorno selezionato.</p>
                    <p style={{ marginBottom: '0px' }}>Per ulteriori necessità scrivi a <Link href='mailto:info@epuntos.it' target='_blank' rel='noreferrer'>info@epuntos.it</Link>.</p>
                </>
            });
        } else if (reason === 'disabled') {
            showMessageDialog({
                title: 'Impossibile modificare l\'iscrizione',
                message: <>
                    <p style={{ marginTop: '0px' }}>Non è più possibile modificare la sua iscrizione.</p>
                    <p style={{ marginBottom: '0px' }}>Per ulteriori necessità scriva a <Link href='mailto:info@epuntos.it' target='_blank' rel='noreferrer'>info@epuntos.it</Link>.</p>
                </>
            });
        } else {
            showMessageDialog({
                title: 'Impossibile modificare l\'iscrizione',
                message: <>
                    <p style={{ marginTop: '0px' }}>Non è più possibile modificare l'iscrizione.</p>
                    <p style={{ marginBottom: '0px' }}>Le iscrizioni possono essere modificate entro il termine del progetto.</p>
                </>
            });
        }
    }, [showMessageDialog]);

    const date = useMemo(() => {
        if (singleSessionEvent) {
            return (
                <>
                    <strong>{moment(session.startTime).format('D MMMM YYYY')}</strong>, dalle ore <strong>{moment(session.startTime).format('H:mm')}</strong> alle ore <strong>{moment(session.endTime).format('H:mm')}</strong>
                </>
            )
        } else if (sessionType === 'daily_week_events') {
            return moment(session.startTime).format('D MMMM YYYY') + ' - ' + moment(session.endTime).format('D MMMM YYYY');
        } else if (sessionType === 'weekly_events') {
            return (
                <>
                    <strong>{moment(session.startTime).format('dddd')}</strong>, dalle ore <strong>{moment(session.startTime).format('H:mm')}</strong> alle ore <strong>{moment(session.endTime).format('H:mm')}</strong>
                </>
            );
        } else {
            return moment(session.startTime).format('dddd D MMMM YYYY');
        }
    }, [session, sessionType, singleSessionEvent]);

    const realSessions = useMemo(() => {
        return [session, ...session._additionals];
    }, [session])

    const foodServiceSession = useMemo(() => {
        return realSessions.find((s: any) => s.hasFoodService) ?? realSessions[0];
    }, [realSessions]);

    return (
        <Card variant='outlined' style={{ marginBottom: '12px' }}>
            <CardContent style={{ textAlign: 'center', paddingTop: '10px', paddingBottom: '8px' }}>
                <div style={userCanChooseSessions ? { marginBottom: '10px' } : { marginTop: '8px', marginBottom: '14px' }}>
                    {isDifferentCourse && (
                        <><b>{session.location.course.name}</b><br /></>
                    )}{date}{showTownName && (
                        <><br /><b>{session.location.townName}</b></>
                    )}
                </div>
                {((!pricesNotAvailable && !session.hidePrice) || userCanChooseSessions) && (
                    <>
                        {sessionsLabels.length === 0 ? (
                            <>
                                <div style={{ marginBottom: '16px' }}>
                                    {!singleSessionEvent && (<b>iscrizione {isSummer ? 'camp' : 'corso'}{(hasFoodService && session.prices.course.actual.foodIncludedInPrice && session.hasFoodService) ? ' + pasti' : ''}</b>)}
                                    {!pricesNotAvailable && !session.hidePrice && (<p style={{ margin: 0 }}>€{((applyPriceOffset && getSessionPriceWithOffset) ? getSessionPriceWithOffset(session.id) : session.prices.course.actual.price).toFixed(2)}</p>)}
                                    {userCanChooseSessions && (
                                        <div style={{ display: 'inline-block' }} onClick={() => session.disabled && handleBlockedSession(session.disabledReason)}>
                                            {!singleSessionEvent ? (
                                                <Checkbox
                                                    color='primary'
                                                    style={{ padding: '4px', transform: 'scale(1.2)' }}
                                                    checked={isSelected(session.id) || ((session.status === 'registered' || session.status === 'queue') && session.disabled)}
                                                    onChange={(e) => {
                                                        handleSelect(session.id, e.target.checked)

                                                        if (e.target.checked && session.prices.course.actual.foodIncludedInPrice) {
                                                            handleFoodSelect(session.id, e.target.checked)
                                                        }
                                                    }}
                                                    disabled={session.disabled}
                                                />
                                            ) : (
                                                <FormControl component='fieldset'>
                                                    <RadioGroup aria-label='gender' name='question' value={(isSelected(session.id) || session.status === 'registered' || session.status === 'queue') ? 'yes' : 'no'} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        const value = (event.target as HTMLInputElement).value;
                                                        handleSelect(session.id, value === 'yes')
                                                    }}>
                                                        {/*<FormControlLabel value='no' control={<Radio color='primary' />} label='no' disabled={session.disabled} />*/}
                                                        <FormControlLabel value='yes' control={<Radio color='primary' />} label='sì' disabled={session.disabled} />
                                                    </RadioGroup>
                                                </FormControl>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </>
                        ) : (
                            <>
                                {sessionsLabels.map(currSessionLabel => {
                                    const currSession = realSessions.find(s => s.overrideLabel === currSessionLabel);

                                    return (
                                        <div style={{ marginBottom: '16px' }}>
                                            {!singleSessionEvent && (<b>{currSessionLabel.toLowerCase()}</b>)}
                                            {!pricesNotAvailable && !session.hidePrice && (<p style={{ margin: 0 }}>€{((applyPriceOffset && getSessionPriceWithOffset) ? getSessionPriceWithOffset(currSession.id) : currSession.prices.course.actual.price).toFixed(2)}</p>)}
                                            {userCanChooseSessions && (
                                                <div style={{ display: 'inline-block' }} onClick={() => currSession.disabled && handleBlockedSession(currSession.disabledReason)}>
                                                    {!singleSessionEvent ? (
                                                        <Checkbox
                                                            color='primary'
                                                            style={{ padding: '4px', transform: 'scale(1.2)' }}
                                                            checked={isSelected(currSession.id) || ((currSession.status === 'registered' || currSession.status === 'queue') && currSession.disabled)}
                                                            onChange={(e) => {
                                                                handleSelect(currSession.id, e.target.checked)

                                                                if (e.target.checked && currSession.prices.course.actual.foodIncludedInPrice) {
                                                                    handleFoodSelect(currSession.id, e.target.checked)
                                                                }
                                                            }}
                                                            disabled={currSession.disabled}
                                                        />
                                                    ) : (
                                                        <FormControl component='fieldset'>
                                                            <RadioGroup aria-label='gender' name='question' value={(isSelected(currSession.id) || currSession.status === 'registered' || currSession.status === 'queue') ? 'yes' : 'no'} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                const value = (event.target as HTMLInputElement).value;
                                                                handleSelect(currSession.id, value === 'yes')
                                                            }}>
                                                                {/*<FormControlLabel value='no' control={<Radio color='primary' />} label='no' disabled={session.disabled} />*/}
                                                                <FormControlLabel value='yes' control={<Radio color='primary' />} label='sì' disabled={currSession.disabled} />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )
                                })}
                            </>
                        )}
                    </>

                )}
                {hasFoodService && !foodServiceSession.prices.course.actual.foodIncludedInPrice && (
                    <div>
                        <b>iscrizione servizio pasti</b>
                        <p style={{ margin: 0 }}>
                            {foodServiceSession.hasFoodService ? (
                                <>
                                    €{foodServiceSession.prices.foodService.actual.price.toFixed(2)}
                                </>
                            ) : (
                                <>
                                    ---
                                </>
                            )}
                        </p>
                        <div style={{ display: 'inline-block' }} onClick={() => foodServiceSession.foodDisabled && handleBlockedSession(foodServiceSession.disabledReason)}>
                            <Checkbox
                                color='primary'
                                style={{ padding: '4px', transform: 'scale(1.2)' }}
                                checked={isFoodSelected(foodServiceSession.id) || ((foodServiceSession.status === 'registered' || foodServiceSession.status === 'queue') && foodServiceSession.userWantsThisSessionFoodService)}
                                onChange={(e) => handleFoodSelect(foodServiceSession.id, e.target.checked)}
                                disabled={foodServiceSession.foodDisabled}
                            />
                        </div>
                    </div>
                )}
            </CardContent>
        </Card>
    );
};

export default SessionCard;
