import { useCallback, useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';

import useInternalLoader from '../../../../common/hooks/useInternalLoader/useExternalLoader';
import api from '../../../../common/utils/api';
import ViewMessageDialog from './components/ViewMessageDialog/ViewMessageDialog';
import { Title } from './styled';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Menu } from '@material-ui/core';
import MessageRecipientsDialog from './components/MessageRecipientsDialog/MessageRecipientsDialog';

const WhatsappMessagesListPage = () => {
    const { width } = useWindowSize();
    const isMobile = width <= 700;

    const setIsLoading = useInternalLoader();
    const [data, setData] = useState<any[]>([]);

    const [isViewMessageDialogOpen, setIsViewMessageDialogOpen] = useState(false);
    const [isViewRecipientsDialogOpen, setIsViewRecipientsDialogOpen] = useState(false);
    const [selectedGroupId, setSelectedGroupId] = useState('');

    const [anchor, setAnchorEl] = useState<null | HTMLElement>(null);
    const [menuGroupId, setMenuGroupId] = useState<null | string>(null);

    const handleMenuClick = useCallback((event: React.MouseEvent<HTMLElement>, userId: string) => {
        setAnchorEl(event.currentTarget);
        setMenuGroupId(userId);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    useEffect(() => {
        api.request('/admin/whatsapp/messages').then(res => {
            setData(res);
            setIsLoading(false);
        });
    }, [setIsLoading]);

    return (
        <>
            <Title isMobile={isMobile}>
                Messaggi WhatsApp inviati
            </Title>

            <TableContainer component={Paper}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Data</TableCell>
                            <TableCell>Oggetto</TableCell>
                            <TableCell>Inviata da</TableCell>
                            <TableCell>Messaggi schedulati</TableCell>
                            <TableCell>Messaggi inviati</TableCell>
                            <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>Azioni</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((email: any) => {
                            return (
                                <TableRow>
                                    <TableCell>{moment(email.createdAt).format('DD/MM/YYYY')}</TableCell>
                                    <TableCell>{email.subject}</TableCell>
                                    <TableCell>{email.superUser.name}</TableCell>
                                    <TableCell>{email.scheduledMessages}</TableCell>
                                    <TableCell>{email.sentMessages}</TableCell>
                                    <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>
                                        <IconButton onClick={(e: any) => handleMenuClick(e, email.id)} >
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            anchorEl={anchor}
                                            keepMounted
                                            open={!!anchor && menuGroupId === email.id}
                                            onClose={handleClose}
                                        >
                                            <MenuItem onClick={() => {
                                                setSelectedGroupId(email.id);
                                                setIsViewMessageDialogOpen(true);
                                                handleClose();
                                            }}>
                                                Visualizza messaggio
                                            </MenuItem>
                                            <MenuItem onClick={() => {
                                                setSelectedGroupId(email.id);
                                                setIsViewRecipientsDialogOpen(true);
                                                handleClose();
                                            }}>
                                                Visualizza destinatari
                                            </MenuItem>
                                        </Menu>

                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <ViewMessageDialog
                isOpen={isViewMessageDialogOpen}
                onClose={() => {
                    setIsViewMessageDialogOpen(false);
                }}
                message={data.find(d => d.id === selectedGroupId)}
            />

            <MessageRecipientsDialog
                isOpen={isViewRecipientsDialogOpen}
                onClose={() => {
                    setIsViewRecipientsDialogOpen(false);
                }}
                groupId={selectedGroupId}
            />

        </>
    );
};

export default WhatsappMessagesListPage;
