import { useCallback, useEffect, useState } from 'react';

import { TextField } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import { StyledTextField } from '../../../../../../common/components/CustomTextField/styled';
import api from '../../../../../../common/utils/api';
import snackbar from '../../../../../../common/utils/snackbar';

interface Props {
    open: boolean;
    closeDialog: (reloadData?: boolean) => void;
}

const filter = createFilterOptions<string>();

const AddUserDialog = (props: Props) => {
    const { open, closeDialog } = props;

    const [isLoading, setIsLoading] = useState(false);

    const [tags, setTags] = useState<string[]>([]);
    const [tagsList, setTagsList] = useState<string[]>([]);

    const [email, setEmail] = useState('');
    const [enableNewsletter, setEnableNewsletter] = useState(false);

    useEffect(() => {
        if (!open) {
            api.request('/admin/emails/tags').then((res: string[]) => {
                setTagsList(res);
            });

            return;
        }

        setEmail('');
        setEnableNewsletter(false);
        setTags([]);
    }, [open]);

    const handleSubmit = useCallback(() => {
        setIsLoading(true);

        api.request('/admin/emails/users', 'POST', { email, enableNewsletter, tags }).then(() => {
            snackbar.success('Utente aggiunto con successo!');
            closeDialog(true);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [closeDialog, email, enableNewsletter, tags]);

    const handleKeyDown = useCallback((e) => {
        if (e.keyCode === 13) {
            handleSubmit();
        }
    }, [handleSubmit]);

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'secondary',
            onClick: () => closeDialog()
        },
        {
            children: 'Aggiungi',
            color: 'primary',
            onClick: () => handleSubmit()
        },
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Aggiungi contatto'} buttons={buttons} isLoading={isLoading} maxWidth='sm' fullWidth>
            <StyledTextField
                label={'Indirizzo email'}
                variant='outlined'
                type='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={handleKeyDown}
                fullWidth
            />

            <FormControlLabel
                control={
                    <Checkbox
                        color='primary'
                        style={{padding: '4px'}}
                        checked={enableNewsletter}
                        onChange={(e) => {
                            setEnableNewsletter(e.target.checked);
                        }}
                    />
                }
                style={{marginLeft: '-4px', marginBottom: '8px'}}
                label={'Abilita newsletter'}
                disabled={isLoading}
            />

            <Autocomplete
                value={tags}
                multiple
                options={tagsList}
                getOptionLabel={(option: any) => option}
                defaultValue={[]}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    if (params.inputValue !== '') {
                        filtered.push(`Aggiungi "${params.inputValue}"`);
                    }

                    return filtered;
                }}
                onChange={(_event, newValue) => {
                    setTags(newValue.map(v => {
                        const regex = /Aggiungi "([\s\S]*)"/;
                        const match = v.match(regex);

                        if (match && match[1]) {
                            return match[1];
                        }

                        return v;
                    }));
                }}
                renderInput={(params: any) => (
                    <TextField
                        {...params}
                        variant='standard'
                        label='Tag'
                    />
                )}
            />
        </CustomDialogWrapper>
    );
};

export default AddUserDialog;
