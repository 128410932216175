import Button from '@material-ui/core/Button';
import routes from '../../routes';
import Logo from './components/Logo/Logo';
import RoutesList from './components/RoutesList/RoutesList';
import UserInfoSection from './components/UserInfoSection/UserInfoSection';
import NewTicketDialog from './components/NewTickedDialog/NewTicketDialog';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../redux/types';

const DrawerContent = () => {
    const [isNewTicketDialogOpen, setIsNewTicketDialogOpen] = useState(false);

    const userInfo = useSelector((state: ReduxState) => state.user);

    return (
        <>
            <UserInfoSection />
            <RoutesList routes={routes} depth={0} />

            {['cristian@cristianlivella.com', 'ariboli@epuntos.it', 'gbuzzone@epuntos.it', 'dscaburri@epuntos.it', 'nicola.bonaita@icloud.com', 'ecappellini@epuntos.it'].includes(userInfo.email) && (
                <div style={{ margin: '8px 0' }}>
                    <Button onClick={() => setIsNewTicketDialogOpen(true)} variant='outlined' style={{ margin: '0 auto', display: 'block' }}>
                        CREA TICKET TECNICO
                    </Button>
                </div>
            )}

            <Logo />

            <NewTicketDialog
                open={isNewTicketDialogOpen}
                closeDialog={() => setIsNewTicketDialogOpen(false)}
            />
        </>
    );
};

export default DrawerContent;
