import MenuItem from '@material-ui/core/MenuItem';

import CustomTextField from '../../../../../../../../common/components/CustomTextField/CustomTextField';
import FlexInputContainer from '../../../../../../../../common/components/FlexInputContainer/FlexInputContainer';
import { SectionProps } from '../../GenericPersonForm';

const SchoolClass = (props: SectionProps) => {
    const { dataOnChange, rawOnChange, schoolClasses, ...validatorProps } = props;
    const { formPrefix, data } = validatorProps;

    const options = (schoolClasses ?? []).map(s => {
        return {
            id: s.id,
            name: s.description
        };
    })

    return (
        <FlexInputContainer>
            <CustomTextField
                label='Classe frequentata'
                path={formPrefix + '.schoolClass'}
                variant='outlined'
                data={data}
                dataOnChange={dataOnChange}
                select
            >
                {options.map(opt => {
                    return (
                        <MenuItem key={opt.id} value={opt.id} style={{ whiteSpace: 'break-spaces' }}>
                            {opt.name}
                        </MenuItem>
                    );
                })}
            </CustomTextField>
        </FlexInputContainer>
    );
};

export default SchoolClass;
