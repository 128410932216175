import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useOpenDialog from '../../../../common/hooks/useOpenDialog/useOpenDialog';
import { ReduxState } from '../../../../redux/types';

const LoginDialogPage = () => {
    const userInfo = useSelector((state: ReduxState) => state.user);
    const isUserLoggedIn = userInfo.id.length > 0;

    const history = useHistory();
    const openLoginDialog = useOpenDialog('loginDialog');

    useEffect(() => {
        if (!isUserLoggedIn) {
            openLoginDialog();
        }

        history.push('/');
    }, [isUserLoggedIn, openLoginDialog, history]);

    return null;
};

export default LoginDialogPage;
