import { ButtonProps } from '@material-ui/core/Button';
import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import CustomTextField from '../../../../../../common/components/CustomTextField/CustomTextField';
import { MenuItem } from '@material-ui/core';

interface Props {
    open: boolean;
    closeDialog: () => void;
    selectedYear: number;
    setSelectedYear: (year: number) => void;
}

const SelectYearDialog = (props: Props) => {
    const { open, closeDialog, selectedYear, setSelectedYear } = props;

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        }
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Seleziona anno'} buttons={buttons} maxWidth='xs' fullWidth >
            <CustomTextField
                select
                label={'Anno'}
                value={selectedYear}
                onChange={(e: any) => {
                    setSelectedYear(e.target.value);
                }}
                variant='outlined'
                fullWidth
            >
                <MenuItem value={2024} style={{ whiteSpace: 'break-spaces' }}>
                    2024
                </MenuItem>

                <MenuItem value={2025} style={{ whiteSpace: 'break-spaces' }}>
                    2025
                </MenuItem>
            </CustomTextField>
        </CustomDialogWrapper >
    );
};

export default SelectYearDialog;
