import { ButtonProps } from '@material-ui/core/Button';
import 'moment/locale/it';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import GenericObject from '../../../../../../typesAdditional/GenericObject';
import CardContent from '@material-ui/core/CardContent';
import FileUploader from '../../../newWhatsappMessagePage/components/FileUploader/FileUploader';
import Typography from '@material-ui/core/Typography';
import { useWindowSize } from 'react-use';
import Card from '@material-ui/core/Card';
import sanitizeHtml from 'sanitize-html';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    message?: GenericObject;
}

const ViewMessageDialog = (props: Props) => {
    const { isOpen, onClose, message } = props;

    const { width } = useWindowSize();

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => onClose()
        },
    ];

    const initialAssets = (message && message.asset) ? [message.asset] : [];

    return (
        <>
            <CustomDialogWrapper open={isOpen} onClose={() => onClose()} title={'Messaggio WhatsApp'} buttons={buttons} maxWidth='md' fullWidth >
                <div style={{ display: width > 1200 ? 'flex' : 'block', marginBottom: '22px' }}>
                    <Card style={{ flex: '4', maxWidth: '1400px', marginTop: '12px', marginRight: (width > 1200 ? '8px' : undefined) }}>
                        <CardContent>
                            <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(message?.formattedMessage ?? '') }} style={{ whiteSpace: 'pre-line' }} />
                        </CardContent>
                    </Card>

                    {initialAssets.length > 0 && (
                        <div style={{ flex: '3', marginTop: '12px', marginLeft: (width > 1200 ? '8px' : undefined) }}>
                            <Card style={{ height: '100%', minHeight: (width <= 1200 ? '400px' : undefined) }}>
                                <CardContent style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                    <Typography variant='h5' component='h2' style={{ flex: 1, flexGrow: 0, marginBottom: '12px' }}>
                                        Allegato
                                    </Typography>

                                    <FileUploader savedAssets={initialAssets} documentId={'dummy'} status={'ok'} handleAssetChange={() => undefined} isAdmin={false} />
                                </CardContent>
                            </Card>
                        </div>
                    )}
                </div>
            </CustomDialogWrapper>
        </>
    );
};

export default ViewMessageDialog;
