import { useMemo } from 'react';
import validator from 'validator';

import GenericObject from '../../../../../../../typesAdditional/GenericObject';

interface Props {
    formPrefix: string;
    data: GenericObject;
}

const useValidateEmailAddress = (props: Props) => {
    const { formPrefix, data } = props;

    return useMemo(() => {
        return {
            'email': validator.isEmail(data[formPrefix]?.email ?? '')
        };
    }, [data, formPrefix])
};

export default useValidateEmailAddress;
