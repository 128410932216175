import { Dispatch, SetStateAction, useCallback } from 'react';
import { useSelector } from 'react-redux';

import useGenericErrorDialog from '../../../../../../../common/hooks/useGenericErrorDialog/useGenericErrorDialog';
import { useMessageDialog } from '../../../../../../../common/hooks/useMessageDialog/useMessageDialog';
import { ReduxState } from '../../../../../../../redux/types';

interface Params {
    setSelectedLocationId?: Dispatch<SetStateAction<string>>;
    setSelectedSessions: Dispatch<SetStateAction<string[]>>;
}

const useHandleApiError = (params: Params) => {
    const { setSelectedLocationId, setSelectedSessions } = params;

    const { isIframe } = useSelector((state: ReduxState) => state.routing);

    const [showMessageDialog] = useMessageDialog();
    const showGenericErrorDialog = useGenericErrorDialog();

    return useCallback((res: any) => {
        if (res.errorDesc) {
            const { errorDesc } = res;

            showMessageDialog({
                title: 'Errore validazione dati',
                message: errorDesc,
                notDismissible: isIframe || res.error === 'error_notte_al_multisport'
            });

            if (setSelectedLocationId) {
                setSelectedLocationId('');
            }

            setSelectedSessions([]);
        } else {
            showGenericErrorDialog(res);
        }
    }, [isIframe, setSelectedSessions, setSelectedLocationId, showMessageDialog, showGenericErrorDialog]);
};

export default useHandleApiError;
