import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';

import { PersonForRegistration } from '../../types';
import AddPersonItem from './components/AddPersonItem/AddPersonItem';
import PersonListItem from './components/PersonListItem/PersonListItem';

interface Props {
    people: PersonForRegistration[];
    selectPerson: (person: PersonForRegistration) => void;
    createNewPerson: () => void;
}

const PersonList = (props: Props) => {
    const { people, selectPerson, createNewPerson } = props;

    const handleLinkClick = (e: React.SyntheticEvent) => {
        createNewPerson();
        e.preventDefault();
        return false;
    };

    return (
        <div style={{textAlign: 'center'}}>
            <Typography variant='body1'>
                Scegli quale persona vuoi iscrivere, oppure{' '}
                <Link href='#' onClick={handleLinkClick}>clicca qui</Link>{' '}
                se non è presente nell'elenco.
            </Typography>

            <List style={{maxWidth: '400px', margin: '0 auto'}}>
                {people.map(person => (
                    <PersonListItem person={person} onClick={() => selectPerson(person)} />
                ))}
                <AddPersonItem onClick={createNewPerson} />
            </List>
        </div>
    );
};

export default PersonList;
