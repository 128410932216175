import { ButtonProps } from "@material-ui/core/Button";
import CustomDialogWrapper from "../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper";
import GenericObject from "../../../../../../typesAdditional/GenericObject";
import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import moment from 'moment';
import 'moment/locale/it';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import { TableBody, TableCell, TableContainer, TableHead, ListItemText, ListItem } from '@material-ui/core';
import styled from "styled-components/macro";
import { Entry } from "../AddInstructorDialog/AddInstructorDialog";
import { useSelector } from "react-redux";
import { ReduxState } from "../../../../../../redux/types";

interface Props {
    open: boolean;
    closeDialog: () => void;
    selectedWeek?: GenericObject;
    selectedLocation?: GenericObject;
    setSelectedTrainerId: Dispatch<SetStateAction<string | undefined>>;
    setSelectedLocationId: Dispatch<SetStateAction<string | undefined>>;
    setAddInstructorDialogMode: Dispatch<SetStateAction<'add' | 'edit' | 'replacement'>>;
    setIsAddInstructorDialogOpen: Dispatch<SetStateAction<boolean>>;
    setIsFindAvailabilityDialogOpen: Dispatch<SetStateAction<boolean>>;
    setAddInstructorEntriesToSelect: Dispatch<SetStateAction<Entry[] | null>>;
    readOnly: boolean;
}

const StyledListItem = styled(ListItem) <{ backgroundColor?: string, canManageStaff: boolean }>`  
    ${props => props.backgroundColor ? ('background-color: ' + props.backgroundColor + ' !important;') : ''} 
 
    ${props => props.canManageStaff && (
        `&:hover {
            background-color: #0000000a !important;
        }`
    )} 
`

const DetailedScheduleDialog = (props: Props) => {
    const { open, closeDialog, selectedWeek, selectedLocation, setSelectedTrainerId, setSelectedLocationId, setAddInstructorDialogMode, setIsAddInstructorDialogOpen, setIsFindAvailabilityDialogOpen, setAddInstructorEntriesToSelect, readOnly } = props;

    const firstDay = selectedLocation?.firstDay;
    const lastDay = selectedLocation?.lastDay;

    const userInfo = useSelector((state: ReduxState) => state.user);
    const canManageStaff = userInfo?.canManageStaff ?? false;

    const dialogTitle = useMemo(() => {
        if (!selectedLocation || !selectedWeek) return '';

        return (selectedLocation.courseShortName ?? selectedLocation.courseName) + ' ' + selectedLocation.townName + ' | ' + selectedWeek.shortName;
    }, [selectedWeek, selectedLocation]);

    const getCountMaxTrainersWithAttributes = useCallback((dayTime: 'morning' | 'afternoon', responsibile: boolean) => {
        if (!selectedLocation) return 0;

        const trainerPerDay = selectedLocation.schedule
            .filter((s: any) => s.isResponsible === responsibile)
            .map((s: any) => s.entries).flat().reduce((prev: number[], curr: any) => {
                if (curr.dayTime !== dayTime) return prev;

                const clonedPrev = [...prev];
                clonedPrev[curr.day] = (clonedPrev[curr.day] ?? 0) + 1;
                return clonedPrev;
            }, []);

        return Math.max(0, Math.max.apply(null, trainerPerDay.filter((x: any) => !!x)));
    }, [selectedLocation]);

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        },
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={dialogTitle} buttons={buttons} maxWidth='xl'>
            <TableContainer style={{ marginTop: '16px', width: '1150px' }} >
                <Table size='small' style={{ tableLayout: 'fixed' }}>
                    {(['morning', 'afternoon'] as ('morning' | 'afternoon')[]).map(dayTime => {
                        return (
                            <>
                                <TableHead>
                                    <TableRow style={{ backgroundColor: dayTime === 'morning' ? '#ffd599' : '#b7dfb9' }}>
                                        <TableCell style={{ fontWeight: 'bold' }}>{dayTime === 'morning' ? 'MATTINA' : 'POMERIGGIO'} </TableCell>

                                        {[...Array(6).keys()].map((day) => {
                                            const realDay = day + 1;

                                            if (realDay < firstDay || realDay > lastDay) return null;

                                            return (
                                                <TableCell style={{ fontWeight: 'bold', width: ((100 - 10) / (lastDay - firstDay + 1)) + '%', textAlign: 'center' }}>
                                                    {moment(selectedWeek?.startDate).add(day, 'days').format('dddd')}{(lastDay - firstDay) > 4 ? <br /> : ' '}
                                                    {moment(selectedWeek?.startDate).add(day, 'days').format('DD/MM')}
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {[true, false].map(isResponsible => {
                                        return (
                                            [...Array(getCountMaxTrainersWithAttributes(dayTime, isResponsible)).keys()].map((trainerIdx) => {
                                                return (
                                                    <TableRow>
                                                        <TableCell />
                                                        {
                                                            [...Array(6).keys()].map((day) => {
                                                                const realDay = day + 1;

                                                                if (realDay < firstDay || realDay > lastDay) return null;

                                                                const trainers = (selectedLocation?.schedule ?? []).filter((s: any) => s.isResponsible === isResponsible && s.entries.find((e: any) => e.day === realDay && e.dayTime === dayTime)).sort((trainer1: any, trainer2: any) => {
                                                                    return (trainer1.superUser.lastName + ' ' + trainer1.superUser.firstName).localeCompare(trainer2.superUser.lastName + ' ' + trainer2.superUser.firstName);
                                                                });

                                                                const trainer = trainers[trainerIdx]?.superUser;

                                                                return (
                                                                    <TableCell style={{ maxWidth: ((100 - 10) / (lastDay - firstDay + 1)) + '%', padding: 0, textAlign: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                        <StyledListItem style={{ padding: '8px 6px', height: '38px', display: 'flex', flexDirection: 'row', alignItems: 'center', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                            backgroundColor={trainer ? (isResponsible ? '#e8f4fd' : undefined) : '#fbdcd8'}
                                                                            canManageStaff={canManageStaff}
                                                                            onClick={() => {
                                                                                if (!canManageStaff || readOnly) return;
                                                                                setSelectedLocationId(selectedLocation?.id);

                                                                                if (trainer) {
                                                                                    setSelectedTrainerId(trainer.id);
                                                                                    setAddInstructorDialogMode('edit');
                                                                                    setIsAddInstructorDialogOpen(true);
                                                                                    setAddInstructorEntriesToSelect(null);
                                                                                } else {
                                                                                    setIsFindAvailabilityDialogOpen(true);
                                                                                    setAddInstructorEntriesToSelect([{ day: realDay, dayTime }]);
                                                                                }
                                                                            }}
                                                                            // @ts-ignore
                                                                            button={canManageStaff && !readOnly}
                                                                        >
                                                                            <ListItemText
                                                                                primary={(
                                                                                    <p style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center', margin: 0, fontWeight: isResponsible ? 'bold' : undefined, ...(isResponsible ? { textTransform: 'uppercase', fontStyle: 'italic' } : {}) }}>
                                                                                        {trainer ? (trainer.firstName + ' ' + trainer.lastName) : ''}
                                                                                    </p>
                                                                                )}
                                                                            />
                                                                        </StyledListItem>
                                                                    </TableCell>
                                                                )
                                                            })
                                                        }
                                                    </TableRow>
                                                )
                                            })
                                        )
                                    })}
                                </TableBody>

                                {dayTime === 'morning' && (
                                    <TableRow style={{ height: '32px' }} />
                                )}
                            </>
                        )
                    })}
                </Table>
            </TableContainer>
        </CustomDialogWrapper>
    )
}

export default DetailedScheduleDialog; 