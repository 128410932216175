import { useEffect, useState } from 'react';

import { ButtonProps } from '@material-ui/core/Button';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import 'moment/locale/it';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

interface Props {
    open: boolean;
    isLoading: boolean;
    saveData: (date: string) => void;
}

const SelectStartDateDialog = (props: Props) => {
    const { open, isLoading, saveData } = props;

    const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [overridePreviousWages, setOverridePreviousWages] = useState(false);

    useEffect(() => {
        if (!open) return;

        setDate(new Date().toISOString().split('T')[0]);
        setIsDatePickerOpen(false);
    }, [open])

    const buttons: ButtonProps[] = [
        {
            children: 'Salva',
            color: 'primary',
            onClick: () => saveData(overridePreviousWages ? '1970-01-01' : date)
        }
    ];

    return (
        <CustomDialogWrapper open={open} title={'Data inizio validità'} buttons={buttons} isLoading={isLoading} maxWidth='sm'>
            <Typography variant='body1' style={{ margin: '0 4px 6px', fontSize: '1em' }}>
                Seleziona la data di inizio di validità del compenso orario inserito.
            </Typography>

            <FormControlLabel
                control={
                    <Checkbox
                        color='primary'
                        style={{ padding: '4px' }}
                        checked={overridePreviousWages}
                        onChange={(e) => {
                            setOverridePreviousWages(e.target.checked);
                        }}
                    />
                }
                style={{ marginLeft: '-4px', marginBottom: '16px' }}
                label={'Sovrascrivi compensi precedenti'}
                disabled={isLoading}
            />

            <MuiPickersUtilsProvider locale='it' utils={MomentUtils}>
                <KeyboardDatePicker
                    label='Data inizio validità'
                    inputVariant='outlined'
                    format='DD/MM/YYYY'
                    value={date}
                    onChange={(newDate: MaterialUiPickersDate) => {
                        setDate(newDate?.format('YYYY-MM-DD') ?? '');
                    }}
                    open={isDatePickerOpen}
                    onClick={() => {
                        setIsDatePickerOpen(true);
                    }}
                    onClose={() => setIsDatePickerOpen(false)}
                    onKeyDown={(e) => {
                        e.preventDefault();
                        return false;
                    }}
                    style={{ flex: 1, marginTop: '2px', marginBottom: '4px' }}
                    inputProps={{ tabIndex: -1 }}
                    KeyboardButtonProps={{ tabIndex: -1 }}
                    fullWidth
                    disabled={isLoading || overridePreviousWages}
                    minDate={'2008-02-01'}
                    maxDate={moment(Date.now()).format('YYYY-MM-DD')}
                />
            </MuiPickersUtilsProvider>

        </CustomDialogWrapper>
    );
};

export default SelectStartDateDialog;
