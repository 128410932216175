import { Dispatch, SetStateAction } from 'react';

import { ButtonProps } from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';

interface Props {
    filters: any[];
    open: boolean;
    closeDialog: () => void;
    conditionNextStep: () => void;
    selectedFilter: string;
    setSelectedFilter: Dispatch<SetStateAction<string>>;
}

const ConditionDialog = (props: Props) => {
    const { filters, open, closeDialog, conditionNextStep, selectedFilter, setSelectedFilter } = props;

    const isLoading = false;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedFilter((event.target as HTMLInputElement).value);
    };

    const buttons: ButtonProps[] = [
        {
            children: 'Continua',
            color: 'primary',
            onClick: () => conditionNextStep()
        },
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        },
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Aggiungi condizione'} buttons={buttons} isLoading={isLoading} maxWidth='sm' fullWidth>
            <FormControl component='fieldset'>
                <RadioGroup aria-label='gender' name='gender1' value={selectedFilter} onChange={handleChange}>
                    {filters.map(filter => (
                        <FormControlLabel value={filter.key} control={<Radio color={'primary'} />} label={filter.description} />
                    ))}
                </RadioGroup>
            </FormControl>
        </CustomDialogWrapper>
    );
};

export default ConditionDialog;
