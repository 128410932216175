import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import * as Sentry from '@sentry/react';

import { ReduxState } from '../../../redux/types';

const SentryConfigDispatcher = () => {
    const userInfo = useSelector((state: ReduxState) => state.user);

    useEffect(() => {
        if (userInfo.id) {
            Sentry.setUser({
                id: userInfo.id,
                email: userInfo.email
            });

        } else {
            Sentry.configureScope(scope => scope.setUser(null));
        }
    }, [userInfo]);

    return null;
};

export default SentryConfigDispatcher;
