import { useEffect, useState } from 'react';

import api from '../../../../../common/utils/api';

const useFilters = () => {
    const [filters, setFilters] = useState([]);

    useEffect(() => {
        api.request('/admin/user_segments/filters').then(res => {
            setFilters(res);
        });
    }, []);

    return filters;
};

export default useFilters;
