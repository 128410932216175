import CircularProgress from '@material-ui/core/CircularProgress';

import { CenterSection, Container, LeftSection, RigthSection, StyledButton } from './styled';

interface Props {
    isLoading: boolean;
    prevStep?: () => void;
    nextStep: () => void;
    finalStep?: boolean;
}

const StepFooter = (props: Props) => {
    const { isLoading, nextStep, prevStep, finalStep } = props;

    return (
        <Container>
            <LeftSection>
                {prevStep && (
                    <StyledButton variant='outlined' color='primary' onClick={() => prevStep()} disabled={isLoading} >
                       Indietro
                    </StyledButton>
                )}
            </LeftSection>

            <CenterSection>
                {isLoading && <CircularProgress />}
            </CenterSection>

            <RigthSection>
                <StyledButton variant='contained' color='primary' onClick={() => nextStep()} disabled={isLoading} >
                   {finalStep ? 'Conferma' : 'Avanti'}
                </StyledButton>
            </RigthSection>
        </Container>
    );
};

export default StepFooter;
