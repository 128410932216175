import { Container, Logo, Spinner } from './styled';

const Loader = () => {
    return (
        <Container>
            <Logo src={process.env.PUBLIC_URL + '/logo512.png'} />
            <Spinner />
        </Container>
    );
};

export default Loader;
