import { useCallback, useEffect, useMemo, useState } from 'react';

import { ButtonProps } from '@material-ui/core/Button';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import { StyledTextField } from '../../../../../../common/components/CustomTextField/styled';
import api from '../../../../../../common/utils/api';
import snackbar from '../../../../../../common/utils/snackbar';
import TownAutoComplete from '../../../../../public/pages/registrationPage/components/GenericPersonForm/sections/address/components/TownAutocomplete/TownAutocomplete';

interface Props {
    open: boolean;
    closeDialog: (reloadData?: boolean, superUserId?: string) => void;
    selectedTrainerId?: string;
    overrideTitle?: string;
}

const AddFacilityDialog = (props: Props) => {
    const { open, closeDialog, selectedTrainerId, overrideTitle } = props;

    const [isLoading, setIsLoading] = useState(false);

    const [name, setName] = useState('');
    const [townLegalId, setTownLegalId] = useState<string | null>(null);

    const dummyTownData = useMemo(() => {
        return {
            dummy: {
                address: {
                    town_legal_id: townLegalId
                }
            }
        };
    }, [townLegalId]);

    const dummyTownRawOnChange = useCallback((path: string, value: string) => {
        if (path === 'dummy.address.town_legal_id') {
            setTownLegalId(value);
        }
    }, []);

    useEffect(() => {
        setName('');
        setTownLegalId(null);
    }, [open]);

    useEffect(() => {
        if (!open || !selectedTrainerId) return;

        setIsLoading(true);

        api.request('/admin/winter_staff/facilities/' + selectedTrainerId).then((res) => {
            setName(res.name);
            setTownLegalId(res.town.legalId);
            setIsLoading(false);
        });
    }, [open, selectedTrainerId])

    const handleSubmit = useCallback(() => {
        setIsLoading(true);

        if (selectedTrainerId) {
            api.request('/admin/winter_staff/facilities/' + selectedTrainerId, 'POST', { name, townLegalId }).then((res) => {
                snackbar.success('Palestra modificata con successo!');
                closeDialog(true, res.id);
            }).finally(() => {
                setIsLoading(false);
            });
        } else {
            api.request('/admin/winter_staff/facilities', 'POST', { name, townLegalId }).then((res) => {
                snackbar.success('Palestra aggiunta con successo!');
                closeDialog(true, res.id);
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }, [closeDialog, name, townLegalId, selectedTrainerId]);

    const handleKeyDown = useCallback((e) => {
        if (e.keyCode === 13) {
            handleSubmit();
        }
    }, [handleSubmit]);

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'secondary',
            onClick: () => closeDialog()
        },
        {
            children: selectedTrainerId ? 'Salva' : 'Aggiungi',
            color: 'primary',
            onClick: () => handleSubmit()
        },
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={overrideTitle ? overrideTitle : (selectedTrainerId ? 'Modifica palestra' : 'Aggiungi palestra')} buttons={buttons} isLoading={isLoading} maxWidth='sm' fullWidth>
            <div style={{ display: 'flex' }}>
                <StyledTextField
                    label={'Descrizione'}
                    variant='outlined'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onKeyDown={handleKeyDown}
                    style={{ marginRight: '6px' }}
                    fullWidth
                    disabled={isLoading}
                />
            </div>

            <TownAutoComplete data={dummyTownData} formPrefix={'dummy'} rawOnChange={dummyTownRawOnChange} overrideLabel='Paese' />
        </CustomDialogWrapper>
    );
};

export default AddFacilityDialog; 
