import { useCallback, useState } from 'react';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import snakecaseKeys from 'snakecase-keys';

import GenericPersonForm, { FormSection } from '../../../../../public/pages/registrationPage/components/GenericPersonForm/GenericPersonForm';
import PersonSelectDialog from './components/PersonSelectDialog/PersonSelectDialog';
import { Section } from './types';

export const sections: Section[] = [
    {
        name: 'Dati partecipante',
        formPrefix: 'participant',
        sections: ['basic_data', 'tax_code', 'address'],
        hideIfNotMinor: false
    },
    {
        name: 'Dati genitore 1',
        formPrefix: 'parent',
        sections: ['basic_data', 'tax_code', 'phone', 'email'],
        hideIfNotMinor: true
    },
    {
        name: 'Dati genitore 2',
        formPrefix: 'second_parent',
        sections: ['basic_data', 'phone', 'email'],
        hideIfNotMinor: true
    }
];

interface Props {
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: string) => void;
    rawOnChange: (field: string, value: any) => void;
    registrationData: any;
    validationResult: any;
    isParticipantMinor: boolean;
}

const PersonalData = (props: Props) => {
    const { onChange, rawOnChange, registrationData, validationResult, isParticipantMinor } = props;

    const [isSelectDialogOpen, setIsSelectDialogOpen] = useState(false);
    const [selectedPrefix, setSelectedPrefix] = useState('');

    const openSelectDialog = useCallback((formPrefix: string) => {
        setIsSelectDialogOpen(true);
        setSelectedPrefix(formPrefix);
    }, []);

    const handlePersonClick = useCallback((data: any) => {
        rawOnChange(selectedPrefix, snakecaseKeys(data));
        setIsSelectDialogOpen(false);

        setTimeout(() => {
            if (selectedPrefix === 'participant') {
                if (data.parent) {
                    rawOnChange('parent', {
                        ...snakecaseKeys(data.parent),
                        email: data.parent.emailsPhones[0] ? data.parent.emailsPhones[0].email : '',
                        phone: data.parent.emailsPhones[0] ? data.parent.emailsPhones[0].phone : '',
                    });
                }
            }
        }, 1500);

        setTimeout(() => {
            if (selectedPrefix === 'participant') {
                if (data.secondParent) {
                    rawOnChange('second_parent', {
                        ...snakecaseKeys(data.secondParent),
                        email: data.secondParent.emailsPhones[0] ? data.secondParent.emailsPhones[0].email : '',
                        phone: data.secondParent.emailsPhones[0] ? data.secondParent.emailsPhones[0].phone : '',
                    });
                }
            }
        }, 3000);
    }, [rawOnChange, selectedPrefix]);

    return (
        <>
            {sections.filter(s => !s.hideIfNotMinor || isParticipantMinor).map(section => {
                const realSections = ((!isParticipantMinor) ? [...section.sections, 'phone', 'email'] : section.sections) as FormSection[];

                return (
                    <Paper style={{padding: '16px', marginTop: '16px'}}>
                        <div style={{display: 'flex'}}>
                            <div style={{flex: '1'}}>
                                <Typography variant='h4' style={{fontSize: '1.6em', textAlign: 'left', margin: '2px 2px 14px'}}>
                                    {section.name}
                                </Typography>
                            </div>
                            <div style={{flex: '0 1 0%', whiteSpace: 'nowrap'}}>
                                <Button onClick={() => openSelectDialog(section.formPrefix)} color={'primary'} variant='outlined'>
                                    Cerca in anagrafica
                                </Button>
                            </div>
                        </div>


                        <GenericPersonForm
                            rawOnChange={rawOnChange}
                            data={registrationData}
                            dataOnChange={onChange}
                            formPrefix={section.formPrefix}
                            sections={realSections}
                            validationResult={validationResult}
                        />
                    </Paper>
                );
            })}

            <PersonSelectDialog open={isSelectDialogOpen} closeDialog={() => setIsSelectDialogOpen(false)} onPersonSelect={handlePersonClick} />
        </>
    );
};

export default PersonalData;
