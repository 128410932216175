import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import initialState from './initialState';

export const globalDialogsSlice = createSlice({
    name: 'globalDialogsSlice',
    initialState,
    reducers: {
        open: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                [action.payload]: true
            };
        },
        close: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                [action.payload]: false
            };
        }
    }
});

export const { open, close } = globalDialogsSlice.actions;

export default globalDialogsSlice.reducer;
