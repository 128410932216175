import { ButtonProps, Checkbox, Table, TableBody, TableCell, TableContainer, TableRow } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import CustomDialogWrapper from "../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper";
import api from "../../../../../../common/utils/api";
import snackbar from "../../../../../../common/utils/snackbar";

interface Props {
    open: boolean;
    closeDialog: (shouldRefresh?: boolean) => void;
    locationId?: string;
    townId?: string;
    townDistinguishing?: string;
}

const SUPER_USERS = [
    {
        name: 'Alessandro',
        email: 'ariboli@epuntos.it'
    },
    {
        name: 'Dario',
        email: 'dscaburri@epuntos.it'
    },
    {
        name: 'Giorgio',
        email: 'gbuzzone@epuntos.it'
    }
];

const SelectCoordinatorDialog = (props: Props) => {
    const { open, closeDialog, locationId, townId, townDistinguishing } = props;
    const [isLoading, setIsLoading] = useState(false);

    const [emails, setEmails] = useState<string[]>([]);

    useEffect(() => {
        if (!open) return;

        setIsLoading(true);

        api.request('/admin/winter_staff/schedule/coordinators', 'GET', { locationId, townId, townDistinguishing }).then(res => {
            setEmails(res.map((x: any) => x.superUser.email));
            setIsLoading(false);
        })
    }, [open, locationId, townId, townDistinguishing]);

    const selectEmail = useCallback((email: string, selected: boolean) => {
        setEmails((currEmails: any) => {
            const filteredEmails = currEmails.filter((x: string) => x !== email);

            if (selected) {
                return [...filteredEmails, email];
            } else {
                return filteredEmails;
            }
        });
    }, []);

    const handleSubmit = useCallback(() => {
        setIsLoading(true);

        api.request('/admin/winter_staff/schedule/coordinators', 'POST', { locationId, townId, townDistinguishing, coordinators: emails }).then(res => {
            snackbar.success('Coordinatore salvato con successo!');
            setIsLoading(false);
            closeDialog(true);
        })
    }, [closeDialog, locationId, townId, townDistinguishing, emails]);

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        },
        {
            children: 'Salva',
            color: 'primary',
            onClick: () => handleSubmit()
        },
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Seleziona coordinatore'} buttons={buttons} isLoading={isLoading} maxWidth='sm' fullWidth>
            <TableContainer style={{ marginTop: '0px' }}>
                <Table size='small'>
                    <TableBody>
                        {SUPER_USERS.map((s: any) => {
                            return (
                                <TableRow>
                                    <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>
                                        <Checkbox
                                            color='primary'
                                            style={{ padding: '4px' }}
                                            checked={emails.includes(s.email)}
                                            onChange={(e) => {
                                                selectEmail(s.email, e.target.checked);
                                            }}
                                            disabled={isLoading}
                                        />
                                    </TableCell>
                                    <TableCell>{s.name}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </CustomDialogWrapper>
    )

}

export default SelectCoordinatorDialog;