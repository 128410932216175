import { useCallback, useEffect, useState } from 'react';

import snakecaseKeys from 'snakecase-keys';

import { GenericStepProps } from '../types';
import Form from './components/Form/Form';
import PersonList from './components/PersonList/PersonList';
import { PersonForRegistration } from './types';

interface Props extends GenericStepProps {
    isParticipantMinor: boolean;
    setIsParticipantMinor: (value: boolean) => void;
    shouldSelectSchoolGrade: boolean;
    schoolClasses: any[];
    personList: PersonForRegistration[];
}

const RegistrationStep1 = (props: Props) => {
    const { data, courseData, rawOnChange, personList } = props;

    const [isCreatingNewPerson, setIsCreatingNewPerson] = useState(false);
    const [canChangePerson, setCanChangePerson] = useState(true);

    const personExists = data?.participant?.id?.length > 0;

    const handleSelectPerson = useCallback((person: PersonForRegistration) => {
        rawOnChange('participant', snakecaseKeys(person));

        const requiredFields = ['name', 'surname', 'taxCode', 'dateOfBirth'];

        rawOnChange('userPrefilledFields', requiredFields.filter(field => {
            // @ts-ignore
            return person && person[field];
        }));
    }, [rawOnChange]);

    const discardPersonChoose = useCallback(() => {
        rawOnChange('participant.id', undefined);
        setIsCreatingNewPerson(false);
    }, [rawOnChange]);

    useEffect(() => {
        if (isCreatingNewPerson) {
            rawOnChange('participant', undefined);
        }
    }, [isCreatingNewPerson, rawOnChange]);

    useEffect(() => {
        if (courseData?.minimumAge >= 18) {
            const accountOwner = personList.find(p => p.isAccountOwner);

            if (accountOwner) {
                handleSelectPerson(accountOwner);
                setCanChangePerson(false);
            } else {
                setIsCreatingNewPerson(true);
            }
        }
    }, [courseData, handleSelectPerson, personList]);

    return (
        <>
            {personList.length > 0 && !data?.participant?.id && !isCreatingNewPerson ? (
                <PersonList people={personList} selectPerson={handleSelectPerson} createNewPerson={() => setIsCreatingNewPerson(true)} />
            ) : (
                <Form {...props} personExists={personExists} discardPersonChoose={discardPersonChoose} canChangePerson={canChangePerson} />
            )}
        </>
    );
};

export default RegistrationStep1;
