import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import GenericObject from '../../../../../../../typesAdditional/GenericObject';

interface Params {
    selectedSessions: GenericObject[];  // TODO: this is deprecated, to remove!
    setSelectedSessions: Dispatch<SetStateAction<GenericObject[]>>;
    sessions?: GenericObject[];
}

const useHandleSelect = (params: Params) => {
    const { setSelectedSessions, sessions } = params;

    const sessionsImpl = useMemo(() => {
        return sessions ?? [];
    }, [sessions]);

    const handleSelect = useCallback((sessionId: string, value: boolean) => {
        setSelectedSessions((selectedSessions: any) => {
            const session = sessionsImpl.find(s => s.id === sessionId);
            const overlappingSession = sessionsImpl.filter(s => s.startTime === session?.startTime && s.endTime === session?.endTime && s.id !== session?.id).map(s => s.id);

            const clonedSessions = [...selectedSessions].filter((s: GenericObject) => !overlappingSession.includes(s.id) || !value);

            const currSession = clonedSessions.find(s => s.id === sessionId);

            if (value === false) {
                return clonedSessions.filter(s => s.id !== sessionId);
            } else if (!currSession) {
                clonedSessions.push({
                    id: sessionId,
                    foodService: false
                });
            }

            return clonedSessions;

        })
    }, [setSelectedSessions, sessionsImpl]);

    const handleFoodSelect = useCallback((sessionId: string, value: boolean) => {
        setSelectedSessions((selectedSessions: any) => {
            const session = sessionsImpl.find(s => s.id === sessionId);
            const overlappingSession = sessionsImpl.filter(s => s.startTime === session?.startTime && s.endTime === session?.endTime && s.id !== session?.id).map(s => s.id);

            const clonedSessions = selectedSessions.filter((s: any) => s.id !== sessionId).filter((s: GenericObject) => !overlappingSession.includes(s.id) || !value);

            clonedSessions.push({
                id: sessionId,
                foodService: value
            });

            return clonedSessions;
        });
    }, [setSelectedSessions, sessionsImpl]);

    return {
        handleSelect,
        handleFoodSelect
    };
};

export default useHandleSelect;
