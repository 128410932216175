import { ButtonProps } from '@material-ui/core/Button';
import 'moment/locale/it';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';

interface Props {
    open: boolean;
    closeDialog: () => void;
    url: string;
}

const PreviewDialog = (props: Props) => {
    const { open, closeDialog, url } = props;

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        },
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Anteprima documento'} buttons={buttons} maxWidth='lg' fullWidth contentProps={{ style: { height: '2000px', padding: 0, overflow: 'hidden' } }}>
            <embed src={url} width='100%' height='100%' />
        </CustomDialogWrapper>
    );
};

export default PreviewDialog;
