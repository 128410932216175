import { useEffect, useState } from 'react';

import Button, { ButtonProps } from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import 'moment/locale/it';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import api from '../../../../../../common/utils/api';
import EmailContentDialog from '../EmailContentDialog/EmailContentDialog';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    userId: string;
}

const SentEmailsDialog = (props: Props) => {
    const { isOpen, onClose, userId } = props;

    const [isLoading, setIsLoading] = useState(false);

    const [data, setData] = useState<any[]>([]);

    const [emailContentDialogOpen, setEmailContentDialogOpen] = useState(false);
    const [selectedMail, setSelectedMail] = useState<any>({});

    useEffect(() => {
        if (!userId) {
            return;
        }

        setData([]);
        setIsLoading(true);
        api.request('/admin/emails/users/' + userId + '/emails').then((res: any) => {
            setData(res);
            setIsLoading(false);
        });
    }, [userId]);

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => onClose()
        },
    ];

    return (
        <>
            <CustomDialogWrapper open={isOpen} onClose={() => onClose()} title={'Email inviate'} buttons={buttons} isLoading={isLoading} maxWidth='xl' fullWidth >
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Oggetto</TableCell>
                                <TableCell>Data invio</TableCell>
                                <TableCell>Data visualizzazione</TableCell>
                                <TableCell>Data apertura link</TableCell>
                                <TableCell style={{width: '1px', whiteSpace: 'nowrap'}}>Azioni</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((v: any, idx: number) => {
                                return (
                                    <TableRow style={{backgroundColor: (v.seenAt || v.openedAt) ? '#edf7ed' : (!v.sentAt ? '#fdecea' : '#fff4e5')}} >
                                        <TableCell>{idx + 1}</TableCell>
                                        <TableCell>{v.subject}</TableCell>
                                        <TableCell>{v.sentAt ? moment(v.sentAt).format('DD/MM/YYYY HH:mm:ss') : ''}</TableCell>
                                        <TableCell>{(v.seenAt || v.openedAt) ? moment(v.seenAt ? v.seenAt : v.openedAt).format('DD/MM/YYYY HH:mm:ss') : ''}</TableCell>
                                        <TableCell>{v.openedAt ? moment(v.openedAt).format('DD/MM/YYYY HH:mm:ss') : ''}</TableCell>
                                        <TableCell style={{width: '1px', whiteSpace: 'nowrap'}}>
                                            <Button size='small' variant='outlined' color='primary' style={{backgroundColor: '#ffffff'}} onClick={() => {
                                                setSelectedMail(v);
                                                setEmailContentDialogOpen(true);
                                            }}>
                                                Visualizza messaggio
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CustomDialogWrapper>

            <EmailContentDialog
                isOpen={emailContentDialogOpen}
                onClose={() => {
                    setEmailContentDialogOpen(false);
                }}
                subject={selectedMail?.subject}
                content={selectedMail?.safeBody}
            />
        </>
    );
};

export default SentEmailsDialog;
