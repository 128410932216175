import Box from '@material-ui/core/Box';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

function CircularProgressWithLabel(props: CircularProgressProps & { labelValue: string | JSX.Element }) {
    const { labelValue, ...otherProps } = props;
  return (
    <Box position='relative' display='inline-flex'>
      <CircularProgress variant='determinate' style={{width: '60px', height: '60px', margin: '8px'}} {...otherProps} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position='absolute'
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        <Typography variant='caption' component='div' color='textPrimary'>{labelValue}</Typography>
      </Box>
    </Box>
  );
}

export default CircularProgressWithLabel;
