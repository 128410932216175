import { useCallback } from 'react';

import Link from '@material-ui/core/Link';
import * as Sentry from '@sentry/react';

import GenericObject from '../../../typesAdditional/GenericObject';
import { useMessageDialog } from '../useMessageDialog/useMessageDialog';

const GENERIC_EMAIL = 'info@epuntos.it';
const SUPPORT_EMAIL = 'info@epuntos.it';

const useGenericErrorDialog = () => {
    const [showMessageDialog] = useMessageDialog();

    return useCallback((additionalData: GenericObject = {}, needTechSupport: boolean = false) => {
        const email = needTechSupport ? SUPPORT_EMAIL : GENERIC_EMAIL;

        const sentryCallback = (eventId: string) => {
            showMessageDialog({
                title: 'Si è verificato un errore inatteso',
                message: (
                    <>
                        Ti invitiamo a riprovare più tardi, oppure scrivi a{' '}
                        <Link href={'mailto:' + email} target='_blank' rel='noreferrer'>{email}</Link>{' '}
                        {eventId && <>comunicando il codice d'errore <b>{eventId}</b>{' '}</>}
                        per ricevere assistenza.
                    </>
                )
            });
        };

        Sentry.withScope(scope => {
            scope.setExtras(additionalData);

            const eventId = Sentry.captureMessage('Errore generico - ' + (new Date().getTime().toString(32)));
            sentryCallback(eventId);
        });
    }, [showMessageDialog]);
};

export default useGenericErrorDialog;
