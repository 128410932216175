import { useRef, useEffect, useMemo, useState, useCallback } from 'react';

import Button, { ButtonProps } from '@material-ui/core/Button';
import moment from 'moment';
import 'moment/locale/it';
import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import GenericObject from '../../../../../../typesAdditional/GenericObject';
import api from '../../../../../../common/utils/api';
import { v4 as uuidv4 } from 'uuid';
import { CardActions, CardContent, Checkbox, Divider, FormControlLabel, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Card from '@material-ui/core/Card';
import AvailabilityTable from './components/AvailabilityTable/AvailabilityTable';
import { useDebounce } from 'use-debounce';
import useLocalStorageListener from '../../../../../../common/hooks/useLocalStorageListener/useLocalStorageListener';
import { styledAvailabilityFilterMenu } from './styled';
import { cx } from '@emotion/css';
import { Entry } from '../AddInstructorDialog/AddInstructorDialog';

interface Props {
    open: boolean;
    closeDialog: () => void;
    selectedWeek?: GenericObject;
    weekId?: string;
    locationId?: string;
    selectTrainer: (trainerId: string) => void;
    addInstructorEntriesToSelect: Entry[] | null;
}

interface ConfigData {
    firstDay: number;
    lastDay: number;
}


const FindAvailabilityDialog = (props: Props) => {
    const { open, closeDialog, selectedWeek, weekId, locationId, selectTrainer, addInstructorEntriesToSelect } = props;

    const [isLoading, setIsLoading] = useState(false);

    const [data, setData] = useState<GenericObject[]>([]);
    const [otherData, setOtherData] = useState<ConfigData | undefined>(undefined);
    const apiRequestId = useRef('');

    const [searchQuery, setSearchQuery] = useState('');

    const [selectedEntries, setSelectedEntries] = useState<GenericObject[]>([]);

    useEffect(() => {
        if (!open) return;

        setSelectedEntries([]);
    }, [open]);

    const defaultSelectedEntries = useMemo(() => {
        return ['morning', 'afternoon'].reduce((prev: any[], dayTime: string) => {
            const newEntries = [...Array(6).keys()].map((day) => {
                const realDay = day + 1;

                if (realDay < (otherData?.firstDay ?? 0) || realDay > (otherData?.lastDay ?? 0)) return null;

                return { day: realDay, dayTime };
            }).filter((x: any) => x !== null);

            return [...prev, ...newEntries];
        }, []);
    }, [otherData]);

    const selectedEntriesLabel = useMemo(() => {
        const firstDay = (otherData?.firstDay) ?? 0;
        const lastDay = (otherData?.lastDay) ?? 0;

        let realDayDate: string[] = [];

        for (let i = firstDay; i <= lastDay; i++) {
            realDayDate[i] = moment(selectedWeek?.startDate).add(i - 1, 'days').format('dddd');
        }

        let hashMap: any = {};

        for (let d = firstDay; d <= lastDay; d++) {
            hashMap[d] = {
                morning: false,
                afternoon: false
            };
        }

        for (let entry of selectedEntries) {
            hashMap[entry.day][entry.dayTime] = true;
        }

        let additiveDesc = [];
        let subtractiveDesc = [];

        for (let d = firstDay; d <= lastDay; d++) {
            let fullDayYes = true;
            let fullDayNo = true;

            for (let dayTime of ['morning', 'afternoon']) {
                fullDayYes = fullDayYes && hashMap[d][dayTime];
                fullDayNo = fullDayNo && !hashMap[d][dayTime];
            }

            if (fullDayYes) {
                additiveDesc.push({ type: 'fullDay', day: d });
            } else if (hashMap[d]['morning']) {
                additiveDesc.push({ type: 'morning', day: d });
            } else if (hashMap[d]['afternoon']) {
                additiveDesc.push({ type: 'afternoon', day: d });
            }

            if (fullDayNo) {
                subtractiveDesc.push({ type: 'fullDay', day: d });
            } else if (!hashMap[d]['morning']) {
                subtractiveDesc.push({ type: 'morning', day: d });
            } else if (!hashMap[d]['afternoon']) {
                subtractiveDesc.push({ type: 'afternoon', day: d });
            }
        }

        let descToUse, initialDesc, prefixDesc;
        if (additiveDesc.length < subtractiveDesc.length) {
            descToUse = additiveDesc;
            initialDesc = 'solo ';
            prefixDesc = '';
        } else {
            descToUse = subtractiveDesc;
            initialDesc = '';
            prefixDesc = 'no ';
        }

        let desc = initialDesc;

        interface DaysObject {
            fullDay: number[];
            morning: number[];
            afternoon: number[];
        }

        let days: DaysObject = {
            fullDay: [],
            morning: [],
            afternoon: []
        };
        let labels = {
            fullDay: 'giornata',
            morning: 'mattina',
            afternoon: 'pomeriggio'
        };

        for (let entry of descToUse) {
            if (days[entry.type as ('fullDay' | 'morning' | 'afternoon')] === undefined) {
                days[entry.type as ('fullDay' | 'morning' | 'afternoon')] = [];
            }

            days[entry.type as ('fullDay' | 'morning' | 'afternoon')].push(entry.day);
        }

        days = Object.fromEntries(
            Object.entries(days).sort(([, a], [, b]) => (a[0] ?? 0) - (b[0] ?? 0))
        ) as DaysObject;

        let nonZeroDays = 0;
        let doneDays = 0;
        for (let realDays of Object.values(days)) {
            if (realDays.length > 0) nonZeroDays++;
        }

        for (let [label, realDays] of Object.entries(days)) {
            if (realDays.length === 0) continue;
            doneDays++;

            desc += `${prefixDesc}${realDays.map((d: number) => realDayDate[d]).join('/')} ${labels[label as 'fullDay' | 'morning' | 'afternoon']}${doneDays === (nonZeroDays - 1) ? ' e' : ''}${doneDays === (nonZeroDays - 2) ? ',' : ''} `;
        }

        desc = desc.trim();

        if (desc === 'solo') {
            desc = 'nessun filtro';
        } else if (desc === '') {
            desc = 'settimana completa';
        }

        return desc;
    }, [selectedEntries, otherData, selectedWeek]);

    useEffect(() => {
        if (addInstructorEntriesToSelect === null) {
            setSelectedEntries([]);
        } else {
            setSelectedEntries(defaultSelectedEntries.filter((d1: any) => addInstructorEntriesToSelect.find((d: GenericObject) => d.day === d1.day && d.dayTime === d1.dayTime)));
        }
    }, [addInstructorEntriesToSelect, defaultSelectedEntries]);

    useLocalStorageListener({
        name: 'summerAvailabilityLastUpdated',
        callback: () => {
            refreshData(true);
        }
    })

    const refreshData = useCallback((silent = false) => {
        if (!weekId || !locationId || !open) return;

        const currentRequestId = uuidv4();
        apiRequestId.current = currentRequestId;
        if (!silent) setIsLoading(true);

        api.request('/admin/summer_schedule/weeks/' + weekId + '/locations/' + locationId + '/availabilities').then(res => {
            if (currentRequestId !== apiRequestId.current) return;

            const { data: trainersList, ...otherData } = res;

            setData(trainersList);
            setOtherData(otherData);
            if (!silent) setIsLoading(false);
        })
    }, [weekId, locationId, open]);

    const isEntrySelected = useCallback((day: number, dayTime: string) => {
        return !!selectedEntries.find(x => x.day === day && x.dayTime === dayTime);
    }, [selectedEntries]);

    const selectEntry = useCallback((day: number, dayTime: string, selected: boolean) => {
        if (selected) {
            setSelectedEntries(curr => {
                const entry = { ...(selectedEntries.find(x => x.day === day && x.dayTime === dayTime) ?? { day, dayTime }) };

                return [
                    ...curr.filter(x => !(x.day === day && x.dayTime === dayTime)),
                    entry
                ]
            });
        } else {
            setSelectedEntries(curr => curr.filter(x => !(x.day === day && x.dayTime === dayTime)));
        }
    }, [selectedEntries]);

    useEffect(() => {
        refreshData();
    }, [refreshData]);

    useEffect(() => {
        if (!open) return;

        setSearchQuery('');
    }, [open]);

    const [debouncedQuery] = useDebounce(searchQuery, 1000);

    const sortedData = useMemo(() => {
        return data.sort((a: GenericObject, b: GenericObject) => {
            if (a.availabilitiesCount.yes === b.availabilitiesCount.yes) {
                if (a.availabilitiesCount.uncertain === b.availabilitiesCount.uncertain) {
                    if (a.priorityScore < b.priorityScore) {
                        return 1;
                    } else {
                        return -1;
                    }
                } else if (a.availabilitiesCount.uncertain < b.availabilitiesCount.uncertain) {
                    return 1;
                } else {
                    return -1;
                }
            } else if (a.availabilitiesCount.yes < b.availabilitiesCount.yes) {
                return 1;
            } else {
                return -1;
            }
        }).filter((trainer: GenericObject) => {
            return (trainer.name + ' ' + trainer.surname).toLowerCase().startsWith(debouncedQuery.toLowerCase()) || (trainer.surname + ' ' + trainer.name).toLowerCase().startsWith(debouncedQuery.toLowerCase()) || trainer.email.toLowerCase().startsWith(debouncedQuery.toLowerCase())
        });
    }, [data, debouncedQuery]);

    const filteredData = useMemo(() => {
        return sortedData.filter((trainer: GenericObject) => {
            return selectedEntries.every((entry: GenericObject) => {
                return trainer.availabilities.find((aval: GenericObject) => aval.day === entry.day && aval.dayTime === entry.dayTime && aval.availability === 'yes' && aval.otherLocations.length === 0);
            });
        })
    }, [sortedData, selectedEntries]);

    const remainingData = useMemo(() => {
        return sortedData.filter((trainer: GenericObject) => !filteredData.find((t2: GenericObject) => trainer.id === t2.id));
    }, [sortedData, filteredData]);

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        },
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Aggiungi istruttore'} buttons={buttons} isLoading={isLoading} maxWidth='md' fullWidth>
            <div style={{ display: 'flex', marginBottom: '14px' }}>

                <TextField
                    placeholder={'Ricerca istruttore'}
                    size='small'
                    variant='outlined'
                    value={searchQuery}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value)}
                    InputProps={{
                        startAdornment: <InputAdornment position='start'><SearchIcon /></InputAdornment>,
                    }}
                    style={{ flex: 2, marginRight: '6px' }}
                />


                <TextField
                    label={'Filtro disponibilità'}
                    size='small'
                    variant='outlined'
                    style={{ flex: 1, marginLeft: '6px' }}
                    select
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{
                        renderValue: () => <>{selectedEntriesLabel}</>,
                        displayEmpty: true,
                        MenuProps: {
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left'
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                            className: cx(styledAvailabilityFilterMenu),
                            onClick: (e: React.MouseEvent) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }
                        }
                    }}
                >
                    <div>
                        {!isLoading && (
                            <>

                                <TableContainer style={{ marginBottom: '0' }} onClick={(e) => e.stopPropagation()} >
                                    <Table size='small'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>

                                                {[...Array(6).keys()].map((day) => {
                                                    const realDay = day + 1;

                                                    if (realDay < (otherData?.firstDay ?? 0) || realDay > (otherData?.lastDay ?? 0)) return null;

                                                    return (
                                                        <TableCell style={{ textAlign: 'center' }}>
                                                            {moment(selectedWeek?.startDate).add(day, 'days').format('dddd').substring(0, 3)}
                                                        </TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {['morning', 'afternoon'].map((dayTime) => {
                                                const label = dayTime === 'morning' ? 'mattino' : 'pomeriggio';

                                                return (
                                                    <TableRow>
                                                        <TableCell><b>{label}</b></TableCell>

                                                        {[...Array(6).keys()].map((day) => {
                                                            const realDay = day + 1;

                                                            if (realDay < (otherData?.firstDay ?? 0) || realDay > (otherData?.lastDay ?? 0)) return null;

                                                            return (

                                                                <TableCell style={{ lineHeight: '0', textAlign: 'center', backgroundColor: '#ffffff' }}>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                color='primary'
                                                                                style={{ padding: '0px' }}
                                                                                checked={isEntrySelected(realDay, dayTime)}
                                                                                onChange={(e) => {
                                                                                    selectEntry(realDay, dayTime, e.target.checked);
                                                                                }}
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                }}
                                                                            />
                                                                        }
                                                                        style={{ display: 'inline-block', marginTop: '-4px', marginLeft: '6px', marginRight: '6px' }}
                                                                        label={''}
                                                                    />
                                                                </TableCell>
                                                            )
                                                        })}
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <div style={{ textAlign: 'center', marginTop: '6px' }} onClick={(e) => e.stopPropagation()}>
                                    <Button variant='outlined' color='primary' size='small' style={{ margin: '0 4px 0 0' }} onClick={() => setSelectedEntries(defaultSelectedEntries)}>Seleziona tutto</Button>
                                    <Button variant='outlined' color='primary' size='small' style={{ margin: '0 0 0 4px' }} onClick={() => setSelectedEntries([])}>Deseleziona tutto</Button>
                                </div>
                            </>
                        )}
                    </div>
                </TextField>


            </div>

            {!isLoading && filteredData.length === 0 && (
                <Typography variant='h2' style={{ textAlign: 'center', fontSize: '2.25em', color: '#444444', marginTop: '24px' }}>
                    Nessun istruttore trovato con i filtri selezionati
                </Typography>
            )}

            {['filtered', 'unfiltered'].map(type => {
                if (type === 'unfiltered' && (defaultSelectedEntries.length !== selectedEntries.length || debouncedQuery || true)) return null;

                return (
                    <>
                        {type === 'unfiltered' && (
                            <>
                                <Divider style={{ marginTop: '16px' }} />
                                <Typography variant='h2' style={{ textAlign: 'left', fontSize: '1.85em', color: '#444444', marginTop: '16px', marginBottom: '12px' }}>
                                    Tutti gli istruttori
                                </Typography>

                            </>
                        )}
                        {(isLoading ? [] : (type === 'filtered' ? filteredData : remainingData)).map((instructor: GenericObject) => {
                            return (
                                <Card key={instructor.id} variant="outlined" style={{ marginBottom: '16px' }}>
                                    <CardContent style={{ paddingBottom: 0 }}>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography style={{ fontSize: '1.25em', flex: 1 }}>
                                                    {(instructor.name && instructor.surname) ? (instructor.name + ' ' + instructor.surname) : instructor.email}
                                                </Typography>
                                                <Typography style={{ fontSize: '1em', textAlign: 'left', paddingTop: '2px' }} color="textSecondary">
                                                    {(instructor.name && instructor.surname) ? (instructor.email) : ''}
                                                </Typography>
                                            </div>
                                            {instructor.residence && (
                                                <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                                                    <p style={{ margin: 0, flex: 1 }} />
                                                    <Typography style={{ fontSize: '1.25em', flex: 1, textAlign: 'right' }}>
                                                        {instructor.residence}
                                                    </Typography>
                                                    {/*
                                        <Typography style={{ fontSize: '0.95em', textAlign: 'right', marginTop: '-2px' }} color="textSecondary">
                                            residenza
                                        </Typography>
                                        */}
                                                    <p style={{ margin: 0, flex: 1 }} />
                                                </div>
                                            )}

                                        </div>
                                        <AvailabilityTable selectedWeek={selectedWeek} instructor={instructor} />
                                        {instructor.notes && (
                                            <p style={{ margin: '16px 12px' }}><b>Note:</b> {instructor.notes}</p>
                                        )}
                                        <Divider />
                                    </CardContent>
                                    <CardActions>
                                        <Button size='small' variant='outlined' style={{ margin: '0 auto' }} onClick={() => selectTrainer(instructor.id)}>
                                            Aggiungi istruttore
                                        </Button>
                                    </CardActions>
                                </Card>
                            )
                        })}
                    </>
                )
            })}

        </CustomDialogWrapper >
    );
};

export default FindAvailabilityDialog;
