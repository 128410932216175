import { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ButtonProps } from '@material-ui/core/Button';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import CustomTextField from '../../../../../../common/components/CustomTextField/CustomTextField';
import api from '../../../../../../common/utils/api';
import snackbar from '../../../../../../common/utils/snackbar';
import { ReduxState } from '../../../../../../redux/types';
import { Typography } from '@material-ui/core';

interface Props {
    open: boolean;
    closeDialog: () => void;
    editorRef: MutableRefObject<any>;
    assetId: string | null;
}

const SimulateConfirmDialog = (props: Props) => {
    const { open, closeDialog, editorRef, assetId } = props;

    const userInfo = useSelector((state: ReduxState) => state.user);

    const [isLoading, setIsLoading] = useState(false);
    const [recipient, setRecipient] = useState('');

    useEffect(() => {
        if (open && userInfo.phone) {
            setRecipient(userInfo.phone);
        }
    }, [open, userInfo]);

    const simulateEmail = useCallback(() => {
        setIsLoading(true);
        api.request('/admin/whatsapp/simulate', 'POST', { message: editorRef.current.current.getContent(), assetId, phoneNumber: recipient }).then(() => {
            snackbar.success('Messaggio inviato correttamente!');
            closeDialog();
        }).finally(() => {
            setIsLoading(false);
        });
    }, [closeDialog, editorRef, assetId, recipient]);

    const buttons: ButtonProps[] = [
        {
            children: 'Invia',
            color: 'primary',
            onClick: () => simulateEmail()
        },
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        },
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Simula invio messaggio'} buttons={buttons} isLoading={isLoading} maxWidth='sm' fullWidth>
            <CustomTextField
                label={'Numero di telefono'}
                variant='outlined'
                value={recipient}
                keepMounted={true}
                onChange={(e) => setRecipient(e.target.value)}
                fullWidth
            />

            <Typography variant='body2'>
                Potrebbero essere necessari alcuni minuti prima che il messaggio venga recapitato.
            </Typography>
        </CustomDialogWrapper>
    );
};

export default SimulateConfirmDialog;
