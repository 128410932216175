import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

export const Container = styled.div`
    padding: 12px;
`;

export const EmailContainer = styled(Typography)`
    display: inline-block;
    overflow: hidden;
    max-width: 88%;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: -6px !important;
`;

export const FlexContainer = styled.div`
    display: flex;
`;

export const FlexPusher = styled.div`
    flex-grow: 1;
`;

export const NameContainer = styled(Typography)`
    margin-top: 0.4rem !important;
    margin-bottom: -0.3rem !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const ProfileMenuButton = styled(IconButton)`
    margin: 0 !important;
    padding: 0 !important;
    float: right;
`;

export const StyledAvatar = styled(Avatar)`
    width: 72px;
    height: 72px;
`;

export const StyledDivider = styled(Divider)`
    margin: 0;
`;
