import { useCallback, useState } from "react";
import { Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import Header from "./components/Header/Header";
import { PageContainer } from "./styled";
import Footer from "./components/Footer/Footer";
import useLocalStorageListener from "../../../../common/hooks/useLocalStorageListener/useLocalStorageListener";
import useSummerWeeksPreloading from "../../../../common/hooks/useSummerWeeksPreloading/useSummerWeeksPreloading";

const SummerWeeklyScheduleStatusPage = () => {
    const [selectedTab, selectTab] = useState(0);

    const getWeekDetailsUrl = useCallback((id: string) => '/admin/summer_schedule/weeks/' + id + '/assignment_status', []);

    const { loadWeeks, refreshData, weeks, selectedWeek, currentWeekData } = useSummerWeeksPreloading({ selectedTab, getWeekDetailsUrl, year: 2025 });

    const localStorageCallback = useCallback(() => {
        refreshData(true);
        loadWeeks(true);
    }, [refreshData, loadWeeks]);

    useLocalStorageListener({
        name: 'summerAvailabilityLastUpdated',
        callback: localStorageCallback
    });

    return (
        <>
            <PageContainer>
                <Header selectedWeek={selectedWeek} />

                <div style={{ flexGrow: 1, overflow: 'auto' }}>
                    <TableContainer component={Paper} style={{ marginTop: '16px' }}>
                        <Table size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Camp</TableCell>
                                    <TableCell>Paese</TableCell>
                                    <TableCell>Responsabile assegnato</TableCell>
                                    <TableCell>Stima bambini {'<'} 6 anni</TableCell>
                                    <TableCell>Stima bambini {'≥'} 6 anni</TableCell>
                                    <TableCell>Istruttori richiesti</TableCell>
                                    <TableCell>Istruttori assegnati</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {currentWeekData.map((camp: any) => {
                                    const statusMap = {
                                        no: {
                                            color: '#fdecea',
                                            borderColor: '#f44336',
                                            label: 'No',
                                            outlined: false
                                        },
                                        yes: {
                                            color: '#edf7ed',
                                            borderColor: '#4caf50',
                                            label: 'Sì',
                                            outlined: false
                                        }
                                    }

                                    return (
                                        <TableRow style={{ height: '45px', backgroundColor: (camp.isResponsibleAssigned && camp.requiredTrainers <= camp.assignedTrainers) ? '#edf7ed' : '#fdecea' }}>
                                            <TableCell style={{ whiteSpace: 'nowrap' }}>{camp.courseName}</TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap' }}>{camp.townName}</TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap' }}>
                                                <Chip label={statusMap[camp.isResponsibleAssigned ? 'yes' : 'no'].label} variant={statusMap[camp.isResponsibleAssigned ? 'yes' : 'no'].outlined ? 'outlined' : 'default'} style={{ ...(statusMap[camp.isResponsibleAssigned ? 'yes' : 'no'].outlined ? {} : { backgroundColor: statusMap[camp.isResponsibleAssigned ? 'yes' : 'no'].color, border: '1px solid ' + statusMap[camp.isResponsibleAssigned ? 'yes' : 'no'].borderColor }), width: '90px' }} />
                                            </TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap' }}>{camp.avgUnderSix}</TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap' }}>{camp.avgOverSix}</TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap' }}>{Math.max(0, camp.requiredTrainers - 1)}</TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap' }}>{Math.max(0, camp.assignedTrainers - 1)}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>

                <Footer weeks={weeks} selectedTab={selectedTab} selectTab={selectTab} />
            </PageContainer >

        </>
    )
}

export default SummerWeeklyScheduleStatusPage;