import { useCallback, useEffect, useState } from 'react';

import { ButtonProps } from '@material-ui/core/Button';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import CustomTextField from '../../../../../../common/components/CustomTextField/CustomTextField';
import api from '../../../../../../common/utils/api';
import snackbar from '../../../../../../common/utils/snackbar';
import { Link, MenuItem } from '@material-ui/core';
import SelectStartDateDialog from '../SelectStartDateDialog/SelectStartDateDialog';
import WageChangelogDialog from '../WageChangelogDialog/WageChangelogDialog';

interface Props {
    open: boolean;
    closeDialog: (shouldRefresh?: boolean) => void;
    selectedTrainer?: any;
    selectedTrainerId?: string;
    externalSelectedCourseId?: string | null;
    externalSelectedCourseLocationId?: string | null;
}

const EditCostDialog = (props: Props) => {
    const { open, closeDialog, selectedTrainer, selectedTrainerId, externalSelectedCourseId, externalSelectedCourseLocationId } = props;

    const [isLoading, setIsLoading] = useState(false);

    const [defaultWage, setDefaultWage] = useState<string | number>('0.00');

    const [data, setData] = useState<any>({});

    const [isSelectStartDateDialogOpen, setIsSelectStartDateDialogOpen] = useState(false);
    const [isWageChangelogDialogOpen, setIsWageChangelogDialogOpen] = useState(false);

    const [costTimeUnit, setCostTimeUnit] = useState<string>('hourly');
    const [selectedCourseId, setSelectedCourseId] = useState<string | null>(null);
    const [selectedCourseLocationId, setSelectedCourseLocationId] = useState<string | null>(null);
    const [wageChangelogSelectedWage, setWageChangelogSelectedWage] = useState<any | undefined>(undefined);

    useEffect(() => {
        if (!open) return;

        setData({});
        setDefaultWage('0.00');
        setSelectedCourseId(externalSelectedCourseId ?? null);
        setSelectedCourseLocationId(externalSelectedCourseLocationId ?? null);
    }, [open, externalSelectedCourseId, externalSelectedCourseLocationId])

    const refreshData = useCallback(() => {
        setIsLoading(true);
        api.request('/admin/winter_staff/facilities/' + selectedTrainerId + '/cost').then(res => {
            if (res.default.current[0]) {
                setDefaultWage(res.default.current[0].cost);
                setCostTimeUnit(res.default.current[0].costTimeUnit);
            }

            setData(res);
            setIsLoading(false);
        });
    }, [selectedTrainerId]);

    useEffect(() => {
        if (!selectedTrainerId || !open) return;

        refreshData();
    }, [open, selectedTrainerId, externalSelectedCourseId, externalSelectedCourseLocationId, refreshData]);

    const saveData = useCallback((startDate: string | null) => {
        setIsLoading(true);
        api.request('/admin/winter_staff/facilities/' + selectedTrainerId + '/cost', 'POST', { cost: defaultWage, costTimeUnit, startDate, courseId: selectedCourseId, courseLocationId: selectedCourseLocationId }).then(() => {
            snackbar.success('Costo salvato con successo!');
            closeDialog(true);
            setIsSelectStartDateDialogOpen(false);
        }).catch(() => {
            //snackbar.error('Si è verificato un errore durante il salvataggio.');
        }).finally(() => {
            setIsLoading(false);
        });
    }, [defaultWage, closeDialog, selectedTrainerId, selectedCourseId, selectedCourseLocationId, costTimeUnit]);

    const simulateSaveData = useCallback(() => {
        setIsLoading(true);
        api.request('//admin/winter_staff/facilities/' + selectedTrainerId + '/cost', 'POST', { cost: defaultWage, costTimeUnit, courseId: selectedCourseId, courseLocationId: selectedCourseLocationId, simulate: true }).then((res: any) => {
            if (res.costExists) {
                setIsSelectStartDateDialogOpen(true);
            } else {
                saveData(null);
            }
        }).catch(() => {
            snackbar.error('Si è verificato un errore durante il salvataggio.');
        }).finally(() => {
            setIsLoading(false);
        });
    }, [defaultWage, selectedTrainerId, saveData, selectedCourseId, selectedCourseLocationId, costTimeUnit]);

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        },
        {
            children: 'Salva',
            color: 'primary',
            onClick: () => simulateSaveData()
        }
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Costo ' + selectedTrainer?.name + ' - ' + selectedTrainer?.town?.name} buttons={buttons} isLoading={isLoading} maxWidth='sm' fullWidth>
            <div style={{ display: 'flex' }}>
                <CustomTextField
                    label={'Costo'}
                    variant='outlined'
                    value={defaultWage}
                    keepMounted={true}
                    onChange={(e: any) => {
                        setDefaultWage(e.target.value);
                    }}
                    onBlur={() => {
                        setDefaultWage((c: any) => {
                            return Math.max(((typeof c === 'string') ? parseFloat(c.replaceAll(',', '.')) : c), 0).toFixed(2);
                        });
                    }}
                    disabled={isLoading}
                />
            </div>

            <div style={{ display: 'flex' }}>
                <CustomTextField
                    label={'Tipologia costo'}
                    value={costTimeUnit}
                    onChange={(e: any) => {
                        setCostTimeUnit(e.target.value);
                    }}
                    variant='outlined'
                    required
                    fullWidth
                    select
                    disabled={!!externalSelectedCourseId || !!externalSelectedCourseLocationId}
                >
                    <MenuItem value={'hourly'} style={{ whiteSpace: 'break-spaces' }}>
                        orario
                    </MenuItem>
                    <MenuItem value={'weekly'} style={{ whiteSpace: 'break-spaces' }}>
                        settimanale
                    </MenuItem>
                    <MenuItem value={'monthly'} style={{ whiteSpace: 'break-spaces' }}>
                        mensile
                    </MenuItem>
                    <MenuItem value={'yearly'} style={{ whiteSpace: 'break-spaces' }}>
                        annuale (anno solare)
                    </MenuItem>
                    <MenuItem value={'yearly_sport_calendar'} style={{ whiteSpace: 'break-spaces' }}>
                        annuale (anno sportivo)
                    </MenuItem>
                </CustomTextField>
            </div>

            {(data?.default?.previous ?? []).length > 0 && (
                <div style={{ flex: 1, textAlign: 'right', display: 'flex', alignItems: 'center', marginTop: '2px', marginBottom: '12px' }}>
                    <div style={{ flex: 1 }}>
                        <Link href='#' onClick={(e: any) => {
                            e.preventDefault();
                            setIsWageChangelogDialogOpen(true);
                            setWageChangelogSelectedWage(data?.default?.current);
                        }}>
                            Storico costi
                        </Link>
                    </div>
                </div>
            )}

            <SelectStartDateDialog
                open={isSelectStartDateDialogOpen}
                saveData={(date: string) => saveData(date)}
                isLoading={isLoading}
            />

            <WageChangelogDialog
                open={isWageChangelogDialogOpen}
                closeDialog={() => setIsWageChangelogDialogOpen(false)}
                selectedTrainer={selectedTrainer}
                selectedWage={wageChangelogSelectedWage}
                data={data}
            />
        </CustomDialogWrapper>
    );
};

export default EditCostDialog;
