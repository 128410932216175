import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

interface Props {
    setCategory: (category: string) => void;
}

const Step1 = ({ setCategory }: Props) => {
    return (
        <>
            <Typography variant='h2' style={{fontSize: '25px', fontFamily: '\'Libre Franklin\', sans-serif', textAlign: 'center', margin: '8px'}}>
                Voglio preventivo per
            </Typography>

            <div style={{width: 'fit-content', margin: '24px auto'}}>
                <Button variant='outlined' color='primary' onClick={() => setCategory('summer_camp')} style={{display: 'block', margin: '14px 0', width: '100%', padding: '10px 15px'}}>
                    Camp Estivi
                </Button>

                <Button variant='outlined' color='primary' onClick={() => undefined} style={{display: 'block', margin: '14px 0', width: '100%', padding: '10px 15px'}} disabled>
                    Corsi Annuali Bambini
                </Button>

                <Button variant='outlined' color='primary' onClick={() => undefined} style={{display: 'block', margin: '14px 0', width: '100%', padding: '10px 15px'}} disabled>
                    Corsi Annuali Adulti
                </Button>
            </div>
        </>
    );
};

export default Step1;
