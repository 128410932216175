import Backdrop from '@material-ui/core/Backdrop';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components/macro';

export const BackdropWithoutTransition = styled(Backdrop)`
    transition: none !important;
`;

export const StyledPaper = styled(Paper)<{show: number}>`
    ${props => props.show ? '' : 'display: none;'}
`;
