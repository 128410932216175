import { ButtonProps } from '@material-ui/core/Button';
import 'moment/locale/it';

import { VennDiagram, asSets, ISetLike, createVennJSAdapter } from '@upsetjs/react';
import { layout } from '@upsetjs/venn.js';
import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import { useMemo, useState } from 'react';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    filters: any[];
}

const VennDiagramDialog = (props: Props) => {
    const { isOpen, onClose } = props;

    const [selection, setSelection] = useState<ISetLike<unknown> | unknown[] | null>(null);

    const baseSets = useMemo(() => {
        return [];

        /*
        const flattenFilters = [];

        return filters.map((f1: any, idx: number) => {
            return f1.map((f2: any) => {
                return {
                    ...f2,
                    users: f2.details.users.map((u: number) => idx.toString() + '-' + u.toString())
                }
            })
        }).flat().map((f: any, idx: number) => {
            return {
                name: 'C' + (idx + 1),
                fullname: f.details.description,
                elems: f.users
            }
        })
        */
    }, [])

    const sets = useMemo(() => asSets(baseSets).slice(0, baseSets.length), [baseSets]);

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => onClose()
        },
    ];

    return (
        <>
            <CustomDialogWrapper open={isOpen} onClose={() => onClose()} title={'Utenti selezionati, Diagramma di Venn'} buttons={buttons} maxWidth='lg' fullWidth >
                <div style={{ display: 'flex' }}>
                    <div>
                        <VennDiagram
                            layout={createVennJSAdapter(layout)}
                            sets={sets}
                            width={780}
                            height={500}
                            selection={selection}
                            onHover={setSelection}
                            theme={'light'}
                            exportButtons={false}
                            onClick={console.log}
                        />
                    </div>
                    <div>
                        Condizioni:
                    </div>
                </div>
            </CustomDialogWrapper>
        </>
    );
};

export default VennDiagramDialog;
