import { useWindowSize } from 'react-use';

import Button, { ButtonProps } from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    receipts: any[];
}

const ReceiptsListDialog = (props: Props) => {
    const { isOpen, onClose, receipts } = props;

    const isLoading = false;

    const { width } = useWindowSize();
    const isMobile = width < 700;

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => onClose()
        }
    ];

    return (
        <CustomDialogWrapper fullScreen={isMobile} open={isOpen} onClose={onClose} title={'Visualizza ricevute'} buttons={buttons} isLoading={isLoading} maxWidth='lg' fullWidth contentProps={{}} >

        {receipts.length === 0 ? (
            <Typography variant='body1' style={{margin: '0 4px 16px'}}>
                Al momento non c'è nessuna ricevuta disponibile. Ricontrolla tra alcuni giorni.
            </Typography>
        ) : (
            <TableContainer component={Paper}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Numero</TableCell>
                            <TableCell>Data</TableCell>
                            <TableCell>Nome genitore</TableCell>
                            <TableCell>Codice fiscale</TableCell>
                            <TableCell>Metodo di pagamento</TableCell>
                            <TableCell>Importo</TableCell>
                            <TableCell>Azioni</TableCell>
                        </TableRow>
                    </TableHead>
                        <TableBody>
                        {receipts.map((receipt: any) => {
                            return (
                                <TableRow>
                                    <TableCell>{receipt.fullNumber}</TableCell>
                                    <TableCell>{moment(receipt.date).format('DD/MM/YYYY')}</TableCell>
                                    <TableCell>{receipt.receiptRequest.name}</TableCell>
                                    <TableCell>{receipt.receiptRequest.taxCode}</TableCell>
                                    <TableCell>{receipt.receiptRequest.registrationPayments[0]?.masterPayment?.method?.name ?? receipt.receiptRequest.registrationPayments[0]?.masterPayment?.welfareProvider?.name ?? ''}</TableCell>
                                    <TableCell>
                                        € {receipt.receiptRequest.registrationPayments.reduce((prev: any, current: any) => {
                                            return prev + parseFloat(current.masterPayment.amount);
                                        }, 0.0).toFixed(2)}
                                    </TableCell>
                                    <TableCell style={{width: '1px', whiteSpace: 'nowrap'}}>
                                        <Button href={process.env.REACT_APP_API_HOST + '/assets/' + receipt.asset.id} target='_blank' color={'primary'} variant='outlined'>
                                            Visualizza
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        )}

        </CustomDialogWrapper>
    );
};

export default ReceiptsListDialog;
