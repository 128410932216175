import { useCallback, useRef, useState, useEffect } from 'react';

import { ButtonProps } from '@material-ui/core/Button';
import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import Draggable from 'react-draggable';
import Paper from '@material-ui/core/Paper';
import { TransitionProps } from '@material-ui/core/transitions';
import TinyEditor from '../../../newEmailPage/components/TinyEditor/TinyEditor';
import styled from 'styled-components';
import GenericObject from '../../../../../../typesAdditional/GenericObject';
import api from '../../../../../../common/utils/api';
import { v4 as uuidv4 } from 'uuid';

interface Props {
    open: boolean;
    closeDialog: () => void;
    selectedWeek: GenericObject;
    refreshWeekData: (weekId: string, silent: boolean) => void;
    readOnly: boolean;
}

const Container = styled.div`
    height: 100%; 
`;

const StyledTinyEditor = styled(TinyEditor)`
    height: 100%;
    
    & .tox-tinymce {
        height: 100% !important;
        margin-top: -4px; 
    }
`

const PaperComponent = (props: any) => {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const WeekNotesDialog = (props: Props) => {
    const { open, closeDialog, selectedWeek, refreshWeekData, readOnly } = props;

    const [oldValue, setOldValue] = useState('');
    const [currentValue, setCurrentValue] = useState('');
    const [editedWeekId, setEditedWeekId] = useState('');

    const [isInitialized, setIsInitialized] = useState(false);
    const [isDirty, setIsDirty] = useState(false);

    const editorRef = useRef<any>(null);

    const setEditorRef = useCallback((ref: any) => {
        editorRef.current = ref;
    }, [editorRef]);

    useEffect(() => {
        if (!open) return;

        let interval = setInterval(() => undefined, 60 * 60 * 1000);

        setIsInitialized(false);
        setOldValue(selectedWeek.notes);
        setCurrentValue(selectedWeek.notes);
        setIsDirty(false);

        if (editorRef?.current?.current) {
            editorRef.current.current.setContent(selectedWeek.notes);
            setIsInitialized(true);
        } else {
            interval = setInterval(() => {
                if (editorRef?.current?.current) {
                    editorRef.current.current.setContent(selectedWeek.notes);
                    setIsInitialized(true);
                    clearInterval(interval);
                }
            }, 100)
        }

        return () => clearInterval(interval);
    }, [selectedWeek?.notes, open])

    useEffect(() => {
        if (!isInitialized || !open) return;

        const interval = setInterval(() => {
            if (editorRef?.current?.current) {
                setCurrentValue(editorRef.current.current.getContent());
                setEditedWeekId(selectedWeek.id);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [open, isInitialized, editorRef, selectedWeek]);

    useEffect(() => {
        if ((editedWeekId !== selectedWeek?.id || !open) && editedWeekId && isDirty) {
            refreshWeekData(editedWeekId, true);
        }
    }, [editedWeekId, selectedWeek?.id, open, refreshWeekData, isDirty]);

    useEffect(() => {
        if (isInitialized && oldValue !== currentValue) {
            api.request('/admin/summer_schedule/weeks/' + selectedWeek.id + '/notes', 'POST', { notes: currentValue }).then(() => {
                setOldValue(currentValue);
                setIsDirty(true);
                localStorage.setItem('summerAvailabilityLastUpdated', Date.now().toString());
            }).catch(() => {
                setTimeout(() => {
                    setOldValue(uuidv4());
                }, 2000);
            });
        }
    }, [selectedWeek, oldValue, currentValue, isInitialized]);

    const buttons: ButtonProps[] = [];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Note settimana'} buttons={buttons} maxWidth='xl' PaperComponent={PaperComponent} titleProps={{ id: 'draggable-dialog-title' }} hideBackdrop disableBackdropClick disablePortal TransitionProps={{ tabIndex: "" } as TransitionProps} style={{ pointerEvents: 'none' }} PaperProps={{ style: { pointerEvents: 'initial', overflow: 'hidden' } }} contentProps={{ style: { padding: 0, height: 'calc(100% + 4px)' } }} hideActions resizable>
            <Container>
                <StyledTinyEditor setEditorRef={setEditorRef} useMinimalStyle readOnly={readOnly} />
            </Container>
        </CustomDialogWrapper >
    );
};

export default WeekNotesDialog;
