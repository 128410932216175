import { useCallback } from 'react';

import CustomTextField from '../../../../../../../../common/components/CustomTextField/CustomTextField';
import FlexInputContainer from '../../../../../../../../common/components/FlexInputContainer/FlexInputContainer';
import { SectionProps } from '../../GenericPersonForm';
import useValidateEmailAddress from '../../hooks/useValidateEmailAddress';
import useValidatePhoneNumber from '../../hooks/useValidatePhoneNumber';

interface Props extends SectionProps {
    withPhone: boolean;
    withEmail: boolean;
}

const ContactData = (props: Props) => {
    const { dataOnChange, rawOnChange, withPhone, withEmail, ...validatorProps } = props;
    const { formPrefix, data } = validatorProps;

    const { phone: isPhoneValid } = useValidatePhoneNumber(validatorProps);
    const { email: isEmailValid } = useValidateEmailAddress(validatorProps);

    const onEmailChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e?.target?.value ?? '';
        rawOnChange(formPrefix + '.email', value.trim());
    }, [formPrefix, rawOnChange]);

    return (
        <FlexInputContainer>
            {withPhone && (
                <CustomTextField
                    label='Recapito telefonico'
                    path={formPrefix + '.phone'}
                    variant='outlined'
                    data={data}
                    dataOnChange={dataOnChange}
                    error={!isPhoneValid}
                    inputProps={{maxLength: 15}}
                />
            )}

            {withEmail && (
                <CustomTextField
                    label='Email'
                    path={formPrefix + '.email'}
                    variant='outlined'
                    data={data}
                    onChange={onEmailChange}
                    error={!isEmailValid}
                />
            )}
        </FlexInputContainer>
    );
};

export default ContactData;
