import { useRef, useEffect, useState, useCallback } from 'react';

import { ButtonProps } from '@material-ui/core/Button';
import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import GenericObject from '../../../../../../typesAdditional/GenericObject';
import api from '../../../../../../common/utils/api';
import { v4 as uuidv4 } from 'uuid';
import { MenuItem } from '@material-ui/core';
import 'moment/locale/it';
import CustomTextField from '../../../../../../common/components/CustomTextField/CustomTextField';
import snackbar from '../../../../../../common/utils/snackbar';

interface Props {
    open: boolean;
    closeDialog: (shouldRefresh?: boolean) => void;
    superUserId: string;
    weekId: string;
}

const ChangeWageDialog = (props: Props) => {
    const { open, closeDialog, superUserId, weekId } = props;

    const [isLoading, setIsLoading] = useState(false);

    const [automaticWage, setAutomaticWage] = useState(true);
    const [wage, setWage] = useState('0.00');
    const [notes, setNotes] = useState('');

    const [data, setData] = useState<GenericObject>({});
    const apiRequestId = useRef('');

    useEffect(() => {
        if (!superUserId || !weekId || !open) return;

        const currentRequestId = uuidv4();
        apiRequestId.current = currentRequestId;
        setIsLoading(true);

        api.request('/admin/summer_payouts/' + superUserId + '/weeks/' + weekId).then(res => {
            if (currentRequestId !== apiRequestId.current) return;

            setData(res);
            setWage(parseFloat(res.amount).toFixed(2));
            setAutomaticWage(!res.overrideWage);
            setNotes(res.notes);
            setIsLoading(false);
        })
    }, [superUserId, weekId, open]);

    useEffect(() => {
        if (!open) return;

        setData({});
        setWage('0.00');
        setNotes('');
        setAutomaticWage(true);
    }, [open]);

    const saveData = useCallback(() => {
        setIsLoading(true);
        api.request('/admin/summer_payouts/' + superUserId + '/weeks/' + weekId, 'POST', { wage, automaticWage, notes }).then(res => {
            setIsLoading(false);
            snackbar.success('Compenso salvato con successo!');
            closeDialog(true);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [superUserId, weekId, wage, automaticWage, closeDialog, notes]);

    const buttons: ButtonProps[] = [
        {
            children: 'Annulla',
            color: 'primary',
            onClick: () => closeDialog()
        },
        {
            children: 'Salva',
            color: 'primary',
            onClick: () => saveData()
        },
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Compenso' + (data.weekShortName ? (' ' + data.weekShortName) : '') + (data.trainerName ? (' - ' + data.trainerName) : '')} buttons={buttons} isLoading={isLoading} maxWidth='sm' fullWidth>
            <div style={{ display: 'flex', marginTop: '6px' }}>
                <CustomTextField
                    select
                    label={'Calcolo automatico compenso'}
                    value={automaticWage ? 'yes' : 'no'}
                    onChange={(e: any) => {
                        setAutomaticWage(e.target.value === 'yes');
                    }}
                    variant='outlined'
                    size='small'
                    fullWidth
                    style={{ marginRight: '5px ' }}
                    disabled={isLoading}
                >
                    <MenuItem value={'yes'} style={{ whiteSpace: 'break-spaces' }}>
                        sì
                    </MenuItem>
                    <MenuItem value={'no'} style={{ whiteSpace: 'break-spaces' }}>
                        no
                    </MenuItem>
                </CustomTextField>

                <CustomTextField
                    label='Compenso settimanale'
                    variant='outlined'
                    size='small'
                    value={automaticWage ? ((parseFloat(data?.amount ?? 0.0) ?? 0.0).toFixed(2)) : (wage === 'NaN' ? '0.00' : wage)}
                    keepMounted={true}
                    onChange={(e) => {
                        setWage(e.target.value);
                    }}
                    onBlur={() => {
                        setWage((val: any) => {
                            return Math.max(((typeof val === 'string') ? parseFloat(val.replaceAll(',', '.')) : val), 0).toFixed(2);
                        });
                    }}
                    style={{ marginLeft: '5px' }}
                    inputProps={{ readOnly: automaticWage }}
                    disabled={isLoading}
                />
            </div>

            <CustomTextField
                label={'Note'}
                variant='outlined'
                size='small'
                value={notes}
                keepMounted={true}
                onChange={(e) => {
                    setNotes(e.target.value);
                }}
                rows={2}
                multiline
                disabled={isLoading}
            />

        </CustomDialogWrapper >
    );
};

export default ChangeWageDialog;
