import { useCallback } from 'react';

import { ButtonProps } from '@material-ui/core/Button';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import FileUploader from '../FileUploader/FileUploader';
import { Asset } from '../../types';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onFileUploaded: (assetId: string) => void;
}

const NewReconciliationDialog = (props: Props) => {
    const { isOpen, onClose, onFileUploaded } = props;

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'secondary',
            onClick: () => onClose()
        }
    ];

    const handleAssetChange = useCallback((documentId: string, assets: Asset[]) => {
        if (assets.length > 0 && assets[0].status === 'uploaded') {
            onFileUploaded(assets[0].id);
        }
    }, [onFileUploaded]);

    return (
        <>
            <CustomDialogWrapper open={isOpen} onClose={() => onClose()} title={'Importa estratto conto'} buttons={buttons} maxWidth='sm' fullWidth >
                <FileUploader initialAssets={[]} documentId={'dummy'} status={'missing'} savedAssets={[]} handleAssetChange={handleAssetChange} isAdmin={false} />
            </CustomDialogWrapper>
        </>
    );
};

export default NewReconciliationDialog;
