import { useCallback, useRef, useState, useEffect } from 'react';

import { ButtonProps } from '@material-ui/core/Button';
import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import TinyEditor from '../../../newEmailPage/components/TinyEditor/TinyEditor';
import styled from 'styled-components';
import GenericObject from '../../../../../../typesAdditional/GenericObject';
import api from '../../../../../../common/utils/api';
import snackbar from '../../../../../../common/utils/snackbar';

interface Props {
    open: boolean;
    closeDialog: () => void;
    refreshRegistrations: () => void;
    registration?: GenericObject;
}

const Container = styled.div`
    height: 100%; 
`;

const StyledTinyEditor = styled(TinyEditor)`    
    & .tox-tinymce {
        margin-top: -4px;
        border-bottom: none;
    }
`

const RegistrationNotesDialog = (props: Props) => {
    const { open, closeDialog, refreshRegistrations, registration } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);

    const editorRef = useRef<any>(null);

    const setEditorRef = useCallback((ref: any) => {
        editorRef.current = ref;
    }, [editorRef]);

    useEffect(() => {
        if (open) return;

        setEditorRef(null);
    }, [setEditorRef, open]);

    useEffect(() => {
        if (!open || !registration) return;

        let interval = setInterval(() => undefined, 60 * 60 * 1000);

        setIsInitialized(false);

        if (editorRef?.current?.current) {
            editorRef.current.current.setContent(registration.notes ?? '');
            setIsInitialized(true);
        } else {
            interval = setInterval(() => {
                if (editorRef?.current?.current) {
                    editorRef.current.current.setContent(registration.notes ?? '');

                    setIsInitialized(true);
                    clearInterval(interval);
                }
            }, 100)
        }

        return () => clearInterval(interval);
    }, [registration, open])

    const saveNotes = useCallback(() => {
        if (!editorRef?.current?.current || !isInitialized) return;

        setIsLoading(true);

        const notes = editorRef.current.current.getContent();

        api.request('/admin/courses/' + (registration?.course?.id) + '/locations/' + (registration?.courseLocation?.id) + '/registrations/' + registration?.id + '/notes', 'POST', { notes }).then(() => {
            snackbar.success('Note salvate con successo!');
            refreshRegistrations();
            closeDialog();
        }).finally(() => {
            setIsLoading(false);
        })
    }, [editorRef, registration, closeDialog, isInitialized, refreshRegistrations]);

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        },
        {
            children: 'Salva',
            color: 'primary',
            onClick: () => saveNotes()
        },
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Note ' + registration?.person?.surname + ' ' + registration?.person?.name} buttons={buttons} maxWidth='sm' fullWidth contentProps={{ style: { padding: 0 } }} isLoading={!isInitialized || isLoading}>
            <Container>
                <StyledTinyEditor setEditorRef={setEditorRef} useMinimalStyle />
            </Container>
        </CustomDialogWrapper >
    );
};

export default RegistrationNotesDialog;