import { useMemo } from "react";
import useCompareSessionDate from "../useCompareSessionDate/useCompareSessionDate";
import GenericObject from "../../../../../../typesAdditional/GenericObject";

interface Props {
    sessions: GenericObject[];
    sessionType: string;
    questionResponse?: string;
    schoolGrade?: number;
    yearOfBirth?: number;
    isAdmin?: boolean;
}

const useSortAndFilterSessions = (props: Props) => {
    const { sessions, sessionType, questionResponse, schoolGrade, yearOfBirth, isAdmin } = props;

    const compareSessionDate = useCompareSessionDate({ sessionType });

    return useMemo(() => {
        return sessions.sort(compareSessionDate).filter((s: any) =>
            (((!s.question || s.question === questionResponse) && questionResponse !== s.disabledQuestion) || isAdmin) &&
            (schoolGrade === undefined || (
                (!s.minimumSchoolGrade && !s.maximumSchoolGrade) ||
                (schoolGrade >= s.minimumSchoolGrade && schoolGrade <= s.maximumSchoolGrade))
            ) &&
            (!s.minimumYearOfBirth || !yearOfBirth || yearOfBirth >= s.minimumYearOfBirth) &&
            (!s.maximumYearOfBirth || !yearOfBirth || yearOfBirth <= s.maximumYearOfBirth)
        ).reduce((prev: GenericObject[], curr: GenericObject) => {
            const thisKey = curr.startTime + curr.endTime;
            const currentGroup = prev.find(x => x._key === thisKey);

            if (currentGroup) {
                return prev.filter(x => x._key !== thisKey).concat({
                    _key: thisKey,
                    sessions: [...currentGroup.sessions, curr]
                })
            } else {
                return prev.concat({
                    _key: thisKey,
                    sessions: [curr]
                })
            }
        }, []).map((itm) => {
            return {
                ...itm.sessions[0],
                _additionals: itm.sessions.slice(1)
            }
        }).sort(compareSessionDate);
    }, [compareSessionDate, sessions, questionResponse, schoolGrade, yearOfBirth, isAdmin]);
}

export default useSortAndFilterSessions;