import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { open } from '../../../redux/globalDialogs/globalDialogsSlice';

const useOpenDialog = (name: string) => {
    const dispatch = useDispatch();

    const openDialog = useCallback(() => {
        dispatch(open(name));
    }, [name, dispatch]);

    return openDialog;
};

export default useOpenDialog;
