import { useEffect, useState } from 'react';

import snakecaseKeys from 'snakecase-keys';

import api from '../../../../../../common/utils/api';
import GenericPersonForm, { FormSection } from '../../components/GenericPersonForm/GenericPersonForm';
import { Props as FieldsHookProps } from '../../components/GenericPersonForm/hooks/useFields';
import StepFooter from '../../components/StepFooter/StepFooter';
import useValidatePersonStep from '../../hooks/useValidatePersonStep/useValidatePersonStep';
import { GenericStepProps } from '../types';
import InfoAlert from './components/InfoAlert/InfoAlert';

const formSections: FormSection[] = ['basic_data', 'tax_code', 'phone'];

const RegistrationStep2 = (props: GenericStepProps) => {
    const { courseId, validationResult, data, onChange, rawOnChange, prevStep, courseData, gtmStepPush, selectedLocationId, preselectedLocation, nextStep } = props;

    const [isLoading, setIsLoading] = useState(false);
    const prefilledFields = data?.parentPrefilledFields ?? [];

    useEffect(() => {
        const campTown = (courseData?.locations?.find((l: any) => l.id === selectedLocationId) ?? preselectedLocation)?.town?.name;

        const analyticsData = {
            visitorLoggedIn: '1',
            summer_camp: courseData?.name ?? '',
            camp_town: campTown ?? '',
            student_country: 'Italy',
            student_city: data?.participant?.address?.town_name ?? '',
            student_zip: data?.participant?.address?.zip ?? '',
        };

        gtmStepPush(1, analyticsData);
    }, [gtmStepPush, courseData, selectedLocationId, data, preselectedLocation]);

    useEffect(() => {
        if (data?.parent) return;

        const requiredFields = ['name', 'surname', 'taxCode', 'dateOfBirth'];

        setIsLoading(true);
        api.request('/auth/me').then(res => {
            const person = {
                ...res.person,
                phone: res.phone
            };

            const { name, surname, taxCode, dateOfBirth, phone } = person;

            rawOnChange('parent', snakecaseKeys({
                name,
                surname,
                taxCode,
                dateOfBirth,
                phone
            }));

            rawOnChange('parentPrefilledFields', requiredFields.filter(field => {
                return res?.person && res?.person[field];
            }));
        }).finally(() => {
            setIsLoading(false);
        });
    }, [data, rawOnChange]);

    const fieldsHookProps: FieldsHookProps = {
        data,
        validationResult,
        formPrefix: 'parent',
        sections: formSections
    };

    const validatePersonStep = useValidatePersonStep({
        courseId,
        ...fieldsHookProps
    });

    const internalNextStep = () => {
        setIsLoading(true);

        validatePersonStep().then(() => {
            nextStep();
        }).finally(() => {
            setIsLoading(false);
        });
    };

    return (
        <>
            {prefilledFields.length > 0 && (
                <InfoAlert />
            )}

            <GenericPersonForm
                rawOnChange={rawOnChange}
                dataOnChange={onChange}
                disabledFields={prefilledFields}
                {...fieldsHookProps}
            />

            <StepFooter isLoading={isLoading || validationResult?.parent?.isLoading} prevStep={prevStep} nextStep={internalNextStep} />
        </>
    );
};

export default RegistrationStep2;
