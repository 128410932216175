import { Fab, TableCell } from "@material-ui/core"
import moment from "moment";
import 'moment/locale/it';
import { useCallback, useState } from "react";
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { useMessageDialog } from "../../../../../../common/hooks/useMessageDialog/useMessageDialog";
import api from "../../../../../../common/utils/api";
import snackbar from "../../../../../../common/utils/snackbar";
import { useSelector } from "react-redux";
import { ReduxState } from "../../../../../../redux/types";

interface Props {
    day: any;
    courseSession?: any;
    refreshData: () => void;
}

const DateButton = ({ day, courseSession, refreshData }: Props) => {
    const [hasFocus, setHasFocus] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const userInfo = useSelector((state: ReduxState) => state.user);
    const canManageStaff = userInfo?.canManageStaff ?? false;

    const [showMessageDialog, closeMessageDialog] = useMessageDialog();

    const shouldShowTime = (moment(courseSession.startTime).format('HH:mm') !== moment('1971-01-01 ' + day.startTime).format('HH:mm') || moment(courseSession.endTime).format('HH:mm') !== moment('1971-01-01 ' + day.endTime).format('HH:mm')) || courseSession.id === 'a62d6fdb-76a0-11ef-aac5-4865ee14ab2a'

    const deleteDay = useCallback(() => {
        showMessageDialog({
            title: 'Rimuovi data',
            message: (
                <>
                    <p style={{ margin: '0px' }}>
                        Sei sicuro di voler rimuovere il giorno <b>{moment(day.date).format('DD/MM/YYYY')}</b> dal calendario del corso?
                    </p>
                </>
            ),
            actions: [
                {
                    text: 'Annulla',
                    action: () => {
                        closeMessageDialog();
                    }
                },
                {
                    text: 'Conferma',
                    action: () => {
                        setIsLoading(true);

                        api.request('/admin/winter_staff/schedule/course_session/' + (courseSession?.id ?? '') + '/days', 'DELETE', { date: moment(day.date).format('YYYY-MM-DD'), additionalDayId: day.additionalDayId }).then(() => {
                            snackbar.success('Data eliminata!');
                            refreshData();
                        }).finally(() => {
                            setIsLoading(false);
                        });

                        closeMessageDialog();
                    }
                }
            ]
        });

    }, [courseSession, refreshData, showMessageDialog, closeMessageDialog, day]);

    return (
        <TableCell onMouseEnter={() => setHasFocus(true)} onMouseLeave={() => setHasFocus(false)} style={{ fontWeight: 'bold', textAlign: 'center', backgroundColor: day.active ? (day.additionalDayId ? '#e8f4fd' : undefined) : '#ff0000', paddingTop: shouldShowTime ? '2px' : undefined, paddingBottom: shouldShowTime ? '2px' : undefined }}>
            <div style={{ position: 'relative' }}>
                {hasFocus && day.active && (canManageStaff || (courseSession?.location?.id === '6cc5a03f-76a0-11ef-aac5-4865ee14ab2a' && userInfo.email.toLowerCase() === 'lorenzo.assolari@gmail.com')) && (
                    <Fab onClick={deleteDay} disabled={isLoading} size="small" color="secondary" aria-label="add" style={{ position: 'absolute', left: '-8px', top: shouldShowTime ? '4px' : '-10px' }} >
                        <RemoveCircleIcon />
                    </Fab>
                )}
            </div>

            {moment(day.date).format('DD')}

            {shouldShowTime && (
                <p style={{ margin: '0', fontWeight: 'normal', marginTop: '4px' }}>
                    {moment('1971-01-01 ' + day.startTime).format('HH:mm')} - {moment('1971-01-01 ' + day.endTime).format('HH:mm')}
                </p>
            )}
        </TableCell>
    )
}

export default DateButton; 