import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

const InfoAlert = () => {
    return (
        <Alert severity='info' style={{paddingBottom: '0px', marginBottom: '24px'}}>
            <AlertTitle>Dati facoltativi</AlertTitle>

            <p style={{marginTop: '0px'}}>
                La compilazione è obbligatoria in caso di genitori separati o divorziati.
            </p>
        </Alert>
    );
};

export default InfoAlert;
