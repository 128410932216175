import { useCallback, useEffect, useState } from 'react';

import { Divider, LinearProgress } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import moment from 'moment';
import 'moment/locale/it';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import api from '../../../../../../common/utils/api';
import snackbar from '../../../../../../common/utils/snackbar';
import CustomTextField from '../../../../../../common/components/CustomTextField/CustomTextField';
import GenericObject from '../../../../../../typesAdditional/GenericObject';

type AVAILABILITY_TYPE = 'yes' | 'no' | 'uncertain';

const availabilityMap = {
    yes: 'sì',
    no: 'no',
    uncertain: 'forse'
};

interface Props {
    open: boolean;
    closeDialog: (reloadData?: boolean) => void;
    data: GenericObject[];
}

const UpdateRequestDialog = (props: Props) => {
    const { open, closeDialog, data } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [simulationData, setSimulationData] = useState<GenericObject | undefined>();

    const [reason, setReason] = useState('');

    const areChangesEmpty = useCallback((data: any) => {
        if (data === undefined) return false;

        const changes = data?.changes ?? [];

        for (let i = 0; i < changes.length; i++) {
            console.log(changes[i].changes);
            if (changes[i].changes.length > 0) return false;
        }

        return true;
    }, [])

    useEffect(() => {
        if (!open) return;

        setIsLoading(true);

        api.request('/admin/summer_availability/simulate_update', 'POST', { data }).then((res) => {
            setIsLoading(false);
            setSimulationData(res);

            if (areChangesEmpty(res)) {
                snackbar.error('Non è presente nessuna modifica da salvare!');
                closeDialog();
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }, [areChangesEmpty, closeDialog, open, data]);

    useEffect(() => {
        setReason('');
        setSimulationData(undefined);
    }, [open]);

    const handleSubmit = useCallback(() => {
        setIsLoading(true);

        api.request('/admin/summer_availability/update', 'POST', { data, reason }).then((res) => {
            setIsLoading(false);
            snackbar.success('Modifiche salvate con successo!' + (res.needApproval ? ' Riceverai un\'email quando verranno approvate.' : ''));
            closeDialog(true);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [closeDialog, data, reason]);

    const buttons: ButtonProps[] = [
        {
            children: 'Annulla',
            color: 'secondary',
            onClick: () => closeDialog()
        },
        {
            children: 'Invia',
            color: 'primary',
            onClick: () => handleSubmit()
        },
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Modifica disponibilità'} buttons={buttons} isLoading={isLoading} maxWidth='sm' fullWidth>
            {(simulationData === undefined || areChangesEmpty(simulationData)) ? (
                <LinearProgress style={{ margin: '8px 0' }} />
            ) : (
                <>
                    {(simulationData?.changes ?? []).filter((week: any) => week.changes.length > 0).map((week: any, idx: number) => {
                        const startDate = moment(week.week);
                        const endDate = moment(week.week).add(4, 'days');

                        return (
                            <>
                                <p style={{ fontWeight: 'bold', marginTop: idx === 0 ? '0px' : undefined }}>{startDate.format('LL')} - {endDate.format('LL')}</p>

                                <ul>
                                    {week.changes.map((change: any) => {
                                        if (change.type === 'week') {
                                            return (
                                                <li>intera settimana: {availabilityMap[change.previousAvailability as AVAILABILITY_TYPE]} → {availabilityMap[change.newAvailability as AVAILABILITY_TYPE]}</li>
                                            )
                                        } else if (change.type === 'day') {
                                            return (
                                                <li>{moment(week.week).add(change.day - 1, 'days').format('dddd D')} giornata: {availabilityMap[change.previousAvailability as AVAILABILITY_TYPE]} → {availabilityMap[change.newAvailability as AVAILABILITY_TYPE]}</li>
                                            )
                                        } else {
                                            return (
                                                <li>{moment(week.week).add(change.day - 1, 'days').format('dddd D')} {change.dayTime === 'morning' ? 'mattina' : 'pomeriggio'}: {availabilityMap[change.previousAvailability as AVAILABILITY_TYPE]} → {availabilityMap[change.newAvailability as AVAILABILITY_TYPE]}</li>
                                            )
                                        }
                                    })}
                                </ul>
                            </>
                        )
                    })}

                    <Divider style={{ margin: '12px 0' }} />

                    <p style={{ margin: 0, marginTop: '8px' }}>
                        Le modifiche successive alla prima compilazione devono essere approvate da un amministratore.
                    </p>

                    <p style={{ marginTop: '6px', marginBottom: 0 }}>
                        Inserisci la motivazione della modifica, e premi il pulsante <b>Invia</b>. Riceverai un'email di conferma quando le modifiche verranno approvate.
                    </p>

                    <CustomTextField
                        label='Motivazione modifica'
                        variant='outlined'
                        value={reason}
                        keepMounted={true}
                        onChange={(e: any) => {
                            setReason(e.target.value);
                        }}
                        disabled={isLoading}
                        rows={2}
                        multiline
                        style={{ marginTop: '12px' }}
                    />
                </>
            )}
        </CustomDialogWrapper >
    );
};

export default UpdateRequestDialog; 
