import { useCallback, useEffect, useState } from "react";
import CustomDialogWrapper from "../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper";
import api from "../../../../../../common/utils/api";
import snackbar from "../../../../../../common/utils/snackbar";
import { ButtonProps } from "@material-ui/core";
import { StyledTextField } from "../../../../../../common/components/CustomTextField/styled";

interface Props {
    open: boolean;
    closeDialog: () => void;
}

const NewTicketDialog = (props: Props) => {
    const { open, closeDialog } = props;

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!open) return;

        setTitle('');
        setDescription('');
    }, [open]);

    const handleSubmit = useCallback(() => {
        setIsLoading(true);

        api.request('/admin/tickets', 'POST', { title, description }).then((res) => {
            snackbar.success('Ticket creato con successo! Numero #' + res.ticketNumber);
            closeDialog();
        }).finally(() => {
            setIsLoading(false);
        })
    }, [title, description, closeDialog]);

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'secondary',
            onClick: () => closeDialog()
        },
        {
            children: 'Salva',
            color: 'primary',
            onClick: () => handleSubmit()
        },
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Nuovo ticket tecnico'} buttons={buttons} isLoading={isLoading} maxWidth='sm' fullWidth>
            <StyledTextField
                label={'Titolo'}
                variant='outlined'
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                fullWidth
            />

            <StyledTextField
                label={'Descrizione'}
                variant='outlined'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
                multiline
                rows={6}
            />
        </CustomDialogWrapper>
    )
};

export default NewTicketDialog;