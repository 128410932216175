import { Container, Image } from './styled';

const Logo = () => {
    return (
        <Container>
            <Image src={process.env.PUBLIC_URL + '/logo512.png'} alt='logo' />
        </Container>
    );
};

export default Logo;
