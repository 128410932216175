import { useCallback, useEffect, useRef, useState } from 'react';
import { useWindowSize } from 'react-use';

import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';
import Skeleton from '@material-ui/lab/Skeleton';
import { useDebounce } from 'use-debounce';
import { v4 as uuidv4 } from 'uuid';

import GreenButton from '../../../../common/components/GreenButton/GreenButton';
import useInternalLoader from '../../../../common/hooks/useInternalLoader/useExternalLoader';
import { useMessageDialog } from '../../../../common/hooks/useMessageDialog/useMessageDialog';
import api from '../../../../common/utils/api';
import snackbar from '../../../../common/utils/snackbar';
import { FlexContainer } from '../../components/DrawerContent/components/UserInfoSection/styled';
import AddUserDialog from './components/AddUserDialog/AddUserDialog';
import EditUserTagsDialog from './components/EditUserTagsDialog/EditUserTagsDialog';
import SentEmailsDialog from './components/SentEmailsDialog/SentEmailsDialog';
import { StyledTablePagination, Title } from './styled';

const EmailUserListPage = () => {
    const { width } = useWindowSize();
    const isMobile = width <= 700;

    const setIsLoading = useInternalLoader();
    const [users, setUsers] = useState<any[]>([]);

    const [paginationPage, setPaginationPage] = useState(0);
    const [paginationTotal, setPaginationTotal] = useState(0);
    const [paginationItemsPerPage, setPaginationItemsPerPage] = useState(25);
    const [query, setQuery] = useState('');
    const [loadedQuery, setLoadedQuery] = useState('');
    const [loadedPage, setLoadedPage] = useState(0);
    const [loadedItemsPerPage, setLoadedItemsPerPage] = useState(25);
    const [internalLoading, setInternalLoading] = useState(false);
    const [debouncedQuery] = useDebounce(query, 500);

    const [updateInProgress, setUpdateInProgress] = useState<string[]>([]);

    const [showMessageDialog, closeMessageDialog] = useMessageDialog();
    const [showAddUserDialog, setShowAddUserDialog] = useState(false);
    const [showEditUserTagsDialog, setShowEditUserTagsDialog] = useState(false);
    const [editUserId, setEditUserId] = useState<string | null>(null);

    const apiRequestId = useRef('');

    const [anchor, setAnchorEl] = useState<null | HTMLElement>(null);
    const [menuUserId, setMenuUserId] = useState<null | string>(null);

    const [showSentEmailsDialog, setShowSentEmailsDialog] = useState(false);
    const [sentEmailsUserId, setSentEmailsUserId] = useState('');

    const handleMenuClick = useCallback((event: React.MouseEvent<HTMLElement>, userId: string) => {
        setAnchorEl(event.currentTarget);
        setMenuUserId(userId);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    useEffect(() => {
        setInternalLoading(query !== loadedQuery || paginationPage !== loadedPage || paginationItemsPerPage !== loadedItemsPerPage);
    }, [query, loadedQuery, paginationPage, loadedPage, paginationItemsPerPage, loadedItemsPerPage]);

    useEffect(() => {
        setPaginationPage(0);
    }, [debouncedQuery]);

    const refreshData = useCallback(() => {
        const currentRequestId = uuidv4();
        apiRequestId.current = currentRequestId;

        api.request('/admin/emails/users', 'GET', { q: debouncedQuery, page: paginationPage + 1, perPage: paginationItemsPerPage }).then((res: any) => {
            if (currentRequestId !== apiRequestId.current) return;

            const { data, total, lastPage } = res;
            setUsers(data);
            setPaginationTotal(total);
            setLoadedQuery(debouncedQuery);
            setLoadedPage(paginationPage);
            setLoadedItemsPerPage(paginationItemsPerPage);

            if (paginationPage + 1 > lastPage) {
                setPaginationPage(lastPage - 1);
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }, [debouncedQuery, setIsLoading, paginationPage, paginationItemsPerPage]);

    const updateConsent = useCallback((userId: string, consent: boolean) => {
        setUpdateInProgress(a => [...a, userId]);

        api.request('/admin/emails/users/' + userId + '/newsletter_consent', 'POST', { consent }).then((res: any) => {
            if (res.consent !== undefined) {
                snackbar.success('Newsletter ' + (res.consent ? 'abilitata' : 'disabilitata') + ' con successo!');

                setUsers(state => {
                    const changedUser = state.find(user => user.id === userId);
                    const changedUserIndex = state.findIndex(user => user.id === userId);

                    if (!changedUser) {
                        return state;
                    }

                    const clonedChangedUser = {...changedUser};
                    clonedChangedUser.newsletterEmail = res.consent;

                    const clonedState = [...state];

                    clonedState[changedUserIndex] = clonedChangedUser;

                    return clonedState;
                });
            }

            setUpdateInProgress(a => a.filter(u => u !== userId));
        });
    }, []);

    const disableUser = useCallback((userId: string, email: string) => {
        showMessageDialog({
            title: 'Elimina utente',
            message: (
                <>
                    <p style={{margin: '0px'}}>
                        Sei sicuro di volere eliminare l'indirizzo email <b>{email}</b> dalla lista dei contatti?
                    </p>
                </>
            ),
            actions: [
                {
                    text: 'Annulla',
                    action: () => {
                        closeMessageDialog();
                    }
                },
                {
                    text: 'Conferma',
                    action: () => {
                        setUpdateInProgress(a => [...a, userId]);
                        closeMessageDialog();

                        api.request('/admin/emails/users/' + userId, 'DELETE').then(() => {
                            snackbar.success('Utente eliminato con successo!');

                            setUsers(state => {
                                return [...state.filter(u => u.id !== userId)];
                            });

                            setUpdateInProgress(a => a.filter(u => u !== userId));
                        });
                    }
                }
            ]
        });
    }, [closeMessageDialog, showMessageDialog]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            refreshData();
        }, 10);

        return () => clearTimeout(timeout);
    }, [refreshData]);

    return (
        <>
            <FlexContainer>
                <Title isMobile={isMobile}>
                    Lista contatti
                </Title>

                <GreenButton onClick={() => setShowAddUserDialog(true)}>
                    Aggiungi contatto
                </GreenButton>
            </FlexContainer>

            <div style={{display: 'flex'}}>
                <TextField
                    label='Ricerca indirizzo email'
                    variant='outlined'
                    placeholder={'Inserisci l\'indirizzo email'}
                    value={query}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQuery(e.target.value)}
                    InputProps={{
                        startAdornment: <InputAdornment position='start'><SearchIcon /></InputAdornment>,
                    }}
                    style={{width: '100%', maxWidth: '600px'}}
                />

                {internalLoading && (
                    <div style={{display: 'flex', alignItems: 'center', marginLeft: '16px'}}>
                        <CircularProgress />
                    </div>
                )}
            </div>

            <TableContainer component={Paper} style={{marginTop: '16px'}}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Indirizzo email</TableCell>
                            <TableCell>Newsletter abilitata</TableCell>
                            <TableCell>Email inviate</TableCell>
                            <TableCell>Invii falliti</TableCell>
                            <TableCell>Email lette</TableCell>
                            <TableCell>Email con link visualizzati</TableCell>
                            <TableCell style={{width: '1px', whiteSpace: 'nowrap'}}>Azioni</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map(user => {
                            return (
                                <TableRow>
                                    <TableCell>{internalLoading ? <Skeleton variant='text' /> : user.email}</TableCell>
                                    <TableCell>{internalLoading ? <Skeleton variant='text' /> : (user.newsletterEmail ? 'sì' : 'no')}</TableCell>
                                    <TableCell>{internalLoading ? <Skeleton variant='text' /> : user.sentEmails}</TableCell>
                                    <TableCell>{internalLoading ? <Skeleton variant='text' /> : user.errorEmails}</TableCell>
                                    <TableCell>{internalLoading ? <Skeleton variant='text' /> : user.readEmails}</TableCell>
                                    <TableCell>{internalLoading ? <Skeleton variant='text' /> : user.openedEmails}</TableCell>
                                    <TableCell style={{whiteSpace: 'nowrap', height: '44px', textAlign: 'right'}}>
                                        {internalLoading ? <Skeleton variant='text' /> : (
                                            <>
                                                <IconButton onClick={(e: any) => handleMenuClick(e, user.id)} >
                                                    <MoreVertIcon />
                                                </IconButton>
                                                <Menu
                                                    anchorEl={anchor}
                                                    keepMounted
                                                    open={!!anchor && menuUserId === user.id}
                                                    onClose={handleClose}
                                                >
                                                    <MenuItem disabled={updateInProgress.includes(user.id)} onClick={() => {
                                                        updateConsent(user.id, !user.newsletterEmail);
                                                        handleClose();
                                                    }}>
                                                        {user.newsletterEmail ? 'Disabilita newsletter' : 'Abilita newsletter'}
                                                    </MenuItem>
                                                    <MenuItem disabled={updateInProgress.includes(user.id)} onClick={() => {
                                                        setShowEditUserTagsDialog(true);
                                                        setEditUserId(user.id);
                                                        handleClose();
                                                    }}>
                                                        Modifica tag
                                                    </MenuItem>
                                                    <MenuItem disabled={updateInProgress.includes(user.id)} onClick={() => {
                                                        setShowSentEmailsDialog(true);
                                                        setSentEmailsUserId(user.id);
                                                        handleClose();
                                                    }}>
                                                        Storico email inviate
                                                    </MenuItem>
                                                    <MenuItem disabled={updateInProgress.includes(user.id)} onClick={() => {
                                                        disableUser(user.id, user.email);
                                                        handleClose();
                                                    }}>
                                                        Elimina
                                                    </MenuItem>
                                                </Menu>

                                                {/*
                                                <Button size='small' variant='outlined' color='primary' disabled={updateInProgress.includes(user.id)} onClick={() => {
                                                    updateConsent(user.id, !user.newsletterEmail);
                                                }}>
                                                    {user.newsletterEmail ? 'disabilita newsletter' : 'abilita newsletter'}
                                                </Button>{' '}

                                                <Button size='small' variant='outlined' color='secondary' disabled={updateInProgress.includes(user.id)} onClick={() => {
                                                    disableUser(user.id, user.email);
                                                }}>
                                                    Elimina
                                                </Button>
                                                */}
                                            </>
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <StyledTablePagination
                count={paginationTotal}
                page={paginationPage}
                onPageChange={(e: any, page: number) => setPaginationPage(page)}
                rowsPerPage={paginationItemsPerPage}
                onChangeRowsPerPage={(e: any) => setPaginationItemsPerPage(parseInt(e.target.value, 10))}
                // @ts-ignore
                component='div'
                backIconButtonText='Pagina precedente'
                nextIconButtonText='Pagina successiva'
                labelRowsPerPage='Righe'
                labelDisplayedRows={({from, to, count}) => {
                    return `${from} - ${to} di ${count}`;
                }}
            />

            <AddUserDialog
                open={showAddUserDialog}
                closeDialog={(reloadData?: boolean) => {
                    if (reloadData) {
                        refreshData();
                    }

                    setShowAddUserDialog(false);
                }}
            />

            <EditUserTagsDialog
                open={showEditUserTagsDialog}
                closeDialog={(reloadData?: boolean) => {
                    if (reloadData) {
                        refreshData();
                    }

                    setShowEditUserTagsDialog(false);
                }}
                userId={editUserId}
            />

            <SentEmailsDialog
                isOpen={showSentEmailsDialog}
                onClose={() => {
                    setShowSentEmailsDialog(false);
                }}
                userId={sentEmailsUserId}
            />
        </>
    );
};

export default EmailUserListPage;
