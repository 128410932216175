import { ButtonProps } from "@material-ui/core/Button";
import CustomDialogWrapper from "../../../../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper";
import api from "../../../../../../../../../common/utils/api";
import { useState, useEffect } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";

interface Props {
    open: boolean;
    closeDialog: () => void;
    selectedDiets: any[];
    selectDiet: (details: any | null, description: string | null, selected: boolean) => void;
}

const SelectorModal = (props: Props) => {
    const { open, closeDialog, selectedDiets, selectDiet } = props;

    const [specialDiets, setSpecialDiets] = useState([]);

    useEffect(() => {
        api.request('/special_diets').then(res => {
            setSpecialDiets(res);
        })
    }, [setSpecialDiets]);


    const buttons: ButtonProps[] = [
        {
            children: 'Salva',
            color: 'primary',
            onClick: () => closeDialog()
        },
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Intolleranze o diete speciali'} buttons={buttons} isLoading={false} maxWidth='sm' fullWidth>
            {specialDiets.map((diet: any) => {
                return (
                    <div>
                        <FormControlLabel
                            label={(
                                <>
                                    <span style={{ fontSize: '0.89em' }}>
                                        {diet.description}
                                    </span>
                                </>
                            )}
                            control={
                                <Checkbox
                                    checked={!!selectedDiets.find((s: any) => (s.details?.id ?? null) === diet.id)}
                                    onChange={(e) => selectDiet(diet, null, e.target.checked)}
                                    style={{ paddingTop: '6px', paddingBottom: '6px' }}
                                    color='primary'
                                />
                            }
                        />
                    </div>
                )
            })}

            <p style={{ margin: '18px 4px 12px' }}>
                Se non hai trovato la tua dieta speciale o intolleranza nella lista sopra scrivila qui:
            </p>

            <TextField
                label='Altra dieta'
                variant='outlined'
                placeholder={''}
                value={selectedDiets.find((s: any) => s.details === null)?.description ?? ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => selectDiet(null, e.target.value, true)}
                style={{ width: '100%', maxWidth: '600px' }}
            />
        </CustomDialogWrapper>
    )
}

export default SelectorModal;