import styled from 'styled-components/macro';

export const Container = styled.div`
    flex-shrink: 0;
    margin: 12px;
`;

export const Image = styled.img`
    width: 140px;
    margin: 0 auto;
    display: block;
`;
