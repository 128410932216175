import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { FullSessionSelectorProps } from '../../types';
import SessionRow from './components/SessionRow';
import { Container } from './styled';
import useSortAndFilterSessions from '../../../../hooks/useSortAndFilterSessions/useSortAndFilterSessions';
import useSessionsMergeFilter from '../../hooks/useSessionsMergeFilter';
import GenericObject from '../../../../../../../../typesAdditional/GenericObject';

const TableSessionsSelector = (props: FullSessionSelectorProps) => {
    const { courseId, sessionType, hasFoodService, simulateRegistrationData, questionResponse, schoolGrade, yearOfBirth, tmpFixCorsiAdulti, getSessionPriceWithOffset, selectedSessions, isAdmin, singleSessionEvent, isSummer, userCanChooseSessions, showAllLocationsSessions, showTownName, selectedSessionsSuggestions, ...rowProps } = props;

    const sessions = useSessionsMergeFilter({
        simulateRegistrationData,
        selectedSessionsSuggestions,
        showAllLocationsSessions,
        courseId
    });

    const filteredSessions = useSortAndFilterSessions({
        sessions,
        sessionType,
        questionResponse,
        schoolGrade,
        yearOfBirth,
        isAdmin
    });

    const sessionsLabels = [...new Set(sessions.map((s: any) => s.overrideLabel).filter((s: any) => !!s))].sort((s1: any, s2: any) => {
        return s2.localeCompare(s1);
    }) as string[];

    const thereIsADifferentCourse = sessions.some((s: any) => {
        return courseId !== undefined && s.location.course.id !== courseId;
    });

    const thereIsADifferentTown = sessions.reduce((prev: string[], curr: GenericObject) => {
        const clonedPrev = [...prev];

        if (!clonedPrev.includes(curr.location.townName)) {
            clonedPrev.push(curr.location.townName);
        }

        return clonedPrev;
    }, []).length > 1;

    const hideCourseColumn = !userCanChooseSessions && filteredSessions.every(s => s.hidePrice);

    return (
        <Container>
            <TableContainer component={Paper}>
                <Table aria-label='simple table'>
                    {!singleSessionEvent && (
                        <TableHead>
                            <TableRow>
                                <TableCell>{sessionType === 'daily_week_events' ? 'Settimana' : 'Giorno'}</TableCell>
                                {sessionsLabels.length === 0 ? (
                                    <>
                                        {!hideCourseColumn && (
                                            <TableCell align='center'>{isSummer ? 'Camp' : 'Corso'}</TableCell>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {sessionsLabels.map((label: string) => {
                                            return (
                                                <TableCell align='center'>{label}</TableCell>
                                            )
                                        })}
                                    </>
                                )}

                                {hasFoodService && <TableCell align='center'>Pasti</TableCell>}
                            </TableRow>
                        </TableHead>
                    )}
                    <TableBody>
                        {filteredSessions.map((session: any) => {
                            const isDifferentCourse = thereIsADifferentCourse;

                            return (
                                <SessionRow key={session.id} sessionType={sessionType} hasFoodService={hasFoodService} session={session} isDifferentCourse={isDifferentCourse} showTownName={thereIsADifferentTown || showTownName} applyPriceOffset={(!!session.isSuggestion) && tmpFixCorsiAdulti} getSessionPriceWithOffset={(!!session.isSuggestion) ? getSessionPriceWithOffset : undefined} singleSessionEvent={singleSessionEvent} userCanChooseSessions={userCanChooseSessions} hideCourseColumn={hideCourseColumn} sessionsLabels={sessionsLabels} {...rowProps} />
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default TableSessionsSelector;
