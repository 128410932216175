import Link from '@material-ui/core/Link';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

interface Props {
    missingDocuments: any[];
    count: number;
    courseData: any;
}

const MissingDocumentsAlert = (props: Props) => {
    const { missingDocuments, count, courseData } = props;

    // TODO: this id is hard coded, not too god
    const missingMedicalCertificate = missingDocuments.filter(d => d.documentType.id === '7b775071-989b-46ef-90da-045458260766').length > 0;
    const missingOnlyMedicalCertificate = missingMedicalCertificate && count === 1;


    return (
        <Alert severity='info' style={{ paddingBottom: '0px' }}>
            <AlertTitle>{count === 1 ? 'Documento richiesto' : 'Documenti richiesti'}</AlertTitle>

            {!missingOnlyMedicalCertificate && (
                <>
                    <p style={{ marginTop: '0px', marginBottom: '10px' }}>
                        La partecipazione al corso selezionato richiede il caricamento{' '}
                        {count === 1 ? 'del seguente documento' : 'dei seguenti documenti'}.
                    </p>
                    <p style={{ marginTop: '0px', marginBottom: missingMedicalCertificate ? '10px' : '4px' }}>
                        Puoi anche {count === 1 ? 'caricarlo' : 'caricarli'} in un secondo momento accedendo alla tua area riservata su{' '}
                        <Link href={process.env.REACT_APP_URL} target='_blank'>crm.epuntos.it</Link>
                    </p>
                </>
            )}

            {missingMedicalCertificate && (
                <p style={{ marginTop: '0px', marginBottom: '4px' }}>
                    {courseData.category.name === 'Corsi Adulti Annuali' && (
                        <>
                            <p>E' obbligatoria la consegna del Certificato Medico per tutti i nostri Corsi Adulti Annuali.</p>
                            <p>Se ora non hai disponibile il CM clicca su avanti e completa comunque l'iscrizione.</p>
                            <p>Potrai caricare il CM in un secondo momento, comodamente dalla tua area privata.</p>
                            <b>E' possibile partecipare alle lezioni prova anche senza la consegna del CM.</b>
                        </>
                    )}

                    {courseData.category.name === 'Corsi Bimbi e Ragazzi Annuali' && courseData.name !== 'Scuola Calcio' && (
                        courseData.name === 'Atletica leggera' ? (
                            <>
                                <p><b>Il Certificato Medico è obbligatorio e deve avere la dicitura:</b></p>
                                <p><b>- Per gli atleti fino alle 5^ Primaria inclusa -{'>'} " Attività Fisica non Agonistica "</b></p>
                                <p><b>- Per gli atleti dalla 1^ Secondaria -{'>'}  " Attività Fisica Agonistica - Sport Atletica "</b></p>
                                <p>Se ora non hai disponibile il CM clicca su avanti e completa comunque l'iscrizione.</p>
                                <p>Potrai caricare il CM in un secondo momento, comodamente dalla tua area privata.</p>
                                <p><b>E' possibile partecipare alle lezioni prova anche senza la consegna del CM.</b></p>
                            </>
                        ) : (
                            courseData.name === 'Karate' ? (
                                <>
                                    <p><b>Il Certificato Medico è obbligatorio e deve avere la dicitura:</b></p>
                                    <p><b>- Per gli atleti fino alle 5^ Primaria inclusa -{'>'} " Attività Fisica non Agonistica "</b></p>
                                    <p><b>- Per gli atleti dalla 1^ Secondaria -{'>'}  " Attività Fisica Agonistica - Karate "</b></p>
                                    <p>Se ora non hai disponibile il CM clicca su avanti e completa comunque l'iscrizione.</p>
                                    <p>Potrai caricare il CM in un secondo momento, comodamente dalla tua area privata.</p>
                                    <p><b>E' possibile partecipare alle lezioni prova anche senza la consegna del CM.</b></p>
                                </>
                            ) : (
                                <>
                                    <p>E' obbligatoria la consegna del Certificato Medico per tutti i nostri Corsi Bimbi e Ragazzi Annuali solo dal compimento del 6° anno di vita.</p>
                                    <p>I bimbi che frequentano i corsi PPS per l'infanzia e hanno meno di 6 anni sono gli unici esentati dalla consegna del certificato.</p>
                                    <p>Se ora non hai disponibile il CM o vuoi iscriverti ad un corso PPS clicca su avanti e completa comunque l'iscrizione.</p>
                                    <p>Potrai caricare il CM in un secondo momento, comodamente dalla tua area privata.</p>
                                    <b>E' possibile partecipare alle lezioni prova anche senza la consegna del CM.</b>
                                </>
                            )
                        )
                    )}
                </p>
            )}
        </Alert>
    );
};

export default MissingDocumentsAlert;
