import { useCallback, useEffect } from "react";

interface Props {
    name: string;
    callback: () => void;
}

const useLocalStorageListener = (props: Props) => {
    const { name, callback } = props;

    const storageEventHandler = useCallback((event: StorageEvent) => {
        if (event.key === name && event.newValue !== event.oldValue) {
            callback();
        }
    }, [name, callback]);

    useEffect(() => {
        window.addEventListener("storage", storageEventHandler);

        return () => {
            window.removeEventListener("storage", storageEventHandler);
        }
    }, [storageEventHandler]);
}

export default useLocalStorageListener; 