import { ButtonProps } from '@material-ui/core/Button';

import CustomDialogWrapper from '../../../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import { useEffect, useState, useCallback } from 'react';
import CustomTextField from '../../../../../../../../common/components/CustomTextField/CustomTextField';
import snackbar from '../../../../../../../../common/utils/snackbar';
import { useMessageDialog } from '../../../../../../../../common/hooks/useMessageDialog/useMessageDialog';
import api from '../../../../../../../../common/utils/api';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { ReduxState } from '../../../../../../../../redux/types';
import { useSelector } from 'react-redux';

interface Props {
    open: boolean;
    closeDialog: (shouldRefresh?: boolean) => void;
    registrationId?: string;
    savedVoucherCode?: string;
}

const DeletedPaymentsDialog = (props: Props) => {
    const { open, closeDialog, registrationId, savedVoucherCode } = props;

    const userInfo = useSelector((state: ReduxState) => state.user);
    const isAdmin = !!userInfo.administrator;

    const [isLoading, setIsLoading] = useState(false);
    const [voucherCode, setVoucherCode] = useState('');
    const [forceApply, setForceApply] = useState(false);

    const [showMessageDialog] = useMessageDialog();

    useEffect(() => {
        if (open) {
            setVoucherCode(savedVoucherCode ?? '');
        }
    }, [open, savedVoucherCode]);

    const saveData = useCallback(() => {
        setIsLoading(true);

        api.request('/admin/registrations/' + registrationId + '/voucher', 'PUT', { voucherCode, forceApply }).then((res) => {
            if (res.status === 'ok') {
                snackbar.success('Voucher modificato con successo!');
                closeDialog(true);
            } else if (res.status === 'validation_error') {
                showMessageDialog({
                    title: 'Errore validazione voucher',
                    message: (
                        <>
                            Il codice inserito non è valido.

                            {res.error && (
                                <p style={{ marginBottom: 0 }}>
                                    {res.error}
                                </p>
                            )}
                        </>
                    )
                });
            }

        }).catch(() => {
            snackbar.error('Si è verificato un errore durante il salvataggio.');
        }).finally(() => {
            setIsLoading(false);
        });
    }, [closeDialog, registrationId, showMessageDialog, voucherCode, forceApply]);

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog(),
            disabled: isLoading
        },
        {
            children: 'Salva',
            color: 'primary',
            onClick: () => saveData(),
            disabled: isLoading
        },
    ];;

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Modifica codice voucher'} buttons={buttons} maxWidth='sm' fullWidth>
            <CustomTextField
                label='Codice voucher'
                variant='outlined'
                value={voucherCode}
                keepMounted={true}
                onChange={(e: any) => {
                    setVoucherCode(e.target.value);
                }}
                disabled={isLoading}
            />

            {isAdmin && (
                <FormControlLabel
                    control={
                        <Checkbox
                            color='primary'
                            style={{ padding: '4px' }}
                            checked={forceApply}
                            onChange={(e) => {
                                setForceApply(e.target.checked)
                            }}
                        />
                    }
                    style={{ marginLeft: '-4px', marginBottom: '8px', display: 'block' }}
                    label={'Ignora vincoli (persona, utente e stagione corso)'}
                    disabled={isLoading}
                />
            )}
        </CustomDialogWrapper>
    );
};

export default DeletedPaymentsDialog;
