import { useMemo, useState } from 'react';
import TextField from "@material-ui/core/TextField";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import { StyledAutocomplete, StyledButton } from "./styled";

const filter = createFilterOptions<string>();

interface Props {
    incrementZoom: () => void;
    decrementZoom: () => void;
    zoomLevel: number;
    setZoomLevel: (zoom: string) => void;
    style?: React.CSSProperties;
}

const ZoomControl = ({ incrementZoom, decrementZoom, zoomLevel, setZoomLevel, style }: Props) => {
    const [isZoomAutocompleteOpen, setIsZoomAutocompleteOpen] = useState(false);

    const zoomOptions = useMemo(() => {
        let options = [];

        for (let i = 0.6; i <= 1.41; i += 0.2) {
            options.push(Math.round((i + Number.EPSILON) * 100) / 100);
        }

        if (!options.includes(Math.round((zoomLevel + Number.EPSILON) * 100) / 100)) {
            options.push(Math.round((zoomLevel + Number.EPSILON) * 100) / 100);
        }

        return options.sort().map(opt => Math.round(opt * 100) + '%');
    }, [zoomLevel]);

    return (
        <div style={{ display: 'flex', height: '48px', ...style }}>
            <StyledButton variant={'outlined'} color='primary' onClick={() => decrementZoom()} >
                <ZoomOutIcon />
            </StyledButton>{' '}

            <StyledAutocomplete
                open={isZoomAutocompleteOpen}
                onOpen={() => setIsZoomAutocompleteOpen(true)}
                onClose={() => setIsZoomAutocompleteOpen(false)}
                onChange={(_event, newValue) => setZoomLevel(newValue as unknown as string)}
                options={zoomOptions}
                filterOptions={(options, params) => {
                    const filtered = filter(options as string[], params);

                    if (params.inputValue !== '' && !filtered.length) {
                        filtered.push(params.inputValue.replaceAll('%', '') + '%');
                    }

                    return filtered;
                }}
                // @ts-ignore
                value={Math.round(zoomLevel * 100) + '%'}
                disableClearable
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            variant='outlined'
                            size='small'
                            onKeyDown={e => {
                                if (e.code === 'Enter' && (e?.target as HTMLInputElement)?.value) {
                                    setZoomLevel((e?.target as HTMLInputElement)?.value ?? '100');
                                    setIsZoomAutocompleteOpen(false);
                                }
                            }}
                        />
                    )
                }}
            />

            <StyledButton variant={'outlined'} color='primary' onClick={() => incrementZoom()} >
                <ZoomInIcon />
            </StyledButton>
        </div>
    )
}

export default ZoomControl;