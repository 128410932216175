import { useEffect, useMemo, useState } from 'react';
import { matchPath } from 'react-router';

import { v4 as uuidv4 } from 'uuid';

import RouteItem from './components/RouteItem';
import useProps from './hooks/useProps';
import useRouteClick from './hooks/useRouteClick';
import { Container } from './styled';
import { BasicProps } from './types';

const RoutesList = (props: BasicProps) => {
    const { routes, additionalProps, depth, parentGuid } = props;

    const realAdditionalProps = useProps(additionalProps);
    const { lastPath, setLastPath, setIsLoading } = realAdditionalProps;

    const [openedSection, setOpenedSection] = useState('');

    const handleClick = useRouteClick({ lastPath, setLastPath, setIsLoading, setOpenedSection, parentGuid });

    const finalRoutes = useMemo(() => {
        return routes.map(route => {
            return {
                ...route,
                guid: uuidv4()
            };
        });
    }, [routes]);

    useEffect(() => {
        finalRoutes.forEach(route => {
            const match = matchPath(window.location.pathname, (window.location.host.startsWith('admin.epuntos.it') ? '' : '/admin') + route.path);

            if (route.subRoutes && match) {
                setOpenedSection(route.guid);
            }
        });
    }, [finalRoutes]);

    const items = useMemo(() => {
        return finalRoutes.map(route => {
            return (
                <RouteItem
                    route={route}
                    openedSection={openedSection}
                    additionalProps={realAdditionalProps}
                    handleClick={handleClick}
                    depth={depth}
                />
            );
        });
    }, [depth, handleClick, finalRoutes, openedSection, realAdditionalProps]);

    return (
        <Container>
            {items}
        </Container>
    );
};

export default RoutesList;
