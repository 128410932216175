export const participantDetails = [
    {
        name: 'Nome',
        key: 'name'
    },
    {
        name: 'Cognome',
        key: 'surname'
    },
    {
        name: 'Codice fiscale',
        key: 'taxCode'
    },
    {
        name: 'Data di nascita',
        key: 'dateOfBirth',
        transform: 'date'
    },
    {
        name: 'Luogo di nascita',
        key: 'placeOfBirth'
    },
    {
        name: 'Indirizzo email',
        key: 'email'
    },
    {
        name: 'Cellulare',
        key: 'phone'
    },
    {
        name: 'Consenso foto',
        key: 'photoConsent',
        transform: 'boolean'
    }
];

export const participantResidence = [
    {
        name: 'Indirizzo',
        key: 'street'
    },
    {
        name: 'Numero civico',
        key: 'streetNumber'
    },
    {
        name: 'Paese',
        key: 'townZip.town.name'
    },
    {
        name: 'CAP',
        key: 'townZip.zip'
    }
];

export const firstParentDetails = [
    {
        name: 'Nome',
        key: 'name'
    },
    {
        name: 'Cognome',
        key: 'surname'
    },
    {
        name: 'Codice fiscale',
        key: 'taxCode'
    },
    {
        name: 'Data di nascita',
        key: 'dateOfBirth',
        transform: 'date'
    }
];

export const parentDetails = [
    {
        name: 'Nome',
        key: 'person.name',
    },
    {
        name: 'Cognome',
        key: 'person.surname'
    },
    {
        name: 'Codice fiscale',
        key: 'person.taxCode'
    },
    {
        name: 'Data di nascita',
        key: 'person.dateOfBirth',
        transform: 'date'
    },
    {
        name: 'Indirizzo email',
        key: 'email'
    },
    {
        name: 'Cellulare',
        key: 'phone'
    }
];

const detailsMap = [
    {
        name: 'Partecipante',
        keyPrefix: 'participant',
        map: participantDetails
    },
    {
        name: 'Residenza',
        keyPrefix: 'person.residenceAddress',
        map: participantResidence
    },
    {
        name: 'Genitore 1',
        keyPrefix: 'parent',
        map: parentDetails
    },
    {
        name: 'Genitore 2',
        keyPrefix: 'secondParentUser',
        map: parentDetails
    }
];

export default detailsMap;
