import Tooltip from "@material-ui/core/Tooltip";
import { useEffect, useState } from "react";

interface Props {
    description: string;
    textColor?: string;
}

const DynamicTooltipFacilityName = ({ description, textColor }: Props) => {
    const [shouldShowTooltip, setShouldShowTooltip] = useState(false);

    const [ref, setRef] = useState<HTMLParagraphElement | null>();

    useEffect(() => {
        if (!ref) return;

        const element = ref;
        setShouldShowTooltip(element.offsetWidth < element.scrollWidth);
    }, [ref]);

    const content = (
        <p
            key={description}
            ref={(newRef: HTMLParagraphElement) => setRef(newRef)}
            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center', margin: 0, fontWeight: 'bold', color: textColor }}>
            {description}
        </p>
    );

    if (shouldShowTooltip) {
        return (
            <Tooltip title={description} arrow>
                {content}
            </Tooltip>
        )
    } else {
        return content;
    }


}

export default DynamicTooltipFacilityName;