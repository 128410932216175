import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { useWindowSize } from "react-use";

const Header = () => {
    const { width } = useWindowSize();
    const isMobile = width <= 700;

    return (
        <div style={{ display: isMobile ? undefined : 'flex', textAlign: isMobile ? 'center' : undefined }}>
            <div style={{ display: 'flex', flexGrow: 1 }}>
                <Typography variant='h2' style={{ fontSize: '3.6em' }}>
                    Richieste variazione disponibilità
                </Typography>
            </div>

            <Divider style={{ margin: '16px 0' }} />
        </div>
    )
}

export default Header;