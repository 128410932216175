import { ButtonProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import GenericObject from "../../../../../../../../typesAdditional/GenericObject"
import CustomDialogWrapper from "../../../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper";
import moment from "moment";

interface Props {
    open: boolean;
    closeDialog: () => void;
    payouts: GenericObject[];
    year: number;
}

const OtherAsdPayoutsDialog = (props: Props) => {
    const { open, closeDialog, payouts, year } = props;

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        },
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Compensi altre ASD (' + year + ')'} buttons={buttons} maxWidth='sm' fullWidth>
            <TableContainer style={{ marginTop: '0px' }}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell>data</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>importo</TableCell>
                            <TableCell>nome ASD</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {payouts.map((payout: any) => {
                            if (parseInt(moment(payout.date).format('YYYY')) !== year) return null;

                            return (
                                <TableRow>
                                    <TableCell>{moment(payout.date).format('DD/MM/YYYY')}</TableCell>
                                    <TableCell style={{ textAlign: 'right' }}>€ {parseFloat(payout.amount).toFixed(2)}</TableCell>
                                    <TableCell>{payout.asdName}</TableCell>
                                </TableRow>
                            )
                        })}
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>TOTALE</TableCell>
                            <TableCell style={{ fontWeight: 'bold', textAlign: 'right' }}>
                                € {payouts.reduce((prev: number, curr: any) => (prev + (parseInt(moment(curr.date).format('YYYY')) === year ? parseFloat(curr.amount) : 0.0)), 0.0).toFixed(2)}
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </CustomDialogWrapper>
    )
}

export default OtherAsdPayoutsDialog;