import { useCallback, useEffect, useMemo, useState } from "react";
import GenericObject from "../../../typesAdditional/GenericObject";
import api from "../../utils/api";
import useInternalLoader from "../useInternalLoader/useExternalLoader";
import { v4 as uuidv4 } from 'uuid';

interface Props {
    selectedTab: number;
    getWeekDetailsUrl: (weekId: string) => string;
    year: number;
}

const useSummerWeeksPreloading = ({ selectedTab, getWeekDetailsUrl, year }: Props) => {
    const setIsLoadingWeeks = useInternalLoader();
    const setIsLoadingWeekData = useInternalLoader();

    const [weeks, setWeeks] = useState<GenericObject[]>([]);
    const [data, setData] = useState<GenericObject[]>([]);

    const getEmptyWeekData = useCallback((id: string, idx: number) => {
        return {
            id: id,
            idx: idx,
            data: undefined,
            shouldReload: true,
            shouldShowLoader: true,
            shouldReloadHightPriority: false,
            startedLoadingAt: 0,
            isActive: false,
            uuidStart: null,
            uuidLoading: null
        };
    }, []);

    const findWeekOrGetEmpty = useCallback((weeksData: GenericObject[], id: string) => {
        const week = weeksData.find((x: any) => x.id === id) ?? getEmptyWeekData(id, weeksData.length);
        return { ...week };
    }, [getEmptyWeekData]);

    const replaceWeekObject = useCallback((weeksData: GenericObject[], id: string, newObject: GenericObject) => {
        return [...weeksData.filter((x: GenericObject) => x.id !== id), newObject];
    }, []);

    const selectedWeek = useMemo(() => {
        return weeks[selectedTab];
    }, [weeks, selectedTab]);

    const selectedWeekId = useMemo(() => {
        return selectedWeek?.id ?? '';
    }, [selectedWeek]);

    const currentWeekData = useMemo(() => {
        return findWeekOrGetEmpty(data, selectedWeekId)?.data ?? [];
    }, [data, selectedWeekId, findWeekOrGetEmpty]);

    const shouldShowLoader = useMemo(() => {
        return data.find((w: GenericObject) => w.id === selectedWeekId)?.shouldShowLoader ?? false;
    }, [data, selectedWeekId]);

    const loadWeeks = useCallback((silent: boolean = false) => {
        if (!silent) setIsLoadingWeeks(true);

        api.request('/admin/summer_schedule/weeks', 'GET', { year }).then(res => {
            setWeeks(res);

            setData(oldData => {
                return res.reduce((prev: any, curr: any) => {
                    const week = findWeekOrGetEmpty(prev, curr.id);
                    week.shouldReload = true;
                    week.shouldShowLoader = !silent;
                    week.shouldReloadHightPriority = week.isActive;
                    week.uuidStart = uuidv4();
                    return replaceWeekObject(prev, curr.id, week);
                }, oldData);
            });

            setIsLoadingWeeks(false);
        })
    }, [year, setIsLoadingWeeks, findWeekOrGetEmpty, replaceWeekObject]);

    const refreshWeekData = useCallback((weekId: string, silent: boolean = false) => {
        setData(oldData => {
            const week = findWeekOrGetEmpty(oldData, weekId);
            week.shouldReload = true;
            week.shouldReloadHightPriority = true;
            week.shouldShowLoader = !silent;
            week.uuidStart = uuidv4();
            return replaceWeekObject(oldData, weekId, week);
        });
    }, [findWeekOrGetEmpty, replaceWeekObject]);

    const refreshData = useCallback((silent: boolean = false) => {
        if (!selectedWeekId) return;

        refreshWeekData(selectedWeekId, silent);
    }, [selectedWeekId, refreshWeekData]);

    useEffect(() => {
        // we're considering requests started more than 10 seconds ago as stalling, so we don't consider them as loading 
        const loadingWeeks = data.filter((w: GenericObject) => w.startedLoadingAt && w.startedLoadingAt > (Date.now() - 10000));

        const lastStartLoadingTime = loadingWeeks.length ?
            Math.max(...loadingWeeks.map((w: GenericObject) => w.startedLoadingAt)) :
            0;

        // we first look if we need to load a high priority week (that means, the active/selected week), otherwise we preload another one;
        // 500ms delays between low priority requests, to avoid network congestions
        const weekToLoad = data.find((w: GenericObject) => w.shouldReloadHightPriority && (w.startedLoadingAt < (Date.now() - 10000) || w.uuidStart !== w.uuidLoading)) ??
            ((lastStartLoadingTime < (Date.now() - 500)) ?
                data.find((w: GenericObject) => w.shouldReload && (w.startedLoadingAt < (Date.now() - 10000) || w.uuidStart !== w.uuidLoading)) :
                null);

        if (!weekToLoad) return;    // nothing to do

        // set UUID and started loading time for week
        setData(oldData => {
            const week = findWeekOrGetEmpty(oldData, weekToLoad.id);
            week.uuidLoading = week.uuidStart;
            week.startedLoadingAt = Date.now();
            return replaceWeekObject(oldData, weekToLoad.id, week);
        });

        api.request(getWeekDetailsUrl(weekToLoad.id)).then(res => {
            setData(oldData => {
                const week = findWeekOrGetEmpty(oldData, weekToLoad.id);

                week.data = res;
                week.shouldReload = false;
                week.shouldShowLoader = false;
                week.shouldReloadHightPriority = false;
                week.startedLoadingAt = 0;

                return replaceWeekObject(oldData, weekToLoad.id, week);
            })
        });
    }, [data, findWeekOrGetEmpty, getWeekDetailsUrl, replaceWeekObject]);

    // load weeks on page mounting 
    useEffect(() => {
        loadWeeks();
    }, [loadWeeks]);

    // reload week data on tab change
    useEffect(() => {
        if (!selectedWeekId) return;

        setData(oldData => {
            const week = findWeekOrGetEmpty(oldData, selectedWeekId);

            week.shouldReload = true;
            week.shouldReloadHightPriority = true;
            week.isActive = true;
            week.uuidStart = uuidv4();

            return replaceWeekObject(oldData, selectedWeekId, week);
        });

        return () => {
            setData(oldData => {
                const week = findWeekOrGetEmpty(oldData, selectedWeekId);
                week.isActive = false;
                return replaceWeekObject(oldData, selectedWeekId, week);
            });
        }
    }, [selectedWeekId, findWeekOrGetEmpty, replaceWeekObject]);

    // show/display loader for active week 
    useEffect(() => {
        setIsLoadingWeekData(shouldShowLoader);
    }, [setIsLoadingWeekData, shouldShowLoader])

    // clear loaders when unmounting the page
    useEffect(() => {
        return () => {
            setIsLoadingWeeks(false);
            setIsLoadingWeekData(false);
        }
    }, [setIsLoadingWeeks, setIsLoadingWeekData]);

    return { loadWeeks, refreshWeekData, refreshData, weeks, selectedWeek, currentWeekData };
}

export default useSummerWeeksPreloading;