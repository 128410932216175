import { useCallback, useEffect, useState } from "react";
import CustomDialogWrapper from "../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper";
import GenericObject from "../../../../../../typesAdditional/GenericObject"
import { ButtonProps } from "@material-ui/core";
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import 'moment/locale/it';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { DateType } from "@date-io/type";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import api from "../../../../../../common/utils/api";
import snackbar from "../../../../../../common/utils/snackbar";

interface Props {
    open: boolean;
    closeDialog: () => void;
    selectedSession?: GenericObject;
    refreshData: () => void;
}

const AddAdditionalDayDialog = ({ open, closeDialog, selectedSession, refreshData }: Props) => {
    const [isLoading, setIsLoading] = useState(false);

    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [isStartTimePickerOpen, setIsStartTimePickerOpen] = useState(false);
    const [isEndTimePickerOpen, setIsEndTimePickerOpen] = useState(false);

    const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
    const [startTime, setStartTime] = useState<DateType>(moment('00:00'));
    const [endTime, setEndTime] = useState<DateType>(moment('00:00'));

    useEffect(() => {
        if (!selectedSession || !open) return;

        setDate(new Date().toISOString().split('T')[0]);
        setStartTime(selectedSession.startTime);
        setEndTime(selectedSession.endTime);
    }, [open, selectedSession]);

    const saveData = useCallback(() => {
        setIsLoading(true);

        api.request('/admin/winter_staff/schedule/course_session/' + (selectedSession?.id ?? '') + '/days', 'POST', { date, startTime: moment(startTime).format('HH:mm'), endTime: moment(endTime).format('HH:mm') }).then(() => {
            snackbar.success('Data aggiunta!');
            refreshData();
            closeDialog();
        }).finally(() => {
            setIsLoading(false);
        });
    }, [selectedSession, startTime, endTime, date, refreshData, closeDialog]);

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        },
        {
            children: 'Salva',
            color: 'primary',
            onClick: () => saveData()
        },
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Aggiungi data'} buttons={buttons} isLoading={isLoading} maxWidth='sm' fullWidth>
            <div style={{ display: 'flex' }}>
                <MuiPickersUtilsProvider locale='it' utils={MomentUtils}>
                    <KeyboardDatePicker
                        label='Giorno'
                        inputVariant='outlined'
                        minDate={'2024-09-01'}
                        maxDate={'2025-06-30'}
                        format='DD/MM/YYYY'
                        value={date}
                        onChange={(date: MaterialUiPickersDate) => {
                            setDate(date?.format('YYYY-MM-DD') ?? '');
                        }}
                        open={isDatePickerOpen}
                        onClick={() => {
                            setIsDatePickerOpen(true);
                        }}
                        onClose={() => setIsDatePickerOpen(false)}
                        onKeyDown={(e) => {
                            e.preventDefault();
                            return false;
                        }}
                        style={{ flex: 1, marginTop: '2px', marginBottom: '4px', marginRight: '6px' }}
                        disabled={isLoading}
                        fullWidth
                    />
                </MuiPickersUtilsProvider>

                <MuiPickersUtilsProvider locale='it' utils={MomentUtils}>
                    <KeyboardTimePicker
                        label='Ora inizio'
                        inputVariant='outlined'
                        value={startTime}
                        onChange={(newDate: MaterialUiPickersDate) => {
                            if (newDate) setStartTime(newDate);
                        }}
                        open={isStartTimePickerOpen}
                        onClick={() => {
                            setIsStartTimePickerOpen(true);
                        }}
                        onClose={() => setIsStartTimePickerOpen(false)}
                        onKeyDown={(e) => {
                            e.preventDefault();
                            return false;
                        }}
                        style={{ flex: 1, marginTop: '2px', marginBottom: '4px', marginLeft: '6px', marginRight: '6px' }}
                        fullWidth
                        ampm={false}
                        disabled={isLoading}
                    />
                </MuiPickersUtilsProvider>

                <MuiPickersUtilsProvider locale='it' utils={MomentUtils}>
                    <KeyboardTimePicker
                        label='Ora fine'
                        inputVariant='outlined'
                        value={endTime}
                        onChange={(newDate: MaterialUiPickersDate) => {
                            if (newDate) setEndTime(newDate);
                        }}
                        open={isEndTimePickerOpen}
                        onClick={() => {
                            setIsEndTimePickerOpen(true);
                        }}
                        onClose={() => setIsEndTimePickerOpen(false)}
                        onKeyDown={(e) => {
                            e.preventDefault();
                            return false;
                        }}
                        style={{ flex: 1, marginTop: '2px', marginBottom: '4px', marginLeft: '6px' }}
                        fullWidth
                        ampm={false}
                        disabled={isLoading}
                    />
                </MuiPickersUtilsProvider>
            </div>
        </CustomDialogWrapper>
    )
}

export default AddAdditionalDayDialog; 