import { useCallback, useState } from "react";
import CustomTextField from "../../../../../../../../common/components/CustomTextField/CustomTextField";
import MenuItem from "@material-ui/core/MenuItem";
import SelectorModal from "./components/SelectorModal";

interface Props {
    selectedSpecialDiets: any[];
    rawOnChange: (field: string, value: any) => void;
    disabled: boolean;
}

const SpecialDietsV2Selector = (props: Props) => {
    const { selectedSpecialDiets, rawOnChange, disabled } = props;

    const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);

    const selectDiet = useCallback((details: any | null, description: string | null, selected: boolean) => {
        const newDiets = selectedSpecialDiets.filter(s => (s.details?.id ?? null) !== (details?.id ?? null));

        if (selected && (details || description)) {
            newDiets.push({
                details,
                description
            });
        }

        rawOnChange('specialDietsV2', newDiets);
    }, [selectedSpecialDiets, rawOnChange])

    const selectProps = {
        open: false,
        onOpen: () => disabled ? undefined : setIsSelectModalOpen(true)
    };

    return (
        <>
            <CustomTextField
                value={selectedSpecialDiets.length === 0 ? null : 1}
                label='Intolleranze o diete speciali'
                path={'special_diets'}
                data={({})}
                dataOnChange={() => undefined}
                variant='outlined'
                select
                SelectProps={selectProps}
                disabled={disabled}
            >
                {selectedSpecialDiets.length === 0 ? (
                    <MenuItem />
                ) : (
                    <MenuItem value={1}>
                        {selectedSpecialDiets.sort((diet1, diet2) => {
                            return (diet1.details?.order ?? 9999) - (diet2.details?.order ?? 9999)
                        }).map((diet) => {
                            if (diet.details) {
                                return diet.details.description;
                            } else {
                                return diet.description;
                            }
                        }).join(', ')}
                    </MenuItem>
                )}

            </CustomTextField>

            <SelectorModal
                open={isSelectModalOpen}
                closeDialog={() => setIsSelectModalOpen(false)}
                selectedDiets={selectedSpecialDiets}
                selectDiet={selectDiet}
            />
        </>
    )
}

export default SpecialDietsV2Selector;