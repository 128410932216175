import { useSelector } from 'react-redux';

import { ReduxState } from '../../../redux/types';
import LoginDialog from './LoginDialog/LoginDialog';

const Dialogs = () => {
    const { loginDialog } = useSelector((state: ReduxState) => state.globalDialogs);

    return (
        <>
            <LoginDialog open={loginDialog} />
        </>
    );
};

export default Dialogs;
