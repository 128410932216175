import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useWindowSize } from 'react-use';

import { ReduxState } from '../../../../redux/types';
import RegistrationPage from './RegistrationPage';
import RegistrationPageLogin from './RegistrationPageLogin';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import useSearchParams from '../../../../common/hooks/useSearchParams/useSearchParams';
import { useMessageDialog } from '../../../../common/hooks/useMessageDialog/useMessageDialog';
import { useEffect, useState } from 'react';
import api from '../../../../common/utils/api';
import useInternalLoader from '../../../../common/hooks/useInternalLoader/useExternalLoader';

const RegistrationPageWrapper = () => {
    const { id: courseId } = useParams<{ id: string }>();
    const { isIframe } = useSelector((state: ReduxState) => state.routing);
    const { welfare } = useSearchParams();
    const { width } = useWindowSize();

    const setExternalIsLoading = useInternalLoader();
    const history = useHistory();

    const [courseData, setCourseData] = useState<any>(null);

    useEffect(() => {
        api.request('/courses/' + courseId).then(res => {
            setCourseData(res);
        }).catch(() => {
            history.push('/');
        }).finally(() => {
            setExternalIsLoading(false);
        });
    }, [setExternalIsLoading, courseId, history]);

    const userInfo = useSelector((state: ReduxState) => state.user);
    const isUserLoggedIn = userInfo.id.length > 0;

    const [welfareData, setWelfareData] = useState<any | undefined | null>(undefined);

    const [showMessageDialog] = useMessageDialog();

    useEffect(() => {
        const showError = () => {
            showMessageDialog({
                title: 'Link non valido',
                message: (
                    <>
                        <p style={{ margin: 0 }}>
                            Il link potrebbe essere già stato utilizzato, oppure non è stato copiato correttamente.
                        </p>
                        <p style={{ marginBottom: 0 }}>
                            Verifica che il link sia corretto, oppure scrivi a{' '}
                            <Link href={'mailto:info@epuntos.it'} target='_blank' rel='noreferrer'>info@epuntos.it</Link>{' '}
                            per ricevere assistenza.
                        </p>
                    </>
                ),
                notDismissible: true
            });
        };

        if (welfare) {
            api.request('/welfare/links/' + welfare, 'GET', undefined, false).then((res: any) => {
                setWelfareData(res);

                if (res.courseId !== courseId || !res.valid) {
                    showError();
                }
            }).catch(() => {
                showError();
            })
        }
    }, [welfare, courseId, setWelfareData, showMessageDialog])

    if (courseId.toLowerCase() === 'fd8524cb-5577-4869-8e4c-f43cbee9c71e') {
        return (
            <div>
                <Paper elevation={isIframe ? 0 : 3} style={{ padding: '8px', margin: width > 700 ? '6px 24px' : '2px', textAlign: 'center' }}>
                    <p style={{ fontSize: '1.65em', marginTop: '4px', marginBottom: 0 }}>
                        Siamo spiacenti ma è scaduto il termine per le iscrizioni della GITA NATURALISTICA/SPORTIVA CLERICI.
                    </p>
                    <p style={{ fontSize: '1.15em', marginBottom: '6px' }}>
                        Se necessitate di maggiori informazioni potete contattare il nostro Responsabile Riboli Alessandro direttamente al <Link href='tel:+393405957531'>3405957531</Link>.
                    </p>
                </Paper>
            </div>
        );
    }

    if (!courseData?.registrationsOpen) {
        return (
            <div>
                <Paper elevation={isIframe ? 0 : 3} style={{ padding: '8px', margin: width > 700 ? '6px 24px' : '2px', textAlign: 'center' }}>
                    <p style={{ fontSize: '1.65em', marginTop: '4px', marginBottom: 0 }}>
                        Siamo spiacenti ma al momento non è possibile iscriversi al corso selezionato.
                    </p>
                    <p style={{ fontSize: '1.15em', marginBottom: '6px' }}>
                        Se necessitate di maggiori informazioni potete contattarci a <Link href='mailto:info@epuntos.it'>info@epuntos.it</Link>.
                    </p>
                </Paper>
            </div>
        );
    }

    if (!isUserLoggedIn) {
        return <RegistrationPageLogin />;
    }

    return (
        <RegistrationPage welfareData={welfareData} resetWelfareData={() => setWelfareData(null)} />
    );
};

export default RegistrationPageWrapper;
