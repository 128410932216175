import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components/macro';

export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`

export const Title = styled(Typography) <{ isMobile: boolean }>`
    font-size: 3em;
    flex: 1;
    text-align: ${p => p.isMobile ? 'center' : 'left'};
    display: ${p => p.isMobile ? 'block' : 'flex'};
    width: 100%;
    margin: 0 0 6px;
`;

export const StyledTablePagination = styled(TablePagination)`
    width: fit-content;
    margin: 5px auto 0;
`;
