import GenericObject from '../../../../../../typesAdditional/GenericObject';
import Address from './sections/address/Address';
import BasicData from './sections/basicData/BasicData';
import ContactData from './sections/contactData/ContactData';
import SchoolClass from './sections/schoolClass/SchoolClass';
import SchoolGrade from './sections/schoolGrade/ScoolGrade';

export type FormSection = 'basic_data' | 'tax_code' | 'school_grade' | 'school_class' | 'address' | 'phone' | 'email';

export interface SectionProps {
    formPrefix: string;
    rawOnChange: (field: string, value: any) => void;
    dataOnChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: string) => void;
    data: GenericObject;
    validationResult: GenericObject;
    disabledFields?: GenericObject;
    schoolClasses?: any[];
}

interface Props extends SectionProps {
    sections: FormSection[];
}

const GenericPersonForm = (props: Props) => {
    const { sections, ...sectionProps } = props;

    return (
        <>
            {(sections.includes('basic_data') || sections.includes('tax_code')) && (
                <>
                    <BasicData {...sectionProps} withTaxCode={sections.includes('tax_code')} />
                </>
            )}

            {(sections.includes('school_grade')) && (
                <SchoolGrade {...sectionProps} />
            )}

            {(sections.includes('school_class')) && (
                <SchoolClass {...sectionProps} />
            )}

            {sections.includes('address') && (
                <Address {...sectionProps} />
            )}

            {(sections.includes('phone') || sections.includes('email')) && (
                <ContactData {...sectionProps} withPhone={sections.includes('phone')} withEmail={sections.includes('email')} />
            )}
        </>
    );
};

export default GenericPersonForm;
