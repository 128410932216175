import { useCallback, useEffect, useRef, useState } from 'react';
import { useWindowSize } from 'react-use';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { v4 as uuidv4 } from 'uuid';

import CustomTextField from '../../../../common/components/CustomTextField/CustomTextField';
import { useMessageDialog } from '../../../../common/hooks/useMessageDialog/useMessageDialog';
import api from '../../../../common/utils/api';
import snackbar from '../../../../common/utils/snackbar';
import UserSegmentSelector from '../../components/UserSegmentSeletor/UserSegmentSelector';
import SimulateConfirmDialog from './components/SimulateConfirmDialog/SimulateConfirmDialog';
import TinyEditor from './components/TinyEditor/TinyEditor';
import { Title } from './styled';
import VennDiagramDialog from '../../components/UserSegmentSeletor/components/VennDiagramDialog/VennDiagramDalog';

const NewEmailPage = () => {
    const { width } = useWindowSize();
    const isMobile = width <= 700;

    const [userSelectorHeight, setUserSelectorHeight] = useState(0);
    const userSelectorRef = useRef<any>(null);

    useEffect(() => {
        const interval = setInterval(() => {
            if (userSelectorRef.current) {
                setUserSelectorHeight(userSelectorRef.current.clientHeight);
            }
        }, 100);

        return () => clearInterval(interval);
    }, []);

    const [subject, setSubject] = useState('');
    const [filtersTree, setFiltersTree] = useState<any>([]);
    const [selectionType, setSelectionType] = useState<'people' | 'users'>('people');
    const [simulationResult, setSimulationResult] = useState<any>({ countUsers: 0 });
    const [editorRef, setEditorRef] = useState<any>(null);
    const [isSimulateConfirmDialogOpen, setIsSimulateConfirmDialogOpen] = useState(false);
    const [isVennDiagramDialogOpen, setIsVennDiagramDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [showMessageDialog, closeMessageDialog] = useMessageDialog();

    const [ignoreNewsletterConsent, setIgnoreNewsletterConsent] = useState(false);

    const apiRequestId = useRef('');

    useEffect(() => {
        const currentRequestId = uuidv4();
        apiRequestId.current = currentRequestId;
        setIsLoading(true);

        api.request('/admin/user_segments/simulate', 'POST', { filter: filtersTree, ignoreNewsletterConsent, selectionType }).then(res => {
            if (currentRequestId !== apiRequestId.current) return;

            api.request('/admin/user_segments/simulate', 'POST', { filter: filtersTree, ignoreNewsletterConsent, selectionType, advanced: true }).then(res2 => {
                if (currentRequestId !== apiRequestId.current) return;

                setSimulationResult(res2);
            });

            setSimulationResult(res);
            setIsLoading(false);
        });
    }, [filtersTree, ignoreNewsletterConsent, selectionType]);

    const schedulateEmail = useCallback(() => {
        api.request('/admin/emails/schedulate', 'POST', { filter: filtersTree, content: editorRef.current.getContent(), subject, ignoreNewsletterConsent, selectionType }).then(res => {
            snackbar.success('Email inviata correttamente!');
            setFiltersTree([]);
        });
    }, [editorRef, filtersTree, subject, ignoreNewsletterConsent, selectionType]);

    const schedulateEmailAskConfirm = useCallback(() => {
        showMessageDialog({
            title: 'Invia email',
            message: (
                <>
                    <p style={{ marginTop: '0px' }}>
                        Sei sicuro di volere inviare l'email a <b>{simulationResult.countUsers} utent{simulationResult.countUsers === 1 ? 'e' : 'i'}</b>?
                    </p>
                    <p style={{ marginBottom: '0px' }}>
                        L'operazione non è reversibile.
                    </p>
                </>
            ),
            actions: [
                {
                    text: 'Annulla',
                    action: () => {
                        closeMessageDialog();
                    }
                },
                {
                    text: 'Conferma',
                    action: () => {
                        closeMessageDialog();
                        schedulateEmail();
                    }
                }
            ]
        });
    }, [closeMessageDialog, showMessageDialog, schedulateEmail, simulationResult]);

    return (
        <>
            <Title isMobile={isMobile}>
                Invio nuova email
            </Title>

            <CustomTextField
                label={'Oggetto della mail'}
                variant='outlined'
                value={subject}
                keepMounted={true}
                onChange={(e: any) => {
                    setSubject(e.target.value);
                }}
            />

            {/*<Typography variant='h5' component='h2' style={{marginBottom: '12px'}}>
                Inserisci il testo dell'email
            </Typography>*/}

            <TinyEditor setEditorRef={setEditorRef} />

            <div style={{ marginTop: '10px' }} />

            <div style={{ display: width > 1200 ? 'flex' : 'block' }}>
                <div ref={userSelectorRef} style={{ flex: '1', maxWidth: '600px', marginTop: '12px', marginRight: (width > 1200 ? '8px' : undefined) }}>
                    <UserSegmentSelector
                        filtersTree={filtersTree}
                        setFiltersTree={setFiltersTree}
                        hint={'Seleziona i destinatari dell\'email'}
                        selectionType={selectionType}
                        setSelectionType={setSelectionType}
                        showSelectionTypeMenu
                        simulationResult={simulationResult}
                    />
                </div>
                <div style={{ flex: '1', marginTop: '12px', marginLeft: (width > 1200 ? '8px' : undefined), maxHeight: (width > 1200 ? userSelectorHeight : undefined) }}>
                    <Card style={{ height: '100%', minHeight: (width <= 1200 ? '400px' : undefined), maxHeight: (width <= 1200 ? '400px' : undefined) }}>
                        <CardContent>
                            <Typography variant='h5' component='h2' style={{ marginBottom: '12px' }}>
                                Destinatari selezionati
                            </Typography>

                            {(isLoading || simulationResult.personList === null || simulationResult.personList === undefined) ? (
                                <CircularProgress style={{ margin: '0 auto', display: 'block' }} />
                            ) : (
                                <List style={{ maxHeight: (width > 1200 ? (userSelectorHeight - 80) : undefined), overflow: 'auto' }}>
                                    {simulationResult.personList.sort((person1: any, person2: any) => {
                                        return (!person1.surname ? 'ZZZ' : person1.surname).localeCompare(!person2.surname ? 'ZZZ' : person2.surname) || (!person1.name ? 'ZZZ' : person1.name).localeCompare(!person2.name ? 'ZZZ' : person2.name);
                                    }).map((person: any, index: number) => {
                                        return (
                                            <>
                                                <ListItemText primary={person.surname + ' ' + person.name} secondary={person.emails.join(', ')} />

                                                {(index + 1) !== simulationResult.personList.length && (
                                                    <Divider component='li' />
                                                )}
                                            </>
                                        );
                                    })}
                                </List>
                            )}
                        </CardContent>
                    </Card>
                </div>
            </div>

            <div style={{ marginTop: '20px' }} />

            <div>
                <FormControlLabel
                    control={
                        <Checkbox
                            color='primary'
                            style={{ padding: '4px' }}
                            checked={ignoreNewsletterConsent}
                            onChange={(e) => {
                                setIgnoreNewsletterConsent(e.target.checked);
                            }}
                        />
                    }
                    style={{ marginLeft: '-4px', marginBottom: '8px' }}
                    label={'Invia anche agli utenti che non hanno dato il consenso alla ricezione della newsletter'}
                />
            </div>

            {/*
            <div>
                <Button variant='outlined' disabled={isLoading} color='primary' onClick={() => setIsVennDiagramDialogOpen(true)}>
                    Mostra diagramma di Venn
                </Button>
            </div>
            */}

            <div style={{ marginTop: '8px' }} />

            <Button variant='outlined' disabled={isLoading} color='primary' onClick={() => setIsSimulateConfirmDialogOpen(true)}>
                Simula invio email
            </Button>

            <Button style={{ marginLeft: '8px' }} variant='contained' color='primary' onClick={schedulateEmailAskConfirm} disabled={isLoading || simulationResult.countUsers === 0}>
                Invia email a {simulationResult.countUsers} utent{simulationResult.countUsers === 1 ? 'e' : 'i'}
            </Button>

            <SimulateConfirmDialog
                open={isSimulateConfirmDialogOpen}
                closeDialog={() => setIsSimulateConfirmDialogOpen(false)}
                editorRef={editorRef}
                subject={subject}
            />

            <VennDiagramDialog
                isOpen={isVennDiagramDialogOpen}
                onClose={() => setIsVennDiagramDialogOpen(false)}
                filters={simulationResult?.filters ?? []}
            />
        </>
    );
};

export default NewEmailPage;
