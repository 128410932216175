import { useCallback, useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';

import { Button, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import ArchiveIcon from '@material-ui/icons/Archive';
import moment from 'moment';

import GreenButton from '../../../../common/components/GreenButton/GreenButton';
import useInternalLoader from '../../../../common/hooks/useInternalLoader/useExternalLoader';
import { useMessageDialog } from '../../../../common/hooks/useMessageDialog/useMessageDialog';
import api from '../../../../common/utils/api';
import snackbar from '../../../../common/utils/snackbar';
import NewVoucherDialog from './components/NewVoucherDialog/NewVoucherDialog';
import VoucherDetailsDialog from './components/VoucherDetailsDialog/VoucherDetailsDialog';
import { Title } from './styled';

export const TYPES_MAP = {
    specific_users: 'codici individuali',
    everyone: 'singolo codice generico',
    quote: 'voucher automatico preventivo'
};

const VouchersPage = () => {
    const setIsLoading = useInternalLoader();

    const { width } = useWindowSize();

    const [showMessageDialog, closeMessageDialog] = useMessageDialog();

    const isMobile = width <= 700;

    const [data, setData] = useState<any[]>([]);

    const [isNewVoucherDialogOpen, setIsNewVoucherDialogOpen] = useState(false);
    const [isDetailsVoucherDialogOpen, setIsDetailsVoucherDialogOpen] = useState(false);
    const [detailsVoucherId, setDetailsVoucherId] = useState('');

    const [isArchive, setIsArchive] = useState(false);

    const updateData = useCallback(() => {
        setIsLoading(true);
        setData([]);
        api.request('/admin/voucher_groups' + (isArchive ? '/archive' : '')).then(res => {
            setData(res);
            setIsLoading(false);
        });
    }, [setIsLoading, isArchive]);

    const archiveGroup = useCallback((id: string) => {
        api.request('/admin/voucher_groups/' + id + '/archive', 'POST').then(() => {
            snackbar.success('Voucher archiviato con successo');
            closeMessageDialog();
            updateData();
        });
    }, [updateData, closeMessageDialog]);

    const archiveGroupConfirm = useCallback((id: string) => {
        showMessageDialog({
            title: 'Archivia voucher',
            message: (
                <>
                    Sei sicuro di volere archiviare il voucher selezionato?<br/>
                    In questo modo non sarà più utilizzabile da altri utenti.
                </>
            ),
            actions: [
                {
                    text: 'Annulla',
                    action: () => {
                        closeMessageDialog();
                    }
                },
                {
                    text: 'Archivia',
                    color: 'secondary',
                    action: () => {
                        archiveGroup(id);
                    }
                }
            ]
        });
    }, [archiveGroup, showMessageDialog, closeMessageDialog]);

    useEffect(() => {
        updateData();
    }, [updateData]);

    return (
        <>
            <Title isMobile={isMobile}>
                <div style={{flex: '1', flexGrow: 1}}>
                    Voucher
                </div>

                <Link href='#' onClick={() => setIsArchive(f => !f)} style={{fontSize: 'initial', marginTop: '15px', marginRight: '10px'}}>
                    {isArchive ? (
                        <>Visualizza voucher attivi</>
                    ) : (
                        <><ArchiveIcon style={{marginTop: '5px', marginBottom: '-5px'}}/> Visualizza voucher archiviati</>
                    )}
                </Link>

                {!isArchive && (
                    <GreenButton onClick={() => setIsNewVoucherDialogOpen(true)}>
                        Nuovo voucher
                    </GreenButton>
                )}
                {/*<div style={{flex: '1', flexGrow: 1, maxWidth: '200px', marginTop: '4px'}}>
                    <CustomTextField
                        select
                        label={'Stagione iscrizioni'}
                        value={seasonId}
                        onChange={(e: any) => {
                            setSeasonId(e.target.value);
                            localStorage.setItem('courses_season_id', e.target.value);
                        }}
                        variant='outlined'
                        required
                        fullWidth
                    >
                        {seasons.map((opt: any) => (
                            <MenuItem key={opt.id} value={opt.id} style={{whiteSpace: 'break-spaces'}}>
                                {opt.name}
                            </MenuItem>
                         ))}
                     </CustomTextField>
                </div>*/}
            </Title>

            <TableContainer component={Paper} >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Descrizione</TableCell>
                            <TableCell>Data creazione</TableCell>
                            <TableCell>Data scadenza</TableCell>
                            <TableCell>Creato da</TableCell>
                            <TableCell>Tiplogia</TableCell>
                            <TableCell>Valore</TableCell>
                            <TableCell>Voucher utilizzati</TableCell>
                            <TableCell>Costo iniziativa</TableCell>
                            <TableCell style={{width: '1px', whiteSpace: 'nowrap'}}>Azioni</TableCell>
                        </TableRow>
                    </TableHead>
                        <TableBody>
                        {data.map((voucher: any) => {
                            return (
                                <TableRow>
                                    <TableCell>{voucher.description}</TableCell>
                                    <TableCell>{moment(voucher.createdAt).format('DD/MM/YYYY')}</TableCell>
                                    <TableCell>{moment(voucher.expireDate).format('DD/MM/YYYY')}</TableCell>
                                    <TableCell>{voucher.superUser?.name}</TableCell>
                                    <TableCell>{TYPES_MAP[voucher.target as keyof(typeof TYPES_MAP)]}</TableCell>
                                    <TableCell>€{parseFloat(voucher.amount).toFixed(2)}</TableCell>
                                    <TableCell>{voucher.usedVouchers}</TableCell>
                                    <TableCell>€{parseFloat(voucher.expense).toFixed(2)}</TableCell>
                                    <TableCell style={{whiteSpace: 'nowrap'}}>
                                        <Button onClick={() => {
                                            setDetailsVoucherId(voucher.id);
                                            setIsDetailsVoucherDialogOpen(true);
                                        }} color={'primary'} variant='outlined'>
                                            Visualizza dettagli
                                        </Button>{' '}
                                        {!isArchive && (
                                            <Button onClick={() => archiveGroupConfirm(voucher.id)} color={'secondary'} variant='outlined'>
                                                Archivia
                                            </Button>
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <NewVoucherDialog isOpen={isNewVoucherDialogOpen} onClose={() => setIsNewVoucherDialogOpen(false)} updateData={updateData} />

            <VoucherDetailsDialog isOpen={isDetailsVoucherDialogOpen} onClose={() => setIsDetailsVoucherDialogOpen(false)} voucherId={detailsVoucherId} />
        </>
    );
};

export default VouchersPage;
