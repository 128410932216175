import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import api from '../../../../../../common/utils/api';
import GenericObject from '../../../../../../typesAdditional/GenericObject';
import useHandleApiError from '../../steps/RegistrationStep6/hooks/useHandleApiError';
import usePriceOffset from '../../steps/RegistrationStep6/hooks/usePriceOffset';

interface Params {
    courseId: string;
    courseData: GenericObject;
    data: GenericObject;
    sessionType: string;
    selectedLocationId: string;
    setSelectedLocationId?: Dispatch<SetStateAction<string>>;
    initialSelectedSessions?: GenericObject[];
    finalStep?: boolean;
    validateCallback?: any;
    apiErrorCallback?: (res: any) => void;
    suggestedSessions?: GenericObject[];
    welfareData?: any;
}

const useSimulateRegistration = (params: Params) => {
    const { courseId, courseData, data, sessionType, selectedLocationId, setSelectedLocationId, initialSelectedSessions, finalStep, validateCallback, apiErrorCallback, suggestedSessions, welfareData } = params;
    const { locationType } = courseData;

    const [isLoading, setIsLoading] = useState(false);

    const [simulateRegistrationData, setSimulateRegistrationData] = useState<any>({
        sessions: []
    });

    const [selectedSessions, setSelectedSessions] = useState<any[]>(initialSelectedSessions ?? []);

    const apiRequestId = useRef('');
    const prevLocationId = useRef('');

    const handleApiError = useHandleApiError({ setSelectedLocationId, setSelectedSessions });

    const { getSessionPriceWithOffset, getMultiSessionSaving, isUsingPriceOffsetHooks } = usePriceOffset({
        suggestions: simulateRegistrationData?.suggestions ?? [],
        savedSessions: simulateRegistrationData?.suggestionsPriceGroups ?? [],
        currentRegistrationSessions: suggestedSessions ?? []
    });

    const suggestionsAdditionalPrice = useMemo(() => {
        return (suggestedSessions ?? []).reduce((prev, curr) => {
            return prev + getSessionPriceWithOffset(curr.id);
        }, 0.0);
    }, [suggestedSessions, getSessionPriceWithOffset]);

    const isSelected = useCallback((sessionId: string) => {
        return !!selectedSessions.find(s => s.id === sessionId) || !!suggestedSessions?.find(s => s.id === sessionId);
    }, [selectedSessions, suggestedSessions]);

    const isFoodSelected = useCallback((sessionId: string) => {
        const curr = selectedSessions.find(s => s.id === sessionId);
        const curr2 = suggestedSessions?.find(s => s.id === sessionId);

        if (!curr && !curr2) return false;

        if (curr) return curr.foodService;
        if (curr2) return curr2.foodService;
    }, [selectedSessions, suggestedSessions]);

    const getSessionsLabel = useCallback((count: number) => {
        if (sessionType === 'single_event') {
            if (count === 1) {
                return 'evento';
            } else {
                return 'eventi';
            }
        } else if ((sessionType === 'weekly_events')) {
            if (count === 1) {
                return 'giorno';
            } else {
                return 'giorni';
            }
        } else if (sessionType === 'daily_week_events') {
            if (count === 1) {
                return 'settimana';
            } else {
                return 'settimane';
            }
        }

        return '';
    }, [sessionType]);

    const hasFoodService = simulateRegistrationData?.sessions?.some((location: any) => {
        return location.hasFoodService;
    }) ?? false;

    const countSessions = useMemo(() => {
        return simulateRegistrationData?.sessions?.filter((sess: any) => {
            return sess.status === 'pending_registration' || sess.status === 'registered';
        })?.length ?? 0;
    }, [simulateRegistrationData]);

    const countSessionsFoodService = useMemo(() => {
        return simulateRegistrationData?.sessions?.filter((sess: any) => {
            return (sess.status === 'pending_registration' || sess.status === 'registered') && sess.userWantsThisSessionFoodService;
        })?.length ?? 0;
    }, [simulateRegistrationData]);

    const countQueueSessions = useMemo(() => {
        return simulateRegistrationData?.sessions?.filter((sess: any) => {
            return (sess.status === 'pending_queue' || sess.status === 'queue');
        })?.length ?? 0;
    }, [simulateRegistrationData]);

    const toPayNoAdditional = useMemo(() => {
        let cost = 0.0;

        if (simulateRegistrationData?.discounts?.selected?.newFinalPrice !== undefined) {
            cost = simulateRegistrationData?.discounts?.selected?.newFinalPrice + (suggestionsAdditionalPrice ?? 0);
        } else {
            cost = simulateRegistrationData?.finalPrice + (suggestionsAdditionalPrice ?? 0);
        }

        return cost;
    }, [simulateRegistrationData, suggestionsAdditionalPrice]);

    const toPay = useMemo(() => {
        let cost = toPayNoAdditional;

        cost += (simulateRegistrationData?.discounts?.additionals ?? []).reduce((prev: number, curr: any) => {
            return prev - parseFloat(curr.amount);
        }, 0.0);

        cost += (simulateRegistrationData?.tempAdditionals ?? []).reduce((prev: any, curr: any) => {
            return prev - parseFloat(curr.amount);
        }, 0.0);

        return cost;
    }, [toPayNoAdditional, simulateRegistrationData]);

    const saving = useMemo(() => {
        return simulateRegistrationData?.basePrice - simulateRegistrationData?.finalPrice + (
            isUsingPriceOffsetHooks ?
                getMultiSessionSaving() :
                (simulateRegistrationData?.suggestedResult?.basePrice ?? 0) - simulateRegistrationData?.suggestedResult?.finalPrice ?? 0);
    }, [simulateRegistrationData, getMultiSessionSaving, isUsingPriceOffsetHooks]);

    const additionalSaving = useMemo(() => {
        return simulateRegistrationData?.finalPrice - toPayNoAdditional;
    }, [simulateRegistrationData, toPayNoAdditional]);

    useEffect(() => {
        if (!courseData.showAllLocationsSessions) {
            setSimulateRegistrationData({ sessions: [] });
        }
    }, [courseData, selectedLocationId]);

    useEffect(() => {
        if (!selectedLocationId) return;

        console.log('X');

        const requestData = {
            ...data,
            locationId: selectedLocationId,
            sessions: selectedSessions,
            suggestedSessions,
            documents: [],
            consents: [],
            welfareData
        };

        const currentRequestId = uuidv4();
        apiRequestId.current = currentRequestId;

        setIsLoading(true);
        console.log('Y');

        const isAdmin = (window.location.pathname.startsWith('/admin') || window.location.host.startsWith('admin.epuntos.it'));

        const realValidateCallback = validateCallback ? validateCallback() : Promise.resolve();

        const timeout = setTimeout(() => {
            realValidateCallback.then(() => {
                api.request((isAdmin ? '/admin' : '') + '/courses/' + courseId + '/registration/simulate', 'POST', requestData, false).then(res => {
                    if (currentRequestId !== apiRequestId.current) return;

                    if (prevLocationId.current !== selectedLocationId && (!_.isEqual(res?.savedRegistration?.sessions ?? [], selectedSessions) || selectedSessions.length === 0)) {
                        prevLocationId.current = selectedLocationId;

                        if (!_.isEqual(res?.savedRegistration?.sessions ?? [], selectedSessions) && !finalStep && selectedSessions.length === 0) {
                            setSelectedSessions(res?.savedRegistration?.sessions ?? []);
                        } else {
                            setSimulateRegistrationData(res);
                            setIsLoading(false);
                        }
                    } else {
                        setSimulateRegistrationData(res);
                        setIsLoading(false);
                    }
                }).catch((res) => {
                    if (currentRequestId !== apiRequestId.current) return;
                    setIsLoading(false);
                    setSimulateRegistrationData({ sessions: [] });
                    if (apiErrorCallback) {
                        apiErrorCallback(res);
                    } else if (handleApiError) {
                        handleApiError(res);
                    }
                });
            }).catch(() => {
                if (currentRequestId !== apiRequestId.current) return;
                setIsLoading(false);
                setSimulateRegistrationData({ sessions: [] });
            });
        }, 1000);

        // console.log(validateCallback);

        return () => clearTimeout(timeout);
    }, [apiErrorCallback, courseId, selectedSessions, data, handleApiError, locationType, prevLocationId, selectedLocationId, finalStep, validateCallback, suggestedSessions, welfareData]);

    useEffect(() => {
        console.log('courseId changed');
    }, [courseId]);

    useEffect(() => {
        console.log('selectedSessions changed');
    }, [selectedSessions]);

    useEffect(() => {
        console.log('data changed');
    }, [data]);

    useEffect(() => {
        console.log('handleApiError changed');
    }, [handleApiError]);

    useEffect(() => {
        console.log('locationType changed');
    }, [locationType]);

    useEffect(() => {
        console.log('prevLocationId changed');
    }, [prevLocationId]);

    useEffect(() => {
        console.log('selectedLocationId changed');
    }, [selectedLocationId]);

    useEffect(() => {
        console.log('finalStep changed');
    }, [finalStep]);

    useEffect(() => {
        console.log('validateCallback changed');
    }, [validateCallback]);

    return {
        simulateRegistrationData,
        selectedSessions,
        setSelectedSessions,
        hasFoodService,
        countSessions,
        countSessionsFoodService,
        countQueueSessions,
        isLoading,
        isSelected,
        isFoodSelected,
        getSessionsLabel,
        toPay,
        saving,
        additionalSaving
    };
};

export default useSimulateRegistration;
