import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import AddIcon from '@material-ui/icons/Add';

import { StyledAvatar } from './styled';

interface Props {
    onClick: () => void;
}

const AddPersonItem = (props: Props) => {
    const { onClick } = props;

    return (
        <ListItem onClick={onClick} button>
            <ListItemAvatar>
                <StyledAvatar>
                    <AddIcon />
                </StyledAvatar>
            </ListItemAvatar>
            <ListItemText primary={'Aggiungi un\'altra persona'} />
        </ListItem>
    );
};

export default AddPersonItem;
