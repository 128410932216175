import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import GenericObject from "../../../../../../../../typesAdditional/GenericObject";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

interface Props {
    data: GenericObject;
    rawOnChange: (field: string, value: any) => void;
}

const NotteAlMultisportDetails = (props: Props) => {
    const { data, rawOnChange } = props;

    const ritiratoDaGenitore = data?._additionals?.ritiratoDaGenitore;
    const cognomePersonaRitiro = data?._additionals?.cognomePersonaRitiro;
    const nomePersonaRitiro = data?._additionals?.nomePersonaRitiro;
    const codiceFiscalePersonaRitiro = data?._additionals?.codiceFiscalePersonaRitiro;
    const oraRitiro = data?._additionals?.oraRitiro;
    const consensoFoto = data?._additionals?.consensoFoto;

    return (
        <>
            <div style={{ marginTop: '12px' }}>
                <Typography variant='body1' style={{ fontSize: '1.25em', textAlign: 'center', marginBottom: '6px' }}>
                    Tua figlia/figlio verrà ritirata da te genitore?
                </Typography>

                <div style={{ display: 'flex' }}>
                    <Button color={'primary'} variant={ritiratoDaGenitore === true ? 'contained' : 'outlined'} onClick={() => rawOnChange('_additionals.ritiratoDaGenitore', true)} style={{ flexGrow: 1, marginRight: '4px', height: '45px' }}>
                        Sì
                    </Button>
                    <Button color={'primary'} variant={ritiratoDaGenitore === false ? 'contained' : 'outlined'} onClick={() => rawOnChange('_additionals.ritiratoDaGenitore', false)} style={{ flexGrow: 1, marginLeft: '4px', height: '45px' }}>
                        No
                    </Button>
                </div>
            </div>

            {ritiratoDaGenitore === false && (
                <>
                    <div style={{ marginTop: '28px' }}>
                        <Typography variant='body1' style={{ fontSize: '1.25em', textAlign: 'center', marginBottom: '6px' }}>
                            Cognome e Nome della persona incaricata del ritiro
                        </Typography>

                        <div style={{ display: 'flex' }}>
                            <TextField
                                label='Cognome'
                                variant='outlined'
                                value={cognomePersonaRitiro}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => rawOnChange('_additionals.cognomePersonaRitiro', e.target.value)}
                                style={{ flexGrow: 1, marginRight: '4px' }}
                            />

                            <TextField
                                label='Nome'
                                variant='outlined'
                                value={nomePersonaRitiro}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => rawOnChange('_additionals.nomePersonaRitiro', e.target.value)}
                                style={{ flexGrow: 1, marginLeft: '4px' }}
                            />
                        </div>
                    </div>

                    <div style={{ marginTop: '28px' }}>
                        <Typography variant='body1' style={{ fontSize: '1.25em', textAlign: 'center', marginBottom: '6px' }}>
                            Codice Fiscale della persona incaricata del ritiro
                        </Typography>

                        <div style={{ display: 'flex' }}>
                            <TextField
                                label='Codice fiscale'
                                variant='outlined'
                                value={codiceFiscalePersonaRitiro}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => rawOnChange('_additionals.codiceFiscalePersonaRitiro', e.target.value)}
                                style={{ flexGrow: 1, marginRight: '4px' }}
                            />
                        </div>
                    </div>
                </>
            )}

            <div style={{ marginTop: '28px' }}>
                <Typography variant='body1' style={{ fontSize: '1.25em', textAlign: 'center', marginBottom: '2px' }}>
                    Ritirerà la bambina / il bambino alle...
                </Typography>

                <Typography variant='body2' style={{ fontSize: '1.05em', textAlign: 'center', marginBottom: '6px' }}>
                    L'orario di ritiro è indicativo e non vincolante 😊
                </Typography>

                <TextField
                    label='Ora ritiro'
                    variant='outlined'
                    value={oraRitiro}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => rawOnChange('_additionals.oraRitiro', e.target.value)}
                    style={{ width: '100%' }}
                    select
                >
                    <MenuItem value={'21:30'}>
                        21:30
                    </MenuItem>
                    <MenuItem value={'22:00'}>
                        22:00
                    </MenuItem>
                    <MenuItem value={'22:30'}>
                        22:30
                    </MenuItem>
                </TextField>
            </div>

            <div style={{ marginTop: '28px' }}>
                <Typography variant='body1' style={{ fontSize: '1.25em', textAlign: 'center', marginBottom: '2px' }}>
                    Ci dai consenso a scattare qualche foto per ricordare l'evento? 🤗
                </Typography>

                <Typography variant='body2' style={{ fontSize: '1.05em', textAlign: 'center', marginBottom: '6px' }}>
                    Vorremmo ricordare l'evento e ci piacerebbe scattare  qualche foto! ❤️
                </Typography>

                <div style={{ display: 'flex' }}>
                    <Button color={'primary'} variant={consensoFoto === true ? 'contained' : 'outlined'} onClick={() => rawOnChange('_additionals.consensoFoto', true)} style={{ flexGrow: 1, marginRight: '4px', height: '45px' }}>
                        Sì
                    </Button>
                    <Button color={'primary'} variant={consensoFoto === false ? 'contained' : 'outlined'} onClick={() => rawOnChange('_additionals.consensoFoto', false)} style={{ flexGrow: 1, marginLeft: '4px', height: '45px' }}>
                        No
                    </Button>
                </div>
            </div>
        </>
    )
}

export default NotteAlMultisportDetails;